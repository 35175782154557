// React
import React from "react";
import { useHistory } from "react-router-dom";

// Material
import { Avatar, useMediaQuery } from "@material-ui/core";
import Moment from "react-moment";

// Images
import User from "../../../../../assets/img/user-icon.svg";
//API

export default function CardHome({ pregunta }) {
  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");
  let history = useHistory();
  return (
    <>
      {matchPhone ? (
        <div className={`w-100`}>
          <div className="br-3 box-shadow-all py-2">
            {/* <img className="question-img-card w-100" src={Pregunta}></img> */}
            <div
              className={` cursor-pointer mt-3 ml-3`}
              onClick={() => {
                history.push({
                  pathname: "/cid/carso/questions/list/" + pregunta.question_id,
                  state: { question: pregunta },
                });
              }}
            >
              <div className={`d-flex align-items-center`}>
                <Avatar
                  alt="user"
                  style={{ maxHeight: "28px", maxWidth: "28px" }}
                  src={
                    pregunta?.photo_link != undefined
                      ? `https://images.weserv.nl/?url=${pregunta.photo_link}&maxage=31d `
                      : User
                  }
                />
                <p
                  className={`ml-3 a-text-regular-second-neutral-30 text-normal`}
                >
                  {pregunta.name != null ? pregunta.name : "user"} - Hace{" "}
                  <Moment locale="es" fromNow>
                    {pregunta.created_at}
                  </Moment>{" "}
                </p>
              </div>
              <p
                className={`a-text-bold-second-neutral-70 text-normal mt-3 pb-7`}
              >
                {pregunta.question != null ? pregunta.question : ""}
              </p>
            </div>
          </div>
        </div>
      ) : matchIpadMini ? (
        <>
          <div className={`d-flex`}>
            <div
              className="bg-blue-card br-3 box-shadow-all d-flex flex-column justify-content-center"
              style={{ minHeight: "190px" }}
            >
              <div
                className={`cursor-pointer d-flex flex-column bck-color-white box-shadow-all br-3 my-3 mx-4`}
                style={{ minWidth: "324px" }}
                onClick={() => {
                  history.push({
                    pathname:
                      "/cid/carso/questions/list/" + pregunta.question_id,
                    state: { question: pregunta },
                  });
                }}
              >
                <div className={`d-flex mt-4 ml-4 align-items-center`}>
                  <Avatar
                    alt="user"
                    style={{ maxHeight: "32px", maxWidth: "32px" }}
                    src={
                      pregunta?.photo_link != null
                        ? `https://images.weserv.nl/?url=${pregunta.photo_link}&maxage=31d `
                        : User
                    }
                  />
                  <p
                    className={`ml-2 a-text-regular-second-neutral-30 text-normal`}
                  >
                    {pregunta.name != null ? pregunta.name : "user"} - Hace{" "}
                    <Moment locale="es" fromNow>
                      {pregunta.created_at}
                    </Moment>{" "}
                  </p>
                </div>
                <p
                  className={`a-text-bold-second-neutral-70 text-normal mt-3 pb-7 mr-2 ml-4 line-height-24`}
                >
                  {pregunta.question != null ? pregunta.question : ""}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
