// React
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';

// Components
import Card from '../../../molecules/Card';

// Api
import { PDF_PATH } from './../../../../api/config';

export default function FilteredCompany({ data, noSlice, image, name, type, onClick, noName, noMostrar, fullView, miniView }) {
  const user = useAuth();

  // State
  const [more, setMore] = useState(5);
  //${res.investors.map(res => ` ${res.investor}`)}`}, esto va para que pintara todo lo que hubiera
  if (noSlice) {
    return data.map((res, i) => (
      <Card
        key={i}
        /* noEdit={user.rolId === 3} */
        image={res.logo_link}
        title={res.name}
        link={{
          pathname: '/cid/company/' + res.company_id,
          state: {
            array: data.map((r) => r.company_id),
            index: i,
            location: 2,
          },
        }}
        linkEdit={'/cid/update-company/' + res.company_id}
        linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
        linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
        id={res.company_id}
        onClick={onClick}
        fullView={fullView}
        miniView={miniView}
      />
    ));
  } else if (type) {
    function handleFilter(res, i) {
      switch (type) {
        case 'sector':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.sectores}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'industria':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.industrias}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'tecnología':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.markets}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'subtech':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.subtech}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'mercado':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.mercados}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'sede':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.sede}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'alianza':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.alliances}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'inversionista':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${res.investors}`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );
        case 'fundador':
          return (
            <Card
              key={i}
              noEdit={user.rolId === 3}
              image={res.logo_link}
              title={`${res.name} - ${
                res.company_founders.includes(',')
                  ? res.company_founders
                      .split(',')
                      .filter(Boolean)
                      .map((res) => ` ${res}`)
                  : res.company_founders
              }`}
              link={{
                pathname: '/cid/company/' + res.company_id,
                state: {
                  array: data.map((r) => r.company_id),
                  index: i,
                  location: 2,
                },
              }}
              linkEdit={'/cid/update-company/' + res.company_id}
              linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
              linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
              id={res.company_id}
              onClick={onClick}
              fullView={fullView}
              miniView={miniView}
            />
          );

        default:
          break;
      }
    }
    return data !== undefined ? (
      image !== undefined ? (
        <div className='pb-3'>
          <div className='d-flex'>
            <img
              src={image}
              alt='Icon'
              className='mb-3 w-1'
            />
            <h4 className='ml-2 mb-4 a-text-medium-first-primary-60'>{name} -</h4>
            <span className='a-text-regular-first-neutral-80 ml-2 mt-2'>{data.length > 1 ? `${data.length} Resultados` : `${data.length} Resultado`}</span>
          </div>
          {data.map((res, i) => handleFilter(res, i)).slice(0, more)}
          <div
            onClick={() => setMore(more + 5)}
            className={`text-normal cursor-pointer a-text-regular-first-primary-60 ${data.length < 6 || data.map((res) => res).slice(0, more).length == data.length ? 'd-none' : ''}`}
          >
            Ver más ...
          </div>
        </div>
      ) : (
        <>
          <div>
            {data.map((res, i) => handleFilter(res, i)).slice(0, more)}
            <div
              onClick={() => setMore(more + 5)}
              className={`text-normal cursor-pointer pb-3 a-text-regular-first-primary-60 ${data.length < 6 || data.map((res) => res).slice(0, more).length == data.length ? 'd-none' : ''}`}
            >
              Ver más ...
            </div>
          </div>
        </>
      )
    ) : (
      <div className={noMostrar ? 'd-none' : 'd-flex align-items-center'}>
        <img
          src={image}
          alt='Icon'
          className='mb-4 w-1'
        />
        <h4 className='ml-2 mb-4 pb-2 a-text-medium-first-primary-60'>{name} -</h4>
        <span className='a-text-regular-first-neutral-80 ml-2 mb-4'>0 Resultados</span>
      </div>
    );
  } else {
    return data.length !== 0 ? (
      noName ? (
        <div className='pb-3'>
          <div className='d-flex align-items-center'>
            <img
              src={image}
              alt='Icon'
              className='mb-3 py-2 w-1'
            />
            <h4 className='ml-2 mb-4 a-text-medium-first-primary-60'>{name} -</h4>
            <span className='a-text-regular-first-neutral-80 ml-2 mb-3'>{data.length > 1 ? `${data.length} Resultados` : `${data.length} Resultado`}</span>
          </div>
          {data
            .map((res, i) => (
              <Card
                key={i}
                noEdit={user.rolId === 3}
                image={res.logo_link}
                title={res.name}
                link={{
                  pathname: '/cid/company/' + res.company_id,
                  state: {
                    array: data.map((r) => r.company_id),
                    index: i,
                    location: 2,
                  },
                }}
                linkEdit={'/cid/update-company/' + res.company_id}
                linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
                linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
                id={res.company_id}
                onClick={onClick}
                fullView={fullView}
                miniView={miniView}
              />
            ))
            .slice(0, more)}
          <div
            onClick={() => setMore(more + 5)}
            className={`text-normal cursor-pointer a-text-regular-first-primary-60 ${data.length < 6 || data.map((res) => res).slice(0, more).length == data.length ? 'd-none' : ''}`}
          >
            Ver más ...
          </div>
        </div>
      ) : (
        <div>
          {data
            .map((res, i) => (
              <Card
                key={i}
                noEdit={user.rolId === 3}
                image={res.logo_link}
                title={res.name}
                link={{
                  pathname: '/cid/company/' + res.company_id,
                  state: {
                    array: data.map((r) => r.company_id),
                    index: i,
                    location: 2,
                  },
                }}
                linkEdit={'/cid/update-company/' + res.company_id}
                linkPdf={`${PDF_PATH}/get-one-page?companyId=${res.company_id}`}
                linkPtt={`${PDF_PATH}/get-one-page-pdf?companyId=${res.company_id}`}
                id={res.company_id}
                onClick={onClick}
                fullView={fullView}
                miniView={miniView}
              />
            ))
            .slice(0, more)}
          <div
            onClick={() => setMore(more + 5)}
            className={`text-normal cursor-pointer pb-3 a-text-regular-first-primary-60 ${data.length < 6 || data.map((res) => res).slice(0, more).length == data.length ? 'd-none' : ''}`}
          >
            Ver más ...
          </div>
        </div>
      )
    ) : (
      <div className='d-flex align-items-center'>
        <img
          src={image}
          alt='Icon'
          className='mb-3 pb-2 w-1'
        />
        <h4 className='ml-2 mb-4 a-text-medium-first-primary-60 pb-2'>{name} -</h4>
        <span className='a-text-regular-first-neutral-80 ml-2 pb-2 mb-3'>0 Resultados</span>
      </div>
    );
  }
}
