// React
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

// Material
import { Grid } from "@material-ui/core";

// Images
import CatalogueActive from "../../../../assets/img/catalogue-icon-active.svg";

// Componens
import { Input } from "../../../atoms/Input";
import { Button } from "../../../atoms/Button";
import useAuth from "../../../../hooks/useAuth";

export default function CardHome({ active, linkSearch, link, linkAdd, image }) {
  const history = useHistory();

  const location = useLocation();

  function handleChange(e) {
    history.push({
      pathname: "/cid/search-company-alternative",
      state: {
        value: e.target.value,
        location: location.pathname,
      },
    });
  }

  const user = useAuth();

  function handleFocus() {
    setTimeout(() => {
      document.getElementById("search-company-header").focus();
      document.getElementById("search-report-header").focus();
    }, 500);
  }

  return (
    <div className="br-1 br-md-2 p-3 mb-3 px-md-5 px-xl-4 py-xl-5 py-md-4 br-t-lg-0 background-lg-white box-shadow-all box-shadow-lg-none">
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12} lg={9} xl={7} className="pl-xl-2">
          <div className="d-flex align-items-center mb-3">
            <img
              src={CatalogueActive}
              alt="Catalogue Icon"
              className="home-cid_icon mr-2 d-lg-none"
            />
            <h4 className="a-text-regular-second-neutral-70 text-md-big h1-lg">
              Catálogo de {active ? "empresas" : "análisis"}
            </h4>
          </div>
          <p className="a-text-regular-second-neutral-70 text-small text-md-medium">
            {active
              ? "Consulta las empresas que ya han sido registradas por usuarios del CID."
              : "Consulta los análisis que han sido registrados por usuarios del CID."}
          </p>
          <div className="my-4">
            <Link
              to={linkSearch}
              className="text-decoration-none"
              onClick={handleFocus}
            >
              <Input
                type="search2"
                placeholder={`Buscar ${active ? "empresas" : "análisis"}`}
                width="100%"
                focus={true}
                onChange={handleChange}
              />
            </Link>
          </div>
          <div className="d-flex justify-content-end justify-content-lg-start mb-md-5 mt-lg-5">
            {user.rolId !== 3 && (
              <Link to={linkAdd} className="d-none d-lg-block">
                <Button
                  type="button"
                  classes="text-small text-md-medium a-button-gray-1 home-cid_button mr-lg-3"
                  text="Agregar"
                />
              </Link>
            )}
            <Link to={link}>
              <Button
                type="button"
                classes="text-small text-md-medium a-button-blue-1 home-cid_button roboto-medium"
                text="Ver todo"
              />
            </Link>
          </div>
        </Grid>
        <Grid
          item
          lg={9}
          xl={5}
          className="pb-lg-4 pb-xl-0 d-none d-lg-flex align-items-center justify-content-xl-center"
        >
          <img src={image} />
        </Grid>
      </Grid>
    </div>
  );
}
