import React,{useState,useEffect} from 'react'
import userIcon from '../../../assets/img/user-icon.svg'
import { Grid,Modal,useMediaQuery,Avatar } from '@material-ui/core';
import { Input } from "../../atoms/Input";
import { useAuth } from "../../../hooks/useAuth";
import { getProfile } from "../../../api/profile";

//import { getProfileData } from "../../../api/questions";


export default function QuestionInput({placeholder, onChange, onKeyDown}) {
    const ipadMiniMatch = useMediaQuery("(min-width: 768px)")
    const user = useAuth();
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    const { userId } = user;
    useEffect(() => {
        getProfileData(userId)
    }, [])

    async function getProfileData(userId) {
        setLoad(true)

        try {

            const response = await getProfile(userId)
            setData(response.data.profilesUser[0])
            setLoad(false)

        } catch (error) {
            console.log(error)
        }
        
    }

    console.log(data.photo_link,'profile data')
    return (
        <>
            <div className={`d-flex align-items-center w-100 `} >
                <Avatar alt="user" src={load ? userIcon : `https://images.weserv.nl/?url=${data.photo_link}&maxage=31d`}  className="mr-lg-2 align-self-start"><img src={userIcon} className="w-100" /></Avatar>
                <Input onKeyDown={onKeyDown} type={'text'} placeholder={placeholder} width='100%' className={` border-first-neutral-60  h3-md text-small input-topic text-lg-normal py-2 pl-3 a-text-regular-second-neutral-60 text w-100 `} id={1} onChange={onChange} focus={true} rows={3} />
            </div>
            
        </>
    )
}
