// React
import { useState } from "react";

// Material
import { Popper } from "@material-ui/core";

// Components
import { InputWithLabel } from "../InputWithLabel";
import { Input } from "../../../atoms/Input";
import Time from "../../../atoms/Time";

export default function InputWithTime({
  width,
  text,
  id,
  placeholder,
  classLabel,
  value,
  onEvent,
}) {
  // State
  const [popper, setPopper] = useState(null);

  // Variables
  const isOpenPopper = Boolean(popper);

  // Funciones
  function handleTime(h, m, z) {
    onEvent(h, m, z);
  }

  return (
    <>
      {text ? (
        <InputWithLabel
          width={width}
          text={text}
          id={id}
          placeholder={placeholder}
          type="time"
          classLabel={classLabel}
          onClick={(e) => setPopper(popper ? null : e.currentTarget)}
          value={value}
        />
      ) : (
        <Input
          width={width}
          id={id}
          placeholder={placeholder}
          type="time"
          onClick={(e) => setPopper(popper ? null : e.currentTarget)}
          value={value}
        />
      )}
      <div
        className={isOpenPopper ? "popper" : "d-none"}
        onClick={() => setPopper(null)}
      ></div>
      <Popper open={isOpenPopper} anchorEl={popper}>
        <Time onEvent={(h, m, z) => handleTime(h, m < 10 ? "0" + m : m, z)} />
      </Popper>
    </>
  );
}
