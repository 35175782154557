import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import Moment from "react-moment";

import { PodcastContext } from "../../../providers/PodcastContext";

import Header from "../../../components/molecules/Header";
import ProfileComponent from "../../../components/molecules/Card/ProfileComponent/ProfileDetailBlog";

import Close from "../../../assets/img/closeFilter.svg";
import userIcon from "../../../assets/img/user-icon.svg";
import Play from "../../../assets/img/icon/PlayIcon.svg";
import Tag from "../../../assets/img/blogs/etiqueta.svg";
import Compartir from "../../../assets/img/icon/compartir.svg";
import Save from "../../../assets/img/news/guardar-1.svg";
import Megusta from "../../../assets/img/questions/megusta.svg";

import { Grid, useMediaQuery, IconButton } from "@material-ui/core";

import { useAuth } from "../../../hooks/useAuth";

export const PodcastEpisode = (e) => {
  const location = useLocation();
  const user = useAuth();
  const history = useHistory();
  const { userId } = user;

  const [duration, setDuration] = useState();

  const { setPodcast, setActive } = useContext(PodcastContext);

  const episode = location.state.episode;
  const ipadProUse = useMediaQuery("(min-width:992px)");

  getSound(episode.audio_podcast)

  const toTime = (value) => {
    var hours = Math.floor(value / 3600).toString();
    var minutes = Math.floor((value - hours * 3600) / 60).toString();
    var seconds = Math.ceil(value - hours * 3600 - minutes * 60).toString();

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    let time = `${hours != "00" ? hours + ":" : ""}${minutes + ":"}${
      seconds != "00" ? seconds : ""
    }`;

    return time;
  };

  function getSound(file) {
    var audio = new Audio(file);
    audio.addEventListener("loadeddata", () => {
      let duration = audio.duration;
      setDuration(toTime(duration));
    });
  }

  return (
    <Grid container justify="center" spacing={0} className="">
      {ipadProUse ? (
        <Header isCollapsed={e.isCollapsed} link="" text="Buscar podcast" />
      ) : (
        <div />
      )}
      <div
        className={`${
          ipadProUse
            ? "d-none"
            : "w-100 d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-1 mx-md-5 mx-4 py-3 mt-2 "
        }`}
      >
        {" "}
        <p className="h3-md text-big a-text-medium-first-primary-60">
          Podcast
        </p>{" "}
        {/* <Link to={"/cid/carso/podcast/list"}>
          {" "}
          <IconButton>
            <img
              src={Close}
              alt="PDF Icon"
              className="cursor-pointer "
              width="24px"
            />
          </IconButton>
        </Link> */}
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            src={Close}
            alt="PDF Icon"
            className="cursor-pointer "
            width="24px"
          />
        </IconButton>
      </div>
      <Grid
        item
        xs={10}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2"
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ">
          {ipadProUse ? (
            <div className="d-flex justify-content-between align-items-center mb-4 ">
              {" "}
              <p className="h3-md text-big a-text-medium-first-primary-60">
                Podcast
              </p>{" "}
              {/* <Link to={"/cid/carso/podcast/list"}>
                {" "}
                <IconButton>
                  <img
                    src={Close}
                    alt="PDF Icon"
                    className="cursor-pointer "
                    width="24px"
                  />
                </IconButton>
              </Link> */}
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
              >
                <img
                  src={Close}
                  alt="PDF Icon"
                  className="cursor-pointer "
                  width="24px"
                />
              </IconButton>
            </div>
          ) : (
            <div />
          )}
          {/* <div className="w-100 d-flex mt-2">
            <div className="d-flex justify-content-start  align-items-center ">
              <img
                src={
                  episode.photo_link !== null
                    ? `https://images.weserv.nl/?url=${episode.photo_link}&maxage=31d`
                    : userIcon
                }
                alt="icon"
                className="icon-card-question icon-md-card-question  mr-2 align-self-start br-3"
              />
            </div>
            <div className="d-flex align-content-center">
              <div className="d-flex a-text-regular-first-neutral-100 text-small text-md-normal align-items-center">
                <div className="mr-1">{episode.name}</div>
                <Moment fromNow>{episode.created_at}</Moment>
              </div>
            </div>
          </div> */}
          <div className="w-100 d-flex justify-content-center">
            <img
              src={`https://images.weserv.nl/?url=${episode.cover_podcast}&maxage=31d`}
              alt="PC"
              width={200}
              className="image-border mt-4"
            />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <p className="a-text-bold-second-neutral-70 text-big h3-md">
              {episode.titleEp}
            </p>
          </div>

          <div className="d-flex justify-content-center mt-2">
            <p className="text-xs a-text-regular-first-neutral-100">
              <Moment fromNow>{episode.created_at}</Moment>
            </p>
          </div>

          <div className="d-flex overflow-scroll mt-1 align-items-center justify-content-center mt-1">
            <img
              src={Tag}
              alt="icon"
              className={`tag-icon mr-1 ml-1  ${
                episode.labelsEp?.length != 0 ? "" : "d-none"
              }`}
            />
            {episode.labelsEp?.length != 0
              ? episode.labelsEp?.map((label, i) => (
                  <p
                    className="a-text-regular-first-primary-60 text-xs"
                    key={i}
                  >
                    {label.name}
                    {i < episode.labelsEp?.length - 1 ? ", " : ""}{" "}
                  </p>
                ))
              : ""}
          </div>

          <div className="w-100 mt-3">
            <p className="container-blog-preview text-small">
              {episode.descriptionEp}
            </p>
          </div>

          <div className="mt-4 w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <IconButton
                onClick={() => {
                  setPodcast(episode);
                  setActive(true);
                }}
                className={"cursor-pointer"}
                size="small"
              >
                <img src={Play} alt="Play" width="32px" />
              </IconButton>
              <p className="ml-1 text-small a-text-bold-first-neutral-100 ">{duration}</p>
            </div>
            <IconButton
              onClick={() => {}}
              className={"cursor-pointer"}
              size="small"
            >
              <img src={Compartir} alt="Play" width="24px" />
            </IconButton>
            <IconButton
              onClick={() => {}}
              className={"cursor-pointer"}
              size="small"
            >
              <img src={Save} alt="Play" width="18px" />
            </IconButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
