// React
import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";

// Componentes
import { Button } from "../../../atoms/Button/";
import { Input } from "../../../atoms/Input";
import TabMenu from "../../../molecules/TabMenu/Followers/followers";
import CardFollower from "../../../molecules/Card/CardFollower/index";

// Material
import {useMediaQuery, Grid } from '@material-ui/core/';
import { Skeleton } from '@material-ui/lab';

// API
import { getAllUsersWithProfile } from "../../../../api/profile";

// Images
import Close from "../../../../assets/img/closeFilter.svg"
import NotFound from "../../../../assets/img/sin-resultados-1.svg";


export default function SearchUsers({ userId, cerrarBusqueda, inputHeader }){

    const [usuarios, Setusuarios] = useState([]);
    const [namesUsers, setNamesUsers] = useState();
    const [mapaUsers, setMapaUsers] = useState();
    const [searchUsers, setSearchUsers] = useState(false);
    const [usersResults, setUsersResults] = useState([]);
    const [inputSearch, setInputSearch] = useState();

    const [prueba, setPrueba] = useState();
    const [loading, setLoading] = useState(true);


    const matchDesktop = useMediaQuery("(min-width: 1200px)")
    const matchIpadPro = useMediaQuery("(min-width: 992px)")
    const matchIpadMini = useMediaQuery("(min-width: 768px)")
    const matchPhone = useMediaQuery("(max-width: 767px)")


    useEffect(() => {
        recuperarListaUsuarios()
    }, [])

    if (prueba != inputHeader && loading == false) {
        setPrueba(inputHeader)
        buscarSeguidor2(inputHeader)
        console.log(namesUsers)
    }

    async function recuperarListaUsuarios(){
        try{
            let mapa = new Map();
            let mapa2 = new Map();
            const response = await getAllUsersWithProfile(userId)
            console.log(response.data.globalUsers, "api nueva")
            Setusuarios(response.data.globalUsers)
            if (response.data.globalUsers.length != 0) {
                response.data.globalUsers.forEach(element => {
                    let name = element.name + ' ' + element.lastname
                    name = name.toLowerCase()
                    mapa.set(element.user_id, name)
                    mapa2.set(element.user_id, element)
                });
                setNamesUsers(mapa)
                setMapaUsers(mapa2)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    function buscarSeguidor2(input){
        setSearchUsers(true)
        setInputSearch(input)
        let keys = []
        namesUsers.forEach((valor, clave) => {
            if (valor.includes(input.toLowerCase())) {
                keys.push(clave)
            }
        });
        setUsersResults(keys)
    }

    function buscarSeguidor(e){
        e.preventDefault()
        setSearchUsers(true)
        let input = "" + e.target.value
        setInputSearch(input)
        let keys = []
        namesUsers.forEach((valor, clave) => {
            if (valor.includes(input.toLowerCase())) {
                keys.push(clave)
            }
        });
        setUsersResults(keys)
    }

    function cardSkeleton(){
        let skeleton = []

        for (let index = 0; index < 5; index++) {
            if (matchIpadMini) {
                skeleton.push(
                    <div className={`body-card br-2 d-flex px-4 py-7 mb-3 ${matchIpadPro ? 'mt-3' : ''}`}>
                        <Skeleton variant="circle" animation='wave' width={35} height={32}></Skeleton>
                        <Grid container spacing={0} className={`d-flex align-items-center ml-4`}>
                            <Grid item xs={6}>
                                <Skeleton variant='rect' className='br-1' animation='wave' width={110} height={21} ></Skeleton>
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant='rect' className='br-1' animation='wave' width={80} height={21} ></Skeleton>
                            </Grid>
                        </Grid>
                    </div>
                )
            } else{
                skeleton.push(
                    <div className={`body-card br-2 d-flex px-3 py-2 mb-2`}>
                        <Skeleton variant="circle" animation='wave' width={24} height={24}></Skeleton>
                        <div className={`d-flex flex-column ml-3`}>
                            <Skeleton variant='rect' className='br-1' animation='wave' width={110} height={16} ></Skeleton>
                            <Skeleton variant='rect' className='br-1 mt-2' animation='wave' width={80} height={16} ></Skeleton>
                        </div>
                    </div>
                )
            }
        }

        return skeleton
    }
    console.log(mapaUsers)


    return(
        <>
            <div className={`d-flex flex-column ${matchIpadPro ? ' mt-2 ' : matchIpadMini ? 'ml-8 mr-6 mt-13 mb-6' : matchPhone ? 'mt-11 mx-6' : ''} `}>
                <div className={`d-flex align-items-center justify-content-between ${matchIpadPro ? '' : matchIpadMini ? 'mb-6' : 'mb-4'}`}>
                    <p className={`a-text-medium-first-primary-60 ${matchIpadPro ? 'h3 ml-1' : matchIpadMini ? 'h3' : ''}`}>Colegas</p>
                    <img className={`cursor-pointer ${matchIpadPro ? 'mr-1' : ''}`} onClick={cerrarBusqueda} src={Close} width={matchIpadPro ? 24 : matchIpadMini ? 32 : 20} height={matchIpadPro ? 24 : matchIpadMini ? 32 : 20}></img>
                </div>
                {matchIpadPro ? 
                <>
                </>
                : matchIpadMini ?
                <>
                <div className="hr-nav mb-4"></div>
                <div className={`mb-6`}><Input id='buscar' onChange={buscarSeguidor} autocomplete="off"  className={``} type="search" placeholder={"Buscar colega"} width='100%'  /></div>
                </>
                :
                <>
                <div className="hr-nav mb-4"></div>
                <div className={`mb-4`}><Input id='buscar' onChange={buscarSeguidor} autocomplete="off"  className={``} type="search" placeholder={"Buscar colega"} width='100%'  /></div>
                </>
                }
                <div className={`${matchIpadPro ? 'mt-3' : ''}`}>
                    {loading == false ? 
                    <>
                    {
                        usuarios.length != 0 && searchUsers == false ?
                            usuarios.map(s =>
                                <CardFollower colega={true} id={s.user_id} name={s.name} lastname={s.lastname} area={s.nameArea} empresa={s.company} image={s.photo_link} user_id={userId}></CardFollower>
                            )

                        : searchUsers && usersResults.length!=0 ?
                        usersResults.map(s => 
                            <>
                                <CardFollower colega={true} id={mapaUsers.get(s).user_id} name={mapaUsers.get(s).name} lastname={mapaUsers.get(s).lastname} area={mapaUsers.get(s).nameArea} empresa={mapaUsers.get(s).company} image={mapaUsers.get(s).photo_link} user_id={userId}></CardFollower>
                            </>
                            )
                        :
                        <>
                        <div className={`${matchIpadMini ? 'py-4' : 'py-3'} d-flex flex-column align-items-center border-ocean-30 br-2`}>
                            <p className={`${matchIpadMini ? 'h3' : 'text-small mt-3'} a-text-medium-second-neutral-60`}>No se encontraron resultados para</p>
                            <p className={`${matchIpadMini ? 'h3 mt-3' : 'text-medium mt-3'} a-text-medium-first-primary-60`}>"{inputSearch}"</p>
                        </div>    
                        <img className={`mt-10`} height={matchIpadMini ? 385 : 185} src={NotFound}></img>
                        </>
                    }
                    </>
                    :
                    cardSkeleton()    
                }
                </div>
                
            </div>
        </>
    )
}