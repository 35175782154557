import { useEffect, useState } from "react";

//API
import {
  getUserApi,
  getProfile,
  getFollowersInfo,
  getFollowsInfo,
  getFollowers,
  getFollows,
} from "../../../api/profile";

// Material
import { useMediaQuery, Grid, ListSubheader } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Componens
import { Input } from "../../atoms/Input";
import TabMenu from "../../molecules/TabMenu/Followers/followers";
import CardFollower from "../../molecules/Card/CardFollower/index";

// Images
import User from "../../../assets/img/user-icon.svg";
import Birrete from "../../../assets/img/profile/academica.svg";
import Descripcion from "../../../assets/img/profile/descripcion.svg";
import Empresa from "../../../assets/img/profile/empresa.svg";
import Camara from "../../../assets/img/profile/camara 1.svg";
import Area from "../../../assets/img/profile/area.svg";
import Idiomas from "../../../assets/img/profile/idiomas.svg";
import Close from "../../../assets/img/closeFilter.svg";
import NotFound from "../../../assets/img/sin-resultados-1.svg";

export default function FollowersList({ userId, other_id, selected }) {
  const [profile, setProfile] = useState([]);
  const [useA, setUseA] = useState([]);
  const [useR, setUseR] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectseguidores, setSelectSeguidores] = useState(true);
  const [selectseguidos, setSelectSeguidos] = useState(false);

  const [seguidores, setSeguidores] = useState();
  const [seguidos, setSeguidos] = useState();

  const [mapaSeguidores, setMapaSeguidores] = useState();
  const [mapaSeguidos, setMapaSeguidos] = useState();
  const [search, setSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [erase, setErase] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [lista, setLista] = useState(true);

  const [listaSeguidores, setListaSeguidores] = useState([]);
  const [listaSeguidos, setListaSeguidos] = useState([]);
  const [namesSeguidores, setNamesSeguidores] = useState();
  const [namesSeguidos, setNamesSeguidos] = useState();

  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    validateProfile();
    async function getProfileFromUser() {
      const response = getUserApi(other_id);
      setLoading(true);
      response
        .then((result) => {
          if (result.status === 200) {
            setUseR(result.data.user);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      const profileD = getProfile(other_id);
      profileD
        .then((result) => {
          if (result.status === 200) {
            setProfile(result.data.profilesUser);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    assignSelection();
    getProfileFromUser();
    recuperarSeguidores();
    recuperarSeguidos();
    recuperarListaSeguidores();
    recuperarListaSeguidos();
  }, []);

  async function validateProfile() {
    if (other_id == "none") {
      other_id = userId;
    }
  }

  async function assignSelection() {
    if (selected == "seguidores") {
      setSelectSeguidores(true);
      setSelectSeguidos(false);
    } else {
      setSelectSeguidores(false);
      setSelectSeguidos(true);
    }
    setTimeout(() => {
      setLoading2(false);
    }, 500);
  }

  function handleSeguidores(e) {
    e.preventDefault();
    setLoading2(true);
    setSelectSeguidores(true);
    setSelectSeguidos(false);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
    resetSearch();
  }

  function handleSeguidos(e) {
    e.preventDefault();
    setLoading2(true);
    setSelectSeguidores(false);
    setSelectSeguidos(true);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
    resetSearch();
  }

  function resetSearch() {
    if (search == true) {
      setSearch(false);
      setResults([]);
      setErase(true);
    }
  }

  function eraseSearch() {
    document.getElementById("buscar").value = "";
    setErase(false);
  }

  function handleBack(e) {
    e.preventDefault();
    window.history.back();
  }

  async function recuperarListaSeguidores() {
    try {
      let mapa = new Map();
      let mapa2 = new Map();
      const { data: { followersInfo } } = await getFollowersInfo(other_id);
      setListaSeguidores(followersInfo);
      if (followersInfo.length != 0) {
        followersInfo.forEach((element) => {
          let name = element.name + " " + element.lastname;
          name = name.toLowerCase();
          mapa.set(element.user_following_id, name);
          mapa2.set(element.user_following_id, element);
        });
        setNamesSeguidores(mapa);
        setMapaSeguidores(mapa2);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function buscarSeguidor(e) {
    e.preventDefault();
    setSearch(true);
    let input = "" + e.target.value;
    let keys = [];
    namesSeguidores.forEach((valor, clave) => {
      if (valor.includes(input.toLowerCase())) {
        keys.push(clave);
      }
    });
    setResults(keys);
  }

  function izquierdaSeguidoresMosaico(arraytoWork) {
    let izquierda = [];
    let index = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      index = index + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={mapaSeguidores.get(arraytoWork[i]).user_following_id}
              name={mapaSeguidores.get(arraytoWork[i]).name}
              lastname={mapaSeguidores.get(arraytoWork[i]).lastname}
              area={mapaSeguidores.get(arraytoWork[i]).nameArea}
              empresa={mapaSeguidores.get(arraytoWork[i]).company}
              image={mapaSeguidores.get(arraytoWork[i]).photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    } else {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_following_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    }

    return izquierda;
  }

  function izquierdaSeguidosMosaico(arraytoWork) {
    let izquierda = [];
    let index = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      index = index + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={mapaSeguidos.get(arraytoWork[i]).user_followed_id}
              name={mapaSeguidos.get(arraytoWork[i]).name}
              lastname={mapaSeguidos.get(arraytoWork[i]).lastname}
              area={mapaSeguidos.get(arraytoWork[i]).nameArea}
              empresa={mapaSeguidos.get(arraytoWork[i]).company}
              image={mapaSeguidos.get(arraytoWork[i]).photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    } else {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_followed_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    }
    return izquierda;
  }

  function derechaSeguidoresMosaico(arraytoWork) {
    let derecha = [];
    let start = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      start = start + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_following_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    } else {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_following_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    }
    return derecha;
  }

  function derechaSeguidosMosaico(arraytoWork) {
    let derecha = [];
    let start = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      start = start + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={mapaSeguidos.get(arraytoWork[i]).user_followed_id}
              name={mapaSeguidos.get(arraytoWork[i]).name}
              lastname={mapaSeguidos.get(arraytoWork[i]).lastname}
              area={mapaSeguidos.get(arraytoWork[i]).nameArea}
              empresa={mapaSeguidos.get(arraytoWork[i]).company}
              image={mapaSeguidos.get(arraytoWork[i]).photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    } else {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_followed_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>
        );
      }
    }
    return derecha;
  }

  function buscarSeguido(e) {
    e.preventDefault();
    setSearch(true);
    let input = "" + e.target.value;
    let keys = [];
    namesSeguidos.forEach((valor, clave) => {
      if (valor.includes(input.toLowerCase())) {
        keys.push(clave);
      }
    });
    setResults(keys);
  }

  async function recuperarListaSeguidos() {
    try {
      let mapa = new Map();
      let mapa2 = new Map();
      const response = await getFollowsInfo(other_id);
      setListaSeguidos(response.data.FollowsInfo);
      if (response.data.FollowsInfo.length != 0) {
        response.data.FollowsInfo.forEach((element) => {
          let name = element.name + " " + element.lastname;
          name = name.toLowerCase();
          mapa.set(element.user_followed_id, name);
          mapa2.set(element.user_followed_id, element);
        });
        setNamesSeguidos(mapa);
        setMapaSeguidos(mapa2);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function recuperarSeguidores() {
    try {
      const response = await getFollowers(other_id);
      console.log(response.data)
      setSeguidores(response.data.countFollower);
    } catch (error) {
      console.log(error);
    }
  }

  async function recuperarSeguidos() {
    try {
      const response = await getFollows(other_id);
      setSeguidos(response.data.countFollows);
    } catch (error) {
      console.log(error);
    }
  }

  function cardSkeleton(type) {
    let skeleton = [];

    if (type == "lista") {
      for (var i = 0; i < 3; i++) {
        if (matchIpadMini) {
          skeleton.push(
            <div className={`body-card br-2 d-flex px-4 py-7 mb-3`}>
              <Skeleton
                variant="circle"
                animation="wave"
                width={35}
                height={32}
              ></Skeleton>
              <Grid
                container
                spacing={0}
                className={`d-flex align-items-center ml-4`}
              >
                <Grid item xs={6}>
                  <Skeleton
                    variant="rect"
                    className="br-1"
                    animation="wave"
                    width={110}
                    height={21}
                  ></Skeleton>
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="rect"
                    className="br-1"
                    animation="wave"
                    width={80}
                    height={21}
                  ></Skeleton>
                </Grid>
              </Grid>
            </div>
          );
        } else {
          skeleton.push(
            <div className={`body-card br-2 d-flex px-3 py-2 mb-2`}>
              <Skeleton
                variant="circle"
                animation="wave"
                width={24}
                height={24}
              ></Skeleton>
              <div className={`d-flex flex-column ml-3`}>
                <Skeleton
                  variant="rect"
                  className="br-1"
                  animation="wave"
                  width={110}
                  height={16}
                ></Skeleton>
                <Skeleton
                  variant="rect"
                  className="br-1 mt-2"
                  animation="wave"
                  width={80}
                  height={16}
                ></Skeleton>
              </div>
            </div>
          );
        }
      }
    } else {
      for (let index = 0; index < 2; index++) {
        skeleton.push(
          <div className={`body-card br-2 d-flex mx-1 px-3 py-2 mb-3 h-min-46`}>
            <Skeleton
              variant="circle"
              animation="wave"
              width={32}
              height={32}
            ></Skeleton>
            <div className={`d-flex flex-column ml-3`}>
              <Skeleton
                variant="rect"
                className="br-1"
                animation="wave"
                width={110}
                height={19}
              ></Skeleton>
              <Skeleton
                variant="rect"
                className="br-1 mt-2"
                animation="wave"
                width={80}
                height={19}
              ></Skeleton>
            </div>
          </div>
        );
      }
    }

    return skeleton;
  }

  setTimeout(() => {
    if (profile != undefined) {
      setUseA(profile);
      if (useA.length != 0) {
        setLoading(false);
      }
    } else {
      setUseA(useR);
      if (useA.length != 0) {
        setLoading(false);
      }
    }
  }, 1);

  return (
    <>
      {loading == false && (
        <Grid className={`${matchIpadMini ? "mx-7 mt-13" : ""}`}>
          <div
            className={`${
              matchPhone ? "mx-6 mt-11" : ""
            } d-flex justify-content-between`}
          >
            <p
              className={` ${
                matchIpadMini
                  ? "a-text-bold-first-primary-60 h3"
                  : "a-text-medium-first-primary-60 text-normal"
              }`}
            >
              {useA[0].name} {useA[0].lastname}
            </p>
            <img
              className={`cursor-pointer`}
              onClick={handleBack}
              width={matchIpadMini ? 24 : 20}
              height={matchIpadMini ? 24 : 20}
              src={Close}
            ></img>
          </div>
          <TabMenu
            resetSearch={resetSearch}
            setLoading2={setLoading2}
            lista={lista}
            setDisplay={setLista}
            opttwo={selectseguidores}
            optthird={selectseguidos}
            fun1={handleSeguidores}
            fun2={handleSeguidos}
            fun3={setErase}
            setOptTwo={setSelectSeguidores}
            setOptThird={setSelectSeguidos}
            seguidores={seguidores}
            seguidos={seguidos}
          />

          {selectseguidores == true ? (
            <>
              {seguidores != 0 && loading2 == false ? (
                <>
                  <div className={`${matchPhone ? "mx-6" : ""} `}>
                    <Input
                      id="buscar"
                      autocomplete="off"
                      onChange={(e) => buscarSeguidor(e)}
                      className={`${matchIpadMini ? "mt-9" : "mt-3"}`}
                      type="search"
                      placeholder={"Buscar a quién sigues"}
                      width="100%"
                    />
                  </div>
                  {search == false && lista ? (
                    <div
                      className={`d-flex ${
                        matchPhone ? "mx-6 mt-3" : "mt-6"
                      } flex-column`}
                    >
                      {listaSeguidores.map((s) => (
                        <>
                          <CardFollower
                            id={s.user_following_id}
                            name={s.name}
                            lastname={s.lastname}
                            area={s.nameArea}
                            empresa={s.company}
                            image={s.photo_link}
                            user_id={userId}
                          ></CardFollower>
                        </>
                      ))}
                    </div>
                  ) : search == false && lista == false ? (
                    <Grid container spacing={0} className={`d-flex`}>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex mr-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {izquierdaSeguidoresMosaico(listaSeguidores)}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex ml-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {listaSeguidores.length > 1 &&
                            derechaSeguidoresMosaico(listaSeguidores)}
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <div
                      className={`d-flex ${
                        matchPhone ? "mx-6 mt-3" : "mt-6"
                      } flex-column`}
                    >
                      {results.length != 0 && lista ? (
                        results.map((s) => (
                          <>
                            <CardFollower
                              id={mapaSeguidores.get(s).user_following_id}
                              name={mapaSeguidores.get(s).name}
                              lastname={mapaSeguidores.get(s).lastname}
                              area={mapaSeguidores.get(s).nameArea}
                              empresa={mapaSeguidores.get(s).company}
                              image={mapaSeguidores.get(s).photo_link}
                              user_id={userId}
                            ></CardFollower>
                          </>
                        ))
                      ) : results.length != 0 && lista == false ? (
                        <Grid container spacing={0} className={`d-flex`}>
                          <Grid item xs={6}>
                            <div className={`d-flex mr-3 flex-column`}>
                              {izquierdaSeguidoresMosaico(results)}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={`d-flex ml-3 flex-column`}>
                              {results.length > 1 &&
                                derechaSeguidosMosaico(results)}
                            </div>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <div
                            className={`${
                              matchIpadMini ? "py-4" : "py-3"
                            } d-flex flex-column align-items-center border-ocean-30 br-2`}
                          >
                            <p
                              className={`${
                                matchIpadMini ? "h3" : "text-small mt-3"
                              } a-text-medium-second-neutral-60`}
                            >
                              No se encontraron resultados para
                            </p>
                            <p
                              className={`${
                                matchIpadMini ? "h3 mt-3" : "text-medium mt-3"
                              } a-text-medium-first-primary-60`}
                            >
                              "{document.getElementById("buscar").value}"
                            </p>
                          </div>
                          <img
                            className={`mt-10`}
                            height={matchIpadMini ? 385 : 185}
                            src={NotFound}
                          ></img>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : seguidores == 0 ? (
                <div className={`d-flex flex-column`}>
                  <p
                    className={`${
                      matchIpadMini ? "h3 mt-12" : "text-small mt-6"
                    } a-text-medium-second-neutral-70 align-self-center`}
                  >
                    Seguidores
                  </p>
                  <p
                    className={`${
                      matchIpadMini
                        ? "text-medium line-height-24 mt-4"
                        : "text-small line-height-21 mt-2"
                    } text-center align-self-center a-text-regular-second-neutral-70`}
                  >
                    Cuando te comiencen a seguir, tus colegas<br></br>aparecerán
                    aquí
                  </p>
                </div>
              ) : (
                <>
                  <div className={`${matchPhone ? "mx-6" : ""}`}>
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={matchPhone ? 29 : 35}
                      animation="wave"
                      className={` ${matchPhone ? "mt-3" : "mt-9"} br-7`}
                    ></Skeleton>
                  </div>
                  {lista ? (
                    <div
                      className={`d-flex ${
                        matchPhone ? "mx-6 mt-3" : "mt-6"
                      } flex-column`}
                    >
                      {cardSkeleton("lista")}
                    </div>
                  ) : (
                    <Grid container spacing={0} className={`d-flex`}>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex mr-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {cardSkeleton("mosaico")}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex ml-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {cardSkeleton("mosaico")}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {seguidos != 0 && loading2 == false ? (
                <>
                  <div className={`${matchPhone ? "mx-6" : ""} `}>
                    <Input
                      id="buscar"
                      autocomplete="off"
                      onChange={(e) => buscarSeguido(e)}
                      className={`${matchIpadMini ? "mt-9" : "mt-3"}`}
                      type="search"
                      placeholder={"Buscar a quién sigues"}
                      width="100%"
                    />
                  </div>
                  {search == false && lista ? (
                    <div
                      className={`d-flex ${
                        matchPhone ? "mx-6 mt-3" : "mt-6"
                      } flex-column`}
                    >
                      {listaSeguidos.map((s) => (
                        <>
                          <CardFollower
                            id={s.user_followed_id}
                            name={s.name}
                            lastname={s.lastname}
                            area={s.nameArea}
                            empresa={s.company}
                            image={s.photo_link}
                            user_id={userId}
                          ></CardFollower>
                        </>
                      ))}
                    </div>
                  ) : search == false && lista == false ? (
                    <Grid container spacing={0} className={`d-flex`}>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex mr-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {izquierdaSeguidosMosaico(listaSeguidos)}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex ml-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {listaSeguidos.length > 1 &&
                            derechaSeguidosMosaico(listaSeguidos)}
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <div
                      className={`d-flex ${
                        matchPhone ? "mx-6 mt-3" : "mt-6"
                      } flex-column`}
                    >
                      {results.length != 0 && lista == true ? (
                        results.map((s) => (
                          <>
                            <CardFollower
                              id={mapaSeguidos.get(s).user_followed_id}
                              name={mapaSeguidos.get(s).name}
                              lastname={mapaSeguidos.get(s).lastname}
                              area={mapaSeguidos.get(s).nameArea}
                              empresa={mapaSeguidos.get(s).company}
                              image={mapaSeguidos.get(s).photo_link}
                              user_id={userId}
                            ></CardFollower>
                          </>
                        ))
                      ) : results.length != 0 && lista == false ? (
                        <Grid container spacing={0} className={`d-flex`}>
                          <Grid item xs={6}>
                            <div className={`d-flex mr-3 flex-column`}>
                              {izquierdaSeguidosMosaico(results)}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={`d-flex ml-3 flex-column`}>
                              {results.length > 1 &&
                                derechaSeguidosMosaico(results)}
                            </div>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <div
                            className={`${
                              matchIpadMini ? "py-4" : "py-3"
                            } d-flex flex-column align-items-center border-ocean-30 br-2`}
                          >
                            <p
                              className={`${
                                matchIpadMini ? "h3" : "text-small mt-3"
                              } a-text-medium-second-neutral-60`}
                            >
                              No se encontraron resultados para
                            </p>
                            <p
                              className={`${
                                matchIpadMini ? "h3 mt-3" : "text-medium mt-3"
                              } a-text-medium-first-primary-60`}
                            >
                              "{document.getElementById("buscar").value}"
                            </p>
                          </div>
                          <img
                            className={`mt-10`}
                            height={matchIpadMini ? 385 : 185}
                            src={NotFound}
                          ></img>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : seguidos == 0 ? (
                <div className={`d-flex flex-column`}>
                  <p
                    className={`${
                      matchIpadMini ? "h3 mt-12" : "text-small mt-6"
                    } a-text-medium-second-neutral-70 align-self-center`}
                  >
                    Aún no sigues a nadie
                  </p>
                  <p
                    className={`${
                      matchIpadMini
                        ? "text-medium line-height-24 mt-4"
                        : "text-small line-height-21 mt-2"
                    } text-center align-self-center a-text-regular-second-neutral-70`}
                  >
                    Cuando comiences a seguir a un colega<br></br>aparecerá aquí
                  </p>
                </div>
              ) : (
                <>
                  <div className={`${matchPhone ? "mx-6" : ""}`}>
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={matchPhone ? 29 : 35}
                      animation="wave"
                      className={` ${matchPhone ? "mt-3" : "mt-9"} br-7`}
                    ></Skeleton>
                  </div>
                  {lista ? (
                    <div
                      className={`d-flex ${
                        matchPhone ? "mx-6 mt-3" : "mt-6"
                      } flex-column`}
                    >
                      {cardSkeleton("lista")}
                    </div>
                  ) : (
                    <Grid container spacing={0} className={`d-flex`}>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex mr-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {cardSkeleton("mosaico")}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className={`d-flex ml-3 ${
                            matchPhone ? "mx-6 mt-3" : "mt-6"
                          } flex-column`}
                        >
                          {cardSkeleton("mosaico")}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
          {erase == true && eraseSearch()}
        </Grid>
      )}
    </>
  );
}
