import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Smartlook from "smartlook-client";

//import { createStore} from 'redux'
//Style
import "./App.scss";
// prueba
//Routes
import routes from "./config/routes";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

function App() {
  useEffect(() => {
    Smartlook.init("d510683fd15f87cdb2ecacb240ba3e1a169692ab");
  }, []);

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </Router>
      </MuiThemeProvider>
    </>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component routes={route.routes} {...props} />}
    />
  );
}

export default App;
