//React
import { useState } from "react";
import { Link } from "react-router-dom";

// Materiali
import { useMediaQuery, Grid } from "@material-ui/core";

//Images
import Pdf from "../../../../assets/img/pdf.svg";
import Pencil from "../../../../assets/img/icon/pencil-blue.svg";
import Trash from "../../../../assets/img/icon/trash-blue.svg";
import Compartir from "../../../../assets/img/icon/compartir.svg";
import CompartirGris from "../../../../assets/img/icon/compartir-gris.svg";
import PDFIcon from "../../../../assets/img/pdf-icon-ocean.svg";
import Correo from "../../../../assets/img/social/correo.svg";
import Whats from "../../../../assets/img/social/whats.svg";

export default function CardReport({
  noEdit,
  name,
  linkPdf,
  linkPtt,
  id,
  onClick,
  linkEdit,
  date,
}) {
  // State
  const [isShare, setIsShare] = useState(false);
  const ipadProMatch = useMediaQuery("(min-width: 1024px)");
  const reportDate = date.slice(0, 10);
  const dateSeparated = reportDate.split("-");
  const dateOrdered = `${dateSeparated[2]}/${
    dateSeparated[1]
  }/${dateSeparated[0].slice(2)}`;

  return (
    <Grid container justify="center" spacing={0} className="mb-3">
      <Grid item xs={12} className="box-shadow-all br-1 px-2 pt-2">
        <a
          href={linkPdf}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <div className="background-first-primary-10 p-2 br-1 d-flex">
            <img src={Pdf} alt="PDF" />
            <div>
              <p
                className={`${
                  ipadProMatch ? "text-big" : "text-small"
                } ml-3 a-text-regular-second-primary-70`}
              >
                {name.replace("&amp;", "&")}
              </p>
              <p className=" text-small ml-3 a-text-regular-second-primary-70 mt-1">
                {dateOrdered}
              </p>
            </div>
          </div>
        </a>
        { noEdit ?  <div className="d-flex justify-content-between p-2">
        <div className={"d-flex"}>
          <img
            src={CompartirGris}
            alt="Compartir"
            width={`16px`}
            className="mr-3 share-img"
          />
          <a href={linkPdf} rel="noreferrer" target="_blank" className="">
            <img
              src={PDFIcon}
              alt="Compartir"
              width={`19px`}
              className="cursor-pointer mr-3"
            />
          </a>
          <a
            href={`https://api.whatsapp.com/send?text= Reporte: ${name.replace(
              "&amp;",
              "&"
            )}%0aLink de descarga: ${linkPdf}`}
            target="_blank"
          >
            <img
              src={Whats}
              alt="Compartir"
              width={`20px`}
              className="cursor-pointer mr-3"
            />
          </a>
          <a
            href={`mailto:?subject=Reporte: ${name.replace(
              "&amp;",
              "&"
            )}&body=Reporte:%20${name.replace(
              "&amp;",
              "&"
            )}%0D%0ALink%20de%20descarga:%20${linkPdf}%3A`}
            target="_blank"
          >
            <img
              src={Correo}
              alt="Compartir"
              className="cursor-pointer mr-3"
            />
          </a>
        </div>
      </div> 
       : ipadProMatch  ? (
        <div className="d-flex justify-content-between p-2">
          <div className={"d-flex"}>
            <img
              src={CompartirGris}
              alt="Compartir"
              width={`16px`}
              className="mr-3 share-img"
            />
            <a href={linkPdf} rel="noreferrer" target="_blank" className="">
              <img
                src={PDFIcon}
                alt="Compartir"
                width={`19px`}
                className="cursor-pointer mr-3"
              />
            </a>
            <a
              href={`https://api.whatsapp.com/send?text= Reporte: ${name.replace(
                "&amp;",
                "&"
              )}%0aLink de descarga: ${linkPdf}`}
              target="_blank"
            >
              <img
                src={Whats}
                alt="Compartir"
                width={`20px`}
                className="cursor-pointer mr-3"
              />
            </a>
            <a
              href={`mailto:?subject=Reporte: ${name.replace(
                "&amp;",
                "&"
              )}&body=Reporte:%20${name.replace(
                "&amp;",
                "&"
              )}%0D%0ALink%20de%20descarga:%20${linkPdf}%3A`}
              target="_blank"
            >
              <img
                src={Correo}
                alt="Compartir"
                className="cursor-pointer mr-3"
              />
            </a>
          </div>
          <div className="d-flex">
            <Link to={linkEdit} className="text-decoration-none d-flex">
              <img
                src={Pencil}
                alt="Pencil"
                className="mr-3 cursor-pointer"
              />
            </Link>
            <img
              src={Trash}
              alt="Trash"
              className="cursor-pointer"
              onClick={() => onClick(id)}
            />
          </div>
        </div>
      ) : (
          <div className="d-flex justify-content-between p-2">
            <img
              src={Compartir}
              alt="Compartir"
              className={isShare ? "d-none" : "cursor-pointer"}
              onClick={() => setIsShare(true)}
            />
            <div
              className={!isShare ? "d-none" : "d-flex"}
              onClick={() => setIsShare(false)}
            >
              <img
                src={Compartir}
                alt="Compartir"
                className="cursor-pointer mr-2"
              />
              <a href={linkPdf} rel="noreferrer" target="_blank" className="">
                <img
                  src={PDFIcon}
                  alt="Compartir"
                  width={`18px`}
                  className="cursor-pointer mr-2 pdf-img"
                />
              </a>
              <a
                href={`https://api.whatsapp.com/send?text= Reporte: ${name.replace(
                  "&amp;",
                  "&"
                )}%0aLink de descarga: ${linkPdf}`}
                target="_blank"
              >
                <img
                  src={Whats}
                  width={`20px`}
                  alt="Compartir"
                  className="cursor-pointer mr-2"
                />
              </a>
              <a
                href={`mailto:?subject=Reporte: ${name.replace(
                  "&amp;",
                  "&"
                )}&body=Reporte:%20${name.replace(
                  "&amp;",
                  "&"
                )}%0D%0ALink%20de%20descarga:%20${linkPdf}%3A`}
                target="_blank"
              >
                <img
                  src={Correo}
                  alt="Compartir"
                  className="cursor-pointer mr-2"
                />
              </a>
            </div>
            <div className="d-none">
              <Link to={linkEdit} className="text-decoration-none d-flex">
                <img
                  src={Pencil}
                  alt="Pencil"
                  className="mr-2 cursor-pointer"
                />
              </Link>
              <img
                src={Trash}
                alt="Trash"
                className="cursor-pointer"
                onClick={() => onClick(id)}
              />
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}
