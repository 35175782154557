import React from "react";

import { Input } from "../../../atoms/Input";
import { useMediaQuery } from "@material-ui/core/";

export function InputWithLabel({
  text,
  id,
  placeholder,
  type,
  focus,
  value,
  onChange,
  maxlength,
  classLabel,
  data,
  onClick,
  className,
  seeMoreButton,
  limit,
  name,
  warning,
  config,
  setIndustriasActivas,
  setIndustria,
  setSubtech,
  setSubtechActivas,
  checked = true,
  sectorChanged,
  technologyChanged,
  handleChangeInactive,
  disabled,
  dateChanged,
  foundationChange,
  selectClass,
  sizeChanged,
  paisesChanged,
  userChanged,
  estadosChanged,
  height,
  width = 100,
  handleClick
}) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <label
        className={`${type === "checkboxIcon" ? "w-100" : ""}`}
        htmlFor={id}
      >
        <p
          className={`pl-lg-1 ${
            type === "checkboxIcon"
              ? `mb-lg-3 ${
                  config
                    ? "a-text-medium-first-primary-60  text-md-big mb-lg-4"
                    : "a-text-medium-first-primary-60 text-md-medium"
                }   mb-md-4  mb-3  ${ipadMiniMatch ? " mb-3" : ""}`
              : "a-text-medium-second-neutral-70"
          } mb-md-2  ${classLabel} 
          ${
            checked
              ? "a-text-medium-second-neutral-70"
              : "a-text-medium-first-neutral-80"
          }
          `}
        >
          {text}
        </p>
      </label>
      <Input
        className={className}
        placeholder={placeholder}
        width={width}
        id={id}
        maxlength={maxlength}
        type={type}
        focus={focus}
        value={value}
        onChange={onChange}
        data={data}
        onClick={onClick}
        seeMoreButton={seeMoreButton}
        limit={limit}
        name={name}
        warning={warning}
        setIndustriasActivas={setIndustriasActivas}
        setIndustria={setIndustria}
        setSubtech={setSubtech}
        setSubtechActivas={setSubtechActivas}
        sectorChanged={sectorChanged}
        technologyChanged={technologyChanged}
        handleChangeInactive={handleChangeInactive}
        disabled={disabled}
        dateChanged={dateChanged}
        foundationChange={foundationChange}
        sizeChanged={sizeChanged}
        paisesChanged={paisesChanged}
        estadosChanged={estadosChanged}
        userChanged={userChanged}
        selectClass={selectClass}
        height={height}
        handleClick={handleClick}
      />
    </>
  );
}
