// React

//Material UI
import { useMediaQuery } from '@material-ui/core';


// Images
import Proximamente from "../../../../assets/img/ilustraciones-home-carso/proximamente.svg";

export default function CardHome({ text1 = null, text2 = null, title=null}) {
    const matchPhone = useMediaQuery("(max-width: 767px)")
    return (
        <>
        {matchPhone ? 
          <div className=" px-7 ml-2 ">
              <div className= "d-flex flex-column w-100">
              <div  className='br-3 box-shadow-all w-100  '>
                <img className='question-img-card w-100'  src={Proximamente}></img>
                <div className={`d-flex justify-content-center`}>
                    <p className={`a-text-regular-first-primary-60 text-small mt-2 pb-3 line-height-18 text-center`}>{text1} {text2 != null ? <br></br> : ''} {text2 != null ? text2 : ''} </p>
                </div>
            </div>
              </div>
            
             </div>
        :
            <div className={`d-flex flex-column br-3 box-shadow-all bck-color-white mt-6 py-8 px-8`}>
                <p className={`a-text-medium-first-primary-60 h3 mb-3`}>{title}</p>
                <div className={`d-flex`}>
                    <div style={{maxWidth: '261px' }} className='br-3 box-shadow-all br-b-3'>
                        <img className='question-img-card' width={261} height={137} src={Proximamente}></img>
                        <div className={`d-flex justify-content-center bck-color-white br-b-3`} style={{minHeight: '53px' }}>
                        </div>
                    </div>
                    <div className={`ml-4 w-100`}>
                        <div style={{minHeight: '40px' }} className={`prox-card d-flex justify-content-center mb-3 w-100`}>
                                <div style={{minHeight: '36px' }} className={`inner-prox-card d-flex align-items-center`}>
                                <p className={`a-text-regular-second-primary-50 h3`}>¡Próximamente!</p>
                                </div>
                        </div>
                        <p className={`a-text-regular-first-primary-60 text-small mt-7 pb-3 line-height-18 text-center`}>{text1} {text2 != null ? <br></br> : ''} {text2 != null ? text2 : ''} </p>
                    </div>
                </div>
            </div>
        }
        </>
    )
}