import { CircularProgress, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import CloseWhiteIcon from "../../../assets/img/company/closeWhite.svg";
import FileIcon from "../../../assets/img/company/archive.svg";
import Download from "../../../assets/img/company/Download.svg";
import PdfIconOcean from "../../../assets/img/company/PdfIconOcean.svg";
import XlsOceanIcon from "../../../assets/img/company/XlsOceanIcon.svg";
import { PDF_PATH } from "../../../api/config";
import { ExcelSearchReport } from "../../../pages/CID/Company/SearchCompany/ExcelSearchReport";

export const DownloadReportsModal = ({
  setShowModal,
  filteredCompanies,
  companiesBySector,
  companiesByIndustry,
  companiesByTech,
  companiesByMercado,
  companiesBySubtech,
  companiesBySede,
  companiesByAlliance,
  companiesByFounder,
  companiesByInvestor,
  setArrIds,
  companiesForPdf
}) => {
  const [excel, setExcel] = useState(false);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between background-blue pl-4 pr-3 border-radius-download ">
          <p className="align-self-center a-text-medium-third-neutral text-big">
            Preparando Descarga
          </p>
          <IconButton className="py-3">
            <img
              src={CloseWhiteIcon}
              className="width-close-download"
              alt="close icon"
              onClick={() => setShowModal((prevState) => !prevState)}
            />
          </IconButton>
        </div>
        <div className="px-3 my-4">
          <div>
            <div className="a-text-medium-first-primary-60 d-flex justify-content-between mb-2 box-shadow-first-neutral-50 p-2">
              <div className="d-flex">
                <img src={PdfIconOcean} alt="" className="mr-4"/>
                <p className="align-self-center text-big a-text-medium-first-neutral-100">
                  Generar One Page's
                </p>
              </div>
               <a href={`${PDF_PATH}/get-one-pages-report?company_id=(${companiesForPdf})}`} rel="noreferrer" target="_blank" className="d-flex align-items-center">
               <IconButton>
                <img src={Download} alt=""/>
              </IconButton>
                </a>
            </div>

            <div className="a-text-medium-first-primary-60 d-flex justify-content-between align-items-center box-shadow-first-neutral-50 p-2">
              <div className="d-flex">
                <img src={XlsOceanIcon} alt="" className="mr-4" />
                <p className="align-self-center text-big a-text-medium-first-neutral-100">
                  Generar lista de empresas
                </p>
              </div>
              <ExcelSearchReport
                filteredCompanies={filteredCompanies}
                companiesBySector={companiesBySector}
                companiesByIndustry={companiesByIndustry}
                companiesByTech={companiesByTech}
                companiesByMercado={companiesByMercado}
                companiesBySubtech={companiesBySubtech}
                companiesBySede={companiesBySede}
                companiesByAlliance={companiesByAlliance}
                companiesByFounder={companiesByFounder}
                companiesByInvestor={companiesByInvestor}
                setArrIds={setArrIds}
                companiesForPdf={companiesForPdf}
              />
            </div>
          </div>

          {/* <div id="modalDownload" className="align-self-center">
              {
                  fetchingData ? <CircularProgress /> : error ? <img src={FailIcon} alt="icono de fallo" /> : <img src={SuccessIcon} alt="icono de exito" />
              }
          </div> */}
        </div>
      </div>
    </>
  );
};
