// React
import React, { useEffect, useState } from "react";

// Material
import { Grid, useMediaQuery, Modal } from "@material-ui/core";

// API
import {
  deleteQuestion,
  getTopicsByUserId,
  getQuestionFiltered,
  getTopics,
  updateTopicbyUser,
  getTopicsByquestion,
  getQuestionById
} from "../../../api/questions";

// Components
import { Input } from "../../atoms/Input";

import CardQuestion from "../../molecules/Card/CardQuestion/index";
import SkeletonCardQuestion from "../../molecules/Skeleton/SkeletonQuestions/SkeletonCardQuestion";
import SkeletonTextInput from "../../molecules/Skeleton/SkeletonQuestions/SkeletonTextTopics";
import ModalSuccess from "../../molecules/Modal/SuccessModal";
import EmptyStateFilter from "../../molecules/EmptyState/EmptyStateQuestions/EmptyFilterState";
import { WarningModal } from "../../molecules/Modal/WarningModal";
import useAuth from "../../../hooks/useAuth";
let all = { topics_id: 10, name: "Todas" };
let allCompare = { count: 20, topics_id: 10 };

let allForAll = { topics: { topics_id: 10, name: "Todas" }, compare: 20 }

export function QuestionList({ userId, searchedQuestions, loadingSearch }) {


  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const [load, setLoad] = useState(true);
  const [loadInput, setLoadInput] = useState(true);
  const [currentData, setCurrentData] = useState([]);
  const [inputData, setInputData] = useState([]);
  // const [filteredTopic, setFilteredTopic] = useState({topics_id:10,name:''})
  const [deletedQ, setDeletedQ] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);
  const [openWarning, setOpenWarning] = useState(false);
  const [question, setQuestion] = useState([]);
  const [topics, setTopics] = useState([]);

  const [filteredTopics, setFilteredTopics] = useState([10]);
  const [compare, setCompare] = useState([allCompare]);
  const [allTopics, setAllTopics] = useState([])
  const [loadInputAll, setLoadInputAll] = useState(true)

  async function deleteObject(questionId, type) {
    try {
      setOpen(true);
      const deleted = await deleteQuestion(questionId);

      if (deleted.status == 200) {
        setLoadDelete(false);
        setTimeout(() => {
          setOpen(false);
          setDeletedQ(!deletedQ);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function sortCompare(a, b) {
    if (a.compare > b.compare) {
      return -1;
    }
    if (a.compare < b.compare) {
      return 1;
    }
    return 0;
  }
  function sortTopics(a, b) {
    if (a.count > b.count) {
      return -1;
    }
    if (a.count < b.count) {
      return 1;
    }
    return 0;
  }


  useEffect(() => {
    const getAllTopics = async () => {
      setLoadInputAll(true);
      try {
        const { data: { topics } } = await getTopics(userId);
        let aux = topics
        aux.sort(sortCompare)
        aux.unshift(allForAll)
        setAllTopics(aux)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadInputAll(false);
      }
    }
    getAllTopics()
  }, [])

  useEffect(() => {
    const getTopicsByUser = async () => {
      setLoadInput(true);
      try {
        const { data } = await getTopicsByUserId(userId);
        if (data) {
          const { topics: { topics, compare } } = data
          if (topics.length > 0) {
            let newArr = topics.map((topicsState, i) => ({
              topics_id: topicsState.topics_id,
              name: topicsState.name,
              count: compare[i].count
            }))
            setTopics(newArr);
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoadInput(false);
      }
    }
    getTopicsByUser()
  }, []);

  const getQuestionsFilteredData = async () => {
    setLoad(true);
    try {
      const { data: { questions: { questionFilterd } } } = await getQuestionFiltered(filteredTopics.join());
      setFilterData(questionFilterd);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
  }

  useEffect(() => {
    getQuestionsFilteredData()
  }, [filteredTopics, deletedQ])


  useEffect(() => {
    const temasStorage = JSON.parse(localStorage.getItem('topics_user'))
    if (temasStorage) {
      if (topics.length > 0 && temasStorage.length === 0) {
        const temasDB = topics.map(topicsState => topicsState.topics_id)
        setFilteredTopics(temasDB)
        localStorage.setItem('topics_user', JSON.stringify(temasDB));
      }
      else if (topics.length === 0 && temasStorage.length === 0) {
        setFilteredTopics([10])
        localStorage.setItem('topics_user', JSON.stringify([10]));
      }
    }
  }, [loadInput])


  useEffect(() => {
    const temasStorage = JSON.parse(localStorage.getItem('topics_user'))
    if (!temasStorage) {
      const temasDB = topics.map(topicsState => topicsState.topics_id)
      setFilteredTopics(temasDB)
    } else {
      setFilteredTopics(temasStorage)
    }
  }, [topics])

  useEffect(() => {
    localStorage.setItem('topics_user', JSON.stringify(filteredTopics));
  }, [filteredTopics])


  useEffect(() => {
    const actualizandoQuestionList = async () => {
      if (updated !== 0) {
        getQuestionsFilteredData()
        setUpdated(0)
      }
    }
    actualizandoQuestionList()
  }, [updated])

  return (
    <>

      {loadInput || loadInputAll || load ? (
        <div className="d-flex  mb-4">
          <span className="a-text-regular-first-neutral-100 text-md-medium mt-1 mr-3">Filtrar por:</span>
          <SkeletonTextInput num={6} />
        </div>
      ) : (
        <div className="mb-2 d-flex">
          <span style={{ "white-space": "nowrap" }} className="a-text-regular-first-neutral-100 text-md-medium mt-1 mr-3">Filtrar por:</span>
          <Input
            data={allTopics}
            compare={compare}
            className={"filterCheck"}
            type="checkboxTextTopicsFilter"
            setFilteredTopics={setFilteredTopics}
            filteredTopics={filteredTopics}
          />
        </div>
      )}

      <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <Grid xs={11} lg={12} className="w-100">
          <div></div>

          {
            loadingSearch || load ?
              <SkeletonCardQuestion num={10} />
              :
              searchedQuestions != undefined ?
                searchedQuestions.length == 0 ?
                  filterData?.length === 0 ?
                    (
                      <EmptyStateFilter topic={filteredTopics} data={allTopics} />
                    )
                    :
                    (
                      filterData?.map((res, idx) => (
                        <div className="d-flex w-100" key={idx}>

                          <CardQuestion
                            key={idx}
                            info={res}
                            userId={userId}
                            onUpdateQuestion={setUpdated}
                            updated={updated}
                            setOpenWarning={setOpenWarning}
                            openWarning={openWarning}
                            setQuestion={setQuestion}
                            link={{
                              pathname: "/cid/carso/questions/list/" + res.question_id,
                              state: { info: res },
                            }}
                            multiple={
                              res.images_link === null
                                ? false
                                : res.images_link.includes(",")
                            }
                            CardType={"numeros"}
                            temas={allTopics}
                          />{" "}
                        </div>
                      ))
                    )
                  :
                  (
                    searchedQuestions.map((res, idx) => (
                      <div className="d-flex w-100" key={idx}>
                        <CardQuestion
                          key={idx}
                          info={res}
                          userId={userId}
                          onUpdateQuestion={setUpdated}
                          updated={updated}
                          setOpenWarning={setOpenWarning}
                          openWarning={openWarning}
                          setQuestion={setQuestion}
                          link={{
                            pathname: "/cid/carso/questions/list/" + res.question_id,
                            state: { info: res },
                          }}
                          multiple={
                            res.images_link === null
                              ? false
                              : res.images_link.includes(",")
                          }
                          CardType={"numeros"}
                        />{" "}
                      </div>
                    ))
                  )
                :
                ""
            // Aquí iría la vista para cuando no se encuentran resultados
          }
        </Grid>

        <Modal
          open={open}
          onClose={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal">
            <ModalSuccess
              open={open}
              setOpen={setOpen}
              message={`${loadDelete
                ? "Espera un momento..."
                : "Tu pregunta se ha eliminado"
                }`}
              action={"Timmer"}
            />
          </div>
        </Modal>

        <Modal
          open={openWarning}
          onClose={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal">
            <WarningModal
              message={"Tu pregunta será eliminada"}
              open={openWarning}
              setOpen={setOpenWarning}
              setOpenSuccess={setOpen}
              openSuccess={open}
              deleteObject={deleteObject}
              object={question}
              type={"question"}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
