/**Consumo de endpoint para la creación de los perfiles.
 */

 import { BASE_PATH } from "./config";
 import axios from "axios";
 
 export function createBlog(data) {
     return axios.post(`${BASE_PATH}/blogs/create-blog`, data);
 }

 export function updateBlof(data) {
    return axios.put(`${BASE_PATH}/blogs/update-blog`, data);
}
export function getBlogs(userId) {
    return axios.get(`${BASE_PATH}/blogs/get-blogs`);
}
// export function getFilteredBlogs(userId,dataLabels) {
//     return axios.post(`${BASE_PATH}/blogs/get-filteredBlogs`,{user_id:userId,categories:dataLabels});
// }
export function getBlogsFiltered(categories){
    const categorias = categories.join(",")
    return axios.get(`${BASE_PATH}/blogs/get-filterByBlog?categories=${categorias}`)
}
export function getBlogsByUser(userId) {
	return axios.get(`${BASE_PATH}/blogs/get-BlogsByUser?userId=${userId}`);
}
export function getProgrammBlogs(userId) {
	return axios.get(`${BASE_PATH}/blogs/get-programBlogs?userId=${userId}`);
}

/* ESTE EP YA NO SE VA A USAR */

// export function getLabelByBlog(userId,blog_id) {
//     return axios.post(`${BASE_PATH}/blogs/get-LabelByBlog`,{user_id:userId,blog_id:blog_id});
// }
export function getTopAuthors(userId) {
    return axios.get(`${BASE_PATH}/blogs/get-TopAuthors`);
}

// export function getLabels(userId) {
//     return axios.post(`${BASE_PATH}/blogs/get-labels`,{user_id:userId});
// }

export function getBlogsByAuthors(userId) {
	return axios.get(`${BASE_PATH}/blogs/get-BlogsByAuthors?userId=${userId}`);
}
export function deleteBlog(userId,blog_id) {
    return axios.delete(`${BASE_PATH}/blogs/delete-blog?blogId=${blog_id}`);
}

export function saveBlog(user_id, blog_id, saved) {
	return axios.post(`${BASE_PATH}/blogs/save-blog`, { userId: user_id, blogId: blog_id, isSaved: saved });
}

export function getSavedBlogs(userId) {
	return axios.get(`${BASE_PATH}/blogs/get-save-blog?userId=${userId}`);
}

export function likeBlog(userId, blogId, liked) {
	return axios.post(`${BASE_PATH}/blogs/update-like-blog`, { userId: userId, blogId: blogId, isLiked: liked });
}

export function getReactionsBlog(user_id, blog_id) {
	return axios.get(`${BASE_PATH}/blogs/get-likes-blog?userId=${user_id}&blogId=${blog_id}`);
}

export function getLabelsForBlogs() {
	return axios.get(`${BASE_PATH}/blogs/get-labels`);
}

export function getBlogComments(blog_id) {
	return axios.get(`${BASE_PATH}/blogs/get-commentsByBlog?blogId=${blog_id}`);
}

export function createComment(userId, blogId, comment) {
	return axios.post(`${BASE_PATH}/blogs/create-commentBlog`, { userId: userId, blogId: blogId, comment: comment });
}

export function updateComment(userId, commentId, comment){
    return axios.put(`${BASE_PATH}/blogs/update-commentBlog`, {userId:userId, commentId:commentId, comment:comment})
}

export function deleteComment(userId, commentId){
    return axios.delete(`${BASE_PATH}/blogs/delete-commentBlog?commentId=${commentId}`)
}

export function getBlogById(blog_id) {
	return axios.get(`${BASE_PATH}/blogs/get-blogId?blogId=${blog_id}`);
}

export function getOrderedBlogs(orderP, orderD, userId) {
	return axios.get(`${BASE_PATH}/blogs/get-oderByBlog?orderP=${orderP}&orderDate=${orderD}&userId=${userId}`);
}

export function getDraftedBlogs(userId) {
	return axios.get(`${BASE_PATH}/blogs/get-autoSaved?userId=${userId}`);
}
