import SedeIcon from "../../../../assets/img/company/sede-non-selected.svg";

export function paisMenu({ text, value, key, setCurrentValue, onMouseDown }) {
  const name = value.name.split(",")[0];
  return (
    <>
      <div className="d-flex" id={text}
        onMouseDown={() => { onMouseDown(value);
        }}
        style={{
          cursor: "pointer",
          padding: 5,
        }}>

        <img className="mr-2" id={key} alt={text} src={SedeIcon} />
        <div className="d-flex flex-column" id={text}>
          <p id={text}>{name}</p>
          <p id={text} className="text-xs a-text-medium-first-neutral-90">Locación - {text}</p>
        </div>
      </div>
    </>
  );
}
