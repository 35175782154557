import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Modal,
  Grid,
  createMuiTheme,
  withStyles,
} from "@material-ui/core";
import { EmptyStateQuestions } from "../../molecules/EmptyState/EmptyStateQuestions/index";
import { WarningModal } from "../../molecules/Modal/WarningModal"
import ModalSuccess from "../../molecules/Modal/SuccessModal";
import {
  deleteQuestion,
  getQuestionsByUserId,
  getQuestionsAssignedByMe,
  getQuestionsAssignedToMe,
  getQuestionById
} from "../../../api/questions";
import CardQuestion from "../../molecules/Card/CardQuestion/index";
// Components
import SkeletonCardQuestion from "../../molecules/Skeleton/SkeletonQuestions/SkeletonCardQuestion";

export default function MenuUserQuestios({ page, setPage, userId }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const [topics, setTopics] = useState(true);
  const [myQuestions, setMyquestions] = useState(true);
  const [myAnsw, setMyAnsw] = useState(false);
  const [myRequest, setMyRequest] = useState(false);

  const [load, setLoad] = useState(true);
  const [loadRes, setLoadRes] = useState(true);
  const [loadSol, setLoadSol] = useState(true);
  const [loadDelete, setLoadDelete] = useState(true);
  const [currentData, setCurrentData] = useState([""]);
  const [assignedQuestion, setAssignedQuestion] = useState([]);
  const [assignedQuestionToMe, setAssignedQuestionToMe] = useState([]);
  const [deletedQ, setDeletedQ] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [openWarning, setOpenWarning] = useState(false)
  const [question, setQuestion] = useState()
  const [open, setOpen] = useState(false);

  useEffect(() => {
    GetQuestiosByUser();
    GetQuestionsAssignedByMe(userId);
    GetQuestionsAssignedToMe(userId);
  }, [deletedQ]);

  async function GetQuestiosByUser() {
    setLoad(true);
    try {
      const { data: { questions } } = await getQuestionsByUserId(userId);
      questions ? setCurrentData(questions) : setCurrentData([])
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  }

  async function GetQuestionsAssignedToMe(userId) {
    try {
      const { data: { questions: { result } } } = await getQuestionsAssignedToMe(userId);
      setAssignedQuestionToMe(result);
      setLoadSol(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function GetQuestionsAssignedByMe(userId) {
    try {
      const { data: { questions: { result } } } = await getQuestionsAssignedByMe(userId);
      setAssignedQuestion(result);
      setLoadRes(false);
    } catch (err) {
      console.log(err)
    }
  }
  async function deleteObject(questionId, type) {
    try {
      setOpen(true);
      const deleted = await deleteQuestion(questionId);

      if (deleted.status == 200) {
        setLoadDelete(false);
        setTimeout(() => {
          setOpen(false);
          setDeletedQ(!deletedQ);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const actualizandoQuestionList = async () => {
      if (updated !== 0) {
        const { company, name, nameArea, photo_link, presentation } = currentData.find(questionState => questionState.question_id == updated)
        const arrayUpdated = currentData.filter(questionState => questionState.question_id != updated);
        try {
          const { data: { questionByid } } = await getQuestionById(1, updated)
          const [{ question, question_id, user_id, images_link, created_at, updated_at }] = questionByid
          const preguntaActualizada = { question, question_id, user_id, images_link, created_at, 
                                        updated_at, company, name, nameArea, photo_link, presentation }
          setCurrentData([preguntaActualizada, arrayUpdated].flat())
          setUpdated(0)
        } catch (error) {
          console.log(error)
        }
      }
    }
    actualizandoQuestionList()
  }, [updated])

  return (
    <Grid xs={12} className="w-100  ">
      <div className="d-flex mt-1  align-items-center    w-100">
        <div
          onClick={() => {
            setMyquestions(true);
            setMyAnsw(false);
            setMyRequest(false);
          }}
          className={` ${ipadMiniMatch ? " " : ""
            } cursor-pointer text-small text-lg-normal  mb-3 text-md-medium mr-md-3 text-center br-4 px-2 py-1 py-md-2  ${myQuestions ? "switch-question-active" : "switch-question"
            } px-md-4 py-lg-1 h-a`}
        >
          Preguntas{" "}
        </div>
        <div
          onClick={() => {
            setMyRequest(false);
            setMyquestions(false);
            setMyAnsw(true);
          }}
          className={` ${ipadMiniMatch ? " " : ""
            } cursor-pointer text-small text-md-medium  mb-3  text-lg-normal mr-md-3 text-center br-4  py-1 px-2   py-md-2   ${myAnsw ? "switch-question-active" : "switch-question"
            } px-md-4 h-a py-lg-1`}
        >
          Respuestas
        </div>
        <div
          onClick={() => {
            setMyRequest(true);
            setMyquestions(false);
            setMyAnsw(false);
          }}
          className={` ${ipadMiniMatch ? " " : ""
            } text-small text-md-medium cursor-pointer mr-md-3 mb-3 text-center br-4 text-lg-normal px-2 py-1 py-md-2  ${myRequest ? "switch-question-active" : "switch-question"
            } px-md-4 h-a py-lg-1`}
        >
          Solicitudes
        </div>
      </div>
      <Grid item xs={12} className=" d-flex justify-content-center py-2  ">
        {myQuestions ? (
          <Grid xs={11} lg={12} className=" w-100">
            <div className="d-flex flex-column w-100">
              {currentData.length === 0 ? (
                <EmptyStateQuestions
                  sub={
                    "Anímate a preguntar tus dudas a la comunidad, cada que lo necesites."
                  }
                  titulo={"Aún no tienes preguntas. "}
                />
              ) : (
                <>
                  {load ? (
                    <SkeletonCardQuestion num={10} />
                  ) : (
                    currentData.map((res, idx) => (
                      <div className="d-flex w-100 mt-1" key={idx}>
                        <CardQuestion
                          key={idx}
                          info={res}
                          userId={userId}
                          link={{
                            pathname: "/cid/carso/questions/list/" + res.question_id,
                            state: { info: res },
                          }}
                          onClickQuestion={deleteObject}
                          onUpdateQuestion={setUpdated}
                          updated={updated}
                          setOpenWarning={setOpenWarning}
                          openWarning={openWarning}
                          setQuestion={setQuestion}
                        />{" "}
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </Grid>
        ) : (
          <div />
        )}
        {myAnsw ? (
          <Grid xs={11} lg={12} className=" w-100">
            <div className="d-flex flex-column w-100">
              {assignedQuestion.length === 0 ? (
                <EmptyStateQuestions
                  titulo={"No te quedes sin responder."}
                  redirect="/cid/search-company"
                  link={true}
                  hyper={"todas las preguntas"}
                  setPage={setPage}
                  page={page}
                />
              ) : (
                <>
                  {loadRes ? (
                    <SkeletonCardQuestion num={10} />
                  ) : (
                    assignedQuestion.map((res, idx) => (
                      <div className="d-flex w-100 mt-1" key={idx}>
                        <CardQuestion
                          key={idx}
                          info={res}
                          userId={userId}
                          onClickQuestion={deleteObject}
                          onUpdateQuestion={setUpdated}
                          updated={updated}
                          setOpenWarning={setOpenWarning}
                          openWarning={openWarning}
                          link={{
                            pathname: "/cid/carso/questions/list/" + res.question_id,
                            state: { info: res },
                          }}
                          setQuestion={setQuestion}
                        />{" "}
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </Grid>
        ) : (
          <div />
        )}
        {myRequest ? (
          <Grid xs={11} lg={12} className=" w-100">
            <div className="d-flex flex-column w-100">
              {assignedQuestionToMe.length === 0 ? (
                <EmptyStateQuestions
                  sub={
                    "Te enviaremos una notificación cuando tengamos novedades"
                  }
                  titulo={"Aún no hay solicitudes. "}
                  redirect="/cid/search-company"
                  link={false}
                  hyper={"lorem"}
                />
              ) : (
                <>
                  {loadSol ? (
                    <SkeletonCardQuestion num={10} />
                  ) : (
                    assignedQuestionToMe.map((res, idx) => (
                      <div className="d-flex w-100 mt-1" key={idx}>
                        <CardQuestion
                          key={idx}
                          info={res}
                          userId={userId}
                          onClickQuestion={deleteObject}
                          onUpdateQuestion={setUpdated}
                          updated={updated}
                          setOpenWarning={setOpenWarning}
                          openWarning={openWarning}
                          setQuestion={setQuestion}
                          link={{
                            pathname: "/cid/carso/questions/list/" + res.question_id,
                            state: { info: res },
                          }}
                        />{" "}
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </Grid>
        ) : (
          <div />
        )}
      </Grid>
      <Modal
        open={open}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={open}
            setOpen={setOpen}
            message={`${loadDelete
              ? "Espera un momento..."
              : "Tu pregunta se ha eliminado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
      <Modal
        open={openWarning}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <WarningModal
            message={"Tu pregunta será eliminada"}
            open={openWarning}
            setOpen={setOpenWarning}
            setOpenSuccess={setOpen}
            openSuccess={open}
            deleteObject={deleteObject}
            object={question}
            type={'question'}
          />
        </div>
      </Modal>
    </Grid>
  );
}
