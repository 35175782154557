import React from "react";

export const Filters = ({ filters, setFilters }) => {
  const labels = [
    {
      name: "Activos",
      value: 115,
    },
    {
      name: "Inactivos",
      value: 125,
    },
    {
      name: "CID",
      value: 135,
    },
    {
      name: "Carso",
      value: 145,
    },
  ];

  const handleOnChange = (position) => {
    if (filters.filter((el) => el.name == labels[position].name).length > 0) {
      let aux = filters.filter((elm) => {
        return elm.name !== labels[position].name;
      });
      setFilters(aux);
    } else {
      setFilters([...filters, labels[position]]);
    }
  };

  return (
    <div className="">
       {/* {filters.map((filter) => (
          <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-2">
            {filter.name}
          </p>
        ))} */}
      {labels.map((label, index) => (
        <div className="mb-3 d-flex" key={label.value}>
          <input
            onChange={() => handleOnChange(index)}
            checked={filters.find((filter) => filter.name === label.name)}
            type="checkbox"
            id={label.name}
            className=" radioNews cursor-pointer check-blog-filter"
            name={label.name}
            value={label.value}
          />
          <label
            className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
            htmlFor={label.name}
          >
            {label.name}
          </label>
        </div>
      ))}
    </div>
  );
};
