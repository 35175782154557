//React
import React, { useState, useEffect, useRef, useContext } from "react";
import { PodcastContext } from "../../../../providers/PodcastContext";
import Moment from "react-moment";
// Material
import { Grid, IconButton, useMediaQuery, Tooltip } from "@material-ui/core";
import LikeComponent from "../CardQuestion/LikeComponent";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import { ProfileGeneral } from "../ProfileComponent/ProfileGeneral";

//API's
import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";

import Compartir from "../../../../assets/img/icon/compartir.svg";
import Copy from "../../../../assets/img/news/copiar-enlace.svg";
import Correo from "../../../../assets/img/social/correo.svg";
import Whats from "../../../../assets/img/social/whats.svg";

import Play from "../../../../assets/img/icon/PlayIcon.svg";
import Pause from "../../../../assets/img/icon/pausa.svg";

import Save from "../../../../assets/img/news/guardar-1.svg";
import Add from "../../../../assets/img/icon/agregar.svg";
import SaveActive from "../../../../assets/img/news/guardar-2.svg";
import Tag from "../../../../assets/img/blogs/etiqueta.svg";

import { savePodcast } from "../../../../api/podcasts";

export function CardHorizontal({
  info,
  userId,
  setElement,
  setOpenWarning,
  openWarning,
}) {
  const { setPodcast, setActive } = useContext(PodcastContext);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const phoneMatch = useMediaQuery("(max-width: 480px)");

  let history = useHistory();

  function copyToClipboard() {
    // const url = window.location.href + `/${blog_id}`;
    // navigator.clipboard.writeText(url);
  }

  const handleOnClick = () => {
    history.push({
      pathname: "/cid/carso/podcast/list/program/" + info.program_podcast_id,
      state: { podcast: info },
    });
  }

  const handleClickAdd = () => {
    history.push({
      pathname: "/cid/carso/podcast/upload/",
      state: { program: info },
    });
  }

  const handleClickDelete = (e) => {
    setOpenWarning(!openWarning);
    setElement(info.podcast_id);
  };
  console.log(userId)
  const handleClickSave = async (e, podcast) => {
    e.preventDefault()
    var formData = new FormData();
    formData.append("podcastId", podcast.program_podcast_id);
    formData.append("user_id", userId);
    formData.append("isSaved", true);
    try {
      const { data } = await savePodcast({"podcastId": podcast.program_podcast_id, "user_id": userId, "isSaved": "true" });
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {
        <div className="mb-3">
          <div
            className={`podcast-card h-a d-flex br-2  px-4  box-shadow-lg-all card  py-md-3 py-2 `}
          >
            <div className="w-100">
              <div className={`d-flex justify-content-between`}>
                <div className="d-flex flex-column justify-content-between w-90">
                  <div
                    className={`cursor-pointer card-blog-height"
                      }  w-100`}
                  >
                    <div className="container-blog-preview text-xs text-md-small w-100" onClick={handleOnClick}>
                      <h2> {info.title} </h2>
                    </div>
                  </div>
                  <div className="d-flex text-small">
                    <ProfileGeneral
                      userId={userId}
                      type={"onlyProfile"}
                      info={info}
                    />
                    <span className="a-text-regular-first-neutral-100 text-small text-md-medium cursor-pointer ">
                      <Typography>
                        <Moment className="ml-1" fromNow>
                          {info.created_at}
                        </Moment>
                      </Typography>

                    </span>

                  </div>

                  <div className="d-flex  align-items-center">
                    <img
                      src={Tag}
                      alt="icon"
                      className={`tag-icon mr-1 ml-1  ${info.labelsPodcast?.length !== 0 ? "" : "d-none"
                        }`}
                    />

                    <>
                      {info.labels?.length == 0 ? (
                        <div></div>
                      ) : (
                        <>
                          {info.labelsPodcast?.map((res, idx) =>
                            res.name !== "undefined" ? (
                              <span
                                className="text-xs a-text-regular-first-neutral-100 mr-1 "
                                key={idx}
                              >
                                {` ${res.name} `}{" "}
                                {info.labelsPodcast.length > 1 &&
                                  idx != info.labelsPodcast.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ) : (
                              <span></span>
                            )
                          )}
                        </>
                      )}
                    </>
                  </div>

                  <div className="w-90">
                    <p className="a-text-regular-second-neutral-70 text-small mt-2">{info.description.length > 150 ? info.description.substr(0, 149) : info.description}</p>
                  </div>


                  {/* Iconos */}
                  <div className="d-flex mt-2">

                    <IconButton className={"cursor-pointer"} size="small">
                      <img src={Compartir} alt="Share" width="22px" />
                    </IconButton>
                    {
                      userId == info.user_id ? (
                        <>
                          <div
                            className="ml-8"
                            onClick={() => { handleClickAdd() }}
                          >
                            <Tooltip title="Agregar episodio" placement="right" >
                              <IconButton
                                className={"cursor-pointer"} size="small"
                              >
                                <img src={Add} alt="Opt" width="22px" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        <div className="ml-8">
                          <IconButton className={"cursor-pointer"} size="small">
                            <img src={Save} alt="Opt" width="20px" onClick={(e) => handleClickSave(e, info)} />
                          </IconButton>
                        </div>

                      )
                    }


                  </div>
                </div>

                <div className="container-image-podcast d-flex align-items-center cursor-pointer" onClick={handleOnClick}>
                  <div className="subcontainer-image-podcast">
                    <img
                      src={`https://images.weserv.nl/?url=${info.cover}&maxage=31d`}
                      alt="icon"
                      className="image-podcast-list"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

