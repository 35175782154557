import { useEffect, useState } from "react";

export function ImageInput({ className, warning, id, style, Icon, originalSource }) {
  const [source, setSource] = useState("");

  function onChange(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      setSource(reader.result);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if (originalSource) {
      setSource(originalSource);
    }
  }, [originalSource]);

  return (
    <>
      <input
        className={`d-none ${className}`}
        type="file"
        id={id}
        // autoFocus={focus}
        onChange={onChange}
        accept=".jpeg, .jpg, .png" />
      <label htmlFor={id}>
        <div
          className="input-file-logo d-flex"
          style={{ alignItems: "center", justifyContent: "center", ...style }}
        >
          <img src={source ? source : Icon}
            alt="logo"
            className={source ? "m-1" : "checkbox-img m-auto"}
            style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </div>
      </label>
       {warning ? (
        <p className="mt-2 a-text-regular-alert-60">{warning}</p>
      ) : (
        ""
      )}
    </>
  );
}
