import { useEffect, useState } from "react";

export function Checkbox({ data, value, setValue, className, nameKey, id, warning, dateChanged, selectClass, disabled }) {
  const name = nameKey ? nameKey : (elem) => elem?.name;
  const [checked, setChecked] = useState(new Array(data.length).fill(false));

  useEffect(() => {
    if (data.length === 0) return;

    // limpia los valores que no estan en data
    for (const elem of value) {
      if (!data.map((elem) => name(elem)).includes(elem)) {
        setValue(value.filter((elem2) => elem2 !== elem));
      }
    }
    // limpia los valores que estan en data pero no en value
    let array = new Array(data.length).fill(false);
    for (const elem of data){
      if (value.includes(name(elem))){
        array[data.indexOf(elem)] = true;
      }
    }
    setChecked(array);
  }, [data, value]);

  return (
    <div className="mt-2">
      {data.map((item, i) => (
        <>
          <input
            type="checkbox"
            checked={checked[i]}
            className={`d-none input-checkbox ${id} `}
            id={`${id}-${i} `}
            value={name(item)}
            onChange={(e) => {
              const newChecked = [...checked];
              newChecked[i] = !newChecked[i];
              setChecked(newChecked);
              if (newChecked[i]) {
                setValue([...value, name(item)]);
              } else {
                setValue(value.filter((elem) => elem !== name(item)));
              }
            }} />
          <label
            className={`${disabled
              ? "btn-checkbox-form-inactive"
              : "btn-checkbox-form"} pt-4 px-3 mr-2 mb-2 text-small mr-md-2 mb-md-2 cursor-pointer`}
            htmlFor={`${id}-${i} `}
          >
            {name(item)}
          </label>
        </>
      ))}

      {warning ? (
        <p className="mt-2 a-text-regular-alert-60">{warning}</p>
      ) : (
        ""
      )}
    </div>
  );
}
