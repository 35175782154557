import React, { useState } from "react";

//Components
import {InputWithLabel} from "../../molecules/InputWith/InputWithLabel";
import { Button } from "../../atoms/Button";

//API
import { createNewPasswordApi } from "../../../api/user";


//Material UI
import { CircularProgress} from '@material-ui/core';

export default function FormRecoverPassword({ userId, token }){
    const [ isLoading, setIsLoading ] = useState(false)
    const [ warningPassword, setWarningPassword ] = useState("");

    async function handleSubmitPassword(e){
        e.preventDefault();
        const newPassword = document.getElementById("reset-password").value
        const newCoPassword = document.getElementById("reset-co-password").value

        if(newPassword !== newCoPassword){
            setWarningPassword(<p className="text-center mb-3 a-text-regular-alert-60">Las contraseñas deben de coincidir</p>)
            return
        }else{
            setWarningPassword("")
        }

        try {
            await createNewPasswordApi(newPassword, userId, token);
            window.location.href="/sucess-reset-password"
        } catch (error) {
            setIsLoading(false);
        }
    }

    return(
        <form onSubmit={(e) => handleSubmitPassword(e)}>
            <div className="mb-3">
                <InputWithLabel type="password" text="Contraseña" id="reset-password" placeholder="Ingresa tu nueva contraseña" />
            </div>
            <div className="mb-3">
                <InputWithLabel type="password" text="Confirmar contraseña" id="reset-co-password" placeholder="Ingresa tu nueva contraseña" />
            </div>
            {warningPassword}
            <Button disabled={isLoading ? true : false} type="submit" classes={`a-button-primary1 ${isLoading ? "disabled" : "" }`} height={36} width="100%" text={isLoading ? <CircularProgress /> : "Restablecer contraseña"} />
        </form>
    )
}