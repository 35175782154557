function verifyLink(link) {
  if (link === "" || link === undefined) {
    return false;
  }
  let expression =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  
  return expression.test(link);
}

export function validateForm(data, setWarnings, mode) {
  let valid = true;
  let warnings = {};

  if (data.name === "" || data.name === undefined) {
    warnings.name = "Ingresa el nombre de la empresa";
    valid = false;
  }

  if (mode === 0) {
    // verify logo
    if (document.getElementsByClassName("logo")[0].files.length === 0) {
      warnings.logo = "Ingresa el logo de la empresa";
      valid = false;
    }
  }

  if (data.description === "" || data.description === undefined) {
    warnings.description = "Ingresa la descripción de la empresa";
    valid = false;
  }

  if (data.foundation === "" || data.foundation === undefined) {
    warnings.foundation = "Ingresa el año de fundación de la empresa";
    valid = false;
  }


  if (data.size === "" || data.size === undefined) {
    warnings.size = "Selecciona el tamaño de la empresa";
    valid = false;
  }

  if (data.sectors === "" || data.sectors === undefined) {
    warnings.sectors = "Selecciona el sector";
    valid = false;
  }

  if (data.industries.length === 0) {
    warnings.industries = "Selecciona al menos una industria";
    valid = false;
  }

  if (data.technology === "" || data.technology === undefined) {
    warnings.technology = "Selecciona la tecnología";
    valid = false;
  }

  if (data.secundaryTechnology.length === 0) {
    warnings.subtechnology = "Selecciona al menos una tecnología secundaria";
    valid = false;
  }

  if (data.market.length === 0) {
    warnings.mercados = "Selecciona al menos un mercado";
    valid = false;
  }

  if (data.opportunities === "" || data.opportunities === undefined) {
    warnings.opportunities = "Ingresa las oportunidades de la empresa";
    valid = false;
  }

  if (data.website === "" || data.website === undefined) {
    warnings.website = "Ingresa el sitio web de la empresa";
    valid = false;
  }

  if (data.contactocid === "" || data.contactocid === undefined) {
    warnings.contactocid = "Selecciona el contacto CID";
    valid = false;
  }

  if (verifyLink(data.website) === false) {
    warnings.website = "Ingresa un link válido";
    valid = false;
  }

  if (data.website === "" || data.website === undefined) {
    warnings.website = "Ingresa el sitio web de la empresa";
    valid = false;
  }

  if (!(data.video_url === "" || !data.video_url)) {
    if (!verifyLink(data.video_url)) {
      warnings.video_url = "Ingresa un link válido";
      valid = false;
    }
  }

  if (!(data.linkedin === "")) {
    if (!verifyLink(data.linkedin)) {
      warnings.linkedin = "Ingresa un link válido";
      valid = false;
    }
  }

  if (!(data.youtube === "")) {
    if (!verifyLink(data.youtube)) {
      warnings.youtube = "Ingresa un link válido";
      valid = false;
    }
  }

  if (!(data.twitter === "")) {
    if (!verifyLink(data.twitter)) {
      warnings.twitter = "Ingresa un link válido";
      valid = false;
    }
  }

  if (!(data.facebook === "")) {
    if (!verifyLink(data.facebook)) {
      warnings.facebook = "Ingresa un link válido";
      valid = false;
    }
  }

  if (!(data.instagram === "")) {
    if (!verifyLink(data.instagram)) {
      warnings.instagram = "Ingresa un link válido";
      valid = false;
    }
  }

  if (data.registerNewSede == true && data.validSede == false) {
    warnings.new_sede = "Selecciona una sede válida";
    valid = false;
  } else if (data.location === "" || data.location === undefined) {
    warnings.location = "Selecciona la sede de la empresa";
    valid = false;
  }

  if (valid === false) {
    warnings.validForm = "Ingresa la información faltante";
  }

  setWarnings(warnings);
  
  return valid;
}
