import React , { useState, useEffect } from 'react';

// import Volume from "../../../assets/img/icon/volumen.svg"
import Volume from "../../../assets/img/icon/volumen blanco.svg"
import Mute from "../../../assets/img/icon/volumen cancel.svg"

import {
    IconButton,
    useMediaQuery
} from '@material-ui/core'
import { useRef } from 'react';

export const VolumeControl = ({ volume, file }) => {

    const ipadMiniMatch = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        setCurrentVolume(1)
    }, [file])

    const [currentVolume, setCurrentVolume] = useState(1)
    const [isMute, setIsMute] = useState(false);
    const [savedVolume, setSavedVolume] = useState();

    const handleVolume = (value) => {
        volume(value)
    }

    const handleClickMute = () => {
        if (!isMute) {
            volume(0);
            setSavedVolume(currentVolume)
            setCurrentVolume(0);
        } else {
            volume(savedVolume);
            setCurrentVolume(savedVolume)
        }
        setIsMute(!isMute)
    }

    const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentVolume*100}%, #23a2e7), color-stop(${currentVolume*100}%, #FFFF))
  `;

    return (
        <div className='d-flex align-items-center'>
            <div>
            <IconButton
                onClick={handleClickMute}
                className={"cursor-pointer"}
            >
                <img
                src={!isMute ? Volume : Mute}
                alt="Play"
                width={24}
                height={24}
                />
            </IconButton> 
            </div>
            <div className={'d-flex align-content-center'}>
            <input
                type="range"
                value={currentVolume}
                step="0.1"
                min="0"
                max="1"
                className="progress-bar"
                onChange={(e) => {
                    handleVolume(e.target.value)
                    setCurrentVolume(e.target.value)
                }}
                style={{ background: trackStyling }}
            />
            </div>


        </div>
    )
}
