import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormAddUser } from '../../../components/organisms/FormAddUser';
import useAuth from '../../../hooks/useAuth';

export default function AddUser(e) {
	const user = useAuth();
	if (!user) {
		return <Redirect to='/admin/' />;
	}
	if (user.rolId !== 1) {
		if (user.rolId !== 2) {
			return <Redirect to='/cid/carso/home' />;
		}
		return <Redirect to='/cid' />;
	}
	return <FormAddUser isCollapsed={e.isCollapsed} />;
}
