import { convertToRaw } from "draft-js";
import { draftjsToMd } from "draftjs-md-converter";

/**
 * @param {ContentState} contentState - ContentState a convertir a Markdown.
 * @returns {string} - La representación en Markdown del ContentState.
 */
export default function draftjsToMarkdown(contentState) {
  return draftjsToMd(convertToRaw(contentState.getCurrentContent()));
}
