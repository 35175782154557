//React
import React, { useState } from "react";
import Moment from "react-moment";
// Material
import {
  IconButton,
  useMediaQuery,
  Popover,
  Button,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useHistory } from "react-router-dom";
import { ProfileGeneral } from "../ProfileComponent/ProfileGeneral";

//Api´s
import { saveBlog } from "../../../../api/blogs";

import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";
import PencilIconOcean from "../../../../assets/img/pencil-icon-ocean.svg";

import Compartir from "../../../../assets/img/icon/compartir.svg";
import Copy from "../../../../assets/img/news/copiar-enlace.svg";
import Correo from "../../../../assets/img/social/correo.svg";
import Whats from "../../../../assets/img/social/whats.svg";

import Save from "../../../../assets/img/news/guardar-1.svg";
import SaveActive from "../../../../assets/img/news/guardar-2.svg";

import Tag from "../../../../assets/img/blogs/etiqueta.svg";
import Clock from "../../../../assets/img/blogs/reloj.svg";
import userIcon from '../../../../assets/img/user-icon.svg'
import LikeComponent from "../CardQuestion/LikeComponent";


export default function CardBlog({
  info,
  noIcons,
  userId,
  type,
  deleteObject,
  setOpenSuccess,
  openSuccess,
  path,
  saved,
  setChange,
  change,
  setOpenWarning,
  openWarning,
  setBlog,
  category
}) {
  /*
  Esta card fue creada para generar la vista de mosaico, comparte las mismas propiedades que la card que correspponde a la vista de lista,
  al no ser un diseño ya establecido y es algo que pasará por el AB testing decidí llamarlo OptionTwo.
  */

  // Estos regex se utilizan porque la descripción del blog devuelta por la db contiene ya los tags html
  // y elementos propios del editor para resolverlo utilizamos un replace
  const htmlTagRegex = /<\/?[^>]+>/gi;
  const ampRegex = /(&nbsp)/gi;
  const scRegex = /;/gi;
  const defaultRegex = /(Escribir un título)/;


  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  let history = useHistory();

  const [labels, setLabels] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [isSaved, setIsSaved] = useState(saved);

  const [anchorEl, setAnchorEl] = useState(null);

  const blog = info;

  function copyToClipboard(blog_id) {
    const url = window.location.href + `/${blog_id}`;
    navigator.clipboard.writeText(url);
    console.log("this is on the clipboard", url);
  }

  function handleClickDelete() {
    setOpenWarning(!openWarning);
    setBlog(blog.blog_id);
  }

  const handleClickSave = (blog_id) => {
    setIsSaved(!isSaved);
    saveBlog(userId, blog_id, !isSaved)
      .then((result) => {
        if (result.status == 200) {
          console.log("Se guardó o borró correctamente");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    !!setChange && setChange(!change);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openM = Boolean(anchorEl);

  return (
    <div
      className={`d-flex br-2  px-4 ${
        path != "HomeCarso"
          ? "mx-3 card-vertical-blog"
          : "card-vertical-blog-home"
      }  box-shadow-lg-all card   py-3`}
    >
      <div className="pr-3 w-100">
        <div
          className={`d-flex justify-content-start  align-items-center w-100   mt-md-1 mt-lg-1 `}
        >
          <div>
            <img
              alt="user"
              src={ blog.photo_link == null || blog.photo_link == "undefined" ? userIcon  : `https://images.weserv.nl/?url=${blog.photo_link}&maxage=31d` }
              className="mr-2 icon-user-blog"
            />
          </div>

          <div className="d-flex flex-column">
          <div className="d-flex">
                  <ProfileGeneral userId={userId} type={'onlyProfile'} info={blog}/>
                    {type == "programada" ? (
                      <div className="d-flex align-items-center">
                        <img src={Clock} alt="icon" className={`tag-icon mr-2`} />
                        <span className="text-xs a-text-regular-first-neutral-100 ">
                          {""}
                          <Moment format="DD/MM/YYYY">
                            {blog.published_date}
                          </Moment>{" "}
                          a las {` ${blog.published_time} `}
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center a-text-regular-first-neutral-100 cursor-pointer ">
                          {" "}
                          <Moment className="ml-1" fromNow>
                            {blog.created_at}
                          </Moment>
                        </div>
                    )}
                </div>
            {blog.labels.length != 0 ? 
            (
<div
              style={{ height: "18px", "overflowX": "hidden" }}
              className="align-items-center w-100 h-14"
            >
              <img
                src={Tag}
                alt="icon"
                className={`tag-icon mr-1 ml-1  ${
                  blog.labels?.length !== 0 &&
                  blog.labels[0].name !== "undefined" &&
                  blog.labels[0].name !== ""
                    ? ""
                    : "d-none"
                }`}
              />
              <>
                {blog.labels?.length == 0 ? (
                  <div></div>
                ) : (
                  <>
                    {blog.labels?.map((res, idx) =>
                      res.name !== "undefined" ? (
                        <span
                          className="text-xs a-text-regular-first-neutral-100 mr-1 "
                          key={idx}
                        >
                          {` ${res.name} `}{" "}
                          {blog.labels.length > 1 &&
                          idx != blog.labels.length - 1
                            ? ", "
                            : ""}
                        </span>
                      ) : (
                        <span></span>
                      )
                    )}
                  </>
                )}
              </>
              <div className="d-flex ml-2 "></div>
            </div>
            )
            : 
              ""
            }
            
          </div>
        </div>

        <div
          className=" cursor-pointer card-blog-height-vertical "
          onClick={() => {
            history.push({
              pathname: "/cid/carso/blogs/list/" + blog.blog_id,
              state: { blog: blog, tags: labels },
            });
          }}
        >
          {/* <div className="container-blog-preview" dangerouslySetInnerHTML={{ __html: blog.description}}> */}
          <div className="container-blog-preview w-100">
            <h3
              style={{ height: "56px", "overflowY": "hidden" }}
              className="titulos"
            >
              {" "}
              {blog.title}{" "}
            </h3>
            {!!blog.description &&
              blog.description
                .replace(htmlTagRegex, " ")
                .replace(ampRegex, ".")
                .replace(blog.title, "")
                .replace(scRegex, ".")
                .replace(defaultRegex, "")
                .substr(0, 45)}
            {!!blog.description && blog.description.length > 45 ? "..." : ""}
          </div>
        </div>
        <div className="image-preview-blog-container2-md mt-3">
          <div className="subcontainer-image-blog-card">
            <img
              src={`https://images.weserv.nl/?url=${blog.cover_blog}&maxage=31d`}
              alt="icon"
              // className="image-preview-blog"
              className="image-blog-card"
            />
          </div>
        </div>
        {path === "HomeCarso" ? (
          " "
        ) : (
          <>
            {" "}
            <hr className="hr-ocean mt-3" />
            <div
              className={`d-flex w-100   align-items-center justify-content-between ${
                ipadMiniMatch ? " " : " d-flex justify-content-between"
              }`}
            >
              <div className={`${category == 'borradores' || category == 'programadas' ? "d-none" : "d-flex mr-md-3 mb-2"}`}>
                <div className="d-flex justify-content-between">
                  <IconButton
                    onClick={() => setIsShare(true)}
                    className={isShare ? "d-none" : "d-flex"}
                  >
                    <img
                      src={Compartir}
                      alt="Compartir"
                      className="cursor-pointer"
                      width="24px"
                    />
                  </IconButton>

                  <div
                    className={
                      !isShare ? "d-none" : "d-flex align-items-center"
                    }
                    onClick={() => setIsShare(false)}
                  >
                    <IconButton>
                      <img
                        src={Compartir}
                        alt="Compartir"
                        className="cursor-pointer"
                        width="24px"
                      />
                    </IconButton>

                    <a
                      href={`https://api.whatsapp.com/send?text=*${
                        info.title
                      }*%0A🌐%20${window.location.href}${`/${info.blog_id}`}`}
                      target="_blank"
                    >
                      <button className="d-flex btn-in">
                        <img
                          src={Whats}
                          width={`20px`}
                          alt="Compartir"
                          className="cursor-pointer mr-2"
                        />
                      </button>
                    </a>
                    <a
                      href={`mailto:?subject=Blog: &body=Blog:%20${
                        window.location.href
                      }${`/${info.blog_id}`}%0D`}
                      target="_blank"
                    >
                      <button className="d-flex btn-in">
                        <img
                          src={Correo}
                          alt="Compartir"
                          className="cursor-pointer mr-2"
                        />
                      </button>
                    </a>

                    <button
                      className="d-flex btn-in"
                      onClick={() => {
                        copyToClipboard(info.blog_id);
                      }}
                    >
                      <img
                        src={Copy}
                        id="f"
                        alt="Copiar"
                        width={`20px`}
                        className="cursor-pointer mr-2 pdf-img"
                      />
                    </button>
                  </div>
                </div>

                <div
                  className={
                    isShare ? "d-none" : "d-flex justify-content-between ml-3"
                  }
                >
                  <LikeComponent
                    blog={info.blog_id}
                    userId={userId}
                    type={"blog"}
                  />
                </div>
              </div>

              {userId == info.user_id ? (
                <div
                  className={`${
                    ipadMiniMatch
                      ? "d-flex flex-row-reverse  ml-md-4 w-100 mb-2"
                      : "d-flex flex-row-reverse"
                  }`}
                >
                  <Button
                    aria-describedby={"simple-popover"}
                    color="#2e4a63"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon color="#2e4a63" width="24px" />
                  </Button>

                  <Popover
                    id={"simple-popover"}
                    open={openM}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div className="display-grid px-2 mr-1">
                      <div
                        className="cursor-pointer d-flex align-items-center "
                        onClick={handleClickDelete}
                      >
                        <IconButton>
                          <img
                            src={TrashIconOcean}
                            alt="Trash Icon"
                            width="24px"
                          />
                        </IconButton>
                        <span className="a-text-regular-first-neutral-100 text-normal ">
                          Eliminar
                        </span>
                      </div>
                      <div
                        className={`${
                          userId !== info.user_id
                            ? "d-none asas"
                            : "d-flex align-items-center cursor-pointer"
                        }`}
                        onClick={() => {
                          history.push({
                            pathname: "/cid/carso/blog/editar/" + info.blog_id,
                            state: { blog: info },
                          });
                        }}
                      >
                        <IconButton>
                          <img
                            src={PencilIconOcean}
                            alt="icon"
                            className="cursor-pointer"
                            width="22px"
                          />
                        </IconButton>
                        <span
                          className="a-text-regular-first-neutral-100 text-normal "
                          style={{ paddingLeft: "2px" }}
                        >
                          Editar
                        </span>
                      </div>
                    </div>
                  </Popover>
                </div>
              ) : (
                <div className="d-flex mb-2">
                  <IconButton
                    onClick={() => {
                      handleClickSave(blog.blog_id);
                    }}
                  >
                    <img
                      src={isSaved ? SaveActive : Save}
                      alt="Guardar"
                      // className={`${isShare ? "d-none" : "cursor-pointer"}`}
                      className="cursor-pointer"
                    />
                  </IconButton>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
