import React from 'react';
//Hooks
import useAuth from '../../../hooks/useAuth';

//Images
import Logo from '../../../assets/img/logo.svg';
import MailSentImage from '../../../assets/img/mail-sent.svg';

//Material UI
import Grid from '@material-ui/core/Grid';

export default function MailSent(props) {
  const user = useAuth();
  if (user) {
    window.location.href = props.history.goBack();
  } else {
    return (
      <div className='mailsent-container'>
        <div className='card--large'>
          <img
            className='logo'
            src={Logo}
            alt='Wikicid Logo'
          />
          <h3>Recupera tu cuenta</h3>
          <p>Se ha enviado un correo para recuperar tu cuenta.</p>
          <div>
            <img
              src={MailSentImage}
              alt='Mail Sent'
              className='image-news'
            />
          </div>
        </div>
      </div>
    );
  }
}
