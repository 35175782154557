import React, { useState } from "react";
import { Button } from "../../atoms/Button/";
import { Link } from "react-router-dom";
import { assignUserQuestion } from "../../../api/questions";
import { useAuth } from "../../../hooks/useAuth";
import { CircularProgress } from "@material-ui/core";

export default function ConfirmAsignModal({
  username,
  close,
  questionId,
  userAsId,
  setModalGeneral,
}) {
  const [loading, setLoading] = useState(false);

  const user = useAuth();

  const { userId } = user;
  async function submitCreateForm(e) {
    e.preventDefault();

    var formData = new FormData();
    formData.append("user_id", userId);
    formData.append("question_id", questionId);
    formData.append("userAsId", userAsId);
    setLoading(true);
    try {
      await assignUserQuestion(userId, questionId, userAsId)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        close(false);
        setModalGeneral(false);
        setLoading(false);
      }, 1500);
    }
  }

  return (
    <form onSubmitCapture={submitCreateForm}>
      <div className="d-flex flex-column ">
        <span className="text-normal h3-md a-text-medium-second-neutral-60 text-center  mb-3">
          Tu pregunta se asignará a: 
        </span>
        <span className="text-medium a-text-medium-first-primary-70 h3-md text-center  ">
          {username}
        </span>

        <div className="d-flex justify-content-center mt-3 ">
          <Button
            type="button"
            classes={
              "text-normal text-lg-normal  mr-md-3 a-button-topic-gray-1  roboto-bold h3-md btn-asign  "
            }
            text={"Cancelar"}
            onclick={() => {
              close(false);
            }}
          />

          <Button
            type="submit"
            classes={
              "text-normal text-lg-normal  mr-md-3 a-button-topic-blue-1  roboto-bold h3-md  btn-asign"
            }
            text={loading ? <CircularProgress /> : "Aceptar"}
          />
        </div>
      </div>
    </form>
  );
}
