import React, { useState } from 'react';
import { createUserApi } from '../../../api/admin';
import useAuth from '../../../hooks/useAuth';

import { InputWithLabel } from '../../molecules/InputWith/InputWithLabel';
import { Button } from '../../atoms/Button/';
import { Link, useHistory } from 'react-router-dom';
import { emailValidation } from '../../../utils/emailValidation';
import FailIcon from '../../../assets/img/fail-icon.svg';
import Close from '../../../assets/img/icon/close.svg';

//Material
import { CircularProgress, Grid, IconButton, Modal } from '@material-ui/core';
import Header from '../../molecules/Header';

function FormAddUser({ isCollapsed }) {
	// State
	const [isLoading, setIsLoading] = useState(false);

	const user = useAuth();
	const { userId } = user;

	const [warningRol, setWarningRol] = useState('');
	const [warningEmail, setWarningEmail] = useState('');
	const [warningName, setWarningName] = useState('');
	const [warningLastName, setWarningLastname] = useState('');
	const [open, setOpen] = useState(false);
	const [modalBody, setModalBody] = useState('');

	const history = useHistory();

	const handleClose = () => {
		setOpen(false);
	};

	const handleBack = () => {
		window.location.href = '/cid/admin/users';
	};

	function submitCreateForm(e) {
		e.preventDefault();
		setIsLoading(true);
		const rol = document.getElementById('add-user-type');
		const name = document.getElementById('add-user-name').value;
		const lastname = document.getElementById('add-user-lastname').value;
		const email = document.getElementById('add-user-email').value;
		const emailInput = document.getElementById('add-user-email');
		const nameInput = document.getElementById('add-user-name');
		const lastNameInput = document.getElementById('add-user-lastname');
		const isEmailValid = emailValidation(email);
		const active = 1;

		let rolId = rol.getAttribute('value');

		if (!isEmailValid) {
			setWarningEmail(<p className='mt-2 a-text-regular-alert-60'>Ingresa un email válido</p>);
			emailInput.classList.add('input-error');
		} else {
			setWarningEmail('');
			emailInput.classList.remove('input-error');
		}

		if (!rolId) {
			setWarningRol(<p className='mt-2 a-text-regular-alert-60'>Ingresa un rol</p>);
			rol.classList.add('input-error');
		} else {
			setWarningRol('');
			rol.classList.remove('input-error');
		}

		if (!name) {
			setWarningName(<p className='mt-2 a-text-regular-alert-60'>Ingresa un nombre</p>);
			nameInput.classList.add('input-error');
		} else {
			setWarningName('');
			nameInput.classList.remove('input-error');
		}

		if (!lastname) {
			setWarningLastname(<p className='mt-2 a-text-regular-alert-60'>Ingresa un apellido</p>);
			lastNameInput.classList.add('input-error');
		} else {
			lastNameInput.classList.remove('input-error');
			setWarningLastname('');
		}

		if (isEmailValid && name && lastname && rolId) {
			const newUser = {
				userId,
				rolId,
				name,
				lastname,
				email,
				active,
			};
			const response = createUserApi(newUser);
			response
				.then((result) => {
					if (result.status === 200) {
						// history.push("/admin/users/1", { "type": 1, "value": true });
						history.push({
							pathname: '/cid/admin/users',
							state: { openSuccessCreated: true },
						});
					}
				})
				.catch((err) => {
					setIsLoading(false);
					switch (err.response.status) {
						case 400:
							setOpen(true);
							setModalBody(
								<>
									<div className='d-flex justify-content-center'>
										<img
											src={FailIcon}
											alt='Fail Icon'
											className='mb-3 modal__icon-modal'
										/>
									</div>
									<p className='mb-3 a-text-medium-second-neutral-90 text-center'>
										No se ha podido registrar el usuario
										<br /> Inténtelo de nuevo
									</p>
									<div className='d-flex justify-content-center'>
										<div
											className='w-50'
											onClick={handleClose}
										>
											<Button
												type='button'
												classes='a-button-primary1'
												height={36}
												width='100%'
												text='Aceptar'
											/>
										</div>
									</div>
								</>
							);
							break;
						//usuario duplicado
						case 500:
							setOpen(true);
							setModalBody(
								<>
									<div className='d-flex justify-content-center'>
										<img
											src={FailIcon}
											alt='Fail Icon'
											className='mb-3 modal__icon-modal'
										/>
									</div>
									<p className='mb-3 a-text-medium-second-neutral-90 text-center'>
										eMail ya registrado.
										<br /> Inténtalo de nuevo
									</p>
									<div className='d-flex justify-content-center'>
										<div
											className='w-50'
											onClick={handleClose}
										>
											<Button
												type='button'
												classes='a-button-primary1'
												height={36}
												width='100%'
												text='Aceptar'
											/>
										</div>
									</div>
								</>
							);
							break;
						default:
							setModalBody(
								<>
									<div className='d-flex justify-content-center'>
										<img
											src={FailIcon}
											alt='Fail Icon'
											className='mb-3 modal__icon-modal'
										/>
									</div>
									<p className='mb-3 a-text-medium-second-neutral-90 text-center'>
										No se ha podido añadir el usuario
										<br /> Inténtelo de nuevo
									</p>
									<div className='d-flex justify-content-center'>
										<Link
											to='/cid/admin/users'
											className='modal__link-button'
										>
											<Button
												type='button'
												classes='a-button-primary1'
												height={36}
												width='100%'
												text='Aceptar'
											/>
										</Link>
									</div>
								</>
							);
							break;
					}
				});
		} else {
			setIsLoading(false);
		}
	}

	const [dataUsers] = useState([
		{
			name: 'CID',
			rolId: 2,
		},
		{
			name: 'Carso',
			rolId: 3,
		},
	]);

	return (
		<Grid
			container
			alignItems='center'
			spacing={0}
		>
			<Header
				//   isCollapsed={e.isCollapsed}
				hideMessageAndNoti={true}
				text='Buscar usuarios'
				isCollapsed={isCollapsed}
				//   onKeyDown={handleKeyDown}
			/>
			<Grid
				item
				xs={12}
				alignItems='center'
			>
				<div className='background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0'>
					<div className='background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all'>
						<div className='d-flex justify-content-between'>
							<p className='ml-3 h3-lg text-big a-text-bold-second-neutral-70 align-self-center '>
								Formulario de usuarios
							</p>
							<IconButton>
								<img
									src={Close}
									alt='Close'
									className='nav-company-icon '
									onClick={handleBack}
								/>
							</IconButton>
						</div>
						<form
							onSubmitCapture={submitCreateForm}
							className='ml-3 w-90 w-lg-60'
						>
							<p className='a-text-regular-first-neutral-100 text-small mb-3'>* Campos obligatorios</p>
							<div className='mb-3'>
								<InputWithLabel
									classLabel='mb-2'
									text='Nombre *'
									id='add-user-name'
									class
									placeholder='Nombre(s)'
									// focus={true}
									type='text'
								/>
								{warningName}
							</div>
							<div className='mb-3'>
								<InputWithLabel
									classLabel='mb-2'
									width={100}
									text='Apellidos *'
									id='add-user-lastname'
									placeholder='Apellidos'
									type='text'
								/>
								{warningLastName}
							</div>
							<div className='mb-3'>
								<InputWithLabel
									text='Rol *'
									classLabel='mb-2'
									id='add-user-type'
									placeholder='Selecciona un rol'
									// focus={true}
									data={dataUsers}
									// onChange={() => console.log("buenas")}
									type='select'
								/>
								{warningRol}
							</div>
							<div className='mb-4'>
								<InputWithLabel
									classLabel='mb-2'
									text='Correo electrónico *'
									id='add-user-email'
									placeholder='ejemplo@globalhitss.com'
									type='text'
								/>
								{warningEmail}
							</div>
							<Button
								type='submit'
								classes='a-button-primary1'
								height={36}
								width='100%'
								text={isLoading ? <CircularProgress /> : 'Añadir usuario'}
							/>
							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby='simple-modal-title'
								aria-describedby='simple-modal-description'
							>
								<div className='modal'>{modalBody}</div>
							</Modal>
						</form>
					</div>
				</div>
			</Grid>
		</Grid>
	);
}

export { FormAddUser };
