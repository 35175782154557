//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";
export default function SkeletonTextTopics({ num }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  return arraySkeleton.map((r) => (
    <div className=" br-4  py-1 px-2   py-md-2  px-md-4 py-lg-1 h-a  switch-question  mr-2">
      <Skeleton variant="h3" width="50px" animation="wave" />
    </div>
  ));
}
