import React from "react";
import { Grid } from "@material-ui/core/";
import CardNews from "../../../components/organisms/CardNews";

export default function Profile(e) {
  return (
    <Grid container justify="center" spacing={0}>
      <Grid item xs={12}>
        <CardNews
          userId={e.userId}
          isCollapsed={e.isCollapsed}
          linkSearch="/cid/carso/news"
        />
      </Grid>
    </Grid>
  );
}
