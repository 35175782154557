import React, { useState } from "react";

//Imagenes
import Mosaico from "../../../../assets/img/iconos-ml/mosaico.svg";
import Lista from "../../../../assets/img/iconos-ml/lista.svg";

// Material
import { Grid, IconButton, Modal, useMediaQuery } from "@material-ui/core";

export default function TabMenu({
  opttwo,
  optthird,
  setOptTwo,
  setOptThird,
  setDisplay,
  setLoading2,
  seguidores,
  seguidos,
  fun1 = null,
  fun2 = null,
  lista = true,
  resetSearch,
}) {
  const matchDesktop = useMediaQuery("(min-width: 1200px)");
  const matchIpadPro = useMediaQuery("(min-width: 992px)");
  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");

  function handleMenu(id) {
    setOptTwo(false);
    setOptThird(false);
    switch (id) {
      case 2:
        setOptTwo(true);
        break;
      case 3:
        setOptThird(true);
        break;
      default:
        break;
    }
  }

  function switchView(e) {
    e.preventDefault();
    setLoading2(true);
    setDisplay(!lista);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
    resetSearch();
  }

  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      className={`${matchPhone ? "mt-3" : "mt-6"} `}
    >
      <Grid
        item
        xs={12}
        className="mt-3 w-100 d-flex justify-content-center  flex-column "
      >
        <div className={`hr-gray ${matchPhone ? "mx-6" : ""}`}></div>
        <Grid
          className={`${matchPhone ? "mx-6" : ""}`}
          container
          justify="center"
          spacing={0}
        >
          <Grid
            xs={12}
            md={12}
            className="d-flex justify-content-start list-style-none a-text-regular-second-neutral-60 "
          >
            <div
              className={`${
                matchIpadPro
                  ? "mr-9"
                  : matchIpadMini
                  ? "w-125 w-lg-30 mr-5"
                  : "w-109 w-lg-30 mr-8"
              } `}
            >
              {" "}
              <li
                className={`cursor-pointer w-100 text-center text-small text-md-medium ${
                  matchIpadPro
                    ? "pt-4 pb-7"
                    : matchIpadMini
                    ? "pt-4 pb-7 w-lg-80"
                    : "py-3"
                } ${
                  opttwo && !matchDesktop
                    ? "a-text-medium-first-primary-60 border-bottom-second-primary-50"
                    : opttwo && matchDesktop
                    ? "a-text-medium-first-primary-60 border-bottom-second-primary-50"
                    : ""
                }`}
                onClick={fun1 != null ? (e) => fun1(e) : () => handleMenu(2)}
              >
                {seguidores} Seguidores
              </li>
            </div>
            <div
              className={`${
                matchIpadPro
                  ? ""
                  : matchIpadMini
                  ? "w-125 w-lg-30"
                  : "w-109 w-lg-30"
              }`}
            >
              {" "}
              <li
                className={`cursor-pointer w-100 text-center text-small text-md-medium ${
                  matchIpadPro
                    ? "pt-4 pb-7"
                    : matchIpadMini
                    ? "pt-4 pb-7 w-lg-80"
                    : "py-3"
                } ${
                  optthird && !matchDesktop
                    ? "a-text-medium-first-primary-60 border-bottom-second-primary-50"
                    : optthird && matchDesktop
                    ? "a-text-medium-first-primary-60 border-bottom-second-primary-50"
                    : ""
                }`}
                onClick={fun2 != null ? (e) => fun2(e) : () => handleMenu(3)}
              >
                {seguidos} Seguidos
              </li>
            </div>
            {matchIpadMini && (
              <div className={`ml-auto mr-1`}>
                <img
                  onClick={switchView}
                  className={`mt-4 cursor-pointer`}
                  width={27}
                  height={24}
                  src={lista ? Lista : Mosaico}
                ></img>
              </div>
            )}
          </Grid>
        </Grid>
        <div className="hr-nav"></div>
      </Grid>
    </Grid>
  );
}
