import { useState } from 'react';

export const useBusquedaColegas = () => {
	const [buscarColegas, setBuscarColegas] = useState(false);
	const [inputSearch, setInputSearch] = useState();

	function desplegarBusqueda(e) {
		e.preventDefault();
		if (buscarColegas === false) {
			setBuscarColegas(true);
		}
	}

	function cerrarBusqueda() {
		if (buscarColegas === true) {
			setBuscarColegas(false);
			document.getElementById('buscar').value = '';
			setInputSearch();
		}
		console.log('buenas2');
	}

	function buscarSeguidor(e) {
		e.preventDefault();
		let input = e.target.value;
		setInputSearch(input);
	}

	return {
		inputSearch,
		buscarColegas,
		desplegarBusqueda,
		cerrarBusqueda,
		buscarSeguidor,
		setBuscarColegas,
	};
};
