//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";
export default function SkeletonCidUser({ num }) {
  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  return arraySkeleton.map((r) => (
    <div className=" d-flex w-100">
      <div className="card mb-3 h-a d-flex br-2  px-4  py-3 align-items-center box-shadow-lg-all w-100">
        <div className="d-flex align-items-center w-100 ">
          <Skeleton
            variant="circle"
            width="20px"
            height="20px"
            className="mr-3"
            animation="wave"
          />
          <Skeleton variant="h3" width="50%" animation="wave" />
        </div>
      </div>
    </div>
  ));
}
