// React
import React from "react";

export default function EmptyState({ word }) {
  return (
    <p className="text-center a-text-bold-second-neutral-40 h3 mt-4 mb-4 mt-lg-5 mb-lg-5">
      No se han encontrado resultados {word ? `para "${word}"` : ""}
    </p>
  );
}
