export function Label({ text }) {
  return (
    <label>
      <p style={{
        marginTop: 2,
        padding: 0,
        fontSize: 15,
      }}
        className={`pl-lg-1 ${"a-text-medium-second-neutral-70"} mb-md-2 
      ${"a-text-medium-second-neutral-70"}
      `}
      >
        {text}
      </p>
    </label>
  );
}
