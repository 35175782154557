import { useState } from "react";
import styles from "./styles.module.css";

export default function HashtagsInput({ hashtags, setHashtags }) {
  const [newHashtag, setNewHashtag] = useState("");

  function handleNewHashtag(event) {
    const inputValue = event.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");

    setNewHashtag(sanitizedValue);
  }

  function handleAddHashtag(event) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();
    if (event.key === "Enter" && trimmedValue) {
      setHashtags((h) => [
        ...h,
        {
          hashtag_id: crypto.randomUUID(),
          hashtag: trimmedValue,
        },
      ]);
      setNewHashtag("");
    }
  }

  function handleEdit(event, hashtagId) {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.trim();
    if (inputValue === "") {
      handleDelete(hashtagId);
      return;
    }
    setHashtags((h) =>
      h.map((hashtag) => {
        if (hashtag.hashtag_id === hashtagId) {
          const hashtag = h.find((hashtag) => hashtag.hashtag_id === hashtagId);
          hashtag.hashtag = trimmedValue
            ? trimmedValue // if the value is not empty, update the hashtag
            : hashtag.hashtag; // if the value is empty, keep the old value
        }
        return hashtag;
      }),
    );
  }

  function handleDelete(hashtagId) {
    setHashtags((h) => h.filter((hashtag) => hashtag.hashtag_id !== hashtagId));
  }

  return (
    <div className={styles.hashtags}>
      <label htmlFor="hashtagsField">Hashtags *</label>
      <div className={styles.hashtagsInputContainer}>
        <div className={styles.hashtagsContainer}>
          {hashtags.map(({ hashtag, hashtag_id }) => (
            <div key={hashtag_id}>
              <input
                value={hashtag}
                onChange={(e) => handleEdit(e, hashtag_id)}
                className={styles.hashtagInput}
              />
              <button type="button" onClick={() => handleDelete(hashtag_id)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 8 8"
                  fill="currentColor"
                >
                  <path
                    d="M4.93909 3.99943L7.80299 1.14179C7.92841 1.01635 7.99886 0.846231 7.99886 0.668843C7.99886 0.491455 7.92841 0.321332 7.80299 0.1959C7.67758 0.0704672 7.50748 0 7.33011 0C7.15275 0 6.98265 0.0704672 6.85724 0.1959L4 3.06021L1.14276 0.1959C1.01735 0.0704672 0.847248 -1.32164e-09 0.669885 0C0.492522 1.32165e-09 0.322423 0.0704672 0.197008 0.1959C0.0715939 0.321332 0.00113664 0.491455 0.00113663 0.668843C0.00113663 0.846231 0.0715939 1.01635 0.197008 1.14179L3.06091 3.99943L0.197008 6.85708C0.134583 6.919 0.085035 6.99267 0.0512219 7.07385C0.0174088 7.15502 0 7.24208 0 7.33002C0 7.41795 0.0174088 7.50502 0.0512219 7.58619C0.085035 7.66737 0.134583 7.74104 0.197008 7.80296C0.258924 7.8654 0.332587 7.91495 0.413748 7.94877C0.494909 7.98259 0.581962 8 0.669885 8C0.757808 8 0.844861 7.98259 0.926022 7.94877C1.00718 7.91495 1.08085 7.8654 1.14276 7.80296L4 4.93866L6.85724 7.80296C6.91915 7.8654 6.99282 7.91495 7.07398 7.94877C7.15514 7.98259 7.24219 8 7.33011 8C7.41804 8 7.50509 7.98259 7.58625 7.94877C7.66741 7.91495 7.74108 7.8654 7.80299 7.80296C7.86542 7.74104 7.91497 7.66737 7.94878 7.58619C7.98259 7.50502 8 7.41795 8 7.33002C8 7.24208 7.98259 7.15502 7.94878 7.07385C7.91497 6.99267 7.86542 6.919 7.80299 6.85708L4.93909 3.99943Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          ))}
          <input
            id="hashtagsField"
            className={styles.hashtagsField}
            placeholder="tecnología"
            value={newHashtag}
            onChange={handleNewHashtag}
            onKeyDown={handleAddHashtag}
          />
        </div>
      </div>
    </div>
  );
}
