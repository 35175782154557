import React from "react";

export const Orders = ({orders, setOrders}) => {

  const labels = [
    {
      name: "Recientes",
      value: 1,
    },
    {
      name: "Antiguos",
      value: 2,
    },
    {
      name: "Activos",
      value: 3,
    },
    {
      name: "Inactivos",
      value: 4,
    }
  ];


  const handleOnChange = (value) => {
    if(orders == value){
        setOrders()
    }else {
        setOrders(value);
    }
  };

  return (
    <div className="mb-2">
      {labels.map((label, index) => (
        <div className="mb-3 d-flex" key={label.value}>
          <input
            onChange={() => handleOnChange(label.value)}
            checked={orders === label.value}
            type="checkbox"
            id={label.name}
            className=" radioNews cursor-pointer check-blog-filter"
            name={label.name}
            value={label.value}
          />
          <label
            className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
            htmlFor={label.name}
          >
            {label.name}
          </label>
        </div>
      ))}
    </div>
  );
};
