//React
import React, { useState, useEffect, useRef, useContext } from "react";
import { PodcastContext } from "../../../../providers/PodcastContext";
import Moment from "react-moment";
// Material
import { Grid, IconButton, useMediaQuery, Tooltip } from "@material-ui/core";
import LikeComponent from "../CardQuestion/LikeComponent";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useHistory } from "react-router-dom";

import { ProfileGeneral } from "../ProfileComponent/ProfileGeneral";

//API's
import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";

import Compartir from "../../../../assets/img/icon/compartir.svg";
import Copy from "../../../../assets/img/news/copiar-enlace.svg";
import Correo from "../../../../assets/img/social/correo.svg";
import Whats from "../../../../assets/img/social/whats.svg";

import Play from "../../../../assets/img/icon/PlayIcon.svg";
import Pause from "../../../../assets/img/icon/pausa.svg";

import Save from "../../../../assets/img/news/guardar-1.svg";
import SaveActive from "../../../../assets/img/news/guardar-2.svg";
import Tag from "../../../../assets/img/blogs/etiqueta.svg";
import Add from "../../../../assets/img/icon/agregar.svg";

export function CardPodcast({
  info,
  userId,
  setElement,
  setOpenWarning,
  openWarning,
}) {
  const { setPodcast, setActive } = useContext(PodcastContext);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const phoneMatch = useMediaQuery("(max-width: 480px)");

  let history = useHistory();

  function copyToClipboard() {
    // const url = window.location.href + `/${blog_id}`;
    // navigator.clipboard.writeText(url);
  }
  // const [isShare, setIsShare] = useState(false);
  // const [duration, setDuration] = useState("00:00");

  // getSound(info.audio_podcast);

  // const toTime = (value) => {
  //   var hours = Math.floor(value / 3600).toString();
  //   var minutes = Math.floor((value - hours * 3600) / 60).toString();
  //   var seconds = Math.ceil(value - hours * 3600 - minutes * 60).toString();

  //   if (hours < 10) {
  //     hours = "0" + hours;
  //   }
  //   if (minutes < 10) {
  //     minutes = "0" + minutes;
  //   }
  //   if (seconds < 10) {
  //     seconds = "0" + seconds;
  //   }

  //   let time = `${hours != "00" ? hours + ":" : ""}${minutes + ":"}${
  //     seconds != "00" ? seconds : ""
  //   }`;

  //   return time;
  // };

  // function getSound(file) {
  //   var audio = new Audio(file);
  //   audio.addEventListener("loadeddata", () => {
  //     let duration = audio.duration;
  //     setDuration(toTime(duration));
  //   });
  // }
  const handleClickAdd = () => {
    history.push({
      pathname: "/cid/carso/podcast/upload/",
      state: { program: info },
    });
  }

  const handleOnClick = () => {
    history.push({
      pathname: "/cid/carso/podcast/list/program/" + info.program_podcast_id,
      state: { podcast: info },
    });
  };

  // const handleClickDelete = () => {
  //   setOpenWarning(!openWarning);
  //   setElement(info.podcast_id);
  // };

  return (
    <Grid
      item
      sm={6}
      md={12}
      xs={12}
      className="box-shadow-lg-all card-podcast py-3"
      style={{ marginBlockEnd: "15px", marginInlineEnd: "10px" }}
    >
      {/* <div className="box-shadow-lg-all card-podcast py-3"> */}
      <div className="d-flex flex-column align-items-center justify-content-lg-between">
        <div className="container-image-podcast-vertical cursor-pointer">
          <img
            src={`https://images.weserv.nl/?url=${info.cover}&maxage=31d`}
            alt="icon"
            className="image-podcast-list-vertical mb-2"
            onClick={handleOnClick}
          />
        </div>
        
        <div className="w-80 mb-2 mt-2 cursor-pointer" onClick={handleOnClick}>
          <p className="text-normal text-lg-big a-text-medium">{info.title}</p>
        </div>
        <div className="d-flex justify-content-start w-80">
          <ProfileGeneral userId={userId} type={"onlyProfile"} info={info} />
        </div>
        <div className="mb-2 w-80 d-flex overflow-scroll align-items-center">
          <img
            src={Tag}
            alt="icon"
            className={`tag-icon mr-1 ml-1  ${
              info.labelsPodcast.length != 0 ? "" : "d-none"
            }`}
          />
          {info.labelsPodcast?.length == 0 ? (
            <div></div>
          ) : (
            <>
              {info.labelsPodcast?.map((res, idx) =>
                res.name !== "undefined" ? (
                  <span
                    className="text-xs a-text-regular-first-neutral-100 mr-1 "
                    style={{"white-space": "nowrap"}}
                    key={idx}
                  >
                    {` ${res.name} `}{" "}
                    {info.labelsPodcast.length > 1 &&
                    idx != info.labelsPodcast.length - 1
                      ? ", "
                      : ""}
                  </span>
                ) : (
                  <span></span>
                )
              )}
            </>
          )}
        </div>
        <div className="d-flex justify-content-between w-80 mt-1">
          <IconButton className={"cursor-pointer"} size="small">
            <img src={Compartir} alt="Share" width="22px" />
          </IconButton>
          {userId == info.user_id ? (
            <>
              <div
                onClick={() => {handleClickAdd()}}
              >
                <Tooltip title="Agregar episodio" >
                <IconButton
                  className={"cursor-pointer"} size="small"
                >
                  <img src={Add} alt="Opt" width="22px" />
                </IconButton>   
              </Tooltip>
              </div>
            </>
          ) : (
            <div>
              <IconButton className={"cursor-pointer"} size="small">
                <img src={Save} alt="Opt" width="20px" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </Grid>
  );
}
