// React
import React, { useState } from "react";
import { Redirect } from "react-router-dom";

// Material
import { Grid } from "@material-ui/core/";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";

// Componentes
import { FormAddReport } from "../../../../components/organisms/FormAddReport";
import Header from "../../../../components/molecules/Header";

export default function AddReport(e) {
  // State
  const user = useAuth();
  const { userId } = user;
  if (!user) {
    return <Redirect to="/" />;
  }
  if (user.rolId == 3) {
    return <Redirect to="/cid/carso/home" />;
  }

  return (
    <Grid container justify="center" spacing={0} className="">
      <Header
        isCollapsed={e.isCollapsed}
        link="/cid/search-analysis"
        text="Buscar análisis"
      />
      {user.rolId === 3 ? (
        ""
      ) : (
        <Grid
          item
          xs={10}
          lg={12}
          className="background-lg-first-neutral-10 container  h-100vh-header scroll-lg-overflow br-2"
        >
          <div className="background-lg-white mt-4 m-lg-4 p-lg-4 br-2">
            <FormAddReport userId={userId} />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
