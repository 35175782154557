import React from 'react'
import Pagination from '@material-ui/lab/Pagination';
import { useMediaQuery } from '@material-ui/core';

//Arrows
import ArrowLeft from "../../../assets/img/arrowLeft.svg";
import ArrowRight from "../../../assets/img/arrowright.svg";

export default function Paginations({ postPerPage, totalCompanies, paginate, currentPage, className }) {
  const ipadProMatch = useMediaQuery("(min-width: 992px)")

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalCompanies / postPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChange = (event, value) => {
    paginate(value);


  };

  function jumpnext() {
    const nextPage = currentPage + 1
    paginate(nextPage);

  }
  function jumpback() {
    const backPage = currentPage - 1
    paginate(backPage);

  }
  return (

    <>
      {ipadProMatch ?
        <div className={`${className} d-flex justify-content-between p-2 `}>
          <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer ${currentPage <= 1 ? "disable disabled" : "a-text-regular-second-primary-70"}`} onClick={jumpback}>
          {/* <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer`} onClick={jumpback}> */}
            <img src={ArrowRight} alt="ArrowLeft" className="mr-3" />
          Anterior
          </button>

          <Pagination className="text-pagination text-normal" count={pageNumbers.length} shape="rounded" page={currentPage} onChange={handleChange} hideNextButton={true} hidePrevButton={true} />
          <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer ${currentPage >= pageNumbers.length ? "disable disabled" : "a-text-regular-second-primary-70"}`} onClick={jumpnext}>
          {/* <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer`} onClick={jumpnext}> */}
            Siguiente
            <img src={ArrowLeft} alt="ArrowRight" className="ml-3" />

          </button>

        </div>
        :
        // <Pagination    count={pageNumbers.length} shape="rounded" page={currentPage}  siblingCount={-6}  onChange={handleChange}/>

        <div className={`${className} d-flex justify-content-between p-2 text-normal`}>
          <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer ${currentPage <= 1 ? "disable disabled" : "a-text-regular-second-primary-70"}`} onClick={jumpback}>
          {/* <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer `} onClick={jumpback}> */}
            <img src={ArrowRight} alt="ArrowLeft" className="mr-3" />
                  Anterior
               </button>
          <span className="a-text-regular-second-neutral-60 text-normal"> {currentPage} </span>
          {/* <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer`} onClick={jumpnext}> */}
          <button className={`btn-pagination text-normal d-flex align-items-center cursor-pointer ${currentPage >= pageNumbers.length ? "disable disabled" : "a-text-regular-second-primary-70"}`} onClick={jumpnext}>
            Siguiente
                 <img src={ArrowLeft} alt="ArrowRight" className="ml-3" />
          </button>

        </div>
      }
    </>
  )
}
