import React, { useEffect, useState } from "react";

// Material
import FilterIcon from "../../../../assets/img/whitefilter.svg";
import FilterIconAzul from "../../../../assets/img/filtrar.svg";
import closeIcon from "../../../../assets/img/closeFilter.svg";
import EliminarFiltro from "../../../../assets/img/eliminar_filtros.svg";
import EliminarFiltroDisabled from "../../../../assets/img/eliminarFiltroDisabled.svg";

import {
  getIndustrias,
  getMercados,
  getSectors,
  getSubtechnology,
  getTechnology,
} from "../../../../api/company";
import {
  getClients,
  getMarketsReport,
  getSectorsReport,
} from "../../../../api/report";
import { Error500connection } from "../../Error/500";
import { IconButton } from "@material-ui/core";

export default function Filter({
  filter,
  type,
  userId,
  onClick,
  globalFilterTech,
  setGlobalFilterTech,
  globalFilterSector,
  setGlobalFilterSector,
  globalFilterClient,
  setGlobalFilterClient,
  globalFilterMercado,
  setGlobalFilterMercado,
  globalFilterIndustria,
  setGlobalFilterIndustria,
  globalFilterSubtech,
  setGlobalFilterSubtech,
}) {

  const [mostrarSectores, setMostrarSectores] = useState(true);
  const [mostrarIndustrias, setMostrarIndustrias] = useState(false);
  const [mostrarTecnologias, setMostrarTecnologias] = useState(false);
  const [mostrarSubtechs, setMostrarSubtechs] = useState(false);
  const [mostrarMercados, setMostrarMercados] = useState(false);
  const [mostrarClientes, setMostrarClientes] = useState(false);

  const [error, setError] = useState(false);

  const [sectors, setSectors] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [clients, setClients] = useState([]);
  const [industrias, setIndustrias] = useState([]);
  const [subtechs, setSubtechs] = useState([]);

  const [mercados, setMercados] = useState([]);

  const [filtroSectores, setFiltroSectores] = useState([]);
  const [filtroIndustrias, setFiltroIndustrias] = useState([]);
  const [filtroTecnologias, setFiltroTecnologias] = useState([]);
  const [filtroSubtechs, setFiltroSubtechs] = useState([]);
  const [filtroMercados, setFiltroMercados] = useState([]);

  useEffect(() => {
    if (filter) {
      if (type == "company") {
        const fetchSectors = async () => {
          const { data } = await getSectors(userId);
          setSectors(data.sectores);
        };

        const fetchTechnologies = async () => {
          const { data } = await getTechnology(userId);
          setTechnologies(data.technology);
        };

        const fetchMercados = async () => {
          const { data } = await getMercados(userId);
          setMercados(data.mercados);
        };

        const fetchIndustrias = async () => {
          const { data } = await getIndustrias();
          setIndustrias(data.industrias);
        };

        const fetchSubtechs = async () => {
          const { data } = await getSubtechnology();
          setSubtechs(data.subTechnology);
        };

        fetchSectors().catch((error) => {
          setError(true);
          console.log(error);
        });

        fetchTechnologies().catch((error) => {
          setError(true);
          console.log(error);
        });

        fetchMercados().catch((error) => {
          setError(true);
          console.log(error);
        });

        fetchIndustrias().catch((error) => {
          setError(true);
          console.log(error);
        });

        fetchSubtechs().catch((error) => {
          setError(true);
          console.log(error);
        });
      } else {
        const fetchSectorsReports = async () => {
          const { data } = await getSectorsReport(userId);
          setSectors(data.sectors);
        };

        const fetchTechsReports = async () => {
          const { data } = await getMarketsReport(userId);
          setTechnologies(data.markets);
        };

        const fetchClientsReports = async () => {
          const { data } = await getClients(userId);
          setClients(data.clientes);
        };

        // const clients = getClients(userId);
        // clients.then((result) => {
        //   if (result.status === 200) {
        //     setClientes(result.data.clientes);
        //   }
        // });

        fetchSectorsReports().catch((err) => {
          setError(true);
          console.log(err);
        });
        fetchTechsReports().catch((err) => {
          setError(true);
          console.log(err);
        });
        fetchClientsReports().catch((err) => {
          setError(true);
          console.log(err);
        });
      }
    }
  }, [filter]);

  const handleOnChangeSectors = (e, position) => {
    // let updatedChecked = checkedSectors.map((item, index) => index === position ? !item : item);
    // setCheckedSectors(updatedChecked);
    // checkedUpdatedSectors = checkInit.map((item, index) => index === position ? !item : item);
    if (e.target.checked) {
      setFiltroSectores([...filtroSectores, e.target.value]);
    } else {
      setFiltroSectores(
        filtroSectores.filter(
          (sectorSelected) => sectorSelected !== e.target.value
        )
      );
    }

    if (
      globalFilterSector.filter((elm) => elm.name === sectors[position].name)
        .length > 0
    ) {
      let aux = globalFilterSector.filter((el) => {
        return el.name !== sectors[position]?.name;
      });
      setGlobalFilterSector(aux);
    } else {
      setGlobalFilterSector([
        ...globalFilterSector,
        { id: sectors[position].sector_id, name: sectors[position].name },
      ]);
    }
  };

  const handleOnChangeIndustrias = (e, position) => {
    // const updatedChecked = checkedIndustrias.map((item, index) =>
    //   index === position ? !item : item
    // );
    // setCheckedIndustrias(updatedChecked);
    if (e.target.checked) {
      setFiltroIndustrias([...filtroIndustrias, e.target.value]);
    } else {
      setFiltroIndustrias(
        filtroIndustrias.filter(
          (industriaSelected) => industriaSelected !== e.target.value
        )
      );
    }

    if (
      globalFilterIndustria.filter(
        (elm) => elm.name === industrias[position].name
      ).length > 0
    ) {
      let aux = globalFilterIndustria.filter((el) => {
        return el.name !== industrias[position]?.name;
      });
      setGlobalFilterIndustria(aux);
    } else {
      setGlobalFilterIndustria([
        ...globalFilterIndustria,
        {
          id: industrias[position].industria_id,
          name: industrias[position].name,
        },
      ]);
    }
  };

  const handleOnChangeTech = (e, position) => {
    // const updatedChecked = checkedTech.map((item, index) =>
    //   index === position ? !item : item
    // );
    // setCheckedTech(updatedChecked);

    if (e.target.checked) {
      setFiltroTecnologias([...filtroTecnologias, e.target.value]);
    } else {
      setFiltroTecnologias(
        filtroTecnologias.filter(
          (tecnologiaSelected) => tecnologiaSelected !== e.target.value
        )
      );
    }

    if (
      globalFilterTech.filter((elm) => elm.name === technologies[position].name)
        .length > 0
    ) {
      let aux = globalFilterTech.filter((el) => {
        return el.name !== technologies[position]?.name;
      });
      setGlobalFilterTech(aux);
    } else {
      setGlobalFilterTech([
        ...globalFilterTech,
        {
          id: technologies[position].market_id,
          name: technologies[position].name,
        },
      ]);
    }
  };

  const handleOnChangeSubtech = (e, position) => {
    // const updatedChecked = checkedSubtech.map((item, index) =>
    //   index === position ? !item : item
    // );
    // setCheckedSubtech(updatedChecked);

    if (e.target.checked) {
      setFiltroSubtechs([...filtroSubtechs, e.target.value]);
    } else {
      setFiltroSubtechs(
        filtroSubtechs.filter(
          (subtechSelected) => subtechSelected !== e.target.value
        )
      );
    }

    if (
      globalFilterSubtech.filter(
        (elm) => elm.name === subtechs[position].Subtechnology
      ).length > 0
    ) {
      let aux = globalFilterSubtech.filter((el) => {
        return el.name !== subtechs[position]?.Subtechnology;
      });
      setGlobalFilterSubtech(aux);
    } else {
      setGlobalFilterSubtech([
        ...globalFilterSubtech,
        {
          id: subtechs[position].tech2_id,
          name: subtechs[position].Subtechnology,
        },
      ]);
    }
  };

  const handleOnChangeMercado = (e, position) => {
    // const updatedChecked = checkedMercados.map((item, index) =>
    //   index === position ? !item : item
    // );
    // setCheckedMercados(updatedChecked);

    if (e.target.checked) {
      setFiltroMercados([...filtroMercados, e.target.value]);
    } else {
      setFiltroMercados(
        filtroMercados.filter(
          (mercadoSelected) => mercadoSelected !== e.target.value
        )
      );
    }

    if (
      globalFilterMercado.filter((elm) => elm.name === mercados[position].name)
        .length > 0
    ) {
      let aux = globalFilterMercado.filter((el) => {
        return el.name !== mercados[position]?.name;
      });
      setGlobalFilterMercado(aux);
    } else {
      setGlobalFilterMercado([
        ...globalFilterMercado,
        { id: mercados[position].mercado_id, name: mercados[position].name },
      ]);
    }
  };

  // Reports

  const handleOnChangeSectorsReports = (e, position) => {
    // const updatedChecked = checkedSectors.map((item, index) => index === position ? !item : item);
    // setCheckedSectors(updatedChecked);

    if (
      globalFilterSector.filter((elm) => elm.name === sectors[position].name)
        .length > 0
    ) {
      let aux = globalFilterSector.filter((el) => {
        return el.name !== sectors[position]?.name;
      });
      setGlobalFilterSector(aux);
    } else {
      setGlobalFilterSector([
        ...globalFilterSector,
        {
          id: sectors[position].sector_analisis_id,
          name: sectors[position].name,
        },
      ]);
    }
  };

  const handleOnChangeTechReports = (e, position) => {
    // const updatedChecked = checkedTech.map((item, index) =>
    //   index === position ? !item : item
    // );
    // setCheckedTech(updatedChecked);

    if (
      globalFilterTech.filter((elm) => elm.name === technologies[position].name)
        .length > 0
    ) {
      let aux = globalFilterTech.filter((el) => {
        return el.name !== technologies[position]?.name;
      });
      setGlobalFilterTech(aux);
    } else {
      setGlobalFilterTech([
        ...globalFilterTech,
        {
          id: technologies[position].tecnologia_analisis_id,
          name: technologies[position].name,
        },
      ]);
    }
  };

  const handleOnChangeClientsReport = (e, position) => {
    // const updateChecked = checkedClients.map((item) =>
    //   item === position ? !item : item
    // );
    // setCheckedClients(updateChecked);

    if (
      globalFilterClient.filter((elm) => elm.name === clients[position].name)
        .length > 0
    ) {
      let aux = globalFilterClient.filter((elm) => {
        return elm.name !== clients[position].name;
      });
      setGlobalFilterClient(aux);
    } else {
      setGlobalFilterClient([
        ...globalFilterClient,
        { id: clients[position].client_id, name: clients[position].name },
      ]);
    }
  };

  function handleMenu(id) {
    setMostrarSectores(false);
    setMostrarIndustrias(false);
    setMostrarTecnologias(false);
    setMostrarSubtechs(false);
    setMostrarMercados(false);
    setMostrarClientes(false);
    switch (id) {
      case 1:
        setMostrarSectores(true);
        break;
      case 2:
        setMostrarIndustrias(true);
        break;
      case 3:
        setMostrarTecnologias(true);
        break;
      case 4:
        setMostrarSubtechs(true);
        break;
      case 5:
        setMostrarMercados(true);
        break;
      case 6:
        setMostrarClientes(true);
        break;
      default:
        break;
    }
  }

  const eliminarFiltros = () => {
    if (type === "company") {
      setGlobalFilterSector([]);
      setFiltroSectores([]);
      setFiltroIndustrias([]);
      setFiltroTecnologias([]);
      setFiltroSubtechs([]);
      setFiltroMercados([]);
      setGlobalFilterIndustria([]);
      setGlobalFilterTech([]);
      setGlobalFilterSubtech([]);
      setGlobalFilterMercado([]);
    } else {
      setGlobalFilterSector([]);
      setGlobalFilterTech([]);
      setGlobalFilterClient([]);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <p className="a-text-regular-first-primary-60 align-self-center text-medium">
          Filtrar por:
        </p>
        <div className="mr-3">
          <IconButton onClick={onClick}>
            <img
              src={closeIcon}
              className="filter-label-icon width-cerrar-icon"
              alt="Close"
            />
          </IconButton>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div
          disabled={error}
          onClick={error ? "" : eliminarFiltros}
          className={`d-flex align-items-center mr-lg-3 py-2 px-2 br-1 mr-2  ${
            error
              ? "background-first-neutral-40"
              : "background-white cursor-pointer border-first-primary-50"
          } `}
        >
          <img
            src={error ? EliminarFiltroDisabled : EliminarFiltro}
            alt="Order"
            className="img-order"
          />
          <p
            className={`ml-2 text-small text-md-normal ${
              error
                ? "a-text-regular-third-neutral"
                : "a-text-regular-first-primary-60"
            } `}
          >
            Eliminar filtros
          </p>
        </div>

        <div
          disabled={error}
          onClick={error ? "" : onClick}
          className={`d-flex align-items-center mr-lg-3 ${
            error
              ? "background-first-neutral-40"
              : "background-first-primary-60 cursor-pointer"
          } py-2 px-2 br-1 mr-2`}
        >
          <img src={FilterIcon} alt="Order" className="img-order" />
          <p className="ml-2 text-small text-md-normal a-text-regular-third-neutral">
            Filtrar
          </p>
        </div>
      </div>
      <div className="my-3">
        {type == "company" ? (
          <div>
            <div className="mb-4">
              <div className="d-flex flex-wrap">
                {globalFilterSector.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Sectores:
                  </p>
                )}
                {globalFilterSector &&
                  globalFilterSector.map((sector) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {sector.name}
                    </p>
                  ))}
              </div>

              <div className="d-flex flex-wrap">
                {globalFilterIndustria.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Industrias:
                  </p>
                )}
                {globalFilterIndustria &&
                  globalFilterIndustria.map((industria) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {industria.name}
                    </p>
                  ))}
              </div>

              <div className="d-flex flex-wrap">
                {globalFilterTech.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Tecnologías Primarías:
                  </p>
                )}
                {globalFilterTech &&
                  globalFilterTech.map((tech) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {tech.name}
                    </p>
                  ))}
              </div>

              <div className="d-flex flex-wrap">
                {globalFilterSubtech.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Tecnologías Secundarias:
                  </p>
                )}
                {globalFilterSubtech &&
                  globalFilterSubtech.map((subtech) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {subtech.name}
                    </p>
                  ))}
              </div>

              <div className="d-flex flex-wrap">
                {globalFilterMercado.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Mercados:
                  </p>
                )}
                {globalFilterMercado &&
                  globalFilterMercado.map((mercado) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {mercado.name}
                    </p>
                  ))}
              </div>
              {/*  
                  
                {globalFilterTech.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Tecnologías Primarías:
                  </p>
                )}
                {globalFilterTech &&
                  globalFilterTech.map((tech) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {tech.name}
                    </p>
                  ))}
                  {globalFilterSubtech.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Tecnologías Secundarías:
                  </p>
                )}
                {globalFilterSubtech &&
                  globalFilterSubtech.map((subtech) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {subtech.name}
                    </p>
                  ))}
                {globalFilterMercado.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Mercados:
                  </p>
                )}
                {globalFilterMercado &&
                  globalFilterMercado.map((mercado) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {mercado.name}
                    </p>
                  ))} */}
            </div>

            {error ? (
              <Error500connection />
            ) : (
              <div>
                <div className="d-flex flex-nowrap scroll-overflow no-whitespace ">
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarSectores
                        ? " border-bottom-second-primary-50 a-text-medium-first-primary-60"
                        : "a-text-regular-second-neutral-60"
                    }`}
                    onClick={() => handleMenu(1)}
                  >
                    Sectores
                  </p>
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarIndustrias
                        ? " border-bottom-second-primary-50 a-text-medium-first-primary-60"
                        : "a-text-regular-second-neutral-60"
                    }`}
                    onClick={() => handleMenu(2)}
                  >
                    Industrias
                  </p>
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarTecnologias
                        ? " border-bottom-second-primary-50 a-text-medium-first-primary-60"
                        : "a-text-regular-second-neutral-60"
                    }`}
                    onClick={() => handleMenu(3)}
                  >
                    Tecnologías Primarías
                  </p>
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarSubtechs
                        ? " border-bottom-second-primary-50 a-text-medium-first-primary-60"
                        : "a-text-regular-second-neutral-60"
                    }`}
                    onClick={() => handleMenu(4)}
                  >
                    Tecnologías Secundarías
                  </p>
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarMercados
                        ? " border-bottom-second-primary-50 a-text-medium-first-primary-60"
                        : "a-text-regular-second-neutral-60"
                    }`}
                    onClick={() => handleMenu(5)}
                  >
                    Mercados
                  </p>
                </div>
                <div className="hr-nav mb-3"></div>
                {mostrarSectores &&
                  sectors.map((sector, index) => {
                    return (
                      <div className="mb-3 d-flex" key={sector.sector_id}>
                        <input
                          onChange={(e) => handleOnChangeSectors(e, index)}
                          checked={filtroSectores.find(
                            (sectorSeleccionado) =>
                              sectorSeleccionado == sector.name
                          )}
                          type="checkbox"
                          id={sector.name}
                          className=" radioNews cursor-pointer check-blog-filter"
                          name={sector.name}
                          value={sector.name}
                        />
                        <label
                          className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                          htmlFor={sector.name}
                        >
                          {sector.name}
                        </label>
                      </div>
                    );
                  })}

                {mostrarIndustrias &&
                  industrias.map((industria, index) => (
                    <div className="mb-3 d-flex" key={industria.name}>
                      <input
                        onChange={(e) => handleOnChangeIndustrias(e, index)}
                        checked={filtroIndustrias.find(
                          (industriaSeleccionada) =>
                            industriaSeleccionada == industria.name
                        )}
                        type="checkbox"
                        id={industria.name}
                        className=" radioNews cursor-pointer check-blog-filter"
                        name={industria.name}
                        value={industria.name}
                      />
                      <label
                        className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                        htmlFor={industria.name}
                      >
                        {industria.name}
                      </label>
                    </div>
                  ))}

                {mostrarTecnologias &&
                  technologies.map((tech, index) => (
                    <div className="mb-3 d-flex" key={tech.name}>
                      <input
                        onChange={(e) => handleOnChangeTech(e, index)}
                        checked={filtroTecnologias.find(
                          (tecnologiaSeleccionada) =>
                            tecnologiaSeleccionada == tech.name
                        )}
                        type="checkbox"
                        id={tech.name}
                        className=" radioNews cursor-pointer check-blog-filter"
                        name={tech.name}
                        value={tech.name}
                      />
                      <label
                        className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                        htmlFor={tech.name}
                      >
                        {tech.name}
                      </label>
                    </div>
                  ))}

                {mostrarSubtechs &&
                  subtechs.map((subtech, index) => {
                    return (
                      <div className="mb-3 d-flex" key={subtech.Subtechnology}>
                        <input
                          onChange={(e) => handleOnChangeSubtech(e, index)}
                          checked={filtroSubtechs.find(
                            (subtechSeleccionado) =>
                              subtechSeleccionado == subtech.Subtechnology
                          )}
                          type="checkbox"
                          id={subtech.Subtechnology}
                          className=" radioNews cursor-pointer check-blog-filter"
                          name={subtech.Subtechnology}
                          value={subtech.Subtechnology}
                        />
                        <label
                          className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                          htmlFor={subtech.Subtechnology}
                        >
                          {subtech.Subtechnology}
                        </label>
                      </div>
                    );
                  })}

                {mostrarMercados &&
                  mercados.map((mercado, index) => {
                    return (
                      <div className="mb-3 d-flex" key={mercado.mercado_id}>
                        <input
                          onChange={(e) => handleOnChangeMercado(e, index)}
                          checked={filtroMercados.find(
                            (mercadoSeleccionado) =>
                              mercadoSeleccionado == mercado.name
                          )}
                          type="checkbox"
                          id={mercado.mercado_id}
                          className=" radioNews cursor-pointer check-blog-filter"
                          name={mercado.mercado_id}
                          value={mercado.name}
                        />
                        <label
                          className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                          htmlFor={mercado.mercado_id}
                        >
                          {mercado.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
            )}

            {/* <AccordionCid
                    type="SectoresEmpresas"
                    labels={labels}
                    name="Sectores"
                    data={sectors}
                    handleOnChange={handleOnChangeSectors}
                    checked={checkedSectors}
                /> */}
            {/* 
                  <AccordionCid
                    type="IndustriasEmpresas"
                    name="Industrias"
                    data={industria}
                    handleOnChange={handleOnChangeIndustrias}
                    checked={checkedIndustrias}
                />

                <AccordionCid
                    type="TecnologiasEmpresas"
                    name="Tecnologías Primarías"
                    data={technology}
                    handleOnChange={handleOnChangeTech}
                    checked={checkedTech}
                />

                <AccordionCid
                    type="SubtechsEmpresas"
                    name="Tecnologías Secundarías"
                    data={subtechs}
                    handleOnChange={handleOnChangeSubtech}
                    checked={checkedSubtech}
                />

              <AccordionCid
                    type="MercadosEmpresas"
                    name="Mercados"
                    data={mercados}
                    handleOnChange={handleOnChangeMercado}
                    checked={checkedMercados}
                /> */}
          </div>
        ) : (
          <div>
            <div className="mb-4">
              <div className="d-flex flex-wrap">
                {globalFilterSector.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Sectores:
                  </p>
                )}
                {globalFilterSector &&
                  globalFilterSector.map((sector) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {sector.name}
                    </p>
                  ))}
              </div>
              <div className="d-flex flex-wrap">
                {globalFilterTech.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Tecnologías:
                  </p>
                )}
                {globalFilterTech &&
                  globalFilterTech.map((tech) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {tech.name}
                    </p>
                  ))}
              </div>

              <div className="d-flex flex-wrap">
                {globalFilterClient.length > 0 && (
                  <p className="align-self-center text-small a-text-regular-first-primary-60 mr-1">
                    Tecnologías:
                  </p>
                )}
                {globalFilterClient &&
                  globalFilterClient.map((client) => (
                    <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1">
                      {client.name}
                    </p>
                  ))}
              </div>
            </div>

            {error ? (
              <Error500connection />
            ) : (
              <div>
                <div className="a-text-medium-first-primary-60 d-flex flex-nowrap scroll-overflow no-whitespace">
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarSectores ? " border-bottom-second-primary-50" : ""
                    }`}
                    onClick={() => handleMenu(1)}
                  >
                    Sectores
                  </p>
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarTecnologias
                        ? " border-bottom-second-primary-50"
                        : ""
                    }`}
                    onClick={() => handleMenu(3)}
                  >
                    Tecnologías
                  </p>
                  <p
                    className={`mr-4 pb-3 cursor-pointer ${
                      mostrarClientes ? " border-bottom-second-primary-50" : ""
                    }`}
                    onClick={() => handleMenu(6)}
                  >
                    Clientes
                  </p>
                </div>

                <div className="hr-nav mb-3"></div>

                {mostrarSectores &&
                  sectors.map((sector, index) => {
                    return (
                      <div
                        className="mb-3 d-flex"
                        key={sector.sector_analisis_id}
                      >
                        <input
                          onChange={(e) =>
                            handleOnChangeSectorsReports(e, index)
                          }
                          checked={globalFilterSector.find(
                            (sectorSeleccionado) =>
                              sectorSeleccionado.name == sector.name
                          )}
                          type="checkbox"
                          id={sector.sector_analisis_id}
                          className=" radioNews cursor-pointer check-blog-filter"
                          name={sector.name}
                          value={sector.name}
                        />
                        <label
                          className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                          htmlFor={sector.sector_analisis_id}
                        >
                          {sector.name}
                        </label>
                      </div>
                    );
                  })}

                {mostrarTecnologias &&
                  technologies.map((tech, index) => (
                    <div
                      className="mb-3 d-flex"
                      key={tech.tecnologia_analisis_id}
                    >
                      <input
                        onChange={(e) => handleOnChangeTechReports(e, index)}
                        checked={globalFilterTech.find(
                          (tecnologiaSeleccionada) =>
                            tecnologiaSeleccionada.name == tech.name
                        )}
                        type="checkbox"
                        id={tech.tecnologia_analisis_id}
                        className=" radioNews cursor-pointer check-blog-filter"
                        name={tech.name}
                        value={tech.name}
                      />
                      <label
                        className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                        htmlFor={tech.tecnologia_analisis_id}
                      >
                        {tech.name}
                      </label>
                    </div>
                  ))}

                <div className="d-flex flex-wrap">
                  {mostrarClientes &&
                    clients.map((client, index) => (
                      <div>
                        <input
                          onChange={(e) =>
                            handleOnChangeClientsReport(e, index)
                          }
                          checked={globalFilterClient.find(
                            (clientSelected) =>
                              clientSelected.name == client.name
                          )}
                          type="checkbox"
                          id={client.name}
                          className="d-none input-checkbox-img"
                        />
                        {client.logo_client ? (
                          <label
                            className="btn-checkbox-img btn-checkbox-img-add cursor-pointer"
                            htmlFor={client.name}
                          >
                            <img
                              className="checkbox-img"
                              src={`https://images.weserv.nl/?url=${client.logo_client}&maxage=31d`}
                              alt={client.name}
                            />
                          </label>
                        ) : (
                          <label
                            className={`btn-checkbox-img ${
                              client.name.length <= 7
                                ? "text-medium"
                                : "text-small"
                            }  pt-1 pb-1 pl-2 pr-2 cursor-pointer checkbox-img-text btn-checkbox-img-add`}
                            htmlFor={client.name}
                          >
                            {client.name}
                          </label>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}

            {/*       
            <AccordionCid
              type="SectoresAnalisis"
              name="Sectores"
              data={sectors}
              // handleOnChange={handleOnChangeSectorsReports}
              // checked={checkedSectors}
            />

            <AccordionCid
              type="TecnologiasAnalisis"
              name="Tecnologías"
              data={technologies}
              handleOnChange={handleOnChangeTechReports}
              // checked={checkedTech}
            />

            <AccordionCid
              type="clients"
              name="Clientes"
              data={clients}
              handleOnChange={handleChangeClientsReport}
              // checked={checkedClients}
            /> */}
          </div>
        )}
      </div>
    </>
  );
}
