import React from "react";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";
import { Button } from "../../atoms/Button/index";
import { CircularProgress } from "@material-ui/core";

export default function SuccessModal({ open, setOpen, message, action }) {
  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        {message === "Espera un momento..." ? (
          <CircularProgress color="#2e4a63" />
        ) : (
          <img src={SuccessIcon} alt="Success Icon" />
        )}
      </div>
      <p className="mb-4 a-text-medium-second-neutral-90 text-center">
        {message}
      </p>
      <div className="m-auto w-50" onClick={() => setOpen(false)}>
        {action === "Timmer" ? (
          <div />
        ) : (
          <Button
            type="button"
            classes="a-button-primary1 text-small"
            height={36}
            width="100%"
            text="Aceptar"
          />
        )}
      </div>
    </>
  );
}
