import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core/';
import FollowersList from '../../../../components/organisms/FollowersList/index';

export default function FollowList(e) {
	const location = useLocation();
	let other_id = location.state.userFollow;
	let selection = location.state.selected;

	return (
		<Grid
			container
			justify='center'
			spacing={0}
		>
			<Grid
				item
				xs={12}
				lg={12}
				className='background-lg-first-neutral-10 h-100vh-header scroll-overflow  br-2'
			>
				<div className='background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all'>
					<FollowersList
						userId={e.userId}
						other_id={other_id}
						selected={selection}
					></FollowersList>
				</div>
			</Grid>
		</Grid>
	);
}
