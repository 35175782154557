// Material
import { Grid } from '@material-ui/core';

// Componens
import { CardList } from "../../../../components/organisms/CardList"

export default function ReportsList(e) {
    return (
        <Grid container justify="center" spacing={0}>
            <Grid item xs={12}>
                <CardList type='report' titulo='análisis' userId={e.userId} isCollapsed={e.isCollapsed} linkSearch='/cid/search-analysis' linkAdd='/cid/add-analysis' />
            </Grid>
        </Grid>
    )
}