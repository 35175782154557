import React from "react";

// Material
import { Grid, useMediaQuery } from "@material-ui/core";

import Lista from "../../../assets/img/iconos-ml/lista.svg";
import Mosaico from "../../../assets/img/iconos-ml/mosaico.svg";

export default function TabMenu({
  opttwo,
  optthird,
  setOptTwo,
  setOptThird,
  viewType,
  setViewType,
}) {
  const phone = useMediaQuery("(max-width: 400px)");

  function handleMenu(id) {
    setOptTwo(false);
    setOptThird(false);
    switch (id) {
      case 2:
        setOptTwo(true);
        setOptThird(false);
        break;
      case 3:
        setOptThird(true);
        setOptTwo(false);
        break;
      default:
        break;
    }
  }
  return (
    <Grid item xs={12} md={12} lg={12} className="mt-3">
      <Grid
        item
        xs={12}
        className="mt-3 w-100 d-flex justify-content-center  flex-column "
      >
        <div className="hr-nav"></div>
        <Grid container justify="center" spacing={0}>
          <Grid
            item
            xs={12}
            md={12}
            className="d-flex justify-content-start list-style-none a-text-regular-second-neutral-60 "
          >
            <div className="w-100 w-lg-30">
              {" "}
              <li
                className={`cursor-pointer w-100 w-lg-80 text-center text-small text-md-medium py-2 ${
                  opttwo
                    ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50"
                    : ""
                }`}
                onClick={() => handleMenu(2)}
              >
                Recientes
              </li>
            </div>
            <div className="w-100 w-lg-30">
              {" "}
              <li
                className={`cursor-pointer w-100 w-lg-80 text-center text-small text-md-medium   py-2 ${
                  optthird
                    ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50"
                    : ""
                }`}
                onClick={() => handleMenu(3)}
              >
                Guardadas
              </li>
            </div>
            <div
              className={`ml-auto mr-1 ${
                phone ? "d-none" : " d-flex align-items-center"
              }`}
            >
              <img
                onClick={setViewType}
                className={`cursor-pointer`}
                width={27}
                height={24}
                src={viewType ? Lista : Mosaico}
              ></img>
            </div>
          </Grid>
        </Grid>
        <div className="hr-nav"></div>
      </Grid>
    </Grid>
  );
}
