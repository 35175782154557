import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';

import Logo from "../../../assets/img/logo.svg";
import { Button } from "../../../components/atoms/Button"
import { Link } from "react-router-dom";

import { Redirect } from "react-router-dom";

import { verifyUser } from "../../../api/admin";

export default function UserVerify() {

  var token = obtenerValorParametro('token');
  useEffect(() => {
    const verificarUsuario = async () => {
      try {
        const { data } =  await verifyUser(token);
        console.log(data)
      } catch (error) {
        console.log(error)
      }
    }
    verificarUsuario()
  }, [])
  if (token) {
    //console.log("existe token");

    return (
      <div className="sign-in-admin-content">
        <Grid container alignItems="center" justify="center" spacing={0}>
          <Grid item xs={12} md={10} lg={10}>
            <div className="sign-in-container sign-in-admin-container">
              <img className="pt-5 mb-5 logo d-block mx-auto" src={Logo} alt="Wikicid Logo" />
              <h1 className="text-center h1 a-text-bold-second-neutral-70 mb-4">Bienvenido a WikiCID</h1>
              <p className="text-center a-text-bold-second-neutral-40 h3">¡Gracias por unirte a la comunidad!</p>
              <br></br>
              <br></br>
              <p className="text-center a-text-bold-second-neutral-40 h4">Se ha confirmado tu cuenta y te hemos enviado tu contraseña a tu correo.</p>
              <div className="mt-5">
                <Link to="/" className="modal__link-button">
                  <Button classes="a-button-primary1" height={36} width="100%" text="Empezar" />
                </Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

    );


  } else {

    return <Redirect to="/" />
  }

}

//metodo para obtener parametros de la url
function obtenerValorParametro(sParametroNombre) {
  var sPaginaURL = window.location.search.substring(1);
  var sURLVariables = sPaginaURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParametro = sURLVariables[i].split('=');
    if (sParametro[0] == sParametroNombre) {
      return sParametro[1];
    }
  }
  return null;
}