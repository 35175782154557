//React
import { memo } from "react";

import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import "moment/locale/es";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// Material
import {
  IconButton,
  useMediaQuery,
  Modal,
  Popover,
  Button,
} from "@material-ui/core";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import userIcon from "../../../../assets/img/user-icon.svg";
import Close from "../../../../assets/img/closeFilter.svg";

import PencilIconOcean from "../../../../assets/img/pencil-icon-ocean.svg";
import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";

import Comentario from "../../../../assets/img/questions/comentario.svg";

import user2 from "../../../../assets/img/questions/asignar.svg";

import ModalIpadMiniResponse from "../../../molecules/Modal/ResponseModal";
import ModalAsign from "../../../molecules/Modal/MovilAsignModal";
import ModalSuccess from "../../../molecules/Modal/SuccessModal";

import ModalEditQuestion from "../../../molecules/Modal/updateQuestion";

import LikeComponent from "./LikeComponent/index";
import ImageComponent from "./ImageComponent/ImageComponent";
import ImageComponentMultiple from "./ImageComponent/ImageMultipleObserver";
import {
  getAssignUserQuestion,
  getResponsesByuser,
  deleteResponse,
} from "../../../../api/questions";

export default memo(function CardQuestion({
  info,
  userId,
  data,
  setAgregadoRespuesta
}) {
  let multipleImages = [];
  const question = info;
  const [open, setOpen] = useState(false);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const ipadPro = useMediaQuery("(min-width: 992px)");
  const ipadProUse = useMediaQuery("(min-width:992px)");

  const [modalBodystate, setModalBodyState] = useState("");
  const [modalGeneral, setModalGeneral] = useState(false);
  const [responseForMe, setResponseForMe] = useState(false);
  const [modalTopics, setModalTopics] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [responseModal, setResponseModal] = useState(false);
  const [asignModal, setAsignModal] = useState(false);
  const [asignValue, setAsignValue] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [loadMyResponse, setLoadMyResponse] = useState(false);
  const [myResponse, setMyResponse] = useState({ ResponseName: "empty" });
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);

  const theme = useTheme();

  useEffect(() => {
    getAssignUserQuestion(question.user_id, question.question_id)
      .then((res) => {
        setAsignValue(res.data.assigned);
      })
      .catch((err) => console.log(err));
    getMyresponses();
  }, [responseForMe]);

  async function getMyresponses() {
    setLoadMyResponse(true);

    try {
      const response = await getResponsesByuser(userId, question.question_id);
      setLoadMyResponse(true);

      setMyResponse(response.data.responses);

      setLoadMyResponse(false);
    } catch (err) {
      console.log(err);
      setLoadMyResponse(false);
    }
  }

  async function deleteObjet(questionId) {
    try {
      setOpenSuccess(true);

      const deleted = await deleteResponse(userId, questionId);

      if (deleted.status == 200) {
        setLoadDelete(false);
        setTimeout(() => {
          setOpenSuccess(false);
          setResponseForMe(!responseForMe);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (question.images_link) {
    const variables = question.images_link.split(",");
    multipleImages = variables;
  }

  const maxSteps = multipleImages.length;

  const openM = Boolean(anchorEl);

  const imageUrl = `${process.env.REACT_APP_DEV ?
    `${process.env.REACT_APP_IMG_URL}/` :
    'https://images.weserv.nl/?url='
  }`;
  
  const imageLink = multipleImages.length !== 0
    ? `${imageUrl}${multipleImages[activeStep]}${process.env.REACT_APP_DEV !== 'dev' ? '&maxage=31d' : ''}`
    : `${imageUrl}${question.images_link}${process.env.REACT_APP_DEV !== 'dev' ? '&maxage=31d' : ''}`;
    
  
  
  const modalBody = (
    <>
      {ipadPro ? (
        <div className="d-flex w-100">
          <div className="w-100">
            <div className="d-flex w-100 flex-row-ms-reverse mt-2 mr-3 pr-4 position-absolute top-0">
              <IconButton>
                <img
                  src={Close}
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                  width="35px"
                  height="35px"
                />
              </IconButton>
            </div>
            <div className="d-flex w-100 justify-content-md-center h-100vh align-items-md-center align-items-xl-center">
              <img
                className="img-show-ipad"
                src={imageLink}
                alt={multipleImages[activeStep]}
              />
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                className={`background-transparent ${multipleImages.length === 0 ? "d-none" : ""}`}
                nextButton={
                  <Button
                    size="large"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    className="position-absolute top right br-2"
                  >
                    {theme.direction === "rtl" ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    className="position-absolute top left-0"
                  >
                    {theme.direction === "rtl" ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft fontSize="large" />}
                  </Button>
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex w-100">
          <div className={`d-flex w-100 flex-row-ms-reverse mt-2 mr-3 position-absolute top-0`}>
            <IconButton onClick={() => setOpen(false)}>
              <img
                src={Close}
                className="cursor-pointer"
                width={ipadMiniMatch ? "40px" : "24px"}
                height="40px"
                alt="Close Icon"
              />
            </IconButton>
          </div>
          <div>
            <img
              className="img-show"
              src={multipleImages.length !== 0
                ? `https://images.weserv.nl/?url=${multipleImages[activeStep]}&maxage=31d`
                : `https://images.weserv.nl/?url=${question.images_link}&maxage=31d`
              }
              alt={multipleImages[activeStep]}
            />
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              className={`background-transparent ${multipleImages.length === 0 ? "d-none" : ""}`}
              nextButton={
                <Button
                  size="large"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  className="position-absolute top right"
                >
                  {theme.direction === "rtl" ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
                </Button>
              }
              backButton={
                <Button
                  size="large"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  className="position-absolute top"
                >
                  {theme.direction === "rtl" ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft fontSize="large" />}
                </Button>
              }
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <div
      className={` h-a d-flex   br-2  px-4   py-md-2 py-1 `}
      key={`${info.question_id}info `}
    >
      <div className="w-100">
        <div
          className={`d-flex justify-content-start  align-items-center  mt-md-1 mt-lg-1 `}
        >
          <img
            src={
              question.photo_link !== null
                ? `https://images.weserv.nl/?url=${question.photo_link}&maxage=31d`
                : userIcon
            }
            alt="icon"
            className="icon-card-question icon-md-card-question  mr-2 align-self-start br-3 "
          />
          <span className="a-text-regular-first-neutral-100 text-small text-md-medium ">
            {question.user_id === userId ? (
              "Preguntaste "
            ) : (
              <>
                {question.name} {question.lastname || ""}
              </>
            )}{" "}
            { } -{" "}
            <Moment locale="es" fromNow>
              {question.created_at}
            </Moment>{" "}
          </span>
        </div>
        <div className=" pt-2 ">
          <span className="a-text-medium-second-neutral-70 text-normal text-md-medium ">
            {question.question}
          </span>
        </div>
        <div>
          {question.images_link !== "undefined" ? (
            <div
              className="w-100 mt-2 mt-md-3"
              onClick={(event) => {
                event.stopPropagation();

                setOpen(true);
              }}
            >
              {multipleImages.length > 0 ? (
                <ImageComponentMultiple multipleImages={multipleImages} />
              ) : (
                <ImageComponent
                  img={imageLink}
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`d-flex w-100 mt-2  align-items-center  ${ipadMiniMatch ? " " : " d-flex justify-content-between"
            }`}
        >
          <div className=" d-flex mr-md-3 mb-2">
            <LikeComponent
              question={question.question_id}
              userId={userId}
              type={"question"}
            />
            <span className="d-flex align-items-center ml-2 a-text-regular-first-primary-70 text-normal">
              <IconButton
                onClick={() => {
                  setResponseModal(true);
                }}
              >
                <img
                  src={Comentario}
                  alt="PDF Icon"
                  className="cursor-pointer"
                  width="24px"
                />
                <span className="a-text-regular-first-primary-70 text-normal ml-2">
                  {data === 0 ? "" : `${data}`}
                </span>
              </IconButton>
            </span>
          </div>

          {userId === 99999999 ? (
            <div
              className={`${ipadMiniMatch
                ? "d-flex flex-row-reverse  ml-md-4 w-100"
                : "d-flex flex-row-reverse"
                }`}
            >
              <Button aria-describedby={"simple-popover"} color="#2e4a63">
                <MoreHorizIcon color="#2e4a63" />
              </Button>

              <Popover
                id={"simple-popover"}
                open={openM}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="display-grid px-2">
                  <div className="cursor-pointer">
                    <IconButton>
                      <img src={user2} alt="Trash Icon" width="24px" />
                    </IconButton>
                    <span className="a-text-regular-first-neutral-100 text-normal mr-1">
                      Asignar
                    </span>
                  </div>
                  <div className="cursor-pointer">
                    <IconButton>
                      <img src={TrashIconOcean} alt="Trash Icon" width="24px" />
                    </IconButton>
                    <span className="a-text-regular-first-neutral-100 text-normal ">
                      Eliminar
                    </span>
                  </div>
                  <div
                    onClick={() => setModalGeneral(true)}
                    className="cursor-pointer"
                  >
                    <IconButton>
                      <img
                        src={PencilIconOcean}
                        alt="Trash Icon"
                        className=""
                        width="24px"
                      />
                    </IconButton>
                    <span className="a-text-regular-first-neutral-100 text-normal mr-3">
                      Editar
                    </span>
                  </div>
                </div>
              </Popover>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-img d-flex flex-column justify-content-center  ">
          {modalBody}
        </div>
      </Modal>

      <Modal
        open={modalGeneral}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${ipadProUse
            ? " modal-md-width modal-desk d-flex flex-column "
            : "modal-create d-flex flex-column py-3 "
            }`}
        >
          <ModalEditQuestion
            setOpen={setModalGeneral}
            setModalBody={setModalTopics}
            modalTopics={modalTopics}
            setModalBodyState={setModalBodyState}
            question={question}
            title={"Editar pregunta"}
            multipleImages={multipleImages}
            asign={asignValue}
          />
        </div>
      </Modal>

      <Modal
        open={modalTopics}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal d-flex flex-column py-3 ">{modalBodystate}</div>
      </Modal>

      <Modal
        open={responseModal}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${ipadProUse
            ? " modal-md-width modal-desk  height-modal d-flex flex-column "
            : "modal-response d-flex flex-column py-3 px-4   "
            }`}
        >
          <ModalIpadMiniResponse
            question={question}
            setOpen={setResponseModal}
            setResponseForMe={setResponseForMe}
            responseForMe={responseForMe}
            getMyresponses={getMyresponses}
            type={"question"}
            setAgregadoRespuesta={setAgregadoRespuesta}
          />
        </div>
      </Modal>

      <Modal
        open={asignModal}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${ipadProUse
            ? " modal-md-width modal-desk  height-modal d-flex flex-column "
            : "modal-response d-flex flex-column py-3 px-2   "
            }`}
        >
          {<ModalAsign question={question} setModalGeneral={setAsignModal} />}
        </div>
      </Modal>
      <Modal
        open={openSuccess}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${loadDelete ? "Espera un momento..." : "Tu pregunta se ha elimiado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
    </div>
  );
});
