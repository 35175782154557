// React
import React, { memo } from "react";

import OrderIcon from "../../../assets/img/ordenar.svg";
import closeIcon from "../../../assets/img/closeFilter.svg";

export default memo(function Order({ onChange, onClick, orderByDate, orderByAlfa }) {
    // Peticiones
  return(
    <>
      <div className="menu-cid d-flex justify-content-between align-items-center mt-3">
        <div onClick={onClick} className='d-flex align-items-center cursor-pointer mr-xl-4'>
          <img src={OrderIcon} alt="Order" className='img-order' />
          <p className='ml-2 text-small text-md-normal a-text-regular-first-primary-60'>Ordenar</p>
        </div>
        <div className="pr-4" onClick={onClick}>
          <img src={closeIcon} className="filter-label-icon" alt="Close" />
        </div>
      </div>
      <div className="my-4">
        <div className="mb-4 d-flex">
          <input
            onChange={onChange}
            type="radius"
            id="order_1"
            className="radioNews cursor-pointer"
            name="order"
            value="1" />
          <label
            className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
            htmlFor="order_1">Fecha de registro</label>
        </div>
        <div className='d-flex'>
          <input
            onChange={onChange}
            type="radius"
            id="order_2"
            className="radioNews cursor-pointer"
            name="orderAlfa"
            value="2" />
          <label
            className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
            htmlFor="order_2">Orden alfabético</label>
        </div>
      </div>

    </>

  );
})
