// React
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Material
import { Grid,useMediaQuery,createMuiTheme } from '@material-ui/core/';
import { Link } from "react-router-dom";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { getProfile } from "../../../api/profile";
import EmptyStateBlog from "../../../components/molecules/EmptyState/EmptyStateBlog/index";

// Componentes
import Header from "../../../components/molecules/Header";
export default function Blogs(e) {
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)
  const user = useAuth();

  const { userId } = user;
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });


  useEffect(() => {
    getProfileData(userId)
  }, [])

  async function getProfileData(userId) {
    setLoad(true)
    try {
      const response = await getProfile(userId)
      setData(response.data.profilesUser[0])
      setLoad(false)
    } catch (error) {
      console.log(error)
    }
  }
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)")
  const iPadProMatch = useMediaQuery(theme.breakpoints.up('lg'));
  const Desk = useMediaQuery("(min-width: 1100px)")
  
  /*
    
    
    Esta pantalla solo la debes de mostrar cuando no exista ningun otro blog anteriormente 
    para poder hacer todo ese movimiento vas a tener que ir al componente Layout master y ahí detectar si ya existen blogs
    en su caso si hay  no tienes que mostar esta

    Ya hay un ejemplo de esa implementación en ese mismo componente 

    Nota:
    ten cuidado con los tiempos de carga de la plataforma a veces pueden jugar chueco te recomiendo usar full async await 

  */
  return (
    <Grid container justify="center" spacing={0} className=''>
      <Header isCollapsed={e.isCollapsed} link='/cid/analysis' text='Buscar pregunta' />
      <Grid item xs={10} lg={12} className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2">
        <div className={`background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ${Desk ? '': 'h-100vh' } `}>
          <div className="d-flex w-100 ">
            <p className={`mt-3 mt-lg-1 mb-lg-3 mb-lg-4 w-80  ${ipadMiniMatch ? `h3   mb-md-7  ${iPadProMatch ? "mt-0 mb-md-5  "  : "mt-6 mb-md-7  "}`  : ""} mb-3 a-text-medium-second-neutral-70  `}>Blog</p>
            {
              iPadProMatch ?
                <Link to={'/cid/carso/blogs/create'}  className={`text-normal ml-2  a-button-topic-blue-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center ${load ? ' disabled ':' '}`} ><span>Escribir entrada</span></Link>
              : <div/>
            }
          </div>

          <EmptyStateBlog name={`${load ? 'WikiCito': `${data.name}`}`}/>
        </div>
        
      </Grid>
    </Grid>
  )
}
