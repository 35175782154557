//React
import React from "react"

export default function EmptyStateProgrammedBlogs({
  titulo,
  img,
  hyper,
  redirect,
  page,
  setPage,
  sub,
}) {
  return (
    <div className="text-center mt-3">
      <p className="a-text-medium-second-neutral-90 text-md-medium text-small h3-md h1-xl text-normal mb-md-3 px-3">
        {titulo}
      </p>
      <img src={img} className="mt-5 d-block m-auto w-500 h1-500" />
    </div>
  );
}
