export function TextInput({ text, placeholder, setText, warning, disabled, maxLength }) {
  return (
    <>
      <input
        maxLength={maxLength}
        placeholder={placeholder}
        className={`text-normal input py-2 pl-2 a-text-medium-second-neutral-60 
        ${disabled ? "input-disabled" : ""}
        `}
        type="text"
        style={{ width: `calc(${100}% - 10px)` }}
        value={text}
        onChange={(e) => setText(e.target.value)}
        autoComplete="off"
        disabled={disabled} />
      {warning ?
        <p className="mt-2 a-text-regular-alert-60">{warning}</p>
        : ""}
    </>
  );
}
