//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";
export default function SkeletonCardQuestionHome({ num }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  
  return arraySkeleton.map((item, indx) => (
    <div className=" d-flex w-100" key={item+indx}>
      <div className="card h-144 d-flex flex-column  px-3  align-items-center box-shadow-lg-all w-100">
        <div className="d-flex align-items-center w-100 pt-3 mt-3">
          <Skeleton
            variant="circle"
            width="25px"
            height="25px"
            className="mr-3"
            animation="wave"
          />
          <Skeleton variant="rect" width="50%" animation="wave" />
        </div>
        <div className="d-flex align-items-center w-100 pt-4">
          <Skeleton variant="rect" width="100%" animation="wave" />
        </div>
      </div>
    </div>
  ));
}
