//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from '@material-ui/lab';
import {useMediaQuery} from '@material-ui/core';
export default function SkeletonCardQuestion({ num }) {
    const ipadMiniMatch = useMediaQuery("(min-width: 768px)")

    // State
    const [arraySkeleton, setArraySkeleton] = useState([])
    useEffect(() => {
        let numArray = []
        for (let i = 0; i < num; i++) {
            numArray.push('')
        }
        setArraySkeleton(numArray)
    }, [])
    return (
        arraySkeleton.map((r,indx) =>
        <div className=" d-flex w-100" key={indx}>
            <div className="card mb-3 h-144 d-flex flex-column    align-items-center box-shadow-lg-all w-100">
                
                <div className="d-flex  w-90 pt-3">
                    <Skeleton variant="circle" width="25px" height="25px" className="mr-3" animation="wave" />
                    <Skeleton variant="h3" width="50%" animation="wave" />

                </div>
                <div className="d-flex align-items-center  w-90 pt-2">
                    <div className="d-flex flex-column w-100 ">
                    <Skeleton variant="h3" width="95%" animation="wave" className="mb-2"/>
                    <Skeleton variant="h3" width="95%" animation="wave" className="mb-2"/>
                     <Skeleton variant="h3" width="95%" animation="wave" />
  


                    </div>
                    <div>
<Skeleton variant="h3" width="150px" height="80px" animation="wave" />

</div>



                </div>

   
            </div>
         </div>
        )
    )
}