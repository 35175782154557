import React, { useState, useEffect } from "react";

import {
  IconButton,
} from "@material-ui/core/";

// Material
import FilterIcon from "../../../../assets/img/filtrar.svg";
import closeIcon from "../../../../assets/img/closeFilter.svg";
import EliminarFiltro from "../../../../assets/img/eliminar_filtros.svg";
import FilterIconWhite from "../../../../assets/img/whitefilter.svg";

const labelsB = [
  {
    name: "Tecnología",
    value: 115,
  },
  {
    name: "Energía",
    value: 125,
  },
  {
    name: "Entretenimiento",
    value: 135,
  },
  {
    name: "Innovación",
    value: 145,
  },
  {
    name: "Diseño",
    value: 155,
  },
];

// export default function filter({onChange, onClick, filterByTechnology, filterByEnergy, filterByInnovation,filterByEntretenimient, filterByDesign, data,load,}) {
export function BlogFilter({ onClick, filters, setFilters, labels = labelsB }) {
  const [checked, setChecked] = useState(new Array(labels.length).fill(false));
  //Aquí iría el obtener labels de la base de datos,
  //Por el momento se hará escribiendo directo

  const handleOnChange = (position) => {
    const updatedChecked = checked.map((item, index) =>
      index === position ? !item : item
    );
    setChecked(updatedChecked);

    if (filters.includes(labels[position].value)) {
      let aux = filters.filter((el) => {
        return el != labels[position].value;
      });
      setFilters(aux);
    } else {
      setFilters([...filters, labels[position].value]);
    }
  };
  const [filtradoState, setFiltradoState] = useState([])
  
  useEffect(() => {
    setFiltradoState(filters.map(filterState => labels.find(labelState => filterState === labelState.value)))
  }, [filters])

  const handleRemoveFilters = () => {
    setFilters([])
    setChecked(new Array(labels.length).fill(false))
    onClick();
  }

  return (
    <>

      <div className="d-flex justify-content-between mt-3">
        <p className="a-text-regular-first-primary-60 align-self-center text-medium">
          Filtrar por:
        </p>
        <div className="mr-3">
          <IconButton>
            <img
              src={closeIcon}
              className="filter-label-icon width-cerrar-icon"
              alt="Close"
              onClick={onClick}
            />
          </IconButton>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <div
          onClick={handleRemoveFilters}
          className={`d-flex align-items-center mr-lg-3 py-2 px-2 br-1 mr-2 background-white cursor-pointer border-first-primary-50 `}

        >
          <img
            src={EliminarFiltro}
            alt="Order"
            className="img-order"
          />
          <p
            className={`ml-2 text-small text-md-normal ${"a-text-regular-first-primary-60"
              } `}
          >
            Eliminar filtros
          </p>
        </div>
        <div
          onClick={onClick}
          className={`d-flex align-items-center mr-lg-3 ${"background-first-primary-60 cursor-pointer"
            } py-2 px-2 br-1 mr-2`}
        >
          <img src={FilterIconWhite} alt="Order" className="img-order" />
          <p className="ml-2 text-small text-md-normal a-text-regular-third-neutral">
            Filtrar
          </p>
        </div>
      </div>
      <div className="">
        {labels.map(({ name, value }, index) => {
          return (
            <div className="mb-4 d-flex">
              <input
                onChange={() => handleOnChange(index)}
                checked={filtradoState.find(label => label.value === value) }
                type="checkbox"
                id={name}
                className=" radioNews cursor-pointer check-blog-filter"
                name={name}
                value={value}
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer"
                htmlFor={name}
              >
                {name}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}
