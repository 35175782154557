import React,{useState,useEffect} from 'react'
import userIcon from '../../../../assets/img/user-icon.svg'
import { Input } from "../../../atoms/Input/index";
import { useAuth } from "../../../../hooks/useAuth";
import { getProfile } from "../../../../api/profile";
import { Avatar } from '@material-ui/core';
export default function QuestionInputWithRows({placeholder, onChange, rows,id,focus,value}) {
    const user = useAuth();
    const [data, setData] = useState(true)
    const [load, setLoad] = useState(true)

    const { userId } = user;
    useEffect(() => {
        getProfileData(userId)
    }, [])

    async function getProfileData(userId) {
        setLoad(true)

        try {

            const response = await getProfile(userId)
            setData(response.data.profilesUser[0])
            setLoad(false)

        } catch (error) {
            console.log(error)
        }
        
    }
    return (
        <>
            <div className={`d-flex align-items-center w-100 `} >
                <Avatar alt="icon" src={load ? userIcon :`https://images.weserv.nl/?url=${data.photo_link}&maxage=31d`} className='sidebar-community__icon mr-3  icon-md-user br-5  align-self-start'> <img src={userIcon} className="w-100" /> </Avatar>
                <Input type="textarearows" placeholder={placeholder} width='100%' className={`text-small input-topic py-2 pl-2 a-text-regular-second-neutral-60 text w-100`} id={id} onChange={onChange} rows={rows}  focus={true} value={value}  />
            </div>
            
        </>
    )
}
