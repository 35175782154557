import React from 'react';
import Moment from 'react-moment';
import { ProfileGeneral } from '../ProfileComponent/ProfileGeneral';
import Tag from '../../../../assets/img/blogs/etiqueta.svg';
import Add from '../../../../assets/img/icon/agregar.svg';
import Remove from '../../../../assets/img/icon/equisRemove.svg';

import { IconButton, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

export const CardPreview = ({ info, setPodcastSelected, setProgramId, podcastSelected, setAddEpisode, setPortada }) => {
	const handleClickAdd = () => {
		if (podcastSelected == undefined) {
			setPodcastSelected(info);
			setProgramId(info.program_podcast_id);
			setPortada(info.cover);
		} else {
			setPodcastSelected(undefined);
			setProgramId(undefined);
			setPortada(undefined);
			!!setAddEpisode && setAddEpisode(false);
		}
	};
	return (
		<>
			{
				<>
					<div className={` mb-3 h-a d-flex br-2  px-4  box-shadow-lg-all card  py-md-3 py-2}`}>
						<div className=' w-100'>
							<div className={`d-flex justify-content-between align-items-center `}>
								<div className='d-flex justify-content-between'>
									<div
										className={info.uploaded == true ? 'd-none' : 'd-flex align-items-center mr-2'}
									>
										<Tooltip
											title={`${
												podcastSelected == undefined
													? 'Agregar episodio a este podcast'
													: 'Seleccionar otro podcast'
											}`}
										>
											<IconButton
												className={'cursor-pointer'}
												size='small'
												onClick={handleClickAdd}
											>
												<img
													src={`${podcastSelected == undefined ? Add : Remove}`}
													alt='Opt'
													width='28px'
												/>
											</IconButton>
										</Tooltip>
									</div>

									<div
										className={`cursor-pointer card-blog-height"
                          }  w-100`}
									>
										<div className='container-blog-preview text-xs text-md-small w-100'>
											<h2> {info.title} </h2>
										</div>
									</div>
								</div>

								<img
									src={
										info.cover.includes('wikicid')
											? `https://images.weserv.nl/?url=${info.cover}&maxage=31d`
											: info.cover
									}
									alt='icon'
									className='image-podcast-list-preview'
								/>
							</div>
						</div>
					</div>
				</>
			}
		</>
	);
};
