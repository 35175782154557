import { creteCompanyApi, updateCompanyApi } from "../../../../api/company";

export function Submit(data, setError, mode) {
  // create form
  const formData = new FormData();

  data.contacto.forEach((contact) => {
    contact.phone = contact.phone_number;
  });
  formData.append("active", data.active);
  formData.append("dateinactive", data.dateinactive);
  formData.append("company_id", data.company_id);
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("foundation", data.foundation);
  formData.append("sede", data.location);
  formData.append("size", data.size_id);
  formData.append("sector", JSON.stringify([{ name: data.sectors }]));
  formData.append("industry", data.industries);
  formData.append("market", JSON.stringify([{ name: data.technology }]));
  formData.append("tech", data.secundaryTechnology.join(","));
  formData.append("mercado", data.market.join(","));
  formData.append("entry", data.income);
  formData.append("funding", data.funding);
  formData.append("valuation", data.valuation);
  formData.append("opportunities", data.opportunities);
  formData.append("company_investor", data.investors);
  formData.append("web_url", data.website);
  formData.append("linkedin_url", data.linkedin);
  formData.append("youtube_url", data.youtube);
  formData.append("twitter_url", data.twitter);
  formData.append("facebook_url", data.facebook);
  formData.append("instagram_url", data.instagram);
  formData.append("whatsapp_url", data.whatsapp);
  formData.append("alliance", data.alliances.join(","));
  formData.append("contact_user_id", data.contact_user_id);
  formData.append("contacts", JSON.stringify(data.contacto));
  formData.append("logo", document.getElementsByClassName("logo")[0].files[0]);
  // formData.append("ilustration", document.getElementsByClassName("ilustration")[0].files[0]);
  formData.append("founders", JSON.stringify(data.foundersData));
  formData.append("register_user_id", data.register_user_id);
  formData.append("updated_logo_link", data.logo_link);
  formData.append("approach", 0);
  formData.append("video_url", data.video_url);
  formData.append("hashtags", JSON.stringify(data.hashtags));

  let order = [];

  let founders_images = document.getElementsByClassName("founders_photo");

  for (let i = 0; i < founders_images.length; i++) {
    console.log(founders_images[i].files.length);
    if (founders_images[i].files.length) {
      order.push(founders_images[i].files);
    }
    formData.append("founders_photo", founders_images[i].files[0]);
  }

  formData.append("order", JSON.stringify(order));

  // submit
  if (mode === 0) {
    creteCompanyApi(formData)
      .then((response) => {
        console.log(response);
        window.location.href = "/cid/company";
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  } else if (mode === 1) {
    updateCompanyApi(formData, data.id)
      .then((response) => {
        console.log(response);
        window.location.href = "/cid/company";
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  }
}
