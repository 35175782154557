//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";

export default function CardSkeleton({ num }) {
  // State

  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  
  return arraySkeleton.map((r, index) => (
    <div key={index} className="mb-3 card d-flex align-items-center mr-2 d-flex justify-content-between">
      <div className="d-flex align-items-center w-100">
        <Skeleton
          variant="rect"
          width={50}
          height={40}
          animation="wave"
          className="br-1 mx-4"
        />
        <Skeleton variant="rect" width="50%" animation="wave" />
      </div>
      <Skeleton variant="rect" width="10%" animation="wave" />
    </div>
  ));
}
