//React
import React from "react";
// Material
import { useHistory } from "react-router-dom";

import userIcon from '../../../../assets/img/user-icon.svg'

export default function CardBlogAuthor({ info, noIcons, key }) {
  let history = useHistory();
  const author = info;
  return (
    <div
      className="d-flex mt-2  card-author box-shadow-all  cursor-pointer"
      key={key}
      onClick={() => {
        history.push({
          pathname: "/cid/carso/blogs/publications/" + author.name,
          state: author,
        });
      }}
    >
      <div className="w-100 d-flex  ">
        <img
          src={ author.photo_link == null || author.photo_link == "undefined" ? userIcon  : `https://images.weserv.nl/?url=${author.photo_link}&maxage=31d` }
          alt="icon"
          className="image-auhtor object-fit-scale"
        />

        <div className="d-flex flex-column py-2 w-100 ml-4">
          <span className="text-small a-text-medium-first-primary-70 mb-1">
            {author.name}
          </span>
          <span className="text-xs a-text-regular-second-neutral-40">{`${author.publication} publicaciones`}</span>
        </div>
      </div>
    </div>
  );
}
