//React
import { memo } from "react";
import { Link } from "react-router-dom";
import Tooltip from "react-png-tooltip";

import React, { useState, useEffect } from "react";
import Moment from "react-moment";
// Material
import {
  IconButton,
  useMediaQuery,
  Modal,
  Popover,
  Button,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import userIcon from "../../../../../assets/img/user-icon.svg";
import PreviewImageModal from "../../../Modal/PreviewImageModal";
import ModalEditResponse from "../../../../molecules/Modal/UpdateResponseModal";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
//Images, Icons
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ModalIpadMiniResponse from "../../../../molecules/Modal/ResponseModal";
import ModalSuccess from "../../../../molecules/Modal/SuccessModal";

import PencilIconOcean from "../../../../../assets/img/pencil-icon-ocean.svg";
import TrashIconOcean from "../../../../../assets/img/trash-icon-ocean.svg";
import ProfileComponent from "../../ProfileComponent";

import Close from "../../../../../assets/img/closeFilter.svg";
import Comentario from "../../../../../assets/img/questions/comentario.svg";
import LikeComponent from "../LikeComponent/index";
import SkeletonResponseQuestion from "../../../../../components/molecules/Skeleton/SkeletonQuestions/SkeletonResponseQuestion";
import CardResponse from "../../../../../components/molecules/Card/CardQuestion/CardUniqueQuestion/CardResponseByResponse";
import ImageComponent from "../ImageComponent/ImageComponent";
import ImageComponentMultiple from "../ImageComponent/ImageMultipleObserver";
import {
  getResponsesByResponse,
  deleteResponse,
} from "../../../../../api/questions";
import { useHistory } from "react-router-dom";

export default function CardResponseQuestion({
  info,
  userId,
  toResponse,
  setResponseForMe,
  responseForMe,
  deleteClick,
  respuestas,
  setRespuestas,
  setMyResponse,
  myResponse
}) {
  const question = info;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreviewModal, setopenPreviewModal] = useState({
    open: false,
    url: "",
  });
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const [responseModal, setResponseModal] = useState(false);
  const ipadProUse = useMediaQuery("(min-width:992px)");
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const ipadPro = useMediaQuery("(min-width: 992px)");
  const [modalGeneral, setModalGeneral] = useState(false);
  const [more, setMore] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);
  const [responses, setResponses] = useState([]);
  const [loadResponses, setLoadResponses] = useState(true);
  
  const [agregadoRespuesta, setAgregadoRespuesta] = useState(false)
  let history = useHistory();

  const [data, setData] = useState();
  const [loadData, setLoadData] = useState(true);

  let multipleImages = [];
  let multipleEdit = [];
  useEffect(() => {
    getResponsesByquestion(question.comment_id);
  }, []);

  async function deleteObjet(questionId, type) {
    try {
      setOpenSuccess(true);

      const deleted = await deleteResponse(userId, questionId, type);

      if (deleted.status == 200) {
        setLoadDelete(false);
        setTimeout(() => {
          setOpenSuccess(false);
          setResponseForMe(!responseForMe);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getResponsesByquestion(question_id) {
    setLoadData(true);
    try {
      const { data } = await getResponsesByResponse(
        userId,
        question.comment_id,
        "response"
      );
      setResponses(data.responses);
      setLoadResponses(false);
      setData(data.responses.length)
      setLoadData(false);
    } catch (err) {
      console.log(err);
      setLoadData(false);
    }
  }

  if (question.image_comment && question.image_comment.includes(",")) {
    multipleEdit = question.image_comment.split(",");
  }

  async function getMyresponses() {
    setLoadResponses(true);

    try {
      const { data } = await getResponsesByResponse(
        userId,
        question.comment_id,
        "response"
      );
      setResponses(data.responses);
      setLoadResponses(false);
    } catch (err) {
      console.log(err);
      setLoadResponses(false);
    }
  }
  if (question.image_comment) {
    const variables = question.image_comment.split(",");
    multipleImages = variables;
  }


  const openM = Boolean(anchorEl);
  const maxSteps = multipleImages.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeButton = (
    <IconButton onClick={() => setOpen(false)}>
      <img
        src={Close}
        className="cursor-pointer "
        width="35px"
        height="40px"
      />
    </IconButton>
  );


  const imageUrl = `${process.env.REACT_APP_DEV ?
    `${process.env.REACT_APP_IMG_URL}/` :
    'https://images.weserv.nl/?url='
    }`;

  const imageLink = multipleImages.length !== 0
    ? `${imageUrl}${multipleImages[activeStep]}${process.env.REACT_APP_DEV !== 'dev' ? '&maxage=31d' : ''}`
    : `${imageUrl}${question.images_link}${process.env.REACT_APP_DEV !== 'dev' ? '&maxage=31d' : ''}`;

  const mobileStepper = (
    <MobileStepper
      steps={maxSteps}
      position="static"
      variant="dots"
      activeStep={activeStep}
      className={`background-transparent ${multipleImages.length === 0 ? "d-none" : ""}`}
      nextButton={
        <Button size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1} className="position-absolute top right br-2">
          {theme.direction === "rtl" ? <KeyboardArrowLeft fontSize="large" /> : <KeyboardArrowRight fontSize="large" />}
        </Button>
      }
      backButton={
        <Button size="large" onClick={handleBack} disabled={activeStep === 0} className="position-absolute top left-0">
          {theme.direction === "rtl" ? <KeyboardArrowRight fontSize="large" /> : <KeyboardArrowLeft fontSize="large" />}
        </Button>
      }
    />
  );

  const modalBody = (
    <div className="d-flex w-100">
      {ipadPro ? (
        <>
          <div className="w-100">
            <div className="d-flex w-100 flex-row-ms-reverse mt-2 mr-3 pr-4 position-absolute top-0">
              {closeButton}
            </div>
            <div className="d-flex w-100 justify-content-md-center align-items-md-center align-items-xl-center">
              <img className="img-show-ipad" src={imageLink} alt={multipleImages[activeStep]} />
              {mobileStepper}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`d-flex w-100 flex-row-ms-reverse mt-2 mr-3 position-absolute top-0`}>
            {closeButton}
          </div>
          <div>
            <img className="img-show" src={imageLink} alt={multipleImages[activeStep]} />
            {mobileStepper}
          </div>
        </>
      )}
    </div>
  );
  return (
    <div className=" w-90  h-a d-flex br-2 flex-column  py-2 pl-md-4 px-4  mt-2   py-md-2 ">
      <div className=" w-100">
        <div
          className={`d-flex justify-content-start  align-items-center  mt-md-1 mt-lg-1 `}
        >
          <img
            src={
              question.photo_link !== null
                ? `https://images.weserv.nl/?url=${question.photo_link}&maxage=31d`
                : userIcon
            }
            alt="icon"
            className="icon-card-question icon-md-card-question  mr-2 align-self-start br-3 mt-1"
          />
          <div className="d-flex flex-column">
            <span
              className="a-text-regular-first-neutral-100 text-small text-md-medium"
              onClick={() => {
                history.push({
                  pathname: `/cid/carso/profile/${info.ResponseName || info.name
                    }`,
                  state: { profile: info },
                });
              }}
            >
              {question.user_id === userId
                ? "Respondiste"
                : `${question.ResponseName}`}{" "}
              - <Moment fromNow>{question.created_at}</Moment>{" "}
            </span>
            <span className="a-text-regular-first-neutral-100 text-small text-md-medium">
              En respuesta a{" "}
              <span className="a-text-regular-first-primary-60 text-small text-md-normal">
                {question.creator}
              </span>
            </span>
          </div>
        </div>
        <div className=" pt-2 ">
          <span className="a-text-medium-second-neutral-70 text-normal text-md-medium ">
            {question.description}
          </span>
        </div>
        <div>
          {question.image_comment !== null ? (
            <div className="w-100 mt-2 mt-md-3" onClick={() => setOpen(true)}>
              {multipleImages.length > 0 ? (
                <ImageComponentMultiple multipleImages={multipleImages} />
              ) : (
                <ImageComponent
                  img={imageLink}
                />
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
        <div
          className={`d-flex w-100 mt-2  align-items-center  ${ipadMiniMatch ? " " : " d-flex justify-content-between"
            }`}
        >
          <div className=" d-flex mr-md-3 mb-2">
            <LikeComponent
              question={question.comment_id}
              userId={userId}
              type={"question"}
            />
            <span className="d-flex align-items-center ml-2 a-text-regular-first-primary-70 text-normal">
              <IconButton onClick={() => setModalGeneral(true)}>
                <img src={Comentario} alt="PDF Icon" className="cursor-pointer" width="24px" />
              </IconButton>
              {loadData ? null : (data || data > 0) && <span className="a-text-regular-first-primary-70 text-normal">{data}</span>}
            </span>

          </div>
          {userId === question.user_id ? (
            <div
              className={`${ipadMiniMatch
                ? "d-flex flex-row-reverse  ml-md-4 w-100"
                : "d-flex flex-row-reverse"
                }`}
            >
              <Button
                aria-describedby={"simple-popover"}
                color="#2e4a63"
                onClick={handleClick}
              >
                <MoreHorizIcon color="#2e4a63" />
              </Button>

              <Popover
                id={"simple-popover"}
                open={openM}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="display-grid px-2">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      deleteClick(question.comment_id, "question");
                    }}
                  >
                    <IconButton>
                      <img src={TrashIconOcean} alt="Trash Icon" width="24px" />
                    </IconButton>
                    <span className="a-text-regular-first-neutral-100 text-normal ">
                      Eliminar
                    </span>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setResponseModal(true);
                    }}
                  >
                    <IconButton>
                      <img
                        src={PencilIconOcean}
                        alt="Trash Icon"
                        className=""
                        width="22px"
                      />
                    </IconButton>
                    <span
                      className="a-text-regular-first-neutral-100 text-normal mr-3"
                      style={{ paddingLeft: "2px" }}
                    >
                      Editar
                    </span>
                  </div>
                </div>
              </Popover>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className={`${more ? "d-flex flex-column ml-4 w-100" : "d-none"}`}>
        {loadResponses ? (
          <SkeletonResponseQuestion num={1} />
        ) : (
          responses.map((res, idx) => (
            <div
              className="d-flex w-100  border-top-first-Neutral-50 py-2 mr-lg-3"
              key={idx}
            >
              <CardResponse
                setResponseForMe={setResponseForMe}
                responseForMe={responseForMe}
                info={res}
                userId={userId}
                toResponse={question}
                deleteClick={deleteObjet}
                setModalGeneral={setModalGeneral}
                setRespuestas={setResponses}
                respuestas={responses}
              />{" "}
            </div>
          ))
        )}
      </div>

      {data === 0 ? (
        <></>
      ) : (
        <span
          onClick={() => {
            getMyresponses();

            if (more === false) {
              setResponses([]);
            }

            setMore(!more);
          }}
          className="a-text-medium-first-primary-60 text-small text-md-medium cursor-pointer"
        >{`${more ? "Ver menos" : "Ver más respuestas..."}`}</span>
      )}

      <Modal
        open={open}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-img d-flex flex-column justify-content-center  ">
          {modalBody}
        </div>
      </Modal>
      <Modal
        open={openPreviewModal.open}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-preview"></div>
      </Modal>
      <Modal
        open={responseModal}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${ipadProUse
            ? " modal-md-width modal-desk d-flex flex-column "
            : "modal-create d-flex flex-column py-3  "
            }`}
        >
          <ModalEditResponse
            question={question}
            setOpen={setResponseModal}
            setResponseForMe={setResponseForMe}
            responseForMe={responseForMe}
            multipleImages={multipleImages}
            toResponse={toResponse}
            setRespuestas={setRespuestas}
            respuestas={respuestas}
            type={"question"}
            setMyResponse={setMyResponse}
            myResponse={myResponse}
            
            setAgregadoRespuesta={setAgregadoRespuesta}
          />
        </div>
      </Modal>

      <Modal
        open={modalGeneral}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${ipadProUse
            ? " modal-md-width modal-desk  height-modal d-flex flex-column "
            : "modal-response d-flex flex-column py-3   "
            }`}
        >
          <ModalIpadMiniResponse
            question={question}
            setOpen={setModalGeneral}
            setResponseForMe={setResponseForMe}
            responseForMe={responseForMe}
            type={"response"}
            setResponses={setResponses}
            respuestas={responses}
            setAgregadoRespuesta={setAgregadoRespuesta}
          />
        </div>
      </Modal>
      <Modal
        open={openSuccess}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${loadDelete
              ? "Espera un momento..."
              : "Tu respuesta se ha eliminado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
    </div>
  );
}
