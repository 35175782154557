import React, { useContext } from "react";

import { useAudioPlayer } from "react-use-audio-player";
import { AudioControls } from "./AudioControls";
import { ProgressBar } from "./ProgressBar";
import { VolumeControl } from "./VolumeControl";

// import Stop from "../../../assets/img/closeFilter.svg";
import Stop from "../../../assets/img/icon/equis blanca.svg";

import { Avatar, IconButton, useMediaQuery } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { PodcastContext } from "../../../providers/PodcastContext";

export const AudioController = ({ file, podcast }) => {
  const { togglePlayPause, ready, loading, playing, stop, volume } =
    useAudioPlayer({
      src: file,
      format: "mp3",
      autoplay: false,
      onend: () => console.log("sound has ended!"),
    });

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  const { setActive } = useContext(PodcastContext);

  // if (!ready && !loading) return <div>No audio to play</div>
  // if (loading) return <div>Loading audio</div>

  return (
    <>
      {ipadMiniMatch ? (
        <div className="d-flex audio-controller justify-content-between align-items-center">
          <div className="w-25 d-flex justify-content-evenly">
            <div className="h-100Full">
              <img
                className="img-cover"
                src={`https://images.weserv.nl/?url=${podcast.cover_podcast}&maxage=31d`}
              />
            </div>
            <div className="d-flex flex-column justify-content-evenly">
              <p className="text-normal a-text-bold-first-neutral-10 white-text">
                {podcast.titleEp}
              </p>
              <p className="text-small a-text-regular-first-neutral-10">
                {podcast.name}
              </p>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-evenly h-100Full w-50">
            <div className="d-flex">
              {ready && !loading ? (
                <AudioControls
                  togglePlayPause={togglePlayPause}
                  playing={playing}
                />
              ) : (
                <div id="circularProgress">
                  <CircularProgress />
                </div>
              )}
            </div>
            <div className="w-100">
              {ready && !loading ?  <ProgressBar />  : <div id="linearProgress"><LinearProgress /></div>}
            </div>
          </div>
          <div className="w-25 d-flex justify-content-evenly">
            <VolumeControl volume={volume} file={file} />
            <div>
              <IconButton
                onClick={() => {
                  //Aquí iría algo para cerrar la ventana flotante o lo que sea del podcast reproduciendose
                  stop();
                  setActive(false);
                }}
                className={"cursor-pointer"}
              >
                <img src={Stop} alt="Stop" width={24} height={24} />
              </IconButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex audio-controller flex-column  w-100">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex w-80 mt-2">
              <img
                className="img-cover ml-1 mr-1"
                src={`https://images.weserv.nl/?url=${podcast.cover_podcast}&maxage=31d`}
              />
                <div className="d-flex flex-column">
                    <p className="text-normal a-text-bold-first-neutral-10 white-text">
                        {podcast.titleEp}
                    </p>
                    <p className="text-small a-text-regular-first-neutral-10">
                        {podcast.name}
                    </p>
                </div>
            </div>
            <div className="d-flex align-items-center w-20 justify-content-evenly">
                {ready && !loading ? (
                    <AudioControls
                    togglePlayPause={togglePlayPause}
                    playing={playing}
                    />
                ) : (
                    <div id="circularProgress" className="d-flex">
                      <CircularProgress />
                    </div>
                )}
                <IconButton
                onClick={() => {
                  //Aquí iría algo para cerrar la ventana flotante o lo que sea del podcast reproduciendose
                  stop();
                  setActive(false);
                }}
                className={"cursor-pointer"}
                size="small"
              >
                <img src={Stop} alt="Stop" width={20} height={20} />
              </IconButton>
            </div>
          </div>

          <div className="d-flex w-100">
            {ready && !loading ? <ProgressBar /> : <div className="w-100 mr-1 ml-1 mt-1" id="linearProgress"> <LinearProgress /> </div>}
          </div>
        </div>
      )}
    </>
  );
};
