// React

import { Route, Switch, Redirect } from "react-router-dom";


export default function Router({ routes, userId, isCollapsed }) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    render={(props) => <route.component {...props} userId={userId} isCollapsed={isCollapsed} />}
                    exact={route.exact}
                />
            ))}
            <Redirect to = "/404"/>
        </Switch>
    )
}