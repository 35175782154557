
import React, {
    useState,
} from "react";


import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton, useMediaQuery, Popover, Button, createMuiTheme} from "@material-ui/core";

import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";
import PencilIconOcean from "../../../../assets/img/pencil-icon-ocean.svg";
import userIcon from "../../../../assets/img/user-icon.svg";

import { useAuth } from "../../../../hooks/useAuth";

export const CardComment = ({res, setChange, change, deleteObject, setOpenWarning, openWarning, setComment, setCommentEdit, commentEdit}) => {

    
    const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

    const user = useAuth();
    const { userId } = user;
    
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openM = Boolean(anchorEl);

    function handleClickDelete() {
        setOpenWarning(!openWarning)
        setComment(res.comment_blog_id)
    }

    function handleClickUpdate(){
        setCommentEdit(!commentEdit)
        setComment(res)
    }

    return (
        <div 
            className="d-flex justify-content-start mt-3">
            <div>
            <img
                src={
                res.photo_link !== null
                    ? `https://images.weserv.nl/?url=${res.photo_link}&maxage=31d`
                    : userIcon
                }
                alt="icon"
                className="icon-card-question icon-md-card-question  mr-2 align-self-start br-3"
            />
            </div>
            
            <div className="ml-2 blog-comment box-shadow-lg-all w-100">
            
                <div className="a-text-medium-second-neutral-70 mt-1 ml-3 mr-3 mt-3">
                    {
                    res.name
                    }
                </div>
                <div className="mb-2 mt-2"></div>
                <div className="a-text-regular-second-neutral-60 text-normal mt-3 ml-3 mr-3">
                    {
                    res.description
                    }
                </div>
                
                <div>
                {
                userId == res.user_id 
                ? 
                (
                    <div className="d-flex flex-row-reverse mr-2">
                        {
                        ipadMiniMatch 
                        ?
                        <div> 

                            <IconButton 
                                onClick={handleClickUpdate}
                            >
                                <img 
                                    src={PencilIconOcean} 
                                    alt="icon" 
                                    width="22px" 
                                />          
                            </IconButton>
                            <IconButton
                                onClick={handleClickDelete}
                            >
                                <img src={TrashIconOcean} alt="Trash Icon" width="24px" />
                            </IconButton>
                            
                        </div> 
                        :

                        <>
                            <Button
                                aria-describedby={"simple-popover"}
                                color="#2e4a63"
                                onClick={handleClick}
                            >
                                <MoreHorizIcon color="#2e4a63" />
                            </Button>

                            <Popover
                                id={"simple-popover"}
                                open={openM}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                                }}
                                transformOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                                }}
                            >
                                <div className="display-grid px-2 mr-1">
                                <div
                                    className="cursor-pointer d-flex align-items-center"
                                >
                                    <IconButton
                                        onClick={handleClickDelete}
                                    >
                                    <img 
                                        src={TrashIconOcean} 
                                        alt="Trash Icon" 
                                        className="cursor-pointer" 
                                        width="24px" 
                                    />
                                    </IconButton>
                                    <span className="a-text-regular-first-neutral-100 text-normal ">
                                    Eliminar
                                    </span>
                                </div>
                                <div className={`cursor-pointer d-flex align-items-center`}  
                                    onClick={handleClickUpdate}
                                >
                                <IconButton >
                                <img 
                                    src={PencilIconOcean} 
                                    alt="icon" 
                                    className="cursor-pointer" 
                                    width="22px" 
                                />          
                                </IconButton>

                                <span className="a-text-regular-first-neutral-100 text-normal " style={{paddingLeft:"2px"}} >
                                Editar
                                </span>
                                </div>
                            </div>
                            </Popover>
                        </>
                    }
                    </div>
                )
                :
                    ""
                }  
                </div>

            </div>
        </div>
    )
}
