import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import Megusta from "../../../../../assets/img/questions/megusta.svg";
import LikeAzul from "../../../../../assets/img/questions/like-azul.svg";

import {
  getReactionQuestion,
  likeQuestion,
  getReactionResponse,
  likeResponse,
} from "../../../../../api/questions";
import { likeBlog, getReactionsBlog } from "../../../../../api/blogs";

export default function LikeComponent({ question, blog, userId, type }) {
  const [likedQuestion, setLikedQuestion] = useState(false);
  const [likedBlog, setLikedBlog] = useState(false);
  const [likes, setLikes] = useState(0);
  const [load, setLoad] = useState(true);
  const [responsed, setResponsed] = useState([]);
  const [section, setSection] = useState();

  useEffect(() => {
    switch (type) {
      case "pregunta":
        getLikesQuestion(question, userId);
        setSection(type);
        break;
      case "blog":
        getLikesBlog(userId, blog);
        setSection(type);
        break;
      case "question":
        getLikesByResponse(question, userId, type);
        setSection(type);
        break;
      case "response":
        getLikesByResponse(question, userId, type);
        setSection(type);
        break;
    }
  }, []);

  async function getLikesByResponse(question, userId, type) {
    try {
      const likes = await getReactionResponse(question, userId, type);
      setLikes(likes.data.reaction.likes);
      setLikedQuestion(likes.data.reaction.isLiked);
      setLoad(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function getLikesQuestion(question, userId) {
    try {
      const likes = await getReactionQuestion(question, userId);

      setLikes(likes.data.reaction.likes);
      setLikedQuestion(likes.data.reaction.isLiked);

      setLoad(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function getLikesBlog(user_id, blog_id) {
    try {
      const { data: { reaction } } = await getReactionsBlog(user_id, blog_id);
      setLikedBlog(reaction.isLiked);
      setLikes(reaction.likes);
      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClick = () => {
    switch (section) {
      case "pregunta":
        likedAction();
        break;
      case "blog":
        reactionBlog();
        // handleClickLikeBlog();
        break;
      case "question":
        likedAction();
        break;
      case "response":
        likedAction();
        break;
    }
  };

  async function likedAction() {
    if (likedQuestion) {
      setLikes(likes - 1);
      setLikedQuestion(false);
    } else {
      setLikes(likes + 1);
      setLikedQuestion(true);
    }

    try {
      if (type === "pregunta") {
        const response = await likeQuestion(question, userId, likedQuestion);
        setResponsed(response);
      } else {
        const response = await likeResponse(
          question,
          userId,
          likedQuestion,
          type
        );
        setResponsed(response);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function reactionBlog() {
    if (likedBlog) {
      setLikes(likes - 1);
      setLikedBlog(false);
    } else {
      setLikes(likes + 1);
      setLikedBlog(true);
    }

    try {
      const response = await likeBlog(userId, blog, !likedBlog);
      setResponsed(response);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <span className="d-flex align-items-center a-text-regular-first-primary-70 text-normal">
      <IconButton onClick={handleClick}>
        <img
          src={likedQuestion || likedBlog ? LikeAzul : Megusta}
          alt="PDF Icon"
          className="cursor-pointer"
          width="24px"
        />
      </IconButton>
      {load ? "" : `${parseInt(likes) === 0 ? "" : `${likes}`}`}
    </span>
  );
}
