import React, { useState, useEffect } from "react";

import {
  Grid,
  Modal,
  useMediaQuery,
  IconButton,
  createMuiTheme,
} from "@material-ui/core";
import enviar from "../../../assets/img/questions/enviar.svg";
import Error404 from "../../../assets/img/404.svg";
import { useAuth } from "../../../hooks/useAuth";
import { Input } from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import { Link, useHistory } from "react-router-dom";
import QuestionInput from "../../../components/molecules/QuestionInput/index";
import QuestionInputWithRows from "../../../components/molecules/QuestionInput/QuestionInputWithRows/index";
import Close from "../../../assets/img/closeFilter.svg";
import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";
import AlertImg from "../../../assets/img/questions/alert2.svg";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";

import CreateQuestion from "../../molecules/Modal/createQuestion";

import { getCidUsers } from "../../../api/user";
import { creteQuestion } from "../../../api/questions";

const data = [
  {
    id: 1,
    title: "Ciencia",
    link_logo_topic: null,
  },
  {
    id: 2,
    title: "Tecnología",
    link_logo_topic: null,
  },
  {
    id: 3,
    title: "Economía",
    link_logo_topic: null,
  },
  {
    id: 4,
    title: "Negocios",
    link_logo_topic: null,
  },
  {
    id: 5,
    title: "Finanzas",
    link_logo_topic: null,
  },
  {
    id: 6,
    title: "Politica",
    link_logo_topic: null,
  },
  {
    id: 7,
    title: "Development",
    link_logo_topic: null,
  },
  {
    id: 8,
    title: "Web",
    link_logo_topic: null,
  },
  {
    id: 9,
    title: "Gaming",
    link_logo_topic: null,
  },
];

export default function QuestionList() {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const deskTop = useMediaQuery("(min-width: 1000px)");
  const user = useAuth();
  const { name, userId } = user;
  const [active, setActive] = useState(false);
  const [isWriting, setWriting] = useState(false);
  const [imagen, setImagen] = useState("");
  const [open, setOpen] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [isvisibleConfirm, setIsvisibleConfirm] = useState(false);
  const [modalIpadPro, setModalIpadPro] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userSelected, setUserSelected] = useState({
    nameUser: "",
    user_id: 0,
  });
  const [openImages, setOpenImages] = useState(false);
  const ipadProUse = useMediaQuery("(min-width:992px)");
  const [isVisible, setIsvisible] = useState(false);

  let numImages = 0;

  function handleFocus() {
    setModalIpadPro(true);
  }
  function handleWrite(evn) {
    if (evn.target.value !== "") {
      if (iPadProMatch) {
        setWriting(false);
      } else {
        setWriting(true);
      }
    } else {
      setWriting(false);
    }
  }
  const renderPhotos = (source) => {
    numImages = source.length;
    return source.map((photo) => {
      return (
        <div
          key={photo}
          className={`${
            numImages === 1 ? `${ipadMiniMatch ? "w-100 " : "w-100"}` : "w-48"
          } h-min h-max-modal-img w-lg-50 mb-2  `}
        >
          <img
            onClick={() => handleOpenImage(photo)}
            src={photo}
            alt="phohoto"
            className="img-questions  br-2 "
          />
        </div>
      );
    });
  };

  const handleImageChange = (e) => {
    var reader = new FileReader();

    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  };

  function alertView(params) {
    if (params) {
      setIsvisible(true);
      setTimeout(() => {
        setIsvisible(false);
      }, 3000);
    }
  }
  function confimrView(params) {
    if (params) {
      setIsvisibleConfirm(true);
      setTimeout(() => {
        setIsvisibleConfirm(false);
      }, 3000);
    } else {
      setIsvisibleConfirm(false);
    }
  }

  function handleOpenImage(photo) {
    setOpenImages(!openImages);

    setModalImage(
      <>
        <div className={`d-flex w-100 flex-row-ms-reverse mb-5 `}>
          <IconButton>
            <img
              src={Close}
              onClick={() => setOpenImages(false)}
              alt="PDF Icon"
              className="cursor-pointer  "
              width="24px"
            />
          </IconButton>
        </div>

        <div className="mt-4">
          <img alt="image questionsd" src={photo} className="img-show"></img>
        </div>
      </>
    );
  }

  return (
    <>
      <Grid
        item
        xs={12}
        className={`${
          ipadMiniMatch ? " ml-4 " : "justify-content-center "
        } h-100vh h-100vh-list-question`}
        lg={12}
      >
        {active ? (
          <span
            className={`checked py-3 px-3 mb-4 mt-1 mb-md-5   ${
              ipadMiniMatch
                ? " d-flex flex-column ustify-content-center align-items-center"
                : ""
            } `}
          >
            <p className="text-small text-md-big a-text-medium-first-primary-70 text ">
              Tips para tener una buena respuesta a tu pregunta{" "}
            </p>
            <ul className="pl-4 mt-3 px-md-5   ">
              <li className="text-small  text-lg-medium a-text-regular-first-primary-60 mb-2">
                Realiza una pregunta de forma breve y concreta
              </li>
              <li className="text-small text-lg-medium a-text-regular-first-primary-60">
                Verifica tu gramática y ortografía
              </li>
            </ul>
          </span>
        ) : (
          <>
            <p className="a-text-medium-first-primary-60 text-normal mb-1 text-lg-big">{`¡Hola!, ${name}`}</p>
            <p
              className={`mb-4 mb-md-7 mt-md-3 a-text-regular-first-primary-70 text-normal text-lg-big `}
            >
              Anímate a dejar la primer pregunta
            </p>
          </>
        )}
        {deskTop ? (
          <span
            className={`checked py-3 px-3 mb-4 mt-1 mb-md-5   ${
              ipadMiniMatch
                ? " d-flex flex-column ustify-content-center align-items-center"
                : ""
            } `}
          >
            <p className="text-small text-md-big a-text-medium-first-primary-70 text ">
              Tips para tener una buena respuesta a tu pregunta{" "}
            </p>
            <ul className="pl-4 mt-3 px-md-5   ">
              <li className="text-small text-lg-medium a-text-regular-first-primary-60 mb-2">
                Realiza una pregunta de forma breve y concreta
              </li>
              <li className="text-small text-lg-medium a-text-regular-first-primary-60 ">
                Verifica tu gramática y ortografía
              </li>
            </ul>
          </span>
        ) : (
          <></>
        )}
        <Grid xs={12}>
          <div className="mb-6 mt-2" onClick={handleFocus}>
            <QuestionInput placeholder={"Pregunta a la comunidad"} />
          </div>

          <Modal
            open={open}
            onClose={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modal">{modalBody}</div>
          </Modal>

          <Modal
            open={modalIpadPro}
            onClose={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${
                ipadProUse
                  ? " modal-md-width modal-desk d-flex flex-column "
                  : "modal-create d-flex flex-column py-3  "
              }`}
            >
              <CreateQuestion
                setOpen={setModalIpadPro}
                handleOpenImage={handleOpenImage}
                setModalBody={setModalBody}
                setOpenTopics={setOpen}
                openTopics={open}
                controlImage={alertView}
                SuccessAsignment={confimrView}
                setUserSelected={setUserSelected}
                userSelected={userSelected}
              />
              <div
                className={`${
                  isVisible
                    ? "alert-modal d-flex justify-content-center align-items-center"
                    : "  d-none"
                }`}
              >
                <img src={AlertImg} className="mr-4" />
                <p className="h3  a-text-regular-first-neutral-100"></p>
              </div>
              <div
                className={`${
                  isvisibleConfirm
                    ? "alert-modal d-flex justify-content-center align-items-center mt-2"
                    : "  d-none"
                }`}
              >
                <img src={SuccessIcon} className="mr-4" />
                <p className="text-big  a-text-regular-second-neutral-90">
                  La pregunta fue asignada a:{" "}
                  <span className="a-text-regular-first-primary-60 ">
                    {userSelected.nameUser}
                  </span>
                </p>
              </div>
            </div>
          </Modal>
        </Grid>

        <img
          src={Error404}
          className={` ${deskTop ? "d-none" : ""}  ${
            active ? "d-none" : ""
          } my-3 d-block  error-404 m-auto mt-md-3 `}
        />
      </Grid>
    </>
  );
}
