// Mate   rial
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Grid,
  Modal,
  useMediaQuery,
  IconButton,
  createMuiTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Header from "../../../components/molecules/Header";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CardQuestionHeader from "../../../components/molecules/Card/CardQuestion/CardHeaderList";
import CardResponse from "../../../components/molecules/Card/CardQuestion/CardUniqueQuestion/CardResponseQuestion";
import ModalSuccess from "../../../components/molecules/Modal/SuccessModal";

import { getResponses, deleteResponse } from "../../../api/questions";

import SkeletonResponseQuestion from "../../../components/molecules/Skeleton/SkeletonQuestions/SkeletonResponseQuestion";

import Close from "../../../assets/img/closeFilter.svg";

import { useAuth } from "../../../hooks/useAuth";

export default function ResponseList(e) {
  let history = useHistory();
  const location = useLocation();
  const [dataHeader, setDataHeader] = useState([]);
  const user = useAuth();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [loadHeader, setLoadHeader] = useState(true);

  const [responseForMe, setResponseForMe] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);

  const ipadProUse = useMediaQuery("(min-width:992px)");

  const { userId } = user;

  useEffect(() => {
    setDataHeader(location.state.question);
    getResponsesByquestion(location.state.question.question_id);
    setLoadHeader(false);
  }, [responseForMe]);

  async function deleteObjet(questionId, type) {
    try {
      setOpenSuccess(true);

      const deleted = await deleteResponse(userId, questionId, type);

      if (deleted.status == 200) {
        setLoadDelete(false);
        setTimeout(() => {
          setOpenSuccess(false);
          setResponseForMe(!responseForMe);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getResponsesByquestion(question_id) {
    setLoadData(true);
    try {
      const { data: { responses } } = await getResponses(userId, question_id);
      setData(responses);
      setLoadData(false);
    } catch (err) {
      console.log(err);
      setLoadData(false);
    }
  }
  const [agregadoRespuesta, setAgregadoRespuesta] = useState(false)
  useEffect(() => {
    if (agregadoRespuesta) {
      getResponsesByquestion(location.state.question.question_id)
    }
  }, [agregadoRespuesta])
  return (
    <Grid container justify="center" spacing={0} className="">
      {ipadProUse ? (
        <Header isCollapsed={e.isCollapsed} link="" text="Buscar empresa" />
      ) : (
        <div className="w-100 d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-4  b mx-md-5 mx-4 py-3 mt-2  ">
          {" "}
          <p className="h3-md text-big a-text-medium-first-primary-60">
            Preguntas y respuestas
          </p>{" "}
          <Link to={"/cid/carso/questions/list"}>
            {" "}
            <IconButton>
              <img
                src={Close}
                alt="PDF Icon"
                className="cursor-pointer "
                width="24px"
              />
            </IconButton>
          </Link>
        </div>
      )}

      <Grid
        item
        xs={10}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2"
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ">
          {ipadProUse ? (
            <div className="d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-4 ">
              {" "}
              <p className="h3-md text-big a-text-medium-first-primary-60">
                Preguntas y respuestas
              </p>{" "}
              <Link to={"/cid/carso/questions/list"}>
                {" "}
                <IconButton>
                  <img
                    src={Close}
                    alt="PDF Icon"
                    className="cursor-pointer "
                    width="24px"
                  />
                </IconButton>
              </Link>
            </div>
          ) : (
            <></>
          )}
          <div className="card br-2 h-a box-shadow-all">
            {loadHeader ? (
              <SkeletonResponseQuestion num={1} />
            ) : (
              <>
                <CardQuestionHeader
                  info={dataHeader}
                  userId={userId}
                  data={data.length}
                  setAgregadoRespuesta={setAgregadoRespuesta}
                />
              </>
            )}

            <div
              className={` d-flex flex-column  align-items-end pl-lg-5 pr-lg-4 mr-lg-5 `}
            >
              {loadData ? (
                <SkeletonResponseQuestion num={5} />
              ) : (
                data.map((res, idx) => (
                  <div
                    className="d-flex w-90  border-top-first-Neutral-50 py-2 mr-lg-3"
                    key={idx}
                  >
                    <CardResponse
                      setResponseForMe={setResponseForMe}
                      responseForMe={responseForMe}
                      info={res}
                      userId={userId}
                      toResponse={dataHeader}
                      deleteClick={deleteObjet}
                      respuestas={data}
                      setRespuestas={setData}
                    />{" "}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Modal
        open={openSuccess}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${loadDelete
              ? "Espera un momento..."
              : "Tu respuesta se ha eliminado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
    </Grid>
  );
}
