import React, { useEffect, useState } from "react";


import { useAuth } from "../../../hooks/useAuth";

import SkeletonBlog from "../../molecules/Skeleton/SkeletonBlog/index";
import EmptyState from "../../molecules/EmptyState/EmptyStateBlog/EmptyProgrammedBlogs";
import EmptySaved from "../../../assets/img/blogs/EmptyBlogs/i-guardadas.svg";
import EmptyPodcastUploaded from "../../../assets/img/buho micro.svg"
import { CardHorizontal } from "../../molecules/Card/CardPodcast/CardHorizontal";
import { podcastSaved } from "../../../api/podcasts";
import {
  deleteBlog,
  getProgrammBlogs,
  getDraftedBlogs
} from "../../../api/blogs";
import ModalSuccess from "../../molecules/Modal/SuccessModal";
import { WarningModal } from "../../molecules/Modal/WarningModal";
import {
  Modal,
  Grid,
} from "@material-ui/core/";

export function PodcastyUserList({ category, viewType }) {
  const [load, setLoad] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [change, setChange] = useState(true);
  const [openWarning, setOpenWarning] = useState(false);
  const [blog, setBlog] = useState();
  const [podcastGuardados, setPodcastGuardados] = useState([])
  const user = useAuth();
  const { userId } = user;
  //   useEffect(() => {
  //     switch (category) {
  //       case "publicadas":
  //         getAllBlogsByUser(userId);
  //         break;
  //       case "programadas":
  //         getAllProgrammedBlogs(userId);
  //         break;
  //       case "guardadas":
  //         getAllSavedBlogs(userId);
  //         break;
  //       case "borradores":
  //         getAllDraftedBlogs(userId);
  //         break;
  //     }
  //   }, [deleteSuccess, category, change]);

  useEffect(async () => {
    if (category == 'guardados') {
      try {
        const { data: { podcast } } = await podcastSaved(userId)
        setPodcastGuardados(podcast)
      } catch (error) {
        console.log(error)
      } finally {
        setLoad(false)
      }
    } else if (category == 'publicados') {

    }
  }, [category])

  if (load) return <></>

  return (
    <div>
      <div className="mt-4">
        {
          category == 'guardados' ?
            <>
              {
                podcastGuardados.length > 0
                  ?
                  <>
                    {podcastGuardados.map(podcast => (
                      <CardHorizontal
                        key={podcast.program_podcast_id}
                        info={podcast}
                        userId={userId}
                        setOpenWarning={setOpenWarning}
                        openWarning={openWarning}
                      />
                    ))}
                  </>
                  :
                  <EmptyState
                    titulo={"No tienes podcasts" + `${category == "publicados" ? " publicados" : " guardados"}`}
                    img={category == "publicados" ? EmptyPodcastUploaded : EmptySaved}
                  />
              }
            </>
            :
            <EmptyState
              titulo={"No tienes podcasts" + `${category == "publicados" ? " publicados" : " guardados"}`}
              img={category == "publicados" ? EmptyPodcastUploaded : EmptySaved}
            />
        }
      </div>
    </div>
  );
}
