// React
import { Redirect } from "react-router-dom";

// Material
import { Grid } from '@material-ui/core';

// Componens
import Header from "../../../../components/molecules/Header";
import FormCompany from "../../../../components/organisms/Form/FormCompany";
import useAuth from "../../../../hooks/useAuth";

export default function UpdateCompany(e) {

    const user = useAuth();
    if (!user) {
        return <Redirect to="/admin/" />;
      }
    if(user.rolId === 3){
      return <Redirect to="/cid/carso/home" />;
    }
    
      if (window.screen.width < 992) {
        return <Redirect to="/" />;
      }


    if (window.screen.width < 992) {
        return <Redirect to="/" />
    }

    // Variables
    const companyId = e.match.params.id;

    return (
        <Grid container justify='center' spacing={0}>
            <Header isCollapsed={e.isCollapsed} link='/cid/search-company' text='Buscar empresa' />
            {
                user.rolId === 3 ? "" : 
                <Grid item lg={12} className='h-100vh-header scroll-lg-overflow background-lg-first-neutral-10 br-2 px-4'>
                    <FormCompany userId={e.userId} companyId={companyId} text="Editar empresa" />
                </Grid>
             }
        </Grid>
    )
}