// React
import React from "react";

// Material
import {
  useMediaQuery,
  Grid,
  createMuiTheme,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

// Img
import Error404 from "../../../../assets/img/404.svg";

//Components
import { Button } from "../../../atoms/Button";

function NotFound404Error() {
  //Different screens
  const desktopMatch = useMediaQuery("(min-width: 1200px)");
  //const theme = useTheme();
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        sm: 0,
        md: 768,
        lg: 992,
      },
    },
  });
  const phoneMatch = useMediaQuery(theme.breakpoints.between(0, 767));
  const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992));
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));

  //Different screens
  function closeOpenedWindow() {
    window.close();
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <div
            className={`m-20vh ${
              desktopMatch ? "d-flex" : ""
            } justify-content-center`}
          >
            <div
              className={`d-flex m-2 justify-content-center ${
                desktopMatch && "flex-column align-items-start"
              } `}
            >
              <p
                className={`a-text-medium-first-primary-60 my-3 ${
                  iPadMatch ? "mx-3 " : "mx-2"
                } ${iPadProMatch ? "text-biggest" : "text-bigger"}`}
              >
                404
              </p>
              <Divider
                className={`${desktopMatch && "d-none"}`}
                orientation="vertical"
                flexItem
              />
              <span
                className={` ${iPadMatch ? "mx-3 " : "mx-2"} ${
                  iPadProMatch ? "my-4" : "my-2"
                } `}
              >
                <p
                  className={`a-text-bold-first-primary-60 ${
                    phoneMatch ? "text-small" : "h3"
                  }`}
                >
                  ¡Oh no!
                </p>
                <p
                  className={`a-text-regular-first-primary-60  mb-3 ${
                    phoneMatch ? "text-xs" : "text-medium"
                  }`}
                >
                  La página no fue encontrada
                </p>
                <button onClick={closeOpenedWindow} className="b-none">
                  <Button
                    type="button"
                    classes={`a-button-blue-2 text-small roboto-regular ${
                      phoneMatch ? "text-xs" : "text-medium"
                    }`}
                    width="100px"
                    height="33px"
                    text="Cerrar"
                  />
                </button>
              </span>
            </div>

            {/* <span className='a-text-regular-second-neutral-40 h3-md h1-xl'>Haz clic {link ? <Link to={link}><span className='a-text-regular-second-primary-70 cursor-pointer text-decoration-underline'>aquí</span></Link> : <span className='a-text-regular-second-primary-70 cursor-pointer text-decoration-underline' onClick={onClick}>aquí</span>} para agregar {subTitulo}</span> */}
            <img
              src={Error404}
              className={` my-3 d-block  error-404 ${
                desktopMatch ? "mx-2" : "m-auto"
              }`}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export { NotFound404Error };
