// React
import { useEffect, useState } from "react";

//Componentes
import Header from "../../molecules/Header";
import News from "../../molecules/Card/News";
import MenuTabUser from "../../../components/molecules/TabMenu/new";
import { NewsCardVertical } from "../../molecules/Card/News/NewsCardVertical";
import { FilterNews } from "../Filters/NewsFilters/FilterNews";

//Material
import {
  createMuiTheme,
  Grid,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import styles from "./styles.module.css";

//Imagenes
import FilterIconOcean from "../../../assets/img/filtrar.svg";
import { getSavedNew, GNewsAdapter } from "../../../api/news";
import SkeletonNews from "../../molecules/Skeleton/SkeletonNews";
import emptyState404 from "../../../assets/img/questions/sin-resultados.svg";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      md: 768,
      lg: 992,
    },
  },
});

/*
  Propuesta de mejoras:
  1. Input de búsqueda y regresa las noticias encontradas
  2. Filtrar noticias por país
  3. Filtrar noticias por lenguaje
*/

export default function CardNews({ userId, isCollapsed }) {
  const ipadMiniMatch = useMediaQuery("(max-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const ipadMini = useMediaQuery("(min-width: 768px)");

  const [filter, setFilter] = useState(false);
  const [news, setNews] = useState([]);
  const [savedNews, setSavedNews] = useState([]);
  const [search, setSearch] = useState("");
  const [isActiveList, setIsActiveList] = useState(true);
  const [isActiveActivi, setIsActiveActivi] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewType, setViewType] = useState(
    () => JSON.parse(localStorage.getItem("viewType")) ?? true,
  );
  const [change, setChange] = useState(true);
  const [filters, setFilters] = useState(
    () => JSON.parse(localStorage.getItem("categorias")) || [],
  );
  const defaultCategory = "technology";

  useEffect(() => {
    localStorage.setItem("categorias", JSON.stringify(filters));

    checkForStaleNews().then(() => {
      if (filters.length === 0) {
        updateNews(defaultCategory).then(() => setNews(getDefaultNews()));
      } else {
        const n = getFilteredNews();
        setNews(n);
      }
    });
  }, [filters]);

  useEffect(() => {
    updateNews(defaultCategory).then(() => setNews(getDefaultNews()));
  }, []);

  useEffect(() => handleSearch(), [search]);

  useEffect(() => {
    getSavedNews();
  }, [change]);

  function handleSearch() {
    let foundNews;
    if (search === "" && filters.length > 0) {
      foundNews = getFilteredNews();
    } else if (search !== "") {
      foundNews = getSearchedNews();
    } else {
      foundNews = getDefaultNews();
    }

    setNews(foundNews);
  }

  function getDefaultNews() {
    const defaultNews = JSON.parse(localStorage.getItem(defaultCategory)) || [];
    return defaultNews.sort(sortByDate).sort(sortNewsFromPeruLast);
  }

  function handleViewType() {
    const vt = !viewType;
    localStorage.setItem("viewType", JSON.stringify(vt));
    setViewType(vt);
  }

  async function checkForStaleNews() {
    for (const newsCategory of filters) {
      await updateNews(newsCategory);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  async function updateNews(newsCategory) {
    try {
      const cachedData = localStorage.getItem(newsCategory);
      let articles = [];
      let isDataFromToday = false;

      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        articles = parsedData || [];
        const now = new Date();

        // Check if any article is from today
        isDataFromToday = articles.some((article) => {
          const publishedAt = new Date(article.publishedAt);
          return now.toDateString() === publishedAt.toDateString();
        });
      }

      if (!isDataFromToday) {
        // Fetch new data if no article from today is found or no cached data exists
        const getNewsAdapter = new GNewsAdapter();
        const response = await getNewsAdapter.getNews(newsCategory);
        if (
          response.data &&
          response.data.articles &&
          response.data.articles.length > 0
        ) {
          articles = response.data.articles;
          localStorage.setItem(newsCategory, JSON.stringify(articles));
        } else {
          // Keep cached data if there are no new articles
          if (articles.length === 0) {
            // Store an empty array if there's no cache data
            localStorage.setItem(newsCategory, JSON.stringify([]));
          }
        }
      }
    } catch (error) {
      console.log(error);
      localStorage.setItem(newsCategory, JSON.stringify([]));
    }
  }

  function getFilteredNews() {
    const selectedCategories = [];
    for (const f of filters) {
      const newsCategory = JSON.parse(localStorage.getItem(f));
      selectedCategories.push(
        newsCategory.sort(sortByDate).sort(sortNewsFromPeruLast),
      );
    }
    return selectedCategories.flat();
  }

  async function getSavedNews() {
    try {
      const {
        data: { savedNews },
      } = await getSavedNew(userId);
      setSavedNews(savedNews);
    } catch (error) {
      console.log(error);
    }
  }

  function sortByDate(a, b) {
    return new Date(a.publishedAt) - new Date(b.publishedAt);
  }

  function getSearchedNews() {
    return news.filter((n) =>
      n.title.toLowerCase().includes(search.toLowerCase()),
    );
  }

  function sortNewsFromPeruLast(noticia) {
    return !noticia.source.url.includes("pe") ? -1 : 1;
  }

  return (
    <>
      <Header
        isCollapsed={isCollapsed}
        text="Buscar noticias"
        focus={false}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
        <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
          <Grid container spacing={0} className=" justify-content-center">
            <Grid xs={12} md={11} lg={12} className={`px-4 px-lg-0`}>
              <Grid
                item
                xs={12}
                md={11}
                lg={12}
                xl={12}
                className=" justify-content-center"
              >
                <Grid xs={10} lg={12}>
                  <div className={` w-100  ${iPadProMatch ? "mt-0  " : "  "} `}>
                    {" "}
                    <p
                      className={`${
                        ipadMini
                          ? `h3 ${iPadProMatch ? "mt-0" : "mt-6 mb-md-4  "}`
                          : ""
                      }  a-text-medium-second-neutral-70  `}
                    >
                      Noticias
                    </p>{" "}
                  </div>
                </Grid>
                {filter ? (
                  ""
                ) : (
                  <>
                    <div
                      className={`${
                        ipadMiniMatch ? "mt-3" : "ml-0  mt-0 "
                      } align-items-center  align-filters ml-1  `}
                    >
                      <div
                        className={` ${
                          ipadMiniMatch ? "" : "mr-0 "
                        } d-flex mr-2 pr-1 justify-content-between`}
                      >
                        <div
                          onClick={() => setFilter(!filter)}
                          className=" d-flex align-items-center filter-button cursor-pointer"
                        >
                          <IconButton>
                            <Badge
                              badgeContent={filters.length}
                              color="primary"
                            >
                              <img
                                src={FilterIconOcean}
                                alt="Filter"
                                width="20px"
                              />
                            </Badge>
                          </IconButton>
                          <p className="text-small a-text-regular-first-primary-60 filter-button__text">
                            Filtrar
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className={`${filter ? "d-block" : "d-none"}`}>
                  <FilterNews
                    filters={filters}
                    setFilters={setFilters}
                    onClick={() => setFilter(!filter)}
                  />
                </div>

                <Grid
                  xs={12}
                  md={12}
                  lg={12}
                  className={`${!filter ? "d-block" : "d-none"}`}
                >
                  <MenuTabUser
                    opttwo={isActiveList}
                    optthird={isActiveActivi}
                    setOptTwo={setIsActiveList}
                    setOptThird={setIsActiveActivi}
                    viewType={viewType}
                    setViewType={handleViewType}
                  />

                  {!isLoading ? (
                    viewType ? (
                      isActiveList ? (
                        <>
                          <div className="mt-2">
                            {news.length > 0 ? (
                              news.map((res, idx) => (
                                <div key={idx}>
                                  <News
                                    key={idx}
                                    user={userId}
                                    author={res.source.name}
                                    description={res.description}
                                    image={res.image ?? ""}
                                    url={res.url}
                                    title={res.title}
                                    date={res.publishedAt}
                                    newId={res.user_news_id}
                                    newsSaved={savedNews}
                                    change={change}
                                    setChange={setChange}
                                    savedView={true}
                                  />
                                </div>
                              ))
                            ) : (
                              <>
                                <div className="text-center mt-3">
                                  <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
                                    Sin resultados
                                  </p>
                                </div>
                                <img
                                  src={emptyState404}
                                  className="mt-5 d-block m-auto w-500 image-empty "
                                />
                              </>
                            )}
                          </div>
                        </>
                      ) : !!savedNews && savedNews.length !== 0 ? (
                        <>
                          <div className="mt-2">
                            {savedNews.map((res, idx) => (
                              <News
                                key={idx}
                                user={userId}
                                author={res.author_news}
                                description={res.description_news}
                                image={res.image_news ?? ""}
                                url={res.url_news}
                                title={res.name_news}
                                date={res.pubish_date}
                                newId={res.user_news_id}
                                newsSaved={savedNews}
                                change={change}
                                setChange={setChange}
                                savedView={true}
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center mt-3">
                            <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
                              Aún no tiene noticias guardadas.
                              <br />
                            </p>
                          </div>
                          <img
                            src={emptyState404}
                            className="mt-5 d-block m-auto w-500 image-empty "
                          />
                        </>
                      )
                    ) : isActiveList ? (
                      <>
                        <div
                          className={`${styles.fluidGrid} mt-2 ${
                            ipadMiniMatch ? "justify-content-center" : ""
                          }`}
                        >
                          {news.length > 0 ? (
                            news.map(
                              (res, idx) =>
                                res.image && (
                                  <div className="mt-3">
                                    <NewsCardVertical
                                      key={idx}
                                      user={userId}
                                      author={res.source.name}
                                      description={res.description}
                                      image={res.image ?? ""}
                                      url={res.url}
                                      title={res.title}
                                      date={res.publishedAt}
                                      newId={res.user_news_id}
                                      newsSaved={savedNews}
                                      change={change}
                                      setChange={setChange}
                                      savedView={true}
                                    />
                                  </div>
                                ),
                            )
                          ) : (
                            <>
                              <div className="text-center mt-3">
                                <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
                                  Sin resultados
                                </p>
                              </div>
                              <img
                                src={emptyState404}
                                className="mt-5 d-block m-auto w-500 image-empty "
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : !!savedNews && savedNews.length !== 0 ? (
                      <>
                        <div
                          className={`${styles.fluidGrid} mt-2 ${
                            ipadMiniMatch ? "justify-content-center" : ""
                          }`}
                        >
                          {savedNews.map(
                            (res, idx) =>
                              !!res.image_news && (
                                <div className="mt-3">
                                  <NewsCardVertical
                                    key={idx}
                                    user={userId}
                                    author={res.author_news}
                                    description={res.description_news}
                                    image={res.image_news ?? ""}
                                    url={res.url_news}
                                    title={res.name_news}
                                    date={res.pubish_date}
                                    newId={res.user_news_id}
                                    newsSaved={savedNews}
                                    change={change}
                                    setChange={setChange}
                                    savedView={true}
                                  />
                                </div>
                              ),
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-center mt-3">
                          <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
                            Aún no tiene noticias guardadas.
                            <br />
                          </p>
                        </div>
                        <img
                          src={emptyState404}
                          className="mt-5 d-block m-auto w-500 image-empty "
                        />
                      </>
                    )
                  ) : (
                    <SkeletonNews num={10} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
