import React from "react";
import { useMediaQuery, IconButton } from "@material-ui/core";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/Button/index";
import AlertIcon from "../../../assets/img/questions/alert2.svg";
import Close from "../../../assets/img/closeFilter.svg";

export function DraftModal({ open, setOpen, message, setSendDraft }) {
  const matchIpadMini = useMediaQuery("(min-width: 768px)");

  const user = useAuth();
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="d-flex flex-row-ms-reverse">
        <IconButton>
          <img
            src={Close}
            onClick={closeModal}
            alt="Close Icon"
            className="cursor-pointer "
            width="24px"
          />
        </IconButton>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center p-0">
        <img
          src={AlertIcon}
          alt="icon"
          className="align-self-center mb-2 mt-3"
        />
        <p
          className={`align-self-center text-center a-text-medium-second-neutral-70 text-normal mx-3 pt-3 w-100 ${
            matchIpadMini ? "h3 pb-6" : "text-normal mb-3"
          }`}
        >
          {message}
        </p>

        <div className="d-flex mx-auto mb-3">
          <Link
            to={"/cid/carso/blogs/list"}
            onclick={() => {
              setSendDraft(true);
            }}
          >
            <Button
              type="button"
              onclick={() => {
                setSendDraft(true);
              }}
              classes={`a-button-primary1 mr-2 ${
                matchIpadMini ? "text-normal" : "text-small"
              }`}
              height={33}
              width={matchIpadMini ? 147 : 98}
              text="Sí"
            />
          </Link>
          <Button
            type="button"
            onclick={() => {
              setSendDraft(false);
            }}
            classes={`a-text-medium-first-primary-60 a-button-topic-gray-2 mr-2 ${
              matchIpadMini ? "text-normal" : "text-small"
            }`}
            height={33}
            width={matchIpadMini ? 147 : 98}
            text="No"
          />
        </div>
      </div>
    </>
  );
}
