import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormUpdateUser } from '../../../components/organisms/FormUpdateUser';
import useAuth from '../../../hooks/useAuth';

export default function UpdateUser(e) {
	const userId = e.match.params.id;
	const user = useAuth();
	if (!user) {
		return <Redirect to='/admin/' />;
	}
	if (user.rolId !== 1) {
		if (user.rolId !== 2) {
			return <Redirect to='/cid/carso/home' />;
		}
		return <Redirect to='/cid' />;
	}
	return (
		<FormUpdateUser
			userId={userId}
			isCollapsed={e.isCollapsed}
		/>
	);
}
