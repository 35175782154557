//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from '@material-ui/lab';

export function SkeletonCardReport({ num }) {
    // State
    const [arraySkeleton, setArraySkeleton] = useState([])
    useEffect(() => {
        let numArray = []
        for (let i = 0; i < num; i++) {
            numArray.push('')
        }
        setArraySkeleton(numArray)
    }, [])

  return arraySkeleton.map((item, index) => (
    <div key={item+index} className='box-shadow-all mb-3 py-2 p-2 br-1'>
      <div className="d-flex align-items-center">
        <Skeleton variant="rect" width={45} height={50} animation="wave" className="m-2" />
        <Skeleton variant="rect" width="30%" animation="wave" />
      </div>
      <div className='d-flex justify-content-between mt-2'>
        <Skeleton variant="rect" width='10%' animation="wave" />
        <Skeleton variant="rect" width='6%' animation="wave" />
      </div>
    </div>
  ));

}
