import { BASE_PATH } from "./config";
import axios from "axios";

export function getTopics() {
    return axios.get(`${BASE_PATH}/questions/get-topics`)
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

export function getQuestions() {
    return axios.get(`${BASE_PATH}/questions/get-questions`)
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

export function getTopicsByUserId(userId) {


    return axios.post(`${BASE_PATH}/questions/get-TopicsByUser`, { userId })
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}
export function getQuestionById(userId, question_id) {


    return axios.get(`${BASE_PATH}/questions/get-QuestionById/${question_id}`)
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

export function addTopicbyUser(userId, topics, viewState) {


    return axios.post(`${BASE_PATH}/questions/addTopics-byuser`, { userId: userId, topics_id: topics, view: viewState })
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

export function updateTopicbyUser(userId, topics) {


    return axios.put(`${BASE_PATH}/questions/update-TopicsByUser`, { userId: userId, topics_id: topics })
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

export function assignUserQuestion(userId, questionId, userAsId) {



    return axios.post(`${BASE_PATH}/questions/asssig-question`, { user_id: userId, question_id: questionId, userAsId })
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

export function getAssignUserQuestion(questionId) {
    return axios.get(`${BASE_PATH}/questions/get-asssigquestion/${questionId}`)
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}
export function getQuestionsByUserId(userId) {

    return axios.get(`${BASE_PATH}/questions/get-questionByUser/${userId}`)
        .then(response => {
            return response
        }).catch(err => {
            return err
        })
}

//Aquí hicimos una ñerada y se invirtieron los ep, OJO aquí
export function getQuestionsAssignedToMe(userId) {
    return axios.get(`${BASE_PATH}/questions/get-asssignQuestionByuser/${userId}`);
}

export function getQuestionsAssignedByMe(userId) {
    return axios.get(`${BASE_PATH}/questions/get-asssignQuestionsForMe/${userId}`);
}
export function creteQuestion(data) {
	return axios.post(`${BASE_PATH}/questions/create-questions`, data);
}

export function creteResponse(data) {
    return axios.post(`${BASE_PATH}/questions/create-response`, data);
}

export function updateQuestion(data) {
    return axios.put(`${BASE_PATH}/questions/update-question`, data);
}
export function updateResponse(data) {
    return axios.put(`${BASE_PATH}/questions/update-response`, data);
}
export function getTopicsByquestion(questionId) {
    return axios.get(`${BASE_PATH}/questions/get-TopicByQuestion/${questionId}`);
}

export function likeQuestion(questionId, userId, isLiked) {
    return axios.post(`${BASE_PATH}/questions/reaction-like`, { question_id: questionId, user_id: userId, isLiked: isLiked });
}
export function getReactionQuestion(questionId, userId) {
    return axios.post(`${BASE_PATH}/questions/get-reaction`, { question_id: questionId, user_id: userId });
}

export function likeResponse(questionId, userId, isLiked, type) {
    return axios.post(`${BASE_PATH}/questions/reaction-likeByresponse`, { question_id: questionId, user_id: userId, isLiked: isLiked, type: type });
}
export function getReactionResponse(questionId,userId,type) {
    return axios.post(`${BASE_PATH}/questions/get-reactionByResponse`, {question_id:questionId,user_id:userId,type:type});
}
export function getQuestionFiltered(topics_id) {
    console.log(topics_id)
    if (topics_id === '10') {
        return axios.get(`${BASE_PATH}/questions/get-QuestionsFilteredData?userId=1&topicsId=(${topics_id})`);
    } else {
        return axios.get(`${BASE_PATH}/questions/get-QuestionsFilteredData?userId=1&topicsId=${topics_id}`);
    }
}

export function getResponses(userId, question_id) {
    return axios.get(`${BASE_PATH}/questions/get-responses/${question_id}`);
}

export function getResponsesByResponse(userId, question_id, type) {
    return axios.get(`${BASE_PATH}/questions/get-responsesByResponse?type=${type}&question_id=${question_id}`);
}
export function getResponsesByuser(user_id, question_id) {
    return axios.get(`${BASE_PATH}/questions/get-responseByUser?user_id=${user_id}&question_id=${question_id}`);
}
export function deleteQuestion(questionId) {
    return axios.delete(`${BASE_PATH}/questions/delete-question?questionId=${questionId}`).then(response => {
        return response
    }).catch(err => {
        return err
    })
}

export function deleteResponse(userId, comment_id, type) {
    return axios.delete(`${BASE_PATH}/questions/delete-response?type=${type}&comment_id=${comment_id}`).then(response => {
        return response
    }).catch(err => {
        return err
    })
}

export function searchQuestions(userId, text){
    return axios.get(`${BASE_PATH}/questions/get-searchQuestions?search=${text}&userId=${userId}`)
}
