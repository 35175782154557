//React
import React, { useState} from "react";
import Moment from "react-moment";
// Material
import {
  IconButton,
  useMediaQuery,
  Popover,
  Button
} from "@material-ui/core";
import LikeComponent from "../CardQuestion/LikeComponent";

//Por el momento ya se ven bien las imágenes de usuario, pero usar Avatar más adelante si se cambia a MUI 5

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useHistory } from "react-router-dom";

import { ProfileGeneral } from "../ProfileComponent/ProfileGeneral";
import { useAuth } from "../../../../hooks/useAuth";
//API's
import { saveBlog } from "../../../../api/blogs";
import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";
import PencilIconOcean from "../../../../assets/img/pencil-icon-ocean.svg";

import Compartir from "../../../../assets/img/icon/compartir.svg";
import Copy from "../../../../assets/img/news/copiar-enlace.svg";
import Correo from "../../../../assets/img/social/correo.svg";
import Whats from "../../../../assets/img/social/whats.svg";

import Save from "../../../../assets/img/news/guardar-1.svg";
import SaveActive from "../../../../assets/img/news/guardar-2.svg";

import Tag from "../../../../assets/img/blogs/etiqueta.svg";
import Clock from "../../../../assets/img/blogs/reloj.svg";

import userIcon from '../../../../assets/img/user-icon.svg'

/*

Esta card es la card de lista, 
sin embargo para la resolución de celular la estructura cambia radicalmente, notarás que es el mismo componente que para la vista de mosaico.
el open succes te puede ayudar a abrir modales que esten en el componente padre por ejemplo si tienes que borrar los comentarios


En la parte de preguntas esta una implementación muy parecida a la que tend´ras que hacer en los comentarios 
revisa el componente a detalle y podrás hacer está implementación

*/
export default function CardBlog({
  info,
  noIcons,
  userId,
  type,
  deleteObject,
  setOpenSuccess,
  openSuccess,
  path,
  saved,
  setChange,
  change,
  setOpenWarning,
  openWarning,
  setBlog,
  category,
}) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  let history = useHistory();

  // Estos regex se utilizan porque la descripción del blog devuelta por la db contiene ya los tags html
  // y elementos propios del editor para resolverlo utilizamos un replace
  const htmlTagRegex = /<\/?[^>]+>/gi;
  const ampRegex = /(&nbsp)/gi;
  const scRegex = /;/gi;
  const defaultRegex = /(Escribir un título)/;

  const [labels, setLabels] = useState([]);
  const [loadlabels, setLoadLabels] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const [isSaved, setIsSaved] = useState(saved);
  const [anchorEl, setAnchorEl] = useState(null);

  const blog = info;
  const user = useAuth();
  // const { userId } = user;

  function copyToClipboard(blog_id) {
    const url = window.location.href + `/${blog_id}`;
    navigator.clipboard.writeText(url);
  }

  function handleClickDelete() {
    setOpenWarning(!openWarning);
    setBlog(blog.blog_id);
  }

  const handleClickSave = (blog_id) => {
    setIsSaved(!isSaved);
    saveBlog(userId, blog_id, !isSaved)
      .then((result) => {
        if (result.status == 200) {
          console.log("Se guardó o borró correctamente");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    !!setChange && setChange(!change);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openM = Boolean(anchorEl);

  return (
    <>
      {ipadMiniMatch ? (
        <div
          className={` mb-3 h-a d-flex br-2  px-4  box-shadow-lg-all card  py-md-3 py-2 ${
            path != "HomeCarso" ? "" : "w-100"
          }`}
        >
          <div className=" w-100">
            <div className={`d-flex justify-content-md-between`}>
              <div className="d-flex flex-column">
                <div
                  className={`d-flex justify-content-start  align-items-center w-100  `}
                >
                  <img
                    alt="user"
                    src={ blog.photo_link == null || blog.photo_link == "undefined" ? userIcon  : `https://images.weserv.nl/?url=${blog.photo_link}&maxage=31d` }
                    className="mr-3 icon-user-blog br-3"
                  />

                  <div className={`${blog.labels.length != 0 ? "d-flex flex-column" : "d-flex align-items-center"}`}>
                    <span className=" d-flex a-text-regular-first-neutral-100 text-small text-md-normal ">
                      {/* {blog.name} -{" "} */}
                      <ProfileGeneral userId={userId} type={'onlyProfile'} info={blog}/>
                      {type == "programada" ? (
                        <div className="d-flex align-items-center">
                          <img
                            src={Clock}
                            alt="icon"
                            className={`tag-icon mr-1 ml-1`}
                          />
                          <span className="text-xs a-text-regular-first-neutral-100 mr-1 ">
                            <Moment format="DD/MM/YYYY">
                              {blog.published_date}
                            </Moment>
                            a las {` ${blog.published_time} `}
                          </span>
                        </div>
                      ) : (
                        <>
                        <div className="d-flex align-items-center">
                          {" "}
                          <Moment className="ml-1" fromNow>
                            {blog.created_at}
                          </Moment>
                        </div>
                        </>
                      )}
                    </span>
                    <div
                      className="d-flex  align-items-center"
                      style={{ height: "18px", overflowX: "hidden" }}
                    >
                      <img
                        src={Tag}
                        alt="icon"
                        className={`tag-icon mr-1 ml-1  ${
                          blog.labels?.length !== 0 &&
                          blog.labels[0]?.name !== "undefined" &&
                          blog.labels[0].name !== ""
                            ? ""
                            : "d-none"
                        }`}
                      />

                      <>
                        {blog.labels?.length == 0 ? (
                          <div></div>
                        ) : (
                          <>
                            {blog.labels?.map((res, idx) =>
                              res.name !== "undefined" ? (
                                <span
                                  className="text-xs a-text-regular-first-neutral-100 mr-1 "
                                  key={idx}
                                >
                                  {` ${res.name} `}{" "}
                                  {blog.labels.length > 1 &&
                                  idx != blog.labels.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              ) : (
                                <span></span>
                              )
                            )}
                          </>
                        )}
                      </>
                      <div className="d-flex ml-2 "></div>
                    </div>
                  </div>
                </div>

                <div
                  className={` pt-2 pr-3 cursor-pointer ${
                    path === "HomeCarso" ? "card-blog-home" : "card-blog-height"
                  }  w-100`}
                  onClick={() => {
                    history.push({
                      pathname: "/cid/carso/blogs/list/" + blog.blog_id,
                      state: { blog: blog, tags: labels },
                    });
                  }}
                >
                  {/* <div className="container-blog-preview w-100" dangerouslySetInnerHTML={{ __html: blog.description}}>  */}
                  <div className="container-blog-preview text-xs text-md-normal w-100">
                    <h2 className="titulos"> {blog.title} </h2>
                    {!!blog.description &&
                      blog.description
                        .replace(htmlTagRegex, " ")
                        .replace(ampRegex, ".")
                        .replace(blog.title, "")
                        .replace(scRegex, ".")
                        .replace(defaultRegex, "")
                        .substr(0, 117)}
                    {!!blog.description && blog.description.length > 117
                      ? "..."
                      : ""}
                  </div>
                </div>
              </div>

              {/* <div className="image-container-blog flex-column">
          <p className={`${path === 'HomeCarso'? 'image-preview-blog-container-home': 'image-preview-blog-container'} ml-3 mt-3`}>
            <img
              src={`https://images.weserv.nl/?url=${blog.cover_blog}&maxage=31d`}
              alt="icon"
              // className={`${path ==='HomeCarso' ? 'image-preview-blog-home':"image-preview-blog"}`}
              className="image-card-list"
            />  
          </p>
        </div> */}

              <div className="container-image-blog">
                <div className="subcontainer-image-blog">
                  <img
                    src={`https://images.weserv.nl/?url=${blog.cover_blog}&maxage=31d`}
                    alt="icon"
                    className="image-blog-list"
                  />
                </div>
              </div>
            </div>
            {path != "HomeCarso" ? (
              <div
                className={`d-flex w-100 mt-2  align-items-center  ${
                  ipadMiniMatch ? " " : " d-flex justify-content-between"
                }`}
              >
                <div className=" d-flex mr-md-3 mb-2 justify-content-between w-100">
                  <div
                    className={`${
                      category == "borradores" || category == "programadas"
                        ? "d-none"
                        : "d-flex"
                    }`}
                  >
                    <div className="d-flex justify-content-between">
                      <IconButton
                        onClick={() => setIsShare(true)}
                        className={isShare ? "d-none" : "cursor-pointer"}
                      >
                        <img
                          src={Compartir}
                          alt="Compartir"
                          // className={isShare ? "d-none" : "cursor-pointer"}
                          width="24px"
                        />
                      </IconButton>

                      <div
                        className={
                          !isShare ? "d-none" : "d-flex align-items-center"
                        }
                        onClick={() => setIsShare(false)}
                      >
                        <IconButton className={"cursor-pointer"}>
                          <img src={Compartir} alt="Compartir" width="24px" />
                        </IconButton>

                        <a
                          href={`https://api.whatsapp.com/send?text=*${
                            info.title
                          }*%0A🌐%20${
                            window.location.href
                          }${`/${info.blog_id}`}`}
                          target="_blank"
                        >
                          <button className="d-flex btn-in">
                            <img
                              src={Whats}
                              width={`20px`}
                              alt="Compartir"
                              className="cursor-pointer mr-2"
                            />
                          </button>
                        </a>
                        <a
                          href={`mailto:?subject=Blog: &body=Blog:%20${
                            window.location.href
                          }${`/${info.blog_id}`}%0D`}
                          target="_blank"
                        >
                          <button className="d-flex btn-in">
                            <img
                              src={Correo}
                              width={`20px`}
                              alt="Compartir"
                              className="cursor-pointer mr-2"
                            />
                          </button>
                        </a>

                        <button
                          className="btn-in"
                          onClick={() => {
                            copyToClipboard(info.blog_id);
                          }}
                        >
                          <img
                            src={Copy}
                            id="f"
                            alt="Copiar"
                            width={`20px`}
                            className="cursor-pointer mr-2 pdf-img"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between ml-3">
                      <LikeComponent
                        blog={info.blog_id}
                        userId={userId}
                        type={"blog"}
                      />
                    </div>
                  </div>
                  {userId == info.user_id ? (
                    <div
                      className={`${
                        ipadMiniMatch
                          ? "d-flex flex-row-reverse  ml-md-4 w-100"
                          : "d-flex flex-row-reverse"
                      }`}
                    >
                      <Button
                        aria-describedby={"simple-popover"}
                        color="#2e4a63"
                        onClick={handleClick}
                      >
                        <MoreHorizIcon color="#2e4a63" />
                      </Button>

                      <Popover
                        id={"simple-popover"}
                        open={openM}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <div className="display-grid px-2 mr-1">
                          <div
                            className="cursor-pointer d-flex align-items-center"
                            onClick={handleClickDelete}
                          >
                            <IconButton>
                              <img
                                src={TrashIconOcean}
                                alt="Trash Icon"
                                width="24px"
                              />
                            </IconButton>
                            <span className="a-text-regular-first-neutral-100 text-normal ">
                              Eliminar
                            </span>
                          </div>

                          <div
                            className={`${
                              userId !== info.user_id
                                ? "d-none asas"
                                : "d-flex align-items-center cursor-pointer"
                            }`}
                            onClick={() => {
                              history.push({
                                pathname:
                                  "/cid/carso/blog/editar/" + info.blog_id,
                                state: { blog: info },
                              });
                            }}
                          >
                            <IconButton>
                              <img
                                src={PencilIconOcean}
                                alt="icon"
                                className="cursor-pointer"
                                width="22px"
                              />
                            </IconButton>
                            <span
                              className="a-text-regular-first-neutral-100 text-normal "
                              style={{ paddingLeft: "2px" }}
                            >
                              Editar
                            </span>
                          </div>
                        </div>
                      </Popover>
                    </div>
                  ) : (
                    <div className="d-flex">
                      <IconButton
                        onClick={() => {
                          handleClickSave(blog.blog_id);
                        }}
                      >
                        <img
                          src={isSaved ? SaveActive : Save}
                          alt="Guardar"
                          // className={`${isShare ? "d-none" : "cursor-pointer"}`}
                          className="cursor-pointer"
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className=" mb-3 h-a d-flex br-2  px-4  box-shadow-lg-all card  py-md-2 py-3">
          <div className="pr-3 w-100">
            <div
              className={`d-flex justify-content-start  align-items-center w-100   mt-md-1 mt-lg-1 `}
            >
              <img
                alt="user"
                src={ blog.photo_link == null || blog.photo_link == "undefined" ? userIcon  : `https://images.weserv.nl/?url=${blog.photo_link}&maxage=31d` }
                className="mr-2 icon-user-blog"
              />

              <div className="d-flex flex-column">
                <div className="d-flex">
                  <ProfileGeneral userId={userId} type={'onlyProfile'} info={blog}/>
                    {type == "programada" ? (
                      <div className="d-flex align-items-center">
                        <img src={Clock} alt="icon" className={`tag-icon mr-2`} />
                        <span className="text-xs a-text-regular-first-neutral-100 ">
                          {""}
                          <Moment format="DD/MM/YYYY">
                            {blog.published_date}
                          </Moment>{" "}
                          a las {` ${blog.published_time} `}
                        </span>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center a-text-regular-first-neutral-100 cursor-pointer ">
                          {" "}
                          <Moment className="ml-1" fromNow>
                            {blog.created_at}
                          </Moment>
                        </div>
                    )}
                </div>
                <div className="d-flex text-xs text-md-normal align-items-center">
                  <img
                    src={Tag}
                    alt="icon"
                    className={`tag-icon  mr-2  ${
                      blog.labels?.length !== 0 &&
                      blog.labels[0].name !== "undefined" &&
                      blog.labels[0].name !== ""
                        ? ""
                        : "d-none"
                    }`}
                  />
                  <>
                    {blog.labels?.length == 0 ? (
                      <div></div>
                    ) : (
                      <>
                        {blog.labels?.map((res, idx) =>
                          res.name !== "undefined" ? (
                            <span
                              className="a-text-regular-first-neutral-100 mr-1 "
                              key={idx}
                            >
                              {` ${res.name} `}{" "}
                              {blog.labels.length > 1 &&
                              idx != blog.labels.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ) : (
                            <span></span>
                          )
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>

            <div
              className=" cursor-pointer card-blog-height "
              onClick={() => {
                history.push({
                  pathname: "/cid/carso/blogs/list/" + blog.blog_id,
                  state: { blog: blog, tags: labels },
                });
              }}
            >
              <div className="container-blog-preview text-xs text-md-normal w-100">
                <h2 className="titulos"> {blog.title} </h2>
                <div>
                  {!!blog.description &&
                    blog.description
                      .replace(htmlTagRegex, " ")
                      .replace(ampRegex, ".")
                      .replace(blog.title, "")
                      .replace(scRegex, ".")
                      .replace(defaultRegex, "")
                      .substr(0, 99)}
                  {!!blog.description && blog.description.length > 99
                    ? "..."
                    : ""}
                </div>
              </div>
            </div>
            <div></div>

            <div className="image-preview-blog-container2-md mt-3">
              <div className="subcontainer-image-blog-card">
                <img
                  src={`https://images.weserv.nl/?url=${blog.cover_blog}&maxage=31d`}
                  alt="icon"
                  // className="image-preview-blog"
                  className="image-blog-card"
                />
              </div>
            </div>

            <hr className="hr-ocean mt-3" />
            <div
              className={`d-flex w-100 mt-2  align-items-center  ${
                ipadMiniMatch ? " " : " d-flex justify-content-between"
              }`}
            >
              <div className=" d-flex mr-md-3">
                <div
                  className={`${
                    category == "borradores" || category == "programadas"
                      ? "d-none"
                      : "d-flex justify-content-between"
                  }`}
                >
                  <IconButton
                    onClick={() => setIsShare(true)}
                    className={isShare ? "d-none" : "cursor-pointer"}
                  >
                    <img src={Compartir} alt="Compartir" width="24px" />
                  </IconButton>

                  <div
                    className={
                      !isShare ? "d-none" : "d-flex align-items-center"
                    }
                    onClick={() => setIsShare(false)}
                  >
                    <IconButton className={"cursor-pointer"}>
                      <img src={Compartir} alt="Compartir" width="24px" />
                    </IconButton>

                    <a
                      href={`https://api.whatsapp.com/send?text=*${
                        info.title
                      }*%0A🌐%20${window.location.href}${`/${info.blog_id}`}`}
                      target="_blank"
                    >
                      <button className="d-flex btn-in">
                        <img
                          src={Whats}
                          width={`20px`}
                          alt="Compartir"
                          className="cursor-pointer mr-2"
                        />
                      </button>
                    </a>
                    <a
                      href={`mailto:?subject=Blog: &body=Blog:%20${
                        window.location.href
                      }${`/${info.blog_id}`}%0D`}
                      target="_blank"
                    >
                      <button className="d-flex btn-in">
                        <img
                          src={Correo}
                          alt="Compartir"
                          className="cursor-pointer mr-2"
                        />
                      </button>
                    </a>

                    <button
                      className="d-flex btn-in"
                      onClick={() => {
                        copyToClipboard(info.blog_id);
                      }}
                    >
                      <img
                        src={Copy}
                        id="f"
                        alt="Copiar"
                        width={`20px`}
                        className="cursor-pointer mr-2 pdf-img"
                      />
                    </button>
                  </div>

                  <div className="d-flex justify-content-between ml-3">
                    <LikeComponent
                      blog={info.blog_id}
                      userId={userId}
                      type={"blog"}
                    />s
                  </div>
                </div>
              </div>

              {userId == info.user_id ? (
                <div
                // className={`${
                //   ipadMiniMatch
                //     ? "d-flex flex-row-reverse  ml-md-4 w-100"
                //     : "d-flex flex-row-reverse"
                // }`}
                >
                  <Button
                    aria-describedby={"simple-popover"}
                    color="#2e4a63"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon color="#2e4a63" />
                  </Button>

                  <Popover
                    id={"simple-popover"}
                    open={openM}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div className="display-grid px-2 mr-1">
                      <div
                        className="cursor-pointer d-flex align-items-center "
                        onClick={handleClickDelete}
                      >
                        <IconButton>
                          <img
                            src={TrashIconOcean}
                            alt="Trash Icon"
                            width="24px"
                          />
                        </IconButton>
                        <span className="a-text-regular-first-neutral-100 text-normal">
                          Eliminar
                        </span>
                      </div>
                      <div
                        className={`w-100  mb-2 mt-2 ${
                          loadlabels
                            ? "d-none"
                            : `${
                                userId !== info.user_id
                                  ? "d-none asas"
                                  : "d-flex justify-content-center align-items-center"
                              }`
                        } `}
                        onClick={() => {
                          history.push({
                            pathname: "/cid/carso/blog/editar/" + info.blog_id,
                            state: { blog: info, tags: labels },
                          });
                        }}
                      >
                        <IconButton>
                          <img
                            src={PencilIconOcean}
                            alt="icon"
                            className="icon-blog-clock-detail  cursor-pointer"
                          />
                        </IconButton>

                        <span className="a-text-regular-first-neutral-100 text-small text-md-normal cursor-pointer ">
                          Editar
                        </span>
                      </div>
                    </div>
                  </Popover>
                </div>
              ) : (
                <div className="d-flex">
                  <IconButton
                    onClick={() => {
                      handleClickSave(blog.blog_id);
                    }}
                  >
                    <img
                      src={isSaved ? SaveActive : Save}
                      alt="Guardar"
                      // className={`${isShare ? "d-none" : "cursor-pointer"}`}
                      className="cursor-pointer"
                    />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
