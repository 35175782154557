import { useReducer } from 'react';
const ACTIONS = {
	OPEN: 'open',
	LOADING: 'loading',
	MODAL_BODY: 'modal_body',
	ARRAY_DATA: 'array_data',
	NUM_RESULT: 'num_result',
	FILTER_MARKET: 'filter_market',
	FILTER_SECTOR: 'filter_sector',
	FILTER_CLIENT: 'filter_client',
	ORDER_DATE: 'order_date',
	ORDER_ALFA: 'order_alfa',
	IS_DATA: 'is_data',
	ORDER: 'order',
	SECTORES: 'sectores',
	MERCADOS: 'mercados',
	CLIENTES: 'clientes',
	FILTER: 'filter',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.OPEN:
			return { ...state, isOpen: action.payload };
		case ACTIONS.LOADING:
			return { ...state, isLoading: action.payload };
		case ACTIONS.MODAL_BODY:
			return { ...state, modalBody: action.payload };
		case ACTIONS.ARRAY_DATA:
			return { ...state, dataArray: action.payload };
		case ACTIONS.NUM_RESULT:
			return { ...state, numResult: action.payload };
		case ACTIONS.FILTER_MARKET:
			return { ...state, filterMarket: action.payload };
		case ACTIONS.FILTER_SECTOR:
			return { ...state, filterSector: action.payload };
		case ACTIONS.FILTER_CLIENT:
			return { ...state, filterClient: action.payload };
		case ACTIONS.ORDER_DATE:
			return { ...state, orderByDate: action.payload };
		case ACTIONS.ORDER_ALFA:
			return { ...state, orderByAlfa: action.payload };
		case ACTIONS.IS_DATA:
			return { ...state, isThereData: action.payload };
		case ACTIONS.ORDER:
			return { ...state, order: action.payload };
		case ACTIONS.SECTORES:
			return { ...state, sectores: action.payload };
		case ACTIONS.MERCADOS:
			return { ...state, mercados: action.payload };
		case ACTIONS.CLIENTES:
			return { ...state, clientes: action.payload };
		case ACTIONS.FILTER:
			return { ...state, filter: action.payload };
		default:
			return state;
	}
};

export default function useCompanyList({
	initialisOpen,
	initalisLoading,
	initialmodalBody,
	initaldataArray,
	initialnumResult,
	initalfilterMarket,
	initialfilterSector,
	initalfilterClient,
	initialorderByDate,
	initalorderByAlfa,
	initialisThereData,
	initalorder,
	initialsectores,
	initalmercados,
	initalclientes,
	initialfilter,
}) {
	const [state, dispach] = useReducer(reducer, {
		isOpen: initialisOpen,
		isLoading: initalisLoading,
		modalBody: initialmodalBody,
		dataArray: initaldataArray,
		numResult: initialnumResult,
		filterMarket: initalfilterMarket,
		filterSector: initialfilterSector,
		filterClient: initalfilterClient,
		orderByDate: initialorderByDate,
		orderByAlfa: initalorderByAlfa,
		isThereData: initialisThereData,
		order: initalorder,
		sectores: initialsectores,
		mercados: initalmercados,
		clientes: initalclientes,
		filter: initialfilter,
	});
	const {
		isOpen,
		isLoading,
		modalBody,
		dataArray,
		numResult,
		filterMarket,
		filterSector,
		filterClient,
		orderByDate,
		orderByAlfa,
		isThereData,
		order,
		sectores,
		mercados,
		clientes,
		filter,
	} = state;

	return {
		isOpen,
		isLoading,
		modalBody,
		dataArray,
		numResult,
		filterMarket,
		filterSector,
		filterClient,
		orderByDate,
		orderByAlfa,
		isThereData,
		order,
		sectores,
		mercados,
		clientes,
		filter,
		setIsOpen: (isOpen) => dispach({ type: ACTIONS.OPEN, payload: isOpen }),
		setIsLoading: (isLoading) => dispach({ type: ACTIONS.LOADING, payload: isLoading }),
		setModalBody: (modalBody) => dispach({ type: ACTIONS.MODAL_BODY, payload: modalBody }),
		setDataArray: (dataArray) => dispach({ type: ACTIONS.ARRAY_DATA, payload: dataArray }),
		setNumResult: (numResult) => dispach({ type: ACTIONS.NUM_RESULT, payload: numResult }),
		setFilterMarket: (filterMarket) => dispach({ type: ACTIONS.FILTER_MARKET, payload: filterMarket }),
		setFilterSector: (filterSector) => dispach({ type: ACTIONS.FILTER_SECTOR, payload: filterSector }),
		setFilterClient: (filterClient) => dispach({ type: ACTIONS.FILTER_CLIENT, payload: filterClient }),
		setOrderByDate: (orderByDate) => dispach({ type: ACTIONS.ORDER_DATE, payload: orderByDate }),
		setOrderByAlfa: (orderByAlfa) => dispach({ type: ACTIONS.ORDER_ALFA, payload: orderByAlfa }),
		setIsThereData: (isThereData) => dispach({ type: ACTIONS.IS_DATA, payload: isThereData }),
		setOrder: (order) => dispach({ type: ACTIONS.ORDER, payload: order }),
		setSectores: (sectores) => dispach({ type: ACTIONS.SECTORES, payload: sectores }),
		setMercados: (mercados) => dispach({ type: ACTIONS.MERCADOS, payload: mercados }),
		setClientes: (clientes) => dispach({ type: ACTIONS.CLIENTES, payload: clientes }),
		setFilter: (filter) => dispach({ type: ACTIONS.FILTER, payload: filter }),
	};
}
