import React, { useState, useEffect, useRef, useMemo } from "react";
import Close from "../../../assets/img/closeFilter.svg";
import { Modal, IconButton } from "@material-ui/core";
import { Input } from "../../atoms/Input";
import { getCidUsers } from "../../../api/user";
import { getAssignUserQuestion } from "../../../api/questions";
import emptyState404 from "../../../assets/img/questions/sin-resultados.svg";

import Search from "../../../assets/img/icon/search.svg";

import SkeletonCidUser from "../Skeleton/SkeletonQuestions/SkeletonCidUser";
import ConfirmAsignModal from "./confirmAsignModal";

export default function MovilAsignModal({ question, setModalGeneral }) {

  const [cidUser, setCidUser] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalRandom, setModalRandom] = useState(false);

  const [userSelected, setUserSelected] = useState({
    nameUser: "",
    user_id: 0,
  });
  const [load, setLoad] = useState(true);
  const [asignValue, setAsignValue] = useState([""]);
  const [search, setSearch] = useState("");

  const searchInput = useRef(null);


  useEffect(() => {
    const obtenerCidUsers = async () => {
      setLoad(true)
      try {
        const { data: { users } } = await getCidUsers()
        setCidUser(users)
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          setLoad(false)
        }, 500);
      }
    }
    obtenerCidUsers();
  }, [])

  const handleSearch = () => {
    setSearch(searchInput.current.value);
  };
  const filteredUsers = useMemo(
    () =>
      cidUser.filter((cid) => {
        return cid.name.toLowerCase().includes(search.toLowerCase());
      }),
    [cidUser, search]
  );

  useEffect(() => {
    const obtenerAsignadoUsuario = async () => {
      if (question && cidUser.length > 0) {
        try {
          const { data: { assigned } } = await getAssignUserQuestion(question.question_id)
          if (assigned.length > 0) {
            const { user_id } = assigned[0]
            const resultado = cidUser.find(user => user.user_id === user_id)
            const usuarioAsignado = { user_id: resultado.user_id, nameUser: resultado.name + resultado.lastname }
            setAsignValue([usuarioAsignado])
            setUserSelected(usuarioAsignado)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
    obtenerAsignadoUsuario()
  }, [load, question]);

  return (
    <form>
      <div className={`py-2 px-md-5 d-flex flex-column `}>
        <div className=" d-flex justify-content-between align-items-center  mb-4  border-bottom-second-Neutral-100 b-lg-none  ">
          <p className="h3-md a-text-medium-first-primary-60">
            Asignar pregunta
          </p>{" "}
          <IconButton>
            <img
              src={Close}
              onClick={() => setModalGeneral(false)}
              alt="PDF Icon"
              className="cursor-pointer "
              width="24px"
            />
          </IconButton>
        </div>
        <div
          className={`d-flex input-search box-shadow-all background-white `}
          style={{ width: "100%" }}
        >
          <input
            ref={searchInput}
            onChange={handleSearch}
            placeholder={"Buscar en persona"}
            className="input-basic pl-3 pl-lg-4 text-small text-md-normal a-text-regular-second-neutral-60 w-100"
            value={search}
          />
          <img src={Search} alt="Search Icon" className="pr-1" />
        </div>

        {load ? (
          <SkeletonCidUser num={5} />
        ) : (
          <div className=" h-100vh-100px  scroll-overflow  container-cidUser mt-4">
            <Input
              text={""}
              id="question-topic"
              placeholder="Agregar topic"
              type="checkboxUserAsign"
              Location={true}
              data={filteredUsers}
              value={asignValue}
              className="btn-checkbox-icon-add"
              setUser={setUserSelected}
              setModal={setModalConfirm}
              setModalLocation={setModalRandom}
            />

            {filteredUsers.length === 0 ? (
              <div className="text-center mt-3">
                <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
                  No se encontraron resultados <br />{" "}
                </p>

                <img
                  src={emptyState404}
                  className="mt-5 d-block m-auto w-500 image-empty "
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </div>

      <Modal
        open={modalConfirm}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ConfirmAsignModal
            username={userSelected.nameUser}
            userAsId={userSelected.user_id}
            questionId={question.question_id}
            close={setModalConfirm}
            closed={modalConfirm}
            setModalGeneral={setModalGeneral}
          />
        </div>
      </Modal>
    </form>
  );
}
