// React
import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";

// Material
import {
  IconButton,
  createMuiTheme,
  useMediaQuery,
  Tooltip,
  ClickAwayListener,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Images
import EyeIcon from "../../../assets/img/eye-icon.svg";
import EyeIconActivated from "../../../assets/img/eye-icon-activate.svg";
import SearchIconGray from "../../../assets/img/search-icon-gray.svg";
import FileIcon from "../../../assets/img/paisaje.svg";
import FileIcon2 from "../../../assets/img/paisaje2.svg";
import imageIcon from "../../../assets/img/questions/image.svg";
import imageIconDisable from "../../../assets/img/questions/imagedis.svg";
import SedeIcon from "../../../assets/img/company/sede-non-selected.svg";
import SedeIconSelected from "../../../assets/img/company/sede-selected.svg";

import ArrowIcon from "../../../assets/img/arrow.svg";
import AddIcon from "../../../assets/img/add-icon.svg";
import ClipIcon from "../../../assets/img/clip-icon.svg";
import Search from "../../../assets/img/icon/search.svg";
import user2 from "../../../assets/img/questions/user2.svg";
import Fundador from "../../../assets/img/fundador.svg";

import arrow from "../../../assets/img/icon/triangulo.svg";
import { getIndustrias, getSubtechnology } from "../../../api/company";

function Input({
  className = "",
  specialclass = false,
  id,
  width,
  placeholder,
  type,
  focus,
  value,
  onChange,
  data = [],
  seeMoreButton = true,
  limit = null,
  name,
  imagen,
  onClick = null,
  warning,
  height,
  rows,
  count,
  setModalConfirm,
  confirmModal,
  setUser,
  setModal,
  ref,
  setModalLocation,
  setFilteredTopic,
  filteredTopic,
  setFilteredTopics,
  filteredTopics,
  selectClass,
  savedata,
  compare,
  autocomplete = "on",
  setIsFormChanged,
  setIndustriasActivas,
  setIndustria,
  setSubtechActivas,
  setSubtech,
  sectorChanged,
  technologyChanged,
  handleChangeInactive,
  disabled,
  dateChanged,
  foundationChange,
  onKeyDown,
  sede,
  sizeChanged,
  userChanged,
  paisesChanged,
  estadosChanged,
  deletedImgs,
  handleSelect,
  handleClick
}) {
  // State
  const [passwordShowed, setPasswordShowed] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectTwoValue, setSelectTwoValue] = useState("");
  const [selectField, setSelectField] = useState("");
  const [handleId, setHandleId] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [visibilityCardClient, setVisibilityCardClient] = useState("d-none");
  const [visibilityCardSMClient, setVisibilityCardSMClient] = useState("");
  const [visibilityCardText, setVisibilityCardText] = useState("d-none");
  const [visibilityCardSMText, setVisibilityCardSMText] = useState("");

  const selectFilter = selectData.filter((res) =>
    res.name.toLowerCase().includes(selectField.toLowerCase())
  );


  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  const useStyles = makeStyles({
    input: {
      fontFamily: "Roboto-Medium",
      "&::placeholder": {
        color: "#8b8f8b",
        fontFamily: "Roboto-Medium",
        opacity: 0.7,
      },
    },
  });

  const classes = useStyles();

  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992));
  const deskTop = useMediaQuery("(min-width: 1000px)");

  // Funciones
  function showPassword() {
    setPasswordShowed(!passwordShowed);
    if (passwordShowed) {
      document.getElementById(id).setAttribute("type", "password");
      document.getElementById(`${id}-eye-icon`).setAttribute("src", EyeIcon);
    } else {
      document.getElementById(id).setAttribute("type", "text");
      document
        .getElementById(`${id}-eye-icon`)
        .setAttribute("src", EyeIconActivated);
    }
  }

  // Handle para cuando das click en un filtro para las preguntas
  // Línea 1395 comienza el case para los filtros de preguntas
  function handleClickTopic(topic_id) {
    console.log("hiciste click en un botoncito de filtro");
    if (
      topic_id == 10 ||
      (filteredTopics.length == 1 && filteredTopics.indexOf(topic_id) != -1)
    ) {
      setFilteredTopics([10]);
    } else {
      if (filteredTopics.indexOf(topic_id) == -1) {
        let array = filteredTopics.filter((t) => t != 10);
        setFilteredTopics([...array, topic_id]);
      } else {
        let array = [...filteredTopics];
        let index = array.indexOf(topic_id);
        array.splice(index, 1);
        setFilteredTopics([...array]);
      }
    }
  }

  function handleInputChange(e) {
    setSelectTwoValue(e.target.value);
    if (paisesChanged) {
      paisesChanged(e.target.value);
    }
    if (estadosChanged) {
      estadosChanged(e.target.value);
    }
    if (userChanged) {
      userChanged(e.target.id);
    }
    setSelectField(e.target.value);
  }

  function handleList(e, data) {
    if (data) {
      setSelectData(data);
      // setIndustriasActivas && setIndustriasActivas(true)
    }
    setHandleId(e.target.id);
    setOpenList(!openList);
  }

  function closeList(e) {
    setOpenList(false);
  }

  function onDown(e) {
    if (e.keyCode == 13) {
      setOpenList(!openList);
    }
  }


  let ciudad;

  function dataSelect(data, type) {
    if (openList) {
      return (
        <div
          className={`input-option scroll-overflow-y pl-2 mb-md-4 ${selectClass} ${type == 1 && selectFilter.length == 0 ? "d-none" : ""
            } `}
          style={{ width: `calc(${width}% - 10px)`, height: `${((type === 1 || type === 4 || type == 2) && selectFilter.length > 11) || type === 3 ? `${height}` : 'auto'}` }}
        >
          {data.map((res) => {
            if (type === 1) {
              ciudad = res.name.split(", ")
            }

            return (
              <ul key={res.rolId ||
                res.size_id ||
                res.market_id ||
                res.sector_id ||
                res.id ||
                res.user_id ||
                res.name}>
                <li
                  key={
                    res.rolId ||
                    res.size_id ||
                    res.market_id ||
                    res.sector_id ||
                    res.id ||
                    res.user_id ||
                    res.name
                  }
                  id={
                    res.rolId ||
                    res.iso2 ||
                    res.size_id ||
                    res.market_id ||
                    res.sector_id ||
                    res.id ||
                    res.user_id ||
                    res.name
                  }
                  className="a-text-medium-second-neutral-60 pt-1 pb-1"
                  value={
                    res.rolId ||
                    res.sector_id ||
                    res.market_id ||
                    res.user_id ||
                    res.id ||
                    res.size_id ||
                    res.name
                  }
                  // onChange={(e) => onChange(e)}
                  onClick={(e) => {
                    handleOption(
                      e,
                      type,
                      res.rolId ||
                      res.size_id ||
                      res.iso2 ||
                      res.user_id ||
                      res.sector_id ||
                      res.market_id ||
                      res.id ||
                      res.name
                    )
                    //Este setIsFormChanged ayuda a controlar los cambios en el formulario de perfil
                    setIsFormChanged && setIsFormChanged(true);
                    //este setIndustriasActivas sirve para el formulario de empresas cuando le mandas setIndustrasActivas
                    //al componente te controla los cambios de los sectores para activar el campo de industrias
                    setIndustriasActivas && setIndustriasActivas(true);
                    //este setSubtechActivas sirve para el formulario de empresas cuando le mandas setSubtechActivas
                    //al componente te controla los cambios de las tecnologías para activar el campo de tecnologías secundarías
                    setSubtechActivas && setSubtechActivas(true);
                  }}
                >

                  {
                    type === 1 ?
                      <div className="d-flex" id={res.name}>
                        <img className="mr-2" id={res.name} alt={res.name} src={SedeIcon} />
                        <div className="d-flex flex-column" id={res.name}>
                          <p id={res.name}>{ciudad[0]}</p>
                          <p id={res.name} className="text-xs a-text-medium-first-neutral-90">Locación - {res.name}</p>
                        </div>
                      </div>
                      :
                      type === 4 ?
                        <div id={res.iso2} className="d-flex align-items-center">
                          <img className="mr-2" id={res.iso2} src={SedeIcon} alt={res.name} />
                          <p id={res.iso2}>{res.name}</p>
                        </div>
                        :
                        type === 2 ?
                          <p id={res.user_id}>{res.name + " " + res.lastname}</p>

                          : res.name
                  }
                </li>
              </ul>
            )
          })}
        </div>
      );
    }



    async function handleOption(e, type, id) {
      const idSelect = document.getElementById(handleId);

      //devuelve las industrias dependiendo el id del sector
      if (setIndustriasActivas) {
        const industrias = await getIndustrias(id);
        setIndustria(industrias.data.industrias);
      }

      if (sectorChanged) {
        sectorChanged(e);
      }

      if (handleChangeInactive) {
        handleChangeInactive(e);
      }

      if (paisesChanged) {
        paisesChanged(e);
      }

      // Actualización del perfil
      if (setIsFormChanged) {
        handleSelect(id)
      }

      if (estadosChanged) {
        estadosChanged(e)
      }

      if (setSubtechActivas) {
        const subtech = await getSubtechnology(id);
        setSubtech(subtech.data.subTechnology);
      }

      if (technologyChanged) {
        technologyChanged(e);
      }

      if (dateChanged) {
        dateChanged(e);
      }

      if (foundationChange) {
        foundationChange(e);
      }

      if (sizeChanged) {
        sizeChanged(e);
      }

      if (userChanged) {
        userChanged(e);
      }

      idSelect.setAttribute("value", id);

      if (type == 1) {
        setSelectTwoValue(e.target.id)
        setOpenList(!openList);
        idSelect.classList.remove("a-text-regular-first-neutral-90");
        idSelect.classList.add("a-text-medium-second-neutral-60");
      } else if (type == 2) {
        console.log(e.target)
        setSelectTwoValue(e.target.textContent)
        setOpenList(!openList);
        idSelect.classList.remove("a-text-regular-first-neutral-90");
        idSelect.classList.add("a-text-medium-second-neutral-60");
      } else if (type == 4) {
        setSelectTwoValue(e.target.textContent || e.target.alt)
        setOpenList(!openList);
        idSelect.classList.remove("a-text-regular-first-neutral-90");
        idSelect.classList.add("a-text-medium-second-neutral-60");
      } else {
        setSelectValue(e.target.textContent);
        setOpenList(!openList);
        idSelect.classList.remove("a-text-regular-first-neutral-90");
        idSelect.classList.add("a-text-medium-second-neutral-60");
      }

      return true;
    }
  }





  //Victor

  function showClient(e) {
    setVisibilityCardClient("");
    setVisibilityCardSMClient("d-none");
    console.log("abrir");
  }

  function showText(e) {
    setVisibilityCardText("");
    setVisibilityCardSMText("d-none");
    console.log(seeMoreButton);
  }

  switch (type) {
    case "text":
      return (
        <>
          <input
            placeholder={placeholder}
            className={`text-normal input py-2 pl-2 ${disabled
              ? "a-text-medium-first-neutral-80"
              : "a-text-medium-second-neutral-60"
              }   text ${className}`}
            type={type}
            id={id}
            style={{ width: `calc(${width}% - 10px)` }}
            autoFocus={focus}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onKeyDown={onKeyDown}
            autoComplete="off"
          />
          {warning ? (
            <p className="mt-2 a-text-regular-alert-60">{warning}</p>
          ) : (
            ""
          )}
        </>
      );
    case "password":
      return (
        <div className="position-relative">
          <input
            placeholder={placeholder}
            className="text-normal input pt-2 pb-2 pl-2 a-text-medium-second-neutral-60"
            type={type}
            id={id}
            style={{ width: `calc(${width}% - 10px)` }}
            autoFocus={focus}
          />
          <img
            onClick={showPassword}
            id={`${id}-eye-icon`}
            src={EyeIcon}
            alt="Show Password"
            className="position-absolute eye-icon"
          />
        </div>
      );

    case "filterSearch":
      return (
        <div
          className={`d-flex input-search box-shadow-all background-white ${className}`}
          style={{ width: width }}
        >
          <input
            className="input-basic pl-3 pl-lg-4 text-small text-md-normal a-text-regular-second-neutral-60"
            placeholder={placeholder}
            onChange={onChange}
            style={{ width: width }}
            id={id}
            value={value}
            ref={ref}
          />
          <img src={Search} alt="Search Icon" className="pr-1" />
        </div>
      );
    case "search":
      return (
        <div
          className={`d-flex input-search box-shadow-all background-white ${className}`}
          style={{ width: width }}
        >
          <DebounceInput
            className="input-basic pl-3 pl-lg-4 text-small text-md-normal a-text-regular-second-neutral-60"
            placeholder={placeholder}
            onChange={onChange}
            autoFocus={focus}
            style={{ width: width }}
            autoComplete={autocomplete}
            onClick={onClick}
            id={id}
            value={value}
            onKeyDown={onKeyDown}
            debounceTimeout={500}
          />
          <img src={Search} alt="Search Icon" className="pr-1" />
        </div>
      );
    case "search2":
      return (
        <div
          className={`d-flex input-search-2 align-items-center ${className}`}
          style={{ width: width }}
        >
          <img
            src={SearchIconGray}
            alt="Search Icon"
            className="px-2 pl-md-3 input-search-2_icon"
          />
          <input
            className="input-basic text-small text-md-normal"
            placeholder={placeholder}
            onChange={onChange}
            autoFocus={focus}
            style={{ width: width }}
          />
        </div>
      );

    case "search3":
      return (
        <div
          className={`d-flex input-search box-shadow-all background-white ${className}`}
          style={{ width: width }}
        >
          <input
            className="input-basic pl-3 pl-lg-4 text-small text-md-normal a-text-regular-second-neutral-60"
            placeholder={placeholder}
            onChange={onChange}
            autoFocus={focus}
            style={{ width: width }}
            autoComplete={autocomplete}
            onClick={onClick}
            id={id}
            value={value}
          />
          <img src={Search} alt="Search Icon" className="pr-1" />
        </div>
      );
    case "autoresize":
      return (
        <>
          <TextField
            id={id}
            className={`input text-normal a-text-medium-first-neutral-80 
            ${warning ? "input-error" : ""}
            `}
            style={{
              minWidth: `calc(${width}% - 10px)`,
              maxWidth: `100%`,
              minHeight: `25px`,
              paddingInlineStart: "8px",
              paddingBlockStart: "4px",
              fontFamily: "Roboto-Medium",
            }}
            multiline
            maxrows={4}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
            }}
          />
          {warning ? (
            <p className="mt-2 a-text-regular-alert-60">{warning}</p>
          ) : (
            ""
          )}
        </>
      );

    case "date":
      return (
        <div
          className="d-flex input pl-2 cursor-pointer"
          onClick={onClick}
          style={{ width: `calc(${width}% - 10px)` }}
        >
          <input
            className={`input-basic text-normal py-1 a-text-medium-second-neutral-60 cursor-pointer w-100 ${className}`}
            style={{ maxWidth: `100%`, minHeight: `25px` }}
            type="text"
            id={id}
            autoFocus={focus}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readonly="readonly"
          />
          <img src={arrow} alt="Arrow" width="12px" className="pr-2" />
        </div>
      );
    case "time":
      return (
        <div
          className="d-flex input pl-2 cursor-pointer"
          onClick={onClick}
          style={{ width: `calc(${width}% - 10px)` }}
        >
          <input
            className={`input-basic text-normal py-2 a-text-medium-second-neutral-60 cursor-pointer ${className}`}
            type="text"
            id={id}
            autoFocus={focus}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            readonly="readonly"
          />
          <img src={arrow} alt="Arrow" width="12px" className="pr-2" />
        </div>
      );
    case "file":
      return (
        <>
          <input
            type="file"
            id={id}
            className={`d-none ${className}`}
            onChange={onChange}
            name={name}
            accept=".jpeg, .jpg, .png"
          />
          {value != null ? (
            <label htmlFor={id}>
              <p className="input-file d-flex">
                <img
                  className="img-file m-auto"
                  src={
                    value.includes("http")
                      ? `https://images.weserv.nl/?url=${value}&maxage=31d`
                      : `${value}`
                  }
                />

                {/* <img src={value} className='img-file m-auto' />*/}
              </p>
            </label>
          ) : (
            <label htmlFor={id} className={`input-file d-flex ${id} p-4`}>
              <div className="m-auto">
                <img src={FileIcon2} className="my-1" />
                <p className="a-text-medium-first-neutral-80 text-normal">
                  Click para subir una imagen PNG, JPG  de 48x36 px 200 MB máximo
                </p>
              </div>
            </label>
          )}
          {warning ? (
            <p className="mt-2 a-text-regular-alert-60">{warning}</p>
          ) : (
            ""
          )}
        </>
      );

    case "founder-pic":
      return (
        <div className="files-input">
          <label htmlFor={id} className="labelFounder">
            {value &&
              <img
                className="founder-preview-img"
                src={value}
                alt="preview" />
            }
            {!value && <img src={Fundador} alt='founderIcon' />}
          </label>
          <input
            type="file"
            id={id}
            onChange={onChange}
            accept=".jpeg, .jpg, .png"
          >
          </input>
        </div>
      );
    // case "founder-pic":
    //     return (
    //       <>
    //         <input
    //           type="file"
    //           id={id}
    //           className={`d-none ${className}`}
    //           name={name}
    //           onChange={onChange}
    //           accept = ".jpeg, .jpg, .png"
    //         />
    //         {value != null ? (
    //           <label htmlFor={id}>
    //             <p className="input-file d-flex">
    //               <img
    //                 className="img-file m-auto"
    //                 src={
    //                   value.includes("http")
    //                     ? `https:images.weserv.nl/?url=${value}&maxage=31d`
    //                     : `${value}`
    //                 }
    //               />

    //               <img src={value} className='img-file m-auto' />
    //             </p>
    //           </label>
    //         ) : (
    //           <label htmlFor={id} className={`${id} p-4`}>
    //             <div className="m-auto cursor-pointer">
    //               <img src={Fundador} className="my-1" />
    //             </div>
    //           </label>
    //         )}
    //         {warning ? (
    //           <p className="mt-2 a-text-regular-alert-60">{warning}</p>
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    case "profile-pic":
      return (
        <>
          <label
            htmlFor={id}
            className={`a-button-topic-blue-1 a-button-33h cursor-pointer a-text-medium-first-neutral-10 d-flex align-items-center justify-content-center ${className} ${id}`}
          >
            Actualizar
          </label>
          <input
            type="file"
            value={value}
            id={id}
            className={`d-none `}
            onChange={onChange}
          />

          {warning ? (
            <p className="mt-2 a-text-regular-alert-60">{warning}</p>
          ) : (
            ""
          )}
        </>
      );
    case "fileText":
      return (
        <>
          <input
            type="file"
            id={id}
            className={`d-none w-100  `}
            onChange={onChange}
            multiple
            accept="image/*"
            disabled={count >= 4 ? true : false}
          />
          <label
            htmlFor={id}
            className={`${placeholder === "undifined" ? "" : "input-file w-100"
              }  ${id} b-none  h-a `}
          >
            <div className="m-auto d-flex flex-row align-items-center  w-100 h-a ">
              {placeholder !== "" ? (
                <img src={count > 3 ? imageIconDisable : imageIcon} />
              ) : (
                <div />
              )}
              <p
                className={`a-text-regular-first-neutral-90 text-xs text-md-big ml-2 ${placeholder === "undifined" ? "d-none" : ""
                  }`}
              >
                {placeholder}
              </p>
            </div>
          </label>
        </>
      );

    case "logoFile":
      return (
        <>
          <input
            className={`d-none ${className}`}
            type="file"
            id={id}
            autoFocus={focus}
            onChange={onChange}
            accept=".jpeg, .jpg, .png"
          />
          <label htmlFor={id}>
            {value != undefined && value != "" ? (
              <label htmlFor={id}>
                <p className="input-file-logo d-flex">
                  <img src={value} className="checkbox-img m-auto" />
                </p>
              </label>
            ) : (
              <label htmlFor={id} className={`input-file-logo d-flex ${id}`}>
                <div className="m-auto">
                  <img src={FileIcon} className="my-1" />
                  <p className="a-text-regular-first-neutral-90 text-small">
                    Click para subir una imagen PNG, JPG de 5 MB máximo
                  </p>
                </div>
              </label>
            )}
          </label>
        </>
      );
    case "ilustration":
      return (
        <>
          <input
            type="file"
            id={id}
            className={`d-none ${className}`}
            autoFocus={focus}
            onChange={onChange}
            name={name}
          />
          {value != null ? (
            <label htmlFor={id}>

              <p className="input-file-img d-flex">

                <img
                  className="ilustration-file m-auto"
                  src={
                    value.includes("http")
                      ? `https://images.weserv.nl/?url=${value}&maxage=31d`
                      : `${value}`
                  }
                />

                {/* <img src={value} className='img-file m-auto' />*/}
              </p>
            </label>
          ) : (
            <label htmlFor={id} className={`input-file-ilustration p-4 d-flex ${id}`} >
              <div className="m-auto">
                <img src={FileIcon2} className="my-1" />
                <p className="a-text-medium-first-neutral-80 text-normal">
                  Click para subir una imagen PNG, JPG de 225x224px 200MB máximo
                </p>
              </div>
            </label>
          )}

        </>
      );
    case "add":
      return (
        <div
          className="d-flex input pl-2"
          style={{ width: `calc(${width}% - 10px)` }}
        >
          <input
            className={`input-basic text-normal py-1 a-text-medium-second-neutral-60 w-100 ${className}`}
            style={{ maxWidth: `100%`, minHeight: `27px` }}
            type="text"
            id={id}
            autoFocus={focus}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
          <img
            onClick={onClick}
            name={id}
            className="pr-2 cursor-pointer"
            src={AddIcon}
            alt="Add"
          />
        </div>
      );
    // ZAID
    case "textarea":
      return (
        <>
          <textarea
            placeholder={placeholder}
            className="text-normal textarea pt-2 pb-2 pl-2 a-text-medium-second-neutral-60 "
            style={{ width: `calc(${width}% - 10px) ` }}
            rows="1"
            id={id}
            autoFocus={focus}
            value={value}
            onChange={onChange}
          ></textarea>
          {warning ? (
            <p className="mt-2 a-text-regular-alert-60">{warning}</p>
          ) : (
            ""
          )}
        </>
      );
    case "textarearows":
      return (
        <>
          <textarea
            placeholder={placeholder}
            className="text-small h3-md textarea  a-text-regular-second-neutral-100 py-2 pl-2  w-100"
            style={{ height: `${deskTop ? "" : "100px"}` }}
            rows={rows}
            id={id}
            autoFocus={focus}
            value={value}
            onChange={onChange}
          ></textarea>
        </>
      );

    case "select2ScrollUsers":
      if (data != "") {
        return (
          <>
            {value != undefined ? (
              <input
                autoComplete="off"
                placeholder={placeholder}
                className={`text-normal input py-2 pl-2 mr-1 ${disabled
                  ? "a-text-medium-first-neutral-80"
                  : "a-text-medium-second-neutral-60"
                  }
                `}
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue || value}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
              />
            ) : (
              <input
                autoComplete="off"
                placeholder={placeholder}
                className="text-normal input py-2 pl-2 mr-1 a-text-medium-second-neutral-60"
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
              />
            )}
            {dataSelect(selectFilter, 2)}

            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }

    case "select2":
      if (data != "") {
        return (
          <>
            {value != undefined ? (
              <input
                autoComplete="off"
                placeholder={placeholder}
                className={`text-normal input py-2 pl-2 mr-1 ${disabled
                  ? "a-text-medium-first-neutral-80"
                  : "a-text-medium-second-neutral-60"
                  }
                `}
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue || value}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
              />
            ) : (
              <input
                autoComplete="off"
                placeholder={placeholder}
                className="text-normal input py-2 pl-2 mr-1 a-text-medium-second-neutral-60"
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
              />
            )}
            {dataSelect(selectFilter, 1)}

            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }

    case "select2Scroll":
      if (data != "") {
        return (
          <>
            {value != undefined ? (
              <DebounceInput
                autoComplete="off"
                placeholder={placeholder}
                className={`text-normal input py-2 pl-2 mr-1 ${disabled
                  ? "a-text-medium-first-neutral-80"
                  : "a-text-medium-second-neutral-60"
                  }
                `}
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue || value}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
                debounceTimeout={200}
              />
            ) : (
              <DebounceInput
                autoComplete="off"
                placeholder={placeholder}
                className="text-normal input py-2 pl-2 mr-1 a-text-medium-second-neutral-60"
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
                debounceTimeout={200}
              />
            )}
            {dataSelect(selectFilter, 1)}

            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }


    case "select2Scroll2":
      if (data != "") {
        return (
          <>
            {value != undefined ? (
              <DebounceInput
                autoComplete="off"
                placeholder={placeholder}
                className={`text-normal input py-2 pl-2 mr-1 ${disabled
                  ? "a-text-medium-first-neutral-80"
                  : "a-text-medium-second-neutral-60"
                  }
                `}
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue || value}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
                debounceTimeout={200}
              />
            ) : (
              <DebounceInput
                autoComplete="off"
                placeholder={placeholder}
                className="text-normal input py-2 pl-2 mr-1 a-text-medium-second-neutral-60"
                type="text"
                id={id}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={(e) => {
                  handleList(e, data);
                }}
                value={selectTwoValue}
                onChange={handleInputChange}
                onKeyDown={onDown}
                disabled={disabled}
                debounceTimeout={200}
              />
            )}
            {dataSelect(selectFilter, 4)}

            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }
    case "select":
      if (value != undefined && data != "") {
        return (
          <>
            <div
              className={`select input pt-2 pb-2 pl-2 ${disabled
                ? "a-text-medium-first-neutral-80"
                : "a-text-medium-second-neutral-60"
                } cursor-pointer ${className}`}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={disabled ? "" : handleList}
              id={id}
            >
              {selectValue || value}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 0)}
          </>
        );
      } else {
        return (
          <>
            <div
              className={`select ${className}
              ${selectValue.length > 0
                  ? "a-text-medium-second-neutral-60"
                  : "a-text-medium-first-neutral-80"
                } input pt-2 pb-2 pl-2 cursor-pointer `}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={handleList}
              id={id}
            >
              {selectValue || placeholder}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 0)}
            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }

    case "selectScroll":
      if (value != undefined && data != "") {
        return (
          <>
            <div
              className={`select input pt-2 pb-2 pl-2 ${disabled
                ? "a-text-medium-first-neutral-80"
                : "a-text-medium-second-neutral-60"
                } cursor-pointer ${className}`}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={disabled ? "" : handleList}
              id={id}
            >
              {selectValue || value}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 3)}
          </>
        );
      } else {
        return (
          <>
            <div
              className={`select ${className}
                ${selectValue.length > 0
                  ? "a-text-medium-second-neutral-60"
                  : "a-text-medium-first-neutral-80"
                } input pt-2 pb-2 pl-2 cursor-pointer `}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={handleList}
              id={id}
            >
              {selectValue || placeholder}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 3)}
            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }
    case "select3":
      return (
        <>
          <ClickAwayListener onClickAway={closeList}>
            <div>
              <div
                //className="select"
                className={`${className ? className : "a-text-regular-first-neutral-90"
                  } ${savedata ? "input-profile-after" : " "}  ${selectValue ? "input-profile-after" : " "
                  } input pt-2 pb-2 pl-2 cursor-pointer `}
                style={{ width: `calc(${width}% - 10px)` }}
                onClick={handleList}
                id={id}
              >
                {" "}
                {selectValue || savedata || placeholder}
                <img
                  src={ArrowIcon}
                  alt="Arrow Select"
                  className="arrow-icon"
                  id={id}
                />
              </div>
              {dataSelect(data, 0)}

              {warning ? (
                <p className="mt-2 a-text-regular-alert-60">{warning}</p>
              ) : (
                ""
              )}
            </div>
          </ClickAwayListener>
        </>
      );
    case "select4":
      if (value != "" && data != "") {
        return (
          <>
            <div
              className={`select input pt-2 pb-2 pl-2 ${disabled
                ? "a-text-medium-first-neutral-80"
                : "a-text-medium-second-neutral-60"
                } cursor-pointer ${className}`}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={disabled ? "" : handleList}
              id={id}
            >
              {value}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 0)}
          </>
        );
      } else {
        return (
          <>
            <div
              className={`select ${className ? className : "a-text-medium-first-neutral-80"
                } input pt-2 pb-2 pl-2 cursor-pointer `}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={disabled ? "" : handleList}
              id={id}
            >
              {placeholder}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 0)}
            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }

    case "selectAbsolute":
      if (value != "" && data != "") {
        return (
          <div className="d-flex flex-column flex-grow position-relative z-index-1">
            <div
              className={`select input pt-2 pb-2 pl-2 position-absolute-select ${disabled
                ? "a-text-medium-first-neutral-80"
                : "a-text-medium-second-neutral-60"
                } cursor-pointer ${className}`}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={disabled ? "" : handleList}
              id={id}
            >
              {value || placeholder}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 0)}
          </div>
        );
      } else {
        return (
          <div className="d-flex flex-column flex-grow position-relative">
            <div
              className={`position-absolute-select select ${className ? className : "a-text-regular-first-neutral-90"
                } input pt-2 pb-2 pl-2 cursor-pointer `}
              style={{ width: `calc(${width}% - 10px)` }}
              onClick={disabled ? "" : handleList}
              id={id}
            >
              {placeholder}
              <img
                src={ArrowIcon}
                alt="Arrow Select"
                className="arrow-icon"
                id={id}
              />
            </div>
            {dataSelect(data, 0)}
            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </div>
        );
      }

    case "addInput":
      return (
        <>
          <input
            placeholder={placeholder}
            className={`text-small input pt-2 pb-2 pl-2 a-text-medium-second-neutral-70  ${className} `}
            type={type}
            id={id}
            style={{ width: `calc(${width}% - 10px)` }}
            autoFocus={focus}
            value={value}
            onChange={onChange}
          />
          <IconButton onClick={onClick} className="add-icon">
            <img name={id} src={AddIcon} alt="Add Founder" />
          </IconButton>
        </>
      );

    case "checkbox":
      if (value != undefined && data != "") {
        return (
          <div className="mt-2">
            {data.map((d, i) => (
              <>
                {/* {console.log(checked[i])} */}
                <input
                  type="checkbox"
                  checked={value.find((v) =>
                    v.name
                      ? v.name === d.name
                      : v.Subtechnology === d.Subtechnology
                  )}
                  className={`d-none input-checkbox ${id} `}
                  id={`${id}-${i} `}
                  value={d.name || d.Subtechnology}
                  //onChange={(e) => onChange(e,d)}
                  onClick={(e) => onChange(e, d)}
                />
                <label
                  className={`${disabled
                    ? "btn-checkbox-form-inactive"
                    : "btn-checkbox-form"
                    } pt-4 px-3 mr-2 mb-2 text-small mr-md-2 mb-md-2 cursor-pointer`}
                  htmlFor={`${id}-${i} `}
                >
                  {d.name || d.Subtechnology}
                </label>
              </>
            ))}

            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </div>
        );
      }
      return "";

    case "checkbox2":
      if (value != undefined && data != "") {
        return (
          <div className="mt-2">
            {data.map((d, i) => (
              <>
                {/* {console.log(checked[i])} */}
                <input
                  type="checkbox"
                  // checked={value.find((v) => (

                  // )
                  // )}
                  className={`d-none input-checkbox ${id} `}
                  id={`${id}-${i} `}
                  value={d.Subtechnology}
                  //onChange={(e) => onChange(e,d)}
                  onClick={(e) => onChange(e, d)}
                />
                <label
                  className="btn-checkbox-form pt-4 px-3 mr-2 mb-2 text-small mr-md-2 mb-md-2 cursor-pointer"
                  htmlFor={`${id}-${i} `}
                >
                  {d.Subtechnology}
                </label>
              </>
            ))}
          </div>
        );
      }
      return "";

    case "checkbox-sm":
      if (value != undefined && data != "") {
        return (
          <>
            <div
              className={`${specialclass ? "" : "mt-2"
                } mr-lg-4 mr-xl-5 checkbox-text-container`}
            >
              {data.map((d, i) => (
                //Sino mandan limite se muestran todas las cards
                //en el className hacemos el toString() por que no podemos pasar un valor booleano como atributo
                <div className={(i > limit - 1 && ` ${visibilityCardText} `).toString()} key={i}>

                  <input
                    type="checkbox"
                    defaultChecked={value.find((v) => v.name === d.name)}
                    className={`d-none input-checkbox ${id} `}
                    id={`${id}-${i} `}
                    value={d.name}
                    onClick={(e) => onChange(e, d)}
                    disabled={disabled}
                  />
                  <label
                    className={`${disabled
                      ? "btn-checkbox-form-inactive"
                      : "btn-checkbox-form"
                      }  pl-3 pr-3 mr-2 mb-2 text-small cursor-pointer ${className != "" ? className : ""
                      }`}
                    htmlFor={`${id}-${i} `}
                  >
                    {d.name}
                  </label>
                </div>
              ))}
            </div>
            {
              //Se tiene que solicitar el boton de ver más y el limite debe de ser menor a el tamaño del array para poder mostrar el boton
              seeMoreButton && (
                <div
                  className={
                    limit
                      ? `btn-checkbox-sm ${visibilityCardSMText.toString()} `
                      : `d-none`
                  }
                >
                  <input
                    type="checkbox"
                    defaultChecked={false}
                    className={`d-none input-checkbox ${id} `}
                    id={`${id}-sm`}
                    value="seeMore"
                  />
                  <label
                    className={`${disabled ? "btn-checkbox-sm-inactive" : "btn-checkbox-sm"
                      } pr-3 mr-2 text-normal mr-md-4 cursor-pointer`}
                    onClick={showText}
                  >
                    Ver más...
                  </label>
                </div>
              )
            }
            {warning ? (
              <p className="mt-2 a-text-regular-alert-60">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }
      return "";

    //VICTOR
    case "checkboxImg":
      if (value != undefined && data != "") {
        return (
          <>
            <div className="mt-2 mr-lg-4 mr-xl-5 checkbox-img-container">
              {data.map((d, i) => (
                //Sino mandan limite se muestran todas las cards
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit && `${i > limit - 1 && visibilityCardClient} `
                  }
                >
                  {d.act == 1 ? (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.name == d.name ? true : false
                      )}
                      className={`d-none input-checkbox-img ${id} `}
                      id={`${id}-${i} `}
                      value={JSON.stringify({
                        client_Id: d.client_id,
                        name: d.name,
                        logo: d.logo_client
                          ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
                          : d.logo_client,
                      })}
                      onClick={onClick}
                      checked
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.name == d.name ? true : false
                      )}
                      className={`d-none input-checkbox-img ${id} `}
                      id={`${id}-${i} `}
                      value={JSON.stringify({
                        client_Id: d.client_id,
                        name: d.name,
                        logo: d.logo_client
                          ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
                          : d.logo_client,
                      })}
                      onClick={onClick}
                    />
                  )}
                  {d.logo_client ? (
                    <label
                      className={`btn-checkbox-img cursor-pointer  br-lg-2  ${className}`}
                      htmlFor={`${id}-${i} `}
                      onClick={onClick}
                    >
                      <img
                        className={`checkbox-img ${id} `}
                        id={`${id}-${i} `}
                        src={
                          d.logo_client.includes("http")
                            ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
                            : `${d.logo_client}`
                        }
                      ></img>
                    </label>
                  ) : (
                    <label
                      className={` btn-checkbox-img ${d.name.length <= 7 ? "text-medium" : "text-small"
                        }  pt-1 pb-1 pl-2 pr-2 cursor-pointer  checkbox-img-text ${className}`}
                      htmlFor={`${id}-${i} `}
                    >
                      {" "}
                      {d.name}{" "}
                    </label>
                  )}
                </div>
              ))}
            </div>

            {
              //Se tiene que solicitar el boton de ver más y el limite debe de ser menor a el tamaño del array para poder mostrar el boton
              seeMoreButton && (
                <div className={`btn-checkbox-sm ${visibilityCardSMClient} `}>
                  <input
                    type="checkbox"
                    defaultChecked={setVisibilityCardClient}
                    className={`d-none input-checkbox ${id}-sm`}
                    id={`${id}-sm`}
                    value="seeMore"
                  />
                  <label
                    className="text-normal pr-2 cursor-pointer checkbox-img-text-sm"
                    onClick={showClient}
                  >
                    Ver más...
                  </label>
                </div>
              )
            }
            {warning ? (
              <p className=" a-text-regular-alert-60 mb-3">{warning}</p>
            ) : (
              ""
            )}
          </>
        );
      }
      return "";
    // case "checkboxIcon":
    //   if (value != undefined && data != "") {
    //     console.log(data, "data input diego ");
    //     console.log(className, "value input diego ");
    //     return (
    //       <>
    //         <div
    //           className={` checkbox-img-container  justify-content-md-start  justify-content-lg-start  `}
    //         >
    //           {data.map((d, i) => (
    //             <div
    //               className={
    //                 d.act == 1 && i > limit - 1
    //                   ? (limit = null)
    //                   : limit && `${i > limit - 1 && visibilityCardClient} `
    //               }
    //               key={i}
    //             >
    //               {d.act == 1 ? (
    //                 <input
    //                   type="checkbox"
    //                   name={name}
    //                   defaultChecked={value.find((v) =>
    //                     v.topics_id == d.topics_id ? true : false
    //                   )}
    //                   className={`d-none cursor-pointer input-checkbox-icon br-3 ${id} `}
    //                   id={`${id}-${i} `}
    //                   value={JSON.stringify({
    //                     topic_id: d.topics_id,
    //                     title: d.name,
    //                     icon: d.link_logo_topic
    //                       ? `https://images.weserv.nl/?url=${d.llink_logo_topic}&maxage=31d`
    //                       : d.link_logo_topic,
    //                   })}
    //                   onClick={onClick}
    //                   checked
    //                 />
    //               ) : (
    //                 <input
    //                   type="checkbox"
    //                   name={name}
    //                   defaultChecked={value.find((v) =>
    //                     v.topics_id == d.topics_id ? true : false
    //                   )}
    //                   className={`d-none cursor-pointer input-checkbox-icon  br-3 ${id} `}
    //                   id={`${id}-${i} `}
    //                   value={JSON.stringify({
    //                     topic_id: d.topics_id,
    //                     name: d.name,
    //                     icon: d.link_logo_topic
    //                       ? `https://images.weserv.nl/?url=${d.link_logo_topic}&maxage=31d`
    //                       : d.link_logo_topic,
    //                   })}
    //                   onClick={onClick}
    //                 />
    //               )}
    //               {d.link_logo_topic ? (
    //                 <label
    //                   className={`btn-checkbox-icon cursor-pointer   ${className}`}
    //                   htmlFor={`${id}-${i} `}
    //                   onClick={onClick}
    //                 >
    //                   <img
    //                     className={`checkbox-Icon mb-md-3 input-checkbox-icon ${id} `}
    //                     id={`${id}-${i} `}
    //                     src={`https://images.weserv.nl/?url=${d.link_logo_topic}&maxage=31d`}
    //                   ></img>
    //                   <p
    //                     className={`checkbox-icon-text  text-xs text-md-normal  input-checkbox-icon   mt-lg-2`}
    //                   >
    //                     {d.name}
    //                   </p>
    //                 </label>
    //               ) : (
    //                 <label
    //                   className={`btn-checkbox-icon ${
    //                     d.name.length <= 7 ? "text-medium" : "text-small"
    //                   }  pt-1 pb-1 pl-2 pr-2 cursor-pointer  checkbox-icon-text ${className}`}
    //                   htmlFor={`${id}-${i} `}
    //                 >
    //                   {" "}
    //                   {d.name}{" "}
    //                 </label>
    //               )}
    //             </div>
    //           ))}
    //         </div>
    //       </>
    //     );
    //   }
    //   return "";
    case "checkboxIcon":
      if (value != undefined && data != "") {
        return (
          <>
            <div
              className={` checkbox-img-container  justify-content-md-start  justify-content-lg-start  `}
            >
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit && `${i > limit - 1 && visibilityCardClient} `
                  }
                  key={i}
                >
                  {d.act == 1 ? (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.topics_id == d.topics.topics_id ? true : false
                      )}
                      className={`d-none cursor-pointer input-checkbox-icon br-3 ${id} `}
                      id={`${id}-${i} `}
                      value={JSON.stringify({
                        topic_id: d.topics.topics_id,
                        title: d.topics.name,
                        icon: d.topics.link_logo_topic
                          ? `https://images.weserv.nl/?url=${d.topics.link_logo_topic}&maxage=31d`
                          : d.topics.link_logo_topic,
                      })}
                      onClick={onClick}
                      checked
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value?.find((v) =>
                        v.topics_id == d.topics.topics_id ? true : false
                      )}
                      className={`d-none cursor-pointer input-checkbox-icon  br-3 ${id} `}
                      id={`${id}-${i} `}
                      value={JSON.stringify({
                        topic_id: d.topics.topics_id,
                        name: d.topics.name,
                        icon: d.topics.link_logo_topic
                          ? `https://images.weserv.nl/?url=${d.topics.link_logo_topic}&maxage=31d`
                          : d.link_logo_topic,
                      })}
                      onClick={onClick}
                    />
                  )}
                  {d.topics.link_logo_topic ? (
                    <label
                      className={`btn-checkbox-icon cursor-pointer   ${className}`}
                      htmlFor={`${id}-${i} `}
                      onClick={onClick}
                    >
                      <img
                        className={`checkbox-Icon mb-md-3 input-checkbox-icon ${id} `}
                        id={`${id}-${i} `}
                        src={`https://images.weserv.nl/?url=${d.topics.link_logo_topic}&maxage=31d`}
                      ></img>
                      <p
                        className={`checkbox-icon-text  text-xs text-md-normal  input-checkbox-icon   mt-lg-2`}
                      >
                        {d.topics.name}
                      </p>
                    </label>
                  ) : (
                    <label
                      className={`btn-checkbox-icon ${d.topics.name.length <= 7 ? "text-medium" : "text-small"
                        }  pt-1 pb-1 pl-2 pr-2 cursor-pointer  checkbox-icon-text ${className}`}
                      htmlFor={`${id}-${i} `}
                    >
                      {" "}
                      {d.topics.name}{" "}
                    </label>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";
    case "checkboxTextTopic":
      if (value != undefined && data != "") {
        return (
          <>
            <div className="ml-lg-4 mr-xl-5  w-100 d-flex flex-wrap">
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit && `${i > limit - 1 && visibilityCardClient} `
                  }
                  key={i}
                >
                  {d.act == 1 ? (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.title == d.title ? true : false
                      )}
                      className={`d-none input-checkbox-topic br-3 ${id} `}
                      id={`${id}-${i} `}
                      value={JSON.stringify({
                        topic_id: d.title,
                        title: d.title,
                      })}
                      onClick={onClick}
                      checked
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.title == d.title ? true : false
                      )}
                      className={`d-none input-checkbox-topic br-3 ${id} `}
                      id={`${id}-${i} `}
                      value={JSON.stringify({
                        topic_id: d.title,
                        name: d.title,
                      })}
                      onClick={onClick}
                    />
                  )}

                  <label
                    className={`btn-checkbox-icon-text a-text-regular-first-primary-60  px-3 py-2 br-2 ml-2 box-shadow-none text-lg-normal  ${className}`}
                    htmlFor={`${id}-${i} `}
                    onClick={() => setModalConfirm(!confirmModal)}
                  >
                    <p
                      className={`${d.title.length <= 7
                        ? "text-small text-lg-normal"
                        : "text-small text-lg-normal"
                        }  text-center`}
                    >
                      {d.title}
                    </p>
                  </label>
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";
    // case "checkboxImgBlog":
    //   if (value != undefined && data.length != "") {
    //     // console.log('value', value)
    //     // console.log('data', data)
    //     return (
    //       <>
    //         <div className="  d-flex scroll-overflow ml-5 py-2 px-1 ">
    //           {data.map((d, i) => (
    //             //Sino mandan limite se muestran todas las cards
    //             <div
    //               className={
    //                 d.act == 1 && i > limit - 1
    //                   ? (limit = null)
    //                   : limit && `${i > limit - 1 && visibilityCardClient} `
    //               }
    //             >
    //               {d.act == 1 ? (
    //                 <input
    //                   type="checkbox"
    //                   name={name}
    //                   defaultChecked={filteredTopic?.client_Id === d.client_id}
    //                   className={`d-none input-checkbox-img-blog ${id} `}
    //                   id={`${id}-${i} `}
    //                   value={JSON.stringify({
    //                     client_Id: d.client_id,
    //                     name: d.name,
    //                     logo: d.logo_client
    //                       ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
    //                       : d.logo_client,
    //                   })}
    //                   onClick={onClick}
    //                   checked
    //                 />
    //               ) : (
    //                 <input
    //                   type="checkbox"
    //                   name={name}
    //                   // defaultChecked={value.find((v) =>
    //                   //   v.client_id == d.client_id ? true : false
    //                   // )}
    //                   defaultChecked={filteredTopic?.client_Id === d.client_id}
    //                   className={`d-none input-checkbox-img-blog ${id} `}
    //                   id={`${id}-${i} `}
    //                   value={JSON.stringify({
    //                     client_Id: d.client_id,
    //                     name: d.name,
    //                     logo: d.logo_client
    //                       ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
    //                       : d.logo_client,
    //                   })}
    //                   // onClick={onClick}
    //                   onClick={ () => {
    //                     setFilteredTopic({
    //                       client_Id: d.client_id,
    //                       logo_client: d.logo_client,
    //                     });
    //                   }}
    //                 />
    //               )}
    //               {d.logo_client ? (
    //                 <label
    //                   className={`btn-checkbox-img-blog cursor-pointer  br-lg-2  ${className} ${
    //                     d.logo_client == "undefined" ? "d-none" : ""
    //                   }`}
    //                   htmlFor={`${id}-${i} `}
    //                   onClick={() => {
    //                     console.log("Has presionado una imágen");
    //                     console.log("portada", filteredTopic);
    //                     setFilteredTopic({
    //                       client_Id: d.client_id,
    //                       logo_client: d.logo_client,
    //                     });
    //                   }}
    //                 >
    //                   <img
    //                     className={`checkbox-img-blog ${id}  `}
    //                     id={`${id}-${i} `}
    //                     src={
    //                       d.logo_client.includes("http")
    //                         ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
    //                         : `${d.logo_client}`
    //                     }
    //                   ></img>
    //                 </label>
    //               ) : (
    //                 <label
    //                   className={` btn-checkbox-img-blog d-none ${
    //                     d.name.length <= 7 ? "text-medium" : "text-small"
    //                   }  pt-1 pb-1 pl-2 pr-2 cursor-pointer btn-checkbox-img-blog ${className}`}
    //                   htmlFor={`${id}-${i} `}
    //                   onClick={() => {
    //                     setFilteredTopic({
    //                       client_Id: d.client_id,
    //                       logo_client: d.logo_client,
    //                     });
    //                   }}
    //                 >
    //                   {" "}
    //                   {d.name}{" "}
    //                 </label>
    //               )}
    //             </div>
    //           ))}
    //         </div>
    //       </>
    //     );
    //   }
    case "checkboxImgBlog":
      if (data.length != 0) {
        return (
          <>
            <div className="  d-flex scroll-overflow ml-5 py-2 px-1 ">
              {data.map((d, i) => (
                <div>
                  {d.logo_client ? (
                    <label
                      className={`btn-checkbox-img-blog cursor-pointer  br-lg-2 ${filteredTopic?.client_Id === d.client_id
                        ? "checked-img-blog-portada"
                        : "unchecked-img-blog-portada"
                        } ${className} ${d.logo_client == "undefined" ? "d-none" : ""
                        }`}
                      htmlFor={`${id}-${i} `}
                      onClick={() => {
                        console.log("Has presionado una imágen");
                        console.log("portada", filteredTopic);
                        setFilteredTopic({
                          client_Id: d.client_id,
                          logo_client: d.logo_client,
                        });
                      }}
                    >
                      <img
                        className={`checkbox-img-blog ${id}  `}
                        id={`${id}-${i} `}
                        src={
                          d.logo_client.includes("http")
                            ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
                            : `${d.logo_client}`
                        }
                      ></img>
                    </label>
                  ) : (
                    <label
                      className={` btn-checkbox-img-blog d-none ${d.name.length <= 7 ? "text-medium" : "text-small"
                        }  pt-1 pb-1 pl-2 pr-2 cursor-pointer btn-checkbox-img-blog ${className}`}
                      htmlFor={`${id}-${i} `}
                      onClick={() => {
                        setFilteredTopic({
                          client_Id: d.client_id,
                          logo_client: d.logo_client,
                        });
                      }}
                    >
                      {" "}
                      {d.name}{" "}
                    </label>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      }
    case "checkboxImgUploadedBlog":
      if (data.length != 0) {
        return (
          <>
            <div className="  d-flex scroll-overflow ml-5 py-2 px-1 ">
              {data.map((d, i) => (
                <div className={deletedImgs.includes(i) ? "d-none" : ""}>
                  {
                    <label
                      className={`btn-checkbox-img-blog cursor-pointer  br-lg-2  ${className} ${filteredTopic == i ||
                        (filteredTopic == -1 && filteredTopics == d.src)
                        ? "checked-img-blog-portada"
                        : "unchecked-img-blog-portada"
                        } `}
                      htmlFor={`${id}-${i} `}
                      onClick={() => {
                        console.log("Has presionado una imágen");
                        const regexImg = "data:image/";
                        if (d.src.includes(regexImg)) {
                          setFilteredTopic(i);
                        } else {
                          console.log("no lo contiene");
                          setFilteredTopic(-1);
                          setFilteredTopics(d.src);
                        }
                      }}
                    >
                      <img
                        className={`checkbox-img-blog ${id}  `}
                        id={`${id}-${i} `}
                        src={d.src}
                      ></img>
                    </label>
                  }
                </div>
              ))}
            </div>
          </>
        );
      }
    case "checkboxTextTopicFilterBlog":
      if (value != undefined && data != "") {
        const categoriasBlogs = value.map(filterState => data.find(labelState => filterState === labelState.name))
       
        return (
          <>
            <div className=" d-flex scroll-overflow">
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit &&
                      `  ${i > limit - 1 && visibilityCardClient
                      } br-4  py-1 px-2   py-md-2  px-md-4 py-lg-1 h-a  switch-question  mr-2 `
                  }
                  key={i}
                  onClick={() => {
                    setFilteredTopic(d.name);
                  }}
                >
                  {d.act == 1 ? (
                    <>
                      <input
                        type="checkbox"
                        name={name}
                        checked={categoriasBlogs.find(categoria => categoria.name == d.name) ? true : false}
                        className={` d-none cursor-pointer input-checkbox-textBlog  ${className} `}
                        id={`${id}-${d.topics_id} `}
                        value={JSON.stringify({ topics_id: d.topics_id })}
                        
                      />
                    </>
                  ) : (
                    <input
                      type="checkbox"
                      name={name}
                      checked={categoriasBlogs.find(categoria => categoria.name == d.name) ? true : false}
                      className={`d-none cursor-pointer input-checkbox-textBlog ${className} `}
                      id={`${id}-${d.topics_id} `}
                      value={JSON.stringify({ topics_id: d.topics_id })}
                    />
                  )}
                  <label
                    className={` filter-blog-topic text-lg-normal  a-text-regular-first-primary-60 mr-2  py-1 px-2   py-md-2  px-md-4 py-lg-1 br-4  box-shadow-none cursor-pointer`}
                    // ${
                    //   value.find((v) => (v === d.name ? true : false))
                    //     ? `${className} cursor-pointer`
                    //     : `false ${value.toString()} cursor-pointer `
                    // } ` 
                    htmlFor={`${id}-${i} `}
                  >
                    <p
                      className={`cursor-pointer ${d.name.length <= 7
                        ? "text-small text-lg-normal"
                        : "text-smal text-lg-normall"
                        }  text-center`}
                    >
                      {d.name}
                    </p>
                  </label>
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";

    //Este case se usa ahora para que el filtro de las preguntas funcione como un checkbox
    case "checkboxLabelsPodcast":
      if (data != "") {
        return (
          <>
            <div className="  d-flex scroll-overflow">
              {/* <span className="a-text-regular-first-neutral-100 text-md-medium mt-1 mr-1">Filtrar por:</span> */}
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit &&
                      `${i > limit - 1 && visibilityCardClient
                      } br-4  py-1 px-2   py-md-2  px-md-4 py-lg-1 h-a  switch-question  mr-2 `
                  }
                  key={i}
                >
                  <label
                    className={` switch-question-check text-lg-normal  a-text-regular-first-primary-60 mr-2  py-1 px-2   py-md-2  px-md-4 py-lg-1 br-4  box-shadow-none cursor-pointer ${filteredTopics.includes(d.labels_id)
                      ? "filterChecked"
                      : ""
                      }
                      `}
                    htmlFor={`${id}-${i} `}
                    onClick={() => handleClickTopic(d.labels_id)}
                  >
                    <p className={`${"text-small text-lg-normal"}`}>
                      {d.name}
                    </p>
                  </label>
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";

    case "checkboxTextTopicsFilter":
      const temas = [
        {
          "topics_id": 10,
          "name": "Todas",
        },
        {
            "topics_id": 1,
            "name": "Ciencia",
        },
        {
          "topics_id": 2,
          "name": "Tecnología",
        },
        {
          "topics_id": 3,
          "name": "Economía",
        },
        {
          "topics_id": 4,
          "name": "Negocios",
        },
        {
          "topics_id": 5,
          "name": "Finanzas",
        },
        {
          "topics_id": 6,
          "name": "Política",
        },
        {
          "topics_id": 7,
          "name": "Desarrollo",
        },
        {
          "topics_id": 8,
          "name": "Web",
        },
        {
          "topics_id": 9,
          "name": "Gaming",
        }
      ]
      if (data != "") {
        return (
          <>
            <div className="  d-flex scroll-overflow">
              {/* <span className="a-text-regular-first-neutral-100 text-md-medium mt-1 mr-1">Filtrar por:</span> */}
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit &&
                      `${i > limit - 1 && visibilityCardClient
                      } br-4  py-1 px-2   py-md-2  px-md-4 py-lg-1 h-a  switch-question  mr-2 `
                  }
                  key={i}
                >

                  {/* {
                    <>
                      <input
                        type="checkbox"
                        name={name}
                        defaultChecked={filteredTopics.includes(d.topics_id)}
                        className={`d-none input-checkbox-textTopic ${id}`}
                        id={`${id}-${d.topics_id} `}
                        value={JSON.stringify({ topics_id: d.topics_id })}
                      />
                    </>
                  } */}

                  {d.count == 0 ? (
                    <Tooltip title="sin preguntas">
                      <label
                        className={` switch-question-check-none text-lg-normal    a-text-bold-second-neutral-40 mr-2  py-1 px-2   py-md-2  px-md-4 py-lg-1 br-4  box-shadow-none cursor-pointer`}
                        htmlFor={`${id}-${i} `}
                      >
                        <p className={`${"text-small text-lg-normal"}`}>
                          {d.name}
                        </p>
                      </label>
                    </Tooltip>
                  ) : (
                    <label
                      className={` switch-question-check text-lg-normal  a-text-regular-first-primary-60 mr-2  py-1 px-2   py-md-2  px-md-4 py-lg-1 br-4  box-shadow-none cursor-pointer ${filteredTopics.includes(d.topics.topics_id)
                        ? "filterChecked"
                        : ""
                        }
                      `}
                      htmlFor={`${id}-${i} `}
                      onClick={() => handleClickTopic(d.topics.topics_id)}
                    >
                      <p className={`${"text-small text-lg-normal"}`}>
                        {temas.map( tema => (
                          tema.topics_id === d.topics.topics_id && tema.name 
                        ))}
                      </p>
                    </label>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";

    case "checkboxTextAllTopicsFilter":
      if (data != "") {
        console.log("esto es data", data);
        console.log(compare);
        console.log(filteredTopics, "filteredTopics");
        return (
          <>
            <div className="  d-flex scroll-overflow">
              {/* <span className="a-text-regular-first-neutral-100 text-md-medium mt-1 mr-1">Filtrar por:</span> */}
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit &&
                      `${i > limit - 1 && visibilityCardClient
                      } br-4  py-1 px-2   py-md-2  px-md-4 py-lg-1 h-a  switch-question  mr-2 `
                  }
                  key={i}
                >
                  {console.log(
                    filteredTopics.includes(d.topics.topics_id),
                    "incluye?",
                    d.topics_id,
                    "este es el topic"
                  )}

                  {/* {
                    <>
                      <input
                        type="checkbox"
                        name={name}
                        defaultChecked={filteredTopics.includes(d.topics.topics_id)}
                        className={`d-none input-checkbox-textTopic ${id}`}
                        id={`${id}-${d.topics.topics_id} `}
                        value={JSON.stringify({ topics_id: d.topics.topics_id })}
                      />
                    </>
                  } */}

                  {d.compare == null || d.compare == 0 ? (
                    <Tooltip title="sin preguntas">
                      <label
                        className={` switch-question-check-none text-lg-normal    a-text-bold-second-neutral-40 mr-2  py-1 px-2   py-md-2  px-md-4 py-lg-1 br-4  box-shadow-none cursor-pointer`}
                        htmlFor={`${id}-${i} `}
                      >
                        <p className={`${"text-small text-lg-normal"}`}>
                          {d.topics.name}
                        </p>
                      </label>
                    </Tooltip>
                  ) : (
                    <label
                      className={` switch-question-check text-lg-normal  a-text-regular-first-primary-60 mr-2  py-1 px-2   py-md-2  px-md-4 py-lg-1 br-4  box-shadow-none cursor-pointer ${filteredTopics.includes(d.topics.topics_id)
                        ? "filterChecked"
                        : ""
                        }
                      `}
                      htmlFor={`${id}-${i} `}
                      onClick={() => handleClickTopic(d.topics.topics_id)}
                    >
                      <p className={`${"text-small text-lg-normal"}`}>
                        {d.topics.name}
                      </p>
                    </label>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";
    case "checkboxUserAsign":
      if (value != undefined && data != "") {
        return (
          <>
            <div className="  d-flex flex-column  px-2">
              {data.map((d, i) => (
                <div
                  className={
                    d.act == 1 && i > limit - 1
                      ? (limit = null)
                      : limit && `${i > limit - 1 && visibilityCardClient} `
                  }
                  key={i}
                >
                  {d.act == 1 ? (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.user_id === d.user_id ? true : false
                      )}
                      className={`d-none input-checkbox-cidUser br-3 ${id} `}
                      id={`${d.user_id}-${i} `}
                      value={JSON.stringify({
                        user_id: d.user_id,
                        name: d.name,
                      })}
                      onClick={onClick}
                      checked
                    />
                  ) : (
                    <input
                      type="checkbox"
                      name={name}
                      defaultChecked={value.find((v) =>
                        v.user_id == d.user_id ? true : false
                      )}
                      className={`d-none input-checkbox-cidUser br-3 ${id} `}
                      id={`${d.user_id}-${i} `}
                      value={JSON.stringify({
                        user_id: d.user_id,
                        name: d.name,
                      })}
                      onClick={() => {
                        console.log(d.user_id);
                      }}
                    />
                  )}

                  <label
                    className={`card mb-3 h-a d-flex br-2 btn-checkbox-cidUser  btn-checkbox-icon-cid   px-4  py-4 align-items-center box-shadow-lg-all`}
                    htmlFor={`${id}-${i} `}
                    onClick={() => {
                      setUser({
                        nameUser: `${d.name} ${d.lastname}`,
                        user_id: d.user_id,
                      });
                      setModal(true);
                      setModalLocation(false);
                    }}
                  >
                    <img
                      src={user2}
                      alt="icon"
                      className="icon-card-question icon-md-card-question  mr-2 align-self-start "
                    />

                    <span className="a-text-medium-first-primary-70 text-normal text-md-medium ">
                      {d.name} {d.lastname}{" "}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </>
        );
      }
      return "";
    case "social":
      return (
        <div className="">
          <div className={`d-flex ${className} `}>
            <img src={imagen} className="mr-2 w-5" />
            <input
              placeholder={placeholder}
              className={`text-normal input pt-2 pb-2 pl-2 ml-1 ${disabled
                ? "a-text-medium-first-neutral-80"
                : "a-text-medium-second-neutral-60"
                }`}
              type="text"
              id={id}
              style={{ width: `calc(${width}% - 10px)` }}
              autoFocus={focus}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
          {warning && (
            <p className="ml-4 pl-3 mb-2 a-text-regular-alert-60">{warning}</p>
          )}
        </div>
      );
    case "disable":
      return (
        <input
          disabled="true"
          className={`text-normal input-disable pt-2 pb-2 pl-2 mt-2 a-text-regular-second-neutral-60 ${className} `}
          style={{ width: `calc(100% - 10px)` }}
          value={value}
        />
      );
    case "document":
      return (
        <>
          <input
            className={`d-none ${className} `}
            type="file"
            id={id}
            autoFocus={focus}
            onChange={onChange}
          />

          <label
            htmlFor={id}
            className={`button-form text-small minuta_btn-add d-flex align-items-center justify-content-center ${className} `}
            style={{
              height: iPadMatch ? 36 : 28,
              width: iPadProMatch ? 255 : "60%",
            }}
          >
            <img src={ClipIcon} width={16} className="pr-2" />
            <span>Adjuntar</span>
          </label>
          {warning ? (
            <p className="mt-2 a-text-regular-alert-60 mb-3">{warning}</p>
          ) : (
            ""
          )}
        </>
      );
    case "checkboxImgPodcast":
      if (data.length != 0) {
        return (
          <>
            <div className="d-flex scroll-overflow py-2">
              {data.map((d, i) => (
                <div>
                  {d.logo_client ? (
                    <label
                      className={`btn-checkbox-img-blog cursor-pointer  br-lg-2 ${filteredTopic?.client_Id === d.client_id
                        ? "checked-img-blog-portada"
                        : "unchecked-img-blog-portada"
                        } ${className} ${d.logo_client == "undefined" ? "d-none" : ""
                        }`}
                      htmlFor={`${id}-${i} `}
                      onClick={() => {
                        console.log("Has presionado una imágen");
                        console.log("portada", filteredTopic);
                        setFilteredTopic({
                          client_Id: d.client_id,
                          logo_client: d.logo_client,
                        });
                      }}
                    >
                      <img
                        className={`checkbox-img-blog ${id}  `}
                        id={`${id}-${i} `}
                        src={
                          d.logo_client.includes("http")
                            ? `https://images.weserv.nl/?url=${d.logo_client}&maxage=31d`
                            : `${d.logo_client}`
                        }
                      ></img>
                    </label>
                  ) : (
                    <label
                      className={` btn-checkbox-img-blog d-none ${d.name.length <= 7 ? "text-medium" : "text-small"
                        }  pt-1 pb-1 pl-2 pr-2 cursor-pointer btn-checkbox-img-blog ${className}`}
                      htmlFor={`${id}-${i} `}
                      onClick={() => {
                        setFilteredTopic({
                          client_Id: d.client_id,
                          logo_client: d.logo_client,
                        });
                      }}
                    >
                      {" "}
                      {d.name}{" "}
                    </label>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      }

    default:
      return (
        <input
          placeholder={placeholder}
          className={`text-normal input pt-2 pb-2 pl-2 a-text-medium-second-neutral-60 ${className} `}
          type="text"
          id={id}
          style={{ width: `calc(${width}% - 10px)` }}
          autoFocus={focus}
          value={value}
          onChange={onChange}
        />
      );
  }
}

export { Input };