//React
import { Redirect } from "react-router-dom";

//Components
import FormLogin from "../../../components/organisms/Form/FormLogin";

//Material
import { Grid } from '@material-ui/core';

//Images
import Logo from "../../../assets/img/logo.svg";
import LandingImage from "../../../assets/img/landing-image.png";

//Api
import { getAccessToken } from "../../../api/auth";
import { signInApi } from "../../../api/user";

//Jwt
import jwtDecode from "jwt-decode";

export default function SignIn() {

    // Funciones
    if (getAccessToken()) {
        let { rolId } = jwtDecode(getAccessToken())
        if (rolId === 1 || rolId === 2) {
            return <Redirect to="/cid" />
        } else {
            return <Redirect to="/cid/carso/home" />
        }
    }

    return (
        <div className="sign-in-background">
            <div className="sign-in-content">
                <Grid container alignItems="center" justify="center" spacing={0}>
                    <Grid item lg={5} className='d-none d-xl-block'>
                        <h1 className="text-center h1 a-text-bold-second-neutral-70 mb-4">¡Comunícate con toda la comunidad Carso!</h1>
                        <p className="text-center a-text-bold-second-neutral-40 h3">Consulta investigaciones, haz preguntas y muchas cosas más</p>
                        <img src={LandingImage} alt="Landing" className="sign-in-image mt-4" />
                    </Grid>
                    <Grid item lg={1} className='d-none d-xl-block'></Grid>
                    <Grid item xs={10} md={6} lg={4} className='sign-in-container'>
                        <img className="pt-5 mb-5 logo d-block mx-auto" src={Logo} alt="Wikicid Logo" />
                        <FormLogin id='form-signIn' callApi={signInApi} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
