// React
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Material
import {
  Grid,
  useMediaQuery,
  createMuiTheme,
  IconButton,
  Badge,
  Modal,
} from "@material-ui/core/";
import { Link } from "react-router-dom";
import { Button } from "../../../components/atoms/Button";

//Imagenes
import FilterIconOcean from "../../../assets/img/filtrar.svg";
import OrderIconOcean from "../../../assets/img/order-icon-ocean.svg";
import ModalSuccess from "../../../components/molecules/Modal/SuccessModal";

import Header from "../../../components/molecules/Header";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import {
  getBlogs,
  getTopAuthors,
  deleteBlog,
  getLabels,
  getFilteredBlogs,
  getSavedBlogs,
  getOrderedBlogs,
  getBlogsFiltered,
} from "../../../api/blogs";
import SkeletonBlog from "../../../components/molecules/Skeleton/SkeletonBlog/index";
import SkeletonAuthor from "../../../components/molecules/Skeleton/SkeletonBlog/Authors";

// Componentes
import { WarningModal } from "../../../components/molecules/Modal/WarningModal";
import MenuTabUser from "../../../components/molecules/TabMenu/twoOpt";
import MenuUser from "../../../components/organisms/MenuQuestionsUser/blogmenu";

import CardBlog from "../../../components/molecules/Card/CardBlog/index";
import CardBlogVertical from "../../../components/molecules/Card/CardBlog/CardOptionTwo";

import CardAuthor from "../../../components/molecules/Card/CardBlog/auhtors";
import Orders from "../../../components/organisms/Filters/order";
import { BlogFilter } from "../../../components/organisms/Filters/BlogFilters/BlogFilter";
import { Order } from "../../../components/organisms/Filters/Order/Order";
/*


En este componente vas a encontrar las listas de nuestro filtrado,
por el momento el contenido filtrado no esta disponible o esta inconcluso.

Mi lógica para manejar este problema fue diferente a la utilizada anteriormente as´que te recomiendo que revises bien los ejemplos anteriores 
un caso de uso para los filtros e en la sección de company 
el componente que lo almacena es una molecula que se llama filter.

Este componente nos ayuda a diferenciar entre las secciones
es completamente funcional y a menos que te hagan rehacerlo no tendrás problema con el

puedes ver que aquí llamo al modal, ya que la funcionalidad de ese modal se ejecuta en un componente más interno
pero su información la necesito manejar en el componente padre 
si tienes dudas sigue el componente con sus parametros hasta donde llega en cualquier caso sus parametros son iguales

*/

export default function BlogList(e) {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [loadAuthors, setLoadAuthors] = useState(true);
  const [filterName, setFilterName] = useState({
    Tec: false,
    Energ: false,
    Innova: false,
    Entret: false,
    Popular: false,
    Recent: false,
  });
  const [loadDelete, setLoadDelete] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [viewType, setViewType] = useState(localStorage.getItem('viewTypeBlogs') ? JSON.parse(localStorage.getItem('viewTypeBlogs')) : true);

  const [isActiveList, setIsActiveList] = useState(true);
  const [isActiveActivi, setIsActiveActivi] = useState(false);
  const [order, setOrder] = useState(false);
  const [filter, setFilter] = useState(false);

  const [blogs, setBlogs] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [savedBlogs, setSavedBlogs] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [blog, setBlog] = useState();

  const [filters, setFilters] = useState( localStorage.getItem('blogFilters') ? JSON.parse(localStorage.getItem('blogFilters')): []);
  const [orderType, setOrderType] = useState("rec")

  const [allBlogsForSearch, setAllBlogsForSearch] = useState();

  const user = useAuth();

  const { userId } = user;
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  useEffect(() => {
    if (filters.length == 0) {
      getAllBlogs(userId);
      getAllAuthors(userId);
      getAllSavedBlogs(userId);
    } else {
      getAllBlogsFiltered(filters);
      getAllAuthors(userId);
      getAllSavedBlogs(userId);
    }
  }, []);

  // useEffect(() => {
  //   if(filters.length == 0 && orderByPopular == false){
  //     getAllBlogs(userId);
  //     getAllAuthors(userId);
  //     getAllSavedBlogs(userId);
  //   } else if (orderByPopular){
  //       OrderBlogs(1,0,userId);
  //       getAllAuthors(userId);
  //       getAllSavedBlogs(userId);
  //   } else {
  //       getAllBlogsFiltered(filters)
  //       getAllAuthors(userId);
  //       getAllSavedBlogs(userId);
  //   }
  // }, [deleteSuccess, isActiveList, filters, orderByPopular]);

  useEffect(() => {
    if (filters.length == 0) {
      if (orderType == "rec") {
        getAllBlogs(userId, false);
      } else {
        getAllBlogs(userId, true);
      }
    } else {
      if (orderType == "rec") {
        getAllBlogsFiltered(filters, false);
      } else {
        getAllBlogsFiltered(filters, true);
      }
    }
    getAllAuthors(userId);
    getAllSavedBlogs(userId);
  }, [deleteSuccess, isActiveList, filters, orderType]);


  // useEffect(() => {
  //   if(orderByPopular){
  //     OrderBlogs(1,0,userId);
  //     getAllAuthors(userId);
  //     getAllSavedBlogs(userId);
  //   } else {
  //     getAllBlogs(userId);
  //     getAllAuthors(userId);
  //     getAllSavedBlogs(userId);
  //   }
  // }, [orderByPopular, orderByRecent])

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const Desk = useMediaQuery("(min-width: 1100px)");

  useEffect(() => {
    localStorage.setItem('blogFilters', JSON.stringify(filters));
  }, [filters])
  
  async function getAllBlogs(userId, order) {
    try {
      if (!order) {
        setLoad(true);
        const { data } = await getBlogs(userId);
        setBlogs(data.blogs);
        setAllBlogsForSearch(data.blogs);
        setLoad(false);
      } else {
        setLoad(true);
        const { data } = await getBlogs(userId);
        let aux = data.blogs
        let orderedBlogs = orderingBlogs(aux)
        setBlogs(orderedBlogs)
        setLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  }


  function orderingBlogs(blogs) {
    switch (orderType) {
      case "ant":
        blogs.reverse()
        return blogs
      case "az":
        blogs.sort((a, b) => a.title.localeCompare(b.title))
        return blogs
      case "za":
        blogs.sort((a, b) => -1 * a.title.localeCompare(b.title))
        return blogs
    }
  }

  async function deleteObject(userId, blog_id) {
    try {
      setOpenSuccess(true);

      const deleted = await deleteBlog(userId, blog_id);

      if (deleted.status == 200) {
        setLoadDelete(false);

        setTimeout(() => {
          setOpenSuccess(false);
          setDeleteSuccess(!deleteSuccess);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getAllAuthors(userId) {
    try {
      setLoadAuthors(true);
      const { data: { authors } } = await getTopAuthors(userId);
      setData(authors);
      setLoadAuthors(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllSavedBlogs(userId) {
    try {
      const response = await getSavedBlogs(userId);
      setSavedBlogs(response.data.savedBlogs);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllBlogsFiltered(categories, order) {

    try {
      if (!order) {
        setLoad(true);
        const { data: { blogs } } = await getBlogsFiltered(categories);
        setBlogs(blogs);
        setLoad(false);
      } else {
        setLoad(true);
        const { data: { blogs } } = await getBlogsFiltered(categories);
        let aux = blogs
        let orderedBlogs = orderingBlogs(aux)
        setBlogs(orderedBlogs)
        setLoad(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function compare(a, b) {
    console.log("comparing", a.numlikes.likes, b.numlikes.likes);
    if (a.numlikes.likes > b.numlikes.likes) {
      return -1;
    }
    if (a.numlikes.likes < b.numlikes.likes) {
      return 1;
    }
    return 0;
  }

  async function OrderBlogs(orderP, orderD, userId) {
    try {
      setLoad(true);
      const response = await getOrderedBlogs(orderP, orderD, userId);
      console.log("así se ordenan", response)
      let newArr = [...response.data.blogs]
      const ids = newArr.map(obj => obj.blog_id)
      let filtered = newArr.filter(({ blog_id }, index) => !ids.includes(blog_id, index + 1)).sort(compare)
      setBlogs(filtered);
      setLoad(false);
    } catch (error) {
      console.log(error)
    }
  }

  // function selectOrder(type) {
  //   switch (type) {
  //     case "az":
  //       getAllBlogsOrder(userId, type)
  //       break;
  //     case "za":
  //       getAllBlogsOrder(userId, type)
  //       break;
  //     case "rec":
  //       getAllBlogsOrder(userId, type)
  //       break;
  //     case "ant":
  //       getAllBlogsOrder(userId, type)
  //       break;
  //     case "pop":
  //       OrderBlogs(1,0,userId)
  //       break;
  //   }
  // }

  function getSearch(text) {
    let inSearch = allBlogsForSearch.filter((blog) => {
      let regex = new RegExp(text, "i");
      return regex.test(blog.title);
      //Se cambió de includes a un regex para poder idenficar entre mayúsculas y minúsculas cuando se hace una búsqueda.
      // return blog.title.includes(text)
    });
    return inSearch;
  }

  async function handleSearch(e) {
    if (e.target.value !== "") {
      let text = e.target.value;
      const inSearch = await getSearch(text);
      setBlogs(inSearch);
      setLoad(false);
    } else {
      setBlogs(allBlogsForSearch);
      setLoad(false);
    }
  }

  const handleKeyDown = () => {
    setLoad(true);
  };

  useEffect(() => {
    localStorage.setItem('viewTypeBlogs', JSON.stringify(viewType));
  }, [viewType])
  return (
    <Grid container justify="center" spacing={0} className="">
      <Header
        className=""
        isCollapsed={e.isCollapsed}
        onChange={(e) => handleSearch(e)}
        text="Buscar blogs"
        onKeyDown={handleKeyDown}
      />
      <Grid
        item
        xs={12}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow px-2 br-2 "
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 px-md-5 px-3 box-shadow-lg-all ">
          <div className="d-flex w-100 align-items-center">
            <div className={` w-100  ${iPadProMatch ? "mt-0  " : "  "} `}>
              {" "}
              <p
                className={`mt-4 ${ipadMiniMatch ? `h3  ${iPadProMatch ? "mt-0" : "mt-6"}` : ""
                  }  a-text-medium-second-neutral-70  `}
              >
                Blog
              </p>{" "}
            </div>
            {iPadProMatch ? (
              filter || order ? (
                ""
              ) : (
                <Link to="/cid/carso/blogs/create" className="d-none d-lg-block">
                  <Button
                    type="button"
                    classes="a-button-blue-1 text-small roboto-regular"
                    width="150px"
                    height="33px"
                    text="Escribir Entrada"
                  />
                </Link>
              )
            ) : (
              <div />
            )}
          </div>
          <div
            className={`${filter || order
                ? "d-none"
                : "w-100 d-flex justify-content-end mt-1 mb-1"
              } `}
          >
            <div
              onClick={() => setOrder(!order)}
              className=" pr-3 d-flex align-items-center filter-button cursor-pointer"
            >
              <IconButton className="IconOrder pl-0">
                <img src={OrderIconOcean} alt="Order" width="20px" />
              </IconButton>
              <p className="text-small a-text-regular-first-primary-60 filter-button__text">
                Ordenar
              </p>
            </div>
            <div
              onClick={() => setFilter(!filter)}
              className=" d-flex align-items-center filter-button cursor-pointer  "
            >
              <IconButton>
                <Badge badgeContent={filters.length} color="primary">
                  <img src={FilterIconOcean} alt="Filter" width="20px" />
                </Badge>
              </IconButton>
              <p className="text-small a-text-regular-first-primary-60 filter-button__text">
                Filtrar
              </p>
            </div>
          </div>
          {/*filtrar*/}
          <div className={`${filter ? "d-block" : "d-none"}`}>
            
            <BlogFilter
              onClick={() => setFilter(!filter)}
              filters={filters}
              setFilters={setFilters}
            />
          </div>

          {/*ordenar*/}
          <div className={`${order ? "d-block" : "d-none"}`}>
            <Order
              onClick={() => setOrder(!order)}
              orderType={orderType}
              setOrderType={setOrderType}
            />
          </div>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={`${!filter && !order ? "d-block" : "d-none"}`}
          >
            <MenuTabUser
              optone={isActiveList}
              opttwo={isActiveList}
              optthird={isActiveActivi}
              setOptTwo={setIsActiveList}
              setOptThird={setIsActiveActivi}
              setViewType={setViewType}
              viewType={viewType}
            />

            {isActiveList ? (
              <>
                <div className="  d-flex scroll-overflow py-2 ">
                  {loadAuthors ? (
                    <SkeletonAuthor num={3} />
                  ) : (
                    <>
                      {data.map((res, idx) => (
                        <div key={idx} className="mr-3">
                          <CardAuthor key={idx} info={res} />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {viewType ? (
                  <div className="mt-2">
                    {load ? (
                      <SkeletonBlog num={3} />
                    ) : (
                      <>
                        {" "}
                        {blogs?.map((res, idx) => (
                          <div key={idx} className="mt-3">
                            
                            <CardBlog
                              key={idx}
                              info={res}
                              userId={userId}
                              type="normal"
                              deleteObject={deleteObject}
                              setOpenWarning={setOpenWarning}
                              openWarning={openWarning}
                              setBlog={setBlog}
                              saved={
                                !!savedBlogs &&
                                !!savedBlogs.find((el) => {
                                  return el.blog_id == res.blog_id;
                                })
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ) : (
                  <div className=" d-flex flex-wrap mt-2">
                    {load ? (
                      <SkeletonBlog num={3} />
                    ) : (
                      <>
                        {" "}
                        {blogs.map((res, idx) => (
                          <div key={idx} className="mt-3">
                            <CardBlogVertical
                              key={idx}
                              info={res}
                              userId={userId}
                              type="normal"
                              deleteObject={deleteObject}
                              setOpenWarning={setOpenWarning}
                              openWarning={openWarning}
                              setBlog={setBlog}
                              saved={
                                !!savedBlogs &&
                                !!savedBlogs.find((el) => {
                                  return el.blog_id == res.blog_id;
                                })
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <MenuUser viewType={viewType} />
            )}
          </Grid>
        </div>
      </Grid>

      {/* Modales */}

      <Modal
        open={openSuccess}

        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${loadDelete ? "Espera un momento..." : "Tu blog se ha eliminado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
      <Modal
        open={openWarning}

        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <WarningModal
            message={"Tu blog será eliminado"}
            open={openWarning}
            setOpen={setOpenWarning}
            setOpenSuccess={setOpenSuccess}
            openSuccess={openSuccess}
            deleteObject={deleteObject}
            object={blog}
          />
        </div>
      </Modal>
    </Grid>
  );
}
