import React, { useState, useEffect } from "react";
import { updateUserApi } from "../../../api/admin";
import useAuth from "../../../hooks/useAuth";

import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";

import { Button } from "../../atoms/Button/";
import { Link, useHistory } from "react-router-dom";
import { emailValidation } from "../../../utils/emailValidation";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";
import FailIcon from "../../../assets/img/fail-icon.svg";
import Header from "../../molecules/Header";
import Close from "../../../assets/img/icon/close.svg";

import { getUserApi } from "../../../api/admin";

//Material
import { Grid, CircularProgress, Modal, IconButton } from "@material-ui/core";

function FormUpdateUser({ userId, isCollapsed }) {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [warningEmail, setWarningEmail] = useState("");
  const [warningName, setWarningName] = useState("");
  const [warningLastName, setWarningLastname] = useState("");
  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const history = useHistory();
  const [user, setUser] = useState({});
  const [id, setId] = useState(0);
  const [rolUser, setRolUser] = useState(0);

  const [warningRol, setWarningRol] = useState("");
  
  // Peticiones
  useEffect(() => {
    // Cambio 1
    // res.then((result) => {
    //   if (result.status === 200) {
    //     console.log(result)
    //     setRolUser(result.data.user.rol_id)
    //     setUser(result.data.user);
    //     setId(result.data.user.user_id);
    //   }
    // });
    const obtenerUserById = async () => {
      try {
        const { data : { user } }= await getUserApi(userId);
        setRolUser(user.rol_id)
        setUser(user);
        setId(user.user_id);
      } catch (error) {
        console.log(error)
      }
    }
    obtenerUserById()
  }, []);


  // useEffect(() => {
  //   let id = document.getElementById("update-user-type");
  //   console.log(id.value);
  //   setRolUser(id.value);
  // }, [rolUser])

  let rolId = document.getElementById("update-user-type");

  const opciones = {
    1: "Admin",
    2: "CID",
    3: "Carso"
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    setUser(e.target.value);
  };

  const handleBack = () => {
    window.location.href = "/cid/admin/users";
  };

  function submitUpdateForm(e) {
    e.preventDefault();

    const rol = document.getElementById("update-user-type");
    const userId = id;
    const name = document.getElementById("update-user-name").value;
    const lastname = document.getElementById("update-user-lastname").value;
    const email = document.getElementById("update-user-email").value;
    const emailInput = document.getElementById("update-user-email");
    const nameInput = document.getElementById("update-user-name");
    const lastNameInput = document.getElementById("update-user-lastname");
    const isEmailValid = emailValidation(email);

    // if(!rolUser){
    //   setWarningRol(<p className="mt-2 a-text-regular-alert-60">Ingresa un rol</p>)
    //   rol.classList.add("input-error");

    // }else{
    //   setWarningRol("");
    //   rol.classList.remove("input-error");
    // }


    if (!isEmailValid) {
      setWarningEmail(
        <p className="mt-2 a-text-regular-alert-60">Ingresa un email válido</p>
      );
      emailInput.classList.add("input-error");
    } else {
      setWarningEmail("");
      emailInput.classList.remove("input-error");
    }

    if (!name) {
      setWarningName(
        <p className="mt-2 a-text-regular-alert-60">Ingresa un nombre</p>
      );
      nameInput.classList.add("input-error");
    } else {
      setWarningName("");
      nameInput.classList.remove("input-error");
    }

    if (!lastname) {
      setWarningLastname(
        <p className="mt-2 a-text-regular-alert-60">Ingresa un apellido</p>
      );
      lastNameInput.classList.add("input-error");
    } else {
      lastNameInput.classList.remove("input-error");
      setWarningLastname("");
    }

    // let rolId;
    // for(const property in opciones){
    //   if(opciones[property] === rol.innerText){
    //     rolId = property;
    //   };
    // }

    for(const [key, value] of Object.entries(opciones)){
      if(value === rol.innerText){
        rolId = key;
      }
    }
    
    if (isEmailValid && name && lastname) {
      setIsLoading(true);
      const updateUser = {
        userId,
        rolId,
        name,
        lastname,
        email,
      };
      const response = updateUserApi(updateUser);
      response
        .then((result) => {
          if (result.status === 200) {
            // history.push("/admin/users/2", { "type": 2, "value": true });
            // history.push("/admin/users", { "type": 2, "value": true });
            history.push({
              pathname: "/cid/admin/users",
              state: { openSuccess: true },
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          switch (err.response.status) {
            case 400:
              setOpen(true);
              setModalBody(
                <>
                  <div className="d-flex justify-content-center">
                    <img
                      src={FailIcon}
                      alt="Fail Icon"
                      className="mb-3 modal__icon-modal"
                    />
                  </div>
                  <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                    No se ha podido editar el usuario
                    <br /> Inténtelo de nuevo
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/cid/admin/users" className="modal__link-button">
                      <Button
                        type="button"
                        classes="a-button-primary1"
                        height={36}
                        width="100%"
                        text="Aceptar"
                      />
                    </Link>
                  </div>
                </>
              );
              break;

            default:
              setModalBody(
                <>
                  <div className="d-flex justify-content-center">
                    <img
                      src={FailIcon}
                      alt="Fail Icon"
                      className="mb-3 modal__icon-modal"
                    />
                  </div>
                  <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                    No se ha podido editar el usuario
                    <br /> Inténtelo de nuevo
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/cid/admin/users" className="modal__link-button">
                      <Button
                        type="button"
                        classes="a-button-primary1"
                        height={36}
                        width="100%"
                        text="Aceptar"
                      />
                    </Link>
                  </div>
                </>
              );
              break;
          }
        });
    }
  }

  const [dataUsers, setDataUsers] = useState([
    {
      name: "CID",
      rolId: 2
    },
    {
      name: "Carso",
      rolId: 3
    }
  ]);

  return (
    <Grid container alignItems="center" spacing={0}>
      <Header
        hideMessageAndNoti={true}
        text="Buscar usuarios"
        isCollapsed={isCollapsed}
      />
      <Grid item xs={12} alignItems="center">
        <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
          <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
            <div className="d-flex justify-content-between">
              <p className="ml-3 h3-lg text-big a-text-bold-second-neutral-70 align-self-center">
                Formulario de usuarios
              </p>
              <IconButton>
                <img
                  src={Close}
                  alt="Close"
                  className="nav-company-icon "
                  onClick={handleBack}
                />
              </IconButton>
            </div>
            <form onSubmitCapture={submitUpdateForm} className="ml-3 w-lg-60 w-90">
              <p className="a-text-regular-first-neutral-100 text-small mb-3">
                * Campos obligatorios
              </p>
              <div className="mb-3">
                <InputWithLabel
                  classLabel="mb-2"
                  text="Nombre *"
                  id="update-user-name"
                  placeholder="Nombre"
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  value={user.name}
                />
                {warningName}
              </div>
              <div className="mb-3">
                <InputWithLabel
                  classLabel="mb-2"
                  text="Apellidos *"
                  id="update-user-lastname"
                  placeholder="Apellidos"
                  type="text"
                  name="lastname"
                  onChange={handleInputChange}
                  value={user.lastname}
                />
                {warningLastName}
              </div>
              <div className="mb-3">
                <InputWithLabel
                  classLabel="mb-2"
                  text="Rol *"
                  id="update-user-type"
                  placeholder="Selecciona un rol"
                  // focus={true}
                  data={dataUsers}
                  // onChange={handleOnChangeRol}
                  type="select"
                  value={
                    opciones[rolUser]
                  }
                />
                {warningRol}
              </div>
              <div className="mb-4">
                <InputWithLabel
                  classLabel="mb-2"
                  text="Correo electrónico *"
                  id="update-user-email"
                  placeholder="ejemplo@globalhits.com"
                  type="text"
                  name="email"
                  onChange={handleInputChange}
                  value={user.email}
                />
                {warningEmail}
              </div>

              <Button
                type="submit"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text={isLoading ? <CircularProgress /> : "Editar usuario"}
              />

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className="modal">{modalBody}</div>
              </Modal>
            </form>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export { FormUpdateUser };
