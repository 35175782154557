// React
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// Material
import { Grid, IconButton, Modal } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Images
import ImageDefaultIcon from "../../../../assets/img/image-default.svg";
import Arrow from "../../../../assets/img/icon/arrow.svg";
import Close from "../../../../assets/img/icon/close.svg";
import Ingreso from "../../../../assets/img/icon/ingreso.svg";
import Inversionista from "../../../../assets/img/company/inversionista.svg";
import emptyStateMinuta from "../../../../assets/img/empty-state-minuta.svg";
import Linkedin from "../../../../assets/img/linkedin.svg";
import Twitter from "../../../../assets/img/twitter.svg";
import Youtube from "../../../../assets/img/youtube.svg";
import Instagram from "../../../../assets/img/instagram.svg";
import Facebook from "../../../../assets/img/facebook.svg";
import AlertIcon from "../../../../assets/img/alert.svg";
import SuccessIcon from "../../../../assets/img/sucess-icon.svg";
import PDF from "../../../../assets/img/pdf-icon-ocean.svg";
import Trash from "../../../../assets/img/trash-icon-ocean.svg";
import Pencil from "../../../../assets/img/pencil-icon-ocean.svg";
import SitioWeb from "../../../../assets/img/company/sitio-web.svg";
import Sede from "../../../../assets/img/company/sede.svg";
import Tema from "../../../../assets/img/company/tema.svg";
import Redes from "../../../../assets/img/company/redes.svg";
import Fondeo from "../../../../assets/img/company/fondeo.svg";
import Valuacion from "../../../../assets/img/company/valuacion.svg";
import Fundador from "../../../../assets/img/company/fundador.svg";
import Activa from "../../../../assets/img/company/activa.svg";
import Inactiva from "../../../../assets/img/company/inactiva.svg";
import Industrias from "../../../../assets/img/company/Industrias.svg";
import Tecnologias from "../../../../assets/img/company/tecnologias.svg";
import Mercados from "../../../../assets/img/company/Mercados.svg";
import IgInactivo from "../../../../assets/img/social/iggris.svg";
import FbInactivo from "../../../../assets/img/social/fbgris.svg";
import LinkedinInactivo from "../../../../assets/img/social/linkedingris.svg";
import TwitterInactivo from "../../../../assets/img/social/twittergris.svg";
import YtInactivo from "../../../../assets/img/social/ytgris.svg";
import Linkedin2 from "../../../../assets/img/linkedin2.svg";
import Mail from "../../../../assets/img/mail.svg";
import MailOff from "../../../../assets/img/mailoff.svg";
import LinkedinOff from "../../../../assets/img/linkedinoff.svg";
import NoFounder from "../../../../assets/img/company/nofounder.svg";

import CalendarIcon from "../../../../assets/img/calendar-icon.svg";
import ClockIcon from "../../../../assets/img/clock-icon.svg";

// Components
import ReactMarkdown from "react-markdown";
import CardMinuta from "../../../../components/molecules/Card/CardMinuta";
import Header from "../../../../components/molecules/Header";
import FormMinuta from "../../../../components/organisms/Form/FormMinuta";
import { Button } from "../../../../components/atoms/Button";

// Api
import {
  deleteMinutaApi,
  getCompany,
  getFounders,
  getMinuta,
} from "../../../../api/company";
import { PDF_PATH } from "../../../../api/config";

// Utils
import { CompanyDelete } from "../../../../utils/CompanyDelete";
import useAuth from "../../../../hooks/useAuth";

import styles from "./styles.module.css";

// env
require("dotenv").config();

export default function CompanyDetail(e) {
  const user = useAuth();

  // State
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [overview, setOverview] = useState(true);
  const [finanzas, setFinanzas] = useState(false);
  const [fundadores, setFundadores] = useState(false);
  const [foundersInfo, setFoundersInfo] = useState([]);
  const [minuta, setMinuta] = useState(false);
  const [form, setForm] = useState(false);
  const [company, setCompany] = useState({});
  const [minutas, setMinutas] = useState([]);
  const [id, setId] = useState();
  const [modalBody, setModalBody] = useState("");
  const [updateId, setUpdateId] = useState(0);

  const navigate = useHistory();

  // Variables
  const userId = e.userId;
  const companyId = e.match.params.id;
  const state = e.location.state;
  let fechaCreacion = "";
  let fechaActualizacion = "";

  const foundersElements = foundersInfo.map((founder) => {
    return (
      <>
        <div className="pb-3 a-text-medium-black text-normal d-flex align-items-center ml-5  pl-md-4 mb-3">
          <div className="border-first-primary-60 br-2 pl-4 pr-4 pb-6 pt-6 founder-info-card">
            <div className="d-flex">
              <img
                src={founder.url_photo ? founder.url_photo : NoFounder}
                alt="Fundadores"
                className="mr-md-3 mr-2 company-card-profile-icon pr-4"
              />
              <p>{founder.name_founder}</p>
              <p className="p-1 text-xs a-text-medium-first-primary-75-italic">
                {founder.position}
              </p>
              <a href={`mailto:${founder.email}`}>
                <img
                  src={founder.email !== "" ? Mail : MailOff}
                  alt="Fundadores"
                  className="company-card-icon p-1"
                />
              </a>
              <a
                href={`${founder.url_linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={founder.url_linkedin !== "" ? Linkedin2 : LinkedinOff}
                  alt="Fundadores"
                  className="company-card-icon p-1"
                />
              </a>
            </div>
            <div className="align-flex-end d-flex text-right-end">
              <p className="a-text-regular-second-neutral-60 pt-2 line-height-24 text-justified">
                {founder.descripcion_founders}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  });

  // Peticiones
  useEffect(() => {
    if (!isNaN(companyId)) {
      getMinuta(userId, companyId)
        .then((result) => setMinutas(result.data.minuta))
        .catch((err) => console.log(err));
      getCompany(userId, companyId)
        .then((res) => {
          setCompany(res.data.company);
          setTimeout(() => {
            setIsLoading(true);
          }, 500);
        })
        .catch((err) => {
          console.error(err);
          window.location.href = "/404";
        });

      getFounders(companyId)
        .then((response) => {
          setFoundersInfo(response.data.founders);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      window.location.href = "/404";
    }
  }, []);

  const nuevos = {};
  const nuevas = {};
  const [nuevosSectores, setNuevosSectores] = useState([]);
  const [nuevasTecnologias, setNuevasTecnologias] = useState([]);
  let nuevos_sectores = [];
  let nuevas_tecnologias = [];

  useEffect(() => {
    if (company && nuevosSectores.length === 0) {
      company.sectors &&
        company.sectors.forEach((sector) => {
          if (nuevos[sector.sector_id]) {
            nuevos[sector.sector_id].industry.push(sector.industry);
            return;
          }

          nuevos[sector.sector_id] = {
            ...sector,
            industry: [sector.industry],
          };
        });
      for (const [key, sector] of Object.entries(nuevos)) {
        nuevos_sectores.push(sector);
      }
      setNuevosSectores(nuevos_sectores);
    }

    if (company && nuevasTecnologias.length === 0) {
      company.markets &&
        company.markets.forEach((market) => {
          if (nuevas[market.market_id]) {
            nuevas[market.market_id].subtech.push(market.subtech);
            return;
          }

          nuevas[market.market_id] = {
            ...market,
            subtech: [market.subtech],
          };
        });
      for (const [key, market] of Object.entries(nuevas)) {
        nuevas_tecnologias.push(market);
      }
      setNuevasTecnologias(nuevas_tecnologias);
    }
  }, [company.sectors, company.markets]);

  // Funciones

  function handleLocation() {
    if (state !== undefined) {
      switch (state.location) {
        case 1:
          return "/cid/company";
        case 2:
          return "/cid/search-company-alternative";
        case 3:
          return "/cid/search-by-sede";
        case 4:
          return "/cid/search-by-sector";
        case 5:
          return "/cid/search-by-alliance";
        case 6:
          return "/cid/search-by-market";
        default:
          break;
      }
    } else {
      return "/";
    }
  }

  function handleBack() {
    navigate.push(handleLocation(), state.lastState);
  }

  function handleMenu(id) {
    setOverview(false);
    setFinanzas(false);
    setFundadores(false);
    setMinuta(false);
    setForm(false);
    setUpdateId(0);
    switch (id) {
      case 1:
        setOverview(true);
        break;
      case 2:
        setFinanzas(true);
        break;
      case 3:
        setFundadores(true);
        break;
      case 4:
        setMinuta(true);
        break;
      default:
        break;
    }
  }

  function handleMinuta() {
    return minutas.map((r, i) => (
      <>
        <div id={`minuta--1`}></div>
        <div id={`minuta-${i}`}>
          <CardMinuta
            data={r}
            isCollapse={i == id ? true : false}
            collapsed={() => handleCollapsed(i)}
            id={i}
            updateMinuta={handleUpdate}
            deleteMinuta={handleDelete}
          />
        </div>
      </>
    ));
  }

  function handleCollapsed(i) {
    if (i == id) {
      setId(-1);
    } else {
      setId(i);
    }
  }

  function handleForm() {
    setIsLoading(false);
    getMinuta(userId, companyId)
      .then((result) => {
        setMinutas(result.data.minuta);
        setIsLoading(true);
      })
      .catch((err) => console.log(err));
    setForm(false);
  }

  function handleUpdate(minutaId) {
    setUpdateId(minutaId);
    setForm(true);
  }

  function handleDelete(minutaId) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className="d-flex justify-content-center mb-4">
          <img src={AlertIcon} alt="Warning" />
        </div>
        <p className="mb-4 a-text-medium-second-neutral-90 text-center">
          No se podrán deshacer los cambios.
          <br />
          ¿Seguro que deseas eliminar la minuta?
        </p>
        <div className="d-flex justify-content-center">
          <div className="mr-3 w-100" onClick={() => setIsOpen(false)}>
            <Button
              type="button"
              classes="a-button-primary1 text-small"
              height={36}
              width="100%"
              text="Cancelar"
            />
          </div>
          <div onClick={() => deleteMinuta(minutaId)} className="w-100">
            <Button
              type="button"
              classes="a-button-light text-small"
              height={36}
              width="100%"
              text="Aceptar"
            />
          </div>
        </div>
      </>,
    );
  }

  function deleteMinuta(minutaId) {
    deleteMinutaApi(userId, minutaId)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setModalBody(
            <>
              <div className="d-flex justify-content-center mb-4">
                <img src={SuccessIcon} alt="Success Icon" />
              </div>
              <p className="mb-4 a-text-medium-second-neutral-90 text-center">
                Se ha eliminado la minuta correctamente
              </p>
              <div className="m-auto w-50" onClick={() => setIsOpen(false)}>
                <Button
                  type="button"
                  classes="a-button-primary1 text-small"
                  height={36}
                  width="100%"
                  text="Aceptar"
                />
              </div>
            </>,
          );
          getMinuta(userId, companyId)
            .then((result) => {
              if (result.status == 200) {
                setMinutas(result.data.minuta);
              }
              setIsLoading(true);
            })
            .catch((err) => {
              setIsLoading(true);
              setMinutas([]);
              console.error(err);
            });
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err);
      });
  }

  function handleClose() {
    setUpdateId(0);
    setForm(false);
  }

  function formatearFechas(fecha) {
    const newFecha = new Date(fecha);

    const opcionesFormateo = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const fechaFormateada = newFecha.toLocaleString("ex-MX", opcionesFormateo);
    return fechaFormateada;
  }

  fechaCreacion = formatearFechas(company.created_at);
  fechaActualizacion = formatearFechas(company.updated_at);

  return (
    <Grid container justify="center" spacing={0}>
      <Header
        isCollapsed={e.isCollapsed}
        link="/cid/search-company"
        text="Buscar empresa"
        className="pb-4 pb-lg-0"
      />
      <Grid
        item
        xs={12}
        className="scroll-lg-overflow h-100vh-header background-lg-first-neutral-10 h-100vh br-2"
      >
        <div className="m-lg-4 p-lg-4 background-white br-2">
          {isLoading ? (
            <Grid container justify="center" spacing={0}>
              <Grid item xs={10} lg={12}>
                <Grid item xs={12}>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="input-file d-flex border-first-neutral-30">
                      <img
                        onError={(e) => (e.target.src = `${ImageDefaultIcon}`)}
                        src={
                          process.env.NODE_ENV == "development"
                            ? company.logo_link
                            : `https://images.weserv.nl/?url=${company.logo_link}`
                        }
                        className="img-file m-auto"
                      />
                    </p>
                    <div>
                      <div className="d-flex">
                        <Link
                          to={
                            state !== undefined
                              ? {
                                  pathname: `/cid`,
                                  state: {
                                    array: state.array,
                                    index: state.index - 1,
                                    location: state.location,
                                  },
                                }
                              : ""
                          }
                          className={
                            state !== undefined
                              ? `${state.index === 0 ? "disabled" : ""}`
                              : "disabled"
                          }
                        >
                          <IconButton>
                            <img
                              src={Arrow}
                              alt="before"
                              className="image-left nav-company-icon"
                            />
                          </IconButton>
                        </Link>
                        <Link
                          to={
                            state !== undefined
                              ? {
                                  pathname: `/cid`,
                                  state: {
                                    array: state.array,
                                    index: state.index + 1,
                                    location: state.location,
                                  },
                                }
                              : ""
                          }
                          className={`mx-4 mx-xl-5 ${state !== undefined ? `${state.index === state.array.length - 1 ? "disabled" : ""}` : "disabled"}`}
                        >
                          <IconButton>
                            <img
                              src={Arrow}
                              alt="leter"
                              className="image-right nav-company-icon"
                            />
                          </IconButton>
                        </Link>
                        {/* <Link to={handleLocation}> */}
                        <div onClick={() => handleBack()}>
                          <IconButton>
                            <img
                              src={Close}
                              alt="Close"
                              className="nav-company-icon"
                            />
                          </IconButton>
                        </div>
                        {/* </Link> */}
                      </div>
                      <div className="d-none d-lg-flex justify-content-end mt-3">
                        {user.rolId === 3 ? (
                          ""
                        ) : (
                          <div>
                            <Link
                              to={`/cid/update-company/${company.company_id}`}
                              className="text-decoration-none"
                            >
                              <IconButton>
                                <img
                                  src={Pencil}
                                  alt="Pencil Icon"
                                  width="24px"
                                />
                              </IconButton>
                            </Link>
                            <CompanyDelete
                              image={Trash}
                              iconButton={true}
                              companyId={company.company_id}
                              userId={userId}
                              width="24px"
                            />
                          </div>
                        )}
                        <IconButton>
                          <a
                            href={`${PDF_PATH}/get-one-page?companyId=${company.company_id}`}
                            rel="noreferrer"
                            target="_blank"
                            className="d-flex"
                          >
                            <img src={PDF} alt="PDF" width="24px" />
                          </a>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-space-between">
                    {company.activo === "1" ? (
                      <img src={Activa} alt="Active Icon" />
                    ) : (
                      <img src={Inactiva} alt="Inactive Icon" />
                    )}
                    <p className="a-text-medium text-big h3-md my-3 ml-2">
                      {company.name.replace("&amp;", "&")}
                    </p>
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} md={10} lg={12}>
                <Grid item xs={12}>
                  <div className="hr-nav"></div>
                  <Grid container justify="center" spacing={0}>
                    <Grid
                      xs={10}
                      md={12}
                      className="d-flex justify-content-between list-style-none a-text-regular-second-neutral-60"
                    >
                      <li
                        className={`cursor-pointer text-small text-md-normal px-md-4 py-3 ${overview ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50" : ""}`}
                        onClick={() => handleMenu(1)}
                      >
                        Overview
                      </li>
                      <li
                        className={`cursor-pointer text-small text-md-normal px-md-4 py-3 ${finanzas ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50" : ""}`}
                        onClick={() => handleMenu(2)}
                      >
                        Finanzas
                      </li>
                      <li
                        className={`cursor-pointer text-small text-md-normal px-md-4 py-3 ${fundadores ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50" : ""}`}
                        onClick={() => handleMenu(3)}
                      >
                        Fundadores
                      </li>
                      {user.rolId === 3 ? (
                        ""
                      ) : (
                        <li
                          className={`cursor-pointer text-small text-md-normal px-md-4 py-3 ${minuta ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50" : ""}`}
                          onClick={() => handleMenu(4)}
                        >
                          Minuta
                        </li>
                      )}
                    </Grid>
                  </Grid>
                  <div className="hr-nav"></div>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={10}
                lg={12}
                className="padding-menu text-normal m-0"
              >
                <Grid container xs={12} md={12} lg={12} xl={12} className="m-0">
                  {overview ? (
                    <>
                      <p className="a-text-regular-second-neutral-60 margin-text text-small text-md-normal">
                        {company.description.replace("&amp;", "&")}
                      </p>
                      <div className={styles.hashtags}>
                        {company.hashtags.length > 0 &&
                          company.hashtags.map((hashtag) => (
                            <span>#{hashtag}</span>
                          ))}
                      </div>
                      <Grid
                        container
                        lg={12}
                        md={12}
                        xl={12}
                        style={{ gap: 32 }}
                        className="justify-content-start m-0 "
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          className="box-shadow-prueba "
                        >
                          <div className="ml-4 mt-4 mr-4">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Industrias}
                                alt="Sectores e Industrias"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-first-primary-60 text-small text-md-normal">
                                Sectores e Industrias
                              </p>
                            </div>

                            <div className="ml-3 pl-md-4 mb-3 ml-md-4 text-small text-md-normal">
                              {nuevosSectores.length !== 0 ? (
                                nuevosSectores.map((sector) => (
                                  <div className="ml-2 d-flex flex-wrap text-small text-md-normal">
                                    <span className="a-text-medium-second-neutral-90 align-self-center ml-1 ml-mb-0 mr-1 mb-2">
                                      {sector.sector}:
                                    </span>
                                    {sector.industry.map((industry, i) => (
                                      <div className="d-flex mb-2">
                                        <span className="checked-gray align-self-center py-2 px-3 mx-1">
                                          {industry}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                ))
                              ) : (
                                <span className="a-text-regular-second-neutral-60 py-2 px-2 m-1">
                                  N/D
                                </span>
                              )}
                            </div>
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Tecnologias}
                                alt="Tecnologías"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-first-primary-60 text-small text-md-normal">
                                Tecnologías
                              </p>
                            </div>
                            <div className="ml-3 ml-md-4 pl-md-4 mb-4">
                              {nuevasTecnologias.length !== 0 ? (
                                nuevasTecnologias.map((tec, i) => (
                                  <div className="ml-2 d-flex flex-wrap text-small text-md-normal">
                                    <span className="a-text-medium-second-neutral-90 align-self-center ml-1 ml-mb-0 mr-1 mb-2">
                                      {tec.tech}:
                                    </span>
                                    {tec.subtech.map((e, i) => (
                                      <div className="d-flex mb-2">
                                        <span className="checked-gray align-self-center py-2 px-3 mx-1">
                                          {e}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                ))
                              ) : (
                                <span className="a-text-regular-second-neutral-60 py-2 px-2 m-1">
                                  N/D
                                </span>
                              )}
                              {/* {company.tech && company.tech.length !== 0 ? company.tech.map(res => <span className="py-2 px-3 m-1 checked-gray">{res.name}</span>) : <span className="a-text-regular-second-neutral-60 py-2 px-2 m-1">N/D</span>} */}
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          className="box-shadow-prueba"
                        >
                          <div className="ml-4 mt-4 mr-4">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Mercados}
                                alt="Mercados"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Mercados
                              </p>
                            </div>
                            <div className="ml-3 ml-md-4 pl-md-4 mb-4 text-small text-md-normal">
                              {company.mercados &&
                              company.mercados.length !== 0 ? (
                                company.mercados.map((res) => (
                                  <span className="py-2 px-3 m-1 checked-gray">
                                    {res.name}
                                  </span>
                                ))
                              ) : (
                                <span className="a-text-regular-second-neutral-60 py-2 px-2 m-1">
                                  N/D
                                </span>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          className="box-shadow-prueba"
                        >
                          <div className="ml-4 mt-4 mr-4">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={SitioWeb}
                                alt="Sitio web"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Sitio web
                              </p>
                            </div>
                            <div className="ml-md-4">
                              <div className="break-word mb-3 ml-5 ml-md-5 ml-lg-4 ml-xl-5 ">
                                <a
                                  href={company.website_url}
                                  target="_blank"
                                  className="a-text-regular-second-primary-70 text-small text-md-normal "
                                >
                                  {company.website_url}
                                </a>
                              </div>
                              {company.video_url ? (
                                <button className="ml-5 mb-3 py-2 px-4 button-ver-video-overview ">
                                  <a
                                    href={company.video_url}
                                    target="_blank"
                                    className={`d-inline-block text-decoration-none a-text-medium-first-primary-60 `}
                                  >
                                    Ver vídeo
                                  </a>
                                </button>
                              ) : (
                                <button className="ml-5 mb-3 py-2 px-4 button-ver-video-overview-inactivo disabled">
                                  <a
                                    href={company.video_url}
                                    target="_blank"
                                    className={`d-inline-block text-decoration-none`}
                                  >
                                    Ver vídeo
                                  </a>
                                </button>
                              )}
                            </div>
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Sede}
                                alt="Sede"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Sede
                              </p>
                            </div>
                            <div className="ml-5 pl-md-4 a-text-regular-second-neutral-60 mb-3 text-small text-md-normal">
                              <span>{company.sede}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Inversionista}
                                alt="Tamaño de la empresa"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Tamaño de la empresa
                              </p>
                            </div>
                            <div className="ml-5 pl-md-4 a-text-regular-second-neutral-60 mb-4 text-small text-md-normal">
                              <span>{company.company_size_cad}</span>
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          className="box-shadow-prueba"
                        >
                          <div className="ml-4 mt-4 mr-4">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Tema}
                                alt="Oportunidades"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Oportunidades
                              </p>
                            </div>
                            <div className="ml-4 ml-md-5 pl-md-2 pl-1 a-text-regular-second-neutral-60 mb-4 text-small text-md-normal">
                              {company.opportunities ? (
                                <ReactMarkdown>
                                  {company.opportunities}
                                </ReactMarkdown>
                              ) : (
                                <span>N/D</span>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          className={
                            company.linkedin_url == null &&
                            company.youtube_url == null &&
                            company.twitter_url == null &&
                            company.facebook_url == null &&
                            company.instagram_url == null
                              ? "mt-4 box-shadow-prueba"
                              : "mt-4 box-shadow-prueba"
                          }
                        >
                          <div className="ml-4 mt-4 mr-4">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={Redes}
                                alt="Redes sociales"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Redes sociales
                              </p>
                            </div>
                            <div className="d-flex flex-wrap mt-2 pl-md-2 ml-md-5 ml-4 mb-4 pl-1 text-small text-md-normal">
                              {company.linkedin_url == null ? (
                                <div className="d-flex mr-3 mb-2">
                                  <img
                                    src={LinkedinInactivo}
                                    className="img-social-detalles"
                                    alt="Linkedin Inactivo"
                                  />
                                </div>
                              ) : (
                                <div className="d-flex mr-3 mb-2">
                                  <a
                                    href={company.linkedin_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <img
                                      src={Linkedin}
                                      alt="Linkedin"
                                      className="img-social-detalles "
                                    />
                                  </a>
                                </div>
                              )}
                              {company.youtube_url == null ? (
                                <div className="d-flex mr-3 mb-2">
                                  <img
                                    src={YtInactivo}
                                    className="img-social-detalles"
                                    alt="Youtube Inactivo"
                                  />{" "}
                                </div>
                              ) : (
                                <div className="d-flex mr-3 mb-2">
                                  <a
                                    href={company.youtube_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <img
                                      src={Youtube}
                                      alt="Youtube"
                                      className="img-social-detalles"
                                    />
                                  </a>
                                </div>
                              )}
                              {company.twitter_url == null ? (
                                <div className="d-flex mr-3 mb-2">
                                  <img
                                    src={TwitterInactivo}
                                    className="img-social-detalles"
                                    alt="Twitter Inactivo"
                                  />
                                </div>
                              ) : (
                                <div className="d-flex mr-3 mb-2">
                                  <a
                                    href={company.twitter_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <img
                                      src={Twitter}
                                      alt="Twitter"
                                      className="img-social-detalles"
                                    />
                                  </a>
                                </div>
                              )}
                              {company.facebook_url == null ? (
                                <div className="d-flex mr-3 mb-2">
                                  <img
                                    src={FbInactivo}
                                    className="img-social-detalles"
                                    alt="Facebook Inactivo"
                                  />
                                </div>
                              ) : (
                                <div className="d-flex mr-3 mb-2">
                                  <a
                                    href={company.facebook_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <img
                                      src={Facebook}
                                      alt="Facebook"
                                      className="img-social-detalles"
                                    />
                                  </a>
                                </div>
                              )}
                              {company.instagram_url == null ? (
                                <div className="d-flex mr-3 mb-2">
                                  <img
                                    src={IgInactivo}
                                    className="img-social-detalles"
                                    alt="Instagram Inactivo"
                                  />
                                </div>
                              ) : (
                                <div className="d-flex mr-3 mb-2">
                                  <a
                                    href={company.instagram_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <img
                                      src={Instagram}
                                      alt="Instagram"
                                      className="img-social-detalles"
                                    />
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={5}
                          className="box-shadow-prueba"
                        >
                          <div className="ml-4 mt-4 mr-4">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={CalendarIcon}
                                alt="Oportunidades"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Fecha de Creacion
                              </p>
                            </div>
                            <div className="ml-5 pl-md-4 a-text-regular-second-neutral-60 mb-4 text-small text-md-normal">
                              <span>{fechaCreacion}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                              <img
                                src={ClockIcon}
                                alt="Oportunidades"
                                className="mr-2 mr-md-3 list-company-icon"
                              />
                              <p className="a-text-medium-second-neutral-90 text-small text-md-normal hl-text">
                                Fecha de Actualizacion
                              </p>
                            </div>
                            <div className="ml-5 pl-md-4 a-text-regular-second-neutral-60 mb-4 text-small text-md-normal">
                              <span>{fechaActualizacion}</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {finanzas ? (
                    <div className="box-shadow-prueba p-4">
                      <div className="text-small text-md-normal">
                        <div className="d-flex align-items-center mb-2">
                          <img
                            src={Ingreso}
                            alt="Ingresos"
                            className="mr-2 mr-md-3 list-company-icon"
                          />
                          <p className="a-text-medium-second-neutral-90 hl-text">
                            Ingresos
                          </p>
                        </div>
                        <div className="a-text-regular-second-neutral-60 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center ml-5 pl-md-4 mb-3">
                            <span>Ingresos estimados</span>
                            <div className="ml-3 ml-md-4">
                              {company.entry == "N/D" || !company.entry ? (
                                <span className="a-text-regular-first-primary-60 ">
                                  N/D
                                </span>
                              ) : (
                                <span className="a-text-regular-first-primary-60">
                                  $ {company.entry}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src={Fondeo}
                              alt="Fondeo"
                              className="mr-2 mr-md-3 list-company-icon"
                            />
                            <p className="a-text-medium-second-neutral-90 hl-text">
                              Fondeo
                            </p>
                          </div>
                          <div className="a-text-regular-second-neutral-60 d-flex align-items-center justify-content-between justify-content-md-start">
                            <div className="d-flex align-items-center align-items-center ml-5 pl-md-4 mb-3">
                              <span>Fondos recaudados</span>
                              {/* <span className="ml-3 ml-md-4 a-text-regular-first-primary-60">{`${company.funding == 'N/D' || !company.funding ? 'N/D' : `$ ${company.funding}`}`}</span> */}
                              <div className="ml-3 ml-md-4">
                                {company.funding == "N/D" ||
                                !company.funding ? (
                                  <span>N/D</span>
                                ) : parseFloat(company.funding) > 0 ? (
                                  <span className="a-text-regular-first-primary-60">
                                    $ {company.funding}
                                  </span>
                                ) : (
                                  <span className="a-text-regular-first-primary-60">
                                    {" "}
                                    {company.funding}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src={Valuacion}
                              alt="Valuación"
                              className="mr-2 mr-md-3 list-company-icon"
                            />
                            <p className="a-text-medium-second-neutral-90 hl-text">
                              Valuación
                            </p>
                          </div>
                          <div className="a-text-regular-second-neutral-60 d-flex align-items-center justify-content-between justify-content-md-start">
                            <div className="d-flex align-items-center align-items-center ml-5 pl-md-4 mb-3">
                              <span>Valuación</span>
                              <div className="ml-5 ml-md-6">
                                {company.valuation == "N/D" ||
                                !company.valuation ? (
                                  <span className="a-text-regular-first-primary-60">
                                    N/D
                                  </span>
                                ) : (
                                  <span className="a-text-regular-first-primary-60">
                                    $ {company.valuation}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="d-flex align-items-center mb-3">
                            <img
                              src={Inversionista}
                              alt="Inversionista"
                              className="mr-2 mr-md-3 list-company-icon"
                            />
                            <p className="a-text-medium-second-neutral-90 hl-text">
                              Inversionistas
                            </p>
                          </div>
                          <div className="a-text-regular-second-neutral-60 align-items-center ml-5 pl-md-4 mb-3">
                            {company.investors.length !== 0 ? (
                              company.investors.map((res) => (
                                <div className="d-flex align-items-center mb-3">
                                  <li className="">
                                    {res.name.replace("&amp;", "&")}
                                  </li>
                                </div>
                              ))
                            ) : (
                              <div className="d-flex align-items-center">
                                <li>
                                  <span> N/D</span>
                                </li>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  {fundadores ? (
                    <div className="box-shadow-first-neutral-50 p-4">
                      <div className="text-small text-md-normal">
                        <div className="d-flex align-items-center mb-2">
                          <img
                            src={Fundador}
                            alt="Fundadores"
                            className="mr-md-3 mr-2 list-company-icon"
                          />
                          <p className="a-text-medium-second-neutral-90 hl-text">
                            Fundadores
                          </p>
                        </div>
                        {foundersElements}
                        <div className="">
                          <div className="d-flex align-items-center mb-2">
                            <img
                              src={Inversionista}
                              alt="Alianza"
                              className="mr-md-3 mr-2 list-company-icon"
                            />
                            <p className="a-text-medium-second-neutral-90 hl-text">
                              Alianza y colaboraciones
                            </p>
                          </div>
                          <div className="a-text-regular-second-neutral-60 mb-3 ml-5 pl-md-4">
                            {company.alliances.length !== 0 ? (
                              company.alliances.map((res) => (
                                <div className="d-flex align-items-center mb-3 ">
                                  <li className="">
                                    {res.name.replace("&amp;", "&")}
                                  </li>
                                </div>
                              ))
                            ) : (
                              <div className="d-flex align-items-center">
                                <li>
                                  <span>N/D</span>
                                </li>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {minuta ? (
                    minutas != 0 ? (
                      form ? (
                        <Grid item xs={12} xl={11}>
                          <FormMinuta
                            onClick={handleForm}
                            close={handleClose}
                            userId={userId}
                            companyId={companyId}
                            updateId={updateId}
                          />
                        </Grid>
                      ) : (
                        <>
                          <p className="a-text-medium-second-neutral-90 mb-3 text-md-medium text-lg-normal">
                            {" "}
                            Minuta
                          </p>
                          <div
                            className="d-inline-block"
                            onClick={() => setForm(true)}
                          >
                            <Button
                              type="button"
                              classes="a-button-blue-1 text-small roboto-regular"
                              width="150px"
                              height="40px"
                              text="Agregar minuta"
                            />
                          </div>
                          {handleMinuta()}
                        </>
                      )
                    ) : form ? (
                      <Grid item xs={12} xl={11}>
                        <FormMinuta
                          onClick={handleForm}
                          close={handleClose}
                          userId={userId}
                          companyId={companyId}
                          updateId={updateId}
                        />
                      </Grid>
                    ) : (
                      <>
                        <div className="text-center">
                          <p className="a-text-bold-second-neutral-90 h3-md h1-xl mb-md-3">
                            Aún no tenemos una minuta
                          </p>
                          <span className="a-text-regular-second-neutral-40 h3-md h1-xl d-none d-lg-block">
                            Haz clic{" "}
                            <span
                              className="a-text-regular-second-primary-70 cursor-pointer text-decoration-underline"
                              onClick={() => setForm(true)}
                            >
                              aquí
                            </span>{" "}
                            para agregar una
                          </span>
                          <img
                            src={emptyStateMinuta}
                            className="mt-5 d-block m-auto minuta-empty-state"
                          />
                        </div>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center" spacing={0}>
              <Grid item xs={10} lg={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <Skeleton
                    variant="rect"
                    width={120}
                    height={80}
                    animation="wave"
                    className="br-1"
                  />
                  <div>
                    <div className="d-flex">
                      <Skeleton
                        variant="rect"
                        width={25}
                        height={25}
                        animation="wave"
                        className="br-1 mr-1 mr-md-2"
                      />
                      <Skeleton
                        variant="rect"
                        width={25}
                        height={25}
                        animation="wave"
                        className="br-1 mx-4 mx-md-5"
                      />
                      <Skeleton
                        variant="rect"
                        width={25}
                        height={25}
                        animation="wave"
                        className="br-1 mx-2 mx-md-2"
                      />
                    </div>
                    <div className="d-lg-flex mt-4 d-none justify-content-end">
                      <Skeleton
                        variant="rect"
                        width={25}
                        height={25}
                        animation="wave"
                        className="br-1 mr-2"
                      />
                      <Skeleton
                        variant="rect"
                        width={25}
                        height={25}
                        animation="wave"
                        className="br-1 mx-2"
                      />
                      <Skeleton
                        variant="rect"
                        width={25}
                        height={25}
                        animation="wave"
                        className="br-1 mx-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Skeleton
                    variant="rect"
                    width={20}
                    height={20}
                    animation="wave"
                    className="my-3 br-3"
                  />
                  <Skeleton
                    variant="h3"
                    width="50%"
                    animation="wave"
                    className="my-3 ml-2"
                  />
                </div>

                <div className="d-flex justify-content-between py-4">
                  <Skeleton variant="h3" width="20%" animation="wave" />
                  <Skeleton variant="h3" width="20%" animation="wave" />
                  <Skeleton variant="h3" width="20%" animation="wave" />
                  <Skeleton variant="h3" width="20%" animation="wave" />
                </div>
              </Grid>

              <Grid item xs={10} lg={12}>
                <Skeleton
                  variant="h3"
                  width="80%"
                  animation="wave"
                  className="margin-text-skeleton"
                />
              </Grid>

              {/* Aqui empiezan las cards */}

              <Grid container xs={10} lg={12} style={{ gap: 32 }}>
                <Grid item xs={12} lg={5} className="card-overview mb-3">
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={5} className="card-overview mb-3">
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={5} className="card-overview mb-3">
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className="mb-1"
                    />
                    <Skeleton
                      variant="h3"
                      width="25%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={5} className="card-overview mb-3">
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex flex-column ml-5 mb-3">
                    <Skeleton
                      variant="h3"
                      width="70%"
                      animation="wave"
                      className=""
                    />
                  </div>
                </Grid>
                <Grid item xs={12} lg={5} className="card-overview mb-3">
                  <div className="d-flex ml-4 mt-4 mb-2">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-1 br-3"
                    />
                    <Skeleton
                      variant="h3"
                      width="40%"
                      animation="wave"
                      className=""
                    />
                  </div>
                  <div className="d-flex ml-5 mb-3">
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-3 br-1"
                    />
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-3 br-1"
                    />
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-3 br-1"
                    />
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-3 br-1"
                    />
                    <Skeleton
                      variant="rect"
                      width={20}
                      height={20}
                      animation="wave"
                      className="mr-3 br-1"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">{modalBody}</div>
      </Modal>
    </Grid>
  );
}
