import React, { useState, useEffect } from "react";
import {
  createMuiTheme,
  Popover,
  CircularProgress,
} from "@material-ui/core";

import { Input } from "../../atoms/Input";

import { Button } from "../../atoms/Button";
import Calendar from "../../atoms/Calendar";
import Time from "../../atoms/Time/ModalComponent";

import { useAuth } from "../../../hooks/useAuth";
import { getClients } from "../../../api/report";
import { updateBlof, getLabelsForBlogs } from "../../../api/blogs";

import Close from "../../../assets/img/closeFilter.svg";

const valueDefault = [
  {
    client_id: 34,
    name: "Centro de Inteligencia e Innovación Digital",
    logo_client:
      "https://firebasestorage.googleapis.com/v0/b/images-wikicid.appspot.com/o/reportes%2Fclientes%2FCID_V.png?alt=media&token=933e59ef-bbd6-4f73-83b8-502660f0f195",
  },
];
//published_time

/*

Este componente es identico al que funciona con la creación incial del blog
con ciertos cambios muy notorios
por ejemplo para mantener la imagen predeterminada que tiene el blog
también notarás que este ya no cuenta con fecha y hora yq eu para el caso en el que lo quieras editar
debería ya estar publicado y no es posible hacer esa interacción 



lo que vas a enviar como text literalmente es código html en forma de string 
así que debes de rehusarlo de igual forma 

en el componente de detail hay un ejemplo de como renderizar ese contenido html en stringo en el DOM sin necesidad de usar ningun parser
*/
export default function ConfigBlog({
  setOpen,
  open,
  text,
  filePortada,
  portadaUp,
  title,
  setTitle,
  labelsUp,
  dataUp,
  labelsUpd,
  imageOnBlog,
  isAcceptable,
  isUploaded,
  imageOnBlogUploaded,
  uploadedImages,
  uploadedImagesFiles,
  deletedImages
}) {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });


  const [blogImg, setBlogImg] = useState(filePortada ? true : false);
  const [userImg, setUserImg] = useState(filePortada ? false : true);

  const [date, setDate] = useState("");
  const [dateNew, setDateNew] = useState("");

  const [time, setTime] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [dayTime, setDayTime] = useState("");

  const [portada, setPortada] = useState("");

  const [popper, setPopper] = useState(null);
  const [load, setLoad] = useState(false);
  const [loadPublished, setLoadPublished] = useState(false);
  const [popperTimer, setPopperTimer] = useState(null);
  const [dataClients, setDataClients] = useState(valueDefault);
  const [onLoad, setOnLoad] = useState(true);
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [availableTags, setAvailableTags] = useState();
  const [draft, setDraft] = useState(
    dataUp.work_in_progress == 1 ? true : false
  );

  const [indexOfImageUploaded, setIndexOfImageUploaded] = useState()
  const [srcOfImageUploaded, setSrcOfImageUploaded] = useState()
  const [hasTitle, setHasTitle] = useState(title.length != 0 && title != "<br>")

  // Variables
  const user = useAuth();
  const { userId } = user;
  useEffect(() => {
    getClientsInput();
    const labelsBlog = dataUp.labels.map(label => label.name)
    setTags(labelsBlog)
    getAvailableTags();
  }, []);
  async function getClientsInput() {
    setOnLoad(true);
    try {
      const response = await getClients(userId);
      setDataClients(response.data.clientes);
      setOnLoad(false);
    } catch (error) { }
  }
  async function getAvailableTags() {
    // setOnLoad(true);
    try {
      const { data: { labels } } = await getLabelsForBlogs();
      setAvailableTags(labels);
    } catch (error) {
      console.log(error);
    }
  }
  function onChange(e) {
    console.log("chancge");
    switch (e.target.name) {
      case "imgBlog":
        setUserImg(false);
        setBlogImg(!blogImg);

        break;
      case "imgUser":
        setBlogImg(false);
        setUserImg(!userImg);

        break;

      default:
        break;
    }
  }

  function handleDate(d, m, y) {
    setDate(`${d}/${m}/${y}`);
    setDateNew(`${d}/${m < 10 ? "0" + m : m}/${y}`);
    setPopper(null);
  }

  function handleTime(h, m, z) {
    setTime(`${h}:${m} ${z}`);
    setHours(h);
    setMinutes(m);
    setDayTime(z);
  }

  const onChangeTag = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    console.log(key)
    if (
      key === "," ||
      (key === "Enter" && trimmedInput.length && !tags.includes(trimmedInput))
    ) {
      e.preventDefault();

      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const onMoreTops = (name) => {
    const bandera = tags.some(tag => tag === name);
    if (bandera) {
      const tagsSinCopy = tags.filter(tag => tag !== name);
      setTags(tagsSinCopy);
    } else {
      setTags([...tags, name]);
    }
    //setTags(poppedTag);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const isOpenPopper = Boolean(popper);
  const isOpenPopperTime = Boolean(popperTimer);


  function submitCreateForm(e) {
    e.preventDefault();
    setLoad(true);

    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("blog_id", dataUp.blog_id);

    const indexInicio = text.indexOf("<h2");
    const indexFin = text.indexOf("</h2>");
    const titulo = text.slice(indexInicio, indexFin + 5);
    const descriptionSinTitle = text.replace(titulo, "");
    if (descriptionSinTitle.length > 0) {
      formData.append("description", descriptionSinTitle);
    } else {
      formData.append("description", "");
    }
    const defaultTitle = title === "<br>" || title === "" ? "Sin título" : title;
    formData.append("title", defaultTitle);

    const coverImage =
      userImg && portada !== "" ? portada.logo_client : valueDefault[0].logo_client;
    formData.append("cover_blog", coverImage);

    const uploadedImage =
      userImg && indexOfImageUploaded !== undefined
        ? uploadedImagesFiles[indexOfImageUploaded][0]
        : srcOfImageUploaded || filePortada;
    formData.append("blogimgs", uploadedImage);

    if (draft) {
      formData.append("draw", 1);
      if (tags.length > 0) {
        formData.append("labelsUser", [tags]);
      }
    } else {
      formData.append("draw", 0);
      formData.append("labelsUser", [tags]);
    }
    updateBlof(formData)
      .then((response) => {
        window.location.href = "/cid/carso/blogs/list";
        setLoad(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleOnChange = (e) => {
    setTitle(e.target.value)
    if (e.target.value.length != 0) {
      setHasTitle(true)
    } else {
      setHasTitle(false)
    }
  }

  function onPublish(e) {
    e.preventDefault();
    setLoadPublished(true);

    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("blog_id", dataUp.blog_id);
    formData.append("description", text);

    const defaultTitle = title === "<br>" || title === "" ? "Sin título" : title;
    formData.append("title", defaultTitle);

    if (!userImg && indexOfImageUploaded !== undefined) {
      const uploadedImage =
        indexOfImageUploaded != -1
          ? uploadedImagesFiles[indexOfImageUploaded][0]
          : srcOfImageUploaded;

      formData.append("blogimgs", uploadedImage);
    } else {
      const coverImage = portada === "" ? valueDefault[0].logo_client : portada.logo_client;
      formData.append("cover_blog", coverImage);
    }
    formData.append("draw", 1);
    formData.append("labelsUser", [tags]);
    updateBlof(formData)
      .then((response) => {
        window.location.href = "/cid/carso/blogs/list";
        setLoad(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  return (
    // <form onSubmitCapture={submitCreateForm}>
    <form>
      <div>
        <span className="a-text-medium-first-primary-60 text-big">
          Configurar2
        </span>
        <div className="d-flex w-100 align-content-center mt-4">
          <p className="a-text-regular-first-primary-60 align-self-center text-normal">
            Título*:
          </p>
          <div className="align-content-center w-100 mr-1 ml-5">
            <Input
              placeholder={"Escribe un título"}
              type="text"
              value={title}
              onChange={handleOnChange}
              className="w-100"
            />
          </div>
        </div>

        <div className="d-flex w-100  mt-4">
          <span className="a-text-regular-first-primary-60 text-normal">
            Imagen:
          </span>

          <div className="d-flex align-content-center  ml-5">
            <div className=" d-flex mr-5">
              <input
                onChange={onChange}
                checked={blogImg}
                type="checkbox"
                id="imgBlog"
                className="radioNews cursor-pointer"
                name="imgBlog"
                value="imgBlog"
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                htmlFor="imgBlog"
              >
                Usar imagen del blog
              </label>
            </div>
          </div>
          <div>
            <div className=" d-flex">
              <input
                onChange={onChange}
                checked={userImg}
                type="checkbox"
                id="imgUser"
                className="radioNews cursor-pointer"
                name="imgUser"
                value="imgUser"
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                htmlFor="imgUser"
              >
                Usar imagen predeterminada
              </label>
            </div>
          </div>
        </div>
        <div className={`${!userImg ? "mt-3 ml-5 d-flex" : "d-none"}`}>
          <Input
            data={uploadedImages}
            type="checkboxImgUploadedBlog"
            className="btn-checkbox-img-add-blog"
            id="report-client"
            filteredTopic={indexOfImageUploaded}
            filteredTopics={srcOfImageUploaded}
            setFilteredTopic={setIndexOfImageUploaded}
            setFilteredTopics={setSrcOfImageUploaded}
            deletedImgs={deletedImages}
          />
        </div>
        <div className={`${userImg ? "mt-3 ml-5 d-flex" : "d-none"}`}>
          {onLoad ? (
            <div />
          ) : (
            <Input
              value={valueDefault}
              data={dataClients}
              id="report-client"
              type="checkboxImgBlog"
              className="btn-checkbox-img-add-blog"
              setFilteredTopic={setPortada}
            />
          )}
        </div>
        <div className="d-flex align-items-center w-100 mt-4">
          <span className="a-text-regular-first-primary-60 text-normal mr-4">
            Etiquetas:
          </span>

          <div className="ContainerTag">
            {tags.map((tag, index) => (
              <div className="tag" key={tag}>
                {tag}
                <button type="button" onClick={() => deleteTag(index)}>
                  <img className="tag-icon" src={Close}></img>
                </button>
              </div>
            ))}
            <input
              value={input}
              placeholder={`${tags.length > 0 ? '' : 'Ciencia, Tecnología...'}`}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChangeTag}
            />
          </div>
        </div>
        <div className="d-flex ml-5 mt-3">
          <div className="mr-5"></div>
          <Input
            value={tags}
            data={availableTags}
            setFilteredTopic={onMoreTops}
            className={"filterCheckBlog"}
            type="checkboxTextTopicFilterBlog"
          />
        </div>

        <div className="w-100 d-flex mt-4 justify-content-end">

          <Button
            type={"text"}
            onclick={() => {
              setOpen(!open);
            }}
            classes={`text-normal a-button-topic-gray-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer`}
            text={"Cancelar"}
          />
          {/* 
          {draft ? (
            <Button
              type={"submit"}
              classes={`text-normal a-button-topic-gray-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer`}
              text={loadPublished ? <CircularProgress /> : "Publicar"}
            />
          ) : (
            ""
          )} */}


          {draft ? (
            <Button
              onclick={onPublish}
              classes={`text-normal a-button-topic-gray-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer`}
              text={loadPublished ? <CircularProgress /> : "Publicar"}
            />
          ) : (
            ""
          )}

          {/* <Button
            type={"submit"}
            classes={`text-normal ml-  a-button-topic-blue-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer ${
              load ? "disabled" : ""
            } `}
            text={load ? <CircularProgress /> : "Actualizar"}
            disabled={load ? true : false}
          /> */}
          <Button
            onclick={submitCreateForm}
            type={"text"}
            classes={`text-normal ml-  a-button-topic-blue-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer ${load ? "disabled" : ""
              } `}
            text={load ? <CircularProgress /> : "Actualizar"}
            disabled={load ? true : false}
          />
        </div>

        <Popover open={isOpenPopper} anchorEl={popper}>
          <Calendar
            onClick={(d, m, y) => handleDate(d < 10 ? "0" + d : d, m, y)}
          />
        </Popover>

        <Popover open={isOpenPopperTime} anchorEl={popperTimer}>
          <Time
            onEvent={(h, m, z) => handleTime(h, m < 10 ? "0" + m : m, z)}
            onClick={setPopperTimer}
          />
        </Popover>
      </div>
    </form>
  );
}
