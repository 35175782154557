// React
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//Componentes
import { Button } from "../../atoms/Button/";
import { Input } from "../../atoms/Input";
import TabMenu from "../../molecules/TabMenu/Followers/followers";
import CardFollower from "../../molecules/Card/CardFollower/index";

// Material
import {
  Avatar,
  Grid,
  IconButton,
  Modal,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Api
import {
  follow,
  getArea,
  getFollowers,
  getFollowersInfo,
  getFollows,
  getFollowsInfo,
  getFormacion,
  getLanguage,
  getProfile,
  getUserApi,
  searchFollower,
} from "../../../api/profile";
import { getCidUsers } from "../../../api/user";

//Imagenes
import Question from "../../../assets/img/question-icon.svg";
import News from "../../../assets/img/news-icon.svg";
import Blog from "../../../assets/img/blog-icon.svg";
import Calendar from "../../../assets/img/event-icon.svg";
import Birrete from "../../../assets/img/profile/academica.svg";
import Empresa from "../../../assets/img/profile/empresa.svg";
import Area from "../../../assets/img/profile/area.svg";
import Idiomas from "../../../assets/img/profile/idiomas.svg";
import FollowIcon from "../../../assets/img/profile/seguir.svg";
import Podcast from "../../../assets/img/profile/podcast.svg";
import UnFollowIcon from "../../../assets/img/profile/siguiendo.svg";
import NotFound from "../../../assets/img/sin-resultados-1.svg";

import PencilUpdate from "../../../assets/img/pencil-icon-ocean.svg";
import Close from "../../../assets/img/closeFilter.svg";
import Animacion from "../../../assets/img/animaciones/animacion3.gif";

export default function ProfileUser({ userId, userFollow, type }) {
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [useA, setUseA] = useState([]);
  const [useR, setUseR] = useState([]);
  const [area, setArea] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [followStatus, setFollowStatus] = useState(false);

  const [formacion, setFormacion] = useState([]);
  const [idiomas, setIdiomas] = useState([]);

  const [seguidores, setSeguidores] = useState();
  const [seguidos, setSeguidos] = useState();

  const [mapaSeguidores, setMapaSeguidores] = useState();
  const [mapaSeguidos, setMapaSeguidos] = useState();
  const [search, setSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [erase, setErase] = useState(false);
  const [namesSeguidores, setNamesSeguidores] = useState();
  const [namesSeguidos, setNamesSeguidos] = useState();
  const [loading2, setLoading2] = useState(true);
  const [lista, setLista] = useState(true);

  const [listaSeguidores, setListaSeguidores] = useState([]);
  const [listaSeguidos, setListaSeguidos] = useState([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [campoIdioma, setCampoIdioma] = useState("");

  const [selectseguidores, setSelectSeguidores] = useState(true);
  const [selectseguidos, setSelectSeguidos] = useState(false);

  const [pruebas, setPruebas] = useState();

  const [animationTrigger, setAnimationTrigger] = useState(false);

  const matchDesktop = useMediaQuery("(min-width: 1200px)");
  const matchIpadPro = useMediaQuery("(min-width: 992px)");
  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");

  let history = useHistory();
  let data = {};
  data.userId = userId;
  data.userFollow = userFollow;
  let stated = {};
  stated.data = data;

  // Peticiones
  useEffect(() => {
    setLoading(true);

    if (type === "profile") {
      async function getProfileFromUser() {
        const response = getUserApi(userId);
        setLoading(true);
        response
          .then((result) => {
            if (result.status === 200) {
              setUseR(result.data.user);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            // setLoading(false);
          });

        const profileD = getProfile(userId);
        profileD
          .then((result) => {
            if (result.status === 200) {
              setProfile(result.data.profile);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            // setLoading(false);
          });
      }

      getLanguage(userId)
        .then((res) => setIdiomas(res.data.language))
        .catch((err) => console.log(err));

      getProfileFromUser();

      recuperarSeguidores(userId);
      recuperarSeguidos(userId);
      recuperarListaSeguidores(userId);
      recuperarListaSeguidos(userId);
      getArea(userId)
        .then((res) => setArea(res.data.areas))
        .catch((err) => console.log(err));
      getFormacion(userId)
        .then((res) => setFormacion(res.data.FA))
        .catch((err) => console.log(err));
      getCidUsers(userId)
        .then((res) => setPruebas(res.data.users))
        .catch((err) => console.log(err));
      // getFollowers(userId).then(res => setSeguidores(res.data)).catch(err => console.log(err))
      // getFollows(userId).then(res => setSeguidos(res.data)).catch(err => console.log(err))
    } else {
      setLoading(true);

      async function getProfileFromUser() {
        const response = getUserApi(userFollow);
        setLoading(true);
        response
          .then((result) => {
            if (result.status === 200) {
              setUseR(result.data.user);
              console.log(result.data.user);
              handleClose();
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            // setLoading(false);
          });

        const profileD = getProfile(userFollow);
        profileD
          .then((result) => {
            if (result.status === 200) {
              setProfile(result.data.profilesUser);
              console.log(result.data.profileUser);
              handleClose();
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            // setLoading(false);
          });
      }

      getLanguage(userFollow)
        .then((res) => setIdiomas(res.data.language))
        .catch((err) => console.log(err));

      getProfileFromUser();

      recuperarSeguidores(userFollow);
      recuperarSeguidos(userFollow);
      recuperarListaSeguidores(userFollow);
      recuperarListaSeguidos(userFollow);
      getArea(userFollow)
        .then((res) => setArea(res.data.areas))
        .catch((err) => console.log(err));
      getFormacion(userFollow)
        .then((res) => setFormacion(res.data.FA))
        .catch((err) => console.log(err));
      // getFollowers(userId).then(res => setSeguidores(res.data)).catch(err => console.log(err))
      // getFollows(userId).then(res => setSeguidos(res.data)).catch(err => console.log(err))
    }

    searchingFollow(userId, userFollow);
  }, [userFollow]);

  async function searchingFollow(userId, userFollow) {
    try {
      const response = await searchFollower(userId, userFollow);
      setFollowStatus(response.data.follow.isFollow);
    } catch (error) {}
  }

  async function recuperarListaSeguidores(id) {
    console.log(id);
    try {
      let mapa = new Map();
      let mapa2 = new Map();
      const response = await getFollowersInfo(id);
      setListaSeguidores(response.data.followersInfo);
      if (response.data.followersInfo.length != 0) {
        response.data.followersInfo.forEach((element) => {
          let name = element.name + " " + element.lastname;
          name = name.toLowerCase();
          mapa.set(element.user_following_id, name);
          mapa2.set(element.user_following_id, element);
        });
        setNamesSeguidores(mapa);
        setMapaSeguidores(mapa2);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function buscarSeguidor(e) {
    e.preventDefault();
    setSearch(true);
    let input = "" + e.target.value;
    let keys = [];
    namesSeguidores.forEach((valor, clave) => {
      if (valor.includes(input.toLowerCase())) {
        keys.push(clave);
      }
    });
    setResults(keys);
  }

  function buscarSeguido(e) {
    e.preventDefault();
    setSearch(true);
    let input = "" + e.target.value;
    let keys = [];
    namesSeguidos.forEach((valor, clave) => {
      if (valor.includes(input.toLowerCase())) {
        keys.push(clave);
      }
    });
    setResults(keys);
  }

  async function recuperarListaSeguidos(id) {
    console.log(id);
    try {
      let mapa = new Map();
      let mapa2 = new Map();
      const {
        data: { followsInfo },
      } = await getFollowsInfo(id);

      setListaSeguidos(followsInfo);
      if (followsInfo.length != 0) {
        followsInfo.forEach((element) => {
          let name = element.name + " " + element.lastname;
          name = name.toLowerCase();
          mapa.set(element.user_followed_id, name);
          mapa2.set(element.user_followed_id, element);
        });
        setNamesSeguidos(mapa);
        setMapaSeguidos(mapa2);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function recuperarSeguidores(id) {
    try {
      const response = await getFollowers(id);
      setSeguidores(response.data.countFollower);
    } catch (error) {
      console.log(error);
    }
  }

  async function recuperarSeguidos(id) {
    try {
      const response = await getFollows(id);
      setSeguidos(response.data.countFollows);
    } catch (error) {
      console.log(error);
    }
  }

  async function recuperarIdiomas() {
    if (useA[0].language_id != null) {
      let idiomaString = useA[0].language_id;
      let idiomaArray = idiomaString.split(",");
      let idiomasIdInt = [];
      idiomaArray.forEach((element) => {
        idiomasIdInt.push(parseInt(element));
      });

      let final = "";
      idiomasIdInt.forEach((x) => {
        idiomas.forEach((y) => {
          if (y.language_id == x) {
            final = final + y.name + ", ";
          }
        });
      });
      final = final.slice(0, -2);
      setCampoIdioma(final);
    }
    setLoading(false);
  }

  function handleClose() {
    // e.preventDefault();
    setOpen2(false);
    setOpen3(false);
    setLoading2(true);
  }

  async function actualizarSeguidores() {
    recuperarSeguidores(userFollow);
    recuperarSeguidos(userFollow);
    document.getElementById("numSeguidores").innerHTML = seguidores;
    document.getElementById("numSeguidos").innerHTML = seguidos;
  }

  async function handleOpen(e) {
    e.preventDefault();
    if (followStatus == false) {
      setFollowStatus(!followStatus);
      const response = await follow(userId, userFollow, followStatus);
      handleAnimation();
      actualizarSeguidores();
    } else {
      setOpen2(true);
    }
  }

  async function handleAnimation() {
    setAnimationTrigger(true);
    setTimeout(() => {
      var temp = document.getElementById("pulgar");
      temp.className += " fade-out";
    }, 1300);
    setTimeout(() => {
      setAnimationTrigger(false);
    }, 1500);
  }

  async function handleOpen3(e) {
    console.log("buenas2");
    e.preventDefault();
    setSelectSeguidos(false);
    setSelectSeguidores(true);
    setOpen3(true);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
  }

  async function handleOpen4(e) {
    console.log("buenas");
    e.preventDefault();
    setSelectSeguidores(false);
    setSelectSeguidos(true);
    setOpen3(true);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
  }

  function handleSeguidores(e) {
    e.preventDefault();
    setLoading2(true);
    setSelectSeguidores(true);
    setSelectSeguidos(false);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
    resetSearch();
  }

  function handleSeguidos(e) {
    e.preventDefault();
    setLoading2(true);
    setSelectSeguidores(false);
    setSelectSeguidos(true);
    setTimeout(() => {
      setLoading2(false);
    }, 500);
    resetSearch();
  }

  function resetSearch() {
    if (search == true) {
      setSearch(false);
      setResults([]);
      setErase(true);
    }
  }

  function eraseSearch() {
    document.getElementById("buscar").value = "";
    setErase(false);
  }

  function cardSkeleton(type) {
    let skeleton = [];

    if (type == "lista") {
      for (var i = 0; i < 3; i++) {
        skeleton.push(
          <div className={`body-card br-2 d-flex mx-1 px-4 py-7 mt-2 mb-2`}>
            <Skeleton
              variant="circle"
              animation="wave"
              width={35}
              height={32}
            ></Skeleton>
            <Grid
              container
              spacing={0}
              className={`d-flex align-items-center ml-4`}
            >
              <Grid item xs={6}>
                <Skeleton
                  variant="rect"
                  className="br-1"
                  animation="wave"
                  width={110}
                  height={21}
                ></Skeleton>
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  variant="rect"
                  className="br-1"
                  animation="wave"
                  width={80}
                  height={21}
                ></Skeleton>
              </Grid>
            </Grid>
          </div>,
        );
      }
    } else {
      for (let index = 0; index < 2; index++) {
        skeleton.push(
          <div
            className={`body-card br-2 d-flex mx-1 px-3 py-2 mt-2 mb-2 h-min-46`}
          >
            <Skeleton
              variant="circle"
              animation="wave"
              width={32}
              height={32}
            ></Skeleton>
            <div className={`d-flex flex-column ml-3`}>
              <Skeleton
                variant="rect"
                className="br-1"
                animation="wave"
                width={110}
                height={19}
              ></Skeleton>
              <Skeleton
                variant="rect"
                className="br-1 mt-2"
                animation="wave"
                width={80}
                height={19}
              ></Skeleton>
            </div>
          </div>,
        );
      }
    }

    return skeleton;
  }

  function izquierdaSeguidoresMosaico(arraytoWork) {
    let izquierda = [];
    let index = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      index = index + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={mapaSeguidores.get(arraytoWork[i]).user_following_id}
              name={mapaSeguidores.get(arraytoWork[i]).name}
              lastname={mapaSeguidores.get(arraytoWork[i]).lastname}
              area={mapaSeguidores.get(arraytoWork[i]).nameArea}
              empresa={mapaSeguidores.get(arraytoWork[i]).company}
              image={mapaSeguidores.get(arraytoWork[i]).photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    } else {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_following_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    }

    return izquierda;
  }

  function izquierdaSeguidosMosaico(arraytoWork) {
    let izquierda = [];
    let index = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      index = index + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={mapaSeguidos.get(arraytoWork[i]).user_followed_id}
              name={mapaSeguidos.get(arraytoWork[i]).name}
              lastname={mapaSeguidos.get(arraytoWork[i]).lastname}
              area={mapaSeguidos.get(arraytoWork[i]).nameArea}
              empresa={mapaSeguidos.get(arraytoWork[i]).company}
              image={mapaSeguidos.get(arraytoWork[i]).photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    } else {
      for (let i = 0; i < index; i++) {
        izquierda.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_followed_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    }
    return izquierda;
  }

  function derechaSeguidoresMosaico(arraytoWork) {
    let derecha = [];
    let start = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      start = start + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_following_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    } else {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_following_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    }
    return derecha;
  }

  function derechaSeguidosMosaico(arraytoWork) {
    let derecha = [];
    let start = arraytoWork.length / 2;
    if (arraytoWork.length % 2 != 0) {
      start = start + 0.5;
    }
    if (arraytoWork == results) {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={mapaSeguidos.get(arraytoWork[i]).user_followed_id}
              name={mapaSeguidos.get(arraytoWork[i]).name}
              lastname={mapaSeguidos.get(arraytoWork[i]).lastname}
              area={mapaSeguidos.get(arraytoWork[i]).nameArea}
              empresa={mapaSeguidos.get(arraytoWork[i]).company}
              image={mapaSeguidos.get(arraytoWork[i]).photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    } else {
      for (let i = start; i < arraytoWork.length; i++) {
        derecha.push(
          <>
            <CardFollower
              mosaico={true}
              id={arraytoWork[i].user_followed_id}
              name={arraytoWork[i].name}
              lastname={arraytoWork[i].lastname}
              area={arraytoWork[i].nameArea}
              empresa={arraytoWork[i].company}
              image={arraytoWork[i].photo_link}
              user_id={userId}
            ></CardFollower>
          </>,
        );
      }
    }
    return derecha;
  }

  function handleBack(e) {
    e.preventDefault();
    window.history.back();
  }

  setTimeout(() => {
    if (profile != undefined) {
      setUseA(profile);
      if (useA.length != 0 && dataLoaded == false) {
        setDataLoaded(true);
        recuperarIdiomas();
      }
    } else {
      setUseA(useR);
      setLoading(false);
    }
  }, 1);

  async function onClick(e) {
    e.preventDefault();
    setOpen2(false);
    setFollowStatus(!followStatus);
    const response = await follow(userId, userFollow, followStatus);
    actualizarSeguidores();
  }

  return (
    <>
      {loading ? (
        <>
          {matchPhone ? (
            <>
              <Grid className="mt-4" container spacing={0}>
                <Grid className={`d-flex`} item xs={12}>
                  <Skeleton
                    variant="rect"
                    width={144}
                    height={20}
                    animation="wave"
                    className="br-1 ml-7-prueba"
                  />
                  <Skeleton
                    variant="rect"
                    width={20}
                    height={20}
                    animation="wave"
                    className="br-1 d-block ml-4"
                  />
                </Grid>

                <Grid className="mt-4 ml-7-prueba" container spacing={0}>
                  <Skeleton
                    variant="circle"
                    animation="wave"
                    width={44}
                    height={44}
                  />
                  <div className="d-flex flex-column ml-9-prueba justify-content-center">
                    <Skeleton
                      variant="rect"
                      width={44}
                      height={18}
                      animation="wave"
                      className="br-1 m-auto"
                    />
                    <Skeleton
                      variant="rect"
                      width={84}
                      height={18}
                      animation="wave"
                      className="br-1 mt-2"
                    />
                  </div>
                  <div className="d-flex flex-column ml-11-prueba">
                    <Skeleton
                      variant="rect"
                      width={44}
                      height={18}
                      animation="wave"
                      className="br-1 m-auto"
                    />
                    <Skeleton
                      variant="rect"
                      width={84}
                      height={18}
                      animation="wave"
                      className="br-1 mt-2"
                    />
                  </div>
                </Grid>

                {/* <div className={`mt-4 ml-7-prueba a-text-regular-second-neutral-70 text-small`}>
                                                <div className=" d-flex align-items-center mb-3"><Skeleton variant="rect" width={124} height={20} animation="wave" className="br-1" /></div>
                                                <div className=" d-flex align-items-center mb-3" ><Skeleton variant="rect" width={124} height={20}  animation="wave" className="br-1" /></div>
                                                <div className=" d-flex align-items-center mb-3"  ><Skeleton variant="rect" width={124} height={20}  animation="wave" className="br-1" /></div>
                                                <div className=" d-flex align-items-center" ><Skeleton variant="rect" width={124} height={20}  animation="wave" className="br-1" /></div>
                                            </div> */}

                <Grid className="mt-4 pb-3" container spacing={0}>
                  <Grid item xs={6}>
                    <div className="box-shadow-prueba  br-2 height1-prueba mr-1 ml-7-prueba d-flex flex-column">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={84}
                        animation="wave"
                        className="br-1 m-auto"
                      />
                    </div>
                    <div className="box-shadow-prueba br-2 mt-2 height2-prueba mr-1 ml-7-prueba d-flex flex-column">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={138}
                        animation="wave"
                        className="br-1 m-auto"
                      />
                    </div>
                    <div className="box-shadow-prueba br-2 mt-2 height1-prueba mr-1 ml-7-prueba d-flex flex-column">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={84}
                        animation="wave"
                        className="br-1 m-auto"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="box-shadow-prueba br-2 mr-7-prueba height2-prueba ml-1 d-flex flex-column">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={138}
                        animation="wave"
                        className="br-1 m-auto"
                      />
                    </div>
                    <div className="box-shadow-prueba br-2 mr-7-prueba mt-2 height1-prueba ml-1 d-flex flex-column">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={84}
                        animation="wave"
                        className="br-1 m-auto"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                className={`${matchDesktop ? "mt-1" : matchIpadPro ? "mt-2" : ""}`}
                container
                spacing={0}
              >
                <Grid
                  className={` ${matchIpadPro ? "" : "ml-35 mt-6"} `}
                  container
                  spacing={0}
                >
                  <Skeleton
                    variant="circle"
                    animation="wave"
                    width={72}
                    height={72}
                  />
                  <div
                    className={` d-flex flex-column ml-9 ${matchDesktop ? "calc-width-prueba-sk" : " "}`}
                  >
                    <div className="d-flex">
                      <Skeleton
                        variant="rect"
                        width={184}
                        height={20}
                        animation="wave"
                        className="br-1"
                      />
                      <Skeleton
                        className={`br-1 d-block ${matchIpadPro ? "ml-9" : "ml-4"}`}
                        variant="rect"
                        width={24}
                        height={24}
                        animation="wave"
                      />
                    </div>
                    <div className="d-flex mt-3">
                      <div className="d-flex">
                        <Skeleton
                          variant="rect"
                          width={44}
                          height={20}
                          animation="wave"
                          className="br-1"
                        />
                        <Skeleton
                          variant="rect"
                          width={84}
                          height={20}
                          animation="wave"
                          className="br-1 ml-3"
                        />
                      </div>
                      <div className="d-flex ml-8">
                        <Skeleton
                          variant="rect"
                          width={44}
                          height={20}
                          animation="wave"
                          className="br-1"
                        />
                        <Skeleton
                          variant="rect"
                          width={84}
                          height={20}
                          animation="wave"
                          className="br-1 ml-3"
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                {/* <div className={`mt-4 ml-7-prueba a-text-regular-second-neutral-70 text-small`}>
                                                <div className=" d-flex align-items-center mb-3"><Skeleton variant="rect" width={124} height={20} animation="wave" className="br-1" /></div>
                                                <div className=" d-flex align-items-center mb-3" ><Skeleton variant="rect" width={124} height={20}  animation="wave" className="br-1" /></div>
                                                <div className=" d-flex align-items-center mb-3"  ><Skeleton variant="rect" width={124} height={20}  animation="wave" className="br-1" /></div>
                                                <div className=" d-flex align-items-center" ><Skeleton variant="rect" width={124} height={20}  animation="wave" className="br-1" /></div>
                                            </div> */}

                <Grid
                  className={`mt-7 ${matchIpadPro ? "" : "ml-35"} ${matchDesktop ? "d-none" : " "} `}
                  container
                  spacing={0}
                  container
                  spacing={0}
                >
                  <Grid item xs={6}>
                    <div
                      className={`box-shadow-prueba  br-2 height1-prueba mr-7 d-flex flex-column ${matchIpadPro ? "" : ""} `}
                    >
                      <Skeleton
                        variant="rect"
                        width={matchIpadPro ? "100%" : 308}
                        height={112}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                    <div
                      className={`box-shadow-prueba  br-2 mt-4 height2-prueba mr-7 d-flex flex-column ${matchIpadPro ? "" : ""} `}
                    >
                      <Skeleton
                        variant="rect"
                        width={matchIpadPro ? "100%" : 308}
                        height={190}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                    <div
                      className={`box-shadow-prueba  br-2 mt-4 height1-prueba mr-7 d-flex flex-column ${matchIpadPro ? "" : ""} `}
                    >
                      <Skeleton
                        variant="rect"
                        width={matchIpadPro ? "100%" : 308}
                        height={112}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    className="d-flex flex-column"
                    container
                    spacing={0}
                    xs={6}
                  >
                    <div
                      className={` box-shadow-prueba br-2 height2-prueba ml-10 d-flex flex-column ${matchIpadPro ? "" : "mr-6"} `}
                    >
                      <Skeleton
                        variant="rect"
                        width={matchIpadPro ? "100%" : 244}
                        height={190}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                    <div
                      className={` box-shadow-prueba br-2 mt-4 height1-prueba ml-10 d-flex flex-column ${matchIpadPro ? "" : "mr-6"} `}
                    >
                      <Skeleton
                        variant="rect"
                        width={matchIpadPro ? "100%" : 244}
                        height={112}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  className={`mt-7 ${matchDesktop ? " " : "d-none"} `}
                  container
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <div className="box-shadow-prueba  br-2 height1-prueba d-flex flex-column r-pc">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={112}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                    <div className="box-shadow-prueba br-2 mt-4 height2-prueba d-flex flex-column r-pc">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={190}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      className={` box-shadow-prueba br-2 height2-prueba d-flex flex-column l-pc r-pc`}
                    >
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={190}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                    <div
                      className={` box-shadow-prueba br-2 mt-4 height1-prueba d-flex flex-column l-pc r-pc`}
                    >
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={112}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="box-shadow-prueba br-2 height1-prueba d-flex flex-column l-pc">
                      <Skeleton
                        variant="rect"
                        width={"100%"}
                        height={112}
                        animation="wave"
                        className="br-1"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          {useA.map((r) => (
            <>
              {matchPhone ? (
                <>
                  {/**  <Header className="" isCollapsed={isCollapsed} link={linkSearch} text='Buscar empresa' />*/}
                  <Grid className="mt-4" container spacing={0}>
                    <div className={`d-flex`}>
                      <p className="a-text-name-prueba ml-7-prueba text-normal">
                        {r.name} {r.lastname}
                      </p>
                      {type == "profile" ? (
                        <Link to="/cid/carso/update-profile">
                          <img
                            src={PencilUpdate}
                            className="lapiz d-block ml-4"
                          ></img>
                        </Link>
                      ) : (
                        <div className="d-flex">
                          <IconButton className="seguidor" onClick={handleOpen}>
                            <img
                              src={followStatus ? UnFollowIcon : FollowIcon}
                              alt="PDF Icon"
                              className="cursor-pointer lapiz d-block ml-4"
                            />
                          </IconButton>
                          <img
                            id="pulgar"
                            className={` ${animationTrigger ? "" : "d-none"} animation`}
                            src={Animacion}
                            width={86}
                            height={86}
                          ></img>
                        </div>
                      )}
                    </div>

                    <Grid className="mt-4 ml-7-prueba" container spacing={0}>
                      <Avatar
                        alt="user"
                        style={{ maxHeight: "44px", maxWidth: "44px" }}
                        src={r.photo_link}
                      />
                      <div
                        onClick={() => {
                          console.log("buenassss");
                          data.selected = "seguidores";
                          history.push({
                            pathname: `/cid/carso/follows/${r.name}`,
                            state: data,
                          });
                        }}
                        className="d-flex flex-column ml-9-prueba cursor-pointer"
                      >
                        <p
                          id="numSeguidores"
                          className="a-text-medium-gray-prueba text-center text-small"
                        >
                          {seguidores != null ? seguidores : 0}
                        </p>
                        <p className="a-text-regular-second-neutral-70 text-center text-small mt-2 roboto-regular">
                          Seguidores
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          console.log("buenassss");
                          data.selected = "seguidos";
                          history.push({
                            pathname: `/cid/carso/follows/${r.name}`,
                            state: data,
                          });
                        }}
                        className="d-flex flex-column ml-11-prueba cursor-pointer"
                      >
                        <p
                          id="numSeguidos"
                          className="a-text-medium-gray-prueba text-center text-small"
                        >
                          {seguidos != null ? seguidos : 0}
                        </p>
                        <p className="a-text-regular-second-neutral-70 text-center text-small mt-2 roboto-regular">
                          Seguidos
                        </p>
                      </div>
                    </Grid>

                    <div
                      className={`mt-4 ml-7-prueba a-text-regular-second-neutral-70 text-small ${r.hasOwnProperty("company") ? " " : "d-none"}`}
                    >
                      <div
                        className={` align-items-center mb-3 ${r.hasOwnProperty("description") && r.description != " " ? "d-flex" : "d-none"}`}
                      >
                        {r.hasOwnProperty("description") ? r.description : ""}
                      </div>
                      {r.company && (
                        <div
                          className={`align-items-center mb-3 ${r.hasOwnProperty("company") && r.company != " " ? "d-flex" : "d-none"}`}
                        >
                          <img src={Empresa} className="mr-3" />
                          <p>
                            Empresa:{" "}
                            <span className="a-text-regular-first-primary-60">
                              {r.company}
                            </span>
                          </p>
                        </div>
                      )}
                      <div
                        className={`align-items-center mb-3 ${r.hasOwnProperty("nameArea") && r.nameArea != null ? "d-flex" : "d-none"}`}
                      >
                        <img src={Area} className="mr-3" />
                        <p>
                          Área:{" "}
                          <span className="a-text-regular-first-primary-60">
                            {r.hasOwnProperty("nameArea") ? r.nameArea : " "}
                          </span>
                        </p>
                      </div>
                      <div
                        className={`align-items-center mb-3 ${r.hasOwnProperty("nameAcademic") && r.nameAcademic != null ? "d-flex" : "d-none"}`}
                      >
                        <img src={Birrete} className="mr-3" />
                        <p>
                          Formación:{" "}
                          <span className="a-text-regular-first-primary-60">
                            {r.hasOwnProperty("nameAcademic")
                              ? r.nameAcademic
                              : " "}
                          </span>
                        </p>
                      </div>
                      <div
                        className={`align-items-center ${r.hasOwnProperty("language_id") && r.language_id != null ? "d-flex" : "d-none"}`}
                      >
                        <img src={Idiomas} className="mr-3" />
                        <p>
                          Idiomas:{" "}
                          <span
                            id="colocador-idiomas"
                            className="a-text-regular-first-primary-60"
                          >
                            {campoIdioma != "" ? campoIdioma : ""}
                          </span>
                        </p>
                      </div>
                    </div>

                    <Grid className="mt-4 pb-3" container spacing={0}>
                      <Grid item xs={6}>
                        <Link
                          to="/cid/carso/questions/list"
                          class="text-decoration-none"
                        >
                          <div className="box-shadow-prueba  br-2 height1-prueba mr-1 ml-7-prueba d-flex flex-column">
                            <img
                              className="siguiendo mt-2 ml-3"
                              src={Question}
                            ></img>
                            <p className="text-small a-text-regular-first-primary-60 ml-3 mt-2 pb-2">
                              Preguntas
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/cid/carso/blogs/list"
                          class="text-decoration-none"
                        >
                          <div className="box-shadow-prueba br-2 mt-2 height2-prueba mr-1 ml-7-prueba d-flex flex-column">
                            <img
                              className="siguiendo mt-2 ml-3 "
                              src={Blog}
                            ></img>
                            <p className="text-small a-text-regular-first-primary-60 ml-3 mt-2 pb-1  ">
                              Blog
                            </p>
                          </div>
                        </Link>
                        <div className="box-shadow-prueba br-2 mt-2 height3-prueba mr-1 ml-7-prueba d-flex flex-column">
                          <img
                            className="siguiendo mt-2 ml-3 opacity-3"
                            src={Podcast}
                          ></img>
                          <p className="text-small a-text-regular-first-primary-60 ml-3 mt-2 pb-1 opacity-3">
                            Podcast
                          </p>
                          <div className="background-second-primary-80">
                            <div className="d-flex justify-content-center dotted-container">
                              <p className="text-small a-text-regular-second-primary-50">
                                ¡Próximamente!
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <Link to="/cid/carso/news" class="text-decoration-none">
                          <div className="box-shadow-prueba br-2 mr-7-prueba height2-prueba ml-1 d-flex flex-column">
                            <img
                              className="siguiendo mt-2 ml-3"
                              src={News}
                            ></img>
                            <p className="text-small a-text-regular-first-primary-60 ml-3 mt-2 pb-2">
                              Noticias
                            </p>
                          </div>
                        </Link>
                        <div className="box-shadow-prueba br-2 mr-7-prueba mt-2 height3-prueba ml-1 d-flex flex-column">
                          <img
                            className="siguiendo mt-2 ml-3 opacity-3"
                            src={Calendar}
                          ></img>
                          <p className="text-small a-text-regular-first-primary-60 ml-3 mt-2 pb-1 opacity-3">
                            Eventos
                          </p>
                          <div className="background-second-primary-80">
                            <div className="d-flex justify-content-center dotted-container">
                              <p className="text-small a-text-regular-second-primary-50">
                                ¡Próximamente!
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  {/* TODO: SEGUIR REVISANDO LOS LINKS */}
                  <Grid
                    className={`${matchDesktop ? "mt-1" : matchIpadPro ? "mt-2" : ""}`}
                    container
                    spacing={0}
                  >
                    <Grid
                      className={` ${matchIpadPro ? "" : "ml-35 mt-6"} `}
                      container
                      spacing={0}
                    >
                      <Avatar
                        alt="user"
                        style={{ minHeight: "72px", minWidth: "72px" }}
                        src={r.photo_link}
                      />
                      <div className="d-flex flex-column ml-9 calc-width-prueba">
                        <div
                          className={`d-flex ${matchDesktop ? "" : "mr-xl-4"}`}
                        >
                          <div className="">
                            <p className="a-text-name-prueba h3 ">
                              {r.name} {r.lastname}
                            </p>
                          </div>
                          {type == "profile" ? (
                            <Link
                              className={`${matchIpadPro ? "ml-9" : "ml-4"} `}
                              to="/cid/carso/update-profile"
                            >
                              <img
                                style={
                                  matchIpadPro
                                    ? { width: "24px", height: "24px" }
                                    : { width: "24px", height: "24px" }
                                }
                                src={PencilUpdate}
                                className="d-block"
                              ></img>
                            </Link>
                          ) : (
                            <>
                              <div className="ml-4  d-flex mr-lg-4 mt-n20">
                                <IconButton onClick={handleOpen}>
                                  <img
                                    src={
                                      followStatus ? UnFollowIcon : FollowIcon
                                    }
                                    alt="PDF Icon"
                                    className="cursor-pointer "
                                    style={
                                      matchIpadPro
                                        ? { width: "35px", height: "35px" }
                                        : { width: "32px", height: "32px" }
                                    }
                                  />
                                </IconButton>
                                <img
                                  id="pulgar"
                                  className={` ${animationTrigger ? "" : "d-none"} animation`}
                                  src={Animacion}
                                  width={126}
                                  height={126}
                                ></img>
                              </div>
                              <div
                                className={`d-flex justify-content-end ml-auto mt-n9 ${matchIpadPro ? "" : "mr-5"}`}
                              >
                                <Link onClick={handleBack}>
                                  {" "}
                                  <IconButton>
                                    <img
                                      src={Close}
                                      alt="PDF Icon"
                                      className="cursor-pointer "
                                      style={
                                        matchIpadPro
                                          ? { width: "24px", height: "24px" }
                                          : { width: "24px", height: "24px" }
                                      }
                                    />
                                  </IconButton>
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className={`d-flex ${type == "profile" ? "mt-9" : "mt-2"}`}
                        >
                          <div className="d-flex">
                            {matchIpadPro ? (
                              <div
                                onClick={handleOpen3}
                                className={"d-flex cursor-pointer"}
                              >
                                <p
                                  id="numSeguidores"
                                  className="a-text-medium-gray-prueba text-center text-medium"
                                >
                                  {seguidores != null ? seguidores : 0}
                                </p>
                                <p className="a-text-regular-second-neutral-70 text-center text-medium roboto-regular ml-3">
                                  Seguidores
                                </p>
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  console.log("buenassss");
                                  data.selected = "seguidores";
                                  history.push({
                                    pathname: `/cid/carso/follows/${r.name}`,
                                    state: data,
                                  });
                                }}
                                className={"d-flex text-decoration-none"}
                              >
                                <p
                                  id="numSeguidores"
                                  className="a-text-medium-gray-prueba text-center text-medium"
                                >
                                  {seguidores != null ? seguidores : 0}
                                </p>
                                <p className="a-text-regular-second-neutral-70 text-center text-medium roboto-regular ml-3">
                                  Seguidores
                                </p>
                              </div>
                            )}
                          </div>
                          {matchIpadPro ? (
                            <div
                              onClick={handleOpen4}
                              className="d-flex ml-8 cursor-pointer"
                            >
                              <p
                                id="numSeguidos"
                                className="a-text-medium-gray-prueba text-center text-medium"
                              >
                                {seguidos != null ? seguidos : 0}
                              </p>
                              <p className="text-center text-medium roboto-regular ml-3">
                                Seguidos
                              </p>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                console.log("buenassss");
                                data.selected = "seguidos";
                                history.push({
                                  pathname: `/cid/carso/follows/${r.name}`,
                                  state: data,
                                });
                              }}
                              className="d-flex ml-8 text-decoration-none"
                            >
                              <p
                                id="numSeguidos"
                                className="a-text-medium-gray-prueba text-center text-medium"
                              >
                                {seguidos != null ? seguidos : 0}
                              </p>
                              <p className="text-center text-medium roboto-regular ml-3">
                                Seguidos
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid>
                      <div
                        className={`a-text-regular-second-neutral-70 text-medium mt-10 ${matchIpadPro ? "" : "ml-35 mr-6"} ${r.hasOwnProperty("description") && r.description != " " ? "d-flex" : "d-none"} ? "d-flex" : "d-none"}`}
                      >
                        {r.hasOwnProperty("description") ? r.description : ""}
                      </div>
                      <div
                        className={`mt-6 a-text-regular-second-neutral-70 text-small ${r.hasOwnProperty("company") ? "d-flex" : "d-none"}`}
                      >
                        <div
                          className={`d-flex text-medium flex-column ${matchIpadPro ? "" : "ml-35"}`}
                        >
                          {r.company && (
                            <div
                              className={`align-items-center mb-3 ${r.hasOwnProperty("company") && r.company != " " ? "d-flex" : "d-none"}`}
                            >
                              <img
                                style={{ width: "38px", height: "38px" }}
                                src={Empresa}
                                className="mr-3"
                              />
                              <p>
                                Empresa:{" "}
                                <span className="a-text-regular-first-primary-60">
                                  {r.company}
                                </span>
                              </p>
                            </div>
                          )}
                          <div
                            className={`align-items-center ${r.hasOwnProperty("nameAcademic") && r.nameAcademic != null ? "d-flex" : "d-none"}`}
                          >
                            <img
                              style={{ width: "38px", height: "38px" }}
                              src={Birrete}
                              className="mr-3"
                            />{" "}
                            <p>
                              Formación:{" "}
                              <span className="a-text-regular-first-primary-60">
                                {r.hasOwnProperty("nameAcademic")
                                  ? r.nameAcademic
                                  : " "}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column ml-22">
                          <div>
                            <div
                              className={`text-medium align-items-center mb-3 ${r.hasOwnProperty("nameArea") && r.nameArea != null ? "d-flex" : "d-none"}`}
                            >
                              <img
                                style={{ width: "38px", height: "38px" }}
                                src={Area}
                                className="mr-3"
                              />
                              <p>
                                Área:{" "}
                                <span className="a-text-regular-first-primary-60">
                                  {r.hasOwnProperty("nameArea")
                                    ? r.nameArea
                                    : " "}
                                </span>
                              </p>
                            </div>
                            <div
                              className={`text-medium align-items-center ${r.hasOwnProperty("language_id") && r.language_id != null ? "d-flex" : "d-none"}`}
                            >
                              <img
                                style={{ width: "38px", height: "38px" }}
                                src={Idiomas}
                                className="mr-3"
                              />{" "}
                              <p>
                                Idiomas:{" "}
                                <span className="a-text-regular-first-primary-60">
                                  {campoIdioma != "" ? campoIdioma : ""}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      className={`mt-7 ${matchIpadPro ? "" : "ml-35 pb-2"} ${matchDesktop ? "d-none" : " "} `}
                      container
                      spacing={0}
                    >
                      <Grid item xs={6}>
                        <Link
                          to="/cid/carso/questions/list"
                          class="text-decoration-none"
                        >
                          <div className="box-shadow-prueba  br-2 height1-prueba mr-7 d-flex flex-column">
                            <img
                              className="siguiendo mt-9 ml-4-prueba"
                              src={Question}
                            ></img>
                            <p
                              className={`a-text-regular-first-primary-60 ml-4-prueba mt-8 pb-2 text-medium `}
                            >
                              Preguntas
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/cid/carso/blogs/list"
                          class="text-decoration-none"
                        >
                          <div className="box-shadow-prueba br-2 mt-4 height2-prueba mr-7 d-flex flex-column">
                            <img
                              className="siguiendo mt-9 ml-4-prueba "
                              src={Blog}
                            ></img>
                            <p
                              className={`a-text-regular-first-primary-60 ml-4-prueba mt-8 pb-2 text-medium `}
                            >
                              Blog
                            </p>
                          </div>
                        </Link>

                        <div className="box-shadow-prueba br-2 mt-4 height3-prueba mr-7 d-flex flex-column">
                          <img
                            className="siguiendo mt-9 ml-4-prueba opacity-3"
                            src={Podcast}
                          ></img>
                          <p
                            className={`a-text-regular-first-primary-60 ml-4-prueba mt-8 pb-2 text-medium opacity-3`}
                          >
                            Podcast
                          </p>
                          <div className="background-second-primary-80">
                            <div className="d-flex justify-content-center dotted-container">
                              <p className="text-medium py-1 a-text-regular-second-primary-50">
                                ¡Próximamente!
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        className="d-flex flex-column"
                        container
                        spacing={0}
                        xs={6}
                      >
                        <Link to="/cid/carso/news" class="text-decoration-none">
                          <div
                            className={` box-shadow-prueba br-2 height2-prueba ml-10 d-flex flex-column ${matchIpadPro ? "" : "mr-6"} `}
                          >
                            <img
                              className="siguiendo mt-9 ml-4-prueba"
                              src={News}
                            ></img>
                            <p
                              className={`a-text-regular-first-primary-60 ml-4-prueba mt-8 pb-2 text-medium `}
                            >
                              Noticias
                            </p>
                          </div>
                        </Link>
                        <div
                          className={` box-shadow-prueba br-2 mt-4 height3-prueba ml-10 d-flex flex-column ${matchIpadPro ? "" : "mr-6"} `}
                        >
                          <img
                            className="siguiendo mt-9 ml-4-prueba opacity-3"
                            src={Calendar}
                          ></img>
                          <p
                            className={`a-text-regular-first-primary-60 ml-4-prueba mt-8 pb-2 text-medium opacity-3`}
                          >
                            Eventos
                          </p>
                          <div className="background-second-primary-80">
                            <div className="d-flex justify-content-center dotted-container">
                              <p className="text-medium py-1 a-text-regular-second-primary-50">
                                ¡Próximamente!
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      className={`mt-7 ${matchDesktop ? " " : "d-none"} `}
                      container
                      spacing={0}
                    >
                      <Grid item xs={4}>
                        <Link
                          to="/cid/carso/questions/list"
                          class="text-decoration-none"
                        >
                          <div className="box-shadow-prueba  br-2 height1-prueba d-flex flex-column r-pc">
                            <img
                              className="siguiendo mt-9 ml-9"
                              src={Question}
                            ></img>
                            <p
                              className={`a-text-regular-first-primary-60 ml-9 mt-8 pb-2 text-medium`}
                            >
                              Preguntas
                            </p>
                          </div>
                        </Link>
                        <div className="box-shadow-prueba br-2 mt-9 height3-prueba d-flex flex-column r-pc">
                          <img
                            className="siguiendo mt-9 ml-9 opacity-3"
                            src={Calendar}
                          ></img>
                          <p
                            className={`a-text-regular-first-primary-60 ml-9 mt-8 pb-2 text-medium opacity-3`}
                          >
                            Eventos
                          </p>
                          <div className="background-second-primary-80 mt-1">
                            <div className="d-flex justify-content-center dotted-container">
                              <p className="text-medium py-0-5 a-text-regular-second-primary-50">
                                ¡Próximamente!
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <Link to="/cid/carso/news" class="text-decoration-none">
                          <div
                            className={` box-shadow-prueba br-2 height2-prueba d-flex flex-column l-pc r-pc`}
                          >
                            <img
                              className="siguiendo mt-9 ml-9"
                              src={News}
                            ></img>
                            <p
                              className={`a-text-regular-first-primary-60 ml-9 mt-8 pb-2 text-medium `}
                            >
                              Noticias
                            </p>
                          </div>
                        </Link>
                        <div
                          className={` box-shadow-prueba br-2 mt-9 height3-prueba d-flex flex-column l-pc r-pc`}
                        >
                          <img
                            className="siguiendo mt-9 ml-9 opacity-3"
                            src={Podcast}
                          ></img>
                          <p
                            className={`a-text-regular-first-primary-60 ml-9 mt-8 pb-2 text-medium opacity-3`}
                          >
                            Podcast
                          </p>
                          <div className="background-second-primary-80 mt-1">
                            <div className="d-flex justify-content-center dotted-container">
                              <p className="text-medium py-0-5 a-text-regular-second-primary-50">
                                ¡Próximamente!
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <Link
                          to="/cid/carso/blogs/list"
                          class="text-decoration-none"
                        >
                          <div className="box-shadow-prueba br-2 height3-prueba d-flex flex-column l-pc">
                            <img
                              className="siguiendo mt-9 ml-9 "
                              src={Blog}
                            ></img>
                            <p
                              className={`a-text-regular-first-primary-60 ml-9 mt-8 pb-2 text-medium`}
                            >
                              Blog
                            </p>
                          </div>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Modal
                open={open2}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className="modal-confirm-follow">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <p
                      className={`a-text-medium-second-neutral-60 w-100 text-center ${matchIpadMini ? "h3" : "text-normal"}`}
                    >
                      Dejar de seguir a:
                    </p>
                    <p
                      className={`a-text-medium-first-primary-60 w-100 text-center pt-2 ${matchIpadMini ? "h3 mb-6" : "text-medium mb-3"}`}
                    >
                      {r.name} {r.lastname}
                    </p>
                    <div className="d-flex">
                      <Button
                        onclick={onClick}
                        type="button"
                        classes={`a-text-medium-first-primary-60 a-button-topic-gray-2 mr-4 ${matchIpadMini ? "text-normal" : "text-small"}`}
                        height={32}
                        width={matchIpadMini ? 144 : 98}
                        text="Aceptar"
                      />
                      <Button
                        onclick={handleClose}
                        type="button"
                        classes={`a-text-medium-first-neutral-10 a-button-blue-1 ${matchIpadMini ? "text-normal" : "text-small"}`}
                        height={32}
                        width={matchIpadMini ? 144 : 98}
                        text="Cancelar"
                      />
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                open={open3}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Grid className={`modal-view-follows mx-7 `}>
                  <div className={`d-flex justify-content-between mt-11`}>
                    <p className={` a-text-bold-first-primary-60 h3`}>
                      {r.name} {r.lastname}
                    </p>
                    <img
                      onClick={handleClose}
                      className={`cursor-pointer`}
                      width={26}
                      height={26}
                      src={Close}
                    ></img>
                  </div>
                  <TabMenu
                    resetSearch={resetSearch}
                    lista={lista}
                    setLoading2={setLoading2}
                    setDisplay={setLista}
                    opttwo={selectseguidores}
                    optthird={selectseguidos}
                    fun1={handleSeguidores}
                    fun2={handleSeguidos}
                    fun3={setErase}
                    setOptTwo={setSelectSeguidores}
                    setOptThird={setSelectSeguidos}
                    seguidores={seguidores}
                    seguidos={seguidos}
                  />

                  {selectseguidores == true ? (
                    <>
                      {seguidores != 0 && loading2 == false ? (
                        <>
                          <div className={``}>
                            <Input
                              id="buscar"
                              autocomplete="off"
                              onChange={(e) => buscarSeguidor(e)}
                              className={`mt-9`}
                              type="search"
                              placeholder={"Buscar a quién sigues"}
                              width="100%"
                            />
                          </div>
                          {search == false && lista ? (
                            <div
                              className={`d-flex mt-4 h-max-45vh scroll-overflow flex-column h-519`}
                            >
                              {listaSeguidores.map((s) => (
                                <>
                                  <CardFollower
                                    id={s.user_following_id}
                                    name={s.name}
                                    lastname={s.lastname}
                                    area={s.nameArea}
                                    empresa={s.company}
                                    image={s.photo_link}
                                    user_id={userId}
                                  ></CardFollower>
                                </>
                              ))}
                            </div>
                          ) : search == false && lista == false ? (
                            <Grid
                              container
                              spacing={0}
                              className={`d-flex mt-4 h-max-45vh scroll-overflow h-519`}
                            >
                              <Grid item xs={6}>
                                <div className={`d-flex mr-3 flex-column`}>
                                  {izquierdaSeguidoresMosaico(listaSeguidores)}
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={`d-flex ml-3 flex-column`}>
                                  {listaSeguidores.length > 1 &&
                                    derechaSeguidoresMosaico(listaSeguidores)}
                                </div>
                              </Grid>
                            </Grid>
                          ) : (
                            <div
                              className={`d-flex mt-4 h-max-45vh scroll-overflow flex-column h-519`}
                            >
                              {results.length != 0 && lista == true ? (
                                results.map((s) => (
                                  <>
                                    <CardFollower
                                      id={
                                        mapaSeguidores.get(s).user_following_id
                                      }
                                      name={mapaSeguidores.get(s).name}
                                      lastname={mapaSeguidores.get(s).lastname}
                                      area={mapaSeguidores.get(s).nameArea}
                                      empresa={mapaSeguidores.get(s).company}
                                      image={mapaSeguidores.get(s).photo_link}
                                      user_id={userId}
                                    ></CardFollower>
                                  </>
                                ))
                              ) : results.length != 0 && lista == false ? (
                                <Grid
                                  container
                                  spacing={0}
                                  className={`d-flex`}
                                >
                                  <Grid item xs={6}>
                                    <div className={`d-flex mr-3 flex-column`}>
                                      {izquierdaSeguidoresMosaico(results)}
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div className={`d-flex ml-3 flex-column`}>
                                      {results.length > 1 &&
                                        derechaSeguidosMosaico(results)}
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : (
                                <>
                                  <div
                                    className={`${matchIpadMini ? "py-4" : "py-3"} d-flex flex-column align-items-center border-ocean-30 br-2`}
                                  >
                                    <p
                                      className={`${matchIpadMini ? "h3" : "text-small mt-3"} a-text-medium-second-neutral-60`}
                                    >
                                      No se encontraron resultados para
                                    </p>
                                    <p
                                      className={`${matchIpadMini ? "h3 mt-3" : "text-medium mt-3"} a-text-medium-first-primary-60`}
                                    >
                                      "{document.getElementById("buscar").value}
                                      "
                                    </p>
                                  </div>
                                  <img
                                    className={`mt-10`}
                                    height={matchIpadMini ? 258 : 185}
                                    src={NotFound}
                                  ></img>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ) : seguidores == 0 ? (
                        <div className={`d-flex flex-column h-max-45vh h-519`}>
                          <p
                            className={`h3 a-text-medium-second-neutral-70 align-self-center mt-12`}
                          >
                            Seguidores
                          </p>
                          <p
                            className={`text-center align-self-center text-medium a-text-regular-second-neutral-70 line-height-24 mt-4`}
                          >
                            Cuando te comiencen a seguir, tus colegas<br></br>
                            aparecerán aquí
                          </p>
                        </div>
                      ) : (
                        <>
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={35}
                            animation="wave"
                            className={`br-7 mt-9`}
                          ></Skeleton>
                          <div
                            className={`d-flex mt-4 h-max-45vh scroll-overflow flex-column h-519`}
                          >
                            {lista ? (
                              cardSkeleton("lista")
                            ) : (
                              <Grid container spacing={0} className={`d-flex`}>
                                <Grid item xs={6}>
                                  <div className={`d-flex mr-3 flex-column`}>
                                    {cardSkeleton("mosaico")}
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className={`d-flex ml-3 flex-column`}>
                                    {cardSkeleton("mosaico")}
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {seguidos != 0 && loading2 == false ? (
                        <>
                          <div className={``}>
                            <Input
                              id="buscar"
                              autocomplete="off"
                              onChange={(e) => buscarSeguido(e)}
                              className={`mt-9`}
                              type="search"
                              placeholder={"Buscar a quién sigues"}
                              width="100%"
                            />
                          </div>
                          {search == false && lista ? (
                            <div
                              className={`d-flex mt-4 h-max-45vh scroll-overflow flex-column h-519`}
                            >
                              {listaSeguidos.map((s) => (
                                <>
                                  <CardFollower
                                    id={s.user_followed_id}
                                    name={s.name}
                                    lastname={s.lastname}
                                    area={s.nameArea}
                                    empresa={s.company}
                                    image={s.photo_link}
                                    user_id={userId}
                                  ></CardFollower>
                                </>
                              ))}
                            </div>
                          ) : search == false && lista == false ? (
                            <Grid
                              container
                              spacing={0}
                              className={`d-flex mt-4 h-max-45vh scroll-overflow h-519`}
                            >
                              <Grid item xs={6}>
                                <div className={`d-flex mr-3 flex-column`}>
                                  {izquierdaSeguidosMosaico(listaSeguidos)}
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={`d-flex ml-3 flex-column`}>
                                  {listaSeguidos.length > 1 &&
                                    derechaSeguidosMosaico(listaSeguidos)}
                                </div>
                              </Grid>
                            </Grid>
                          ) : (
                            <div
                              className={`d-flex mt-4 h-max-45vh scroll-overflow flex-column h-519`}
                            >
                              {results.length != 0 && lista ? (
                                results.map((s) => (
                                  <>
                                    <CardFollower
                                      id={mapaSeguidos.get(s).user_followed_id}
                                      name={mapaSeguidos.get(s).name}
                                      lastname={mapaSeguidos.get(s).lastname}
                                      area={mapaSeguidos.get(s).nameArea}
                                      empresa={mapaSeguidos.get(s).company}
                                      image={mapaSeguidos.get(s).photo_link}
                                      user_id={userId}
                                    ></CardFollower>
                                  </>
                                ))
                              ) : results.length != 0 && lista == false ? (
                                <Grid
                                  container
                                  spacing={0}
                                  className={`d-flex`}
                                >
                                  <Grid item xs={6}>
                                    <div className={`d-flex mr-3 flex-column`}>
                                      {izquierdaSeguidosMosaico(results)}
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div className={`d-flex ml-3 flex-column`}>
                                      {results.length > 1 &&
                                        derechaSeguidosMosaico(results)}
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : (
                                <>
                                  <div
                                    className={`${matchIpadMini ? "py-4" : "py-3"} d-flex flex-column align-items-center border-ocean-30 br-2`}
                                  >
                                    <p
                                      className={`${matchIpadMini ? "h3" : "text-small mt-3"} a-text-medium-second-neutral-60`}
                                    >
                                      No se encontraron resultados para
                                    </p>
                                    <p
                                      className={`${matchIpadMini ? "h3 mt-3" : "text-medium mt-3"} a-text-medium-first-primary-60`}
                                    >
                                      "{document.getElementById("buscar").value}
                                      "
                                    </p>
                                  </div>
                                  <img
                                    className={`mt-10`}
                                    height={matchIpadMini ? 258 : 185}
                                    src={NotFound}
                                  ></img>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ) : seguidos == 0 ? (
                        <div className={`d-flex h-max-45vh flex-column h-519`}>
                          <p
                            className={`h3 a-text-medium-second-neutral-70 align-self-center mt-12`}
                          >
                            Aún no sigues a nadie
                          </p>
                          <p
                            className={`text-center align-self-center text-medium a-text-regular-second-neutral-70 line-height-24 mt-4`}
                          >
                            Cuando comiences a seguir a un colega<br></br>
                            aparecerá aquí
                          </p>
                        </div>
                      ) : (
                        <>
                          <Skeleton
                            variant="rect"
                            width={"100%"}
                            height={35}
                            animation="wave"
                            className={`br-7 mt-9`}
                          ></Skeleton>
                          <div
                            className={`d-flex mt-4 h-max-45vh scroll-overflow flex-column h-519`}
                          >
                            {lista ? (
                              cardSkeleton("lista")
                            ) : (
                              <Grid container spacing={0} className={`d-flex`}>
                                <Grid item xs={6}>
                                  <div className={`d-flex mr-3 flex-column`}>
                                    {cardSkeleton("mosaico")}
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className={`d-flex ml-3 flex-column`}>
                                    {cardSkeleton("mosaico")}
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {erase == true && eraseSearch()}
                </Grid>
              </Modal>
            </>
          ))}
        </>
      )}
    </>
  );
}
