
export const headers = [
  { label: "ID", key: "id" },
  { label: "Fecha de creación", key: "fecha" },
  { label: "Empresa", key: "emp" },
  { label: "Descripción", key: "desc" },
  { label: "Tamaño", key: "tam" },
  { label: "Fondeo", key: "fond" },
  { label: "Ingreso", key: "ingr" },
  { label: "Valuación", key: "val" },
  { label: "Oportunidades", key: "op" },
  { label: "Sede", key: "sede" },
  { label: "Alianzas", key: "alzs" },
  { label: "Mercados", key: "merca" },
  { label: "Página web", key: "pag" },
  { label: "Facebook", key: "fb" },
  { label: "Twitter", key: "twt" },
  { label: "Instagram", key: "ig" },
  { label: "LinkedIn", key: "lin" },
  { label: "Canal de YT", key: "canal" },
  { label: "Video Empresa", key: "video" },
  { label: "Sector 1", key: "sect1" },
  { label: "Industria 1", key: "ind1" },
  { label: "Sector 2", key: "sect2" },
  { label: "Industria 2", key: "ind2" },
  { label: "Sector 3", key: "sect3" },
  { label: "Industria 3", key: "ind3" },
  { label: "Sector 4", key: "sect4" },
  { label: "Industria 4", key: "ind4" },
  { label: "Sector 5", key: "sect5" },
  { label: "Industria 5", key: "ind5" },
  { label: "Tecnología 1", key: "tech1" },
  { label: "Subtecnología 1", key: "subtech1" },
  { label: "Tecnología 2", key: "tech2" },
  { label: "Subtecnología 2", key: "subtech2" },
  { label: "Tecnología 3", key: "tech3" },
  { label: "Subtecnología 3", key: "subtech3" },
  { label: "Tecnología 4", key: "tech4" },
  { label: "Subtecnología 4", key: "subtech4" },
  { label: "Tecnología 5", key: "tech5" },
  { label: "Subtecnología 5", key: "subtech5" },
  { label: "Inversionista 1", key: "inver1" },
  { label: "Inversionista 2", key: "inver2" },
  { label: "Inversionista 3", key: "inver3" },
  { label: "Inversionista 4", key: "inver4" },
  { label: "Inversionista 5", key: "inver5" },
  { label: "Fundador 1", key: "fund1" },
  { label: "Fundador 2", key: "fund2" },
  { label: "Fundador 3", key: "fund3" },
  { label: "Fundador 4", key: "fund4" },
  { label: "Fundador 5", key: "fund5" },
];


//Este algoritmo sustituye a los de getCleanedMarkeys y getCleanedSectors , más reutilizable y escalable
const getCleanedInfo = (arr, prop, subprop) => {
    let aux1 = arr.map((el) => {
      let aux2 = arr
        .filter((elm) => elm[prop] === el[prop])
        .map((elem) => {
          return elem[subprop];
        });
        let obj = {}
        obj[prop] = el[prop]
        obj[subprop] = aux2
      return obj;
    });
    let uniques = aux1.filter(
      (el, i, self) => i === self.findIndex((elem) => elem[prop] === el[prop])
    );
    return uniques;
  };

const getCleanedData = (arr, prop) => {
  if (arr.length != 0) {
    let newArr = arr.map((el) => {
      return el[prop];
    });
    let str = newArr.join(", ");
    return str;
  } else {
    return "";
  }
};

const getCleanedArray = (arr, prop) => {
  if (arr.length != 0) {
    let newArr = arr.map((el) => {
      return el[prop];
    });
    return newArr;
  } else {
    return [];
  }
};

const getCleanedDate = (date) => {
  let cD = new Date(date);
  let aux = cD.getFullYear() + "-" + (cD.getMonth() + 1) + "-" + cD.getDate();
  return aux;
};

const splitData = (string) => {
  if (string.length > 0) {
    return string.split(",");
  }
};

const validateExistance = (value, instead) => {
  if (value == null || value == undefined) {
    return instead
  } else {
    return value
  }
}

const row = {
  id: "",
  fecha: "",
  emp: "",
  tam: "",
  fond: "",
  ingr: "",
  val: "",
  op: "",
  sede: "",
  alzs: "",
  pag: "",
  fb: "",
  twt: "",
  ig: "",
  lin: "",
  canal: "",
  video: "",
  merca: "",
  sect1: "",
  ind1: "",
  sect2: "",
  ind2: "",
  sect3: "",
  ind3: "",
  sect4: "",
  ind4: "",
  sect5: "",
  ind5: "",
  tech1: "",
  subtech1: "",
  tech2: "",
  subtech2: "",
  tech3: "",
  subtech3: "",
  tech4: "",
  subtech4: "",
  tech5: "",
  subtech5: "",
  inver1: "",
  inver2: "",
  inver3: "",
  inver4: "",
  inver5: "",
  fund1: "",
  fund2: "",
  fund3: "",
  fund4: "",
  fund5: "",
  desc: "",
};

export const createData = (dataCSV, allCompanies) => {
  allCompanies.forEach((el) => {
    if (el != undefined) {
      let newRow = Object.create(row);
    newRow.id = el.id;
    newRow.fecha = getCleanedDate(el.fechaCreacion);
    newRow.emp = el.nombre;
    newRow.tam = el.tamaEmpresa;
    newRow.fond = validateExistance(el.fondeo, "N/D");
    newRow.ingr = validateExistance(el.ingresos, "N/D");
    newRow.val = validateExistance(el.valuacion, "N/D");
    newRow.op = el.opportunities.replace(/(\r\n|\n|\r)/gm, "");
    newRow.sede = el.sede;
    newRow.pag = el.website_url;
    newRow.alzs = getCleanedData(el.alliances, "name");
    newRow.fb = validateExistance(el.facebook_url, "N/D");
    newRow.twt = el.twitter_url;
    newRow.ig = el.instagram_url;
    newRow.lin = el.linkedin_url;
    newRow.canal = el.youtube_url;
    newRow.video = el.video_url;
    newRow.merca = getCleanedData(el.mercadosA, "name");

    //Fundadores
    let arrFounders = splitData(el.fundadores);
    for (let i = 0; i < arrFounders.length; i++) {
      const founder = arrFounders[i];
      newRow[`fund${i + 1}`] = founder;
    }

    //Inversionistas
    let arrInvestors = getCleanedArray(el.investors, "name");
    for (let i = 0; i < arrInvestors.length; i++) {
      const investor = arrInvestors[i];
      newRow[`inver${i + 1}`] = investor;
    }

    //Sectores e Industrias
    let sectores = getCleanedInfo(el.sectoresA, "sector", "industry");
    for (let i = 0; i < sectores.length; i++) {
      const sector = sectores[i];
      newRow[`sect${i + 1}`] = sector.sector;
      newRow[`ind${i + 1}`] = sector.industry.join(", ");
    }

    //Tecnologías y subtecnologías
    let techs = getCleanedInfo(el.tecnologiasA, "tech", "subtech");
    for (let i = 0; i < techs.length; i++) {
      const tech = techs[i];
      newRow[`tech${i + 1}`] = tech.tech;
      newRow[`subtech${i + 1}`] = tech.subtech.join(", ");
    }

    //Descripción con el toString forzamos a que sea string y los siguientes métodos no quiebren, replace se quitan los saltos de línea y con el substr evitamos que se traslapen las celdas.
    newRow.desc = el.descripcion
      .toString()
      .replace(/(\r\n|\n|\r)/gm, "")
      // .substr(0, 100);

    dataCSV.push(newRow);
    }  
  });

  return dataCSV
};
