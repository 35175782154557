import React from "react";
import Close from "../../../assets/img/closeFilter.svg";
import { IconButton } from "@material-ui/core";

export default function PreviewImageModal({ url, setOpen }) {
  return (
    <div className="w-100">
      <IconButton className="close-preview">
        <img
          src={Close}
          onClick={() => setOpen(false)}
          alt="PDF Icon"
          className="cursor-pointer "
          width="15px"
        />
      </IconButton>
      <img src={url} alt="img-preview" className={"img-preview"} />
    </div>
  );
}
