import React, { useEffect, useState } from 'react'
import Workbook from 'react-excel-workbook';
import { headers, createData } from '../../../../components/organisms/CardList/helpersExcel';
// import { allCompaniesData as companies} from '../../../../components/organisms/CardList/companiesData';
import Download from "../../../../assets/img/company/Download.svg";
import { IconButton } from '@material-ui/core';
import { getCompaniesReport } from '../../../../api/company';

export const ExcelSearchReport = ({
    filteredCompanies, 
    companiesBySector,
    companiesByIndustry,
    companiesByTech,
    companiesByMercado,
    companiesBySubtech,
    companiesBySede,
    companiesByAlliance,
    companiesByFounder,
    companiesByInvestor,
    setArrIds,
    companiesForPdf
}) => {
   let searchData=[{"data": "data", "Element 1": "Algo"}]

   let arrOfDatas = [
    filteredCompanies, 
    companiesBySector,
    companiesByIndustry,
    companiesByTech,
    companiesBySubtech,
    companiesByMercado,
    companiesBySede,
    companiesByAlliance,
    companiesByFounder,
    companiesByInvestor
   ]

   const [banderita, setBanderita] = useState(false);
   const [companies, setCompanies] = useState([]);

   const getDataCompanies = async ( ) => {
    try {
         let response = await getCompaniesReport(companiesForPdf);
         let dataForCompanies = settingObjects(response.data.companies);
         setCompanies(dataForCompanies);
         setBanderita(true);
    }catch (err) {
         console.log(err)
    }
}

useEffect(() => {
    getDataCompanies();
}, [])


   console.log("arrOfDatas", arrOfDatas)

// Para hacer un array con los ids de las compañias y sin repetir para usar en el EP 
   const getCompanies = () => {
       let ids = []
       arrOfDatas.forEach(type => {
            if (type != undefined) {
                type.forEach(company => {
                    ids.push(company.company_id)
                });
            }
       });
       let uniques = [... new Set(ids)]
       console.log("ID's de empresas", uniques)
       return uniques;
   }
   
   getCompanies()

   let arrayOfNames = [
       "Empresas", "Sectores", "Industrias", "Tecnologías", "Tecnologías secundarias", "Mercados", "Sedes", "Alianzas",
       "Fundadores", "Inversionistas"
   ]

   const settingObjects = (arr) => {
    let aux = arr.map((arr) => {
        return arr[0]
    });

    return aux;
}


   let aux = arrayOfNames.map( (el, i) => {
    if (arrOfDatas[i] != undefined) {
        let arrOfID = arrOfDatas[i].map(company => company.company_id)
        let companiesArr = arrOfID.map( idToLook => {
            const company = companies.find( ({id}) => id == idToLook)
            return company
        })
        console.log("this is arrOfID", arrOfID)
        // console.log("this is companies", companiesArr)
        let dataCSV = []
        createData(dataCSV, companiesArr)
        const obj = {name:el, companies:dataCSV}
        return obj
    }
   }).filter(el => el != undefined)

   console.log("arr aux", aux)

   return (
            <>
            {
                banderita && 
                    <Workbook filename="Reporte_Busqueda_Wikicid.xlsx" element={<IconButton className=""><img src={Download} /></IconButton>}>
                {
                aux.map( el => {
                    return (
                        <Workbook.Sheet data={el.companies} name={el.name}>
                            {
                                headers.map( el => <Workbook.Column label={el.label} value={el.key}/>)
                            }
                        </Workbook.Sheet>
                    )
                })
                }
            </Workbook>
            }
           </>
   )
}
