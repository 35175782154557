import React from "react";

import { Input } from "../../../atoms/Input";
import { useMediaQuery } from "@material-ui/core/";

export function InputWithLabelProfile({
  text,
  id,
  add,
  remove,
  placeholder,
  type,
  focus,
  value,
  onChange,
  classLabel,
  data,
  onClick,
  className,
  seeMoreButton,
  limit,
  name,
  warning,
  config,
  image,
  imageWidth,
  imageHeight,
  selectClass = " ",
  savedata,
  array,
  opcion,
  setIsFormChanged,
  handleClick,
  handleSelect
}) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <label
        className={` ${type === "checkboxIcon" ? "w-100" : ""}`}
        htmlFor={id}
      >
        <div className={`d-flex align-items-center mb-8`}>
          <img src={image} width={imageWidth} height={imageHeight}></img>
          <p className={` a-text-medium-first-primary-60 ${classLabel}`}>
            {text}
          </p>
        </div>
      </label>
      <Input
        className={`${className}`}
        specialclass={true}
        add={add}
        remove={remove}
        array={array}
        opcion={opcion}
        selectClass={selectClass}
        placeholder={placeholder}
        width={100}
        id={id}
        type={type}
        focus={focus}
        value={value}
        onChange={onChange}
        savedata={savedata}
        data={data}
        onClick={onClick}
        seeMoreButton={seeMoreButton}
        limit={limit}
        name={name}
        warning={warning}
        setIsFormChanged={setIsFormChanged}
        handleSelect={handleSelect}
      />
    </>
  );
}
