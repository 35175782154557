// React
import { Link } from "react-router-dom";

// Material
import { useMediaQuery, Grid } from "@material-ui/core";

// Images
import Message from "../../../assets/img/message-icon.svg";
import Notification from "../../../assets/img/notification-icon.svg";
import Logo from "../../../assets/img/logo.svg";
import Menu from "../../../assets/img/menu-icon.svg";

// Componens
import { Input } from "../../../components/atoms/Input";

export default function Header({
  isCollapsed,
  link,
  text,
  onChange,
  className,
  id,
  focus,
  onClick = null,
  value,
  onKeyDown,
  hideMessageAndNoti
}) {
  // Funciones
  const matchDesktop = useMediaQuery("(min-width: 1200px)");

  function handleFocus() {
    setTimeout(() => {
      document.getElementById("search-company-header")?.focus();
      document.getElementById("search-report-header")?.focus();
    }, 500);
  }

  return (
    <Grid container justify="center" className={`${className} `}>
      <Grid
        item
        xs={10}
        lg={12}
        className="pt-4  pb-lg-4 py-xl-4 background-lg-white"
      >
        <Grid container spacing={0}>
          <Grid item xs={12} lg={12} xl={12}>
            <div className="d-flex justify-content-between d-lg-none">
              <img src={Logo} alt="Wikicid Logo" className="menu-cid_logo" />
              <div className="d-flex">
                <img
                  src={Message}
                  alt="Message Icon"
                  className="header-icon mr-2"
                />
                <img
                  src={Notification}
                  alt="Notification Icon"
                  className="header-icon"
                />
              </div>
            </div>
            <div
              className={` mt-lg-0 ml-lg-4 d-flex align-items-center header-mt-3`}
            >
              <img
                onClick={isCollapsed}
                src={Menu}
                alt="Menú"
                className="mr-3 menu_icon cursor-pointer mr-md-4 d-lg-none"
              />
              {link ? (
                <Link
                  to={link}
                  className="w-100 text-decoration-none"
                  onClick={handleFocus}
                >
                  <Input
                    type="search3"
                    placeholder={text}
                    value={value}
                    onChange={(event) => onChange(event)}
                    width="100%"
                    focus={focus}
                  />
                </Link>
              ) : (
                <Input
                  onChange={onChange}
                  onClick={onClick}
                  type="search"
                  placeholder={text}
                  width="100%"
                  onKeyDown={onKeyDown}
                  className="search-company"
                  id={id}
                  value={value}
                  focus={focus}
                />
              )}
              <div className={`ml-3 d-none d-lg-flex header-mr`}>
                <img
                  src={Message}
                  alt="Message Icon"
                  className={`header-icon hmr ${hideMessageAndNoti ? "d-none" : ""}`}
                />
                <img
                  src={Notification}
                  alt="Notification Icon"
                  className={`header-icon  mx-xl-3 ${hideMessageAndNoti ? "d-none" : ""}`}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
