// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//API
import {
  createProfile,
  updateProfile,
  getArea,
  getFormacion,
  getUserApi,
  getProfile,
  getLanguage,
} from "../../../../api/profile";

// Material
import {
  useMediaQuery,
  Grid,
  Avatar,
  Modal,
  CircularProgress,
} from "@material-ui/core";

// Componens
import { InputWithLabelProfile } from "../../../molecules/InputWith/InputWithLabelProfile";
import { Button } from "../../../atoms/Button";
import { InputWithLabel } from "../../../molecules/InputWith/InputWithLabel";
import RouterLeavingGuard from "./RouterLeavingGuard";

// Images sólo las que se usan
import User from "../../../../assets/img/user-icon.svg";
import Birrete from "../../../../assets/img/profile/academica.svg";
import Area from "../../../../assets/img/profile/area.svg";
import Descripcion from "../../../../assets/img/profile/descripcion.svg";
import Empresa from "../../../../assets/img/profile/empresa.svg";
import Camara from "../../../../assets/img/profile/camara 1.svg";
import Idiomas from "../../../../assets/img/profile/idiomas.svg";
import Close from "../../../../assets/img/closeFilter.svg";
import FailIcon from "../../../../assets/img/fail-icon.svg";
import userIcon from '../../../../assets/img/fundador.svg';
import { lang } from "moment/moment";
import { ImageInput } from "../../../atoms/Input/ImageInput";

export default function FormProfile({ userId }) {
  //State
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [profileExists, setProfileExists] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [useA, setUseA] = useState([]);
  const [useR, setUseR] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [area, setArea] = useState([]);
  const [formacion, setFormacion] = useState([]);
  const [idiomas, setIdiomas] = useState([]);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [previewImg, setPreviewImg] = useState([]);
  const [newImage, setNewImage] = useState(false);

  const [descripcionfield, setDescripcionField] = useState(null);
  const [companyfield, setCompanyField] = useState(null);
  const [educationfield, setEducationField] = useState(0);
  const [languagefield, setLanguageField] = useState([]);
  const [areafield, setAreaField] = useState(0);

  const [selectionlanguages, setSelectionlanguages] = useState([]);

  const matchDesktop = useMediaQuery("(min-width: 1200px)");
  const matchIpadPro = useMediaQuery("(min-width: 992px)");
  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    async function getUser() {
      try {
        const { data: { user } } = await getUserApi(userId);
        setUseR(user[0])
      } catch (error) {
        const { status, data: { message } } = error;
        console.log(status, message)
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    const getProfileFromUser = async () => {
      try {
        const { data: { profile } } = await getProfile(userId);
        setProfile(profile)
        if (profile) {
          setProfileExists(true)
        }
      } catch (error) {
        const { status, data: { message } } = error;
        console.log(status, message)
      } finally {
        setLoading(false)
      }
    }
    getProfileFromUser()
  }, [])

  useEffect(() => {
    const getAreaFromUser = async () => {
      try {
        const { data: { areas } } = await getArea(userId)
        setArea(areas)
      } catch (error) {
        const { status, data: { message } } = error;
        console.log(status, message)
      }
    }
    getAreaFromUser()
  }, [])

  useEffect(() => {
    const getFormacionFromUser = async () => {
      try {
        const { data: { FA } } = await getFormacion(userId);
        setFormacion(FA)
      } catch (error) {
        const { status, data: { message } } = error;
        console.log(status, message)
      }
    }
    getFormacionFromUser()
  }, [])

  useEffect(() => {
    const getLanguageFromUser = async () => {
      try {
        const { data: { language } } = await getLanguage(userId);
        setIdiomas(language)
      } catch (error) {
        const { status, data: { message } } = error;
        console.log(status, message)
      }
    }
    getLanguageFromUser()
  }, [])

  useEffect(() => {
    if (!loading && profile) {
      setUseA(profile);
      setProfileExists(true);
      setDescripcionField(profile[0].description)
      setCompanyField(profile[0].company)
      setImages(profile[0].photo_link);
      profile[0].nameArea !== null && setAreaField(profile[0].nameArea)
      profile[0].nameAcademic !== null && setEducationField(profile[0].nameAcademic)
    }
  }, [loading])

  useEffect(() => {
    if (!loading && profile !== null) {
      if (idiomas.length > 0 && profile[0].language_id ) {
        const idiomasIds = profile[0].language_id.split(",").map(id => parseInt(id));
        const arrIdiomas = idiomasIds.map(id => idiomas.find(idioma => id === idioma.language_id));
        arrIdiomas[0] !== undefined  &&  setLanguageField(arrIdiomas);
      }
    }
  }, [loading, idiomas, profile])

  useEffect(() => {
    const arrLanguageSelect = languagefield.map(language => language.language_id)
    const languageSeleccionados = arrLanguageSelect.join(',')
    setSelectionlanguages(languageSeleccionados)
  }, [languagefield])

  const handleClickLanguage = (e,languageSelect) => {
    setIsFormChanged(true)
    const foundLanguage = languagefield.find(language => language.language_id === languageSelect.language_id);
    if (foundLanguage) {
      const arrLanguage = languagefield.filter(language => language.language_id !== foundLanguage.language_id)
      setLanguageField(arrLanguage)
    } else {
      setLanguageField([...languagefield, languageSelect])
    }
  }

  const handleSelect = (id) => {
    const isArea = area.find(areaState => areaState.name === id)
    const isAcademic = formacion.find(academicState => academicState.name === id)
    if (isArea) {
      setAreaField(isArea.name)
    } else if (isAcademic) {
      setEducationField(isAcademic.name)
    }
  }

  async function submitFormTest(e) {
    e.preventDefault();
    const userPhoto = document.getElementById('user-pic');
    var formData = new FormData();
    formData.append("userId", userId);

    if (userPhoto) {
      formData.append('photo', userPhoto.files[0])
    }

    if (descripcionfield !== null) {
      if (descripcionfield.trim() !== "" && descripcionfield !== 'undefined') {
        formData.append("description", descripcionfield.trim());
      }
    }
    if (companyfield !== null) {
      if (companyfield.trim() !== "" && companyfield !== 'undefined') {
        formData.append("company", companyfield.trim());
      }
    }

    if (areafield === 0) {
      formData.append("area", 0)
    } else {
      const { area_id } = area.find(areaState => areaState.name === areafield)
      formData.append("area", area_id);
    }

    if (educationfield === 0) {
      formData.append("academic", 0)
    } else {
      const { academic_training_id } = formacion.find(academicState => academicState.name === educationfield)
      formData.append("academic", academic_training_id);
    }

    if (languagefield.length > 0) {
      formData.append("language", selectionlanguages);
    }

    setSending(true);
    if (profileExists) {
      try {
        await updateProfile(formData);
        window.location.href = "/cid/carso/profile";
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await createProfile(formData);
        window.location.href = "/cid/carso/profile";
      } catch (error) {
        const { status, data: { message } } = error;
        console.log(status, message)
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  function deleteImg(e) {
    e.preventDefault();
    setImages(null);
    setPreviewImg(User);
    setNewImage(true);
    handleClose();
  }

  function uploadImg(e, i) {
    var fileInput = document.getElementById("profile-img");
    var filePath = fileInput.value;
    var allowedExtension = /(.jpeg|.png|jpg|.gif)$/i;
    if (!allowedExtension.exec(filePath)) {
      handleClose();
      setOpen(true);
      setModalBody(
        <>
          <div className="d-flex justify-content-center">
            <img
              src={FailIcon}
              alt="Fail Icon"
              className="mb-3 modal__icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            Intenta con un archivo con extensión .png, .jpg{" "}
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50" onClick={handleClose}>
              <Button
                type="button"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </>
      );
      fileInput.value = "";
      return false;
    } else {
      //Image preview
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(fileInput.files[0]);
        reader.onload = function (e) {
          setImages(fileInput.files[0]);
          setPreviewImg(reader.result);
          setNewImage(true);
          handleClose();
        };
      }
    }
  }

  function handleModal2(e) {
    e.preventDefault();
    setOpen2(true);
  }

  return (
    <>
      {loading ? (
        <div></div>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            className={`background-lg-white br-2 box-shadow-lg-all profile-form-vh100 ${matchIpadPro
              ? "px-4 py-4"
              : matchIpadMini
                ? "ml-11 pr-3 py-6"
                : "px-7 py-4"
              } `}
          >
            <Grid item xs={12} md={9} lg={12} xl={12} className="">
              <div
                className={`justify-content-between ${matchPhone ? "d-flex" : "d-none"
                  }`}
              >
                {" "}
                <p className={`text-medium a-text-medium-first-primary-60`}>
                  Editar perfil
                </p>{" "}
                <Link to="/cid/carso/profile">
                  <img width={20} height={20} src={Close}></img>
                </Link>{" "}
              </div>
              <hr
                className={`b-none my-3 hr-h-1 bcolor-gray ${matchPhone ? " " : "d-none"
                  } `}
              ></hr>
              <div
                className={`d-flex mb-1 ${matchPhone
                  ? "align-items-center"
                  : matchIpadPro
                    ? "mt-1"
                    : " "
                  }`}
              >
                <div className={`position-relative`}>
                  {/* <Avatar
                    id={`profile-pic`}
                    alt="user"
                    src={User}
                    />
                    <img
                    className={"position-absolute camera-position"}
                    onClick={handleModal2}
                    width={matchPhone ? 22 : 35}
                    height={matchPhone ? 22 : 35}
                    src={Camara}
                    ></img> */}
                  <ImageInput
                    id={'user-pic'}
                    className={`opacity-3 profile-pic input_file`}
                    value={Camara}
                    Icon={userIcon}
                    originalSource={useR.photo_link}
                    style = {{width: 75, height: 75}}
					        />
                </div>
                <p
                  className={`a-text-medium-first-primary-60 ${matchPhone ? "ml-3" : "ml-9 h3 mt-9"
                    }`}
                >
                  {useR.name} {useR.lastname}
                </p>{" "}
                {matchPhone ? (
                  <></>
                ) : (
                  <Link className={`ml-auto mt-9`} to="/cid/carso/profile">
                    <img
                      width={matchIpadPro ? 26 : 24}
                      height={matchIpadPro ? 26 : 24}
                      src={Close}
                    ></img>
                  </Link>
                )}{" "}
              </div>
              <form className={``} onSubmitCapture={submitFormTest}>
                <div className={`mt-6 mr-41`}>
                  <InputWithLabelProfile
                    text="Presentación"
                    className={"input-profile"}
                    id="descripcion"
                    placeholder="Ingresa una breve presentación"
                    type="text"
                    focus={true}
                    classLabel={` a-text-medium-second-neutral-90 text-medium ml-2`}
                    value={descripcionfield !== "undefined" ? descripcionfield : ''}
                    onChange={(e) => (
                      setDescripcionField(e.target.value),
                      setIsFormChanged(e.target.value.length > 0)
                    )}
                    warning={""}
                    image={Descripcion}
                    imageWidth={24}
                    imageHeight={24}
                  />
                </div>

                <div className={`mt-6 mr-41`}>
                  <InputWithLabelProfile
                    text="Empresa"
                    className={"input-profile"}
                    id="empresa"
                    placeholder="Ingresa el nombre de la empresa"
                    type="text"
                    focus={false}
                    classLabel={` a-text-medium-second-neutral-90 text-medium ml-2`}
                    value={companyfield !== "undefined" ? companyfield : ''}
                    onChange={(e) => (
                      setCompanyField(e.target.value),
                      setIsFormChanged(e.target.value.length > 0)
                    )}
                    warning={""}
                    image={Empresa}
                    imageWidth={24}
                    imageHeight={24}
                  />
                </div>

                <div className={`mt-6 mr-41`}>
                  <InputWithLabelProfile
                    text="Área"
                    id="area"
                    placeholder="Ingresa el área en la que trabajas"
                    type="select3"
                    data={area}
                    setIsFormChanged={setIsFormChanged}
                    focus={false}
                    className={`input-profile-lg input-profile`}
                    classLabel={` a-text-medium-second-neutral-90 text-medium ml-2`}
                    selectClass={"select-list"}
                    savedata={areafield !== "" ? areafield : null}
                    value={areafield !== "" ? areafield : null}
                    handleSelect={handleSelect}
                    warning={""}
                    image={Area}
                    imageWidth={24}
                    imageHeight={24}
                  />
                </div>

                <div className={`mt-6 mr-41`}>
                  <InputWithLabelProfile
                    text="Formación académica"
                    id="formacion-academica"
                    placeholder="Ingresa tu formación académica"
                    type="select3"
                    setIsFormChanged={setIsFormChanged}
                    data={formacion}
                    focus={false}
                    className={`input-profile-lg input-profile`}
                    classLabel={` a-text-medium-second-neutral-90 text-medium ml-2`}
                    selectClass={"select-list"}
                    savedata={educationfield !== "" ? educationfield : null}
                    value={educationfield !== "" ? educationfield : null}
                    warning={""}
                    image={Birrete}
                    imageWidth={24}
                    imageHeight={24}
                    handleSelect={handleSelect}
                  />
                </div>

                <div className={`mt-6 mr-41`}>
                  <InputWithLabelProfile
                    seeMoreButton={false}
                    text="Idiomas"
                    id="idiomas"
                    placeholder="Selecciona tus idiomas"
                    type="checkbox-sm"
                    limit={4}
                    opcion="checkbox"
                    add={(arr) => setLanguageField(arr)}
                    remove={(arr) => setLanguageField(arr)}
                    data={idiomas}
                    value={languagefield.length > 0 ? languagefield : []}
                    focus={false}
                    className={`btn-idiomas`}
                    classLabel={` a-text-medium-second-neutral-90 text-medium ml-2`}
                    selectClass={"select-list"}
                    savedata={languagefield !== undefined ? languagefield : []}
                    onChange={handleClickLanguage}
                    warning={""}
                    image={Idiomas}
                    imageWidth={24}
                    imageHeight={24}
                  />
                </div>

                <div className={`mt-7`}>
                  <Button
                    type="submit"
                    classes={`text-small a-button-blue-1 a-text-medium ${sending ? "disabled" : ""
                      }`}
                    height={32}
                    width={152}
                    text={sending ? <CircularProgress /> : "Guardar"}
                  />
                </div>
              </form>
            </Grid>
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modal">{modalBody}</div>
          </Modal>

          {/* <Modal
            open={open2}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className="modal-pic">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Avatar
                  id={`profile-pic`}
                  className={`profile-pic`}
                  alt="user"

                />
                <p
                  className={`a-text-medium-second-neutral-60 w-100 text-center pt-3 ${matchIpadMini ? "h3 pb-6" : "text-normal mb-3"
                    }`}
                >
                  Tu foto de perfil
                </p>
                <div className="d-flex">
                  <Button
                    onclick={deleteImg}
                    type="button"
                    classes={`a-text-medium-first-primary-60 a-button-topic-gray-2 ${matchIpadMini ? "text-normal" : "text-small"
                      }`}
                    height={33}
                    width={matchIpadMini ? 147 : 98}
                    text="Eliminar"
                  />
                  <InputWithLabel
                    className={`ml-4 ${matchIpadMini ? "text-normal" : "text-small"
                      }`}
                    onClick={handleModal2}
                    id="profile-img"
                    type="profile-pic"
                    width={155}
                    Location={true}
                    onChange={(e) => {
                      uploadImg(e, 0);
                      setIsFormChanged(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal> */}

        </>)}
    </>
  );
}
