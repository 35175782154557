/** Consumo de API, para verificación del token, y/o duración de la sesión  en WIKICID,
 * Así como la validación de su termino y actualización
 */
import { BASE_PATH } from './config';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

export function getAccessToken() {
	const accessToken = localStorage.getItem('accessToken');
	if (!accessToken || accessToken === 'null') {
		return null;
	}
	return willExpireToken(accessToken) ? null : accessToken;
}

export function getRefreshToken() {
	const refreshToken = localStorage.getItem('refreshToken');
	if (!refreshToken || refreshToken === 'null') {
		return null;
	}
	return willExpireToken(refreshToken) ? null : refreshToken;
}

export function logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem('viewType')
    localStorage.removeItem('categorias')
}

export function refreshAccessToken(token) {
	return axios
		.post(`${BASE_PATH}/refresh-access-token`, {
			refreshToken: token,
		})
		.then((result) => {
			return result;
		})
		.catch((err) => {
			return err;
		});
}

function willExpireToken(token) {
	const seconds = 60;
	const metaToken = jwtDecode(token);
	const { exp } = metaToken;
	const now = (Date.now() + seconds) / 1000;
	return now > exp;
}


