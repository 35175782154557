//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";
export function SkeletonVerticalBlog({ num }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  
  return arraySkeleton.map((r, indx) => (
    <div style={{minWidth: "230px"}} className={`${ipadMiniMatch ? "d-flex mx-3 card-vertical-blog-home box-shadow-all br-b-3 br-t-3" :  "" }`} key={indx}>
      {/* <div className="card mb-3 h-auto d-flex flex-column    align-items-center box-shadow-lg-all w-100"> */}
      <div className="mr-2 ml-2 " style={{minWidth: "230px"}}>
        <div className="d-flex  w-90 pt-3">
          <Skeleton
            variant="circle"
            width="25px"
            height="25px"
            className="mr-3"
            animation="wave"
          />
          <Skeleton variant="rect" width="50%" animation="wave" />
        </div>
        <div className="mt-3 d-flex align-items-center justify-content-center w-100 pt-2">
          <div className="d-flex flex-column w-100 justify-content-center">
            <Skeleton
              variant="rect"
              width="100%"
              animation="wave"
              className="mb-2"
            />
            <Skeleton
              variant="rect"
              width="100%"
              animation="wave"
              className="mb-2"
            />
            <Skeleton
              variant="rect"
              width="100%"
              animation="wave"
              className="mb-2"
            />
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-center">
          <Skeleton variant="rect" width={120} height={100} animation="wave" className="justify-content-between  align-items-center br-1" />
        </div>
      </div>
    </div>
  ));
}
