import React from 'react';
import Users from '../../../components/organisms/UserList/UserList';
import { Redirect } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

export default function UserList(e) {
	const data = e.location.state || { type: 0, value: false };
	const user = useAuth();
	if (!user) {
		return <Redirect to='/admin/' />;
	}
	if (user.rolId !== 1) {
		if (user.rolId !== 2) {
			return <Redirect to='/cid/carso/home' />;
		}
		return <Redirect to='/cid' />;
	}
	if (e.match.params.id != undefined) {
		if (e.match.params.id === 0) return <Redirect to='/admin/users' />;
	}

	return (
		<Users
			modalValue={data}
			isCollapsed={e.isCollapsed}
		/>
	);
}
