import React, { useEffect } from 'react'
import Workbook from 'react-excel-workbook';
import { headers } from './helpersExcel'

// const ExcelFile = ExportExcel.ExcelFile
// const ExcelSheet = ExportExcel.ExcelSheet
// const ExcelColumn = ExportExcel.ExcelColumn

export const ExcelFileCreator = ({dataCompanies}) => {

  useEffect(() => {
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    function simulateMouseClick(element){
    mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    )
  );
  }

  let element = document.querySelector('#reporte');
  simulateMouseClick(element);
  }, [])

  
//   return (
//     <div>
//         <ExcelFile hideElement="true" filename="Reporte_Empresas_Wikicid">
//             <ExcelSheet data={dataCompanies} name="Empresas">
//                 {
//                     headers.map( el => <ExcelColumn label={el.label} value={el.key}/>)
//                 }
//             </ExcelSheet>
//         </ExcelFile>
//     </div>
//   )
    return (
        <div className="d-none">
            <Workbook filename="Reporte_Empresas_Wikicid.xlsx" element={<button id="reporte">Descargar reporte</button>}>
                <Workbook.Sheet data={dataCompanies} name="Empresas">
                    {
                        headers.map( el => <Workbook.Column label={el.label} value={el.key}/>)
                    }
                </Workbook.Sheet>
            </Workbook>
        </div>
    )
}


