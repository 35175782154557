import { useReducer } from 'react';
const ACTIONS = {
	LOADING: 'loading',
	CHECK_BOX_SECTOR: 'check_box_sector',
	CHECK_BOX_CLIENT: 'check_box_client',
	CHECK_BOX_MARKET: 'check_box_market',
	BUTTON_SECTOR: 'button_sector',
	BUTTON_MARKET: 'button_market',
	OPEN: 'open',
	MODAL_BODY: 'modal_body',
	STORE_FILE: 'store_file',
	STORE_NAME_FILE: 'store_name_file',
	REPORT: 'report',
	CLIENT: 'client',
	SECTOR: 'sector',
	MARKET: 'market',
	SECTOR_UPDATE: 'sector_update',
	MARKET_UPDATE: 'market_update',
	CLIENT_UPDATE: 'client_update',
	STATUS_BSECTOR: 'status_bsector',
	STATUS_BMARKET: 'status_bmarket',
	STATUS_BCLIENT: 'status_bclient',
	STORE_IMAGES: 'store_images',
	STORE_IMAGES_NEW: 'store_images_new',
	STORE_PROGRESS: 'store_progress',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.LOADING:
			return { ...state, isLoading: action.payload };
		case ACTIONS.CHECK_BOX_SECTOR:
			return { ...state, checkboxSector: action.payload };
		case ACTIONS.CHECK_BOX_CLIENT:
			return { ...state, checkboxClient: action.payload };
		case ACTIONS.CHECK_BOX_MARKET:
			return { ...state, checkboxMarket: action.payload };
		case ACTIONS.BUTTON_SECTOR:
			return { ...state, buttonSector: action.payload };
		case ACTIONS.BUTTON_MARKET:
			return { ...state, buttonMarket: action.payload };
		case ACTIONS.OPEN:
			return { ...state, open: action.payload };
		case ACTIONS.MODAL_BODY:
			return { ...state, modalBody: action.payload };
		case ACTIONS.STORE_FILE:
			return { ...state, file: action.payload };
		case ACTIONS.STORE_NAME_FILE:
			return { ...state, nameFile: action.payload };
		case ACTIONS.REPORT:
			return { ...state, report: action.payload };
		case ACTIONS.STORE_IMAGES_NEW:
			return { ...state, imagesNew: action.payload };
		case ACTIONS.STORE_IMAGES:
			return { ...state, images: action.payload };
		case ACTIONS.CLIENT:
			return { ...state, client: action.payload };
		case ACTIONS.SECTOR:
			return { ...state, sector: action.payload };
		case ACTIONS.MARKET:
			return { ...state, market: action.payload };
		case ACTIONS.SECTOR_UPDATE:
			return { ...state, checkSectorUpdate: action.payload };
		case ACTIONS.MARKET_UPDATE:
			return { ...state, checkMarketUpdate: action.payload };
		case ACTIONS.CLIENT_UPDATE:
			return { ...state, checkClientUpdate: action.payload };
		case ACTIONS.STATUS_BSECTOR:
			return { ...state, statusButtonSector: action.payload };
		case ACTIONS.STATUS_BMARKET:
			return { ...state, statusButtonMarket: action.payload };
		case ACTIONS.STATUS_BCLIENT:
			return { ...state, statusButtonClient: action.payload };
		case ACTIONS.STORE_PROGRESS:
			return { ...state, progress: action.payload };
		default:
			return state;
	}
};
export default function useFormUpdateReport({
	initialLoading,
	initialCheckboxSector,
	initialCheckboxClient,
	initialCheckboxMarket,
	initialButtonSector,
	initialButtonMarket,
	initialOpenState,
	initialModalBody,
	initialFile,
	initialnameFile,
	initialreport,
	initialclient,
	initialsector,
	initialmarket,
	initialcheckSectorUpdate,
	initialcheckMarketUpdate,
	initialcheckClientUpdate,
	initialstatusButtonSector,
	initialstatusButtonMarket,
	initialstatusButtonClient,
	initialimages,
	initialimagesNew,
	initialProgress,
}) {
	const [state, dispach] = useReducer(reducer, {
		isLoading: initialLoading,
		checkboxSector: initialCheckboxSector,
		checkboxClient: initialCheckboxClient,
		checkboxMarket: initialCheckboxMarket,
		buttonSector: initialButtonSector,
		buttonMarket: initialButtonMarket,
		open: initialOpenState,
		modalBody: initialModalBody,
		file: initialFile,
		nameFile: initialnameFile,
		report: initialreport,
		client: initialclient,
		sector: initialsector,
		market: initialmarket,
		checkSectorUpdate: initialcheckSectorUpdate,
		checkMarketUpdate: initialcheckMarketUpdate,
		checkClientUpdate: initialcheckClientUpdate,
		statusButtonSector: initialstatusButtonSector,
		statusButtonMarket: initialstatusButtonMarket,
		statusButtonClient: initialstatusButtonClient,
		images: initialimages,
		imagesNew: initialimagesNew,
		progress: initialProgress,
	});
	const {
		isLoading,
		checkboxSector,
		checkboxClient,
		checkboxMarket,
		buttonSector,
		buttonMarket,
		open,
		modalBody,
		file,
		nameFile,
		report,
		client,
		sector,
		market,
		checkSectorUpdate,
		checkMarketUpdate,
		checkClientUpdate,
		statusButtonSector,
		statusButtonMarket,
		statusButtonClient,
		images,
		imagesNew,
		progress,
	} = state;

	return {
		isLoading,
		checkboxSector,
		checkboxClient,
		checkboxMarket,
		buttonSector,
		buttonMarket,
		open,
		modalBody,
		file,
		nameFile,
		report,
		client,
		sector,
		market,
		checkSectorUpdate,
		checkMarketUpdate,
		checkClientUpdate,
		statusButtonSector,
		statusButtonMarket,
		statusButtonClient,
		images,
		imagesNew,
		progress,
		setIsLoading: (isLoading) => dispach({ type: ACTIONS.LOADING, payload: isLoading }),
		setCheckboxSector: (checkboxSector) => dispach({ type: ACTIONS.CHECK_BOX_SECTOR, payload: checkboxSector }),
		setCheckboxClient: (checkboxClient) => dispach({ type: ACTIONS.CHECK_BOX_CLIENT, payload: checkboxClient }),
		setCheckboxMarket: (checkboxMarket) => dispach({ type: ACTIONS.CHECK_BOX_MARKET, payload: checkboxMarket }),
		setButtonSector: (buttonSector) => dispach({ type: ACTIONS.BUTTON_SECTOR, payload: buttonSector }),
		setButtonMarket: (buttonMarket) => dispach({ type: ACTIONS.BUTTON_MARKET, payload: buttonMarket }),
		setOpen: (open) => dispach({ type: ACTIONS.OPEN, payload: open }),
		setModalBody: (modalBody) =>
			dispach({
				type: ACTIONS.MODAL_BODY,
				payload: modalBody,
			}),
		setFile: (file) => dispach({ type: ACTIONS.STORE_FILE, payload: file }),
		setNameFile: (nameFile) => dispach({ type: ACTIONS.STORE_NAME_FILE, payload: nameFile }),
		setReport: (report) => dispach({ type: ACTIONS.REPORT, payload: report }),
		setClient: (client) => dispach({ type: ACTIONS.CLIENT, payload: client }),
		setSector: (sector) => dispach({ type: ACTIONS.SECTOR, payload: sector }),
		setMarket: (market) => dispach({ type: ACTIONS.MARKET, payload: market }),
		setCheckSectorUpdate: (checkSectorUpdate) =>
			dispach({ type: ACTIONS.SECTOR_UPDATE, payload: checkSectorUpdate }),
		setCheckMarketUpdate: (checkMarketUpdate) =>
			dispach({ type: ACTIONS.MARKET_UPDATE, payload: checkMarketUpdate }),
		setCheckClientUpdate: (checkClientUpdate) =>
			dispach({ type: ACTIONS.CLIENT_UPDATE, payload: checkClientUpdate }),
		setStatusButtonSector: (statusButtonSector) =>
			dispach({ type: ACTIONS.STATUS_BSECTOR, payload: statusButtonSector }),
		setStatusButtonMarket: (statusButtonMarket) =>
			dispach({ type: ACTIONS.STATUS_BMARKET, payload: statusButtonMarket }),
		setStatusButtonClient: (statusButtonClient) =>
			dispach({ type: ACTIONS.STATUS_BCLIENT, payload: statusButtonClient }),
		setImages: (images) => dispach({ type: ACTIONS.STORE_IMAGES, payload: images }),
		setImagesNew: (imagesNew) => dispach({ type: ACTIONS.STORE_IMAGES_NEW, payload: imagesNew }),
		setProgress: (progress) => dispach({ type: ACTIONS.STORE_PROGRESS, payload: progress }),
	};
}
