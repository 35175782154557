import React, { useState } from "react";
import { useMediaQuery, Grid } from "@material-ui/core";
import { PodcastyUserList } from "../PodcastList/PodcastByUserList";

// Components
export function PodcastMenu({ page, setPage, userId, viewType }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const categories = ["publicados", "guardados"];

  const [myPodcasts, setMyPodcasts] = useState(true);
  const [mySavedPodcasts, setMySavedPodcasts] = useState(false);
  const [category, setCategory] = useState(categories[0]);

  return (
    <Grid xs={12} className="w-100  ">
      <div className="d-flex mt-3  align-items-center scroll-overflow      w-100">
        <div
          onClick={() => {
            setMyPodcasts(true);
            setMySavedPodcasts(false);
            setCategory(categories[0]);
          }}
          className={` ${
            ipadMiniMatch ? " " : ""
          } cursor-pointer text-small text-lg-normal  text-md-medium mr-2 mr-md-3 text-center br-4 px-2 py-1 py-md-2  ${
            myPodcasts ? "switch-question-active" : "switch-question"
          } px-md-4 py-lg-1 h-a`}
        >
          Publicados{" "}
        </div>

        <div
          onClick={() => {
            setMySavedPodcasts(true);
            setMyPodcasts(false)
            setCategory(categories[1]);
          }}
          className={` ${
            ipadMiniMatch ? " " : ""
          } text-small text-md-medium cursor-pointer mr-md-3 mr-2  text-center br-4 text-lg-normal px-2 py-1 py-md-2  ${
            mySavedPodcasts ? "switch-question-active" : "switch-question"
          } px-md-4 h-a py-lg-1`}
        >
          Guardado
        </div>
      </div>
      {
        myPodcasts ? (<PodcastyUserList category={category}/>) :(<div/>)
      }
      {
        mySavedPodcasts? (<PodcastyUserList category={category}/>) :(<div/>)
      }
    </Grid>
  );
}
