/**Consumo de endpoint para la busqueda de las empresas. */

import { BASE_PATH } from './config';
import axios from 'axios';

//Para la busqueda
export function win({ data }) {
	return axios.get(`${BASE_PATH}/search/by-companies?userId=${data !== '' ? `&companies=${data}` : ''}`);
}

export function SearchCompaniesBySede(userId, sedes) {
	return axios.get(`${BASE_PATH}/search/by-sede?userId=${userId}${sedes !== '' ? `&sede=${sedes}` : ''}`);
}

export function SearchCompaniesBySector(userId, sectors) {
	return axios.get(`${BASE_PATH}/search/by-sector?userId=${userId}${sectors !== '' ? `&sector=${sectors}` : ''}`);
}

export function SearchCompaniesByIndustry(userId, industry) {
	return axios.get(
		`${BASE_PATH}/search/by-industry?userId=${userId}${industry !== '' ? `&industry=${industry}` : ''}`
	);
}

export function SearchCompaniesBySubtech(userId, subtech) {
	return axios.get(`${BASE_PATH}/search/by-subtech?userId=${userId}${subtech !== '' ? `&subtech=${subtech}` : ''}`);
}

export function SearchCompaniesByMercado(userId, mercado) {
	return axios.get(`${BASE_PATH}/search/by-mercado?userId=${userId}${mercado !== '' ? `&mercado=${mercado}` : ''}`);
}

export function SearchCompaniesByAlliance(userId, sectors) {
	return axios.get(`${BASE_PATH}/search/by-alliance?userId=${userId}${sectors !== '' ? `&alliance=${sectors}` : ''}`);
}

export function searchCompaniesByMarkets(userId, markets) {
	return axios.get(`${BASE_PATH}/search/by-market?userId=${userId}${markets ? `&market=${markets}` : ''}`);
}

export function searchCompaniesByInvestors(userId, investors) {
	return axios.get(`${BASE_PATH}/search/by-investor?userId=${userId}${investors ? `&investor=${investors}` : ''}`);
}

export function searchCompaniesByFounder(userId, founder) {
	return axios.get(`${BASE_PATH}/search/by-founder?userId=${userId}${founder ? `&founder=${founder}` : ''}`);
}

//Para el filtrado interno de las empresas
export function getCompaniesFilterApi(userId, sector, market, orderByDate, orderBy) {
	let sectorString = '';
	let marketString = '';
	let orderByDateString = '';
	let orderByString = '';
	// if (sector !== "") {
	//     sectorString = `&sectorId=(${sector})`
	// }

	let arrayMarketId = [];
	let arraySectorId = [];

	if (sector.length !== 0) {
		sector.map((elm) => arraySectorId.push(elm.id));
		sectorString = `&sectorId=(${arraySectorId.join(',')})`;
	}

	if (orderByDate !== 0) {
		orderByDateString = '&orderDate=1';
	}

	if (orderBy !== 0) {
		orderByString = '&orderA=1';
	}

	if (market.length > 0) {
		market.map((elm) => arrayMarketId.push(elm.id));
		marketString = `&marketId=(${arrayMarketId.join(',')})`;
	}

	// if (market.length !== 0) {
	//     marketString = `&marketId=(${market.join(',')})`
	// }

	// if (market !== "") {
	//     marketString = `&marketId=(${market})`
	// }

	return axios
		.get(
			`${BASE_PATH}/get-companies-filter?userId=${userId}${sectorString}${marketString}${orderByDateString}${orderByString}`
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
}

export function getCompaniesByMercado(userId, mercado) {
	let mercadoString = '';
	let arrayMercadoId = [];

	if (mercado.length > 0) {
		mercado.map((elm) => arrayMercadoId.push(elm.id));
		mercadoString = `&mercadoId=(${arrayMercadoId.join(',')})`;
	}

	return axios.get(`${BASE_PATH}/get-companies-by-mercado?userId=${userId}${mercadoString}`);
}

export function getCompaniesByIndustry(userId, industry) {
	let industryString = '';
	let arrayIndustryId = [];

	if (industry.length > 0) {
		industry.map((elm) => arrayIndustryId.push(elm.id));
		industryString = `&industryId=(${arrayIndustryId.join(',')})`;
	}

	return axios.get(`${BASE_PATH}/get-companies-by-industry?userId=${userId}${industryString}`);
}

export function getCompaniesBySubtech(userId, subtech) {
	let subtechString = '';
	let arraySubtechId = [];

	if (subtech.length > 0) {
		subtech.map((elm) => arraySubtechId.push(elm.id));
		subtechString = `&subtechId=(${arraySubtechId.join(',')})`;
	}

	return axios.get(`${BASE_PATH}/get-companies-by-subtech?userId=${userId}${subtechString}`);
}

/*export function getCompaniesBySedeApi(userId, sede) {
    return axios.get(`${BASE_PATH}/get-companies-by-sede?userId=${userId}&sede=${sede}`)
}*/
