// Este componente sólo es para el AB Testing
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Grid,
  Modal,
  useMediaQuery,
  IconButton,
  createMuiTheme,
  Tooltip,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import Header from "../../../../components/molecules/Header";
import QuestionInput from "../../../../components/molecules/QuestionInput/index";

import Close from "../../../../assets/img/closeFilter.svg";

import SuccessIcon from "../../../../assets//img/sucess-icon.svg";

import { useAuth } from "../../../../hooks/useAuth";
import CreateQuestion from "../../../../components/molecules/Modal/createQuestion";

import { getTopicsByUserId } from "../../../../api/questions";

import MenuTabUser from "../../../../components/molecules/TabMenu/index";

import MenuUserQuestios from "../../../../components/organisms/MenuQuestionsUser/index";
// Componens
import { QuestionList } from "../../../../components/organisms/QuestionList";
import { FormAddQuestionTopic } from "../../../../components/organisms/FormAddQuestionTopic/index";

import AlertImg from "../../../../assets/img/questions/alert2.svg";

export default function CompanyList(e) {
  const user = useAuth();
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  const location = useLocation()
  const [isActiveList, setIsActiveList] = useState(false);
  const [isActiveActivi, setIsActiveActivi] = useState(true);

  const [configActive, setConfigActive] = useState(false);
  const [modalIpadPro, setModalIpadPro] = useState(false);

  const [userSelected, setUserSelected] = useState({
    nameUser: "",
    user_id: 0,
  });
  const [openImages, setOpenImages] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [isvisibleConfirm, setIsvisibleConfirm] = useState(false);
  const { userId } = user;
  const [open, setOpen] = useState(false);
  const [initialText, setInitialText] = useState("");

  const [storeTopics, setStoreTopics] = useState([]);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const ipadProUse = useMediaQuery("(min-width:992px)");

  const [isVisible, setIsvisible] = useState(false);
  const [changeTopics, setChangeTopics] = useState(false);

  useEffect(() => {
    const obtenerTopicsUser = async () => {
      try {
        const { data: { topics: { topics } } } = await getTopicsByUserId(userId);
        setStoreTopics(topics)
      } catch (error) {
        console.log(error)
      }
    }
    obtenerTopicsUser()
  }, [changeTopics]);

  if (!user) {
    return <Redirect to="/" />;
  }


  function handleFocus() {
    setModalIpadPro(true);
  }

  function alertView(params) {
    if (params) {
      setIsvisible(true);
      setTimeout(() => {
        setIsvisible(false);
      }, 3000);
    }
  }
  function confimrView(params) {
    if (params) {
      setIsvisibleConfirm(true);
      setTimeout(() => {
        setIsvisibleConfirm(false);
      }, 3000);
    } else {
      setIsvisibleConfirm(false);
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode == 8) {
      return;
    }
    setModalIpadPro(!modalIpadPro);
  };

  function handleOpenImage(photo) {
    setOpenImages(!openImages);

    setModalImage(
      <>
        <div className={`d-flex w-100 flex-row-ms-reverse mb-5 `}>
          <IconButton>
            <img
              src={Close}
              onClick={() => setOpenImages(false)}
              alt="PDF Icon"
              className="cursor-pointer "
              width="24px"
            />
          </IconButton>
        </div>

        <div className="mt-4">
          <img alt="image questionsd" src={photo} className="img-show"></img>
        </div>
      </>
    );
  }

  return (
    <Grid container justify="center" spacing={0} className="">
      <Header
        isCollapsed={e.isCollapsed}
        link="/cid/analysis"
        text="Buscar pregunta"
      />
      <Grid
        item
        xs={10}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2"
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ">
          <Grid xs={10} lg={12}>
            <div className={` w-100  ${iPadProMatch ? "mt-0  " : "  "} `}>
              {" "}
              <p
                className={`mt-4 ${ipadMiniMatch
                    ? `h3   mb-md-4  ${iPadProMatch ? "mt-0 mb-md-4  " : "mt-6 mb-md-4  "
                    }`
                    : ""
                  }  a-text-medium-second-neutral-70  `}
              >
                Sección de preguntas
              </p>{" "}
            </div>
          </Grid>

          <Grid xs={12} lg={12}>
            <div className="mb-6 mt-2" onClick={handleFocus}>
              <QuestionInput
                placeholder={"Pregunta a la comunidad"}
                onChange={(event) => {
                  setInitialText(event.target.value);
                  setModalIpadPro(!modalIpadPro);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </Grid>
          <Grid xs={12} lg={12} className="mb-3">
            <MenuTabUser
              optone={isActiveList}
              opttwo={isActiveList}
              optthird={isActiveActivi}
              optfourth={configActive}
              setOptOne={setIsActiveList}
              setOptTwo={setIsActiveList}
              setOptThird={setIsActiveActivi}
              setOptFourth={setConfigActive}
            />
          </Grid>

          {configActive ? (
            <div className="d-flex  justify-content-center  align-items-center ">
              <FormAddQuestionTopic
                userId={userId}
                page={isActiveList}
                config={setConfigActive}
                state={setIsActiveList}
                text="Puedes agregar o quitar los temas que sean de tu interés"
                type={false}
                storeTopics={storeTopics}
                setChangeTopics={setChangeTopics}
              />
            </div>
          ) : isActiveList ? (
            <QuestionList userId={userId} isCollapsed={e.isCollapsed} />
          ) : (
            <MenuUserQuestios
              page={isActiveActivi}
              setPage={setIsActiveActivi}
              userId={userId}
            />
          )}
        </div>
      </Grid>
      <Modal
        open={modalIpadPro}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${ipadProUse
              ? " modal-md-width modal-desk d-flex flex-column "
              : "modal-create d-flex flex-column py-3  "
            }`}
        >
          <CreateQuestion
            setOpen={setModalIpadPro}
            handleOpenImage={handleOpenImage}
            setModalBody={setModalBody}
            setOpenTopics={setOpen}
            openTopics={open}
            controlImage={alertView}
            SuccessAsignment={confimrView}
            setUserSelected={setUserSelected}
            userSelected={userSelected}
            initialText={initialText}
          />
          <div
            className={`${isVisible
                ? "alert-modal d-flex justify-content-center align-items-center"
                : "  d-none"
              }`}
          >
            <img src={AlertImg} className="mr-4" />
            <p className="h3  a-text-regular-first-neutral-100"></p>
          </div>
          <div
            className={`${isvisibleConfirm
                ? "alert-modal d-flex justify-content-center align-items-center mt-2"
                : "  d-none"
              }`}
          >
            <img src={SuccessIcon} className="mr-4" />
            <p className="text-big  a-text-regular-second-neutral-90">
              La pregunta fue asignada a:{" "}
              <span className="a-text-regular-first-primary-60 ">
                {userSelected.nameUser}
              </span>
            </p>
          </div>
        </div>
      </Modal>
    </Grid>
  );
}
