// React
import { useState } from "react";

// Material
import { Popper } from "@material-ui/core";

// Components
import { InputWithLabel } from "../InputWithLabel";
import { Input } from "../../../atoms/Input";
import Calendar from "../../../atoms/Calendar";

export default function InputWithCalendar({
  width,
  text,
  id,
  placeholder,
  classLabel,
  onClick,
  value,
}) {
  // State
  const [popper, setPopper] = useState(null);

  // Variables
  const isOpenPopper = Boolean(popper);

  // Funciones
  function handleDate(d, m, y) {
    onClick(d, m, y);
    setPopper(null);
  }

  return (
    <>
      {text ? (
        <InputWithLabel
          width={width}
          text={text}
          id={id}
          placeholder={placeholder}
          type="date"
          classLabel={classLabel}
          onClick={(e) => setPopper(popper ? null : e.currentTarget)}
          value={value}
        />
      ) : (
        <Input
          width={width}
          id={id}
          placeholder={placeholder}
          type="date"
          onClick={(e) => setPopper(popper ? null : e.currentTarget)}
          value={value}
        />
      )}
      <div
        className={isOpenPopper ? "popper" : "d-none"}
        onClick={() => setPopper(null)}
      ></div>
      <Popper open={isOpenPopper} anchorEl={popper}>
        <Calendar
          onClick={(d, m, y) =>
            handleDate(d < 10 ? "0" + d : d, m < 10 ? "0" + m : m, y)
          }
        />
      </Popper>
    </>
  );
}
