//React
import React from "react"
// Images
import blog from "../../../../assets/img/blogs/buho-blog.svg";
import { useMediaQuery } from "@material-ui/core/";

export default function EmptyStateBlog({ name }) {
  const ipadProMatch = useMediaQuery("(min-width: 992px)");
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  return (
    <div className=" mt-3 d-flex flex-column ">
      {ipadMiniMatch ? (
        <p className="a-text-medium-first-primary-70 mb-3 h3">
          ¡Hola!, {`${name}`}. Aún no tenemos publicaciones.
        </p>
      ) : (
        <p className="a-text-medium-first-primary-70 text-small h3-md mb-4">
          Aún no tenemos publicaciones
        </p>
      )}
      {ipadProMatch ? (
        <p className="a-text-regular-first-primary-70 mb-2 h3">
          Anímate a escribir en el blog de WikiCID.
        </p>
      ) : (
        <p className="a-text-regular-second-neutral-90 text-small text-md-big mb-md-5 ">
          La opción para escribir está disponible para iPad Pro y escritorio.
        </p>
      )}
      <div className="d-flex h-100Full justify-content-end mt-md-5">
        <img src={blog} className="mt-5  d-flex mx-auto w-500 h1-500" />
      </div>
    </div>
  );
}
