import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  useMediaQuery,
  createMuiTheme,
  Tooltip,
  Popover,
  TextField,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// React
import { Link } from "react-router-dom";

import { Input } from "../../atoms/Input";

import { Button } from "../../atoms/Button";
import Calendar from "../../atoms/Calendar";
import Time from "../../atoms/Time/ModalComponent";

import { useAuth } from "../../../hooks/useAuth";
import { getClients } from "../../../api/report";
import { createBlog, getLabelsForBlogs } from "../../../api/blogs";

import Close from "../../../assets/img/closeFilter.svg";

const valueDefault = [
  {
    client_id: 34,
    name: "Centro de Inteligencia e Innovación Digital",
    logo_client:
      "https://firebasestorage.googleapis.com/v0/b/images-wikicid.appspot.com/o/reportes%2Fclientes%2FCID_V.png?alt=media&token=933e59ef-bbd6-4f73-83b8-502660f0f195",
  },
];
let moreTopics = [];
const hoy = new Date();
//published_time
export default function ConfigBlog({
  setOpen,
  open,
  text,
  filePortada,
  title,
  setTitle,
  imageOnBlog,
  isAcceptable,
  isUploaded,
  imageOnBlogUploaded,
  uploadedImages,
  uploadedImagesFiles,
  deletedImages,
}) {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  
  if (title == "<br>" || title == "Escribir un título") {
    setTitle("")
  }

  const [blogImg, setBlogImg] = useState(filePortada && !isAcceptable);
  const [userImg, setUserImg] = useState(!filePortada || isAcceptable);
  const [date, setDate] = useState("");
  const [dateNew, setDateNew] = useState("");
  const [time, setTime] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [dayTime, setDayTime] = useState("");
  const [portada, setPortada] = useState("");
  const [popper, setPopper] = useState(null);
  const [load, setLoad] = useState(false);
  const [popperTimer, setPopperTimer] = useState(null);
  const [dataClients, setDataClients] = useState(valueDefault);
  const [onLoad, setOnLoad] = useState(true);
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [availableTags, setAvailableTags] = useState();
  const [indexOfImageUploaded, setIndexOfImageUploaded] = useState()
  const [srcOfImageUploaded, setSrcOfImageUploaded] = useState()
  const [hasTitle, setHasTitle] = useState(title.length != 0 && title != "<br>")
  const [errorTitle, setErrorTitle] = useState(false)

  const user = useAuth();
  const { userId } = user;

  useEffect(() => {
    getClientsInput();
    getAvailableTags();
  }, []);

  async function getClientsInput() {
    setOnLoad(true);
    try {
      const response = await getClients(userId);
      setDataClients(response.data.clientes);
      setOnLoad(false);
    } catch (error) {}
  }

  async function getAvailableTags() {
    try {
      const response = await getLabelsForBlogs();
      setAvailableTags(response.data.labels);
    } catch (error) {
      console.log(error);
    }
  }

  function onChange(e) {
    console.log("chancge");
    switch (e.target.name) {
      case "imgBlog":
        setUserImg(false);
        setBlogImg(!blogImg);
        break;
      case "imgUser":
        setBlogImg(false);
        setUserImg(!userImg);
        break;
      default:
        break;
    }
  }

  const handlePopoverOpen = (event) => {
    setPopper(event.currentTarget);
  };

  const handlePopoverOpenTime = (event) => {
    setPopperTimer(event.currentTarget);
    console.log(event.currentTarget);
  };

  function handleDate(d, m, y) {
    setDate(`${d}/${m}/${y}`);
    setDateNew(`${d}/${m < 10 ? "0" + m : m}/${y}`);
    setPopper(null);
  }

  function handleTime(h, m, z) {
    setTime(`${h}:${m} ${z}`);
    setHours(h);
    setMinutes(m);
    setDayTime(z);
  }

  //InputTag
  const onChangeTag = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    console.log(key);

    if (
      key === "," ||
      (key === "Enter" && trimmedInput.length && !tags.includes(trimmedInput))
    ) {
      e.preventDefault();

      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const onMoreTops = (name) => {
    setTags((prevState) => [...prevState, name]);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  function submitCreateForm(e) {
    setLoad(true);
    // e.preventDefault();

    var formData = new FormData();
    formData.append("user_id", userId);
    
    const indexInicio = text.indexOf("<h2");
    const indexFin = text.indexOf("</h2>");
    const titulo = text.slice(indexInicio, indexFin + 5);
    const descriptionSinTitle = text.replace(titulo, "");
    formData.append("description", descriptionSinTitle);
    if (title === "<br>" || title === "") {
      formData.append("title", "Sin título");
    } else {
      formData.append("title", title);
    }

    let datenew = document.getElementById("calendar-blog").value.split("/");

    if (date) {
      formData.append(
        "published_date",
        `${datenew[2]}-${datenew[1]}-${datenew[0]}`
      );
      if (
        hoy.toLocaleDateString() == date &&
        ((hoy.getHours() + 24) % 12 || 12) == hours &&
        hoy.getMinutes() == minutes &&
        ((hoy.getHours() > 12 && dayTime == "p.m.") ||
          (hoy.getHours() <= 12 && dayTime == "a.m."))
      ) {
      } else {
        formData.append("published_time", time);
      }
    }

    //La validación es la siguiente, si el checkbox de imagen del blog está seleccionada entra al if
    // si está en "imagen predeterminada" entra al else
    
    if (!userImg && !!indexOfImageUploaded) {

      //Esto valida si es una imágen subida de la pc o es de url, esto se valida en el onclick del input
      //de "checkboxImgUploadedBlog" 
      //!!indexOfImageUploaded es por si el usuario no selecciona una de las fotos que tiene el blog, lo manda al else
      //y se sube la del CID

      if (indexOfImageUploaded != -1) {
        formData.append("blogimgs", uploadedImagesFiles[indexOfImageUploaded][0]);
      } else {
        formData.append("cover_blog", srcOfImageUploaded);
      }
    } else {
      if (portada === "") {
        //Aquí se manda la del CID
        formData.append("cover_blog", valueDefault[0].logo_client);
      } else {
        //Aquí por si selecciona una de las que están por defecto
        formData.append("cover_blog", portada.logo_client);
      }
    }

    formData.append("labelsUser", [tags]);

    createBlog(formData)
      .then((response) => {
        window.location.href = "/cid/carso/blogs/list";
        setLoad(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClose = () => {
    setPopper(null);
    setPopperTimer(null);
  };

  const handleOnChange = (e) => {
    setTitle(e.target.value)
    if (e.target.value.length != 0) {
      setHasTitle(true)
    } else {
      setHasTitle(false)
    }
  }

  const isOpenPopper = Boolean(popper);

  const isOpenPopperTime = Boolean(popperTimer);

  return (
    // <form onSubmitCapture={submitCreateForm}>
      <div>
        <span className="a-text-medium-first-primary-60 text-big">
          Configurar
        </span>
        <div className="d-flex w-100 align-content-center mt-4">
          <p className="a-text-regular-first-primary-60 align-self-center text-normal">
            Título*:
          </p>
          <div className="align-content-center w-100 mr-1 ml-5">
            <Input
              placeholder={"Escribe un título"}
              type="text"
              value={title}
              onChange={handleOnChange}
              className="w-100"
            />
          </div>
          
          
        </div>
        <div className="align-content-center ml-5">
          <div className="align-content-center ml-7 mt-2">
            <span></span>
            {
              errorTitle ?
                (
                  <div className="a-text-regular-alert-60">
                    Por favor agrega un título.
                  </div>
                )
              :
                ("")
            }
          </div>
          
        </div>
        <div className="d-flex w-100  mt-4">
          <span className="a-text-regular-first-primary-60 text-normal">
            Imagen:
          </span>

          <div className="d-flex align-content-center  ml-5">
            <div className=" d-flex mr-5">
              <input
                onChange={onChange}
                checked={blogImg}
                type="checkbox"
                id="imgBlog"
                className="radioNews cursor-pointer"
                name="imgBlog"
                value="imgBlog"
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                htmlFor="imgBlog"
              >
                Usar imagen del blog
              </label>
            </div>
          </div>
          <div>
            <div className=" d-flex">
              <input
                onChange={onChange}
                checked={userImg}
                type="checkbox"
                id="imgUser"
                className="radioNews cursor-pointer"
                name="imgUser"
                value="imgUser"
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                htmlFor="imgUser"
              >
                Usar imagen predeterminada
              </label>
            </div>
          </div>
        </div>
        <div  className={`${!userImg ? "mt-3 ml-5 d-flex" : "d-none"}`}>
            <Input
              data={uploadedImages}
              type="checkboxImgUploadedBlog"
              className="btn-checkbox-img-add-blog"
              id="report-client"
              filteredTopic={indexOfImageUploaded}
              filteredTopics={srcOfImageUploaded}
              setFilteredTopic={setIndexOfImageUploaded}
              setFilteredTopics={setSrcOfImageUploaded}
              deletedImgs={deletedImages}
            />
        </div>
        <div className={`${userImg ? "mt-3 ml-5 d-flex" : "d-none"}`}>
          {onLoad ? (
            <div />
          ) : (
            <Input
              value={valueDefault}
              data={dataClients}
              id="report-client"
              type="checkboxImgBlog"
              className="btn-checkbox-img-add-blog"
              setFilteredTopic={setPortada}
              filteredTopic={portada}
            />
          )}
        </div>
        <div className="d-flex align-items-center w-100 mt-4">
          <span className="a-text-regular-first-primary-60 text-normal mr-4">
            Etiquetas:
          </span>

          <div className="ContainerTag">
            {tags.map((tag, index) => (
              <div className="tag">
                {tag}
                <button type="button" onClick={() => deleteTag(index)}>
                  <img className="tag-icon" src={Close}></img>
                </button>
              </div>
            ))}
            <input
              value={input}
              placeholder="Ciencia, Tecnología..."
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChangeTag}
            />
          </div>
        </div>
        <div className="d-flex ml-5 mt-3  ">
          <div className="mr-5"></div>

          <Input
            value={tags}
            // data={topics}
            data={availableTags}
            setFilteredTopic={onMoreTops}
            className={"filterCheckBlog"}
            type="checkboxTextTopicFilterBlog"
          />
        </div>
        <div className="d-flex align-items-center  mt-3">
          <span className="a-text-regular-first-primary-60 text-normal mr-4">
            Publicar:
          </span>

          <div className="w-40 d-flex align-items-center ml-2 mr-4">
            <span className="mr-2 a-text-regular-first-neutral-100">
              {" "}
              {`${
                hoy.toLocaleDateString() == date &&
                ((hoy.getHours() + 24) % 12 || 12) == hours &&
                hoy.getMinutes() == minutes &&
                ((hoy.getHours() > 12 && dayTime == "p.m.") ||
                  (hoy.getHours() <= 12 && dayTime == "a.m."))
                  ? "Inmediatamente"
                  : ""
              }`}
            </span>

            <Input
              id={"calendar-blog"}
              placeholder={"Elige fecha"}
              type="date"
              onClick={handlePopoverOpen}
              value={date == "" ? null : date}
            />
          </div>
          {/* <div div className="w-40 ml-4 "> */}
          <div className="w-40 d-flex align-items-center ml-2 mr-4">
            <Input
              id={"time-blog"}
              placeholder={"Elige hora"}
              type="time"
              onClick={(e) =>
                setPopperTimer(popperTimer ? null : e.currentTarget)
              }
              value={time == "" ? null : time}
            />
          </div>
          <div
            className={popperTimer ? "popper" : "d-none"}
            onClick={() => setPopperTimer(null)}
          ></div>
        </div>
        <div className="w-100 d-flex mt-4 justify-content-end">
          <Button
            type={"text"}
            onclick={() => {
              setOpen(!open);
            }}
            classes={`text-normal a-button-topic-gray-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer`}
            text={"Cancelar"}
          />

          <Button
            // type={"submit"}
            type={"text"}
            onclick={() => {
              if (hasTitle && title !== "") {
                submitCreateForm();
              } else {
                setErrorTitle(true);
              }
            }}
            classes={`text-normal ml-  a-button-topic-blue-1 text-decoration-none  roboto-regular h3-md ml-md-4 text-lg-normal text-center d-flex justify-content-center align-items-center cursor-pointer ${
              load ? "disabled" : ""
            } `}
            text={load ? <CircularProgress /> : "Publicar"}
            disabled={load ? true : false}
          />
        </div>

        <Popover open={isOpenPopper} anchorEl={popper} onClose={handleClose}>
          <Calendar
            onClick={(d, m, y) => handleDate(d < 10 ? "0" + d : d, m, y)}
          />
        </Popover>

        <Popover
          open={isOpenPopperTime}
          anchorEl={popperTimer}
          onClose={handleClose}
        >
          <Time
            onEvent={(h, m, z) => handleTime(h, m < 10 ? "0" + m : m, z)}
            onClick={setPopperTimer}
          />
        </Popover>
      </div>
    // </form>
  );
}
