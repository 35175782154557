require('dotenv').config();

const ENV = process.env.NODE_ENV || 'production';
// const ENV = 'production';


if (ENV == 'development') {
    var BASE_PATH = 'http://localhost:3500';
    var PDF_PATH = 'http://localhost:4500';
    var DEVElOPMENT = true;
} else if (ENV == 'production') {
    var BASE_PATH = 'https://test.wikicid.com';
    var PDF_PATH = 'https://empresas.wikicid.com';
    var DEVElOPMENT = false;
}

export { BASE_PATH, PDF_PATH, DEVElOPMENT };