/**
 * Consumo de endpoint para el filtrado de los análisis.
 */
import { BASE_PATH } from './config';
import axios from 'axios';

export function getReportsByClient(userId, clientId, orderBy) {
	let orderByString = '';
	let clientString = '';
	let arrayClientesId = [];
	console.log(clientId);

	if (orderBy !== 0) {
		orderByString = '&orderA=1';
	}
	if (clientId !== '') {
		clientId.map((elm) => arrayClientesId.push(elm.id));

		clientString = `&clientId=(${arrayClientesId.join(',')})`;
	}

	return axios.get(`${BASE_PATH}/get-client-filter?userId=${userId}${clientString}${orderByString}`);
}

export function getReportsFilterApi(userId, sector, market, client, orderByDate, orderBy) {
	let sectorString = '';
	let marketString = '';
	let clientString = '';
	let orderByDateString = '';
	let orderByString = '';

	let arrayMarketId = [];
	let arraySectorId = [];

	if (sector.length !== 0) {
		sector.map((elm) => arraySectorId.push(elm.id));
		sectorString = `&sectorId=(${arraySectorId.join(',')})`;
	}

	if (orderByDate !== 0) {
		orderByDateString = '&orderDate=1';
	}

	if (orderBy !== 0) {
		orderByString = '&orderA=1';
	}

	if (market.length > 0) {
		market.map((elm) => arrayMarketId.push(elm.id));
		marketString = `&marketId=(${arrayMarketId.join(',')})`;
	}

	return axios
		.get(
			`${BASE_PATH}/get-report-filter?userId=${userId}${sectorString}${marketString}${clientString}${orderByDateString}${orderByString}`
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
}

//Para la busqueda

export function SearchReportsBySector(userId, sectors) {
	return axios.get(
		`${BASE_PATH}/report/search/by-sector?userId=${userId}${sectors !== '' ? `&sector=${sectors}` : ''}`
	);
}

export function searchReportsByMarkets(userId, markets) {
	return axios.get(`${BASE_PATH}/report/search/by-market?userId=${userId}${markets ? `&market=${markets}` : ''}`);
}

export function searchReportsByClientes(userId, clientes) {
	return axios.get(
		`${BASE_PATH}/report/search/by-clientes?userId=${userId}${clientes ? `&cliente=${clientes}` : ''}`
	);
}

/*export function searchCompaniesByFounder(userId, founder) {
    return axios.get(`${BASE_PATH}/search/by-founder?userId=${userId}${founder ? `&founder=${founder}` : ""}`)
}*/
