/**Consumo de endpoint para la creación de los perfiles.
 */

import { BASE_PATH } from './config';
import axios from 'axios';

export function createProfile(data) {
	return axios.post(`${BASE_PATH}/profile/create-profile`, data);
}

 export function updateProfile(data) {
    return axios.put(`${BASE_PATH}/profile/update-profile`, data);
}
export function getArea(userId) {
	return axios.get(`${BASE_PATH}/profile/get-area?userId=${userId}`);
}
export function getLanguage(userId) {
	return axios.get(`${BASE_PATH}/profile/get-language?userId=${userId}`);
}

export function follow(user_following_id, user_followed_id, follow) {
	return axios.post(`${BASE_PATH}/profile/follow`, {
		user_id: user_following_id,
		user_following_id: user_following_id,
		user_followed_id: user_followed_id,
		follow: follow,
	});
}
export function searchFollower(user_following_id, user_followed_id) {
	return axios.post(`${BASE_PATH}/profile/searchFollower`, {
		user_id: user_following_id,
		user_following_id: user_following_id,
		user_followed_id: user_followed_id,
	});
}
export function getFormacion(userId) {
	return axios.get(`${BASE_PATH}/profile/get-formacion?userId=${userId}`);
}

export function getFollowers(userId){
    return axios.get(`${BASE_PATH}/profile/getFollowers/${userId}`);
}

export function getFollows(userId){
    return axios.get(`${BASE_PATH}/profile/getFollows/${userId}`);
}

export function getProfile(userId) {
    return axios.get(`${BASE_PATH}/profile/get-profile/${userId}`);
}

export function getProfileAdmin(userId) {
	return axios.get(`${BASE_PATH}/admin/get-admin-user?userId=${userId}`);
}

export function getUserApi(userId) {
    return axios.get(`${BASE_PATH}/profile/get-users/${userId }`);
}

export function getFollowersInfo(userId) {
    return axios.get(`${BASE_PATH}/profile/getFollowersInfo/${userId}`);
}

export function getFollowsInfo(userId) {
    return axios.get(`${BASE_PATH}/profile/getFollowsInfo/${userId}`);
}

export function getAllUsersWithProfile(userId) {
    return axios.get(`${BASE_PATH}/profile/getAllUsersWithProfile`, {user_id:userId});
}
