// React
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// Material
import {
  useMediaQuery,
  Modal,
  Grid,
  createMuiTheme,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useLocation } from "react-router-dom";

// Componentes
import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";
import { Input } from "../../atoms/Input";
import { Button } from "../../atoms/Button/";
import { CardProgram } from "../../molecules/Card/CardPodcast/CardProgram";
import { CardPreview } from "../../molecules/Card/CardPodcast/CardPreview";
import { SkeletonProgramPreview } from "../../molecules/Skeleton/SkeletonPodcast/SkeletonProgramPreview"

// Images
import FailIcon from "../../../assets/img/fail-icon.svg";
import Close from "../../../assets/img/icon/close.svg";

// Api
import { getClients } from "../../../api/report";
import { getLabelsForBlogs } from "../../../api/blogs";
import { getPodcasts } from "../../../api/podcasts";

import { uploadPodcast, createProgram } from "../../../api/podcasts";
import { SettingsInputAntennaTwoTone, Title } from "@material-ui/icons";

const valueDefault = {
  client_id: 34,
  name: "Centro de Inteligencia e Innovación Digital",
  logo_client:
    "https://firebasestorage.googleapis.com/v0/b/images-wikicid.appspot.com/o/reportes%2Fclientes%2FCID_V.png?alt=media&token=933e59ef-bbd6-4f73-83b8-502660f0f195",
};

export function FormAddPodcast({ userId }) {
  const location = useLocation();
  const podcast = location.state?.program;
  console.log(podcast);

  //State info fetched from db
  const [availableTags, setAvailableTags] = useState();
  const [dataClients, setDataClients] = useState();

  const [addEpisode, setAddEpisode] = useState(!!location.state);
  const [addEpisodeInfo, setAddEpisodeInfo] = useState(location.state?.program);

  //States create program
  const [programLabels, setProgramLabels] = useState([]);
  const [portada, setPortada] = useState(valueDefault);
  const [isProgramCreated, setIsProgramCreated] = useState(false);
  const [isUploadingProgram, setIsUploadingProgram] = useState(false);
  const [programId, setProgramId] = useState(
    location.state?.program.program_podcast_id
  );
  const [data, setData] = useState(location.state?.program);
  const [title, setTitle] = useState("");
  const [portadaEpisode, setPortadaEpisode] = useState(valueDefault);
  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState();
  const [uploadedCover, setUploadedCover] = useState(false);
  const [clientCover, setClientCover] = useState(true);

  //States create episode
  const [audioURL, setAudioURL] = useState();
  const [imgEpisodeCheckbox, setImgEpisodeCheckbox] = useState([
    true,
    false,
    false,
  ]);
  const [episodeLabels, setEpisodeLabels] = useState([]);
  const [episodeImage, setEpisodeImage] = useState();
  const [episodeImageFile, setEpisodeImageFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState();

  //State new or already existing
  const [typeOfAdd, setTypeOfAdd] = useState([true, false]);
  const [userPodcasts, setUserPodcasts] = useState([]);
  const [load, setLoad] = useState(true);
  const [podcastSelected, setPodcastSelected] = useState(
    location.state?.program
  );

  const selectCheckbox = (index, arr, setArr) => {
    let aux = [...arr];
    let aux2 = aux.map((el, i) => {
      if (index == i) {
        return true;
      } else {
        return false;
      }
    });
    setArr(aux2);
  };

  useEffect(() => {
    getUserPodcast();
    getClientsInput();
    getAvailableTags();
  }, []);

  const audioRef = useRef();

  useEffect(() => {
    audioRef.current = new Audio(audio);
    console.log(audioRef);
  }, [audio]);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  async function getAvailableTags() {
    try {
      const response = await getLabelsForBlogs();
      console.log(response.data.labels);
      setAvailableTags(response.data.labels);
    } catch (error) {
      console.log(error);
    }
  }
  // Submit
  const getLabelsNames = (labels) => {
    const tags = [...availableTags];
    let aux = tags
      .filter((el) => {
        return labels.includes(el.labels_id);
      })
      .map((el) => {
        return el.name;
      });
    return aux;
  };

  function submitCreateProgram(e) {
    e.preventDefault();
    setIsUploadingProgram(true);

    const title = document.getElementById("program-title");
    const description = document.getElementById("program-description");
    let formData = new FormData();

    formData.append("user_id", userId);
    formData.append("title", title.value);
    formData.append("description", description.value);

    formData.append("labels", getLabelsNames(programLabels));

    if (uploadedCover) {
      formData.append("cover", imageFile);
    } else {
      formData.append("files", portada.logo_client);
    }

    const labels = getLabelsNames(programLabels);

    setData({
      title: title.value,
      description: description.value,
      cover: uploadedCover ? image : portada.logo_client,
      labelsPodcast: labels,
      uploaded: true,
    });

    createProgram(formData)
      .then((response) => {
        console.log("this is the response", response);
        setIsUploadingProgram(false);
        const id = response.data.programId;
        if (response.status == 200) {
          console.log("Haz subido un programa con éxito");
          setIsProgramCreated(true);
          setProgramId(id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function submitCreateEpisode(e) {
    e.preventDefault();
    setIsLoading(true);

    const title = document.getElementById("episode-title");
    const description = document.getElementById("episode-descripcion");

    let formData = new FormData();

    let formURL = audioURL;

    formData.append("podcast", formURL);
    formData.append("user_id", userId);
    formData.append("title", title.value);
    formData.append("description", description.value);
    formData.append("programId", programId);
    formData.append("labels", getLabelsNames(episodeLabels));

    switch (imgEpisodeCheckbox.indexOf(true)) {
      case 0:
        formData.append("files", portadaEpisode.logo_client);
        break;
      case 1:
        if (typeOfAdd[0]) {
          // Este es el caso para cuando seleccionó un podcast ya creado y va a poner un episodio con imágen del podcast
          formData.append("files", portada);
        } else {
          uploadedCover
            ? formData.append("cover", imageFile)
            : formData.append("files", portada.logo_client);
        }

        break;
      case 2:
        formData.append("cover", episodeImageFile);
        break;
    }

    uploadPodcast(formData)
      .then((response) => {
        window.location.href = "/cid/carso/podcast/list";
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getClientsInput() {
    try {
      const response = await getClients(userId);
      setDataClients(response.data.clientes);
    } catch (error) {}
  }

  const handleBack = () => {
    window.location.href = "/cid/carso/podcast/list";
  };

  // function uploadAudioFile(e) {
  //   console.log("Esto nos imprime los files del input", e.target.files);

  //   let fileInput = e.target;
  //   let urlObj = URL.createObjectURL(fileInput.files[0]);
  //   setNameFile(fileInput.files[0].name);
  //   setAudio(urlObj);
  //   document.getElementById("audio").load();

  //   setFile(fileInput.files[0]);
  // }

  function uploadImageFile(e) {
    const inputName = e.target.name;
    var reader = new FileReader();
    console.log("este es el input", e.target.name)
    console.log("esta es la imagen", e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      switch (inputName) {
        case "uploadedCoverEpisode":
          setEpisodeImage(reader.result);
          setEpisodeImageFile(e.target.files[0]);
          break;
        case "uploadedCover":
          setImage(reader.result);
          setImageFile(e.target.files[0]);
          break;
      }
    };
  }

  function onChange(e) {
    switch (e.target.name) {
      case "clientCover":
        setUploadedCover(false);
        setClientCover(!clientCover);
        break;
      case "uploadedCover":
        setClientCover(false);
        setUploadedCover(!uploadedCover);
        break;
    }
  }

  async function getUserPodcast() {
    try {
      const response = await getPodcasts();
      console.log("así llegan los podcasts", response.data.podcast);
      let aux = response.data.podcast;
      let filtered = aux.filter((el) => el.user_id == userId);
      console.log(filtered);
      if (filtered.length == 0 || filtered == undefined) {
        setTypeOfAdd([false, true])
      }
      setUserPodcasts(filtered);
      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  }

  function getFileURL(url) {
   // if (url.includes("google")) {
      //GDrive
    //  const regex = /(?<=d\/).*(?=\/)/;
     // const id = url.match(regex);
     // let newURL;
     // newURL = `https://docs.google.com/uc?export=open&id=${id}`;
     // return newURL;
    //} else 
    if (url.includes("onedrive")) {
      //OneDrive
      let src = url.split('"')[1];
      let lolURL = src.replace("embed", "download");
      return lolURL;
    } else {
      //ClaroDrive
      let cdURL = url + "/download";
      return cdURL;
    }
  }

  const handleOnChangeURL = (e) => {
    const myURL = e.target.value;
    let aux = getFileURL(myURL);
    console.log("this is aux", aux);
    setAudioURL(aux);
    document.getElementById("audio").load();
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={9} lg={12} xl={12} className="">
          <div className="d-flex justify-content-between a-text-bold-second-neutral-70">
            <p
              className={`${
                ipadMiniMatch ? "h3" : ""
              } mb-3 a-text-bold-second-neutral-70`}
            >
              Crear podcast
            </p>
            <div>
              <IconButton>
                <img
                  src={Close}
                  alt="Close"
                  className="nav-company-icon"
                  onClick={handleBack}
                />
              </IconButton>
            </div>
          </div>
          <div className="d-flex">
            <div className=" d-flex mr-5">
              <input
                onChange={() => selectCheckbox(0, typeOfAdd, setTypeOfAdd)}
                checked={typeOfAdd[0]}
                type="checkbox"
                className="radioNews cursor-pointer"
                id="añadir-episodio"
              />
              <label
                className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                htmlFor="añadir-episodio"
              >
                Añadir episodio a un podcast
              </label>
            </div>
            <div>
              <div className=" d-flex mr-5">
                <input
                  onChange={() => selectCheckbox(1, typeOfAdd, setTypeOfAdd)}
                  checked={typeOfAdd[1]}
                  type="checkbox"
                  className="radioNews cursor-pointer"
                  id="crear-nuevo"
                />
                <label
                  className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                  htmlFor="crear-nuevo"
                >
                  Crear podcast nuevo
                </label>
              </div>
            </div>
          </div>
          {!addEpisode ? (
            typeOfAdd[0] ? (
              load ? (
                <div className="mt-4 a-text-regular-second-neutral-40 text-normal">
                  {/* Cargando tus podcast... */}
                  <SkeletonProgramPreview />
                  <SkeletonProgramPreview />
                </div>
              ) : podcastSelected == undefined ? (
                <div className="mt-3">
                  {userPodcasts.map((el) => (
                    <CardPreview
                      info={el}
                      setPodcastSelected={setPodcastSelected}
                      podcastSelected={podcastSelected}
                      setProgramId={setProgramId}
                      setPortada={setPortada}
                    />
                  ))}
                </div>

              ) : (
                // <CardProgram data={podcastSelected} />
                <div className="mt-3">
                  <CardPreview
                    info={podcastSelected}
                    setPodcastSelected={setPodcastSelected}
                    podcastSelected={podcastSelected}
                    setProgramId={setProgramId}
                    setPortada={setPortada}
                  />
                </div>
              )
            ) : isProgramCreated ? (
              <div className="mt-3">
                <CardPreview
                  info={data}
                  setPodcastSelected={setPodcastSelected}
                  podcastSelected={podcastSelected}
                  setProgramId={setProgramId}
                  setAddEpisode={setAddEpisode}
                  setPortada={setPortada}
                />
              </div>
            ) : (
              <form onSubmitCapture={submitCreateProgram}>
                <>
                  <p className="mb-3 mt-3 a-text-regular-second-neutral-40 text-small">
                    * Campo obligatorio
                  </p>
                  <div className="mb-3">
                    <InputWithLabel
                      text="Titulo del podcast*"
                      id="program-title"
                      width={80}
                      placeholder="Ingresa el título del podcast"
                      type="text"
                      focus={true}
                    />
                  </div>
                  <div className="mb-3 w-100">
                    <p className="pl-lg-1 a-text-medium-second-neutral-70 mb-md-2">Descripción</p>
                    <Input
                      text="Descripción"
                      width={80}
                      id={"program-description"}
                      placeholder="Agrega una descripción"
                      type="autoresize"
                    />
                  </div>
                  <div className="mb-3 w-100">
                    <p className="pl-lg-1 a-text-medium-second-neutral-70 mb-md-2">
                      Categorías
                    </p>
                    <div className="mb-2 d-flex">
                      <Input
                        data={availableTags}
                        className={"filterCheck"}
                        type="checkboxLabelsPodcast"
                        setFilteredTopics={setProgramLabels}
                        filteredTopics={programLabels}
                      />
                    </div>
                  </div>
                  <p className="pl-lg-1 a-text-medium-second-neutral-70 mb-md-2">
                    Imágen del podcast*
                  </p>

                  <div className="d-flex w-100  mt-4">
                    <div className="d-flex align-content-center">
                      <div className=" d-flex mr-5">
                        <input
                          onChange={onChange}
                          checked={clientCover}
                          type="checkbox"
                          id="clientCover"
                          className="radioNews cursor-pointer"
                          name="clientCover"
                          value="clientCover"
                        />
                        <label
                          className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                          htmlFor="clientCover"
                        >
                          Usar imagen predeterminada
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className=" d-flex">
                        <input
                          onChange={onChange}
                          checked={uploadedCover}
                          type="checkbox"
                          id="uploadedCover"
                          className="radioNews cursor-pointer"
                          name="uploadedCover"
                          value="uploadedCover"
                        />
                        <label
                          className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                          htmlFor="uploadedCover"
                        >
                          Subir imagen desde el dispositivo
                        </label>
                      </div>
                    </div>
                  </div>
                  {clientCover ? (
                    <div className={"mt-3 d-flex"}>
                      <Input
                        data={dataClients}
                        id="report-client"
                        type="checkboxImgPodcast"
                        setFilteredTopic={setPortada}
                        filteredTopic={portada}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {uploadedCover ? (
                    <div className="mb-3 mt-4 w-30 ">
                      <InputWithLabel
                        id="program-cover-input"
                        type="file"
                        classLabel="a-text-medium-second-neutral-90"
                        onChange={uploadImageFile}
                        name={"uploadedCover"}
                        value={image}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>

                <Button
                  type="submit"
                  classes={`text-small a-button-blue-1 roboto-bold mt-4  ${
                    isLoading ? "disabled" : ""
                  }`}
                  height={40}
                  width={200}
                  text={
                    isUploadingProgram ? <CircularProgress /> : "Crear podcast"
                  }
                />
              </form>
            )
          ) : (
            // <CardProgram data={addEpisodeInfo} setAddEpisode={setAddEpisode}/>
            <div className="mt-3">
              <CardPreview
                info={podcastSelected}
                setPodcastSelected={setPodcastSelected}
                podcastSelected={podcastSelected}
                setProgramId={setProgramId}
                setAddEpisode={setAddEpisode}
                setPortada={setPortada}
              />
            </div>
          )}

          <hr className="hr-ocean mt-3 mb-3" />

          <form onSubmitCapture={submitCreateEpisode}>
            <div className="d-flex justify-content-between">
              <p
                className={`${
                  ipadMiniMatch ? "h3" : ""
                } mb-3 a-text-bold-second-neutral-70`}
              >
                Subir episodio
              </p>
            </div>

            <>
              <p className="mb-3 a-text-regular-second-neutral-40 text-small">
                * Campo obligatorio
              </p>
              <div className="mb-3">
                <InputWithLabel
                  text="Titulo del episodio*"
                  id="episode-title"
                  width={80}
                  placeholder="Ingresa el título del episodio"
                  type="text"
                  focus={isProgramCreated}
                />
              </div>
              <div className="mb-3">
                <InputWithLabel
                  text="URL del audio*"
                  id="episode-audio"
                  width={80}
                  placeholder="Ingresa la URL"
                  type="text"
                  onChange={(e) => handleOnChangeURL(e)}
                />
                <audio
                  controls
                  id="audio"
                  className={audioURL ? "mt-2" : "d-none"}
                >
                  <source src={audioURL}></source>
                </audio>
              </div>
              <div className="mb-3 w-100">
                <p className="pl-lg-1 a-text-medium-second-neutral-70 mb-md-2">
                  Descripción
                </p>
                <Input
                  text="Descripción"
                  width={80}
                  id={"episode-descripcion"}
                  placeholder="Agrega una descripción"
                  type="autoresize"
                />
              </div>
              <div className="mb-3 w-100">
                <p className="pl-lg-1 a-text-medium-second-neutral-70 mb-md-2">
                  Categorías
                </p>
                <div className="mb-2 d-flex">
                  <Input
                    data={availableTags}
                    className={"filterCheck"}
                    type="checkboxLabelsPodcast"
                    setFilteredTopics={setEpisodeLabels}
                    filteredTopics={episodeLabels}
                  />
                </div>
              </div>
              <p className="pl-lg-1 a-text-medium-second-neutral-70 mb-md-2">
                Imágen del episodio*
              </p>

              <div className="d-flex w-100  mt-4">
                <div className="d-flex align-content-center">
                  <div className=" d-flex mr-5">
                    <input
                      // onChange={onChange}
                      onChange={() =>
                        selectCheckbox(
                          0,
                          imgEpisodeCheckbox,
                          setImgEpisodeCheckbox
                        )
                      }
                      checked={imgEpisodeCheckbox[0]}
                      type="checkbox"
                      id="clientCoverEpisode"
                      className="radioNews cursor-pointer"
                      name="clientCoverEpisode"
                      value="clientCoverEpisode"
                    />
                    <label
                      className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                      htmlFor="clientCoverEpisode"
                    >
                      Usar imagen predeterminada
                    </label>
                  </div>
                </div>
                <div>
                  <div className=" d-flex mr-5">
                    <input
                      onChange={() =>
                        selectCheckbox(
                          1,
                          imgEpisodeCheckbox,
                          setImgEpisodeCheckbox
                        )
                      }
                      checked={imgEpisodeCheckbox[1]}
                      type="checkbox"
                      id="podcastSameCover"
                      className="radioNews cursor-pointer"
                    />
                    <label className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer" htmlFor="podcastSameCover">
                      Usar imágen de podcast
                    </label>
                  </div>
                </div>
                <div>
                  <div className=" d-flex">
                    <input
                      // onChange={onChange}
                      onChange={() =>
                        selectCheckbox(
                          2,
                          imgEpisodeCheckbox,
                          setImgEpisodeCheckbox
                        )
                      }
                      checked={imgEpisodeCheckbox[2]}
                      type="checkbox"
                      id="uploadedCoverEpisode"
                      className="radioNews cursor-pointer"
                      name="uploadedCoverEpisode"
                      value="uploadedCoverEpisode"
                    />
                    <label
                      className="a-text-regular-first-primary-60 text-normal ml-2 cursor-pointer"
                      htmlFor="uploadedCoverEpisode"
                    >
                      Subir imagen desde el dispositivo
                    </label>
                  </div>
                </div>
              </div>
              {imgEpisodeCheckbox[0] ? (
                <div className={"mt-3 d-flex"}>
                  <Input
                    data={dataClients}
                    id="report-client"
                    type="checkboxImgPodcast"
                    setFilteredTopic={setPortadaEpisode}
                    filteredTopic={portadaEpisode}
                  />
                </div>
              ) : (
                ""
              )}
              {imgEpisodeCheckbox[1] ? (
                <div className="mb-3 mt-4 w-30 ">
                  <InputWithLabel
                    id="episode-cover-program"
                    type="file"
                    classLabel="a-text-medium-second-neutral-90"
                    value={
                      typeOfAdd[0]
                        ? podcastSelected?.cover
                        : uploadedCover
                        ? image
                        : portada.logo_client
                    }
                  />
                </div>
              ) : (
                ""
              )}
              {imgEpisodeCheckbox[2] ? (
                <div className="mb-3 mt-4 w-30 ">
                  <InputWithLabel
                    id="episode-cover"
                    type="file"
                    classLabel="a-text-medium-second-neutral-90"
                    onChange={uploadImageFile}
                    name={"uploadedCoverEpisode"}
                    value={episodeImage}
                  />
                </div>
              ) : (
                ""
              )}
            </>

            <Button
              type="submit"
              classes={`text-small a-button-blue-1 roboto-bold mt-4  ${
                isLoading ? "disabled" : ""
              }`}
              height={40}
              width={200}
              text={isLoading ? <CircularProgress /> : "Subir episodio"}
            />
          </form>
        </Grid>
      </Grid>
    </>
  );
}
