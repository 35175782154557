//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";
export default function SkeletonResponseQuestion({ num }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  return arraySkeleton.map((r, indx) => (
    <div className=" d-flex  w-100" key={indx}>
      <div className=" mb-3 h-144 d-flex flex-column pl-5 align-items-center w-90 ">
        <div className="d-flex align-items-center w-100 pt-3">
          <Skeleton
            variant="circle"
            width="25px"
            height="25px"
            className="mr-3"
            animation="wave"
          />
          <Skeleton variant="h3" width="50%" animation="wave" />
        </div>
        <div className="d-flex align-items-center w-100 pt-4">
          <Skeleton variant="h3" width="100%" animation="wave" />
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 pt-4">
          <Skeleton variant="h3" width="30%" animation="wave" />
          <Skeleton variant="h3" width="30%" animation="wave" />
          <Skeleton variant="h3" width="30%" animation="wave" />
        </div>
      </div>
    </div>
  ));
}
