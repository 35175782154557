import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from '../stores/companies';
// import {Provider} from 'react-redux';

const root = document.getElementById('root');

// ReactDOM.render(
//   <Provider store = {store}>
//     <React.StrictMode>
//     <App />
//     </React.StrictMode>
//   </Provider>,
//   document.getElementById('root')
// );
// /*

ReactDOM.render(<App />, root);

//just disable strictMode for development enable it for production
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//     </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
