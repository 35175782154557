import { useEffect, useState } from "react"
import useAuth from "../../../hooks/useAuth";
import { verifyTokenPasswordApi } from "../../../api/user";
import Form from "../../../components/organisms/FormRecoverPassword";
import Logo from "../../../assets/img/logo.svg";
import Grid from "@material-ui/core/Grid";

export default function VerifyTokenPassword(props){
    const [ userId, setUserId ] = useState(0)
    const user = useAuth();

    var token = obtenerValorParametro('token');
    useEffect(() =>{
        const verfiicarToken = async() =>{
            try {
                const { data } = await verifyTokenPasswordApi(token)
               setUserId(data.user_id)
            } catch (error) {
                console.log(error)
            }
        }
        verfiicarToken()
    }, [])

    if(user){
        window.location.href = props.history.goBack();
    }
    
    return(
        <div className="recover-password-background">
            <Grid container spacing={0} className="d-flex justify-content-center align-items-md-center h-100">
                <Grid xs={10} md={6} lg={4}>
                    <div className="card--large">
                        <img className="pt-5 mb-5 logo d-block mx-auto" src={Logo} alt="Wikicid Logo" />
                        <h3 className="h3 text-center mb-3 a-text-bold-second-neutral-100">Recupera tu cuenta</h3>
                        <p className="text-center text-normal a-text-regular-second-neutral-60 mb-3">Por favor, ingresa una nueva contraseña.</p>
                        <Form token={token} userId={userId}/>
                    </div>

                </Grid>
            </Grid>
        </div>
    )


}

function obtenerValorParametro(sParametroNombre) {
    var sPaginaURL = window.location.search.substring(1);
    var sURLVariables = sPaginaURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParametro = sURLVariables[i].split('=');
      if (sParametro[0] == sParametroNombre) {
        return sParametro[1];
      }
    }
    return null;
  }