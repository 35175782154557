//React
import { useState } from "react";
import { Link } from "react-router-dom";

//Components
import { InputWithLabel } from "../../../molecules/InputWith/InputWithLabel";
import { Button } from "../../../atoms/Button";

//Material
import { CircularProgress, Modal } from "@material-ui/core";

//Images
import FailIcon from "../../../../assets/img/fail-icon.svg";

// Api
import { getAccessToken } from "../../../../api/auth";

//Jwt
import jwtDecode from "jwt-decode";

// Utils
import { emailValidation } from "../../../../utils/emailValidation";

export default function FormLogin({ className, callApi, id }) {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [warningEmail, setWarningEmail] = useState("");
  const [warningPassword, setWarningPassword] = useState("");
  const [warningAccount, setWarningAccount] = useState("");
  const [status, setStatus] = useState('');

  // Funciones

  // Sumit
  function submitForm(e) {
    e.preventDefault();
    const email = document.getElementById("sign-in-email");
    const password = document.getElementById("sign-in-password");
    let isEmailValid = false;
    if (!email.value) {
      setWarningEmail("Ingresa tu correo electrónico");
      email.classList.add("input-error");
    } else {
      isEmailValid = emailValidation(email.value);
      if (!isEmailValid) {
        setWarningEmail("Ingresa un correo electrónico válido");
        email.classList.add("input-error");
      } else {
        setWarningEmail("");
        email.classList.remove("input-error");
      }
    }
    if (!password.value) {
      setWarningPassword("Ingresa tu contraseña");
      password.classList.add("input-error");
    } else {
      setWarningPassword("");
      password.classList.remove("input-error");
    }

    if (isEmailValid && password.value) {
      setIsLoading(true);
      callApi({ email: email.value, password: password.value })
        .then((res) => {
          setStatus(res.response ? res.response.status : null)
          if (res.status === 200) {
            setIsLoading(false);
            let { accessToken, refreshToken } = res.data;
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            let { rolId } = jwtDecode(getAccessToken());
            
            if (id == "form-signIn-admin") {
              window.location.href = "/admin/users";
            } else if (rolId === 1 || rolId === 2) {
              window.location.href = "/cid";
            } else {
              window.location.href = "/cid/carso/home";
            }
          }
          if (res.response) {
            setIsLoading(false);
            if (res.response.status === 404) {
              setWarningAccount(
                "No pudimos encontrar una cuenta asociada con ese correo y/o contraseña"
              );
            } else {
              setIsOpen(true);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setWarningAccount("Hubo un error al intentar ingresar al sistema");
        });
    }
  }

  return (
    <>
      {/* FORMULARIO */}
      <form onSubmit={submitForm}>
        <div className="mb-3">
          <InputWithLabel
            text="Correo electrónico"
            id="sign-in-email"
            placeholder="Correo electrónico"
            focus={true}
            type="text"
          />
          {warningEmail ? (
            <p className="mt-2 a-text-regular-alert-60">{warningEmail}</p>
          ) : (
            ""
          )}
        </div>
        <div className="mb-4">
          <InputWithLabel
            placeholder="Contraseña"
            text="Contraseña"
            id="sign-in-password"
            type="password"
            focus={false}
          />
          {warningPassword ? (
            <p className="mt-2 a-text-regular-alert-60">{warningPassword}</p>
          ) : (
            ""
          )}
        </div>
        <Button
          disabled={isLoading ? true : false}
          classes={`a-button-primary1 text-small ${
            isLoading ? "disabled" : ""
          }`}
          height={36}
          width="100%"
          text={isLoading ? <CircularProgress /> : "Iniciar sesión"}
        />
        {warningAccount ? (
          <p className="text-center mt-4 a-text-regular-alert-60">
            {warningAccount}
          </p>
        ) : (
          ""
        )}
        <div className={className}>
          <Link
            to="/recover-account"
            className="d-block text-decoration-none mt-4 mb-4 text-center a-text-regular-first-primary-60"
          >
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
      </form>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <div className="d-flex justify-content-center mb-4">
            <img
              src={FailIcon}
              alt="Modal Icon"
              className="modal__icon-modal"
            />
          </div>
          <p className="mb-4 a-text-medium-second-neutral-90 text-center">
            {console.log(status)}
            {status === 403 ? 'Contraseña Incorrecta' : 'Se ha producido un error al intentar iniciar sesión'}
          </p>
          <div className="text-center" onClick={() => setIsOpen(false)}>
            <Button
              type="button"
              classes="a-button-primary1 text-small"
              height={36}
              width="50%"
              text="Aceptar"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
