// React
import { useCallback, useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useHistory, useLocation } from 'react-router';

import { PDF_PATH } from '../../../../api/config';

import debounce from 'lodash.debounce';

//Material
import { Box, Grid, Modal, useMediaQuery } from '@material-ui/core';

// Components
import Header from "../../../../components/molecules/Header";
import CardSkeleton from "../../../../components/molecules/Skeleton/CardSkeleton";
import { Button } from "../../../../components/atoms/Button";
import FilteredCompany from "../../../../components/organisms/Company/FilteredCompany";

// Images
import Company from "../../../../assets/img/searchCompany/empresa.svg";
import Sector from "../../../../assets/img/searchCompany/sector.svg";
import Industria from "../../../../assets/img/company/Industrias.svg";
import Mercado from "../../../../assets/img/company/mercado-sin-fondo.svg";
import Market from "../../../../assets/img/searchCompany/tegnologia.svg";
import Sede from "../../../../assets/img/searchCompany/sede.svg";
import Founder from "../../../../assets/img/searchCompany/fundador.svg";
import Investors from "../../../../assets/img/company/inversionistas-sin-fondo.svg";
import Alliance from "../../../../assets/img/company/alianzas-sin-fondo.svg";
import AlertIcon from "../../../../assets/img/alert.svg";
import SuccessIcon from "../../../../assets/img/sucess-icon.svg";
import Plantilla1 from "../../../../assets/img/Plantilla1.png";
import Plantilla2 from "../../../../assets/img/Plantilla2.png";
import BackIcon from "../../../../assets/img/BackIcon.svg";

// hooks
import useCardList from "../../../../hooks/useCardList";
//import pagination hook
import Paginations from "../../../../components/molecules/Paginations/Paginations";

//API
import { getTrends, deleteCompanyApi, getCompanies, getOnePagesReport, getPagination, getPagination2, searchByData } from "../../../../api/company";
import { SearchCompaniesFilter, searchCompaniesByMarkets, SearchCompaniesBySede, SearchCompaniesBySector, SearchCompaniesByAlliance, searchCompaniesByInvestors, searchCompaniesByFounder, SearchCompaniesByIndustry, SearchCompaniesByMercado, SearchCompaniesBySubtech } from "../../../../api/filtersCompanies";
import { Error500connection } from '../../../../components/organisms/Error/500';
import { Link } from 'react-router-dom';
import { DownloadReportsModal } from '../../../../components/molecules/Modal/DownloadReportsModal';

//store.dispatch({ type: '../../../../stores/counterSlice' })
export default function SearchCompanyAlter({ userId, isCollapsed, type }) {
  const pdf = require('html-pdf');
  const fs = require('fs');
  const matchDesktop = useMediaQuery("(min-width: 1200px)");
  const matchIpadPro = useMediaQuery("(min-width: 992px)");
  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");

  // State
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isSector, setIsSector] = useState(false)
  const [isIndustry, setIsIndustry] = useState(false);
  const [isMarket, setIsMarket] = useState(false);
  const [isSubtech, setIsSubtech] = useState(false);
  const [isMercado, setIsMercado] = useState(false);
  const [isSede, setIsSede] = useState(false)
  const [isAlliance, setIsAlliance] = useState(false)
  const [isInvestor, setIsInvestor] = useState(false)
  const [isFounder, setIsFounder] = useState(false)
  const [companies, setCompanies] = useState([]);
  const [companiesBySector, setCompaniesBySector] = useState([]);
  const [companiesByMarket, setCompaniesByMarket] = useState([]);
  const [companiesBySubtech, setCompaniesBySubtech] = useState([]);
  const [companiesByIndustry, setCompaniesByIndustry] = useState([]);
  const [companiesByMercado, setCompaniesByMercado] = useState([]);
  const [companiesBySede, setCompaniesBySede] = useState([]);
  const [companiesByAlliance, setCompaniesByAlliance] = useState([]);
  const [companiesByInvestor, setCompaniesByInvestor] = useState([]);
  const [companiesByFounder, setCompaniesByFounder] = useState([]);
  const [trends, setTrends] = useState([]);
  const [search, setSearch] = useState('');
  const [modalBody, setModalBody] = useState('')
  const [waypoint, setWaypoint] = useState({ img: Company, name: 'Empresas' })
  const [error, setError] = useState(false);
  const [pdfContent, setPdfContent] = useState([])
  const [arrIds, setArrIds] = useState([])
  const [showModal, setShowModal] = useState(false);

  // Valiables
  const ItemsList = ['Sectores', 'Industrias', 'Tecnología Primaría', 'Tecnología Secundaría', 'Mercados', 'Sede', 'Alianzas', 'Inversionistas', 'Fundadores']
  const filteredCompanies = companies.filter(company => company.name.toLowerCase().includes(search?.toLowerCase()))
  const location = useLocation();

  const history = useHistory();

  let arrOfDatas = [
    filteredCompanies,
    companiesBySector,
    companiesByIndustry,
    companiesByMarket,
    companiesBySubtech,
    companiesByMercado,
    companiesBySede,
    companiesByAlliance,
    companiesByFounder,
    companiesByInvestor
  ]
  let ids = [];

  // empieza para la paginacion ************************************************************
  const {
    dataArray,
    setNumResult,
    setDataArray,
    setIsThereData,

  } = useCardList({
    initaldataArray: [],
    initialnumResult: 0,
  });
  // Status
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [companiesLength, setCompaniesLength] = useState();

  // Variables
  const resultados = 10;

  //Get current post
  const indexOfLastPost = currentPage * resultados;
  const indexOfFirstPost = indexOfLastPost - resultados;
  // Peticiones
  useEffect(() => {
    if (type != "company") {
      getPagination(0)
        .then((res) => {
          setDataArray(res.data.companies);
          setCompaniesLength(res.data.length.empresas);
          setNumResult(res.data.companies.slice(0, resultados).length);
          setIsLoading(true);
          setIsThereData(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(true);
          setIsThereData(false);
        });
    } else {
    }
  }, []);

  function paginate(page) {
    setCurrentPage(page);
    dataArray.length < page * resultados
      ? setNumResult(dataArray.length)
      : setNumResult(page * resultados);
  }
  let filteredData = [];
  if (dataArray !== undefined) {
    if (type === "company") {
    } else {

    }
  }

  var currentData = filteredData.slice(indexOfFirstPost, indexOfLastPost);


  // termina la paginacion  ******************************************************************************
  const getCompaniesForPdf = () => {
    arrOfDatas.forEach(type => {
      if (type != undefined) {
        type.forEach(company => {
          ids.push(company.company_id)
        });
      }
    });
    let uniques = [... new Set(ids)]
    return uniques;
  }

  let companiesForPdf = getCompaniesForPdf()
  // Peticiones
  useEffect(() => {
    if (location?.state?.location === "/cid" || location?.state?.location === "/cid/company") {
      history.push({
        pathname: "/cid/search-company-alternative",
        state: {
          location: location.pathname,
          value: ""
        }
      })
    }
  }, [])


  useEffect(() => {
    if (location?.state?.location !== "/cid/search-company-alternative") {
      if (location?.state?.value?.length === 1) {
        setSearch(location.state.value)
      }
    } else {
      setSearch("");
    }

    if (window.location.href.includes("search-company-alternative")) {
      getPagination("all").then(res => setCompanies(res.data.companies)).catch(err => {
        console.log(companies);
        console.log(err);
        setError(true);
      })
      getTrends(userId)
        .then(result => {
          setTrends(result.data.companies);
          setIsLoading(false);
        }).catch(err => {
          console.log(err);
          setError(true);
        })
      document.getElementById("search-company-header").focus()
    }

  }, [location, userId])

  useEffect(() => {
    error ? document.getElementsByClassName('company-by-filter-0')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-0')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-1')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-1')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-2')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-2')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-3')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-3')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-4')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-4')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-5')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-5')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-6')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-6')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-7')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-7')[0].classList.remove('disabled')
    error ? document.getElementsByClassName('company-by-filter-8')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-8')[0].classList.remove('disabled')

  }, [error])

  // Funciones
  let arrayCompanies = [];
  function handleSearch(evn) {

    if (evn.target.value !== '') {
      setSearch(evn.target.value) // obtene  el valor del input
      const searchCompanyData = filteredCompanies.filter(filteredCompanies => evn.target.value); // filtrado del arreglo por el valor de input

      SearchCompaniesBySector(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesBySector(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-0')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-0')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
      SearchCompaniesByIndustry(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesByIndustry(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-1')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-1')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
      searchCompaniesByMarkets(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesByMarket(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-2')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-2')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
      SearchCompaniesBySubtech(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesBySubtech(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-3')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-3')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })

      SearchCompaniesByMercado(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesByMercado(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-4')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-4')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })

      SearchCompaniesBySede(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesBySede(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-5')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-5')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
      SearchCompaniesByAlliance(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesByAlliance(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-6')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-6')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
      searchCompaniesByInvestors(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesByInvestor(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-7')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-7')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
      searchCompaniesByFounder(userId, evn.target.value).then(res => {
        setIsLoading(false);
        setCompaniesByFounder(res.data.companies)
        res.data.companies === undefined ? document.getElementsByClassName('company-by-filter-8')[0].classList.add('disabled') : document.getElementsByClassName('company-by-filter-8')[0].classList.remove('disabled')
      }).catch(err => {
        console.log(err)
        setError(true);
      })
    } else {
      setSearch('');
      setIsLoading(false);
      document.getElementsByClassName('company-by-filter-0')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-1')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-2')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-3')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-4')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-5')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-6')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-7')[0].classList.remove('disabled')
      document.getElementsByClassName('company-by-filter-8')[0].classList.remove('disabled')
    }

  }

  function handleOpen(id) {
    setIsOpen(true)
    setModalBody(
      <>
        <div className="d-flex justify-content-center mb-4">
          <img src={AlertIcon} alt="Warning" />
        </div>
        <p className="mb-4 a-text-medium-second-neutral-90 text-center">No se podrán deshacer los cambios.<br />¿Seguro que deseas eliminar la empresa?</p>
        <div className="d-flex">
          <div onClick={() => setIsOpen(false)} className="w-100 mr-3">
            <Button type="button" classes="a-button-primary1" height={36} width="100%" text="Cancelar" />
          </div>
          <div onClick={() => { deleteCompany(id) }} className="w-100">
            <Button type="button" classes="a-button-light" height={36} width="100%" text="Eliminar" />
          </div>
        </div>
      </>
    )
  }


  function deleteCompany(companyId) {
    deleteCompanyApi(userId, companyId)
      .then(() => {
        setModalBody(
          <>
            <div className="d-flex justify-content-center mb-4">
              <img src={SuccessIcon} alt="Success Icon" />
            </div>
            <p className="mb-4 a-text-medium-second-neutral-90 text-center">Se ha eliminado la empresa correctamente</p>
            <div className='text-center' onClick={() => setIsOpen(false)}>
              <Button type="button" classes="a-button-primary1 text-small" height={36} width="50%" text="Aceptar" />
            </div>
          </>
        )
      }).catch(err => console.log(err))
  }
  function handleFilter(evn) {
    switch (evn.target.value) {
      case '0':
        setIsSector(!isSector)
        break;
      case '1':
        setIsIndustry(!isIndustry)
        break;
      case '2':
        setIsMarket(!isMarket)
        break;
      case '3':
        setIsSubtech(!isSubtech)
        break;
      case '4':
        setIsMercado(!isMercado)
        break;
      case '5':
        setIsSede(!isSede)
        break;
      case '6':
        setIsAlliance(!isAlliance)
        break;
      case '7':
        setIsInvestor(!isInvestor)
        break;
      case '8':
        setIsFounder(!isFounder)
        break;
      default:
        break;
    }
  }

  function handleKeyDown(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode === 8 && search !== "")) {
      setIsLoading(true);
    }
  }

  const handleBack = () => {
    setIsOpen(false);
  };

  function fullSelectView(id) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className="select-pdf-modal">
          <div>
            <img
              src={BackIcon}
              alt="Regresar"
              className="nav-back"
              onClick={handleBack}
            />
            <p className="frame-back a-text-medium-second-neutral-90" onClick={handleBack}> regresar </p>
          </div>
          <div>
            <p className="p-1-text a-text-medium-second-neutral-90">Plantilla 1</p>
            <a href={`${PDF_PATH}/get-one-page-pdf/?companyId=${id}&empresa= a`} target="_blank">
              <img
                className="p-1-frame-select"
                src={Plantilla1}
                alt="Plantilla 1"
              />
            </a>
          </div>
          <div>
            <p className="p-2-text a-text-medium-second-neutral-90">Plantilla 2</p>
            <a href={`${PDF_PATH}/get-one-page/?companyId=${id}&empresa=a`} target="_blank">
              <img
                className="p-2-frame-select"
                src={Plantilla2}
                alt="Plantilla 2"
              />
            </a>
          </div>
        </div>
      </>
    );
  }

  function miniSelectView(id) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className="select-pdf-modal-m" >
          <div>
            <p className="mp-1-text a-text-medium-second-neutral-90">Plantilla 1</p>
            <a href={`${PDF_PATH}/get-one-page-pdf/?companyId=${id}&empresa= a`} target="_blank">
              <img
                className="mp-1-frame-select"
                src={Plantilla1}
                alt="Plantilla 1"
              />
            </a>
          </div>
          <div>
            <p className="mp-2-text a-text-medium-second-neutral-90">Plantilla 2</p>
            <a href={`${PDF_PATH}/get-one-page/?companyId=${id}&empresa=a`} target="_blank">
              <img
                className="mp-2-frame-select"
                src={Plantilla2}
                alt="Plantilla 2"
              />
            </a>
          </div>
        </div>
      </>
    );
  }

  return (
    <Grid container alignItems="center" justify="center" spacing={0} >
      <Header isCollapsed={isCollapsed} text='Buscar empresa' onKeyDown={handleKeyDown} onChange={error ? "" : (e) => handleSearch(e)} value={search} id='search-company-header' focus={true} />
      <Grid item xs={12} className='background-lg-first-neutral-10 br-tl-2 br-tr-2'>
        <div className='background-lg-white mt-lg-4 mx-lg-4 pt-4 br-tl-2 br-tr-2'>
          <Grid container justify="center" spacing={0}>
            <Grid item xs={10} lg={12}>
              <div className="d-flex mr-lg-4">
                <span style={{ "whiteSpace": "nowrap" }} className="a-text-regular-first-neutral-100 mt-1 ml-lg-4 mr-3">Filtrar por:</span>
                <div className="d-flex scroll-overflow">
                  {ItemsList.map((res, i) => (
                    <div key={i} className="mr-2">
                      <input type="checkbox" className='d-none input-checkbox' id={`company-by-filter-${i}`} value={i} onChange={(e) => handleFilter(e)} />
                      <label className={`btn-checkbox-search cursor-pointer ${matchPhone ? "text-xs" : "text-small"} company-by-filter-${i}`} style={{ "whiteSpace": "nowrap" }} htmlFor={`company-by-filter-${i}`} >{res}</label>
                    </div>
                  ))}
                </div>

              </div>
              {!isLoading && !error && search === "" ?
                <h4 className={"my-4 a-text-bold-second-neutral-70 ml-lg-4 md-text-medium"}>Tendencias en WIKICID</h4> : <div className="mb-3"></div>
              }
            </Grid>
          </Grid>
        </div>
      </Grid>
      {
        !error ?
          <>

            <Grid item xs={12} className='background-lg-first-neutral-10 scroll-overflow h-100vh-search-company' onScroll={e => e.target.scrollTop === 0 ? setWaypoint({ img: Company, name: 'Empresas' }) : ""}>
              <div className='background-lg-white mx-lg-4 mb-lg-4 br-br-2 br-bl-2'>
                <Grid container alignItems="center" justify="center" spacing={0}>
                  <Grid item xs={10} lg={12} className='pb-4 pb-lg-0 px-4'>
                    {search === '' ?
                      isLoading ?
                        <CardSkeleton num={10} />
                        : <FilteredCompany data={trends} noSlice={true} onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />
                      : isLoading ?
                        <div className=''>
                          <CardSkeleton num={10} />
                        </div>
                        :
                        <>
                          <button className="a-button-secondary p-2 br-1 a-text-medium" onClick={() => setShowModal(true)}>Obtener reportes</button>
                          {isSector || isMarket || isSede || isAlliance || isInvestor || isFounder || isIndustry || isMercado || isSubtech ? '' :
                            <>
                              {/* <Waypoint onLeave={() => setWaypoint({ img: Company, name: 'Empresas' })}></Waypoint> */}
                              <FilteredCompany data={filteredCompanies} image={Company} name='Empresas' noName={true} onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />
                              {/* <Waypoint onLeave={() => setWaypoint({ img: Sector, name: 'Sectores' })}></Waypoint> */}
                              <FilteredCompany data={companiesBySector} image={Sector} name='Sectores' type='sector' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />

                              <FilteredCompany data={companiesByIndustry} image={Company} name='Industrias' type='industria' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />

                              {/* <Waypoint onLeave={() => setWaypoint({ img: Market, name: 'Tecnologías' })}></Waypoint> */}
                              <FilteredCompany data={companiesByMarket} image={Market} name='Tecnología Primaría' type='tecnología' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />

                              <FilteredCompany data={companiesBySubtech} image={Market} name='Tecnología Secundaría' type='subtech' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />

                              <FilteredCompany data={companiesByMercado} image={Mercado} name='Mercados' type='mercado' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />

                              {/* <Waypoint onLeave={() => setWaypoint({ img: Sede, name: 'Sede' })}></Waypoint> */}
                              <FilteredCompany data={companiesBySede} image={Sede} name='Sede' type='sede' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />
                              {/* <Waypoint onLeave={() => setWaypoint({ img: Company, name: 'Alianzas' })}></Waypoint> */}
                              <FilteredCompany data={companiesByAlliance} image={Alliance} name='Alianzas' type='alianza' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />
                              {/* <Waypoint onLeave={() => setWaypoint({ img: Company, name: 'Inversionistas' })}></Waypoint> */}
                              <FilteredCompany data={companiesByInvestor} image={Investors} name='Inversionistas' type='inversionista' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />
                              {/* <Waypoint onLeave={() => setWaypoint({ img: Founder, name: 'Fundadores' })}></Waypoint> */}
                              <FilteredCompany data={companiesByFounder} image={Founder} name='Fundadores' type='fundador' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} />
                            </>
                          }
                          {isSector ? <FilteredCompany data={companiesBySector} image={Sector} name='Sectores' type='sector' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isIndustry ? <FilteredCompany data={companiesByIndustry} image={Company} name='Industrias' type='industria' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isMarket ? <FilteredCompany data={companiesByMarket} image={Market} name='Tecnología Primaría' type='tecnología' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isSubtech ? <FilteredCompany data={companiesBySubtech} image={Market} name='Tecnología Secundaría' type='tecnología' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isMercado ? <FilteredCompany data={companiesByMercado} image={Mercado} name='Mercados' type='mercado' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}

                          {isSede ? <FilteredCompany data={companiesBySede} image={Sede} name='Sede' type='sede' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isAlliance ? <FilteredCompany data={companiesByAlliance} image={Company} name='Alianzas' type='alianza' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isInvestor ? <FilteredCompany data={companiesByInvestor} image={Company} name='Inversionistas' type='inversionista' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                          {isFounder ? <FilteredCompany data={companiesByFounder} image={Founder} name='Fundadores' type='fundador' onClick={handleOpen} fullView={fullSelectView} miniView={miniSelectView} /> : ''}
                        </>
                    }
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {
              search === '' ?
                <Paginations
                  postPerPage={resultados}
                  totalCompanies={dataArray.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
                :
                ""
            }
            <Modal open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
              <div className="modal">{modalBody}</div>
            </Modal>
          </>
          :
          <Grid item xs={12} className='background-lg-first-neutral-10 br-br-2 br-bl-2 pb-4'>
            <div className='background-lg-white mx-lg-4 pt-4 br-br-2 br-bl-2'>
              <Error500connection />
            </div>
          </Grid>
      }
      <Modal
        open={showModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-download">
          <DownloadReportsModal
            setShowModal={setShowModal}
            filteredCompanies={filteredCompanies}
            companiesBySector={filteredCompanies}
            companiesByIndustry={filteredCompanies}
            companiesByTech={filteredCompanies}
            companiesByMercado={filteredCompanies}
            companiesBySubtech={filteredCompanies}
            companiesBySede={filteredCompanies}
            companiesByAlliance={filteredCompanies}
            companiesByFounder={filteredCompanies}
            companiesByInvestor={filteredCompanies}
            setArrIds={setArrIds}
            companiesForPdf={companiesForPdf}
          />
        </div>
      </Modal>
    </Grid>
  )
}
