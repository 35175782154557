import React from 'react'
import { Skeleton } from '@material-ui/lab';

export const SkeletonProgramPreview = () => {
  return (
    <>
          <div
            className={` mb-3 h-a d-flex br-2  px-4  box-shadow-lg-all card  py-md-3 py-2}`}
          >
            <div className=" w-100">
              <div
                className={`d-flex justify-content-between align-items-center `}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className={"d-flex align-items-center mr-2"} >
                      <Skeleton variant="circle" width="28px" height="28px" animation="wave" />
                  </div>

                  <Skeleton variant="h3" width="300px" animation="wave" />

                </div>

                <Skeleton variant="rect" width={50} height={50} animation="wave" />
              </div>
            </div>
          </div>
        </>
  )
}
