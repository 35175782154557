// Material
import { Grid } from '@material-ui/core';

// Componens
import { CardList } from "../../../../components/organisms/CardList/Company.js"

export default function CompanyList(e) {
    return (
        <Grid container justify="center" spacing={0}>
            <Grid item xs={12}>
                <CardList type='company' titulo='empresas' lastState={e.location.state} userId={e.userId} isCollapsed={e.isCollapsed} linkSearch='/cid/search-company-alternative' linkAdd='/cid/add-company' />
            </Grid>
        </Grid >
    )
}