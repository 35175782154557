import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    width: "105px",
    height: "35px",
    padding: "0px",
    fontFamily: "Roboto",
    overflow: "visible",
    marginBlockStart: "8px",
  },
  switchBase: {
    marginInlineStart: "5px",
    marginBlockStart: "5px",
    color: "#818181",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#355471",
        border: "1px solid #FFFF",
      },
    },
  },
  thumb: {
    color: "white",
    width: "25px",
    height: "25px",
  },
  track: {
    borderRadius: "20px",
    width: "100px",
    backgroundColor: "#FFFF",
    border: "1px solid #a8aba8",
    opacity: "1 !important",
    "&:after, &:before": {
      fontFamily: "Roboto-regular",
      color: "white",
      fontSize: "14px",
      position: "absolute",
      top: "9px",
    },
    "&:after": {
      content: "'Activa'",
      left: "9px",
    },
    "&:before": {
      color: "#355471",
      content: "'Inactiva'",
      right: "10px",
    },
  },
  checked: {
    color: "#23bf58 !important",
    transform: "translateX(64px) !important",
  },
});
