import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, useMediaQuery, IconButton } from '@material-ui/core';
import Header from '../../../../components/molecules/Header';
import { useLocation } from 'react-router-dom';
import Close from '../../../../assets/img/closeFilter.svg';
import { useAuth } from '../../../../hooks/useAuth';
import ProfileUser from '../../../../components/organisms/ProfileUser/index.js';
import { useBusquedaColegas } from '../../../../hooks/useBusquedaColegas';
import SearchUsers from '../../../../components/organisms/ProfileUser/SearchUsers';

export default function FollowProfile(e) {
	const location = useLocation();
	const [profile, setPorfile] = useState([]);
	const [loadProfile, setLoadPorfile] = useState([]);
	const matchIpadPro = useMediaQuery('(min-width: 992px)');
	const matchIpadMini = useMediaQuery('(min-width: 768px)');
	const matchPhone = useMediaQuery('(max-width: 767px)');
	const user = useAuth();
	const mini = useMediaQuery('(min-width:700px)');
	const { userId } = user;
	const { buscarColegas, inputSearch, buscarSeguidor, cerrarBusqueda, desplegarBusqueda } = useBusquedaColegas();

	useEffect(() => {
		setLoadPorfile(true);
		setPorfile(location.state.profile);
		setLoadPorfile(false);
		cerrarBusqueda();
	}, [location.state.profile]);


	function handleBack(e) {
		e.preventDefault();
		window.history.back();
	}

	return (
		<Grid
			container
			justify='center'
			spacing={0}
			className=''
		>
			{matchIpadPro && buscarColegas === true ? (
				<Header
					id={`buscar`}
					onChange={buscarSeguidor}
					isCollapsed={e.isCollapsed}
					onClick={desplegarBusqueda}
					text='Buscar colega'
				/>
			) : (matchIpadMini || matchPhone) && buscarColegas === true ? (
				<></>
			) : (
				<Header
					isCollapsed={e.isCollapsed}
					onClick={desplegarBusqueda}
					text='Buscar colega'
				/>
			)}

			<div
				className={`${
					mini
						? 'd-none'
						: 'w-100 d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-1 mx-md-5 mx-4 py-3 mt-2 '
				}`}
			>
				{' '}
				<p className='h3-md text-big a-text-medium-first-primary-60'>Perfil</p>{' '}
				<Link onClick={handleBack}>
					{' '}
					<IconButton>
						<img
							src={Close}
							alt='PDF Icon'
							className='cursor-pointer '
							width='24px'
						/>
					</IconButton>
				</Link>
			</div>

			<Grid
				item
				xs={12}
				lg={12}
				className='background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2'
			>
				<div className='background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all '>
					{/* {loadProfile ? (
            <div />
          ) : (
            <ProfileUser
              userId={e.userId}
              userFollow={profile.user_id}
              isCollapsed={e.isCollapsed}
              linkSearch="/cid/search-company"
              type={"follow"}
            />
          )} */}
					{buscarColegas === false ? (
						loadProfile ? (
							<div />
						) : (
							<ProfileUser
								userId={userId}
								userFollow={profile.user_id}
								isCollapsed={e.isCollapsed}
								linkSearch='/cid/search-company'
								type={'follow'}
							/>
						)
					) : (
						<SearchUsers
							userId={userId}
							cerrarBusqueda={cerrarBusqueda}
							inputHeader={inputSearch}
							buscarColegas={buscarColegas}
						></SearchUsers>
					)}
				</div>
			</Grid>
		</Grid>
	);
}
