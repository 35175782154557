//TODO: cambiar la api de la seccion de noticias, aun usa la api de microsoft
// React
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Material
import { Grid, useMediaQuery } from "@material-ui/core";

// Images
import Noticias from "../../../assets/img/ilustraciones-home-carso/noticias.svg";
import Blog from "../../../assets/img/ilustraciones-home-carso/ilustracion-blog.svg";
import Pregunta from "../../../assets/img/ilustraciones-home-carso/preguntas.svg";
// Componens
import Header from "../../../components/molecules/Header";
import CardHome from "../../../components/molecules/Card/CardHome/CARSO/index";
import CardProx from "../../../components/molecules/Card/CardProx/index";
import News from "../../../components/molecules/Card/News";
import CardBlogVertical from "../../../components/molecules/Card/CardBlog/CardOptionTwo";
import CardBlog from "../../../components/molecules/Card/CardBlog/index";
import SkeletonCardQuestionHome from "../../../components/molecules/Skeleton/SkeletonQuestions/SkeletonCardQuestionHome";
import { SkeletonVerticalBlog } from "../../../components/molecules/Skeleton/SkeletonBlog/VerticalBlog"

import { useAuth } from "../../../hooks/useAuth";
//API
import { getQuestions } from "../../../api/questions";
import { GNewsAdapter } from "../../../api/news";
import { getBlogs } from "../../../api/blogs";

export default function HomeCID(e) {
  // Status
  const [isActive, setIsActive] = useState(true);
  const matchDesktop = useMediaQuery("(min-width: 1200px)");
  const matchIpadPro = useMediaQuery("(min-width: 992px)");
  const matchIpadMini = useMediaQuery("(max-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");
  const user = useAuth();
  const userId = user.userId;
  const [currentData, setCurrentData] = useState([]);
  const [news, setNews] = useState([]);
  const [blog, setBlog] = useState([]);
  const [loadQuestion, setLoadQuestion] = useState(false);
  const [loadNews, setLoadNews] = useState(false);
  const [loadBlog, setLoadBlog] = useState(false);

  let history = useHistory();

  useEffect(() => {
    getQuestion(userId);
    getNew();
    getAllBlogs(userId);
  }, []);
  // Funciones
  async function getNew() {
    try {
      if (!localStorage.getItem('general')) {
        const getNewsAdapter = new GNewsAdapter();
        const { data: { articles } } = await getNewsAdapter.getNews('financieras')
        setNews(articles)
        localStorage.setItem('general', JSON.stringify(articles));
      } else {
        const noticias = JSON.parse(localStorage.getItem('general'))
        setNews(noticias)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadNews(true)
    }
  }

  async function getQuestion(userId) {
    try {
      const response = await getQuestions(userId);
      setCurrentData(response.data.questions[0]);
      if (response.data.questions[0] != undefined) {
        setLoadQuestion(true);
      }
    } catch (error) { }
  }

  async function getAllBlogs(userId) {
    try {
      const response = await getBlogs(userId, false);
      setBlog(response.data.blogs[0]);
      if (response.data.blogs[0] != undefined) {
        setLoadBlog(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (e.location.state != undefined) {
    const state = e.location.state;
    return (
      <Redirect
        to={{
          pathname: `/cid/company/${state.array[state.index]}`,
          state: {
            array: state.array,
            index: state.index,
            location: state.location,
          },
        }}
      />
    );
  }

  return (
    <Grid container justify="center" alignContent="flex-start" spacing={0}>
      {/* HEADER */}
      <Header
        isCollapsed={e.isCollapsed}
        link={isActive ? "/cid/search-company" : "/cid/search-analysis"}
        text={`Buscar ${isActive ? "empresa" : "análisis"}`}
      />
      <Grid
        item
        xs={12}
        md={11}
        lg={12}
        className={`h-100vh-header scroll-lg-overflow background-lg-first-neutral-10 br-2 ${matchIpadPro ? "py-lg-4 px-lg-4 " : ""
          }`}
      >
        <div
          className={`  d-flex flex-column justify-content-center pb-6 ${matchIpadMini ? `${matchPhone ? "" : "mt-5"} w-100 ` : ""
            }`}
        >
          <div
            className={`d-flex flex-column br-3 ${matchPhone
                ? "py-7 px-7 mt-2 ml-2 "
                : " box-shadow-all bck-color-white py-8 px-8 "
              } `}
          >
            <p
              className={`a-text-bold-first-primary-60 ${matchIpadMini ? "h3" : "text-medium"
                }`}
            >
              ¡Vive la experiencia WikiCID!
            </p>
            <p
              className={`mt-8 a-text-regular-second-neutral-70 ${matchIpadMini
                  ? `${matchPhone ? "text-big " : "h3 "} `
                  : "text-small"
                }`}
            >
              Tenemos estas secciones para ti.
            </p>

            <p
              className={`a-text-medium-first-primary-60 h3 mb-3 ${matchIpadMini
                  ? `${matchPhone ? "text-big " : "h3 "} mt-7 `
                  : "text-normal mt-6"
                }`}
            >
              <Link
                to={`/cid/carso/questions/list`}
                className="text-decoration-none a-text-medium-first-primary-60"
              >
                Preguntas
              </Link>
            </p>
            {matchPhone ? (
              <div>
                <div className={`d-flex flex-column w-100`}>
                  <Link
                    to={`/cid/carso/questions/list`}
                    className="text-decoration-none"
                  >
                    <div
                      className={`d-flex flex-column box-shadow-all br-b-3 br-t-3 mb-4`}
                    >
                      <img
                        src={Pregunta}
                        height={190}
                        className={`br-t-3 bg-blue-card`}
                      ></img>
                      <div
                        style={{ minHeight: "53px" }}
                        className={`bck-color-white d-flex align-items-center br-b-3`}
                      >
                        <p
                          className={`a-text-regular-second-neutral-30 text-small mt-2 pb-2 mx-auto text-center`}
                        >
                          La comunidad carso <br></br> te ayudará con tus dudas.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className={matchPhone ? `` : `d-flex`}>
              <Link
                to={`/cid/carso/questions/list`}
                className="text-decoration-none"
              >
                <div
                  className={
                    matchPhone
                      ? "d-none"
                      : "br-3 box-shadow-all d-flex flex-column mr-3"
                  }
                  style={{ maxWidth: "261px" }}
                >
                  <img
                    className="question-img-card"
                    width={261}
                    src={Pregunta}
                  ></img>
                  <p
                    className={`a-text-regular-second-neutral-30 text-small mt-2 pb-2 mx-auto text-center`}
                  >
                    La comunidad carso <br></br> te ayudará con tus dudas.
                  </p>
                </div>
              </Link>

              {/* {loadQuestion ? <CardHome pregunta={currentData} /> : " "} */}
              {loadQuestion ? (
                <CardHome pregunta={currentData} />
              ) : (
                <div
                  className="bg-blue-card br-3 box-shadow-all d-flex flex-column justify-content-center"
                  style={{ minHeight: "190px" }}
                >
                  <div
                    className="d-flex flex-column bck-color-white br-3 my-3 mx-4"
                    style={{ minWidth: "324px" }}
                  >
                    <SkeletonCardQuestionHome num={1} />
                  </div>
                </div>
              )}
            </div>
            {/* Espacio para commit */}
            {/** PREGUNTAS */}
            <Link
              to={`/cid/carso/questions/list`}
              className={`${matchIpadPro ? "a-text-regular-first-primary-60" : "d-none"
                }`}
            >
              <p className={`mt-4 text-normal a-text-regular-first-primary-60`}>
                Ver más ...
              </p>
            </Link>
          </div>
          <Link
            to={`/cid/carso/questions/list`}
            className={`ml-4 pl-3 ${matchPhone || matchIpadMini
                ? "a-text-regular-first-primary-60"
                : "d-none"
              }`}
          >
            <p className={`mt-3 text-normal a-text-regular-first-primary-60`}>
              Ver más ...
            </p>
          </Link>
          <div
            className={` ${matchPhone ? "hr-ocean-2 mt-4 mx-auto" : ""} `}
          ></div>

          {/** NOTICIAS */}
          {matchPhone ? (
            <div className="py-7 px-7 mt-2 ml-2 ">
              {/* <p
                className={`a-text-medium-first-primary-60 text-big mt-2 mb-3`}
              >
                Noticias
              </p> */}
              <p
                className={`a-text-medium-first-primary-60 h3 mb-3 ${matchIpadMini
                    ? `${matchPhone ? "text-big " : "h3 "}`
                    : "text-normal"
                  }`}
              >
                <Link
                  to={`/cid/carso/news`}
                  className="text-decoration-none a-text-medium-first-primary-60"
                >
                  Noticias
                </Link>
              </p>
              {/* <div className={`d-flex justify-content-evenly mb-4`}> */}
              <div className={`d-flex mb-4`}>
                <div className={`d-flex flex-column w-100`}>
                  <Link to={`/cid/carso/news`} className="text-decoration-none">
                    <div
                      className={`d-flex flex-column box-shadow-all br-b-3 br-t-3 mb-4`}
                    >
                      <img
                        src={Noticias}
                        height={190}
                        className={`br-t-3 bg-blue-card`}
                      ></img>
                      <div
                        style={{ minHeight: "53px" }}
                        className={`bck-color-white d-flex align-items-center br-b-3`}
                      >
                        <p
                          className={`a-text-regular-second-neutral-30 text-small mt-2 pb-2 mx-auto text-center`}
                        >
                          Te mantendremos informado
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div>
                  <img
                    src={Noticias}
                    width={125}
                    height={104}
                    className={` box-shadow-all bg-blue-card w-100 br-2`}
                  ></img>
                </div> */}
              </div>
              <div className="mr-4 mb-2">
                <p className={`a-text-regular-first-primary-60 text-medium`}>
                  Conoce sobre ...
                </p>
                <ul
                  className={`a-text-medium-first-primary-60 text-medium mt-3 ml-14`}
                >
                  <li
                    className={`cursor-pointer`}
                    onClick={() => {
                      history.push({
                        pathname: "/cid/carso/news",
                        state: { category: "tecnologia" },
                      });
                    }}
                  >Tecnología</li>
                  <li
                    className={`mt-2 cursor-pointer`}
                    onClick={() => {
                      history.push({
                        pathname: "/cid/carso/news",
                        state: { category: "entretenimiento" },
                      });
                    }}
                  >Entretenimiento</li>
                  <li
                    className={`mt-2 cursor-pointer`}
                    onClick={() => {
                      history.push({
                        pathname: "/cid/carso/news",
                        state: { category: "innovacion" },
                      });
                    }}
                  >Innovación</li>
                  <li
                    className={`mt-2 cursor-pointer pb-4`}
                    onClick={() => {
                      history.push({
                        pathname: "/cid/carso/news",
                        state: { category: "energia" },
                      });
                    }}
                  >Energía</li>
                </ul>
              </div>
              {loadNews ? (
                <News
                  author={news[0].source.name}
                  description={news[0].description}
                  image={!!news[0].image && Object.values(news[0].image)}
                  url={news[0].link}
                  title={news[0].title}
                  date={news[0].publishedAt}
                  path="HomeCarso"
                />
              ) : (
                ""
              )}
              {/*<CardNews/>*/}
            </div>
          ) : (
            <div
              className={`d-flex flex-column br-3 box-shadow-all bck-color-white mt-6 py-8 px-8`}
            >
              {/* <p className={`a-text-medium-first-primary-60 h3 mb-3`}>
                Noticias
              </p> */}
              <p
                className={`a-text-medium-first-primary-60 h3 mb-3 ${matchIpadMini
                    ? `${matchPhone ? "text-big " : "h3 "} mt-7 `
                    : "text-normal "
                  }`}
              >
                <Link
                  to={`/cid/carso/news`}
                  className="text-decoration-none a-text-medium-first-primary-60"
                >
                  Noticias
                </Link>
              </p>
              <div className={`d-flex`}>
                <div className={`d-flex flex-column mr-9`}>
                  <div
                    className={`d-flex flex-column box-shadow-all br-b-3 br-t-3`}
                  >
                    <Link
                      to={`/cid/carso/news`}
                      className="text-decoration-none"
                    >
                      <div
                        className={`d-flex flex-column box-shadow-all br-b-3 br-t-3`}
                      >
                        <img
                          src={Noticias}
                          width={261}
                          height={190}
                          className={`br-t-3 bg-blue-card`}
                        ></img>
                        <div
                          style={{ minHeight: "53px" }}
                          className={`bck-color-white d-flex align-items-center br-b-3`}
                        >
                          <p
                            className={`a-text-regular-second-neutral-30 text-small mt-2 pb-2 mx-auto text-center`}
                          >
                            Te mantendremos informado.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <p
                    className={`a-text-regular-first-primary-60 text-small ml-4 mt-4`}
                  >
                    Conoce sobre ...
                  </p>
                  <ul
                    className={`a-text-medium-first-primary-60 text-small mt-3 ml-8`}
                  >
                    <li
                      className={`cursor-pointer`}
                      onClick={() => {
                        history.push({
                          pathname: "/cid/carso/news",
                          state: { category: "tecnologia" },
                        });
                      }}
                    >
                      Tecnología
                    </li>
                    <li
                      className={`mt-2 cursor-pointer`}
                      onClick={() => {
                        history.push({
                          pathname: "/cid/carso/news",
                          state: { category: "entretenimiento" },
                        });
                      }}
                    >
                      Entretenimiento
                    </li>
                    <li
                      className={`mt-2 cursor-pointer`}
                      onClick={() => {
                        history.push({
                          pathname: "/cid/carso/news",
                          state: { category: "innovacion" },
                        });
                      }}
                    >
                      Innovación
                    </li>
                    <li
                      className={`mt-2 cursor-pointer`}
                      onClick={() => {
                        history.push({
                          pathname: "/cid/carso/news",
                          state: { category: "energia" },
                        });
                      }}
                    >
                      Energía
                    </li>
                  </ul>
                </div>
                {/*<CardNews/>*/}
                {/*loadNews ? <CardNews dataNew={news}/> : ""*/}
                {/* El cambio en la línea de abajo, considero pertinente mantenerlo para que valide si llegó una imagen o no */}
                {loadNews ? (
                  <News
                    author={news[0].source.name}
                    description={news[0].description}
                    image={!!news[0].image && Object.values(news[0].image)}
                    url={news[0].link}
                    title={news[0].title}
                    date={news[0].publishedAt}
                    path="HomeCarso"
                  />
                ) : (
                  <SkeletonVerticalBlog num={1} />
                )}
              </div>
              <Link
                to={`/cid/carso/news`}
                className={`${matchIpadPro ? "a-text-regular-first-primary-60" : "d-none"
                  }`}
              >
                <p
                  className={`mt-4 text-normal a-text-regular-first-primary-60`}
                >
                  Ver más ...
                </p>
              </Link>
            </div>
          )}

          <Link
            to={`/cid/carso/news`}
            className={`ml-4 pl-3  ${matchPhone || matchIpadMini
                ? "a-text-regular-first-primary-60"
                : "d-none"
              }`}
          >
            <p className={`mt-3 text-normal a-text-regular-first-primary-60`}>
              Ver más ...
            </p>
          </Link>
          <div
            className={` ${matchPhone ? "hr-ocean-2 mt-4 mx-auto" : ""} `}
          ></div>

          {/**BLOG */}
          {matchPhone ? (
            <div className="py-7 px-7 mt-2 ml-2 ">
              <p
                className={`a-text-medium-first-primary-60 text-big mt-2 mb-3`}
              >
                <Link
                  to={`/cid/carso/blogs/list`}
                  className="text-decoration-none a-text-medium-first-primary-60 h3"
                >
                  Blog
                </Link>
              </p>
              <div className={`d-flex flex-column w-100`}>
                <Link
                  to={`/cid/carso/blogs/list`}
                  className="text-decoration-none"
                >
                  <div
                    className={`d-flex flex-column box-shadow-all br-b-3 br-t-3 mb-4`}
                  >
                    <img
                      src={Blog}
                      height={190}
                      className={`br-t-3 bg-blue-card`}
                    ></img>
                    <div
                      style={{ minHeight: "53px" }}
                      className={`bck-color-white d-flex align-items-center br-b-3`}
                    >
                      <p
                        className={`a-text-regular-second-neutral-30 text-small mt-2 pb-2 mx-auto text-center`}
                      >
                        ¿Quieres escribir o leer?
                      </p>
                    </div>
                  </div>
                </Link>

                {loadBlog ? (
                  <CardBlogVertical
                    info={blog}
                    userId={userId}
                    path="HomeCarso"
                  />
                ) : (
                  <SkeletonVerticalBlog num={1} />
                )}
              </div>
            </div>
          ) : (
            <div
              className={`d-flex flex-column br-3 box-shadow-all bck-color-white mt-6 py-8 px-8 `}
            >
              <p className={`a-text-medium-first-primary-60 h3 mt-2 mb-3`}>
                <Link
                  to={`/cid/carso/blogs/list`}
                  className="text-decoration-none a-text-medium-first-primary-60"
                >
                  Blog
                </Link>
              </p>
              <div className={`d-flex`}>
                <div className={`d-flex flex-column mr-9`}>
                  <Link
                    to={`/cid/carso/blogs/list`}
                    className="text-decoration-none"
                  >
                    <div
                      className={`d-flex flex-column box-shadow-all br-b-3 br-t-3`}
                    >
                      <img
                        src={Blog}
                        width={261}
                        height={190}
                        className={`br-t-3 bg-blue-card`}
                      ></img>
                      <div
                        style={{ minHeight: "53px" }}
                        className={`bck-color-white d-flex align-items-center br-b-3`}
                      >
                        <p
                          className={`a-text-regular-second-neutral-30 text-small mt-2 pb-2 mx-auto text-center`}
                        >
                          ¿Quieres escribir o leer?
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                {loadBlog ? (
                  matchPhone || matchIpadMini || matchIpadPro ? (
                    <CardBlogVertical
                      info={blog}
                      userId={userId}
                      path="HomeCarso"
                    />
                  ) : (
                    <CardBlog info={blog} userId={userId} path="HomeCarso" />
                  )
                ) : (
                  // ""
                  <SkeletonVerticalBlog num={1} />
                )}
              </div>
              <Link
                to={`/cid/carso/blogs/list`}
                className={`${matchIpadPro ? "a-text-regular-first-primary-60" : "d-none"
                  }`}
              >
                <p
                  className={`mt-4 text-normal a-text-regular-first-primary-60`}
                >
                  Ver más ...
                </p>
              </Link>
            </div>
          )}
          <Link
            to={`/cid/carso/blogs/list`}
            className={` ml-4 pl-3 ${matchPhone || matchIpadMini
                ? "a-text-regular-first-primary-60 mt-3 "
                : "d-none"
              }`}
          >
            <p className={` text-normal a-text-regular-first-primary-60`}>
              Ver más ...
            </p>
          </Link>

          <div
            className={` ${matchPhone ? "hr-ocean-2 mt-4 mx-auto" : ""} `}
          ></div>

          {/** PODCAST */}
          {matchPhone && (
            <div className="py-7 px-7 mt-2 ml-2 ">
              <p className={`a-text-medium-first-primary-60 h3 mt-6 mb-3`}>
                Podcast
              </p>
              <div className={`prox-card d-flex justify-content-center w-100`}>
                <div className={`inner-prox-card`}>
                  <p className={`a-text-regular-second-primary-50 text-normal`}>
                    ¡Próximamente!
                  </p>
                </div>
              </div>
            </div>
          )}
          <CardProx
            title="Podcast"
            text1="Pronto verás lo que tenemos preparado"
            text2="Estámos trabajando esta sección para ti."
          ></CardProx>

          {/** EVENTOS */}
          {matchPhone && (
            <div className="py-7 px-7 mt-2 ml-2 ">
              <p className={`a-text-medium-first-primary-60 h3 mt-6 mb-3`}>
                Eventos
              </p>
              <div className={`prox-card d-flex justify-content-center w-100 `}>
                <div className={`inner-prox-card`}>
                  <p className={`a-text-regular-second-primary-50 text-normal`}>
                    ¡Próximamente!
                  </p>
                </div>
              </div>
            </div>
          )}
          <CardProx
            title="Eventos"
            text1="Pronto verás lo que tenemos preparado"
            text2="Estámos trabajando esta sección para ti."
          ></CardProx>
        </div>
      </Grid>
    </Grid>
  );
}
