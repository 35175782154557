import { useEffect, useState } from "react";
import { TextInput } from "../../../atoms/Input/TextInput.js";
import { Label } from "./Label.js";
import { Button } from '../../../atoms/Button';

export function Contacto({ data, setData }) {
  // const [innerDisabled, setInnerDisabled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [textButton, setTextButton] = useState("Agregar contacto adicional");

  const plantilla = {
    name: "",
    job: "",
    email: "",
    phone_number: "",
    notes: "",
    projects: "",
  };

  useEffect(() => {
    if (data.length === 0) {
      setData([{ ...plantilla }]);
    }
  }, []);

  useEffect(() => {
    let allFilled = true;

    for (let i = 0; i < data.length; i++) {
      if (data[i].name === "" && data[i].job === "" && data[i].email === "" && data[i].phone_number === "" && data[i].notes === "" && data[i].projects === "") {
        allFilled = false;
        break;
      }
    }

    if (!allFilled && data.length === 1) {
      setDisabled(true);
      setTextButton("Agregar contacto adicional");
    } else if (!allFilled && data.length > 1) {
      setDisabled(false);
      setTextButton("Cancelar");
    } else {
      setDisabled(false);
      setTextButton("Agregar contacto adicional");
    }

  }, [data]);

  function setValues(e, index, key) {
    const newData = [...data];
    newData[index][key] = e;
    setData(newData);
  }

  function handleButton() {
    if (disabled) return;
    if (textButton === "Cancelar") {
      const newData = [...data];
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].name === "" && newData[i].job === "" && newData[i].email === "" && newData[i].phone_number === "" && newData[i].notes === "" && newData[i].projects === "") {
          newData.splice(i, 1);
          i--;
        }
      }
      setData(newData);
    } else {
      setData([...data, { ...plantilla }]);
    }
  }

  return (
    <div className="d-flex flex-column"

    >
      {data.map((item, index) => (
        <div className="d-flex flex-column"
          style={{
            padding: 10,
            marginBottom: 15,
          }}
        >
          <Label text="Nombre" />
          <TextInput text={item.name} setText={(e) => setValues(e, index, "name")} placeholder={"Ingresa el nombre del contacto"} />
          <Label text="Puesto" />
          <TextInput text={item.job} setText={(e) => setValues(e, index, "job")} placeholder={"Ingresa el puesto del contacto"} />
          <Label text="Email" />
          <TextInput text={item.email} setText={(e) => setValues(e, index, "email")} placeholder={"Ingresa el correo del contacto"} />
          <Label text="Teléfono" />
          <TextInput text={item.phone_number} setText={(e) => setValues(e, index, "phone_number")} placeholder={"Ingresa el teléfono del contacto"} />
          <Label text="Notas" />
          <TextInput text={item.notes} setText={(e) => setValues(e, index, "notes")} placeholder={"Ingresa las notas del contacto"} />
          <Label text="Proyectos" />
          <TextInput text={item.projects} setText={(e) => setValues(e, index, "projects")} placeholder={"Ingresa los proyectos del contacto"} />
        
        </div>
      ))}
      <div 
        className=""
        style = {{marginTop: 10, marginBottom: 10}}
        onClick={handleButton}
        >
            <Button
					    width="40%"
					    id='btn-add'
					    classes={`${
						    disabled
							    ? 'button-form-inactive text-small minuta_btn-add'
							    : 'button-form text-small minuta_btn-add'
					    } `}
					    text={textButton}
					    type='button'
					    // disabled={buttonDisabled}
				/>
      </div>

    </div>
  );
}
