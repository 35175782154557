//React
import { memo } from "react";
import { Link } from "react-router-dom";
import Tooltip from 'react-png-tooltip'

// Material
import { IconButton } from '@material-ui/core';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

//Images, Icons
import ImageDefaultIcon from "../../../assets/img/image-default.svg";
import PencilIconOcean from "../../../assets/img/pencil-icon-ocean.svg"
import TrashIconOcean from "../../../assets/img/trash-icon-ocean.svg"
import PdfIconOcean from "../../../assets/img/pdf-icon-ocean.svg"
import PttIcon from "../../../assets/img/ptt-icon.svg"

// env
require('dotenv').config();

export default memo(function Card({userId, image, title, link, linkEdit, linkPdf, linkPtt, id, onClick, fullView, miniView, noIcons, noEdit }) {
  return (
    <div className='card mb-3 d-flex justify-content-between pr-3 align-items-center box-shadow-lg-all'>
      <Link to={link} className="text-decoration-none w-100 d-flex align-items-center">
        <div className={`card__image px-4 ${image ? '' : 'd-none'}`}>
          <img key={image} alt={title} onError={e => e.target.src = `${ImageDefaultIcon}`} src={process.env.NODE_ENV == "development" ? image:`https://wsrv.nl/?url=${image}`} className='w-100' />
        </div>
        <span className="a-text-regular-second-primary-70">{title.replace('&amp;', '&')}</span>
      </Link>
      {noIcons ? '' : noEdit ? <>
                                 <div className='d-none d-lg-flex align-items-center'>
                                   <IconButton onClick={() => fullView(id)}>
                                     <img src={PdfIconOcean} alt="PDF Icon" className="cursor-pointer"  width="25px" />
                                   </IconButton>
                                 </div>
                                 <div>
                                   <IconButton className='ml-3'>
                                     <a href={linkPtt} rel="noreferrer" target="_blank" className="d-flex align-items-center">
                                       <img src={PttIcon} alt="PTT Icon" className="cursor-pointer" width="27px" />
                                     </a>
                                   </IconButton>
                                 </div>
                                 <IconButton edge="end" className='d-lg-none'>
                                   <Tooltip tooltip={<MoreHorizIcon />} className='tooltip'>
                                     <div className='display-grid'>
                                       <IconButton onClick={() => miniView(id)}>
                                         <span className="a-text-regular-first-neutral-100 text-normal">Descargar</span>
                                         <img src={PdfIconOcean} alt="PDF Icon" className="cursor-pointer"  width="25px" />
                                       </IconButton>
                                       <IconButton className="ml-3">
                                         <a href={linkPtt} rel="noreferrer" target="_blank" className="d-flex justify-content-between w-100 text-decoration-none">
                                           <span className="a-text-regular-first-neutral-100 text-normal">Descargar</span>
                                           <img src={PttIcon} alt="PTT Icon" className="cursor-pointer" width="24px" />
                                         </a>
                                       </IconButton>
                                     </div>
                                   </Tooltip>
                                 </IconButton>
                               </> :
       <>
         <div className='d-none d-lg-flex align-items-center'>
           <Link to={linkEdit} className="text-decoration-none w-100">
             <IconButton justify="space-around" >
               <img src={PencilIconOcean} alt="Pencil Icon" width="23px" />
             </IconButton>
           </Link>
           <IconButton className="ml-3" onClick={() => onClick(id)}>
             <img src={TrashIconOcean} alt="Trash Icon" width="24px" />
           </IconButton>
           <IconButton onClick={() => fullView(id)}>
             <img src={PdfIconOcean} alt="PDF Icon" className="cursor-pointer"  width="25px" />
           </IconButton>
           <IconButton className='ml-3'>
             <a href={linkPtt} rel="noreferrer" target="_blank" className="d-flex align-items-center">
               <img src={PttIcon} alt="PTT Icon" className="cursor-pointer" width="27px" />
             </a>
           </IconButton>
           
         </div>
         <IconButton edge="end" className='d-lg-none'>
           <Tooltip tooltip={<MoreHorizIcon />} className='tooltip'>
             <div className='display-grid'>
               <IconButton onClick={() => miniView(id)}>
                 <span className="a-text-regular-first-neutral-100 text-normal">Descargar</span>
                 <img src={PdfIconOcean} alt="PDF Icon" className="cursor-pointer"  width="25px" />
               </IconButton>
               <IconButton className="ml-3">
                 <a href={linkPtt} rel="noreferrer" target="_blank" className="d-flex justify-content-between w-100 text-decoration-none">
                   <span className="a-text-regular-first-neutral-100 text-normal">Descargar</span>
                   <img src={PttIcon} alt="PTT Icon" className="cursor-pointer" width="24px" />
                 </a>
               </IconButton>
               <IconButton onClick={() => onClick(id)}>
                 <span className="a-text-regular-first-neutral-100 text-normal">Eliminar</span>
                 <img src={TrashIconOcean} alt="Trash Icon" className="ml-3" width="24px" />
               </IconButton>
             </div>
           </Tooltip>
         </IconButton>
       </>
      }
    </div>
  )
})
