import React from 'react';
import { Grid } from '@material-ui/core';
import { NotFound404Error } from '../../components/organisms/Error/404';

export default function NotFound404() {
	return (
		<>
			<Grid
				container
				spacing={0}
			>
				<Grid
					item
					xs={12}
					md={12}
					lg={12}
					xl={12}
				>
					<NotFound404Error />
				</Grid>
			</Grid>
		</>
	);
}
