//React
import React from "react";

// Material

export default function CardBlogAuthor({ info, noIcons, key }) {
  const author = info;
  return (
    <div className="d-flex mt-3  card h-a box-shadow-all   ">
      <div className="w-100 d-flex  ">
        <img
          src={`https://images.weserv.nl/?url=${author.photo_link}&maxage=31d`}
          alt="icon"
          className="image-auhtor-detail object-fit-scale"
        />

        <div className="d-flex flex-column justify-content-center r py-2 py-md-3 py-lg-2   w-100 ml-4">
          <span className="text-regular a-text-medium-first-primary-70 mb-2 mb-md-3 mb-lg-1   h3-md text-lg-medium">
            {author.name}
          </span>
          <span className="text-normal a-text-regular-second-neutral-40   h3-md text-lg-normal">{`${author.publication} publicaciones`}</span>
        </div>
      </div>
    </div>
  );
}
