import React, { useState } from "react";

//API
import { sendEmailResetPasswordApi } from "../../../api/user";

//Matrial UI
import { CircularProgress} from '@material-ui/core';

//Components
import  {InputWithLabel} from "../../molecules/InputWith/InputWithLabel";
import { Button } from "../../atoms/Button";

export default function FormRecoverAccount(){

    const [ isLoading, setIsLoading ] = useState(false);

    function onSubmitEmail(e){
        e.preventDefault();
        const email = document.getElementById("recover-email-input").value;
        const response = sendEmailResetPasswordApi(email);
        setIsLoading(true)
        response.then(result => {
                window.location.href="/mail-sent";
            
        }).catch (err => {
            setIsLoading(false);
            console.log(err)
        })
    }
    return(
        <form onSubmit={(e) => onSubmitEmail(e)}>
            <div className="mb-3">
                <InputWithLabel text="Tu email" id="recover-email-input" placeholder="ejemplo@globalhits.com" />
            </div>
            <Button type="submit" disabled={isLoading ? true : false} classes={`a-button-primary1 ${isLoading ? "disabled" : ""}`} height={36} width="100%" text={isLoading ? <CircularProgress /> : "Enviar enlace"} />
        </form>
    )
}