// React
import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { PodcastContext } from "../../../providers/PodcastContext";
// Material
import { Avatar, Grid, useMediaQuery } from "@material-ui/core";
import { AudioController } from "../../molecules/AudioPlayer/AudioController";
import { AudioPlayerProvider } from "react-use-audio-player";

// Images
import Logo from "../../../assets/img/logo.svg";
import User from "../../../assets/img/user-icon.svg";
import House from "../../../assets/img/house-icon.svg";
import HouseActive from "../../../assets/img/house-icon-active.svg";
import Catalogue from "../../../assets/img/catalogue-icon.svg";
import CatalogueActive from "../../../assets/img/catalogue-icon-active.svg";
import ActiveCompany from "../../../assets/img/activeCompany.svg";
import Question from "../../../assets/img/question-icon.svg";
// import News from "../../../assets/img/news-icon.svg";
import News from "../../../assets/img/noticias.svg";
import Podcast from "../../../assets/img/icon/podcast1.svg";
import PodcastActive from "../../../assets/img/icon/PodcastActive.svg";
import Blog from "../../../assets/img/blogv1.svg";
import BlogActive from "../../../assets/img/blogv2.svg";
import Event from "../../../assets/img/event-icon.svg";
import Logout from "../../../assets/img/logout.svg";
import QuestionActive from "../../../assets/img/questions/preguntasActive.svg";
import NewsActive from "../../../assets/img/news/noticias2.svg";

// Componens
import Router from "./Router";
import SearchCompanyAlter from "../../../pages/CID/Company/SearchCompanyAlter";

// Api
import {
  getAccessToken,
  getRefreshToken,
  logout,
  refreshAccessToken,
} from "../../../api/auth";

import { getProfile } from "../../../api/profile";

// Jwt
import jwtDecode from "jwt-decode";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import SearchReport from "../../../pages/CID/Report/SearchReport";

export default function LayoutMaster({ routes, location }) {
  // State
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [usr, setusr] = useState({});
  const [podcast, setPodcast] = useState();
  const [active, setActive] = useState(false);

  // Variables
  const ipadProMatch = useMediaQuery("(min-width: 992px)");
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const { rolId, name } = usr;
  const [profile, setProfile] = useState();
  const [topicsByuser, setTopicsByuser] = useState(false);
  const [questions, SetQuestions] = useState(false);

  const user = useAuth();
  const { userId } = user;

  // Peticiones
  useEffect(() => {
    if (!accessToken) {
      if (!refreshToken) {
        window.location.href = "/";
      }
      refreshAccessToken(refreshToken)
        .then((result) => {
          setusr(jwtDecode(result.data.accessToken));
          localStorage.setItem("accessToken", result.data.accessToken);
        })
        .catch((err) => console.log(err));
      return;
    }
    setusr(jwtDecode(accessToken));
    const obtenerPerfil = async () => {
      try {
        const {
          data: { profile },
        } = await getProfile(userId);
        if (profile !== null) {
          if (
            profile[0].photo_link != "undefined" &&
            profile[0].photo_link != "null"
          ) {
            setProfile(profile[0].photo_link);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    obtenerPerfil();
  }, [accessToken, refreshToken]);

  // Funciones
  if (!user) {
    return <Redirect to="/" />;
  }
  function handleLogout() {
    logout();
    window.location.href = "/";
  }

  return (
    <PodcastContext.Provider
      value={{
        podcast,
        setPodcast,
        setActive,
      }}
    >
      <>
        <Grid container justify="center" spacing={0} className="h-100">
          <Grid item xs={12} lg={3} className="scroll-lg-overflow h-vh">
            {/* Sidebar */}
            <div
              className={`sidebar-community ${
                isCollapsed ? "sidebar-active" : " "
              } ${ipadProMatch ? "" : "sidebar"}`}
            >
              {/* Menu */}
              <div className="p-4">
                <Link to="/cid">
                  <img
                    src={Logo}
                    alt="Logo"
                    className="sidebar-community__logo"
                  />
                </Link>
                <h3 className="mt-4 h3 a-text-bold-second-neutral-100">Menú</h3>
              </div>
              <div className=" pb-4 ">
                <Link
                  to="/cid/carso/profile"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`pr-4 mt-2 py-2 pl-4 d-flex align-items-center ${
                      location === "/cid/carso/profile" ||
                      location === "/cid/carso/update-profile"
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <Avatar
                      alt="user"
                      src={
                        profile 
                          ? profile
                          : User
                      }
                      className="mr-4"
                    />
                    <p className="a-text-regular-second-primary-70">{name}</p>
                  </div>
                </Link>
              </div>

              <div className="hr-sidebar mx-4"></div>
              {/* Admin */}
              <div className={`pb-4 mt-3 ${rolId === 1 ? "" : "d-none"}`}>
                <h4 className="h4 a-text-medium-second-neutral-100 px-4">
                  Administrador
                </h4>
                <Link
                  to="/cid/admin/users"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={` mt-2 py-2 pl-5 d-flex ${
                      location.includes("users")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      src={
                        location.includes("users") ? ActiveCompany : Catalogue
                      }
                      alt="icon"
                      className="sidebar-community__icon mr-4"
                    />
                    <p className="a-text-regular-second-neutral-60 text-normal">
                      Catálogo de
                      <br />
                      usuarios
                    </p>
                  </div>
                </Link>
              </div>

              <div className="hr-sidebar mx-4"></div>
              {/* CID */}
              <div className={`pb-4 mt-3`}>
                <h4 className="h4 a-text-medium-second-neutral-100 px-4">
                  CID
                </h4>
                <Link
                  to="/cid"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`pr-4 mt-2 py-2 pl-5 d-flex align-items-center ${
                      location === "/cid"
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      src={location === "/cid" ? HouseActive : House}
                      alt="icon"
                      className="sidebar-community__icon mr-4"
                    />
                    <p className="a-text-regular-second-neutral-60 text-normal">
                      Inicio
                    </p>
                  </div>
                </Link>
                <Link
                  to="/cid/company"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={` mt-2 py-2 pl-5 d-flex ${
                      location.includes("company") ||
                      location.includes("search-company-alternative")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      src={
                        location.includes("company") ||
                        location.includes("search-company-alternative")
                          ? ActiveCompany
                          : Catalogue
                      }
                      alt="icon"
                      className="sidebar-community__icon mr-4"
                    />
                    <p className="a-text-regular-second-neutral-60 text-normal">
                      Catálogo de
                      <br />
                      empresas
                    </p>
                  </div>
                </Link>
                <Link
                  to="/cid/analysis"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`pr-4 mt-2 py-2 pl-5 d-flex ${
                      location.includes("analysis") ||
                      location.includes("search-analysis")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      src={
                        location.includes("analysis") ||
                        location.includes("search-analysis")
                          ? CatalogueActive
                          : Catalogue
                      }
                      alt="icon"
                      className="sidebar-community__icon mr-4"
                    />
                    <p className="a-text-regular-second-neutral-60 text-normal">
                      Catálogo de
                      <br />
                      análisis
                    </p>
                  </div>
                </Link>
              </div>
              <div className="hr-sidebar mx-4"></div>
              {/* CARSO */}

              <div className="sidebar-community__header  pb-4 ">
                <h4 className="h4 a-text-medium-second-neutral-100 px-4 mt-3">
                  Carso
                </h4>
                <Link
                  to="/cid/carso/home"
                  className={`text-decoration-none`}
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`d-flex mt-3 d-flex py-2 pl-5 pr-4  align-items-center ${
                      location === "/cid/carso/home" ||
                      location.includes("carso/home")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    } ${
                      location === "/cid/carso/home"
                        ? "sidebar-opcion-active"
                        : ""
                    }`}
                  >
                    <img
                      className="mr-4"
                      src={location === "/cid/carso/home" ? HouseActive : House}
                      alt="icon"
                      width="32px"
                    />
                    <p className="a-text-regular-second-neutral-60 text-normal">
                      Inicio
                    </p>
                  </div>
                </Link>
                {questions ? (
                  <Link
                    to={`${
                      topicsByuser
                        ? "/cid/carso/questions/list"
                        : "/cid/carso/questions"
                    }`}
                    className="text-decoration-none"
                    onClick={() => setIsCollapsed(false)}
                  >
                    <div
                      className={`mt-3 d-flex py-2 pl-5 pr-4  w-100 align-items-center ${
                        location.includes("questions") ||
                        location.includes("questions")
                          ? "background-first-primary-10"
                          : "background-hover-nav"
                      }`}
                    >
                      <img
                        className="mr-4"
                        src={
                          location.includes("questions")
                            ? QuestionActive
                            : Question
                        }
                        alt="icon"
                        width="32px"
                      />
                      <p className=" a-text-regular-second-neutral-60 text-normal">
                        Preguntas
                      </p>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={`/cid/carso/questions/list`}
                    className="text-decoration-none"
                    onClick={() => setIsCollapsed(false)}
                  >
                    <div
                      className={`mt-3 d-flex py-2 pl-5 pr-4  w-100 align-items-center ${
                        location.includes("questions") ||
                        location.includes("questions")
                          ? "background-first-primary-10"
                          : "background-hover-nav"
                      }`}
                    >
                      <img
                        className="mr-4"
                        src={
                          location.includes("questions")
                            ? QuestionActive
                            : Question
                        }
                        alt="icon"
                        width="32px"
                      />
                      <p className=" a-text-regular-second-neutral-60 text-normal">
                        Preguntas
                      </p>
                    </div>
                  </Link>
                )}

                <Link
                  to="/cid/carso/news"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`mt-2 py-2 pl-5 d-flex align-items-center ${
                      location.includes("/cid/carso/news") ||
                      location.includes("/cid/carso/news")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      src={
                        location.includes("/cid/carso/news") ||
                        location.includes("/cid/carso/news")
                          ? NewsActive
                          : News
                      }
                      alt="icon"
                      className="sidebar-community__icon mr-4"
                    />
                    <p className="a-text-regular-second-neutral-60 text-normal ">
                      Noticias
                    </p>
                  </div>
                </Link>
                <Link
                  to="/cid/carso/blogs/list"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`d-flex mt-3 d-flex py-2 pl-5 pr-4 align-items-center ${
                      location.includes("blogs") || location.includes("blogs")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      className="mr-4 "
                      src={location.includes("blogs") ? BlogActive : Blog}
                      alt="icon"
                      width="32px"
                    />
                    <div>
                      <p className=" a-text-regular-second-neutral-60 text-normal ">
                        Blog
                      </p>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/cid/carso/podcast/list"
                  className="text-decoration-none"
                  onClick={() => setIsCollapsed(false)}
                >
                  <div
                    className={`d-flex mt-3 d-flex py-2 pl-5 pr-4 align-items-center ${
                      location.includes("podcast") ||
                      location.includes("podcast")
                        ? "background-first-primary-10"
                        : "background-hover-nav"
                    }`}
                  >
                    <img
                      className="mr-4 "
                      //   Aquí se pondrá el icono azul para cuando se selecciona la categoría.
                      src={
                        location.includes("podcast") ? PodcastActive : Podcast
                      }
                      alt="icon"
                      width="32px"
                    />
                    <div>
                      <p className=" a-text-regular-second-neutral-60 text-normal ">
                        Podcast
                      </p>
                    </div>
                  </div>
                </Link>

                <div className="d-flex mt-3 d-flex py-2 pl-5 pr-4 align-items-center ">
                  <img
                    className="mr-4 disabled"
                    src={Event}
                    alt="icon"
                    width="32px"
                  />
                  <div>
                    <p className=" a-text-regular-second-neutral-60 text-normal disabled">
                      Eventos
                    </p>
                    <div className="background-second-primary-80">
                      <div className="d-flex justify-content-center dotted-container">
                        <p className="text-small a-text-regular-second-primary-50">
                          ¡Próximamente!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* LOGOUT */}
              <div
                className="d-flex mt-4 cursor-pointer py-3 px-4 background-hover-nav"
                onClick={handleLogout}
              >
                <img
                  src={Logout}
                  alt="icon"
                  className="sidebar-community__icon mr-4"
                />
                <p className="a-text-regular-second-neutral-60 text-normal align-self-center">
                  Cerrar sesión
                </p>
              </div>
            </div>
            <div
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={`invisible-button d-lg-none ${
                isCollapsed ? "invisible-button-active" : ""
              }`}
            ></div>
          </Grid>
          <Grid item xs={12} lg={9} className="h-vh">
            <Grid container justify="center" spacing={0}>
              <Grid
                item
                xs={12}
                className={
                  location === "/cid/search-company" ||
                  location === "/cid/search-company/" ||
                  location === "/cid/search-company-alternative" ||
                  location === "/cid/search-company-alternative/"
                    ? "d-block"
                    : "d-none"
                }
              >
                <SearchCompanyAlter
                  // <SearchCompany Aqui descomentar
                  userId={userId}
                  isCollapsed={() => setIsCollapsed(!isCollapsed)}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={0}>
              <Grid
                item
                xs={12}
                className={
                  location === "/cid/search-analysis" ||
                  location === "/cid/search-analysis/"
                    ? "d-block"
                    : "d-none"
                }
              >
                <SearchReport
                  userId={userId}
                  isCollapsed={() => setIsCollapsed(!isCollapsed)}
                />
              </Grid>
            </Grid>
            {/* Router */}
            <Router
              routes={routes}
              userId={userId}
              isCollapsed={() => setIsCollapsed(!isCollapsed)}
            />
          </Grid>
        </Grid>
        {/* Aquí iría el reproductor de audio */}
        {active ? (
          <AudioPlayerProvider>
            <AudioController file={podcast.audio_podcast} podcast={podcast} />
          </AudioPlayerProvider>
        ) : (
          ""
        )}
      </>
    </PodcastContext.Provider>
  );
}
