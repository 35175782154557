export function IconTextInput({ icon, link, setLink, warning, iconInactivo }) {
  return (
    <>
    <div
      className="d-flex flex-row align-items-center"
      style={{
        width: `calc(${100}% - 10px)`,
        paddingTop: 10,
        // paddingBottom: 10,
      }}
    >
      <img
        src={icon}
        alt="icon"
        className="mr-2"
        style={{
          width: 30,
          height: 30,
        }} />
      <input
        // placeholder="Ingresa el link"
        className={`text-normal input py-2 pl-2 a-text-medium-second-neutral-60 `}
        type="text"
        style={{ width: `calc(${100}% - 10px)` }}
        value={link}
        onChange={(e) => setLink(e.target.value)}
        autoComplete="off" />
    </div>
    {warning ? (
        <p className="mt-2 a-text-regular-alert-60">{warning}</p>
      ) : (
        ""
      )}
    </>
    
  );
}
