//React
import { useEffect, useState } from "react";

import { Grid, useMediaQuery } from "@material-ui/core";

//Images
import Compartir from "../../../../assets/img/icon/compartir.svg";
import Correo from "../../../../assets/img/social/correo.svg";
import Whats from "../../../../assets/img/social/whats.svg";
import Save from "../../../../assets/img/news/guardar-1.svg";
import SaveActive from "../../../../assets/img/news/guardar-2.svg";

import Copy from "../../../../assets/img/news/copiar-enlace.svg";

import { deletedNew, getSavedNew, savedNews } from "../../../../api/news";

export function NewsCardVertical({
  user,
  author,
  description,
  image,
  url,
  title,
  date,
  newId,
  path,
  newsSaved,
  change,
  setChange,
  savedView,
}) {
  //Esto se usa para poder remover noticias alojadas en nuestro ep, desde el ep de bing.
  if (newId == undefined && !!newsSaved) {
    let filteredNewsSaved = newsSaved
      .map((el) => {
        return {
          url: el.url_news,
          id: el.user_news_id,
        };
      })
      .filter((el) => el.url === url);
    if (filteredNewsSaved?.length !== 0) {
      newId = filteredNewsSaved[0].id;
    }
  }

  //Este estado se usa para determinar si una noticia del ep de bing está presente en nuestra base de datos
  let initStateSaveNew = newsSaved?.map((el) => el.url_news).includes(url);

  // State
  const [isShare, setIsShare] = useState(false);
  const [saveNew, setSaveNew] = useState(initStateSaveNew);

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const newsDate = date.slice(0, 10);
  const dateSeparated = newsDate.split("-");
  const month = meses[dateSeparated[1] - 1];
  const dateOrdered = `${dateSeparated[2]} ${month} ${dateSeparated[0]}`;
  const ipadProMatch = useMediaQuery("(width: 1024px)");

  const [noticiasGuardadas, setNoticiasGuardadas] = useState([]);
  useEffect(() => {
    const obtenerNewsUser = async () => {
      try {
        const { data } = await getSavedNew(user);
        if (data.savedNews) {
          setNoticiasGuardadas(data.savedNews);
        } else {
          setNoticiasGuardadas([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    obtenerNewsUser();
  }, [saveNew]);

  useEffect(() => {
    const newDeleted = noticiasGuardadas.find(
      (noticia) => noticia.description_news === description,
    );
    if (newDeleted) {
      setSaveNew(true);
    }
  }, [noticiasGuardadas]);

  function copyToClipboard() {
    navigator.clipboard.writeText(url);
  }

  async function saveAction() {
    if (saveNew) {
      try {
        const newDeleted = noticiasGuardadas.find(
          (noticia) => noticia.description_news === description,
        );
        if (newDeleted) {
          await deletedNew(user, newDeleted.user_news_id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setSaveNew(false);
        setChange(!change);
      }
    } else {
      const formData = {
        userId: user,
        isSaved: true,
        title,
        description,
        author,
        photos: image,
        url,
        publish: date,
      };

      try {
        await savedNews(formData);
      } catch (error) {
        console.log(error);
      } finally {
        setSaveNew(true);
        setChange(!change);
      }
    }
  }

  return (
    <div className={`${ipadProMatch ? "ml-5" : "ml-3"}`}>
      <Grid container justify="center" spacing={0} className={`mb-2 mt-2`}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className={`box-shadow-all br-1  
        p-4 h-350
        `}
        >
          <div>
            <div>
              <div className="d-flex align-items-center h-34">
                <a
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                  className="cursor-pointer text-decoration-none"
                >
                  <p className="a-text-light-second-neutral-60 text-small">
                    {author} - {dateOrdered}{" "}
                  </p>
                </a>
              </div>
              <a
                href={url}
                rel="noreferrer"
                target="_blank"
                className="cursor-pointer text-decoration-none"
              >
                <p className="a-text-medium-second-neutral-70 text-normal h-38">
                  {title.substr(0, 45)}
                  {title.length > 45 ? "..." : ""}
                </p>
              </a>
              <div className="d-flex justify-content-center mt-3 mb-3">
                <a
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                  className="cursor-pointer text-decoration-none"
                >
                  <img
                    className="d-flex justify-content-between image-news-card br-1"
                    src={
                      !!image[1] && image[1].hasOwnProperty("contentUrl")
                        ? image[1].contentUrl
                        : image
                    }
                  />
                </a>
              </div>
              <a
                href={url}
                rel="noreferrer"
                target="_blank"
                className="cursor-pointer text-decoration-none"
              >
                <p className="a-text-regular-second-neutral-60 text-small h-85">
                  {description.substr(0, 117)}
                  {description.length > 117 ? "..." : ""}
                </p>
              </a>
              <hr className="hr-ocean mt-2" />

              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between mt-2">
                  <img
                    src={Compartir}
                    alt="Compartir"
                    className={isShare ? "d-none" : "cursor-pointer"}
                    onClick={() => setIsShare(true)}
                  />
                  <div
                    className={!isShare ? "d-none" : "d-flex"}
                    onClick={() => setIsShare(false)}
                  >
                    <img
                      src={Compartir}
                      alt="Compartir"
                      className="cursor-pointer mr-2"
                    />

                    <a
                      href={`https://api.whatsapp.com/send?text=*${title}*%0A🌐%20${url} `}
                      target="_blank"
                    >
                      <img
                        src={Whats}
                        width={`20px`}
                        alt="Compartir"
                        className="cursor-pointer mr-2"
                      />
                    </a>
                    <a
                      href={`mailto:?subject=Noticia: &body=Noticia:%20${url}%0D`}
                      target="_blank"
                    >
                      <img
                        src={Correo}
                        alt="Compartir"
                        className="cursor-pointer mr-2"
                      />
                    </a>

                    <button className="d-flex btn-in" onClick={copyToClipboard}>
                      <img
                        src={Copy}
                        id="f"
                        alt="Copiar"
                        width={`20px`}
                        className="cursor-pointer mr-2 pdf-img"
                      />
                    </button>
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <img
                    src={saveNew ? SaveActive : Save}
                    alt="Guardar"
                    className={`${isShare ? "d-none" : "cursor-pointer"}`}
                    onClick={saveAction}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
