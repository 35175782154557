import React, { useEffect, useState } from "react";
import CardBlog from "../../molecules/Card/CardBlog/index";
import CardBlogVertical from "../../../components/molecules/Card/CardBlog/CardOptionTwo";

import { useAuth } from "../../../hooks/useAuth";
import { getBlogsByUser, getSavedBlogs } from "../../../api/blogs";
import SkeletonBlog from "../../../components/molecules/Skeleton/SkeletonBlog/index";
import EmptyState from "../../../components/molecules/EmptyState/EmptyStateBlog/EmptyProgrammedBlogs";
import EmptyProgramed from "../../../assets/img/blogs/EmptyBlogs/i-programadas.svg";
import EmptyUploaded from "../../../assets/img/blogs/EmptyBlogs/i-publicadas.svg";
import EmptySaved from "../../../assets/img/blogs/EmptyBlogs/i-guardadas.svg";
import EmptyDraft from "../../../assets/img/blogs/EmptyBlogs/i-borradores.svg";


import {
  deleteBlog,
  getProgrammBlogs,
  getDraftedBlogs
} from "../../../api/blogs";
import ModalSuccess from "../../../components/molecules/Modal/SuccessModal";
import { WarningModal } from "../../molecules/Modal/WarningModal";
import {
  Modal,
} from "@material-ui/core/";

export function BlogByUserList({ category, viewType }) {
  const [load, setLoad] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadDelete, setLoadDelete] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [change, setChange] = useState(true);
  const [openWarning, setOpenWarning] = useState(false);
  const [blog, setBlog] = useState();

  const user = useAuth();
  const { userId } = user;

  useEffect(() => {
    switch (category) {
      case "publicadas":
        getAllBlogsByUser(userId);
        break;
      case "programadas":
        getAllProgrammedBlogs(userId);
        break;
      case "guardadas":
        getAllSavedBlogs(userId);
        break;
      case "borradores":
        getAllDraftedBlogs(userId);
        break;
    }
  }, [deleteSuccess, category, change]);

  async function getAllProgrammedBlogs(userId) {
    try {
      setLoad(true);
      const response = await getProgrammBlogs(userId);
      setBlogs(response.data.blogs);
      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllDraftedBlogs(userId) {
    try {
      setLoad(true);
      const { data } = await getDraftedBlogs(userId);
      console.log("estos son los drafts", data.blogs)
      setBlogs(data.blogs);
      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  }


  async function getAllBlogsByUser(userId) {
    try {
      setLoad(true);

      const { data } = await getBlogsByUser(userId);
      console.log("subidas por usuario", data.blogs);
      setBlogs(data.blogs);
      setLoad(false);
    } catch (error) {
      //Esto resuelve temporalmente cuando el usuario no tiene blogs publicados, no se quede el skeleton cargando.
      // if (error.toString().includes("500")) {
      // } else {
      //   setBlogs([])
      //   setLoad(false)
      // }
      console.log(error);
    }
  }

  async function getAllSavedBlogs(userId) {
    try {
      setLoad(true);
      const response = await getSavedBlogs(userId);
      console.log("estos son los blogs guardados", response.data);
      setBlogs(response.data.savedBlogs);
      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteObject(userId, blog_id) {
    try {
      setOpenSuccess(true);

      const deleted = await deleteBlog(userId, blog_id);

      if (deleted.status == 200) {
        setLoadDelete(false);

        setTimeout(() => {
          setOpenSuccess(false);
          setDeleteSuccess(!deleteSuccess);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <div className="mt-4">
        {load ? (
          <SkeletonBlog num={3} />
        ) : !!blogs && blogs.length != 0 ? (
          viewType ? (
            <div className="mt-2">
              <>
                {" "}
                {blogs.map((res, idx) => (
                  <div key={res.blog_id} className="mt-3">
                    <CardBlog
                      info={res}
                      userId={userId}
                      type="normal"
                      saved={category === "guardadas"}
                      setChange={setChange}
                      change={change}
                      setOpenWarning={setOpenWarning}
                      openWarning={openWarning}
                      setBlog={setBlog}
                      category={category}
                    />
                  </div>
                ))}{" "}
              </>
            </div>
          ) : (
            <div className=" d-flex flex-wrap mt-2">
              <>
                {" "}
                {blogs.map((res, idx) => (
                  <div key={idx} className="mt-3">
                    <CardBlogVertical
                      key={idx}
                      info={res}
                      userId={userId}
                      type="normal"
                      saved={category === "guardadas"}
                      setChange={setChange}
                      change={change}
                      setOpenWarning={setOpenWarning}
                      openWarning={openWarning}
                      setBlog={setBlog}
                      category={category}
                    />
                  </div>
                ))}{" "}
              </>
            </div>
          )
        ) : (
          <EmptyState
            titulo={category == "borradores" ? `Cuando tengas borradores se mostrarán aquí` : `Las entradas ${category} se mostrarán aquí.`}
            img={
              category == "publicadas"
                ? EmptyUploaded
                : category == "programadas"
                  ? EmptyProgramed
                  : category == "borradores"
                    ? EmptyDraft
                    : EmptySaved
            }
          />
        )}

        <Modal
          open={openSuccess}
          onClose={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal">
            <ModalSuccess
              open={openSuccess}
              setOpen={setOpenSuccess}
              message={`${loadDelete ? "Espera un momento..." : "Tu blog se ha eliminado"
                }`}
              action={"Timmer"}
            />
          </div>
        </Modal>
        <Modal
          open={openWarning}
          onClose={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal">
            <WarningModal
              message={"Tu blog será eliminado"}
              open={openWarning}
              setOpen={setOpenWarning}
              setOpenSuccess={setOpenSuccess}
              openSuccess={openSuccess}
              deleteObject={deleteObject}
              object={blog}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
