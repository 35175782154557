// React
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';

// Components
import CardReport from "../../../molecules/Card/CardReport";


// Api
import { PDF_PATH } from "./../../../../api/config";

export default function FilteredReports({ data, noSlice, image, name, type, onClick, noName, noMostrar }) {

    const user = useAuth();
    // State
    const [more, setMore] = useState(5);
    if (noSlice) {
        return data.map((res, idx) => <CardReport key={idx} noEdit={user.rolId === 3} name={res.title} linkPdf={res.file_url} id={res.report_id} date={res.created_at} linkEdit={"/cid/update-analysis/ " + res.report_id} onClick={onClick} />)
    } else if (type) {
        function handleFilter(res, idx) {
            //en cada card puede ser también com un .map(res => ` ${res.sector}`), pero esto va conforme el microservicio de reports, y el tipo de base
            switch (type) {
                case 'sector':
                    return <CardReport key={idx} linkPdf={res.file_url} noEdit={user.rolId === 3} name={`${res.title}- ${res.sectores}`} link={{ pathname: "/cid/analysis/" + res.report_id, state: { array: data.map(r => r.report_id), index: idx, location: 2 } }} linkEdit={"/cid/update-analysis/ " + res.report_id} id={res.report_id} date={res.created_at} onClick={onClick} />
                case 'tecnología':
                    return <CardReport key={idx} linkPdf={res.file_url} noEdit={user.rolId === 3} name={`${res.title} - ${res.markets}`} link={{ pathname: "/cid/analysis/" + res.report_id, state: { array: data.map(r => r.report_id), index: idx, location: 2 } }} linkEdit={"/cid/update-analysis/ " + res.report_id} id={res.report_id} date={res.created_at} onClick={onClick} />
                case 'cliente':
                    return <CardReport key={idx} linkPdf={res.file_url} noEdit={user.rolId === 3} name={`${res.title} - ${res.client}`} link={{ pathname: "/cid/analysis/" + res.report_id, state: { array: data.map(r => r.report_id), index: idx, location: 2 } }} linkEdit={"/cid/update-analysis/ " + res.report_id} id={res.report_id} date={res.created_at} onClick={onClick} />
                default:
                    break;
            }
            console.log(res.clientes, "res.clientes")
        }

        return (
            (data !== undefined) ?
                image !== undefined ?
                    <div className="pb-3">
                        <div className="d-flex">
                            <img src={image} alt="Icon" className="mb-3" />
                            <h4 className="ml-2 mb-4 a-text-medium-first-primary-60">{name} -</h4>
                            <span className="a-text-regular-first-neutral-80 ml-2 mt-2">
                                {data.length > 1 ? `${data.length} Resultados` : `${data.length} Resultado`}
                            </span>
                        </div>
                        {data.map((res, i) => handleFilter(res, i)).slice(0, more)}
                        <div onClick={() => setMore(more + 5)} className={`text-normal cursor-pointer a-text-regular-first-primary-60 ${data.length < 6 || data.map(res => res).slice(0, more).length == data.length ? 'd-none' : ''}`}>Ver más ...</div>
                    </div> :
                    <>
                        <div>
                            {data.map((res, i) => handleFilter(res, i)).slice(0, more)}
                            <div onClick={() => setMore(more + 5)} className={`text-normal cursor-pointer a-text-regular-first-primary-60 ${data.length < 6 || data.map(res => res).slice(0, more).length == data.length ? 'd-none' : ''}`}>Ver más ...</div>
                        </div>
                    </> :
                <div className={noMostrar ? "d-none" : "d-flex align-items-center"}>
                    <img src={image} alt="Icon" className="mb-4" />
                    <h4 className="ml-2 mb-4 pb-2 a-text-medium-first-primary-60">{name} -</h4>
                    <span className="a-text-regular-first-neutral-80 ml-2 mb-4">
                        0 Resultados
                    </span>
                </div>
        )
    } else {
        return (
            data.length !== 0 ?
                noName ?
                    <div className="pb-3">
                        <div className="d-flex align-items-center">
                            <img src={image} alt="Icon" className="mb-3 py-2" />
                            <h4 className="ml-2 mb-4 a-text-medium-first-primary-60">{name} -</h4>
                            <span className="a-text-regular-first-neutral-80 ml-2 mb-3">
                                {data.length > 1 ? `${data.length} Resultados` : `${data.length} Resultado`}
                            </span>
                        </div>
                        {data.map((res, idx) => <CardReport key={idx} linkPdf={res.file_url} noEdit={user.rolId === 3} name={res.title} link={{ pathname: "/cid/analysis/" + res.report_id, state: { array: data.map(r => r.report_id), index: idx, location: 2 } }} linkEdit={"/cid/update-analysis/ " + res.report_id} id={res.report_id} date={res.created_at} onClick={onClick} />).slice(0, more)}
                        <div onClick={() => setMore(more + 5)} className={`text-normal cursor-pointer a-text-regular-first-primary-60 ${data.length < 6 || data.map(res => res).slice(0, more).length == data.length ? 'd-none' : ''}`}>Ver más ...</div>
                    </div> :
                    <div>
                        {data.map((res, idx) => <CardReport key={idx} linkPdf={res.file_url} noEdit={user.rolId === 3} name={res.title} link={{ pathname: "/cid/analysis/" + res.report_id, state: { array: data.map(r => r.report_id), index: idx, location: 2 } }} linkEdit={"/cid/update-analysis/ " + res.report_id} id={res.report_id} date={res.created_at} onClick={onClick} />).slice(0, more)}
                        <div onClick={() => setMore(more + 5)} className={`text-normal cursor-pointer a-text-regular-first-primary-60 ${data.length < 6 || data.map(res => res).slice(0, more).length == data.length ? 'd-none' : ''}`}>Ver más ...</div>
                    </div> :
                <div className="d-flex align-items-center">
                    <img src={image} alt="Icon" className="mb-3 pb-2" />
                    <h4 className="ml-2 mb-4 a-text-medium-first-primary-60 pb-2">{name} -</h4>
                    <span className="a-text-regular-first-neutral-80 ml-2 pb-2 mb-3">
                        0 Resultados
                    </span>
                </div>

        )
    }
}