// React
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Material
import {
  Grid,
  useMediaQuery,
  createMuiTheme,
  IconButton,
  Modal,
} from "@material-ui/core/";

import { Link, useParams } from "react-router-dom";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { getProfile } from "../../../api/profile";
import { createBlog } from "../../../api/blogs";
import { useLocation } from "react-router-dom";

import EmptyStateBlog from "../../../components/molecules/EmptyState/EmptyStateBlog/index";
import Close from "../../../assets/img/closeFilter.svg";
import SunEditor from "suneditor-react";
import Alert from '../../../assets/img/alert.svg'
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

// Componentes
import Header from "../../../components/molecules/Header";
import ModalConfig from "../../../components/molecules/Modal/ModalConfigUpdateBlog";

import { Language } from "@material-ui/icons";

import { getBlogById } from "../../../api/blogs";
let topicsbyLocation = [];
export default function BlogEdit(e) {
  /*
        En este componente lo que hacemos es recibir los datos del push
        de la misma forma llenamos estos datos para poder utilizarlos en nuestra edición,
        en este componete no hay más cosas por revisar ya que ha sido probado y todo funciona correctamete

    
    */
  const params = useParams()
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const location = useLocation();
  const [openModalConfig, setOpenModalConfig] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [labels, setLabels] = useState(true);

  const [isAcceptable, setIsAcceptable] = useState(false)
  const [filesPortada, setFilesPortada] = useState(false)
  const [imageOnBlog, setImageOnBlog] = useState('')
  const [imageOnBlogUploaded, setImageOnBlogUploaded] = useState(null)
  const [isUploaded, setisUploaded] = useState(false)
  const [natWidth, setNatWidth] = useState(undefined)
  const [natHeight, setNatHeight] = useState(undefined)

  const [uploadedImages, setUploadedImages] = useState([])
  const [uploadedImagesFiles, setUploadedImagesFiles] = useState([])
  const [deletedImages, setDeletedImages] = useState([])

  const [loadlabels, setLoadLabels] = useState(true);
  const user = useAuth();
  const { userId } = user;
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });


  useEffect(() => {
    const obtenerBlog = async () => {
      setLoadLabels(true);
      try {
        const { data: { blog } } = await getBlogById(params.id);
        const { title, labels } = blog
        setTitle(title)
        setData(blog);
        // setLabels(location.state.tags);
        setLabels(labels)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadLabels(false);
      }
      LabelsIniatlize();
    }
    obtenerBlog()
  }, []);

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const Desk = useMediaQuery("(min-width: 1100px)");

  /* 
    Esta función llena todas nuestras labels acorde a las labels que se recuperan dentro de la edición para llenar nuestra cajita 
    de labels en el modal
    
    */
  function LabelsIniatlize() {
    if (loadlabels) {
    } else {
      console.log();
      for (let index = 0; index < labels.length; index++) {
        topicsbyLocation.push(labels[index].name);
      }
    }
  }
  /*
    con esta función recuperamos los valores de las imagenes que se están usando o que se cargaron para elegir la portada
    */
  // function handleImageUploadBefore(files, info, uploadHandler) {
  //   // uploadHandler is a function
  //   console.log(files, info);
  //   uploadHandler(files);
  //   setFilesPortada(files);
  // }
  let imagesFiles = []

  function handleImageUploadBefore(files, info, uploadHandler) {
    setImageOnBlogUploaded(files)
    setisUploaded(true)
    uploadHandler(files)
    imagesFiles.push(files)
    setUploadedImagesFiles([...imagesFiles])
  }

  /*
    con esta función podemos saber cuantas images existen dentro de nuestro blog, de esta forma sabremos si se escoge o no una portada  por defecto
    */
  let imagesArray = []
  let imagesOffTheArray = []


  function handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
    let width;
    let height;

    switch (state) {
      case "create":
        if (targetImgElement) {
          targetImgElement.onload = function () {
            width = this.naturalWidth;
            height = this.naturalHeight;

            setNatWidth(width)
            setNatHeight(height)

            if (height < 100 || width < 100) {
              setIsAcceptable(true)
            } else {
              setIsAcceptable(false)
            }

          }
          setFilesPortada(true)
          setImageOnBlog(imageInfo)
          imagesArray.push(imageInfo)
          setUploadedImages([...imagesArray])
        }
        break;
      case "delete":
        imagesOffTheArray.push(index)
        setDeletedImages([...imagesOffTheArray])
        if (imagesArray.length == imagesOffTheArray.length) {
          setFilesPortada(false)
        }
        break;
    }

  }


  if (!user) {
    return <Redirect to="/" />;
  }


  function handleChange(content) {
    setText(content);

    if (document.querySelector(".titulos") != undefined) {
      const titleSF = document.querySelector(".titulos").innerHTML
      setTitle(titleSF)
    }
  }
  if (loadlabels) return <></>
  return (
    <Grid container justify="center" spacing={0} className="">
      <Header
        isCollapsed={e.isCollapsed}
        link="/cid/analysis"
        text="Buscar pregunta"
      />
      <Grid
        item
        xs={10}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2"
      >
        <div
          className={`background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ${Desk ? "" : "h-100vh"
            } `}
        >
          <div className="d-flex w-100 ">
            <p
              className={`mt-3 mt-lg-1 mb-lg-3 mb-lg-4 w-80  ${ipadMiniMatch
                ? `h3   mb-md-7  ${iPadProMatch ? "mt-0 mb-md-5  " : "mt-6 mb-md-7  "
                }`
                : ""
                } mb-3 a-text-medium-second-neutral-70  `}
            >
              Blog
            </p>
            {iPadProMatch ? (
              <div className="w-30 pl-3 d-flex justify-content-around align-items-center ">
                <button
                  className={`text-normal  roboto-regular h3-md  text-lg-normal ${loadlabels
                    ? "disable a-button-topic-blue-1 "
                    : "a-button-topic-blue-1  "
                    }`}
                  onClick={() => {
                    setOpenModalConfig(true);
                  }}
                >
                  Actualizar
                </button>
                <Link to={"/cid/carso/blogs/list"}>
                  {" "}
                  <IconButton>
                    <img
                      src={Close}
                      alt="PDF Icon"
                      className="cursor-pointer "
                      width="24px"
                    />
                  </IconButton>
                </Link>
              </div>
            ) : (
              <div />
            )}
          </div>
          <SunEditor
            autoFocus={true}
            onChange={handleChange}
            setContents={`${loadlabels ? "" : `<h2 class=\"titulos\" id=\"forFocus\">${title}</h2>${data.description}`}`}
            onImageUploadBefore={handleImageUploadBefore}
            onImageUpload={handleImageUpload}
            lang="es"
            placeholder="Comenzar a escribir .."
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "list",
                  "align",
                  "fontSize",
                  "formatBlock",
                  "image",
                  "hiliteColor",
                  "horizontalRule",
                ],
              ],
              value: `${loadlabels ? "" : `${document.body}`}`,
              showPathLabel: false,
              imageHeight: "200px",
              imageHeightShow: false,
              imageUploadSizeLimit: "100000",
              defaultStyle: "line-height: 2; font-family: 'Roboto-Regular'; font-size: 16px;"
            }}
          />

          <div></div>
        </div>
      </Grid>

      <Modal
        open={openModalConfig}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={`modal-md-width modal-desk d-flex flex-column`}>
          <ModalConfig
            setOpen={setOpenModalConfig}
            open={openModalConfig}
            filePortada={data.cover_blog}
            text={text}
            labelsUp={topicsbyLocation}
            title={title}
            setTitle={setTitle}
            dataUp={data}
            labelsUpd={labels}
            imageOnBlog={imageOnBlog}
            isAcceptable={isAcceptable}
            isUploaded={isUploaded}
            imageOnBlogUploaded={imageOnBlogUploaded}
            uploadedImages={uploadedImages}
            uploadedImagesFiles={uploadedImagesFiles}
            deletedImages={deletedImages}
          />

          <div
            className={`${isAcceptable ? 'alert-modal d-flex justify-content-center align-items-center mt-2' : '  d-none'}`}
          // className={`${'alert-modal d-flex justify-content-center align-items-center mt-2'}`}        
          >
            <img src={Alert} className="mr-4" />
            <p className="text-big  a-text-regular-second-neutral-90">Usa una imágen que cumpla con los parámetros o utiliza una imágen predeterminada</p>
          </div>

        </div>
      </Modal>
    </Grid>
  );
}
