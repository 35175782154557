import React from "react";
import emptyState404 from "../../../../assets/img/questions/sin-resultados.svg";
import { temas } from "../../../../helpers";
export default function EmptyFilterState({ topic, data }) {
  const topicsEmpty = topic.map(topicState => data.find(dataState => dataState.topics.topics_id === topicState))
  const temasCorreccion = topicsEmpty.map(topicState => temas.find(dataState => dataState.topics_id === topicState.topics.topics_id))
  return (
    <div className="text-center mt-md-3 ">
      <div className="d-flex flex-column border-ocean-30 py-3  py-md-3 px-lg-4 py-lg-4 br-2 mx-lg-4 ">
        <span className="a-text-medium-second-neutral-90 text-small h3-md  h1-xl mb-md-3 px-3 ">
          No se encontraron resultados para
        </span>
        <span className="a-text-medium-first-primary-70 text-small h3-md h1-xl  px-3">
          "{temasCorreccion.map(tema => (`${tema.name}${oracionArreglada(temasCorreccion, tema)}`))}."
        </span>

        <img src={emptyState404} className="mt-3  d-block m-auto w-500 h1-500" />
      </div>
    </div>
  );
}
const oracionArreglada = (topicsEmpty, tema) => {
  return topicsEmpty.indexOf(tema) + 2 === topicsEmpty.length ? ` y `
    : topicsEmpty.indexOf(tema) + 1 < topicsEmpty.length ? `, `
      : ``
}