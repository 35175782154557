import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid,
  Modal,
  useMediaQuery,
  IconButton,
  createMuiTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import QuestionInputWithRows from "../../../components/molecules/QuestionInput/QuestionInputWithRows/index";
// React
import { creteQuestion } from "../../../api/questions";
import { Input } from "../../atoms/Input";
import Search from "../../../assets/img/icon/search.svg";
import { Button } from "../../atoms/Button";
import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";
import PreviewImageModal from "../../molecules/Modal/PreviewImageModal";
import Close from "../../../assets/img/closeFilter.svg";
import { getCidUsers } from "../../../api/user";
import enviar from "../../../assets/img/questions/enviar.svg";
import SkeletonCidUser from "../Skeleton/SkeletonQuestions/SkeletonCidUser";
import { useAuth } from "../../../hooks/useAuth";
import Tema from "../../../assets/img/questions/temas.svg";
import TemaFull from "../../../assets/img/questions/temas2.svg";
import Basurita from "../../../assets/img/questions/basurita.svg";
import emptyState404 from "../../../assets/img/questions/sin-resultados.svg";
import asignar from "../../../assets/img/questions/user1.svg";
import asignarFill from "../../../assets/img/questions/user2.svg";
import Arrow from "../../../assets/img/arrowright.svg";
import { FormAddQuestionBytopic } from "../../../components/organisms/FormAddQuestionTopic/FormAddQuestion/index";

let FilesSelectedImage = [];
const data = [
  {
    id: 1,
    title: "Ciencia",
    link_logo_topic: null,
  },
  {
    id: 2,
    title: "Tecnología",
    link_logo_topic: null,
  },
  {
    id: 3,
    title: "Economía",
    link_logo_topic: null,
  },
  {
    id: 4,
    title: "Negocios",
    link_logo_topic: null,
  },
  {
    id: 5,
    title: "Finanzas",
    link_logo_topic: null,
  },
  {
    id: 6,
    title: "Politica",
    link_logo_topic: null,
  },
  {
    id: 7,
    title: "Development",
    link_logo_topic: null,
  },
  {
    id: 8,
    title: "Web",
    link_logo_topic: null,
  },
  {
    id: 9,
    title: "Gaming",
    link_logo_topic: null,
  },
];

let topics = [];

export default function CreateQuestion({
  setOpen,
  setModalBody,
  setOpenTopics,
  openTopics,
  controlImage,
  setUserSelected,
  userSelected,
  SuccessAsignment,
  initialText = "",
}) {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  const user = useAuth();

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [HomeModal, setHomeModal] = useState(true);
  const [switchTopics, swtSwitchTopcis] = useState(false);
  const [switchAsign, swtSwitchAsign] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userId } = user;
  const [loadingMini, setLoadingMini] = useState(false);
  const [modalAssginConfirm, setModalmodalAssginConfirm] = useState(false);
  const [asignValue, setAsignValue] = useState([""]);
  const [openPreviewModal, setopenPreviewModal] = useState({
    open: false,
    url: "",
  });
  const [imagen, setImagen] = useState("");
  const [cidUser, setCidUser] = useState([]);
  const [deleteD, setDeteled] = useState(false);
  // const [textState, setTextState] = useState('')
  const [textState, setTextState] = useState(initialText);
  const [storeTopics, setStoreTopics] = useState(null);
  const [openMiniModal, setOpenMiniModal] = useState(false);
  const deskTop = useMediaQuery("(min-width: 1000px)");
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(true);
  
  const [erroresMensaje, setErroresMensaje] = useState("")
  const [modalConfirm, setModalCofirm] = useState(true);
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992));
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const ipadProUse = useMediaQuery("(min-width:992px)");
  let numImages = 0;
  const searchInput = useRef(null);

  useEffect(() => {
    setLoad(true);
    getCidUsers(userId)
      .then((res) => {
        setCidUser(res.data.users);
        setLoad(false);
      })
      .catch((err) => console.log(err));
  }, [switchAsign]);

  const handleSearch = () => {
    setSearch(searchInput.current.value);
  };
  const filteredUsers = useMemo(
    () =>
      cidUser.filter((cid) => {
        return cid.name.toLowerCase().includes(search.toLowerCase());
      }),
    [cidUser, search]
  );

  const modal = (
    <form onSubmitCapture={submitCreateForm}>
      <InputWithLabel
        text="Agregar temas relacionados"
        id="question-topic-input"
        type="checkboxTextTopic"
        Location={true}
        data={data}
        value={[]}
        placeholder="Agregar topic"
        className="btn-checkbox-icon-add-topic"
        focus={initialText != "" ? false : true}
      />

      <div className="d-flex mt-3 w-md-50 ">
        <div className="w-100 mr-3 text-decoration-none">
          <Button
            type="submit"
            classes="text-normal a-button-topic-gray-1 roboto-bold "
            height={36}
            width="100%"
            text={loadingMini ? <CircularProgress /> : "Omitir"}
            disabled={loadingMini ? true : false}
          />
        </div>
        <div className="w-100   ">
          <Button
            type="submit"
            classes="text-normal  a-button-topic-blue-1 roboto-bold "
            height={36}
            width="100%"
            text={loadingMini ? <CircularProgress /> : "Publicar"}
            disabled={loadingMini ? true : false}
          />
        </div>
      </div>
    </form>
  );

  const renderPhotos = (source) => {
    numImages = source.length;
    return source.map((photo, index) => {
      return (
        <div
          key={photo}
          className={`${numImages === 1 ? `${ipadMiniMatch ? "w-100 " : "w-100"}` : "w-48"
            }  w-lg-50 mb-2  d-flex flex-row-ms-reverse `}
        >
          <div
            className={`position-absolute ${index >= 4 ? "d-none" : "position-absolute "
              } `}
          >
            <Tooltip title="borrar">
              <IconButton aria-label="borrar">
                <img
                  src={Basurita}
                  alt=""
                  onClick={() => {
                    selectedFiles.splice(index, 1);
                    FilesSelectedImage.splice(index, 1);
                    setDeteled(!deleteD);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>

          <img
            onClick={() => setopenPreviewModal({ open: true, url: photo })}
            src={photo}
            alt="phohoto"
            className={` br-2 ${numImages === 3 && index === 0
                ? "item1 "
                : `itemN  ${index >= 4 ? "d-none" : ""} `
              }  `}
          />
        </div>
      );
    });
  };

  function handleWrite(evn) {
    if (evn.target.value !== "") {
      setTextState(evn.target.value);
    } else {
      setTextState("");
    }
  }

  const handleClick = () => {
    const topicInputs = document.querySelectorAll(".input-checkbox-icon:checked");
    const newTopics = Array.from(topicInputs).map((topic) => {
      const topicData = JSON.parse(topic.value);
      return { topics_id: topicData.topic_id };
    });
  
    if (JSON.stringify(newTopics) !== JSON.stringify(topics)) {
      topics = newTopics;
    }
  };
  
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    let filesArray = selectedFiles.map((file) => URL.createObjectURL(file));
    if (FilesSelectedImage.length + filesArray.length > 4) {
      controlImage(true);
      filesArray = filesArray.slice(0, 4 - FilesSelectedImage.length);
    }
    setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    selectedFiles.forEach((file) => URL.revokeObjectURL(file));
    FilesSelectedImage.push(selectedFiles[0])
  };

  async function submitCreateForm(e) {
    e.preventDefault();
     
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("question", textState);
    formData.append("assignedUser", userSelected.user_id);
  
    if (FilesSelectedImage.length > 0) {
      FilesSelectedImage.slice(0, 4).forEach((file, index) => {
        formData.append("photo", file);
      });
    } else {
      formData.append("photo", []);
    }
  
    if (topics.length > 0) {
      topics.forEach((topic) => {
        formData.append("topic", topic.topics_id);
      });
    } else {
      formData.append("topic", "");
    }
  
    const trimmedTextState = textState.trim();
    if (trimmedTextState === "" && topics.length === 0) {
      setErroresMensaje("Los campos descripción y temas son obligatorios");
    } else if (trimmedTextState === "") {
      setErroresMensaje("El campo de descripción es obligatorio");
    } else if (topics.length === 0) {
      setErroresMensaje("El campo de temas es obligatorio");
    } else {
      setLoading(true);
      setLoadingMini(true);
  
      try {
        await creteQuestion(formData);
        window.location.href = "/cid/carso/questions/list";
      } catch (error) {
        console.error(error);
      }
    }
  }
  

  numImages = selectedFiles.length;
  if (userSelected.nameUser !== "") {
    SuccessAsignment(true);
  }
  return (
    <form onSubmitCapture={submitCreateForm}>
      {ipadProUse ? (
        <>
          {HomeModal ? (
            <div className={` px-5 `}>
              <div className="w-100 d-flex justify-content-between align-items-center  mb-4    ">
                {" "}
                <p className="h3-md a-text-medium-first-primary-60">
                  Agrega tu pregunta
                </p>{" "}
                <IconButton>
                  <img
                    src={Close}
                    onClick={() => setOpen(false)}
                    alt="PDF Icon"
                    className="cursor-pointer "
                    width="24px"
                  />
                </IconButton>
              </div>
              
              <p className="mb-4 text-red">{erroresMensaje}</p>
              <QuestionInputWithRows
                id={"question-text-ipad"}
                placeholder={"Agrega una pregunta pública"}
                focus={true}
                onChange={(e) => handleWrite(e)}
                value={textState}
              />

              <div className=" mt-3 d-flex pl-5  align-items-center  ">
                <div className="mr-4">
                  <p className="h3-md a-text-regular-first-primary-60">
                    Añadir:{" "}
                  </p>
                </div>
                <div className="d-flex align-items-center mr-4  ">
                  <Tooltip title="Imagen">
                    <IconButton aria-label="imagen">
                      <Input
                        textSize="text-small"
                        id="image-question-ipad"
                        type="fileText"
                        placeholder="undifined"
                        onChange={handleImageChange}
                        value={imagen}
                        count={FilesSelectedImage.length}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Temas">
                    <IconButton aria-label="temas">
                      <img
                        src={topics.length === 0 ? Tema : TemaFull}
                        alt=""
                        onClick={() => {
                          setHomeModal(false);
                          swtSwitchTopcis(true);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Asignar">
                    <IconButton aria-label="asignar">
                      <img
                        src={userSelected.user_id !== 0 ? asignarFill : asignar}
                        alt=""
                        onClick={() => {
                          setHomeModal(false);
                          swtSwitchAsign(true);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <span className="a-text-regular-first-primary-60 text-xs w-100px">
                    {userSelected.nameUser}
                  </span>
                </div>
                <div className="d-flex flex-row-ms-reverse w-100 ml-2">
                  <Button
                    type="submit"
                    classes={`text-normal   a-button-topic-blue-1 roboto-bold ${loading ? "disabled" : ""
                      } `}
                    height={36}
                    width="136px"
                    text={loading ? <CircularProgress /> : "Publicar"}
                    disabled={loading ? true : false}
                  />
                </div>
              </div>

              {deleteD ? (
                <>
                  {" "}
                  {numImages == 3 ? (
                    <div className="d-flex flex-column flex-wrap h-min-grid  pl-5 mt-2">
                      {renderPhotos(selectedFiles)}
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap justify-content-between mt-4  pl-5 ">
                      {renderPhotos(selectedFiles)}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {numImages == 3 ? (
                    <div className="d-flex flex-column flex-wrap h-min-grid pl-5 mt-2">
                      {renderPhotos(selectedFiles)}
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap justify-content-between mt-4  pl-5 ">
                      {renderPhotos(selectedFiles)}
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="w-100 d-flex ">
              {switchTopics ? (
                <div className="d-flex pl-3 ">
                  <div className=" mr-2">
                    <IconButton aria-label="asignar">
                      <img
                        src={Arrow}
                        alt=""
                        className="arrow"
                        onClick={() => {
                          handleClick();
                          setHomeModal(true);
                          swtSwitchTopcis(false);
                        }}
                      />
                    </IconButton>
                  </div>
                  <div className="pt-2">
                    {" "}
                    <FormAddQuestionBytopic
                      userId={userId}
                      config={"btn-none"}
                      text={`Selecciona temas relacionados`}
                      storeTopics={storeTopics}
                      info={topics}
                    />
                  </div>
                </div>
              ) : (
                <div />
              )}
              {switchAsign ? (
                <div className="w-100 d-flex">
                  <div className="mr-4">
                    <IconButton aria-label="asignar">
                      <img
                        src={Arrow}
                        alt=""
                        onClick={() => {
                          setHomeModal(true);
                          swtSwitchAsign(false);
                        }}
                      />
                    </IconButton>
                  </div>
                  <div className="w-100 pt-2">
                    <div className="mb-3 d-flex flex-column">
                      <span className="a-text-medium-first-primary-70 h3-lg mb-3 ">
                        Asignar una pregunta
                      </span>
                      <span className="a-text-medium-second-neutral-70 text-normal   ">
                        Solicita la respuesta de una persona de la comunidad
                      </span>
                    </div>
                    <div
                      className={`d-flex input-search box-shadow-all background-white mb-2 `}
                      style={{ width: "100%" }}
                    >
                      <input
                        ref={searchInput}
                        onChange={handleSearch}
                        autoFocus={true}
                        placeholder={"Buscar en persona"}
                        className="input-basic pl-3 pl-lg-4 text-small text-md-normal a-text-regular-second-neutral-60 w-100"
                        value={search}
                      />
                      <img src={Search} alt="Search Icon" className="pr-1" />
                    </div>
                    {load ? (
                      <SkeletonCidUser num={5} />
                    ) : (
                      <div className=" h-100vh-100px  scroll-overflow  container-cidUser mt-4">
                        {" "}
                        <Input
                          text={""}
                          id="question-topic"
                          placeholder="Agregar topic"
                          type="checkboxUserAsign"
                          Location={true}
                          data={filteredUsers}
                          value={[userSelected]}
                          className="btn-checkbox-icon-add"
                          setUser={setUserSelected}
                          setModal={setHomeModal}
                          onClick={SuccessAsignment}
                          setModalLocation={swtSwitchAsign}
                        />
                        {filteredUsers.length === 0 ? (
                          <div className="text-center mt-3">
                            <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
                              No se encontraron resultados <br />{" "}
                            </p>

                            <img
                              src={emptyState404}
                              className="mt-5 d-block m-auto w-500 image-empty "
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div />
              )}{" "}
            </div>
          )}
        </>
      ) : (
        <div className={`py-2 px-md-5  px-4  `}>
          <div className="w-100 d-flex justify-content-between align-items-center  mb-4  border-bottom-second-Neutral-100 pb-3 ">
            {" "}
            <p className="h3-md a-text-medium-first-primary-60">
              Agrega tu pregunta
            </p>{" "}
            <IconButton>
              <img
                src={Close}
                onClick={() => setOpen(false)}
                alt="PDF Icon"
                className="cursor-pointer "
                width="24px"
              />
            </IconButton>
          </div>
          <span
            className={`checked py-3 px-3 mb-4 mt-1 mb-md-5   ${ipadMiniMatch ? " d-flex flex-column pl-5" : ""
              } `}
          >
            <p className="text-small text-md-big a-text-medium-first-primary-70 text ">
              Tips para tener una buena respuesta a tu pregunta{" "}
            </p>
            <ul className="pl-4 mt-3 px-md-5   ">
              <li className="text-small  text-md-big a-text-regular-first-primary-60 mb-2">
                Realiza una pregunta de forma breve y concreta
              </li>
              <li className="text-small text-md-big a-text-regular-first-primary-60 ">
                Verifica tu gramática y ortografía
              </li>
            </ul>
          </span>
          <QuestionInputWithRows
            id={"question-text-ipad-mini"}
            placeholder={"Agrega una pregunta pública"}
            focus={true}
          />
          <div className="d-flex flex-column pl-5 mt-md-3">
            {numImages == 3 ? (
              <div className="d-flex flex-column flex-wrap h-min-grid ">
                {renderPhotos(selectedFiles)}
              </div>
            ) : (
              <div className="d-flex flex-wrap justify-content-between mt-2 pl-md-4">
                {renderPhotos(selectedFiles)}
              </div>
            )}
            <div className="d-flex ml-md-4 mt-md-3">
              {numImages == 4 ? (
                <Input
                  textSize="text-small"
                  id="image-question"
                  type="fileText"
                  placeholder=""
                  onChange={handleImageChange}
                  value={imagen}
                  count={FilesSelectedImage.length}
                />
              ) : (
                <Input
                  textSize="text-small"
                  id="image-question"
                  type="fileText"
                  placeholder={`${numImages == 0 ? "Agrega una imagen" : "Agrega más imágenes"
                    }`}
                  onChange={handleImageChange}
                  value={imagen}
                />
              )}
              <img
                src={enviar}
                alt="icon"
                className="sidebar-community__icon ml-3 ml-md-4 cursor-pointer  align-self-start "
                onClick={() => setOpenMiniModal(true)}
              />
            </div>
          </div>
        </div>
      )}

      <Modal
        open={openMiniModal}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">{modal}</div>
      </Modal>

      <Modal
        open={openPreviewModal.open}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-preview">
          <PreviewImageModal
            url={openPreviewModal.url}
            setOpen={setopenPreviewModal}
          />
        </div>
      </Modal>
    </form>
  );
}
