// React
import React, { createContext, useEffect } from "react";

// Api
import { getAccessToken, getRefreshToken, logout, refreshAccessToken } from "../api/auth";

// Jwt
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

export default function AuthProvider(props) {
    // State
   
    const { children } = props;
    const accessToken = getAccessToken();
    if (!accessToken) {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            return <AuthContext.Provider value=''>{children}</AuthContext.Provider>
        }
        return <AuthContext.Provider value={{ name: '' }}>{children}</AuthContext.Provider>
    }
    const user = jwtDecode(accessToken)
    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
}

// function checkUserLogin(setUser) {
// 	const accessToken = getAccessToken();
// 	if (!accessToken) {
// 		const refreshToken = getRefreshToken();
// 		if (!refreshToken) {
// 			logout();
// 			setUser({
// 				user: null,
// 				isLoading: false,
// 			});
// 		} else {
// 			refreshAccessToken(refreshToken);
// 		}
// 	} else {
// 		setUser({
// 			user: jwtDecode(accessToken),
// 			isLoading: false,
// 		});
// 	}
// }
