//import ReactTooltip from "react-tooltip";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";
import FilterIcon from "../../../assets/img/filtrar.svg";
import FilterIconWhite from "../../../assets/img/whitefilter.svg";
import EliminarFiltro from "../../../assets/img/eliminar_filtros.svg";
import OrderIcon from "../../../assets/img/ordenar.svg";
import OrderIconWhite from "../../../assets/img/ordenar_blanco.svg";
import Close from "../../../assets/img/icon/close.svg";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../atoms/Button";
import Tooltip from "react-png-tooltip";

import { WarningModal } from "../../molecules/Modal/WarningModal";
import ModalSuccess from "../../molecules/Modal/SuccessModal";

import {
  getUsersApi,
  deleteUserApi,
  deactivateUserApi,
  activateUserApi,
} from "../../../api/admin";

import useAuth from "../../../hooks/useAuth";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import UserImage from "../../../assets/img/user-icon.svg";
import PencilIcon from "../../../assets/img/pencil-icon-ocean.svg";
import TrashIcon from "../../../assets/img/trash-icon-ocean.svg";

// Material
import { Skeleton } from "@material-ui/lab";
import Header from "../../molecules/Header";
import { Badge } from "@material-ui/core";
import { Filters } from "./Filters";
import { Orders } from "./Orders";
import Paginations from "../../molecules/Paginations/Paginations";

export default function UserList({ modalValue, isCollapsed }) {
  const location = useLocation();

  const useStyles = makeStyles({
    root: {
      height: "24px",
      width: "50px",
      padding: "0px",
      fontFamily: "Roboto",
      overflow: "visible",
      marginBlockStart: "14px",
    },
    switchBase: {
      marginInlineStart: "5px",
      marginBlockStart: "5px",
      color: "#818181",
      padding: "0px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#355471",
          border: "1px solid #FFFF",
        },
      },
    },
    thumb: {
      color: "white",
      width: "16px",
      height: "16px",
    },
    track: {
      borderRadius: "20px",
      width: "70px",
      backgroundColor: "#a8aba8",
      border: "1px solid #a8aba8",
      opacity: "1 !important",
      "&:after, &:before": {
        fontFamily: "Roboto-regular",
        color: "white",
        fontSize: "14px",
        position: "absolute",
        top: "0px",
      },
    },
    checked: {
      padding: 0,
      color: "#23bf58 !important",
      transform: "translateX(24px) !important",
    },
  });

  const classes = useStyles();

  const match = useMediaQuery("(min-width: 1200px)");

  const [open, setOpen] = useState(modalValue.value);
  const [loading, setLoading] = useState(false);
  const [arraySkeleton, setArraySkeleton] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [deleteSuccess, setDeleteSuccess] = useState(true);
  const [loadDelete, setLoadDelete] = useState(true);
  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  const [openSuccessEdited, setOpenSuccessEdited] = useState(
    !!location.state && !!location.state.openSuccess
      ? location.state.openSuccess
      : false
  );
  const [openSuccessCreated, setOpenSuccessCreated] = useState(
    !!location.state && !!location.state.openSuccessCreated
      ? location.state.openSuccessCreated
      : false
  );
  const [userToDelete, setUserToDelete] = useState();
  const user = useAuth();
  const { userId } = user;
  const [users, setUsers] = useState([]);
  const history = useHistory();

  const [selectField, setSelectField] = useState("");

  const [filters, setFilters] = useState([]);
  const [orders, setOrders] = useState(3);

  // const handleSearch = (e) => {
  //   if (e.target.value !== "") {
  //     let text = e.target.value
  //     const inSearch = await getSearch(text)
  //     setBlogs(inSearch)
  //     setLoad(false)
  //   } else {
  //     setBlogs(allBlogsForSearch)
  //     setLoad(false)
  //   }
  // }

  const [dataArray, setDataArray] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [numResult, setNumResult] = useState(0);

  let resultados = 10;
  //Get current post
  const indexOfLastPost = currentPage * resultados;
  const indexOfFirstPost = indexOfLastPost - resultados;

  function paginate(page) {
    setCurrentPage(page);
    dataArray.length < page * resultados
      ? setNumResult(dataArray.length)
      : setNumResult(page * resultados);
  }

  let currentData = dataArray.slice(indexOfFirstPost, indexOfLastPost);

  let usuariosOrdenados = [];
  useEffect(() => {
    users.forEach((user) => {
      if (user.active === 1) {
        usuariosOrdenados.push(user);
      }
    });
    users.forEach((user) => {
      if (user.active === 0) {
        usuariosOrdenados.push(user);
      }
    });
    setDataArray(usuariosOrdenados);
  }, [users]);

  async function getUsers(userId) {
    try {
      let response = await getUsersApi(userId);
      console.log("usuarios", response.data);
      setUsers(response.data.users);
      setNumResult(response.data.users.slice(0, resultados).length);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  }

  // Peticiones
  useEffect(() => {
    // getUsersApi(userId)
    //   .then((result) => {
    //     if (result.status === 200) {
    //       setUsers(result.data.users);
    //       setLoading(true);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    getUsers(userId);
  }, [deleteSuccess]);

  useEffect(() => {
    // selectFilter = getActiveFilter(filters)
    if (filters.length > 0) {
      setDataArray(getActiveFilter(filters));
    } else {
      setDataArray(ordenarActivos);
    }
    // selectFilter = getActiveFilter(filters);
  }, [filters]);

  useEffect(() => {
    if (orders) {
      ordenar(orders);
    } else {
      setDataArray(ordenarActivos);
    }
  }, [orders]);

  useEffect(() => {
    if (selectField.length === 0) {
      setDataArray(ordenarActivos);
    } else {
      setDataArray(
        users.filter((res) => {
          let nombreCompleto = res.name + " " + res.lastname;
          return nombreCompleto
            .toLowerCase()
            .includes(selectField.toLowerCase());
        })
      );
    }
  }, [selectField]);

  const orderDate = (a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  };

  const ordenarActivos = () => {
    users.forEach((user) => {
      if (user.active === 1) {
        usuariosOrdenados.push(user);
      }
    });

    users.forEach((user) => {
      if (user.active === 0) {
        usuariosOrdenados.push(user);
      }
    });
    return usuariosOrdenados;
  };

  const ordenar = (valores) => {
    if (valores === 1) {
      setDataArray(dataArray.sort(orderDate));
    } else if (valores === 2) {
      setDataArray(dataArray.sort(orderDate).reverse());
    } else if (valores === 3) {
      users.forEach((user) => {
        if (user.active === 1) {
          usuariosOrdenados.push(user);
        }
      });
      users.forEach((user) => {
        if (user.active === 0) {
          usuariosOrdenados.push(user);
        }
      });
      setDataArray(usuariosOrdenados);
    } else if (valores === 4) {
      users.forEach((user) => {
        if (user.active === 0) {
          usuariosOrdenados.push(user);
        }
      });
      users.forEach((user) => {
        if (user.active === 1) {
          usuariosOrdenados.push(user);
        }
      });
      setDataArray(usuariosOrdenados);
    }
  };

  const filtrar = (value) => {
    switch (value) {
      case 115:
        return dataArray.filter((elm) => elm.active === 1);
        break;
      case 125:
        return dataArray.filter((elm) => elm.active === 0);
        break;
      case 135:
        return dataArray.filter((elm) => elm.rol_id === 2);
        break;
      case 145:
        return dataArray.filter((elm) => elm.rol_id === 3);
        break;
    }
  };

  const getActiveFilter = (filters) => {
    let aux = [];
    filters.forEach((elm) => {
      aux = filtrar(elm.value);
    });
    let usuariosRepetidos = [...new Set(aux)];
    aux.filter((elm) => !usuariosRepetidos.includes(elm));
    return aux;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalBase = (type) => {
    switch (type) {
      case 1:
        return (
          <>
            <div className="d-flex justify-content-center">
              <img
                src={SuccessIcon}
                alt="Success Icon"
                className="mb-3 modal__success-icon-modal"
              />
            </div>
            <p className="mb-3 a-text-medium-second-neutral-90 text-center">
              El usuario se ha añadido con éxito
            </p>
            <div className="d-flex justify-content-center">
              <div
                className="w-50"
                onClick={() => history.push("/cid/admin/users")}
              >
                <Button
                  type="button"
                  classes="a-button-primary1"
                  height={36}
                  width="100%"
                  text="Aceptar"
                />
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="modal">
              <div className="d-flex justify-content-center">
                <img
                  src={SuccessIcon}
                  alt="Success Icon"
                  className="mb-3 modal__success-icon-modal"
                />
              </div>
              <p className="mb-3 a-text-medium-second-neutral-90 text-center">
                El usuario se ha editado con éxito.
              </p>
              <div className="d-flex justify-content-center">
                <div className="w-50">
                  <Button
                    type="button"
                    classes="a-button-primary1"
                    height={36}
                    width="100%"
                    text="Aceptar"
                  />
                </div>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  };

  const [modalBody, setModalBody] = useState(modalBase(modalValue.type));
  const handleOpen = (user, type) => {
    switch (type) {
      case 3:
        // setModalBody(
        //   <>
        //     <div className="d-flex justify-content-center mb-4">
        //       <img src={AlertIcon} alt="Warning" />
        //     </div>
        //     <p className="mb-4 a-text-medium-second-neutral-90 text-center">
        //       No se podrán revertir los cambios.
        //       <br />
        //       ¿Seguro que deseas eliminar al usuario?
        //     </p>
        //     <div className="d-flex">
        //       <div onClick={handleClose} className="w-100 mr-3">
        //         <Button
        //           type="button"
        //           classes="a-button-primary1"
        //           height={36}
        //           width="100%"
        //           text="Cancelar"
        //         />
        //       </div>
        //       <div
        //         onClick={() => {
        //           deleteUser(id);
        //         }}
        //         className="w-100"
        //       >
        //         <Button
        //           type="button"
        //           classes="a-button-light"
        //           height={36}
        //           width="100%"
        //           text="Eliminar"
        //         />
        //       </div>
        //     </div>
        //   </>
        // );
        break;
      case 4:
        setModalBody(
          <>
            <div className="d-flex justify-content-center mb-4 mt-2">
              <Avatar
                alt="users"
                src={
                  user.photo_link === undefined ||
                    user.photo_link === "undefined" ||
                    user.photo_link === null
                    ? UserImage
                    : `https://images.weserv.nl/?url=${user.photo_link}&maxage=31d`
                }
              />
            </div>
            <p className="mb-4 a-text-medium-second-neutral-90 text-center text-big">
              El usuario perderá acceso a la{" "}
              <br className="d-none d-lg-block" />
              plataforma. ¿Deseas desactivarlo?
            </p>
            <div className="d-flex justify-content-center mb-3">
              <div
                onClick={() => {
                  deactivateUser(user.user_id);
                }}
                className="mr-3"
              >
                <Button
                  type="button"
                  classes={`a-text-medium-first-primary-60 a-button-topic-gray-2 mr-2 ${match ? "text-normal" : "text-small"
                    }`}
                  height={33}
                  width={match ? 147 : 98}
                  text="Desactivar"
                />
              </div>
              <div onClick={handleClose} className="">
                <Button
                  type="button"
                  classes={` a-button-primary1 ml-2 ${match ? "text-normal" : "text-small"
                    }`}
                  height={36}
                  width={match ? 147 : 98}
                  text="Cancelar"
                />
              </div>
            </div>
          </>
        );
        break;
      case 5:
        setModalBody(
          <>
            <div className="d-flex justify-content-center mb-4 mt-2">
              <Avatar
                alt="users"
                src={
                  user.photo_link === undefined ||
                    user.photo_link === "undefined" ||
                    user.photo_link === null
                    ? UserImage
                    : `https://images.weserv.nl/?url=${user.photo_link}&maxage=31d`
                }
              />
            </div>
            <p className="mb-4 a-text-medium-second-neutral-90 text-center text-big">
              El usuario tendrá acceso a la{" "}
              <br className="d-none d-lg-block " />
              plataforma. ¿Deseas activarlo?
            </p>
            <div className="d-flex justify-content-center mb-3">
              <div
                onClick={() => {
                  activateUser(user.user_id);
                }}
                className="mr-3"
              >
                <Button
                  type="button"
                  classes={`a-text-medium-first-primary-60 a-button-topic-gray-2 mr-2 ${match ? "text-normal" : "text-small"
                    }`}
                  height={33}
                  width={match ? 147 : 98}
                  text="Activar"
                />
              </div>
              <div onClick={handleClose}>
                <Button
                  type="button"
                  classes={` a-button-primary1 ml-2 ${match ? "text-normal" : "text-small"
                    }`}
                  height={36}
                  width={match ? 147 : 98}
                  text="Cancelar"
                />
              </div>
            </div>
          </>
        );
        break;
      default:
        break;
    }
    setOpen(true);
  };

  const handleStatusUserMovil = (user) => {
    if (user.active === 1) {
      return (
        <>
          <IconButton
            onClick={() => {
              handleOpen(user, 4);
            }}
          >
            <span className="a-text-regular-first-neutral-100 text-normal">
              Desactivar
            </span>
            <Switch
              checked={user.active}
              // onChange={handleCheck}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              className="mb-3"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            {/* <img src={SwitchIcon} alt="Switch Icon" className="ml-2" /> */}
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <IconButton
            onClick={() => {
              handleOpen(user, 5);
            }}
          >
            <span className="a-text-regular-first-neutral-100 text-normal">
              Activar
            </span>
            <Switch
              checked={user.active}
              // onChange={handleCheck}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              className="mb-3"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            {/* <img src={SwitchIcon} alt="Switch Icon" /> */}
          </IconButton>
        </>
      );
    }
  };

  const handleStatusUser = (user) => {
    if (user.active === 1) {
      return (
        <>
          <IconButton
            onClick={() => {
              handleOpen(user, 4);
            }}
          >
            <Switch
              checked={user.active}
              // onChange={handleCheck}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              className="mb-3"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            {/* <img src={SwitchIcon} alt="Switch Icon" /> */}
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <IconButton
            onClick={() => {
              handleOpen(user, 5);
            }}
          >
            <Switch
              checked={user.active}
              // onChange={handleCheck}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              className="mb-3"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </IconButton>
        </>
      );
    }
  };

  async function deleteObject(userId, id) {
    try {
      setOpenSuccess(true);
      await deleteUserApi(id, userId);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setOpenSuccess(false);
        setDeleteSuccess(!deleteSuccess);
      }, 500);
    }
  }

  const deactivateUser = (id) => {
    const adminId = userId;
    deactivateUserApi(id)
      .then((result) => {
        if (result.status === 200) {
          setOpen(false);
          return getUsersApi(adminId);
        }
      })
      .then((result) => {
        if (result.status === 200) {
          setUsers(result.data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activateUser = (id) => {
    const adminId = userId;
    activateUserApi(id)
      .then((result) => {
        if (result.status === 200) {
          setOpen(false);
          return getUsersApi(adminId);
        }
      })
      .then((result) => {
        if (result.status === 200) {
          setUsers(result.data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header
        //   isCollapsed={e.isCollapsed}
        hideMessageAndNoti={true}
        value={selectField}
        onChange={(e) => setSelectField(e.target.value)}
        text="Buscar usuarios"
        isCollapsed={isCollapsed}
      //   onKeyDown={handleKeyDown}
      />
      <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
        <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
          <Grid container justify="center" spacing={0}>
            <Grid item xs={10} lg={12}>
              <div className="d-flex d-lg-flex-row flex-column justify-content-between ">
                <p
                  className={`h3-lg text-normal a-text-medium-second-neutral-70 ${showFilters ? "mb-2 pb-1" : "mb-3"
                    }`}
                >
                  Catálogo de usuarios
                </p>
                {!showFilters && !showOrder && (
                  <Link
                    to="/cid/admin/add-user"
                    className="d-lg-block align-self-end"
                  >
                    <Button
                      type="button"
                      classes={`${match ? "text-small" : "text-xs"
                        } a-button-blue-1 roboto-regular mb-3`}
                      width={`${match ? "150px" : "109px"}`}
                      height={`${match ? "33px" : "30px"}`}
                      text="Añadir Usuario"
                    />
                  </Link>
                )}
              </div>
              {showFilters ? (
                <div>
                  <div className="d-flex justify-content-between">
                    <p className="align-self-center a-text-regular-first-primary-60 align-self-center text-medium mb-3">
                      Filtrar por:
                    </p>
                    <IconButton>
                      <img
                        src={Close}
                        alt="Close"
                        className="nav-company-icon mb-1"
                        onClick={() => setShowFilters(!showFilters)}
                      />
                    </IconButton>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="d-flex flex-wrap mb-2">
                      {filters.map((filter) => (
                        <p className="background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-medium-first-primary-60 mb-2">
                          {filter.name}
                        </p>
                      ))}
                    </div>
                    <div className="d-flex align-self-end mt-2 mb-3 pb-2">
                      <div
                        // disabled={error}
                        onClick={() => setFilters([])}
                        className={`d-flex align-items-center mr-lg-3 py-2 px-2 br-1 mr-2 
                          background-white cursor-pointer border-first-primary-50
                      `}
                      >
                        <img
                          src={EliminarFiltro}
                          alt="Order"
                          className="img-order"
                        />
                        <p
                          className={`ml-2 text-small text-md-normal a-text-regular-first-primary-60`}
                        >
                          Eliminar filtros
                        </p>
                      </div>

                      <div
                        //  disabled={error}
                        onClick={() => setShowFilters(!showFilters)}
                        className={`d-flex align-items-center
                       background-first-primary-60 cursor-pointer
                    py-2 px-2 br-1 mr-2`}
                      >
                        <img
                          src={FilterIconWhite}
                          alt="Filter"
                          className="img-order"
                        />
                        <p className="ml-2 text-small text-md-normal a-text-regular-third-neutral">
                          Filtrar
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Filters filters={filters} setFilters={setFilters} />
                  </div>
                </div>
              ) : showOrder ? (
                <div className="">
                  <div className="d-flex justify-content-between">
                    <p className="align-self-center a-text-regular-first-primary-60 align-self-center text-medium">
                      Ordenar por:
                    </p>
                    <IconButton>
                      <img
                        src={Close}
                        alt="Close"
                        className="nav-company-icon mb-1"
                        onClick={() => setShowOrder(!showOrder)}
                      />
                    </IconButton>
                  </div>
                  <div className="d-flex justify-content-end mb-4">
                    <div
                      //  disabled={error}
                      onClick={() => setShowOrder(!showOrder)}
                      className={`d-flex align-items-center
                       background-first-primary-60 cursor-pointer
                    py-2 px-2 br-1 mr-2`}
                    >
                      <img
                        src={OrderIconWhite}
                        alt="Order"
                        className="img-order"
                      />
                      <p className="ml-2 text-small text-md-normal a-text-regular-third-neutral">
                        Ordenar
                      </p>
                    </div>
                  </div>
                  <div>
                    <Orders orders={orders} setOrders={setOrders} />
                  </div>
                </div>
              ) : (
                <>
                  <div className="d-flex flex-column justify-content-xl-between">
                    <p className="a-text-medium-first-neutral-100 align-self-end text-sm ll text-md-normals text-lg-medium d-xl-none mb-3">
                      {`${indexOfLastPost > dataArray.length
                          ? dataArray.length
                          : indexOfLastPost
                        }`}{" "}
                      / {dataArray.length}{" "}
                      <span className="a-text-regular-first-neutral-100">
                        resultados
                      </span>
                    </p>
                    <p className="a-text-medium-first-neutral-100 text-small text-md-normals text-lg-medium d-none d-xl-block">
                      <span className="a-text-regular-first-neutral-100">
                        Mostrando
                      </span>{" "}
                      {`${indexOfLastPost > dataArray.length
                          ? dataArray.length
                          : indexOfLastPost
                        }`}{" "}
                      <span className="a-text-regular-first-neutral-100">
                        de
                      </span>{" "}
                      {dataArray.length}
                    </p>
                    <div className="d-flex align-items-center justify-content-end mt-lg-2 mb-4 mb-lg-3 pb-lg-3">
                      <div
                        onClick={() => setShowOrder(!showOrder)}
                        className="d-flex align-items-center cursor-pointer mr-xl-4 mr-3"
                      >
                        <Badge badgeContent={orders ? 1 : 0} color="primary">
                          <img
                            src={OrderIcon}
                            alt="Order"
                            className="img-order"
                          />
                        </Badge>
                        <p className="ml-2 text-small text-md-normal a-text-regular-first-primary-60">
                          Ordenar
                        </p>
                      </div>
                      {/* {showFilters ? (
                    <div className="d-flex">
                      <div
                        // disabled={error}
                        // onClick={error ? "" : eliminarFiltros}
                        className={`d-flex align-items-center mr-lg-3 py-2 px-2 br-1 mr-2
                            background-white cursor-pointer border-first-primary-50
                        `}
                      >
                        <img
                          src={EliminarFiltro}
                          alt="Order"
                          className="img-order"
                        />
                        <p
                          className={`ml-2 text-small text-md-normal a-text-regular-first-primary-60`}
                        >
                          Eliminar filtros
                        </p>
                      </div>
                      <div
                        //  disabled={error}
                        //  onClick={error ? "" : onClick}
                        className={`d-flex align-items-center mr-lg-3
                         background-first-primary-60 cursor-pointer
                      py-2 px-2 br-1 mr-2`}
                      >
                        <img
                          src={FilterIconWhite}
                          alt="Order"
                          className="img-order"
                        />
                        <p className="ml-2 text-small text-md-normal a-text-regular-third-neutral">
                          Filtrar
                        </p>
                      </div>
                    </div>
                                    ) : ( */}
                      <div
                        onClick={() => setShowFilters(!showFilters)}
                        className="d-flex cursor-pointer"
                      >
                        <Badge badgeContent={filters.length} color="primary">
                          <img
                            src={FilterIcon}
                            alt="Order"
                            className="img-filter"
                          />
                        </Badge>
                        <p className="ml-2 text-small text-md-normal a-text-regular-first-primary-60">
                          Filtrar
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <Link to="/admin/add-user">
                  <Button
                    classes="a-button-primary1"
                    height={36}
                    text="Añadir Usuario"
                  />
                </Link>{" "} */}

                  {showFilters ? (
                    loading ? (
                      <Filters filters={filters} setFilters={setFilters} />
                    ) : (
                      // ? users.map((user, index) => (
                      //     <div
                      //       user_id={user.user_id}
                      //       key={index}
                      //       className="container-user card-list-user mb-4 d-flex align-items-center justify-content-between"
                      //     >
                      //       {hideUser(user)}
                      //       <div className="mr-4">
                      //         <Link to={`/admin/update-user/${user.user_id}`}>
                      //           <IconButton>
                      //             <img src={PencilIcon} alt="Pencil Icon" width={24}/>
                      //           </IconButton>
                      //         </Link>
                      //         {handleStatusUser(user)}
                      //         <IconButton
                      //           className=""
                      //           onClick={() => {
                      //             // handleOpen(user.user_id, 3);
                      //             setOpenWarning(true)
                      //             setUserToDelete(user.user_id)
                      //           }}
                      //         >
                      //           <img src={TrashIcon} alt="Trash Icon" width={24} />
                      //         </IconButton>
                      //       </div>
                      //     </div>
                      //   ))
                      // arraySkeleton.map((r, i) => (
                      //   <div
                      //     key={i}
                      //     className="container-user pl-4 pr-4 mb-4 pt-1 pb-1 pt-md-2 pb-md-2 d-flex align-items-center justify-content-between w-100"
                      //   >
                      //     <div className="d-flex align-items-center w-100 pt-md-1 pb-md-1">
                      //       <Skeleton
                      //         variant="circle"
                      //         width={40}
                      //         height={40}
                      //         animation="wave"
                      //         className="br-1 mr-3"
                      //       />
                      //       <Skeleton
                      //         variant="h3"
                      //         width="30%"
                      //         animation="wave"
                      //       />
                      //     </div>
                      //     <Skeleton
                      //       variant="h3"
                      //       width="100px"
                      //       animation="wave"
                      //     />
                      //   </div>
                      // ))
                      <div>buenas</div>
                    )
                  ) : (
                    currentData.map((res, index) => {
                      return (
                        <div
                          user_id={res.user_id}
                          key={index}
                          className="card mb-3 d-flex justify-content-between pr-3 align-items-center box-shadow-lg-all"
                        >
                          <div className="d-flex ml-2 pl-1 ml-lg-3 my-2 align-items-center">
                            <Avatar
                              alt="users"
                              src={
                                res.photo_link === undefined ||
                                  res.photo_link === "undefined" ||
                                  res.photo_link === null
                                  ? UserImage
                                  : `https://images.weserv.nl/?url=${res.photo_link}&maxage=31d`
                              }
                              className="mr-4"
                              style={res.active === 0 ? { opacity: 0.5 } : {}}
                            />
                            <p
                              className={`${res.active === 1
                                  ? "a-text-regular-first-neutral-100"
                                  : "a-text-regular-first-neutral-50"
                                } text-md-norma`}
                            >
                              {res.name} {res.lastname}
                            </p>
                          </div>

                          <div className="d-none d-lg-flex align-items-center">
                            <Link to={`/cid/admin/update-user/${res.user_id}`}>
                              <IconButton>
                                <img
                                  src={PencilIcon}
                                  alt="Pencil Icon"
                                  width={24}
                                />
                              </IconButton>
                            </Link>
                            {handleStatusUser(res)}
                            <IconButton
                              className=""
                              onClick={() => {
                                // handleOpen(user.user_id, 3);
                                setOpenWarning(true);
                                setUserToDelete(res.user_id);
                              }}
                            >
                              <img
                                src={TrashIcon}
                                alt="Trash Icon"
                                width={24}
                              />
                            </IconButton>
                          </div>

                          <IconButton edge="end" className="d-lg-none">
                            <Tooltip
                              placement="top"
                              tooltip={<MoreHorizIcon />}
                              className="tooltip"
                            >
                              <div className="display-grid">
                                {/* <IconButton>
                                    <Link to={linkEdit} className="w-100 text-decoration-none d-flex justify-content-between">
                                        <span className="a-text-regular-first-neutral-100 text-normal">Editar</span>
                                        <img src={PencilIconOcean} alt="Pencil Icon" className="ml-3" width="23px" />
                                    </Link>
                                </IconButton> */}
                                {/* <a
                                    href={linkPdf}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="d-flex justify-content-between w-100 text-decoration-none"
                                  >
                                    <span className="a-text-regular-first-neutral-100 text-normal">
                                      Descargar
                                    </span>
                                    <img
                                      src={PdfIconOcean}
                                      alt="PDF Icon"
                                      className="cursor-pointer"
                                      width="24px"
                                    />
                                  </a> */}
                                {handleStatusUserMovil(res)}
                                <IconButton
                                  onClick={() => {
                                    window.location.href = `/cid/admin/update-user/${res.user_id}`;
                                  }}
                                >
                                  {/* <a href={`/admin/update-user/${res.user_id}`} rel="noreferrer" target="_blank" className="d-flex justify-content-between w-100 text-decoration-none"> */}
                                  <span className="a-text-regular-first-neutral-100 text-normal">
                                    Editar
                                  </span>
                                  {/* </a> */}
                                  <img
                                    src={PencilIcon}
                                    alt="Pencil Icon"
                                    width={24}
                                  />
                                </IconButton>
                                <IconButton
                                  className=""
                                  onClick={() => {
                                    // handleOpen(user.user_id, 3);
                                    setOpenWarning(true);
                                    setUserToDelete(res.user_id);
                                  }}
                                >
                                  <span className="a-text-regular-first-neutral-100 text-normal">
                                    Eliminar
                                  </span>
                                  <img
                                    src={TrashIcon}
                                    alt="Trash Icon"
                                    width={24}
                                  />
                                </IconButton>
                              </div>
                            </Tooltip>
                          </IconButton>
                        </div>
                      );
                    })
                  )}
                  {showFilters || showOrder ? (
                    ""
                  ) : (
                    <Paginations
                      className={currentData.length < 3 && "mb-20"}
                      postPerPage={resultados}
                      totalCompanies={dataArray.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">{modalBody}</div>
      </Modal>
      <Modal
        open={openSuccess}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${loadDelete ? "Espera un momento..." : "Usuario Eliminado"
              }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
      <Modal
        open={openWarning}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <WarningModal
            message={`No se podrán revertir los cambios. ¿Seguro que deseas eliminar al usuario?`}
            open={openWarning}
            setOpen={setOpenWarning}
            deleteObject={deleteObject}
            object={userToDelete}
          />
        </div>
      </Modal>
      <Modal
        open={openSuccessEdited}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <div className="d-flex justify-content-center">
            <img
              src={SuccessIcon}
              alt="Success Icon"
              className="mb-3 modal__success-icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            Se actualizaron los datos correctamente.
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50">
              <Button
                onclick={() => {
                  setOpenSuccessEdited(false);
                  history.push({
                    state: {},
                  });
                }}
                type="button"
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openSuccessCreated}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <div className="d-flex justify-content-center">
            <img
              src={SuccessIcon}
              alt="Success Icon"
              className="mb-3 modal__success-icon-modal"
            />
          </div>
          <p className="mb-3 a-text-medium-second-neutral-90 text-center">
            El usuario se ha añadido con éxito
          </p>
          <div className="d-flex justify-content-center">
            <div className="w-50">
              <Button
                type="button"
                onclick={() => {
                  setOpenSuccessCreated(false);
                  history.push({
                    state: {},
                  });
                }}
                classes="a-button-primary1"
                height={36}
                width="100%"
                text="Aceptar"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
