// React
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Material
import { Grid, useMediaQuery, createMuiTheme, IconButton, Modal, DialogTitle } from '@material-ui/core/';

import { Link } from "react-router-dom";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { getProfile } from "../../../api/profile";
import { createBlog } from "../../../api/blogs";

import EmptyStateBlog from "../../../components/molecules/EmptyState/EmptyStateBlog/index";
import Close from '../../../assets/img/closeFilter.svg'
import Alert from '../../../assets/img/alert.svg'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

// Componentes
import Header from "../../../components/molecules/Header";
import ModalConfig from "../../../components/molecules/Modal/configBlogModal";
import { DraftModal } from "../../../components/molecules/Modal/DraftModal";

import { CropFree, Language, LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { useStaticState } from "@material-ui/pickers";
const valueDefault = [
    { client_id: 34, name: 'Centro de Inteligencia e Innovación Digital', logo_client: 'https://firebasestorage.googleapis.com/v0/b/images-wikicid.appspot.com/o/reportes%2Fclientes%2FCID_V.png?alt=media&token=933e59ef-bbd6-4f73-83b8-502660f0f195' },
];
const hoy = new Date();

let globalTitle = "";
let globalText = "";

export default function CreateBlog(e) {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)

    const [filesPortada, setFilesPortada] = useState(false)
    const [imageOnBlog, setImageOnBlog] = useState('')
    const [imageOnBlogUploaded, setImageOnBlogUploaded] = useState(null)
    const [openModalConfig, setOpenModalConfig] = useState(false)
    const [text, setText] = useState('')
    const [title, setTitle] = useState('')
    const [natWidth, setNatWidth] = useState(undefined)
    const [natHeight, setNatHeight] = useState(undefined)
    const [isAcceptable, setIsAcceptable] = useState(false)
    const [isUploaded, setisUploaded] = useState(false)
    const [dayTime, setDayTime] = useState('');
    const [time, setTime] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [date, setDate] = useState('');
    const [uploadedImages, setUploadedImages] = useState([])
    const [uploadedImagesFiles, setUploadedImagesFiles] = useState([])
    const [deletedImages, setDeletedImages] = useState([])

    const [openWarning, setOpenWarning] = useState(false)
    const [sendDraft, setSendDraft] = useState(true)

    const user = useAuth();

    const { userId } = user;
    const theme = createMuiTheme({
        breakpoints: {
            values: {
                md: 768,
                lg: 992,
            },
        },
    });


    useEffect(() => {
        getProfileData(userId)
    }, [])

    useEffect(() => {
        return () => {
            sendDraft && submitCreateForm();
        }
    }, [])

    useEffect(() => {
        submitCreateForm();
        if (sendDraft == false) {
            window.location.href = "/cid/carso/blogs/list";
        }

    }, [sendDraft])

    async function getProfileData(userId) {
        setLoad(true)

        try {
            const response = await getProfile(userId)
            setData(response.data.profile[0])
            setLoad(false)
        } catch (error) {
            console.log(error)
        }

    }
    const ipadMiniMatch = useMediaQuery("(min-width: 768px)")
    const iPadProMatch = useMediaQuery(theme.breakpoints.up('lg'));
    const Desk = useMediaQuery("(min-width: 1100px)")

    let imagesFiles = []

    function handleImageUploadBefore(files, info, uploadHandler) {
        console.log("before uploading files", files)
        console.log("before uploading info", info)
        setImageOnBlogUploaded(files)
        setisUploaded(true)
        uploadHandler(files)
        imagesFiles.push(files)
        console.log("this is imagesFiles", imagesFiles)
        setUploadedImagesFiles([...imagesFiles])
    }

    // function handleKeyDown(event){
    //     console.log(event.target.lastChild,'onclick')

    //     if(event.target.lastChild.className === 'titulos'){
    //         console.log(event.target.outerText,'texto en input')
    //         console.log(event.inputType)
    //         console.log(event.target.lastChild.className,'onclick')
    //     }

    // }

    function handleKeyDown(e) {
        // if(e.keyCode === 8){
        //     console.log("presionaste un borrar");
        // }
    }

    let imagesArray = []
    let imagesOffTheArray = []

    function handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
        console.log(targetImgElement, 'targetImgElement')
        console.log(index, 'index')
        console.log(state, 'state')
        console.log(imageInfo, 'imageInfo')
        console.log(remainingFilesCount, 'remainingFilesCount')
        let width;
        let height;

        switch (state) {
            case "create":
                if (targetImgElement) {
                    targetImgElement.onload = function () {
                        width = this.naturalWidth;
                        height = this.naturalHeight;
                        console.log(width, "nh")
                        console.log(height, "nw")

                        setNatWidth(width)
                        setNatHeight(height)

                        if (height < 100 || width < 100) {
                            setIsAcceptable(true)
                        } else {
                            setIsAcceptable(false)
                        }

                    }
                    setFilesPortada(true)
                    setImageOnBlog(imageInfo)
                    imagesArray.push(imageInfo)
                    console.log("this is imagesArray", imagesArray)
                    setUploadedImages([...imagesArray])
                }
                break;
            case "delete":
                imagesOffTheArray.push(index)
                setDeletedImages([...imagesOffTheArray])
                if (imagesArray.length == imagesOffTheArray.length) {
                    setFilesPortada(false)
                }
                break;
        }

    }

    if (!user) {
        return (
            <Redirect to="/" />
        )
    }

    function handleChange(content) {
        setText(content)
        let titleSF
        if (!!document.querySelector(".titulos")) {
            titleSF = document.querySelector(".titulos").innerHTML
            setTitle(titleSF)
        } else {
            titleSF = ""
            setTitle(titleSF)
        }

        globalTitle = titleSF;
        globalText = content;
    }

    function submitCreateForm() {

        if ((globalTitle != '' || globalText.length > 100) && sendDraft) {
            var formData = new FormData()
            formData.append("user_id", userId);

            const indexInicio = globalText.indexOf("<h2");
            const indexFin = globalText.indexOf("</h2>");
            const titulo = globalText.slice(indexInicio, indexFin + 5);
            const descriptionSinTitle = globalText.replace(titulo, "");
            console.log(descriptionSinTitle.length > 0)
            if (descriptionSinTitle.length > 0) {
                formData.append("description", descriptionSinTitle);
            } else {
                formData.append("description", "");
            }

            if (globalTitle === '<br>' || globalTitle === '') {
                formData.append("title", 'Sin título')
            } else {
                formData.append("title", globalTitle);
            }

            formData.append("cover_blog", valueDefault[0].logo_client);
            formData.append("draw", 1)

            createBlog(formData).then(response => {
                // window.location.href = "/cid/carso/blogs/list";
                // setLoad(true)
                console.log("Se alcanzó a guardar como borrador")
            }).catch(error => {
                console.log(error)
            })
            console.log("Estos son los elementos del blog", userId, globalText, globalTitle);
            globalTitle = "";
            globalText = "";

        } else {
            return;
        }

    }


    /*
    
    Toda la personalización de este documento esta dentro del index.css que esta en la misma carpeta en a que estas ahora 
    es importante mencionar que muchos de los estilos fueron sobre escritos así que si quieres acceder a ellos todo se encuentra en esa hoja de sass
    
    El lugar de donde saque este editor de texto es
    http://suneditor.com/sample/index.html
    
    lo más optimo habría sido usarlo con NextJs pero bueno xd
    
    tengo entendido que vas a hacer autoguardado ojo con eso.
    revisa la página para que puedas entender mejor el código que estás viendo rai nau 
    */
    return (
        <Grid container justify="center" spacing={0} className=''>
            <Header isCollapsed={e.isCollapsed} link='/cid/analysis' text='Buscar pregunta' />
            <Grid
                item
                xs={10}
                lg={12}
                className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2">

                <div className={`background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ${Desk ? '' : 'h-100vh'} `}>
                    <div className="d-flex w-100 ">
                        <p
                            className={`mt-3 mt-lg-1 mb-lg-3 mb-lg-4 w-80  ${ipadMiniMatch ? `h3   mb-md-7  ${iPadProMatch ? "mt-0 mb-md-5  " : "mt-6 mb-md-7  "}` : ""} mb-3 a-text-medium-second-neutral-70  `}
                        >
                            Blog
                        </p>

                        {
                            iPadProMatch
                                ?
                                <div className="w-30 pl-3 d-flex justify-content-around align-items-center ">
                                    <button
                                        className={`text-normal  a-button-topic-blue-1  roboto-regular h3-md  text-lg-normal`}
                                        onClick={
                                            () => {
                                                setOpenModalConfig(true)
                                            }
                                        }
                                    >
                                        Publicar
                                    </button>
                                    <IconButton
                                        onClick={() => {
                                            setOpenWarning(true)
                                        }}
                                    >
                                        <img
                                            src={Close}
                                            alt="PDF Icon"
                                            className="cursor-pointer "
                                            width="24px"
                                        />
                                    </IconButton>
                                </div>
                                : <div />
                        }
                    </div>
                    <SunEditor
                        // autoFocus={true}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onImageUploadBefore={handleImageUploadBefore}
                        onImageUpload={handleImageUpload}
                        // defaultValue={`<h2 id="forFocus" class="titulos">Escribir un título</h2>`} 
                        setContents={`<h2 id="forFocus" class="titulos">Escribir un título</h2>`}
                        
                        lang="es"
                        setOptions={{
                            // font: [
                            //     "Roboto-Regular",
                            //     "Arial"
                            // ],
                            buttonList: [
                                [
                                    "undo",
                                    "redo",
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "list",
                                    "align",
                                    "fontSize",
                                    // "font",
                                    "formatBlock",
                                    "image",
                                    "hiliteColor",
                                    "horizontalRule",
                                ]
                            ],
                            showPathLabel: false,
                            imageHeight: '200px',
                            imageHeightShow: false,
                            imageUploadSizeLimit: '1000000',
                            defaultStyle: "line-height: 2; font-family: 'Roboto-Regular'; font-size: 16px;"
                        }}
                    />
                    <div>
                    </div>

                </div>

            </Grid>

            <Modal
                open={openModalConfig}
                onClose={false}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <>
                    <div className={`modal-md-width modal-desk d-flex flex-column`}>
                        <ModalConfig
                            setOpen={setOpenModalConfig}
                            open={openModalConfig}
                            filePortada={filesPortada}
                            text={text}
                            title={title}
                            setTitle={setTitle}
                            imageOnBlog={imageOnBlog}
                            isAcceptable={isAcceptable}
                            isUploaded={isUploaded}
                            imageOnBlogUploaded={imageOnBlogUploaded}
                            uploadedImages={uploadedImages}
                            uploadedImagesFiles={uploadedImagesFiles}
                            deletedImages={deletedImages}
                        />

                        <div
                            className={`${isAcceptable ? 'alert-modal d-flex justify-content-center align-items-center mt-2' : '  d-none'}`}
                        // className={`${'alert-modal d-flex justify-content-center align-items-center mt-2'}`}        
                        >
                            <img src={Alert} className="mr-4" />
                            <p className="text-big  a-text-regular-second-neutral-90">Usa una imágen que cumpla con los parámetros o utiliza una imágen predeterminada</p>
                        </div>

                    </div>

                </>
            </Modal>

            <Modal
                open={openWarning}
                onClose={false}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modal">
                    <DraftModal
                        message={"Deseas mantener este blog como borrador"}
                        open={openWarning}
                        setOpen={setOpenWarning}
                        setSendDraft={setSendDraft}
                    />
                </div>
            </Modal>
        </Grid>
    )

}
