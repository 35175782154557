// React
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

//Material
import { Grid, MenuList, Modal, useMediaQuery } from "@material-ui/core";

// Components
import Header from "../../../../components/molecules/Header";
import CardSkeleton from "../../../../components/molecules/Skeleton/CardSkeleton";
import { Button } from "../../../../components/atoms/Button";
import FilteredReports from "../../../../components/organisms/Reports/FilteredReports";

//import pagination hook
import Paginations from "../../../../components/molecules/Paginations/Paginations"

// Images
import Analisis from "../../../../assets/img/searchCompany/analisis.svg"; //cambiarla por una de reportes
import Sector from "../../../../assets/img/searchCompany/sector.svg";
import Market from "../../../../assets/img/searchCompany/tegnologia.svg";
import Cliente from "../../../../assets/img/searchCompany/clientes.svg";

import AlertIcon from "../../../../assets/img/alert.svg";
import SuccessIcon from "../../../../assets/img/sucess-icon.svg";
// hooks
import useCardList from "../../../../hooks/useCardList";
//API
import { deleteReport, getReports } from "../../../../api/report";
import {
  SearchReportsBySector,
  searchReportsByMarkets,
  searchReportsByClientes,
} from "../../../../api/filtersReports";
import { Error500connection } from "../../../../components/organisms/Error/500";

//en la function se agrego el "type"
export default function SearchReport({ userId, isCollapsed, type }) {
  const matchDesktop = useMediaQuery("(min-width: 1200px)");
  const matchIpadPro = useMediaQuery("(min-width: 992px)");
  const matchIpadMini = useMediaQuery("(min-width: 768px)");
  const matchPhone = useMediaQuery("(max-width: 767px)");

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSector, setIsSector] = useState(false);
  const [isMarket, setIsMarket] = useState(false);
  const [isCliente, setIsCliente] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportsBySector, setReportsBySector] = useState([]);
  const [reportsByMarket, setReportsByMarket] = useState([]);
  const [reportsByClientes, setReportsByClientes] = useState([]);
  const [search, setSearch] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [waypoint, setWaypoint] = useState({ img: Analisis, name: "Análisis" });
  const [error, setError] = useState(false);
  // Valiables, del waypoint
  const ItemsList = ["Sectores", "Tecnologías", "Clientes"];
  const filteredReports = reports.filter((report) =>
    report.title.toLowerCase().includes(search.toLowerCase())
  );
  // Peticiones
  const location = useLocation();
  const history = useHistory();


  // empieza para la paginacion ************************************************************
  const {
    dataArray,
    setNumResult,
    setDataArray,
    setIsThereData,

  } = useCardList({
    initaldataArray: [],
    initialnumResult: 0,
  });
  // Status
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  // Variables
  const resultados = 10;

  //Get current post
  const indexOfLastPost = currentPage * resultados;
  const indexOfFirstPost = indexOfLastPost - resultados;
  // Peticiones
  useEffect(() => {
    if (type != "company") {
    } else {
      getReports(userId)
        .then((res) => {
          setDataArray(res.data.reports);
          setNumResult(res.data.reports.slice(0, resultados).length);
          setIsLoading(false);
          setIsThereData(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(true);
          setIsThereData(false);
        });
    }
  }, []);

  function paginate(page) {
    setCurrentPage(page);
    dataArray.length < page * resultados
      ? setNumResult(dataArray.length)
      : setNumResult(page * resultados);
  }
  let filteredData = [];
  if (dataArray !== undefined) {
    if (type === "company") {
    } else {
      // ? Verificar esta parte
      filteredData = dataArray.filter((report) =>
        report.title.toLowerCase().includes(searchField.toLowerCase())
      );
    }
  }

  var currentData = filteredData.slice(indexOfFirstPost, indexOfLastPost);

  // termina la paginacion  ******************************************************************************
  useEffect(() => {
    if (location?.state?.location === "/cid/analysis") {
      history.push({
        pathname: "/cid/search-analysis",
        state: {
          location: location.pathname,
          value: "",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (location?.state?.location !== "/cid/search-analysis") {
      if (location?.state?.value?.length === 1) {
        setSearch(location.state.value);
      }
    } else {
      setSearch("");
    }
    if (window.location.href.includes("search-analysis")) {
      getReports(userId)
        .then((res) => {
          setReports(res.data.reports.slice(0, 7));
          setError(false);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
      document.getElementById("search-report-header").focus();
    }
  }, [location, userId]);

  useEffect(() => {
    error
      ? document
        .getElementsByClassName("report-by-filter-0")[0]
        .classList.add("disabled")
      : document
        .getElementsByClassName("report-by-filter-0")[0]
        .classList.remove("disabled");
    error
      ? document
        .getElementsByClassName("report-by-filter-1")[0]
        .classList.add("disabled")
      : document
        .getElementsByClassName("report-by-filter-1")[0]
        .classList.remove("disabled");
    error
      ? document
        .getElementsByClassName("report-by-filter-2")[0]
        .classList.add("disabled")
      : document
        .getElementsByClassName("report-by-filter-2")[0]
        .classList.remove("disabled");
  }, [error]);

  function handleKeyDown(event) {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 65 && event.keyCode <= 90) ||
      (event.keyCode === 8 && search !== "")
    ) {
      setIsLoading(true);
    }
  }

  // Funciones
  function handleSearch(evn) {
    if (evn.target.value !== "") {
      setSearch(evn.target.value);
      SearchReportsBySector(userId, evn.target.value)
        .then((res) => {
          //Hace la petición
          setIsLoading(false);
          setReportsBySector(res.data.reportes); //guarda los resultados, posibles obtenidos
          res.data.reportes === undefined
            ? document
              .getElementsByClassName("report-by-filter-0")[0]
              .classList.add("disabled")
            : document
              .getElementsByClassName("report-by-filter-0")[0]
              .classList.remove("disabled"); //Activa o desativa los botones conforme a la busqueda
        })
        .catch((err) => {
          setError(true);
          console.log(err);
        });
      searchReportsByMarkets(userId, evn.target.value)
        .then((res) => {
          setIsLoading(false);
          setReportsByMarket(res.data.reportes);
          res.data.reportes === undefined
            ? document
              .getElementsByClassName("report-by-filter-1")[0]
              .classList.add("disabled")
            : document
              .getElementsByClassName("report-by-filter-1")[0]
              .classList.remove("disabled");
        })
        .catch((err) => {
          setError(true);
          console.log(err);
        });
      searchReportsByClientes(userId, evn.target.value)
        .then((res) => {
          setIsLoading(false);
          setReportsByClientes(res.data.reportes);
          res.data.reportes === undefined
            ? document
              .getElementsByClassName("report-by-filter-2")[0]
              .classList.add("disabled")
            : document
              .getElementsByClassName("report-by-filter-2")[0]
              .classList.remove("disabled");
        })
        .catch((err) => {
          setError(true);
          console.log(err);
        });
    } else {
      setSearch("");
      setIsLoading(false);
      document
        .getElementsByClassName("report-by-filter-0")[0]
        .classList.remove("disabled");
      document
        .getElementsByClassName("report-by-filter-1")[0]
        .classList.remove("disabled");
      document
        .getElementsByClassName("report-by-filter-2")[0]
        .classList.remove("disabled");
    }
  }
  function handleOpen(reportId) {
    setOpen(true);
    setModalBody(
      <>
        <div className="d-flex justify-content-center mb-4">
          <img src={AlertIcon} alt="Warning" />
        </div>
        <p className="mb-4 a-text-medium-second-neutral-90 text-center">
          No se podrán deshacer los cambios.
          <br />
          ¿Seguro que deseas eliminar el análisis?
        </p>
        <div className="d-flex">
          <div onClick={() => setOpen(false)} className="w-100 mr-3">
            <Button
              type="button"
              classes="a-button-primary1"
              height={36}
              width="100%"
              text="Cancelar"
            />
          </div>
          <div
            onClick={() => {
              deleteReports(reportId);
            }}
            className="w-100"
          >
            <Button
              type="button"
              classes="a-button-light"
              height={36}
              width="100%"
              text="Eliminar"
            />
          </div>
        </div>
      </>
    );
  }

  function deleteReports(reportId) {
    const response = deleteReport(userId, reportId);
    response
      .then(() => {
        setModalBody(
          <>
            <div className="d-flex justify-content-center mb-4">
              <img src={SuccessIcon} alt="Success Icon" />
            </div>
            <p className="mb-4 a-text-medium-second-neutral-90 text-center">
              Se ha eliminado el análisis correctamente
            </p>
            <div className="d-flex justify-content-center">
              <div className="w-50" onClick={() => setOpen(false)}>
                <Button
                  type="button"
                  classes="a-button-primary1"
                  height={36}
                  width="100%"
                  text="Aceptar"
                />
              </div>
            </div>
          </>
        );
        return getReports();
      })
      .then((result) => {
        setReports(result.data.reportes);
      })
      .catch((err) => console.log(err));
  }
  function handleFilter(evn) {
    switch (evn.target.value) {
      case "0":
        setIsSector(!isSector);
        break;
      case "1":
        setIsMarket(!isMarket);
        break;
      case "2":
        setIsCliente(!isCliente);
        break;
      default:
        break;
    }
  }

  return (
    <Grid container alignItems="center" justify="center" spacing={0} >
      <Header
        isCollapsed={isCollapsed}
        text="Buscar análisis"
        onChange={error ? "" : (e) => handleSearch(e)}
        onKeyDown={handleKeyDown}
        id="search-report-header"
        focus={true}
        value={search}
      />
      <Grid item xs={12} className='background-lg-first-neutral-10 br-tl-2 br-tr-2'>
        <div className='background-lg-white mt-lg-4 mx-lg-4 pt-4 br-tl-2 br-tr-2'>
          <Grid container justify="center" spacing={0}>
            <Grid item xs={10} lg={12}>
              <div className="d-flex mr-lg-4">
                <span style={{ "whiteSpace": "nowrap" }} className="a-text-regular-first-neutral-100 mt-1 ml-lg-4 mr-3">Filtrar por:</span>
                <div className="d-flex scroll-overflow">
                  {ItemsList.map((res, i) => (
                    <div key={i} className="mr-2">
                      <input
                        type="checkbox"
                        className="d-none input-checkbox"
                        id={`report-by-filter-${i}`}
                        value={i}
                        onChange={(e) => handleFilter(e)}
                      />
                      <label
                        className={`btn-checkbox-search cursor-pointer ${matchPhone ? "text-xs" : "text-small"
                          } report-by-filter-${i}`}
                        style={{ "whiteSpace": "nowrap" }}
                        htmlFor={`report-by-filter-${i}`}
                      >
                        {res}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {!isLoading && !error && search === "" ?
                <h4 className={"my-4 a-text-bold-second-neutral-70 ml-lg-4 md-text-medium"}>Tendencias en WIKICID</h4> : <div className="mb-3"></div>
              }
            </Grid>
          </Grid>
        </div>
      </Grid>
      {
        !error ?
          <>
            <Grid item xs={12} className='background-lg-first-neutral-10 scroll-overflow h-100vh-search-company' onScroll={e => e.target.scrollTop === 0 ? setWaypoint({ img: Analisis, name: "Análisis" }) : ""}>
              <div className='background-lg-white mx-lg-4 mb-lg-4 br-br-2 br-bl-2'>
                <Grid container alignItems="center" justify="center" spacing={0}>
                  <Grid item xs={10} lg={12} className='pb-4 pb-lg-0 px-4'>
                    {search === '' ?
                      isLoading ?
                        <CardSkeleton num={10} />
                        : <FilteredReports
                          data={reports}
                          noSlice={true}
                        />
                      : isLoading ?
                        <div className=''>
                          <CardSkeleton num={10} />
                        </div>
                        :
                        <>
                          {isSector || isMarket || isCliente ? '' :
                            <>
                              <FilteredReports
                                data={filteredReports}
                                image={Analisis}
                                name="Análisis"
                                noName={true}
                                onClick={handleOpen}
                              />
                              <FilteredReports
                                data={reportsBySector}
                                image={Sector}
                                name="Sectores"
                                type="sector"
                                onClick={handleOpen}
                              />
                              <FilteredReports
                                data={reportsByMarket}
                                image={Market}
                                name="Tecnologías"
                                type="tecnología"
                                onClick={handleOpen}
                              />
                              <FilteredReports
                                data={reportsByClientes}
                                image={Cliente}
                                name="Clientes"
                                type="cliente"
                                onClick={handleOpen}
                              />
                            </>
                          }
                          {isSector ?
                            <FilteredReports
                              data={reportsBySector}
                              image={Sector}
                              name="Sectores"
                              type="sector"
                              onClick={handleOpen}
                            />
                            :
                            ""
                          }
                          {isMarket ?
                            <FilteredReports
                              data={reportsByMarket}
                              image={Market}
                              name="Tecnologías"
                              type="tecnología"
                              onClick={handleOpen}
                            />
                            :
                            ""
                          }
                          {isCliente ?
                            <FilteredReports
                              data={reportsByClientes}
                              image={Cliente}
                              name="Clientes"
                              type="cliente"
                              onClick={handleOpen}
                            />
                            :
                            ""
                          }
                        </>
                    }
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {/* {
              search === '' ?
                <Paginations
                  postPerPage={resultados}
                  totalCompanies={dataArray.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
                :
                ""
            } */}
            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
              <div className="modal">{modalBody}</div>
            </Modal>
          </>
          :
          <Grid item xs={12} className='background-lg-first-neutral-10 br-br-2 br-bl-2 pb-4'>
            <div className='background-lg-white mx-lg-4 pt-4 br-br-2 br-bl-2'>
              <Error500connection />
            </div>
          </Grid>
      }
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">{modalBody}</div>
      </Modal>
    </Grid>
  )
}