import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Header from '../../molecules/Header';
import { useHistory, useLocation } from 'react-router-dom';
import { Badge, Grid } from '@material-ui/core';
import { Button } from '../../atoms/Button';
import { Link } from 'react-router-dom';
import Card from '../../molecules/Card';
import Modal from '@material-ui/core/Modal';
// import Filter from "./Filter";
import Paginations from '../../molecules/Paginations/Paginations';

// Images, Icons
import OrderIcon from '../../../assets/img/ordenar.svg';
import FilterIcon from '../../../assets/img/filtrar.svg';
import AlertIcon from '../../../assets/img/alert.svg';
import Plantilla1 from '../../../assets/img/Plantilla1.png';
import Plantilla2 from '../../../assets/img/Plantilla2.png';
import BackIcon from '../../../assets/img/BackIcon.svg';
import SuccessIcon from '../../../assets/img/sucess-icon.svg';
import CloseIcon from '../../../assets/img/closeFilter.svg';
import FilterIconAzul from '../../../assets/img/filtrar.svg';
import closeIcon from '../../../assets/img/closeFilter.svg';
import EliminarFiltro from '../../../assets/img/eliminar_filtros.svg';
import WhiteFilterIcon from '../../../assets/img/whitefilter.svg';
import CardSkeleton from '../../molecules/Skeleton/CardSkeleton';
import { getIndustrias, getMercados, getSectors, getSubtechnology, getTechnology } from '../../../api/company';

import Filter from '../Filters/CIDFilters';

// api
import { PDF_PATH } from '../../../api/config';

import { getCompanies, deleteCompanyApi, getAllCompaniesForCSV, getPagination, getCompaniesV2 } from '../../../api/company';
import { getCompaniesByIndustry, getCompaniesBySubtech, getCompaniesByMercado, getCompaniesFilterApi } from '../../../api/filtersCompanies';

import createData from './helpersExcel';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

function handleClickReporte() {
  alert('Reporte generado');
}

function FilterOptions({ filter, setFilter, setShowFilterOptions }) {
  const [current, setCurrent] = useState(0);

  const [sectores, setSectores] = useState([]);
  const [industrias, setIndustrias] = useState([]);
  const [techs, setTechs] = useState([]);
  const [subtechs, setSubtechs] = useState([]);
  const [mercados, setMercados] = useState([]);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  function setStyle(value) {
    if (value === current) {
      return 'border-bottom-second-primary-50 a-text-medium-first-primary-60';
    } else {
      return 'a-text-regular-second-neutral-60';
    }
  }

  useEffect(() => {
    getSectors(1)
      .then((res) => {
        setSectores(res.data.sectores);
        setOptions(res.data.sectores);
      })
      .catch((err) => console.log(err));

    getIndustrias('')
      .then((res) => setIndustrias(res.data.industrias))
      .catch((err) => console.log(err));

    getTechnology(1)
      .then((res) => setTechs(res.data.technology))
      .catch((err) => console.log(err));

    getSubtechnology('')
      .then((res) => {
        res.data.subTechnology.forEach((element) => {
          element.name = element.Subtechnology;
        });
        setSubtechs(res.data.subTechnology);
      })
      .catch((err) => console.log(err));

    getMercados(1)
      .then((res) => setMercados(res.data.mercados))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let currentOption;
    if (current === 0) {
      currentOption = sectores;
    } else if (current === 1) {
      currentOption = industrias;
    } else if (current === 2) {
      currentOption = techs;
    } else if (current === 3) {
      currentOption = subtechs;
    } else if (current === 4) {
      currentOption = mercados;
    }
    setOptions(currentOption);
  }, [current]);

  function handleSelectFilter(elem, index) {
    let array = filter;
    if (current === 0) {
      if (array.sectors.includes(elem.sector_id)) {
        array.sectors = array.sectors.filter((item) => item !== elem.sector_id);
      } else {
        array.sectors.push(elem.sector_id);
      }
    } else if (current === 1) {
      if (array.industries.includes(elem.industry_id)) {
        array.industries = array.industries.filter((item) => item !== elem.industria_id);
      } else {
        array.industries.push(elem.industria_id);
      }
    } else if (current === 2) {
      if (array.technologies.includes(elem.market_id)) {
        array.technologies = array.technologies.filter((item) => item !== elem.market_id);
      } else {
        array.technologies.push(elem.market_id);
      }
    } else if (current === 3) {
      if (array.subtechnologies.includes(elem.tech2_id)) {
        array.subtechnologies = array.subtechnologies.filter((item) => item !== elem.tech2_id);
      } else {
        array.subtechnologies.push(elem.tech2_id);
      }
    } else if (current === 4) {
      if (array.markets.includes(elem.mercado_id)) {
        array.markets = array.markets.filter((item) => item !== elem.mercado_id);
      } else {
        array.markets.push(elem.mercado_id);
      }
    }
    setFilter(array);
    if (selected.includes(index)) {
      setSelected(selected.filter((item) => item !== index));
    } else {
      setSelected([...selected, index]);
    }
  }

  function checkSelected(elem) {
    if (current === 0) {
      return filter.sectors.includes(elem.sector_id);
    } else if (current === 1) {
      return filter.industries.includes(elem.industria_id);
    } else if (current === 2) {
      return filter.technologies.includes(elem.market_id);
    } else if (current === 3) {
      return filter.subtechnologies.includes(elem.tech2_id);
    } else if (current === 4) {
      return filter.markets.includes(elem.mercado_id);
    }
  }

  function deleteFilters() {
    let array = filter;
    array.sectors = [];
    array.industries = [];
    array.technologies = [];
    array.subtechnologies = [];
    array.markets = [];
    setFilter(array);
    setSelected([]);
  }

  return (
    <>
      <p className='h3-lg a-text-medium-second-neutral-70 align-self-end'>Catálogo de empresas</p>
      <div className='menu-cid d-flex justify-content-between align-items-center mt-3'>
        <div className='d-flex align-items-center mr-xl-4'>
          <p className='ml-2 text-small text-md-normal a-text-regular-first-primary-60'>Filtrar por:</p>
        </div>
        <div
          className='pr-2'
          onClick={() => setShowFilterOptions(false)}
        >
          <img
            src={CloseIcon}
            className='filter-label-icon'
            alt='Close'
            width='40px'
          />
        </div>
      </div>
      <div className='w-100'>
        <div className='d-flex justify-content-end align-items-center'>
          <div
            onClick={deleteFilters}
            className={`d-flex align-items-center mr-lg-3 py-2 px-2 br-1 mr-2  ${'background-white cursor-pointer border-first-primary-50'} `}
          >
            <img
              src={EliminarFiltro}
              alt='Order'
              className='img-order'
            />
            <p className={`ml-2 text-small text-md-normal ${'a-text-regular-first-primary-60'} `}>Eliminar filtros</p>
          </div>

          <div
            onClick={() => setShowFilterOptions(false)}
            className={`d-flex align-items-center mr-lg-3 ${'background-first-primary-60 cursor-pointer'} py-2 px-2 br-1 mr-2`}
          >
            <img
              src={WhiteFilterIcon}
              alt='Order'
              className='img-order'
            />
            <p className='ml-2 text-small text-md-normal a-text-regular-third-neutral'>Filtrar</p>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 30 }}>
        {filter.sectors.length > 0 && sectores.length ? (
          <div style={{ marginTop: 30 }}>
            Sectores:{' '}
            {filter.sectors.length > 0
              ? filter.sectors.map((item) => <span className='background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1'>{sectores.filter((elem) => elem.sector_id === item)[0].name}</span>)
              : ''}
          </div>
        ) : (
          ''
        )}
        {filter.industries.length > 0 && industrias.length ? (
          <div style={{ marginTop: 30 }}>
            Industrias:{' '}
            {filter.industries.length > 0
              ? filter.industries.map((item) => <span className='background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1'>{industrias.filter((elem) => elem.industry_id === item)[0].name}</span>)
              : ''}
          </div>
        ) : (
          ''
        )}
        {filter.technologies.length > 0 && techs.length ? (
          <div style={{ marginTop: 30 }}>
            Tecnologías Primarias:{' '}
            {filter.technologies.length > 0
              ? filter.technologies.map((item) => <span className='background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1'>{techs.filter((elem) => elem.market_id === item)[0].name}</span>)
              : ''}
          </div>
        ) : (
          ''
        )}
        {filter.subtechnologies.length > 0 && subtechs.length ? (
          <div style={{ marginTop: 30 }}>
            Tecnologías Secundarias:{' '}
            {filter.subtechnologies.length > 0
              ? filter.subtechnologies.map((item) => <span className='background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1'>{subtechs.filter((elem) => elem.tech2_id === item)[0].name}</span>)
              : ''}
          </div>
        ) : (
          ''
        )}
        {filter.markets.length > 0 && mercados.length ? (
          <div style={{ marginTop: 30 }}>
            Mercados:{' '}
            {filter.markets.length > 0
              ? filter.markets.map((item) => <span className='background-filter-labels py-2 px-3 br-3 mr-2 text-small a-text-regular-first-primary-60 mb-1'>{mercados.filter((elem) => elem.mercado_id === item)[0].name}</span>)
              : ''}
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='d-flex flex-nowrap scroll-overflow no-whitespace mt-5'>
        <p
          className={`mr-4 pb-3 cursor-pointer ${setStyle(0)}`}
          onClick={() => setCurrent(0)}
        >
          Sectores
        </p>
        <p
          className={`mr-4 pb-3 cursor-pointer ${setStyle(1)}`}
          onClick={() => setCurrent(1)}
        >
          Industrias
        </p>
        <p
          className={`mr-4 pb-3 cursor-pointer ${setStyle(2)}`}
          onClick={() => setCurrent(2)}
        >
          Tecnologías Primarías
        </p>
        <p
          className={`mr-4 pb-3 cursor-pointer ${setStyle(3)}`}
          onClick={() => setCurrent(3)}
        >
          Tecnologías Secundarias
        </p>
        <p
          className={`mr-4 pb-3 cursor-pointer ${setStyle(4)}`}
          onClick={() => setCurrent(4)}
        >
          Mercados
        </p>
      </div>
      <div className='hr-nav mb-3'></div>
      <div className='mt-2 mb-3 scroll-none'>
        {options.map((item, i) => (
          <div
            className='mb-3 d-flex'
            key={item.name}
            onMouseDown={() => handleSelectFilter(item, i)}
          >
            <input
              type='checkbox'
              onChange={() => {}}
              onClick={() => {}}
              checked={checkSelected(item)}
              className='radioNews cursor-pointer'
              id={item.name}
              name={item.name}
              value={item.name}
            />
            <label
              className='a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer'
              htmlFor={item.name}
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </>
  );
}

function SortOptions({ setSortOptions, setShowSortOptions, sortSelected }) {
  const [sort, setSort] = useState(1);

  function onChange(e, option) {
    console.log(sortSelected);
    if (option === 1) {
      setSortOptions(3);
    } else {
      setSortOptions(1);
    }
  }

  useEffect(() => {
    setSort(sortSelected);
  }, [sortSelected]);

  return (
    <>
      <p className='h3-lg a-text-medium-second-neutral-70 align-self-end'>Catálogo de empresas</p>
      <div className='menu-cid d-flex justify-content-between align-items-center mt-3'>
        <div
          onClick={() => setShowSortOptions(0)}
          className='d-flex align-items-center cursor-pointer mr-xl-4'
        >
          <img
            src={OrderIcon}
            alt='Order'
            className='img-order'
          />
          <p className='ml-2 text-small text-md-normal a-text-regular-first-primary-60'>Ordenar</p>
        </div>
        <div
          className='pr-4'
          onClick={() => setShowSortOptions(0)}
        >
          <img
            src={CloseIcon}
            className='filter-label-icon'
            alt='Close'
          />
        </div>
      </div>
      <div className='my-4'>
        <div className='mb-4 d-flex'>
          <input
            onChange={(e) => onChange(e, 1)}
            checked={sort !== 1}
            type='radio'
            id='order_1'
            className='radioNews cursor-pointer'
            name='order'
            value='1'
          />
          <label
            className='a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer'
            onClick={(e) => onChange(e, 1)}
            htmlFor='order_1'
          >
            Fecha de registro
          </label>
        </div>
        <div className='d-flex'>
          <input
            onChange={(e) => onChange(e, 3)}
            checked={sort !== 3}
            type='radio'
            id='order_1'
            className='radioNews cursor-pointer'
            name='order'
            value='1'
          />
          <label
            className='a-text-regular-first-primary-60 text-normal ml-3 cursor-pointer'
            onClick={(e) => onChange(e, 3)}
            htmlFor='order_2'
          >
            Orden alfabético
          </label>
        </div>
      </div>
    </>
  );
}

function List({ data, userId, Reload, handleDeleteButton, state, setModalBody, setIsOpen }) {
  function handleBack() {
    setModalBody('');
    setIsOpen(false);
  }

  function fullSelectView(id) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className='select-pdf-modal'>
          <div>
            <img
              src={BackIcon}
              alt='Regresar'
              className='nav-back'
              onClick={handleBack}
            />
            <p
              className='frame-back a-text-medium-second-neutral-90 nav-back'
              onClick={handleBack}
            >
              {' '}
              regresar{' '}
            </p>
          </div>
          <div>
            <p className='p-1-text a-text-medium-second-neutral-90'>Plantilla 1</p>
            <a
              href={`${PDF_PATH}/get-one-page-pdf/?companyId=${id}`}
              target='_blank'
            >
              <img
                className='p-1-frame-select'
                src={Plantilla1}
                alt='Plantilla 1'
              />
            </a>
          </div>
          <div>
            <p className='p-2-text a-text-medium-second-neutral-90'>Plantilla 2</p>
            <a
              href={`${PDF_PATH}/get-one-page/?companyId=${id}`}
              target='_blank'
            >
              <img
                className='p-2-frame-select'
                src={Plantilla2}
                alt='Plantilla 2'
              />
            </a>
          </div>
        </div>
      </>,
    );
  }

  function miniSelectView(id) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className='select-pdf-modal-m'>
          <div>
            <p className='mp-1-text a-text-medium-second-neutral-90'>Plantilla 1</p>
            <a
              href={`${PDF_PATH}/get-one-page-pdf/?companyId=${id}`}
              target='_blank'
            >
              <img
                className='mp-1-frame-select'
                src={Plantilla1}
                alt='Plantilla 1'
              />
            </a>
          </div>
          <div>
            <p className='mp-2-text a-text-medium-second-neutral-90'>Plantilla 2</p>
            <a
              href={`${PDF_PATH}/get-one-page/?companyId=${id}`}
              target='_blank'
            >
              <img
                className='mp-2-frame-select'
                src={Plantilla2}
                alt='Plantilla 2'
              />
            </a>
          </div>
        </div>
      </>,
    );
  }

  return (
    <div style={{ minWidth: 500 }}>
      <Grid
        xs={10}
        lg={12}
        className='mt-4'
      >
        {data.map((res, idx) => (
          <Card
            key={idx}
            noEdit={userId === 3}
            image={res.logo_link}
            title={res.name}
            ilustration={res.ilustration_link}
            link={{
              pathname: '/cid/company/' + res.company_id,
              state: {
                array: data.map((res) => res.company_id),
                index: idx,
                lastState: state,
                location: 1,
              },
            }}
            linkEdit={'/cid/update-company/' + res.company_id}
            linkPdf={`${PDF_PATH}/get-one-page/?companyId=${res.company_id}}`}
            linkPtt={`${PDF_PATH}/get-one-page-pdf/?companyId=${res.company_id}`}
            linkPortDocForm={`${PDF_PATH}/get-one-page-pdf/?companyId=${res.company_id}}`}
            id={res.company_id}
            userId={userId}
            onClick={handleDeleteButton}
            fullView={fullSelectView}
            miniView={miniSelectView}
          />
        ))}
      </Grid>
    </div>
  );
}

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function CardList(props) {
  const history = useHistory();
  const location = useLocation();
  const user = { id: 1, rolId: 1 };
  const [data, setData] = useState([]);
  const lastState = props.lastState || { pagination: 1, sort: 3, filter: { sectors: [], industries: [], technologies: [], subtechnologies: [], markets: [] } };
  const { type, titulo, userId, isCollapsed, linkSearch, linkAdd } = props;
  const [currentPage, setCurrentPage] = useState(lastState.pagination || 1);
  const [isOpen, setIsOpen] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [length, setLength] = useState(0);
  const [currentView, setCurrentView] = useState(0);
  const isMountingRef = useIsMount();
  const [modalDownload, setModalDownload] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [activateDownload, setActivateDownload] = useState(false);
  const skeleton = (
    <div style={{ paddingTop: 10 }}>
      <CardSkeleton num={10} />
    </div>
  );

  const [filter, setFilter] = useState(lastState.filter);
  const [sort, setSort] = useState(lastState.sort);

  const fetchCompanies = (pagination, sortOption, filterOptions) => {
    setFetchingData(true);

    getCompaniesV2(pagination, filterOptions, sortOption)
      .then((res) => {
        setData(res.data.companies);
        setLength(res.data.length);
        setFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingData(false);
      });
  };

  function setSortHandler(option) {
    setSort(option);
    setCurrentPage(1);
  }

  function setFilterHandler(option) {
    setFilter(option);
    setCurrentPage(1);
  }

  useEffect(() => {
    if (isMountingRef) return;
    if (currentView === 0) {
      setFetchingData(true);

      fetchCompanies(currentPage - 1, sort, filter);
    }
  }, [currentView]);

  useEffect(() => {
    if (isMountingRef) return;
    setFetchingData(true);

    fetchCompanies(currentPage - 1, sort, filter);
    lastState.pagination = currentPage;
  }, [currentPage]);

  useEffect(() => {
    setFetchingData(true);
    fetchCompanies(currentPage - 1, sort, filter);
    window.history.replaceState({}, document.title);
  }, []);

  function Reload() {
    fetchCompanies(currentPage - 1, sort, filter);
  }

  const ExportExcel = (data) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExcel, 'Reporte_Empresas_Wikicid' + fileExtension);
  };

  const handleClickReporte = () => {
    getAllCompaniesForCSV()
      .then((res) => {
        ExportExcel(res.data.company);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function deleteCompany(id) {
    deleteCompanyApi(userId, id)
      .then((res) => {
        console.log(res);
        Reload();
      })
      .catch((err) => {
        console.log(err);
      });
    Reload();
    setIsOpen(false);
  }

  function handleDeleteButton(id) {
    setIsOpen(true);
    setModalBody(
      <>
        <div className='d-flex justify-content-center mb-4'>
          <img
            src={AlertIcon}
            alt='Warning'
          />
        </div>
        <p className='mb-4 a-text-medium-second-neutral-90 text-center'>
          No se podrán deshacer los cambios.
          <br />
          ¿Seguro que deseas eliminar la empresa?
        </p>
        <div className='d-flex justify-content-center'>
          <div
            className='mr-3 w-100'
            onClick={() => setIsOpen(false)}
          >
            <Button
              type='button'
              classes='a-button-primary1 text-small'
              height={36}
              width='100%'
              text='Cancelar'
            />
          </div>
          <div
            onClick={() => deleteCompany(id)}
            className='w-100'
          >
            <Button
              type='button'
              classes='a-button-light text-small'
              height={36}
              width='100%'
              text='Aceptar'
            />
          </div>
        </div>
      </>,
    );
  }

  function handleChangeHeader(e) {
    history.push({
      pathname: '/cid/search-company-alternative',
      state: {
        value: e.target.value,
        location: location.pathname,
      },
    });
  }

  return (
    <div>
      <Header
        isCollapsed={isCollapsed}
        link={linkSearch}
        text={'Buscar empresa'}
        focus={true}
        onChange={(e) => handleChangeHeader(e)}
      />

      <div className='background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0'>
        <div className='background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all'>
          <Grid
            container
            alignItems='center'
            justify='center'
            spacing={0}
          >
            <Grid
              xs={10}
              lg={12}
            >
              {currentView == 2 ? (
                <SortOptions
                  sortSelected={sort}
                  setSortOptions={setSortHandler}
                  setShowSortOptions={() => setCurrentView(0)}
                />
              ) : (
                ''
              )}
              {currentView == 1 ? (
                <FilterOptions
                  filter={filter}
                  setFilter={setFilterHandler}
                  setShowFilterOptions={() => setCurrentView(0)}
                />
              ) : (
                ''
              )}
              {currentView == 0 ? (
                <>
                  <div className='d-flex justify-content-between'>
                    <p className='h3-lg a-text-medium-second-neutral-70 align-self-end'>Catálogo de empresas</p>
                    <div className='d-flex'>
                      <Button
                        type='button'
                        classes='a-button-secondary text-small roboto-medium mr-3 br-1 d-none d-lg-block'
                        width='150px'
                        height='33px'
                        text={'Obtener reporte'}
                        onclick={() => handleClickReporte()}
                      />
                      {user.rolId === 3 ? (
                        ''
                      ) : (
                        <Link
                          to={linkAdd}
                          className='d-none d-lg-block '
                        >
                          <Button
                            type='button'
                            classes='a-button-blue-1 text-small roboto-regular'
                            width='150px'
                            height='33px'
                            text={'Añadir empresas'}
                          />
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mt-4'>
                    <div className='d-flex'>
                      Mostrando {data.length} de {length} resultados
                    </div>
                    <div className='d-flex'>
                      <div
                        className='d-flex align-items-center'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setCurrentView(2)}
                      >
                        <img
                          src={OrderIcon}
                          style={{ width: 20, height: 20 }}
                          alt='Ordenar'
                          className='mr-2'
                        />
                        <p className='a-text-medium-second-neutral-70'>Ordenar</p>
                      </div>
                      <div
                        className='d-flex align-items-center ml-4'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setCurrentView(1)}
                      >
                        <Badge
                          badgeContent={filter.sectors.length + filter.industries.length + filter.technologies.length + filter.subtechnologies.length + filter.markets.length}
                          color='primary'
                        >
                          <img
                            src={FilterIcon}
                            style={{ width: 20, height: 20 }}
                            alt='Filtrar'
                            className='mr-2'
                          />
                        </Badge>
                        <p className='a-text-medium-second-neutral-70 pl-1'>Filtrar</p>
                      </div>
                    </div>
                  </div>
                  {data.length === 0 ? (
                    skeleton
                  ) : (
                    <List
                      setModalBody={setModalBody}
                      setIsOpen={setIsOpen}
                      state={{ pagination: currentPage, sort: sort, filter: filter }}
                      data={data}
                      userId={userId}
                      handleDeleteButton={handleDeleteButton}
                    />
                  )}
                  <Paginations
                    postPerPage={10}
                    totalCompanies={length}
                    paginate={setCurrentPage}
                    currentPage={currentPage}
                  />
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div className='modal'>{modalBody}</div>
      </Modal>
    </div>
  );
}
