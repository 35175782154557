import React from 'react';
import { Grid } from '@material-ui/core';
import { Error500connection } from '../../components/organisms/Error/500';

export default function Error500() {
	return (
		<>
			<Grid
				container
				spacing={0}
			>
				<Grid
					item
					xs={12}
					md={12}
					lg={12}
					xl={12}
				>
					<Error500connection />
				</Grid>
			</Grid>
		</>
	);
}
