import { convertFromRaw, EditorState } from "draft-js";
import { mdToDraftjs } from "draftjs-md-converter";

/**
 * @param {string} content - La representación en Markdown.
 * @returns {EditorState} - La representación del Markdown en EditorState.
 */
export default function markdownToEditorState(content) {
  const rawData = mdToDraftjs(content);
  const contentState = convertFromRaw(rawData);
  return EditorState.createWithContent(contentState);
}
