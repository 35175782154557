function FormatDateLeft(date) {
	if (date != undefined) {
		var dateArr = date.split('T')[0].split('-');
		return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
	} else {
		return '';
	}
}
function FormatDateRight(date) {
	if (date != undefined) {
		var dateArr = date.split('T')[0].split('-');
		return `${dateArr[0]}/${dateArr[1]}/${dateArr[2]}`;
	} else {
		return '';
	}
}

export { FormatDateLeft, FormatDateRight };
