// React
import { useEffect, useState } from "react";

// Material
import { Grid, CircularProgress } from '@material-ui/core';

// Images
import Objetivo from "../../../../assets/img/FormMinuta/objetivo.svg";
import Moderador from "../../../../assets/img/company/fundador.svg";
import Lugar from "../../../../assets/img/company/sede.svg";
import Fecha from "../../../../assets/img/FormMinuta/fecha.svg";
import Hora from "../../../../assets/img/FormMinuta/hora.svg";
import Asistentes from "../../../../assets/img/FormMinuta/asistentes.svg";
import Tema from "../../../../assets/img/company/tema.svg";
import Acuerdo from "../../../../assets/img/icon/acuerdo.svg"
import ProximaReunion from "../../../../assets/img/icon/proxima-reunion.svg"


// Componens
import InputWithCalendar from "../../../molecules/InputWith/InputWithCalendar";
import InputWithTime from "../../../molecules/InputWith/InputWithTime";
import {InputWithLabel} from "../../../molecules/InputWith/InputWithLabel";
import { Button } from "../../../atoms/Button"
import { AddButton } from "../../../molecules/AddButton"

// Api
import { getMinutaById, updateMinuta, createMinuta  } from "../../../../api/company";

// Utils
import { FormatDateLeft } from "../../../../utils/FormatDate";
import { CreateArray } from '../../../../utils/CreateAray';

//Hooks
import useFormMinuta from '../../../../hooks/useFormMinuta'


export default function FormMinuta({ onClick, userId, companyId, close, updateId }) {
    // State
    const {isLoading,assistant,agreement,date,nextDate,warningObject,warningTheme,warningSite,
        warningModerator,warningAgreement,data,
    setIsLoading,setAssistant,setDate,setNextDate,setWarningObject,setWarningTheme,setWarningSite,setWarningModerator,setWarningAgreement,setData,setAgreement}= useFormMinuta({
        initialLoading:false,initalAssitant:[''],
        initialAgreement:[''], initialNextTime:',', initialTime:'',
        initialDate:'',initialNextDate:'',
        initialWarningObject:'',initialWarningTheme:'',
        initialWarningSite:'',initialWarningModerator:'',initialWarningAgreement:'',initialData:''})

    const [time, setTime] = useState('');

    const [nextTime, setNextTime] = useState('');



    useEffect(() => {
        if (updateId != 0)
            getMinutaById(userId, updateId).then(result => {
                if (result.status == 200) {
                    setData(result.data.minuta)
                    setTime(result.data.minuta.time)
                    setDate(FormatDateLeft(result.data.minuta.date))
                    setNextDate(FormatDateLeft(result.data.minuta.date_meeting))
                    setNextTime(result.data.minuta.time_meeting)
                    if (result.data.minuta.assitants !== null) {
                        setAssistant(CreateArray(result.data.minuta.assitants))
                    }
                    if (result.data.minuta.agreements !== null) {
                        setAgreement(CreateArray(result.data.minuta.agreements))
                    }
                }
            }).catch(err => console.log(err))
    }, [])

    // Sumit
    function submitForm(e) {
        e.preventDefault();

        let object = document.getElementById('minuta-object')
        let theme = document.getElementById('minuta-theme')
        let site = document.getElementById('minuta-site')
        let moderator = document.getElementById('minuta-moderator')

        if (!object.value) {
            setWarningObject('Ingresa un objetivo')
            object.classList.add('input-error')
        } else {
            setWarningObject('')
            object.classList.remove('input-error')
        }
        if (!theme.value) {
            setWarningTheme('Ingresa un tema')
            theme.classList.add('input-error')
        } else {
            setWarningTheme('')
            theme.classList.remove('input-error')
        }
        if (!site.value) {
            setWarningSite('Ingresa un lugar')
            site.classList.add('input-error')
        } else {
            setWarningSite('')
            site.classList.remove('input-error')
        }
        if (!moderator.value) {
            setWarningModerator('Ingresa un moderator')
            moderator.classList.add('input-error')
        } else {
            setWarningModerator('')
            moderator.classList.remove('input-error')
        }

        let agreement = []
        document.querySelectorAll('.minuta-agreement').forEach(res => {
            if (res.value) {
                agreement.push(res.value)
            }
        })

        if (agreement == 0) {
            document.querySelectorAll('.minuta-agreement').forEach(res => {
                res.classList.add('input-error')
            })
            setWarningAgreement('Ingresa un acuerdo')
        } else {
            document.querySelectorAll('.minuta-agreement').forEach(res => {
                res.classList.remove('input-error')
            })
            setWarningAgreement('')
        }

        var assistant = []
        document.querySelectorAll('.minuta-assistant').forEach(res => {
            if (res.value) {
                assistant.push(res.value)
            }
        });
        var date = document.getElementById('minuta-date').value.split('/')
        var nextDate;
        if (document.getElementById('minuta-next-date').value == '') {
            nextDate = '30/11/1899'.split('/')
        } else {
            nextDate = document.getElementById('minuta-next-date').value.split('/')
        }
        var formData = {
            'user_id': userId,
            'company_id': companyId,
            'objectives': object.value,
            'presenter': moderator.value,
            'place': site.value,
            'date': `${date[2]}-${date[1]}-${date[0]}`,
            'time': document.getElementById('minuta-time').value,
            'assitants': assistant,
            'meeting_theme': theme.value,
            'agreements': agreement,
            'place_meeting': document.getElementById('minuta-next-site').value,
            'date_meeting': `${nextDate[2]}-${nextDate[1]}-${nextDate[0]}`,
            'time_meeting': document.getElementById('minuta-next-time').value,
        }
        var formDataUpdate = {
            'user_id': userId,
            'minutas_id': updateId,
            'objectives': object.value,
            'presenter': moderator.value,
            'place': site.value,
            'date': `${date[2]}-${date[1]}-${date[0]}`,
            'time': document.getElementById('minuta-time').value,
            'assitants': assistant,
            'meeting_theme': theme.value,
            'agreements': agreement,
            'place_meeting': document.getElementById('minuta-next-site').value,
            'date_meeting': `${nextDate[2]}-${nextDate[1]}-${nextDate[0]}`,
            'time_meeting': document.getElementById('minuta-next-time').value,
        }
        if (object.value && theme.value && site.value && moderator.value && agreement.length != 0) {
            setIsLoading(true)
            if (updateId != 0) {
                updateMinuta(formDataUpdate).then(result => {
                    if (result.status == 200) {
                        setIsLoading(false)
                        onClick()
                    }
                }).catch(err => console.log(err))
            } else {
                createMinuta(formData).then(result => {
                    if (result.status == 200) {
                        setIsLoading(false)
                        onClick()
                    }
                }).catch(err => {
                    console.log(err)
                    setIsLoading(false)
                })
            }
        }
    }

    return (
        <div className='ml-lg-3'>
            <form onSubmit={submitForm}>
                <p className="a-text-medium-second-neutral-90 mb-3 text-md-medium text-lg-normal">Minuta</p>
                <p class="mb-4 a-text-regular-first-neutral-100 text-small">* Campo obligatorio</p>
                <Grid container spacing={0}>
                    <Grid item xs={12} lg={6} className='pr-lg-3'>
                        {/* Objetivo */}
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Objetivo} alt='Objetivo' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <InputWithLabel width={100} text='Objetivo *' id='minuta-object' placeholder='Ingresa un objetivo' focus={true} type='autoresize' classLabel='a-text-regular-second-neutral-90' value={data != '' ? data.objectives : null} onChange={e => data != '' ? setData(e.target.value) : null} warning={warningObject} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} className='pl-lg-3'>
                        {/* Moderador */}
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Moderador} alt='Moderador' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <InputWithLabel width={100} text='Moderador *' id='minuta-moderator' placeholder='Ingresa el nombre del moderador' type='text' classLabel='a-text-regular-second-neutral-90' value={data != '' ? data.presenter : null} onChange={e => data != '' ? setData(e.target.value) : null} warning={warningModerator} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6} className='pr-lg-3'>
                        {/* Lugar */}
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Lugar} alt='Lugar' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <InputWithLabel width={100} text='Lugar *' id='minuta-site' placeholder='Ingresa el lugar de reunión' type='text' classLabel='a-text-regular-second-neutral-90' value={data != '' ? data.place : null} onChange={e => data != '' ? setData(e.target.value) : null} warning={warningSite} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7} lg={6} className='pl-lg-3'>
                        {/* Fecha */}
                        <div className='d-flex align-items-start'>
                            <img src={Fecha} alt='Fecha' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <InputWithCalendar width={100} text='Fecha' id='minuta-date' placeholder='Selecciona la fecha' classLabel='a-text-regular-second-neutral-90' onClick={(d, m, y) => setDate(`${d}/${m}/${y}`)} value={date == '' ? null : date} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7} lg={6} className='pr-lg-3'>
                        {/* Hora */ }
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Hora} alt='Hora' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <InputWithTime width={100} text='Hora' id='minuta-time' placeholder='Selecciona la hora' classLabel='a-text-regular-second-neutral-90'  onEvent={(h, m, z) => setTime(`${h}:${m} ${z}`)} value={time == '' ? null : time}  />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {/* Asistentes */}
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Asistentes} alt='Asistentes' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <p className='a-text-regular-second-neutral-90'>Asistentes</p>
                                <AddButton width={100} placeholder='Nombre del asistente' type='text' className='minuta-assistant mt-2' add={arr => setAssistant(arr)} remove={arr => setAssistant(arr)} array={assistant} onChange={arr => setAssistant(arr)} text='Agregar asistente' opcion='grid' />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {/* Tema */}
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Tema} alt='Tema' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <InputWithLabel width={100} text='Tema *' id='minuta-theme' placeholder='Ingresa el tema' type='autoresize' classLabel='a-text-regular-second-neutral-90' value={data != '' ? data.meeting_theme : null} onChange={e => data != '' ? setData(e.target.value) : null} warning={warningTheme} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {/* Acuerdo */}
                        <div className='d-flex align-items-start mb-3'>
                            <img src={Acuerdo} alt='Acuerdo' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <p className='a-text-regular-second-neutral-90'>Acuerdos *</p>
                                <AddButton width={100} placeholder='Ingresa el acuerdo' type='text' className='minuta-agreement mt-2' add={arr => setAgreement(arr)} remove={arr => setAgreement(arr)} array={agreement} onChange={arr => setAgreement(arr)} text='Agregar acuerdo' warning={warningAgreement} opcion='fiend' />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {/* Próxima reunión */}
                        <div className='d-flex align-items-start mb-5'>
                            <img src={ProximaReunion} alt='Fecha' className='mr-2 minuta_image' />
                            <div className='w-100'>
                                <p className='a-text-regular-second-neutral-90 mb-2'>Próxima reunión</p>
                                <InputWithLabel text='Lugar' id='minuta-next-site' placeholder='Ingresa el lugar' type='text' classLabel='a-text-regular-second-neutral-90' value={data != '' ? data.place_meeting : null} onChange={e => data != '' ? setData(e.target.value) : null} />
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={7} lg={6} className='pt-3 pt-lg-2 minuta_pr-col-6'>
                                        <div className='d-flex'>
                                            <img src={ProximaReunion} alt='Evento' className='mr-2 minuta_image' />
                                            <InputWithCalendar width={100} id='minuta-next-date' placeholder='Selecciona la fecha' onClick={(d, m, y) => setNextDate(`${d}/${m}/${y}`)} value={nextDate == '' || nextDate == '30/11/1899' ? null : nextDate} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={7} lg={6} className='pt-2 minuta_pl-col-6'>
                                        <div className='d-flex'>
                                            <img src={Hora} alt='Reloj' className='mr-2 minuta_image' />
                                            <InputWithTime width={100} id='minuta-next-time' placeholder='Selecciona la hora' value={nextTime == '' ? null : nextTime} onEvent={(h, m, z) => setNextTime(`${h}:${m} ${z}`)} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className='d-flex'>
                    <div className='d-inline-block ml-4 mr-3 mr-md-4' onClick={close}>
                        <Button id='btn-minuta-cancel' classes="a-button-gray-2 text-small minuta_btn" text="Cancelar" type="button" />
                    </div>
                    <Button id='btn-minuta-agree' classes="a-button-blue-1 text-small minuta_btn" text={isLoading ? <CircularProgress /> : updateId != 0 ? 'Actualizar' : 'Guardar'} type="sumit" />
                </div>
            </form>
        </div>
    )
}