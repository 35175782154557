import { useReducer } from 'react';
const ACTIONS = {
	WARNING_TITLE: 'warning_title',
	WARNING_DESC: 'warning_desc',
	WARNING_FILE: 'warning_file',
	WARNING_CLIENT: 'warning_client',
	WARNING_SECTOR: 'warning_sector',
	WARNING_MARKET: 'warning_market',
	BUTTON_IMAGEN: 'button_imagen',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.WARNING_TITLE:
			return { ...state, warningTitle: action.payload };
		case ACTIONS.WARNING_DESC:
			return { ...state, warningDescription: action.payload };
		case ACTIONS.WARNING_FILE:
			return { ...state, warningFile: action.payload };
		case ACTIONS.WARNING_CLIENT:
			return { ...state, warningClient: action.payload };
		case ACTIONS.WARNING_SECTOR:
			return { ...state, warningSector: action.payload };
		case ACTIONS.WARNING_MARKET:
			return { ...state, warningMarket: action.payload };
		case ACTIONS.BUTTON_IMAGEN:
			return { ...state, warningImagen: action.payload };
		default:
			return state;
	}
};

export default function useFormReport({
	initialwarningTitle,
	initialwarningDescription,
	initialwarningFile,
	initialwarningClient,
	initialwarningSector,
	initialwarningMarket,
	initialwarningImagen,
}) {
	const [state, dispach] = useReducer(reducer, {
		warningTitle: initialwarningTitle,
		warningDescription: initialwarningDescription,
		warningFile: initialwarningFile,
		warningClient: initialwarningClient,
		warningSector: initialwarningSector,
		warningMarket: initialwarningMarket,
		warningImagen: initialwarningImagen,
	});
	const {
		warningTitle,
		warningDescription,
		warningFile,
		warningClient,
		warningSector,
		warningMarket,
		warningImagen,
	} = state;

	return {
		warningTitle,
		warningDescription,
		warningFile,
		warningClient,
		warningSector,
		warningMarket,
		warningImagen,
		setWarningTitle: (warningTitle) => dispach({ type: ACTIONS.WARNING_TITLE, payload: warningTitle }),
		setWarningDescription: (warningDescription) =>
			dispach({ type: ACTIONS.WARNING_DESC, payload: warningDescription }),
		setWarningFile: (warningFile) => dispach({ type: ACTIONS.WARNING_FILE, payload: warningFile }),
		setWarningClient: (warningClient) => dispach({ type: ACTIONS.WARNING_CLIENT, payload: warningClient }),
		setWarningSector: (warningSector) => dispach({ type: ACTIONS.WARNING_SECTOR, payload: warningSector }),
		setWarningMarket: (warningMarket) => dispach({ type: ACTIONS.WARNING_MARKET, payload: warningMarket }),
		setWarningImagen: (warningImagen) => dispach({ type: ACTIONS.BUTTON_IMAGEN, payload: warningImagen }),
	};
}
