// Mate   rial
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Grid,
  Modal,
  useMediaQuery,
  IconButton,
  createMuiTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Header from "../../../components/molecules/Header";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CardQuestionHeader from "../../../components/molecules/Card/CardQuestion/CardHeaderList";
import CardResponse from "../../../components/molecules/Card/CardQuestion/CardUniqueQuestion/CardResponseQuestion";
import ModalSuccess from "../../../components/molecules/Modal/SuccessModal";

import { getBlogsByAuthors } from "../../../api/blogs";

import SkeletonResponseQuestion from "../../../components/molecules/Skeleton/SkeletonQuestions/SkeletonResponseQuestion";
import CardBlog from "../../../components/molecules/Card/CardBlog/index";
import CardAuthorDetail from "../../../components/molecules/Card/CardBlog/BlogAuhtorDetail";

import Close from "../../../assets/img/closeFilter.svg";

import Clock from "../../../assets/img/blogs/reloj.svg";

import { useAuth } from "../../../hooks/useAuth";
import SkeletonBlog from "../../../components/molecules/Skeleton/SkeletonBlog/index";
import { Skeleton } from "@material-ui/lab";

const authors = [
  {
    name: "Diego Romero Mora",
    publication: 1,
    photo_link:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR40IkZzDjBhjlZr4PeUNo8YnIFEn76T9QE9aSGv56TXKng6q_TOi9aBmbGksjuikLZHPuubb6uvbA&usqp=CAU",
  },
];

export default function Publications(e) {
  let history = useHistory();
  const location = useLocation();
  const [dataHeader, setDataHeader] = useState([]);
  const user = useAuth();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [author, setAuthor] = useState({});

  const mini = useMediaQuery("(min-width:700px)");

  const ipadProUse = useMediaQuery("(min-width:992px)");
  const phoneUse = useMediaQuery("(min-width:400px)");
  const { userId } = user;
  useEffect(() => {
    console.log(location.state);
    setData(location.state);
    getAllBlogs(location.state.user_id);
    setAuthor(location.state);
    // result: 'some_value'
  }, []);


  async function getAllBlogs(userId) {
    try {
      setLoad(true);

      const response = await getBlogsByAuthors(userId);
      setBlogs(response.data.blogs);
      console.log("Esta es la respuesta", response);

      setLoad(false);
    } catch (error) {
      console.log(error);
    }
  }
  console.log(author, "in response");

  /*
  Esta es la lista de publicaciones por autor no hay mucho más que decir xd
  
  */
  return (
    <Grid container justify="center" spacing={0} className="">
      {ipadProUse ? (
        <Header isCollapsed={e.isCollapsed} link="" text="Buscar empresa" />
      ) : (
        <div />
      )}

      <div
        className={`${
          ipadProUse
            ? "d-none"
            : "w-100 d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-1 mx-md-5 mx-4 py-3 mt-2 "
        }`}
      >
        {" "}
        <p className="h3-md text-big a-text-medium-first-primary-60">
          Publicaciones
        </p>{" "}
        <Link to={"/cid/carso/blogs/list"}>
          {" "}
          <IconButton>
            <img
              src={Close}
              alt="PDF Icon"
              className="cursor-pointer "
              width="24px"
            />
          </IconButton>
        </Link>
      </div>

      <Grid
        item
        xs={11}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow px-2   br-2"
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ">
          {ipadProUse ? (
            <div className="d-flex justify-content-between align-items-center mb-4 ">
              {" "}
              <p className="h3-md text-big a-text-medium-first-primary-60">
                Publicaciones
              </p>{" "}
              <Link to={"/cid/carso/blogs/list"}>
                {" "}
                <IconButton>
                  <img
                    src={Close}
                    alt="PDF Icon"
                    className="cursor-pointer "
                    width="24px"
                  />
                </IconButton>
              </Link>
            </div>
          ) : (
            <div />
          )}
          <div className="mt-4">
            {load ? (
              <div className="card-author h-120  mt-2 d-flex align-items-center box-shadow-all  mr-3 mt-2 w-100 ">
                <Skeleton
                  variant="h3"
                  width="30%"
                  height="100%"
                  className="mr-1 br-tl-2 br-bl-2"
                  animation="wave"
                />
                <div className="d-flex flex-column  w-90  ml-3">
                  <Skeleton
                    variant="h3"
                    width="50%"
                    height="30px"
                    className="mb-3 "
                    animation="wave"
                  />
                  <Skeleton
                    variant="h3"
                    width="50%"
                    height="30px"
                    animation="wave"
                  />
                </div>
              </div>
            ) : (
              <CardAuthorDetail info={data} />
            )}
          </div>
          <div className="mt-4">
            <div className="mt-4">
              {load ? (
                <SkeletonBlog num={3} />
              ) : (
                <>
                  {" "}
                  {blogs.map((res, idx) => (
                    <div key={idx} className="mt-3">
                      <CardBlog
                        key={idx}
                        info={res}
                        userId={userId}
                        type="normal"
                      />{" "}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
