export function SelectScroll({ data, className, id, IdKey, nameKey, selectValue, warning, placeholder, dateChanged, value, selectClass, disabled }) {
  const name = nameKey ? nameKey : (elem) => elem;

  return (
    <>
      <select
        className={`input-option scroll-overflow-y pl-2 mb-md-4 `}
        style={{
          width: `calc(${50}% - 10px)`,
          height: "35px",
          fontFamily: "Roboto-Medium",
          fontSize: "15px",
          border: "1px solid #a8aba8",
          borderRadius: "5px",
        }}
        placeholder={placeholder}
        onChange={dateChanged}
        value={value}
        // id={id}
        disabled={disabled}
      >
        <option
          className="a-text-medium-second-neutral-60 pt-1 pb-1 text-xs a-text-medium-first-neutral-90" value=""
          style={{
            fontFamily: "Roboto-Medium",
            fontSize: "15px",
            color: "#8b8f8b",
          }}
          disabled
        >
          {placeholder}
        </option>
        {data.map((item, index) => (
          <option
            // id = {} 
            value={IdKey ? IdKey(item) : name(item)}
            className="a-text-medium-second-neutral-60 pt-1 pb-1 text-xs a-text-medium-first-neutral-90"
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: "19px",
              padding: "10px",
            }}
          >
            {name(item)}
          </option>
        ))}
      </select>
      {warning ? (
        <p className="mt-2 a-text-regular-alert-60">{warning}</p>
      ) : (
        ""
      )}
    </>
  );
}
