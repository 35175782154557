//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery } from "@material-ui/core";
export default function SkeletonTopicQuestion({ num }) {
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const ipadProMatch = useMediaQuery("(min-width: 900px)");
  const desktop = useMediaQuery("(min-width: 1100px)");

  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);
  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  return (
    <>
      <div className="w-100 d-flex ">
        <Skeleton variant="h3" width="50%" animation="wave" className="mb-3 " />
      </div>
      <div
        className={` ${
          ipadProMatch && desktop
            ? "checkbox-img-container"
            : "checkbox-img-container"
        } `}
      >
        {arraySkeleton.map((r, indx) => (
          <div key={indx}>
            <div className={`btn-checkbox-icon  btn-checkbox-icon-add`}>
              <Skeleton
                variant="rect"
                width="40%"
                height="40%"
                animation="wave"
              />
              <Skeleton
                variant="h3"
                width="50%"
                animation="wave"
                className="mt-2"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
