import { BASE_PATH } from "./config";
import axios from "axios";

// Interfaz para el servicio de noticias
class NewsService {
  getNews(categoria) {}
}

// Adaptador para el servicio actual de noticias (gnews.io)
class GNewsAdapter extends NewsService {
  constructor() {
    super();
  }

  async getNews(categoria) {
    const options = {
      method: "GET",
      url: `https://gnews.io/api/v4/top-headlines?category=${categoria}&lang=es&max=10&apikey=18cb40fa8e89ae09cba6d071cd45a417`,
    };
    const req = await axios.request(options);

    if (req.status !== 200) {
      return {
        data: {
          articles: [],
        },
      };
    }

    return req;
  }
}

// Exportar la interfaz y el adaptador
export { NewsService, GNewsAdapter };

export function savedNews(data) {
  return axios.post(`${BASE_PATH}/profile/create-new`, data);
}

export function getNewsByFilters(data) {
  const options = {
    method: "GET",
    url: `https://gnews.io/api/v4/search?q=${data}$&lang=es&country=es&max=10&apikey=18cb40fa8e89ae09cba6d071cd45a417`,
  };
  return axios.request(options);
}

export function getOrderByRecent() {
  var options = {
    method: "GET",
    url: "https://bing-news-search1.p.rapidapi.com/news/search",
    params: {
      q: "tecnologia, IT",
      safeSearch: "Off",
      textFormat: "Raw",
      freshness: "Day",
      count: "30",
    },
    headers: {
      "x-bingapis-sdk": "true",
      // 'x-rapidapi-key': 'bafa9f1814msh58b552e209dee6cp19555ajsn5c8db01ec1dd',
      "x-rapidapi-key": "5ffb0a1d36msh34fd2174a368c30p1d8182jsn0da7924bc388",
      "x-rapidapi-host": "bing-news-search1.p.rapidapi.com",
    },
  };
  return axios.request(options);
}

export function searchNews(data) {
  var options = {
    method: "GET",
    url: "https://bing-news-search1.p.rapidapi.com/news/search",
    params: {
      q: data,
      safeSearch: "Off",
      textFormat: "Raw",
      freshness: "Day",
      mkt: "es-MX",
    },
    headers: {
      "x-bingapis-sdk": "true",
      // 'x-rapidapi-key': 'bafa9f1814msh58b552e209dee6cp19555ajsn5c8db01ec1dd',
      "x-rapidapi-key": "5ffb0a1d36msh34fd2174a368c30p1d8182jsn0da7924bc388",
      "x-rapidapi-host": "bing-news-search1.p.rapidapi.com",
    },
  };
  return axios.request(options);
}

export function getSavedNew(userId) {
  return axios.get(`${BASE_PATH}/profile/get-new?userId=${userId}`);
}

export function deletedNew(userId, newId) {
  return axios.delete(
    `${BASE_PATH}/profile/delete-new?userId=${userId}&newId=${newId}`,
  );
}
