import React, {
  useState,
} from "react";
import {
  Modal,
  useMediaQuery,
  IconButton,
  createMuiTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import QuestionInputWithRows from "../../../components/molecules/QuestionInput/QuestionInputWithRows/index";

// React
import PreviewImageModal from "../../molecules/Modal/PreviewImageModal";
import CardResponseModal from "../../molecules/Card/CardQuestion/CardUniqueQuestion/CardResponseModal";
import { creteResponse } from "../../../api/questions";
import { Input } from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import Close from "../../../assets/img/closeFilter.svg";
import enviar from "../../../assets/img/questions/enviar.svg";
import { useAuth } from "../../../hooks/useAuth";
import Basurita from "../../../assets/img/questions/basurita.svg";

// Componens

let FilesSelectedImage = [];

export default function ResposeModal({ setOpen, question, controlImage, setResponseForMe, responseForMe, type, setAgregadoRespuesta
}) {

  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  const user = useAuth();

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  const { userId } = user;

  const [openPreviewModal, setopenPreviewModal] = useState({
    open: false,
    url: "",
  });
  const [imagen, setImagen] = useState("");
  const [deleteD, setDeteled] = useState(false);
  const [textState, setTextState] = useState("");


  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const ipadProUse = useMediaQuery("(min-width:992px)");
  let numImages = 0;

  const renderPhotos = (source) => {
    numImages = source.length;
    return source.map((photo, index) => {
      return (
        <div
          key={photo}
          className={`${numImages === 1 ? `${ipadMiniMatch ? "w-100 " : "w-100"}` : "w-48"
            }  w-lg-50 mb-2  d-flex flex-row-ms-reverse `}
        >
          <div
            className={`position-absolute ${index >= 4 ? "d-none" : "position-absolute "
              } `}
          >
            <Tooltip title="borrar">
              <IconButton aria-label="borrar">
                <img
                  src={Basurita}
                  alt=""
                  onClick={() => {
                    selectedFiles.splice(index, 1);
                    FilesSelectedImage.splice(index, 1);
                    setDeteled(!deleteD);
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>

          <img
            onClick={() => setopenPreviewModal({ open: true, url: photo })}
            src={photo}
            alt="phohoto"
            className={` br-2 ${numImages === 3 && index === 0
              ? "item1 "
              : `itemN  ${index >= 4 ? "d-none" : ""} `
              }  `}
          />
        </div>
      );
    });
  };

  function handleWrite(evn) {
    if (evn.target.value !== "") {
      setTextState(evn.target.value);
    } else {
      setTextState("");
    }
  }

  const handleImageChange = (e) => {
    FilesSelectedImage = []
    const selectedFiles = Array.from(e.target.files);
    let filesArray = selectedFiles.map((file) => URL.createObjectURL(file));
    if (FilesSelectedImage.length + filesArray.length > 4) {
      controlImage(true);
      filesArray = filesArray.slice(0, 4 - FilesSelectedImage.length);
    }
    setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    selectedFiles.forEach((file) => URL.revokeObjectURL(file));
    FilesSelectedImage.push(selectedFiles[0])
  };

  async function submitCreateForm(e) {
    e.preventDefault();

    var formData = new FormData();
    formData.append("userId", userId);
    formData.append("question", textState);
    formData.append("type", type);
    formData.append("user_creatorid", question.user_id);

    if (type === 'question') {
      formData.append("question_id", question.question_id);
    } else {
      formData.append("question_id", question.comment_id);
    }

    const addImages = (array, name) => {
      array.slice(0, 4).forEach((item) => {
        formData.append(name, item);
      });
    };

    addImages(FilesSelectedImage, "photo");
    setLoading(true);
    try {
      await creteResponse(formData)
      setAgregadoRespuesta(true)
      setResponseForMe(!responseForMe)
      setOpen(false)
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
      setAgregadoRespuesta(false)
      FilesSelectedImage = []
    }
  }

  numImages = selectedFiles.length;

  return (
    <form onSubmitCapture={submitCreateForm}>
      {ipadProUse ? (
        <div className={` px-5 `}>
          <div className="w-100 d-flex justify-content-between align-items-center      ">

            <div>
              <p className="h3-md a-text-medium-first-primary-60">
                Agrega una respuestasss
              </p>
            </div>

            <IconButton>
              <img
                src={Close}
                onClick={() => setOpen(false)}
                alt="PDF Icon"
                className="cursor-pointer "
                width="24px"
              />
            </IconButton>
          </div>

          <CardResponseModal info={question} type={type} />



          <div className={`separator ${selectedFiles.length >= 1 ? `${selectedFiles.length >= 3 ? 'top-separator-3 ' : 'top-separator-1'} ` : ' '}`}></div>

          <QuestionInputWithRows
            id={"reponse-text-ipad"}
            placeholder={"Responde a la pregunta"}
            focus={true}
            onChange={(e) => handleWrite(e)}
            value={textState}
          />

          <div className=" mt-2 d-flex pl-5  align-items-center  ">
            <div className="mr-4">
              <p className="h3-md a-text-regular-first-primary-60">Añadir: </p>
            </div>
            <div className="d-flex align-items-center mr-4  ">
              <Tooltip title="Imagen">
                <IconButton aria-label="imagen">
                  <Input
                    textSize="text-small"
                    id="image-question-ipad"
                    type="fileText"
                    placeholder="undifined"
                    onChange={handleImageChange}
                    value={imagen}
                    count={FilesSelectedImage.length}
                  />
                </IconButton>
              </Tooltip>
            </div>
            <div className="d-flex flex-row-ms-reverse w-100 ml-2">
              <Button
                type="submit"
                classes={`text-normal   a-button-topic-blue-1 roboto-bold ${loading ? "disabled" : ""
                  } `}
                height={36}
                width="136px"
                text={loading ? <CircularProgress /> : "Publicar"}
                disabled={loading ? true : false}
              />
            </div>
          </div>

          {deleteD ? (
            <>
              {" "}
              {numImages == 3 ? (
                <div className="d-flex flex-column flex-wrap h-min-grid  pl-5 mt-2">
                  {renderPhotos(selectedFiles)}
                </div>
              ) : (
                <div className="d-flex flex-wrap justify-content-between mt-4  pl-5 ">
                  {renderPhotos(selectedFiles)}
                </div>
              )}
            </>
          ) : (
            <>
              {" "}
              {numImages == 3 ? (
                <div className="d-flex flex-column flex-wrap h-min-grid pl-5 mt-2">
                  {renderPhotos(selectedFiles)}
                </div>
              ) : (
                <div className="d-flex flex-wrap justify-content-between mt-4  pl-5 ">
                  {renderPhotos(selectedFiles)}
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={`py-2 px-md-5  px-2 `}>
          <div className="w-100 d-flex justify-content-between align-items-center  mb-3  border-bottom-second-Neutral-100 pb-3 ">

            <p className="h3-md a-text-medium-first-primary-60">
              Agrega una respuesta
            </p>
            <IconButton>
              <img
                src={Close}
                onClick={() => setOpen(false)}
                alt="PDF Icon"
                className="cursor-pointer "
                width="24px"
              />
            </IconButton>
          </div>
          <CardResponseModal info={question} type={type} />
          <div className={`separator-mini `}></div>

          <QuestionInputWithRows
            id={"question-text-ipad-mini"}
            placeholder={"Responde a la pregunta"}
            focus={true}
          />
          <div className="d-flex flex-column pl-5 mt-md-3">
            {numImages == 3 ? (
              <div className="d-flex flex-column flex-wrap h-min-grid ">
                {renderPhotos(selectedFiles)}
              </div>
            ) : (
              <div className="d-flex flex-wrap justify-content-between mt-2 pl-md-4">
                {renderPhotos(selectedFiles)}
              </div>
            )}
            <div className="d-flex ml-md-4 mt-md-3">
              {numImages == 4 ? (
                <Input
                  textSize="text-small"
                  id="image-question"
                  type="fileText"
                  placeholder=""
                  onChange={handleImageChange}
                  value={imagen}
                  count={FilesSelectedImage.length}
                />
              ) : (
                <Input
                  textSize="text-small"
                  id="image-question"
                  type="fileText"
                  placeholder={`${numImages == 0 ? "Agrega una imagen" : "Agrega más imágenes"
                    }`}
                  onChange={handleImageChange}
                  value={imagen}
                />
              )}
              <button className="background-transparent b-none " type="submit">
                <img
                  src={enviar}
                  alt="icon"
                  className="sidebar-community__icon ml-3 ml-md-4 cursor-pointer  align-self-start "

                />
              </button>

            </div>
          </div>
        </div>
      )}

      <Modal open={openPreviewModal.open} onClose={false} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
        <div className="modal-preview">
          <PreviewImageModal url={openPreviewModal.url} setOpen={setopenPreviewModal} />

        </div>

      </Modal>

    </form>
  );
}
