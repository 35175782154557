// React
import { Link } from "react-scroll";

// Material
import { IconButton } from "@material-ui/core";

// Images
import Arrow from "../../../../assets/img/dev/arrow-blue.svg";
import Pencil from "../../../../assets/img/pencil-icon-ocean.svg";
import Trash from "../../../../assets/img/trash-icon-ocean.svg";
import Fecha from "../../../../assets/img/icon/fecha.svg";
import Sede from "../../../../assets/img/company/sede.svg";
import Reloj from "../../../../assets/img/icon/reloj.svg";
import Objetivo from "../../../../assets/img/icon/objetivo.svg";
import Fundador from "../../../../assets/img/company/fundador.svg";
import Inversionista from "../../../../assets/img/company/inversionista.svg";
import Tema from "../../../../assets/img/company/tema.svg";
import Acuerdo from "../../../../assets/img/icon/acuerdo.svg";
import ProximaReunion from "../../../../assets/img/icon/proxima-reunion.svg";

// Utils
import { FormatDateLeft } from "../../../../utils/FormatDate";
import { CreateArray } from "../../../../utils/CreateAray";

export default function CardMinuta({
  data,
  isCollapse,
  collapsed,
  id,
  updateMinuta,
  deleteMinuta,
}) {
  // Variables
  var date = FormatDateLeft(data.date);
  var nextDate = FormatDateLeft(data.date_meeting);
  var arrAssitants = CreateArray(data.assitants);
  var arrAgreements = CreateArray(data.agreements);

  return (
    <>
      {!isCollapse ? (
        <Link to={`minuta-${id - 1}`} smooth={true} duration="1000">
          <div
            className="py-3 mt-3 pl-5 pr-3 br-1 px-md-4 d-flex align-items-md-center justify-content-between cursor-pointer background-first-primary-10"
            onClick={collapsed}
          >
            <div>
              <p className="a-text-regular-second-neutral-70 text-md-medium">
                Fecha
              </p>
              <p className="a-text-regular-second-neutral-60 text-md-medium">
                {date == "30/11/1899" ? FormatDateLeft(data.created_at) : date}
              </p>
            </div>
            <img src={Arrow} className="minuta-icon image-top opacity-4" />
          </div>
        </Link>
      ) : (
        <div className="p-3 mt-3 br-1 border-first-neutral-30">
          <div className="d-flex">
            <div className="w-100 cursor-pointer" onClick={collapsed}></div>
            <div className="d-flex align-items-center">
              <IconButton>
                <img
                  src={Pencil}
                  className="cursor-pointer"
                  width="24px"
                  onClick={() => updateMinuta(data.minutas_id)}
                />
              </IconButton>
              <IconButton>
                <img
                  src={Trash}
                  className="cursor-pointer"
                  width="24px"
                  onClick={() => deleteMinuta(data.minutas_id)}
                />
              </IconButton>
              <IconButton>
                <img
                  src={Arrow}
                  className="cursor-pointer minuta-icon"
                  onClick={collapsed}
                />
              </IconButton>
            </div>
          </div>
          <div className="d-flex">
            <img
              src={Fecha}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Fecha
              </p>
              <span className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {date == "30/11/1899" ? FormatDateLeft(data.created_at) : date}
              </span>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Sede}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Lugar
              </p>
              <span className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {data.place.replace("&amp;", "&")}
              </span>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Reloj}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Hora
              </p>
              {data.time ? (
                <span className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                  {data.time}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Objetivo}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Objetivo
              </p>
              <span className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {data.objectives.replace("&amp;", "&")}
              </span>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Fundador}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Moderador
              </p>
              <span className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {data.presenter.replace("&amp;", "&")}
              </span>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Inversionista}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Participantes
              </p>
              <div className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {arrAssitants.map((res) => (
                  <li className="mb-2 ml-3">{res.replace("&amp;", "&")}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Tema}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Tema
              </p>
              <span className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {data.meeting_theme.replace("&amp;", "&")}
              </span>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={Acuerdo}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Acuerdos
              </p>
              <div className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                {arrAgreements.map((res) => (
                  <li className="mb-2 ml-3">{res.replace("&amp;", "&")}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <img
              src={ProximaReunion}
              className="list-company-icon d-none d-lg-block mr-4"
            />
            <div>
              <p className="a-text-medium-second-neutral-90 mb-3 mb-md-2 text-md-medium mt-lg-2">
                Próxima reunión
              </p>
              <div className="pl-3 pl-lg-0 a-text-regular-second-neutral-60">
                <p>{data.place_meeting}</p>
                <p>{nextDate == "30/11/1899" ? "" : nextDate}</p>
                <p>{data.time_meeting}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
