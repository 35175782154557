//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from '@material-ui/lab';
import {useMediaQuery} from '@material-ui/core';
export default function SkeletonCardQuestion({ num }) {
    const ipadMiniMatch = useMediaQuery("(min-width: 768px)")

    // State
    const [arraySkeleton, setArraySkeleton] = useState([])
    useEffect(() => {
        let numArray = []
        for (let i = 0; i < num; i++) {
            numArray.push('')
        }
        setArraySkeleton(numArray)
    }, [])
    return (
        arraySkeleton.map((r,indx) =>
        <div className=" d-flex " key={indx}>
            <div className="card-author d-flex align-items-center box-shadow-lg-all  mr-3 mt-2 ">
                <Skeleton variant="h3" width="50px" height="100%" className="mr-1 br-tl-2 br-bl-2" animation="wave" />
                <div className="d-flex flex-column  w-90 ">
                    <Skeleton variant="h3" width="90%" height="10px" className="mb-1 " animation="wave" />
                    <Skeleton variant="h3" width="90%" height="10px" animation="wave" />
                </div>
            </div>
         </div>
        )
    )
}