// React
import { useState } from "react";
import { Redirect } from "react-router-dom";

// Material
import { Grid } from "@material-ui/core";

// Images
import Company from "../../../assets/img/company.svg";
import Analisis from "../../../assets/img/analisis.svg";

// Componens
import Header from "../../../components/molecules/Header";
import CardHome from "../../../components/molecules/Card/CardHome";

export default function HomeCID(e) {
  // Status
  const [isActive, setIsActive] = useState(true);

  // Funciones
  if (e.location.state != undefined) {
    const state = e.location.state;
    return (
      <Redirect
        to={{
          pathname: `/cid/company/${state.array[state.index]}`,
          state: {
            array: state.array,
            index: state.index,
            location: state.location,
          },
        }}
      />
    );
  }

  return (
    <Grid container justify="center" alignContent="flex-start" spacing={0}>
      
      {/* HEADER */}
      <Grid item xs={12}>
        <Header
          isCollapsed={e.isCollapsed}
          link={isActive ? "/cid/search-company-alternative" : "/cid/search-analysis"}
          text={`Buscar ${isActive ? "empresa" : "análisis"}`}
        />
        <div className="background-lg-first-neutral-10 br-2 h-100vh-header scroll-overflow pt-4 pt-lg-0">
          <div className="background-lg-white m-lg-4 p-lg-4 br-2 box-shadow-lg-all">
            <Grid
              item
              xs={12}
              className="h-100vh-header scroll-lg-overflow br-2 pt-4 pt-lg-0"
            >
              <Grid
                container
                justify="center"
                spacing={0}
                className="mt-4 my-lg-5 br-2"
              >
                <Grid
                  item
                  xs={10}
                  lg={10}
                  className="box-shadow-lg-all br-2 px-1 px-md-0"
                >
                  <Grid container justify="center" spacing={0}>
                    {/* SWITCH */}
                    <Grid item xs={12} className="d-flex cursor-pointer">
                      <div
                        onClick={() => setIsActive(true)}
                        className={`w-100 text-normal text-md-big h3-xl text-center py-1 py-md-2 py-xl-3 br-tl-2 ${
                          isActive ? "switch-cid-active" : "switch-cid"
                        }`}
                      >
                        Empresas
                      </div>
                      <div
                        onClick={() => setIsActive(false)}
                        className={`w-100 text-normal text-md-big h3-xl text-center py-1 py-md-2 py-xl-3 br-tr-2 ${
                          isActive ? "switch-cid" : "switch-cid-active"
                        }`}
                      >
                        Análisis
                      </div>
                    </Grid>
                    {/* CARDS */}
                    <Grid item xs={12} lg={12} className="pt-md-5 pt-lg-0">
                      {isActive ? (
                        <CardHome
                          active={true}
                          linkSearch="/cid/search-company-alternative"
                          link="/cid/company"
                          linkAdd="/cid/add-company"
                          image={Company}
                        />
                      ) : (
                        <CardHome
                          active={false}
                          linkSearch="/cid/search-analysis"
                          link="/cid/analysis"
                          linkAdd="/cid/add-analysis"
                          image={Analisis}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
