// React
import { useState } from 'react';

// Images
import Triangulo from '../../../assets/img/icon/triangulo-blanco.svg';

var date = new Date();
var customYear = date.getFullYear();
var customMonth = date.getMonth();
var day = date.getDate();
var monthArray = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
];

export default function Calendar({ onClick }) {
	// State
	const [mes, setMes] = useState(customMonth);
	const [año, setAño] = useState(customYear);

	// Funciones
	function handleDay(month, year) {
		let dia = [];
		let dias = [];
		if (new Date(year, month, 1).getDay() == 0) {
			for (let i = 1; i < 7; i++) {
				dia.push(i);
			}
		} else {
			for (let i = 1; i < new Date(year, month, 1).getDay(); i++) {
				dia.push(i);
			}
		}
		for (let i = 1; i <= new Date(year, month + 1, 0).getDate(); i++) {
			dias.push(i);
		}
		return (
			<>
				{dia.map((r, i) => (
					<div
						key={i}
						className='py-2 mt-1'
					></div>
				))}
				{dias.map((r, i) => (
					<div
						key={i}
						className={`py-2 mt-1 cursor-pointer ${
							day == r && customMonth == mes && customYear == año
								? 'background-first-primary-60 a-text-medium-third-neutral br-1'
								: ''
						}`}
						onClick={() => onClick(r, month + 1, year)}
					>
						{r}
					</div>
				))}
			</>
		);
	}
	function handleMonth(month, year, type) {
		if (type == 0) {
			if (month == 0) {
				setMes(11);
				setAño(year - 1);
			} else {
				setMes(month - 1);
				setAño(year);
			}
		} else {
			if (month == 11) {
				setMes(0);
				setAño(year + 1);
			} else {
				setMes(month + 1);
				setAño(year);
			}
		}
	}
	function handleYear(month, year, type) {
		if (type == 0) {
			setMes(month);
			setAño(year - 1);
		} else {
			setMes(month);
			setAño(year + 1);
		}
	}

	return (
		<div className='background-white box-shadow-first-neutral-50 br-2 calendar'>
			<div className='d-flex text-center background-first-primary-60 text-white a-text-medium-third-neutral py-2 br-t-2 px-3'>
				<div className='d-flex w-100 justify-content-center cursor-pointer'>
					<img
						src={Triangulo}
						alt='Triangulo blanco'
						className='mr-2 image-left'
						width='15px'
						onClick={() => handleMonth(mes, año, 0)}
					/>
					<span className='month'>{monthArray[mes]}</span>
					<img
						src={Triangulo}
						alt='Triangulo blanco'
						className='ml-2 image-right'
						width='15px'
						onClick={() => handleMonth(mes, año, 1)}
					/>
				</div>
				<div className='d-flex w-100 justify-content-center cursor-pointer'>
					<img
						src={Triangulo}
						alt='Triangulo blanco'
						className='mr-3 image-left'
						width='15px'
						onClick={() => handleYear(mes, año, 0)}
					/>
					<span>{año}</span>
					<img
						src={Triangulo}
						alt='Triangulo blanco'
						className='ml-3 image-right'
						width='15px'
						onClick={() => handleYear(mes, año, 1)}
					/>
				</div>
			</div>
			<div className='d-flex justify-content-between a-text-regular-second-neutral-100 calendar_week text-center pt-2 px-3'>
				<div>L</div>
				<div>M</div>
				<div>M</div>
				<div>J</div>
				<div>V</div>
				<div>S</div>
				<div>D</div>
			</div>
			<div className='calendar_day px-3 a-text-regular-second-neutral-100 br-b-2'>{handleDay(mes, año)}</div>
		</div>
	);
}
