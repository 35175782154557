import { useEffect, useState } from 'react';

export default function Time({ onEvent, onClick, time }) {
	var date = new Date();
	var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
	var minutes = date.getMinutes();
	var zones = date.getHours() > 12 ? 'p.m.' : 'a.m.';
	var scrollBase = 36;

	// State
	const [Hour, setHour] = useState(hours);
	const [Minute, setMinute] = useState(minutes);
	const [Zone, setZone] = useState(zones);

	useEffect(() => {
		document.querySelector('.hours').scrollTop = scrollBase * Hour - scrollBase;
		document.querySelector('.minutes').scrollTop = scrollBase * Minute;
		document.querySelector('.zones').scrollTop = Zone == 'p.m.' ? scrollBase : 0;
	}, []);

	// Funciones
	function handleTime() {
		let arrayHours = ['', '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, '', ''];
		let arrayMinutes = [
			'',
			'',
			'00',
			'01',
			'02',
			'03',
			'04',
			'05',
			'06',
			'07',
			'08',
			'09',
			10,
			11,
			12,
			13,
			14,
			15,
			16,
			17,
			18,
			19,
			20,
			21,
			22,
			23,
			24,
			25,
			26,
			27,
			28,
			29,
			30,
			31,
			32,
			33,
			34,
			35,
			36,
			37,
			38,
			39,
			40,
			41,
			42,
			43,
			44,
			45,
			46,
			47,
			48,
			49,
			50,
			51,
			52,
			53,
			54,
			55,
			56,
			57,
			58,
			59,
			'',
			'',
		];
		let arrayZone = ['', '', 'a.m.', 'p.m.', '', ''];
		return (
			<>
				<div
					className='time-container px-4 hours'
					onScroll={handleHours}
				>
					{arrayHours.map((res, idx) => (
						<div
							className={`time-section d-flex align-items-center ${Hour + 1 == idx ? handleEvent() : ''}`}
						>
							{res}
						</div>
					))}
				</div>
				<div
					className='time-container px-5 minutes'
					onScroll={handleMinutes}
				>
					{arrayMinutes.map((res, idx) => (
						<div
							className={`time-section d-flex align-items-center ${
								Minute + 1 == idx ? handleEvent() : ''
							}`}
						>
							{res}
						</div>
					))}
				</div>
				<div
					className='time-container px-4 zones'
					onScroll={handleZone}
				>
					{arrayZone.map((res, idx) => (
						<div
							className={`time-section d-flex align-items-center ${
								Zone == arrayZone[idx] ? handleEvent() : ''
							}`}
						>
							{res}
						</div>
					))}
				</div>
			</>
		);
	}
	function handleEvent() {
		onEvent(Hour, Minute - 1, Zone);
		return 'a-text-regular-second-primary-70';
	}
	function handleHours(e) {
		switch (e.target.scrollTop) {
			case 0:
				setHour(1);
				break;
			case scrollBase * 1:
				setHour(2);
				break;
			case scrollBase * 2:
				setHour(3);
				break;
			case scrollBase * 3:
				setHour(4);
				break;
			case scrollBase * 4:
				setHour(5);
				break;
			case scrollBase * 5:
				setHour(6);
				break;
			case scrollBase * 6:
				setHour(7);
				break;
			case scrollBase * 7:
				setHour(8);
				break;
			case scrollBase * 8:
				setHour(9);
				break;
			case scrollBase * 9:
				setHour(10);
				break;
			case scrollBase * 10:
				setHour(11);
				break;
			case scrollBase * 11:
				setHour(12);
				break;
		}
	}
	function handleMinutes(e) {
		switch (e.target.scrollTop) {
			case 0:
				setMinute(1);
				break;
			case scrollBase * 1:
				setMinute(2);
				break;
			case scrollBase * 2:
				setMinute(3);
				break;
			case scrollBase * 3:
				setMinute(4);
				break;
			case scrollBase * 4:
				setMinute(5);
				break;
			case scrollBase * 5:
				setMinute(6);
				break;
			case scrollBase * 6:
				setMinute(7);
				break;
			case scrollBase * 7:
				setMinute(8);
				break;
			case scrollBase * 8:
				setMinute(9);
				break;
			case scrollBase * 9:
				setMinute(10);
				break;
			case scrollBase * 10:
				setMinute(11);
				break;
			case scrollBase * 11:
				setMinute(12);
				break;
			case scrollBase * 12:
				setMinute(13);
				break;
			case scrollBase * 13:
				setMinute(14);
				break;
			case scrollBase * 14:
				setMinute(15);
				break;
			case scrollBase * 15:
				setMinute(16);
				break;
			case scrollBase * 16:
				setMinute(17);
				break;
			case scrollBase * 17:
				setMinute(18);
				break;
			case scrollBase * 18:
				setMinute(19);
				break;
			case scrollBase * 19:
				setMinute(20);
				break;
			case scrollBase * 20:
				setMinute(21);
				break;
			case scrollBase * 21:
				setMinute(22);
				break;
			case scrollBase * 22:
				setMinute(23);
				break;
			case scrollBase * 23:
				setMinute(24);
				break;
			case scrollBase * 24:
				setMinute(25);
				break;
			case scrollBase * 25:
				setMinute(26);
				break;
			case scrollBase * 26:
				setMinute(27);
				break;
			case scrollBase * 27:
				setMinute(28);
				break;
			case scrollBase * 28:
				setMinute(29);
				break;
			case scrollBase * 29:
				setMinute(30);
				break;
			case scrollBase * 30:
				setMinute(31);
				break;
			case scrollBase * 31:
				setMinute(32);
				break;
			case scrollBase * 32:
				setMinute(33);
				break;
			case scrollBase * 33:
				setMinute(34);
				break;
			case scrollBase * 34:
				setMinute(35);
				break;
			case scrollBase * 35:
				setMinute(36);
				break;
			case scrollBase * 36:
				setMinute(37);
				break;
			case scrollBase * 37:
				setMinute(38);
				break;
			case scrollBase * 38:
				setMinute(39);
				break;
			case scrollBase * 39:
				setMinute(40);
				break;
			case scrollBase * 40:
				setMinute(41);
				break;
			case scrollBase * 41:
				setMinute(42);
				break;
			case scrollBase * 42:
				setMinute(43);
				break;
			case scrollBase * 43:
				setMinute(44);
				break;
			case scrollBase * 44:
				setMinute(45);
				break;
			case scrollBase * 45:
				setMinute(46);
				break;
			case scrollBase * 46:
				setMinute(47);
				break;
			case scrollBase * 47:
				setMinute(48);
				break;
			case scrollBase * 48:
				setMinute(49);
				break;
			case scrollBase * 49:
				setMinute(50);
				break;
			case scrollBase * 50:
				setMinute(51);
				break;
			case scrollBase * 51:
				setMinute(52);
				break;
			case scrollBase * 52:
				setMinute(53);
				break;
			case scrollBase * 53:
				setMinute(54);
				break;
			case scrollBase * 54:
				setMinute(55);
				break;
			case scrollBase * 55:
				setMinute(56);
				break;
			case scrollBase * 56:
				setMinute(57);
				break;
			case scrollBase * 57:
				setMinute(58);
				break;
			case scrollBase * 58:
				setMinute(59);
				break;
			case scrollBase * 59:
				setMinute(60);
				break;
		}
	}
	function handleZone(e) {
		switch (e.target.scrollTop) {
			case 0:
				setZone('a.m.');
				break;
			case scrollBase * 1:
				setZone('p.m.');
				break;
		}
	}

	return (
		<div
			className='background-white d-flex box-shadow-first-neutral-50 br-2 a-text-regular-first-neutral-100 cursor-pointer p-2'
			onClick={() => onClick(null)}
		>
			<div className='position-absolute bar-time'></div>
			{handleTime()}
		</div>
	);
}
