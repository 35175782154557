import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Avatar, useMediaQuery, Grid } from "@material-ui/core";

import UserImage from "../../../../assets/img/user-icon.svg";

export default function CardFollower({ cerrarBusqueda, name, lastname, area, empresa, image=null, id, user_id, mosaico=false, colega=false}) {
    const matchIpadPro = useMediaQuery("(min-width: 992px)")
    const matchIpadMini = useMediaQuery("(min-width: 768px)")
    const matchPhone = useMediaQuery("(max-width: 767px)")
    let history = useHistory();
    let profile = {}
    profile.user_id = id;
    let stated = {}
    stated.profile = profile

    function mandarAOtroPerfil(){
        history.push({
            pathname:`/cid/carso/profile/${name}`,
            state: stated
        })
    }
    

    return (
        <>
            <div className={` ${(area == null || area == ' ') && (empresa == null || empresa == ' ') && (matchPhone || mosaico) ? 'align-items-center h-40' : ''} body-card br-2 d-flex ${matchIpadPro && mosaico==false && colega == false ? 'mx-1 px-4 py-7 mt-2 mb-2' : matchIpadPro && mosaico == false && colega ? 'mx-1 px-4 py-7 mt-3 mb-3' : matchIpadMini && mosaico==false ? 'px-4 py-7 mb-3' : mosaico && matchIpadPro ? 'mx-1 px-3 py-2 mb-2 mt-2 h-min-46' : mosaico && matchIpadMini ? 'mx-1 px-3 py-2 mb-3 h-min-46' : 'px-3 py-2 mb-2'}`}>
                <Avatar alt="user" style={matchPhone ? { maxHeight: '24px', maxWidth: '24px' } : { maxHeight: '32px', maxWidth: '32px' }} src={(image != null && image != 'null' && image != 'undefined' && image != undefined) ? `https://images.weserv.nl/?url=${image}&maxage=31d` : UserImage}></Avatar>
                {matchPhone || mosaico ? 
                    <div className={`d-flex flex-column ml-3`}>
                        {id != user_id ?
                    <p onClick={()=>{
                        history.push({
                          pathname:`/cid/carso/profile/${name}`,
                          state: stated
                      });
                    }} className={`cursor-pointer a-text-medium-first-primary-60 ${mosaico ? 'text-normal' : 'text-small'}`}>{name} {lastname}</p>
                    :
                    <Link to={`/cid/carso/profile`} className={`text-decoration-none`}>
                        <p className={`a-text-medium-first-primary-60 ${mosaico ? 'text-normal' : 'text-small'}`}>{name} {lastname}</p>
                    </Link>
                    }
                    <p className={`${(area == null && empresa == null) ? 'd-none' : ''} a-text-regular-second-neutral-60 mt-2 ${mosaico ? 'text-normal' : 'text-small'}`}>{area != null && area != ' ' ? area : ''} {(area != null && area != ' ' && empresa != null && empresa != ' ') ? ' - ' : '' } {empresa != null && empresa != ' ' ? empresa : ''}</p>
                    </div>
                :
                    <Grid container spacing={0} className={`d-flex align-items-center ml-4`}>
                    <Grid item xs={6}>
                    {id != user_id ?
                    <p onClick={mandarAOtroPerfil} className={`cursor-pointer a-text-medium-first-primary-60 text-medium `}>{name} {lastname}</p>
                    :
                    <Link to={`/cid/carso/profile`} className={`text-decoration-none`}>
                        <p className={`a-text-medium-first-primary-60 text-medium`}>{name} {lastname}</p>
                    </Link>
                    }
                    </Grid>
                    <Grid item xs={6}>
                    <p className={`${(area == null && empresa == null) ? 'd-none' : ''} a-text-regular-second-neutral-60 text-normal`}>{area != null && area != ' ' ? area : ''} {(area != null && area != ' ' && empresa != null && empresa != ' ') ? ' - ' : '' } {empresa != null && empresa != ' ' ? empresa : ''}</p>
                    </Grid>
                    </Grid>
                }
            </div>
            
        </>
    )
}