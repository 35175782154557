import { useReducer } from 'react';
const ACTIONS = {
	LOADING: 'loading',
	STORE_ASSISTANT: 'store_assistant',
	STORE_AGREEMENT: 'store_agreement',
	STORE_DATE: 'store_date',
	STORE_TIME: 'store_time',
	STORE_NEXTDATE: 'store_nextdate',
	STORE_NEXTIME: 'store_nextTime',
	STORE_WARNING_OBJECT: 'store_warning_object',
	STORE_WARNING_THEME: 'store_warning_theme',
	STORE_WARNING_SITE: 'store_warning_site',
	STORE_WARNING_MODERATOR: 'store_warning_moderator',
	STORE_WARNING_AGREEMENT: 'store_warning_agreement',
	STORE_DATA: 'store_data',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.LOADING:
			return { ...state, isLoading: action.payload };
		case ACTIONS.STORE_ASSISTANT:
			return { ...state, assistant: action.payload };
		case ACTIONS.STORE_DATE:
			return { ...state, date: action.payload };
		case ACTIONS.STORE_NEXTDATE:
			return { ...state, nextDate: action.payload };
		case ACTIONS.STORE_WARNING_OBJECT:
			return { ...state, warningObject: action.payload };
		case ACTIONS.STORE_WARNING_THEME:
			return { ...state, warningTheme: action.payload };
		case ACTIONS.STORE_WARNING_SITE:
			return { ...state, warningSite: action.payload };
		case ACTIONS.STORE_WARNING_MODERATOR:
			return { ...state, warningModerator: action.payload };
		case ACTIONS.STORE_WARNING_AGREEMENT:
			return { ...state, warningAgreement: action.payload };
		case ACTIONS.STORE_DATA:
			return { ...state, data: action.payload };
		case ACTIONS.STORE_AGREEMENT:
			return { ...state, agreement: action.payload };
		case ACTIONS.STORE_TIME:
			return { ...state, time: action.payload };
		case ACTIONS.STORE_NEXTIME:
			return { ...state, nextTime: action.payload };
		default:
			return state;
	}
};

export default function useFormMinuta({
	initialLoading,
	initialAgreement,
	initalAssitant,
	initialDate,
	initialTime,
	initialNextDate,
	initialNextTime,
	initialWarningObject,
	initialWarningTheme,
	initialWarningSite,
	initialWarningModerator,
	initialWarningAgreement,
	initialData,
}) {
	const [state, dispach] = useReducer(reducer, {
		isLoading: initialLoading,
		agreement: initialAgreement,
		assistant: initalAssitant,
		date: initialDate,
		time: initialTime,
		nextDate: initialNextDate,
		nextTime: initialNextTime,
		warningObject: initialWarningObject,
		warningTheme: initialWarningTheme,
		warningSite: initialWarningSite,
		warningModerator: initialWarningModerator,
		warningAgreement: initialWarningAgreement,
		data: initialData,
	});
	const {
		isLoading,
		agreement,
		assistant,
		date,
		time,
		nextDate,
		nextTime,
		warningObject,
		warningTheme,
		warningSite,
		warningModerator,
		warningAgreement,
		data,
	} = state;

	return {
		isLoading,
		agreement,
		assistant,
		date,
		time,
		nextDate,
		nextTime,
		warningObject,
		warningTheme,
		warningSite,
		warningModerator,
		warningAgreement,
		data,
		setIsLoading: (isLoading) => dispach({ type: ACTIONS.LOADING, payload: isLoading }),
		setAssistant: (assistant) => dispach({ type: ACTIONS.STORE_ASSISTANT, payload: assistant }),
		setDate: (date) => dispach({ type: ACTIONS.STORE_DATE, payload: date }),
		setNextDate: (nextDate) => dispach({ type: ACTIONS.STORE_NEXTDATE, payload: nextDate }),
		setWarningObject: (warningObject) => dispach({ type: ACTIONS.STORE_WARNING_OBJECT, payload: warningObject }),
		setWarningTheme: (warningTheme) => dispach({ type: ACTIONS.STORE_WARNING_THEME, payload: warningTheme }),
		setWarningSite: (warningSite) => dispach({ type: ACTIONS.STORE_WARNING_SITE, payload: warningSite }),
		setWarningModerator: (warningModerator) =>
			dispach({
				type: ACTIONS.STORE_WARNING_MODERATOR,
				payload: warningModerator,
			}),
		setWarningAgreement: (warningAgreement) =>
			dispach({ type: ACTIONS.STORE_WARNING_AGREEMENT, payload: warningAgreement }),
		setData: (data) => dispach({ type: ACTIONS.STORE_DATA, payload: data }),
		setAgreement: (agreement) => dispach({ type: ACTIONS.STORE_AGREEMENT, payload: agreement }),
		setTime: (time) => dispach({ type: ACTIONS.STORE_TIME, payload: time }),
		setNextTime: (nextTime) => dispach({ type: ACTIONS.STORE_NEXTIME, payload: nextTime }),
	};
}
