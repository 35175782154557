// React
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Material
import { Grid,useMediaQuery,createMuiTheme } from '@material-ui/core/';
import {getQuestions,getTopicsByUserId} from '../../../api/questions'

// Hooks
import { useAuth } from "../../../hooks/useAuth";

// Componentes
import { FormAddQuestionTopic } from "../../../components/organisms/FormAddQuestionTopic/index";
import Header from "../../../components/molecules/Header";
import CardQuestion from '../../../components/organisms/CardQuestions/index'
export default function FirstQuestion(e) {
    // State
    const theme = createMuiTheme({
        breakpoints: {
            values: {
                md: 768,
                lg: 992,
            },
        },
    });
    const ipadMiniMatch = useMediaQuery("(min-width: 768px)")
    const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992))
    const iPadProMatch = useMediaQuery(theme.breakpoints.up('lg'));

    const user = useAuth();



    return (
        <Grid container justify="center" spacing={0} className=''>
            <Header isCollapsed={e.isCollapsed} text='Buscar pregunta' />
            <Grid item xs={10} lg={12} className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2">
            <div className='background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all h-100vh '>

                <p className={`mt-3 mt-lg-1 mb-lg-3 mb-lg-4  ${ipadMiniMatch ? `h3   mb-md-7  ${iPadProMatch ? "mt-0 mb-md-5  "  : "mt-6 mb-md-7  "}`  : ""} mb-3 a-text-medium-second-neutral-70  `}>Sección de preguntas</p>  
                <CardQuestion  name={e.username}/>             
                </div>
            </Grid>
        </Grid>
    )
}