import React from 'react';
import { useMediaQuery, Grid } from '@material-ui/core/';
import ProfileUser from '../../../components/organisms/ProfileUser/index.js';
import SearchUsers from '../../../components/organisms/ProfileUser/SearchUsers/index.js';
import Header from '../../../components/molecules/Header';
import { useBusquedaColegas } from '../../../hooks/useBusquedaColegas';

export default function Profile(e, isCollapsed, linkSearch) {
	const matchIpadPro = useMediaQuery('(min-width: 992px)');
	const matchIpadMini = useMediaQuery('(min-width: 768px)');
	const matchPhone = useMediaQuery('(max-width: 767px)');

	const { inputSearch, buscarSeguidor, desplegarBusqueda, cerrarBusqueda, buscarColegas } = useBusquedaColegas();

	return (
		<Grid
			container
			justify='center'
			spacing={0}
		>
			{matchIpadPro && buscarColegas === true ? (
				<Header
					id={`buscar`}
					onChange={buscarSeguidor}
					isCollapsed={e.isCollapsed}
					onClick={desplegarBusqueda}
					text='Buscar colega'
				/>
			) : (matchIpadMini || matchPhone) && buscarColegas === true ? (
				<></>
			) : (
				<Header
					isCollapsed={e.isCollapsed}
					onClick={desplegarBusqueda}
					text='Buscar colega'
				/>
			)}
			<Grid
				item
				xs={12}
				lg={12}
				className='background-lg-first-neutral-10 h-100vh-header scroll-overflow  br-2'
			>
				<div className='background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all'>
					{buscarColegas === false ? (
						<ProfileUser
							userId={e.userId}
							userFollow={'none'}
							isCollapsed={e.isCollapsed}
							type={'profile'}
						/>
					) : (
						<SearchUsers
							userId={e.userId}
							cerrarBusqueda={cerrarBusqueda}
							inputHeader={inputSearch}
						></SearchUsers>
					)}
				</div>
			</Grid>
		</Grid>
	);
}
