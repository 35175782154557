import {Grid} from '@material-ui/core';
import {Button} from '../../../atoms/Button';
import { useEffect, useState } from 'react';
import { TextInput } from '../../../atoms/Input/TextInput';

function AddMultipleText({
    data,
    setData,
    cancelLabel = 'Cancelar',
    addLabel = 'Agregar',
    placeholder = '',
}){
    const [buttonLabel, setButtonLabel] = useState(addLabel);
    const [disabled, setDisabled] = useState(false);
    
    useEffect(() => {
        let allFilled = true;
        for (let i = 0; i < data.length; i++){
            if (data[i] === ''){
                allFilled = false;
                break;
            }
        }

        if (!allFilled && data.length === 1){
            setDisabled(true);
            setButtonLabel(addLabel);
        } else if (!allFilled && data.length > 1){
            setDisabled(false);
            setButtonLabel(cancelLabel);
        } else {
            setDisabled(false);
            setButtonLabel(addLabel);
        }

        if (data.length === 0){
            setData(['']);
        }
    }, [data]);



    function handleButton(){
        if (disabled) return;
        if (buttonLabel === cancelLabel){
            const newData = [...data];
            for (let i = 0; i < newData.length; i++){
                if (newData[i] === ''){
                    newData.splice(i, 1);
                    break;
                }
            }
            setData(newData);
        } else {
            setData([...data, '']);
        }
    }

    function handleInput(e, investor, index){
        let arr = [...data];
        arr[index] = e;
        setData(arr);
    }

    return(<div>
            <Grid>
                {data.map((investor, index) => (
                    <Grid item xs={12} sm={12} key={index}>
                        <div className="mb-2">
                        <TextInput 
                            placeholder={placeholder}
                            text={investor}
                            setText={(e) => handleInput(e, investor, index)}
                        />
                        </div>
                    </Grid>
                ))}
                <Grid item xs={12} sm={12} key={-1}>
                    <div onClick={handleButton}>
                    <Button
                        width="40%"
                        height="40px"
                        classes={`${
                            disabled
                            ? 'button-form-inactive text-small minuta_btn-add'
                            : 'button-form text-small minuta_btn-add'
                        } `}
                        text = {buttonLabel}
                        type = "button"
                    />
                    </div>
                </Grid>
            </Grid>
                
        </div>
    );
}

export default AddMultipleText;