/**
 * Cosumo de endpoint para CRUD de análisis.
 */

import { BASE_PATH } from './config';
import axios from 'axios';

export function getReports() {
	return axios.get(`${BASE_PATH}/reports`);
}
export function deleteReport(userId, reportId) {
    return axios
        .delete(`${BASE_PATH}/delete-report?userId=${userId}&reportId=${reportId}`)
        .then(response => response)
        .catch(err => err);
}

export function getClients(userId) {
	return axios.get(`${BASE_PATH}/report-clientes?userId=${userId}`);
}
export function createReport(data) {
	return axios.post(`${BASE_PATH}/create-report`, data);
}
export function updateReports(data) {
	return axios.put(`${BASE_PATH}/update-report`, data);
}
export function getReport(userId, reportId) {
	return axios
		.get(`${BASE_PATH}/get-report?userId=${userId}&reportId=${reportId}`)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
}
export function getMarketsReport(userId) {
	return axios.get(`${BASE_PATH}/report-markets?userId=${userId}`);
}

export function getSectorsReport(userId) {
	return axios.get(`${BASE_PATH}/report-sectors?userId=${userId}`);
}
