import { CircularProgress, IconButton } from "@material-ui/core";
import React from "react";
import CloseWhiteIcon from "../../../assets/img/company/closeWhite.svg";
import FileIcon from "../../../assets/img/company/archive.svg";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";
import FailIcon from "../../../assets/img/fail-icon.svg";

export const DownloadModal = ({error, setModalDownload, fetchingData}) => {
  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between background-blue pl-4 pr-3 border-radius-download ">
          <p className="align-self-center a-text-medium-third-neutral text-big">
            Preparando Descarga
          </p>
          <IconButton className="py-3" onClick={() => setModalDownload(false)}>
            <img src={CloseWhiteIcon} className="width-close-download" alt="close icon" />
          </IconButton>
        </div>
        <div className="d-flex justify-content-between padding-download my-4">
          <div>
            <img src={FileIcon} alt="file icon" />
          </div>
          <div className="align-self-center"> 
            {
              fetchingData && <p className="text-big a-text-medium-first-neutral-90">
              Generando archivo
            </p>
            } 
            <div className="a-text-medium-first-primary-60">
              {
                fetchingData ? 
                <div>
                    <p>Este proceso puede tardar algunos minutos</p>
                    <p>No salgas de esta página</p>
                </div>
                : error ? 
                  <div>
                     <p className="text-medium">Ha ocurrido un error al generar el reporte</p>
                  </div>
                :
                <div>
                  <p className="text-big">Se ha generado un archivo</p>
                </div>
              }
              
            </div>
          </div>
          <div id="modalDownload" className="align-self-center">
              {
                  fetchingData ? <CircularProgress /> : error ? <img src={FailIcon} alt="icono de fallo" /> : <img src={SuccessIcon} alt="icono de exito" />
              }
          </div>
        </div>
      </div>
    </>
  );
};
