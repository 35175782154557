import React, { useState, useRef } from "react";
import classnames from "classnames";
import { useIntersection } from "../../../../../hooks/useIntersectionObserver";

const ImageMultipleReader = ({ multipleImages, img, idx }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div
      ref={imgRef}
      className={`${
        multipleImages.length === 3
          ? " d-flex flex-column flex-wrap h-min-grid-card"
          : "d-flex flex-wrap"
      }`}
    >
      {isInView === false ? (
        <>
          {multipleImages.map((img, idx) => (
            <div
              className={classnames(
                ` ${
                  multipleImages.length === 3 && idx === 0
                    ? "img-container-list-multiple-item1 br-1"
                    : "img-container-list-multiple br-1"
                }  `,
                {
                  ["isLoadedTran"]: !!isLoaded,
                }
              )}
              key={idx}
            />
          ))}
        </>
      ) : (
        <>
                  {multipleImages.map((img, idx) => (
                    <img
                      className={` ${
                        multipleImages.length === 3 && idx === 0
                          ? "img-question-list-multiple-item1 br-1"
                          : "img-question-list-multiple br-1"
                      }  `}
                      src={`${process.env.REACT_APP_DEV ? `${process.env.REACT_APP_IMG_URL}/${img}` : `https://images.weserv.nl/?url=${img}&maxage=31d`}`}
                      key={idx}
                    />
                  ))}
        </>
      )}
    </div>
  );
};

export default ImageMultipleReader;
