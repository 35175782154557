// React
import { useState } from 'react';

// Material
import { IconButton, Modal } from '@material-ui/core';

// Images
import Alert from '../assets/img/alert.svg';
import SuccessIcon from '../assets/img/sucess-icon.svg';

// Componens
import { Button } from '../components/atoms/Button';

// Api
import { deleteCompanyApi } from '../api/company';

export function CompanyDelete({ image, className, iconButton, companyId, userId, width }) {
	// State
	const [isOpen, setIsOpen] = useState(false);
	const [modalBody, setModalBody] = useState('');

	// Funciones
	function handleOpen() {
		setIsOpen(true);
		setModalBody(
			<>
				<div className='d-flex justify-content-center mb-4'>
					<img
						src={Alert}
						alt='Warning'
					/>
				</div>
				<p className='mb-4 a-text-medium-second-neutral-90 text-center'>
					No se podrán deshacer los cambios.
					<br />
					¿Seguro que deseas eliminar la empresa?
				</p>
				<div className='d-flex justify-content-center'>
					<div
						className='mr-3 w-100'
						onClick={() => setIsOpen(false)}
					>
						<Button
							type='button'
							classes='a-button-primary1 text-small'
							height={36}
							width='100%'
							text='Cancelar'
						/>
					</div>
					<div
						onClick={deleteComapny}
						className='w-100'
					>
						<Button
							type='button'
							classes='a-button-light text-small'
							height={36}
							width='100%'
							text='Aceptar'
						/>
					</div>
				</div>
			</>
		);
	}

	function deleteComapny() {
		deleteCompanyApi(userId, companyId)
			.then((result) => {
				window.history.back();
				setModalBody(
					<>
						<div className='d-flex justify-content-center mb-4'>
							<img
								src={SuccessIcon}
								alt='Success Icon'
							/>
						</div>
						<p className='mb-4 a-text-medium-second-neutral-90 text-center'>
							Se ha eliminado la empresa correctamente
						</p>
						<div
							className='m-auto w-50'
							onClick={() => setIsOpen(false)}
						>
							<Button
								type='button'
								classes='a-button-primary1 text-small'
								height={36}
								width='100%'
								text='Aceptar'
							/>
						</div>
					</>
				);
			})
			.catch((err) => console.log(err));
	}

	return (
		<>
			{iconButton ? (
				<IconButton onClick={handleOpen}>
					<img
						src={image}
						alt='Trash'
						className={`${className}`}
						width={width}
					/>
				</IconButton>
			) : (
				''
			)}
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				<div className='modal'>{modalBody}</div>
			</Modal>
		</>
	);
}
