// React
import { useState } from 'react';

// Material
import { Grid } from '@material-ui/core';

// Componens
import { Button } from '../../atoms/Button';
import { Input } from '../../atoms/Input';

export function AddButton({
	width,
	widthBtn,
	heightBtn,
	placeholder,
	type,
	className,
	add,
	remove,
	array,
	onChange,
	text,
	warning,
	opcion,
	id,
	value,
	nodo,
	disabled,
	checked,
}) {
	// State
	const [isButton, setIsButton] = useState(false);
	const [isFocus, setIsFocus] = useState(false);
	const [isNewFiend, setIsNewFiend] = useState(false);
	// Funciones
	function handleButton() {
		if (isButton) {
			setIsButton(false);
			setIsFocus(false);
			remove(array.splice(1, array.length - 1));
		} else {
			setIsButton(true);
			setIsFocus(true);
			add([...array, '']);
		}
	}
	function handleInput(e, res, index) {
		if (res != '') {
			let arr = [...array];
			arr[index] = e.target.value;
			onChange(arr);
		}
		setIsButton(false);
		setIsFocus(false);
	}
	function handleFiend() {
		setIsButton(!isButton);
		setIsNewFiend(!isNewFiend);
	}
	function addCheckbox() {
		setIsButton(false);
		setIsNewFiend(false);
		let check = document.getElementById(`add-${id}`).value;
		if (check != '') {
			add([...array, { name: check, check_id: array.length + 1 }]);
		}
	}
	function typeFiend() {
		switch (opcion) {
			case 'grid':
				return (
					<Grid
						container
						spacing={0}
					>
						{array.map((res, index) => (
							<Grid
								key={index}
								item
								xs={12}
								lg={6}
								className={index % 2 == 0 ? 'minuta_pr-col-6' : 'minuta_pl-col-6'}
							>
								<Input
									width={width}
									placeholder={placeholder}
									type={type}
									className={className}
									onChange={(e) => handleInput(e, res, index)}
									// value={res != '' ? res : ''}
									focus={isFocus}
								/>
							</Grid>
						))}
					</Grid>
				);
			case 'fiend':
				return array.map((res, index) => (
					<Input
						key={index}
						width={width}
						placeholder={placeholder}
						type={type}
						className={className}
						onChange={(e) => handleInput(e, res, index)}
						// value='dadas'
						focus={isFocus}
						disabled={disabled}
						checked={checked}
					/>
				));
			case 'checkbox':
				return (
					<>
						<Input
							type='checkbox-sm'
							data={array}
							value={value}
							onChange={onChange}
							limit={10}
							seeMoreButton={true}
							id={id}
						/>
						{isNewFiend ? (
							<div className='mt-2'>
								<Input
									width={width}
									placeholder={placeholder}
									type='add'
									id={`add-${id}`}
									focus={true}
									onClick={addCheckbox}
								/>
							</div>
						) : (
							''
						)}
					</>
				);
			default:
				break;
		}
	}

	function handleNodo(res, nodo) {
		switch (nodo) {
			case 'name':
				return res.name;
			default:
				break;
		}
	}

	return (
		<>
			{typeFiend()}
			{warning ? <p className='mt-2 a-text-regular-alert-60'>{warning}</p> : ''}
			<div
				className='d-inline-block mt-3 mb-2 w-100'
				onClick={opcion == 'checkbox' ? handleFiend : handleButton}
			>
				<Button
					id='btn-add'
					width={widthBtn}
					height={heightBtn}
					classes={`${
						disabled
							? 'button-form-inactive text-small minuta_btn-add'
							: 'button-form text-small minuta_btn-add'
					} `}
					disabled={disabled}
					text={isButton ? 'Cancelar' : text}
					type='button'
				/>
			</div>
		</>
	);
}
