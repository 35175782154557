import { Editor, RichUtils } from "draft-js";

import styles from "./styles.module.css";
import "draft-js/dist/Draft.css";

export default function MarkdownEditor({ editorState, setEditorState }) {
  const onBoldClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };
  const onItalicsClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };
  const onUnderlineClick = (e) => {
    e.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };
  const onUnorderedClick = (e) => {
    e.preventDefault();
    setEditorState(
      RichUtils.toggleBlockType(editorState, "unordered-list-item"),
    );
  };

  const currentStyle = editorState.getCurrentInlineStyle();

  function isInLineStyleActive(style) {
    return currentStyle.has(style);
  }

  function isBlockTypeActive(currentBlockType) {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return blockType === currentBlockType;
  }

  return (
    <div>
      <div className={styles.markdownToolbar}>
        <div>
          <button
            type="button"
            className={`${styles.button} ${isInLineStyleActive("BOLD") ? styles.activeButton : ""}`}
            onMouseDown={onBoldClick}
          >
            <Bold width={24} height={24} />
          </button>
          <button
            type="button"
            className={`${styles.button} ${isInLineStyleActive("ITALIC") ? styles.activeButton : ""}`}
            onMouseDown={onItalicsClick}
          >
            <Italics width={24} height={24} />
          </button>
          <button
            type="button"
            className={`${styles.button} ${isInLineStyleActive("UNDERLINE") ? styles.activeButton : ""}`}
            onMouseDown={onUnderlineClick}
          >
            <Underline width={24} height={24} />
          </button>
        </div>
        <Separator role="separador" />
        <div>
          <button
            type="button"
            className={`${styles.button} ${isBlockTypeActive("unordered-list-item") ? styles.activeButton : ""}`}
            onMouseDown={onUnorderedClick}
          >
            <ListBullet width={24} height={24} />
          </button>
        </div>
      </div>
      <div className={styles.markdownContainer}>
        <Editor editorState={editorState} onChange={setEditorState} />
      </div>
    </div>
  );
}

function Separator() {
  return <div className={styles.separator} />;
}

function Underline({ ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.34619 22.125V20.625H18.6537V22.125H5.34619ZM11.9999 18.5287C10.4448 18.5287 9.23102 18.0566 8.35869 17.1125C7.48619 16.1683 7.04994 14.9032 7.04994 13.3172V5.41345H8.90369V13.4095C8.90369 14.4198 9.17228 15.2295 9.70944 15.8385C10.2466 16.4475 11.0101 16.752 11.9999 16.752C12.9898 16.752 13.7533 16.4475 14.2904 15.8385C14.8276 15.2295 15.0962 14.4198 15.0962 13.4095V5.41345H16.9499V13.3172C16.9499 14.9032 16.5137 16.1683 15.6412 17.1125C14.7689 18.0566 13.5551 18.5287 11.9999 18.5287Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function Italics({ ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.39404 18.625V16.8173H9.21129L12.4518 7.18275H8.63454V5.375H17.7883V7.18275H14.2785L11.0383 16.8173H14.5478V18.625H5.39404Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function Bold({ ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.33838 18.625V5.375H12.1999C13.219 5.375 14.1405 5.69233 14.9644 6.327C15.788 6.9615 16.1999 7.816 16.1999 8.8905C16.1999 9.63783 16.0194 10.2471 15.6584 10.7182C15.2975 11.1894 14.9088 11.5314 14.4921 11.7442C15.005 11.9211 15.4947 12.2708 15.9614 12.7933C16.428 13.3158 16.6614 14.0193 16.6614 14.9038C16.6614 16.1819 16.1902 17.1217 15.2479 17.723C14.3055 18.3243 13.3562 18.625 12.3999 18.625H7.33838ZM9.48838 16.6328H12.3191C13.1063 16.6328 13.6627 16.4142 13.9884 15.977C14.314 15.5398 14.4769 15.1206 14.4769 14.7192C14.4769 14.3179 14.314 13.8987 13.9884 13.4615C13.6627 13.0243 13.0909 12.8058 12.2729 12.8058H9.48838V16.6328ZM9.48838 10.875H12.0826C12.6903 10.875 13.172 10.7013 13.5279 10.3538C13.8835 10.0064 14.0614 9.59042 14.0614 9.10575C14.0614 8.59042 13.8733 8.16925 13.4971 7.84225C13.1208 7.51542 12.6595 7.352 12.1134 7.352H9.48838V10.875Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function ListBullet({ ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.30775 18.75V17.25H20.5V18.75H9.30775ZM9.30775 12.75V11.25H20.5V12.75H9.30775ZM9.30775 6.75005V5.25005H20.5V6.75005H9.30775ZM5.1635 19.6635C4.706 19.6635 4.31442 19.5006 3.98875 19.1748C3.66292 18.8491 3.5 18.4575 3.5 18C3.5 17.5425 3.66292 17.151 3.98875 16.8253C4.31442 16.4995 4.706 16.3365 5.1635 16.3365C5.621 16.3365 6.01258 16.4995 6.33825 16.8253C6.66408 17.151 6.827 17.5425 6.827 18C6.827 18.4575 6.66408 18.8491 6.33825 19.1748C6.01258 19.5006 5.621 19.6635 5.1635 19.6635ZM5.1635 13.6635C4.706 13.6635 4.31442 13.5006 3.98875 13.1748C3.66292 12.8491 3.5 12.4575 3.5 12C3.5 11.5425 3.66292 11.151 3.98875 10.8253C4.31442 10.4995 4.706 10.3365 5.1635 10.3365C5.621 10.3365 6.01258 10.4995 6.33825 10.8253C6.66408 11.151 6.827 11.5425 6.827 12C6.827 12.4575 6.66408 12.8491 6.33825 13.1748C6.01258 13.5006 5.621 13.6635 5.1635 13.6635ZM5.1635 7.66355C4.706 7.66355 4.31442 7.50063 3.98875 7.1748C3.66292 6.84913 3.5 6.45755 3.5 6.00005C3.5 5.54255 3.66292 5.15096 3.98875 4.8253C4.31442 4.49946 4.706 4.33655 5.1635 4.33655C5.621 4.33655 6.01258 4.49946 6.33825 4.8253C6.66408 5.15096 6.827 5.54255 6.827 6.00005C6.827 6.45755 6.66408 6.84913 6.33825 7.1748C6.01258 7.50063 5.621 7.66355 5.1635 7.66355Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
