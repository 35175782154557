
import { useState } from "react";

export function Select2Scroll({
  data,
  className,
  onMouseDown,
  id,
  warning,
  placeholder,
  onChange,
  setData,
  value,
  selectClass, 
  disabled, 
  Children, 
  autocomplete = false,
  onClickEvent,
  nameKey,
  width }) {
  //dropdown menu with search bar
  const [visible, setVisible] = useState('hidden');
  const name = nameKey ? (elem) => nameKey(elem) : (elem) => elem?.name;

  function filterData() {
    if (value === undefined|| value === null) {
      return data;
    }
    return data.filter((res) => name(res).toLowerCase().includes(value.toLowerCase()));
  }


  return (
    <div
      style={{
        width: width ? width : "calc(100% - 10px)",
        height: "45px",
        position: "relative",
      }}
      onBlur={() => setVisible('hidden')}
    >
      <input
        className={`input-option scroll-overflow-y pl-2 mb-md-4 `}
        style={{
          width: `calc(${100}% - 10px)`,
          height: "35px",
          fontFamily: "Roboto-Medium",
          fontSize: "15px",
          border: "1px solid #a8aba8",
          borderRadius: "5px",
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        id={id}
        onFocus={() => setVisible('visible')}
        autoComplete={autocomplete ? "on" : "off"}
        disabled={disabled} />
      {warning ? <p 
        className="mt-2 a-text-regular-alert-60"
        style={{
          width: `calc(${100}% - 10px)`,
          zIndex: "1",
          position: "absolute",
          top: "30px",
          left: "0px",
          maxHeight: "300px",
        }}
        >{warning}</p> : ""}
      {/* dropdown */}
      <div className="position-absolute-list"
        style={{
          width: `calc(${100}% - 10px)`,
          fontFamily: "Roboto-Medium",
          fontSize: "15px",
          border: "1px solid #a8aba8",
          borderRadius: "5px",
          overflow: "auto",
          zIndex: "1",
          position: "absolute",
          top: "40px",
          left: "0px",
          backgroundColor: "#FFFF",
          visibility: visible,
          overflowY: "scroll",
          maxHeight: "300px",
        }}
      >
        {filterData().map((item) => (
          <div >
            <Children text={name(item)} value={item} key={item.country_id} onMouseDown={onMouseDown} />
          </div>
        )   
        )}
      </div>
    </div>

  );
}
