// React
import { useEffect, useState } from 'react';

// Material
import { Grid, IconButton } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

// Components
import AddMultipleText from '../../../molecules/AddButton/AddMultipleText';
import { AddFounder2 } from '../../../molecules/AddButton/AddFounder2/index.js';
import { SelectScroll } from '../../../atoms/Input/SelectScroll.js';
import { Select2Scroll } from '../../../atoms/Input/Select2Scroll.js';
import { AutoexpansibleInput } from '../../../atoms/Input/AutoexpansibleInput.js';
import { Checkbox } from '../../../atoms/Input/Checkbox.js';
import { IconTextInput } from './IconTextInput.js';
import { TextInput } from '../../../atoms/Input/TextInput.js';
import { BigLabel } from './BigLabel.js';
import { Label } from './Label.js';
import { useStyles } from './useStyles.js';
import { Contacto } from './Contacto.js';
import { ImageInput } from '../../../atoms/Input/ImageInput.js';
import { paisMenu } from './paisMenu.js';
import { contactoCID } from './contactoCID.js';
import { Error } from './Error.js';
import SedeIcon from '../../../../assets/img/company/sede-non-selected.svg';
import SkeletonCompanies from '../../../molecules/Skeleton/SkeletonCompanies/index';
import MarkdownEditor from '../../MarkdownEditor';
import { EditorState } from 'draft-js';
import markdownToEditorState from '../../../../utils/markdownToDraftjs';
import draftjsToMarkdown from '../../../../utils/draftjsToMarkdown';

// Images
import Close from '../../../../assets/img/icon/close.svg';

//Images 2.0
import Linkedin2 from '../../../../assets/img/linkedin2.svg';
import Twitter2 from '../../../../assets/img/twitter2.svg';
import Youtube2 from '../../../../assets/img/youtube2.svg';
import Instagram2 from '../../../../assets/img/instagram2.svg';
import Facebook2 from '../../../../assets/img/facebook2.svg';
import IgInactivo from '../../../../assets/img/social/iggris.svg';
import FbInactivo from '../../../../assets/img/social/fbgris.svg';
import LinkedinInactivo from '../../../../assets/img/social/linkedingris.svg';
import TwitterInactivo from '../../../../assets/img/social/twittergris.svg';
import YtInactivo from '../../../../assets/img/social/ytgris.svg';
import FileIcon from '../../../../assets/img/paisaje.svg';

// Api
import { getCidUsers } from '../../../../api/user';
import { getCompany, getFounders, getHashtags, getIndustrias, getMercados, getSectors, getSedes, getSizes, getSubtechnology, getTechnology } from '../../../../api/company';
import { getUserApi } from '../../../../api/admin';
import { validateForm } from './validateForm.js';
import { Submit } from './Submit.js';
import { RegisterNewSedeComponent } from './RegisterNewSedeComponent.js';
import HashtagsInput from './hashtagsInput';

const headers = new Headers();
headers.append('X-CSCAPI-KEY', 'cGxSOHNsQWhKS2RXVXFXQWdrYXU5NEtIZG1VZWg3d1gyZWx0V0tRaA==');

export var requestOptions = {
  method: 'GET',
  headers: headers,
  redirect: 'follow',
};

const years = Array.from(new Array(100), (v, i) => {
  return new Date().getFullYear() - i;
});

const foundationYears = Array.from(new Array(new Date().getFullYear() - 1839), (v, i) => {
  return new Date().getFullYear() - i;
});

function Button({ onClick, label }) {
  return (
    <div
      className='d-flex'
      style={{
        marginTop: 14,
        marginBottom: 10,
      }}
    >
      <div
        onClick={onClick}
        className='btn btn-primary'
        style={{
          cursor: 'pointer',
          borderRadius: 4,
          fontFamily: 'Roboto-Medium',
          border: '1px solid #355471',
          fontSize: 15,
          letterSpacing: 0.25,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // disable text selection
          WebkitTouchCallout: 'none' /* iOS Safari */,
          WebkitUserSelect: 'none' /* Safari */,
          KhtmlUserSelect: 'none' /* Konqueror HTML */,
          MozUserSelect: 'none' /* Old versions of Firefox */,
          MsUserSelect: 'none' /* Internet Explorer/Edge */,
          userSelect: 'none' /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */,
          width: 300,
          height: 35,
          color: '#355471',
          fontWeight: 550,
        }}
      >
        {label}
      </div>
    </div>
  );
}

export function locationDropdown({ text, value, key, setCurrentValue, onMouseDown }) {
  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          padding: 5,
          display: 'flex',
          flowDirection: 'row',
          alignItems: 'center',
        }}
        onMouseDown={() => {
          onMouseDown(value);
        }}
      >
        <img
          className='mr-2'
          id={key}
          alt={text}
          src={SedeIcon}
        />
        <div
          className='d-flex flex-column'
          id={text}
        >
          <p id={text}>{text}</p>
        </div>
      </div>
    </>
  );
}

const handleBack = () => {
  window.location.href = '/cid/company';
};

function Form({ userId, companyId, text, setError }) {
  // states
  const [active, setActive] = useState(true);
  const [dateInactive, setDateInactive] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [foundation, setFoundation] = useState('');
  const [location, setLocation] = useState('');
  const [size, setSize] = useState('');
  const [sizeId, setSizeId] = useState('');
  const [founders, setFounders] = useState([]);
  const [sectors, setSectors] = useState('');
  const [sectorId, setSectorId] = useState('');
  const [industries, setIndustries] = useState([]);
  const [technology, setTechnology] = useState('');
  const [technologyId, setTechnologyId] = useState('');
  const [secundaryTechnology, setSecundaryTechnology] = useState('');
  const [market, setMarket] = useState('');
  const [income, setIncome] = useState('');
  const [funding, setFunding] = useState('');
  const [valuation, setValuation] = useState('');
  const [opportunities, setOpportunities] = useState(() => EditorState.createEmpty());
  const [investors, setInvestors] = useState(['']);
  const [website, setWebsite] = useState('');
  const [video_url, setVideo_url] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [youtube, setYoutube] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [alliances, setAlliances] = useState(['']);
  const [capturista, setCapturista] = useState('');
  const [contactocid, setContactocid] = useState('');
  const [IDcontactoCID, setIDContactoCID] = useState('');
  const [validSede, setValidSede] = useState(true);
  const [hashtags, setHashtags] = useState([]);

  const [contacto, setContacto] = useState([]);
  const [logoSource, setLogoSource] = useState('');
  const [imageSource, setImageSource] = useState('');
  const [registerNewSede, setRegisterNewSede] = useState(false);

  // data from api
  const [sedes, setSedes] = useState([]);
  const [sectorsData, setSectorsData] = useState([]);
  const [sizesData, setSizesData] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);
  const [technologyData, setTechnologyData] = useState([]);
  const [subtechnologyData, setSubtechnologyData] = useState([]);
  const [mercadosData, setMercadosData] = useState([]);
  const [cidUsers, setCidUsers] = useState([{}]);

  const [warnings, setWarnings] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // initial petitions
  useEffect(() => {
    const fetchData = async () => {
      try {
        getHashtags(companyId).then((res) => {
          setHashtags([...res.data]);
        });

        getSedes()
          .then((response) => {
            setSedes(response.data.sedes);
          })
          .catch((err) => {
            throw Error(err);
          });

        getSectors()
          .then((response) => {
            setSectorsData(response.data.sectores);
            console.log(response.data.sectores);
          })
          .catch((err) => {
            throw Error(err);
          });

        getSizes()
          .then((response) => {
            setSizesData(response.data.sizes);
          })
          .catch((err) => {
            throw Error(err);
          });

        getTechnology()
          .then((response) => {
            setTechnologyData(response.data.technology);
          })
          .catch((err) => {
            throw Error(err);
          });

        getMercados()
          .then((response) => {
            setMercadosData(response.data.mercados);
          })
          .catch((err) => {
            throw Error(err);
          });

        getUserApi(userId)
          .then((response) => {
            setCapturista(response.data.user.name + ' ' + response.data.user.lastname);
          })
          .catch((err) => {
            throw Error(err);
          });

        getCidUsers()
          .then((response) => {
            setCidUsers(response.data.users);
          })
          .catch((err) => {
            throw Error(err);
          });
      } catch (error) {
        setError(true);
        console.log(error);
      }
    };

    fetchData().catch((err) => {
      console.log(err);
    });

    if (companyId) {
      setIsLoading(true);
      getCompany(userId, companyId)
        .then((response) => {
          try {
            setActive(response.data.company.activo == 1);
            setDateInactive(response.data.company.date_inactive);
            setName(response.data.company.name);
            setDescription(response.data.company.description);
            setFoundation(response.data.company.foundation);
            setLocation(response.data.company.sede);
            setSize(response.data.company.company_size_cad);
            setTechnology(response.data.company.technology);
            setIncome(response.data.company.entry);
            setFunding(response.data.company.funding);
            setValuation(response.data.company.valuation);
            setOpportunities(markdownToEditorState(response.data.company.opportunities));
            setInvestors(response.data.company.investors.map((item) => item.name));
            setWebsite(response.data.company.website_url);
            setLinkedin(response.data.company.linkedin_url);
            setYoutube(response.data.company.youtube_url);
            setTwitter(response.data.company.twitter_url);
            setFacebook(response.data.company.facebook_url);
            setInstagram(response.data.company.instagram_url);
            setWhatsapp(response.data.company.whatsapp_url);
            setAlliances(response.data.company.alliances.map((item) => item.name));
            setContactocid(response.data.company.contact_cid + ' ' + response.data.company.contact_cid_lastname);
            setContacto(response.data.company.contacts);
            setLogoSource(response.data.company.logo_link);
            setIDContactoCID(response.data.company.contact_user_id);
            setVideo_url(response.data.company.video_url);
            if (response.data.company.sectors.length > 0) {
              setSectors(response.data.company.sectors[0].sector);
              setSectorId(response.data.company.sectors[0].sector_id);
              setIndustries(response.data.company.sectors.map((item) => item.industry));
            }

            if (response.data.company.markets.length > 0) {
              setTechnology(response.data.company.markets[0].tech);
              setTechnologyId(response.data.company.markets[0].market_id);
              setSecundaryTechnology(response.data.company.markets.map((item) => item.subtech));
            }

            if (response.data.company.mercados.length > 0) {
              setMarket(response.data.company.mercados.map((item) => item.name));
            }
            setIsLoading(false);
          } catch (error) {
            setError(true);
            console.log(error);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      getFounders(companyId)
        .then((response) => {
          setFounders(response.data.founders);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (size === '') return;
    if (sizesData.length === 0) return;
    const id = sizesData.filter((item) => item.name === size)[0].size_id;
    setSizeId(id);
  }, [size, sizesData]);

  useEffect(() => {
    if (technology === '') {
      setSubtechnologyData([]);
      return;
    }
    getSubtechnology(technologyId).then((response) => {
      setSubtechnologyData(response.data.subTechnology);
    });

    // console.log(technology);
  }, [technologyId]);

  useEffect(() => {
    if (sectorId === '') {
      setIndustriesData([]);
      return;
    }
    getIndustrias(sectorId)
      .then((response) => {
        setIndustriesData(response.data.industrias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sectorId]);

  const classes = useStyles();

  const submitForm = async () => {
    const company = {
      active: active ? 1 : 0,
      dateinactive: dateInactive,
      company_id: companyId,
      name: name,
      description: description,
      foundation: foundation,
      location: location,
      size: size,
      sectors: sectors,
      industries: industries,
      technology: technology,
      secundaryTechnology: secundaryTechnology,
      market: market,
      income: income ? income : '',
      funding: funding ? funding : '',
      valuation: valuation ? valuation : '',
      opportunities: opportunities ? draftjsToMarkdown(opportunities) : '',
      investors: investors,
      website: website ? website : '',
      video_url: video_url ? video_url : '',
      linkedin: linkedin ? linkedin : '',
      youtube: youtube ? youtube : '',
      twitter: twitter ? twitter : '',
      facebook: facebook ? facebook : '',
      instagram: instagram ? instagram : '',
      whatsapp: whatsapp ? whatsapp : '',
      alliances: alliances,
      contactocid: contactocid,
      contacto: contacto,
      foundersData: founders,
      register_user_id: userId,
      contact_user_id: IDcontactoCID,
      size_id: sizeId,
      logo_link: logoSource,
      registerNewSede: registerNewSede,
      validSede: validSede,
      hashtags: hashtags.map((h) => h.hashtag),
    };

    const isValid = validateForm(company, setWarnings, companyId ? 1 : 0);

    if (!isValid) return;

    Submit(company, setError, companyId ? 1 : 0);
  };

  return (
    <div className=' background-white br-2 box-shadow-black-10'>
      <Grid
        container
        spacing={0}
        className='background-lg-white br-2 py-4 pr-4 my-4 pl-form box-shadow-lg-all'
      >
        <Grid
          item
          xs={12}
          md={9}
          lg={12}
          xl={12}
          className=''
        >
          <div className='d-flex justify-content-between'>
            <p className='h3 a-text-bold-second-neutral-70'>Formulario de Empresa</p>
            <div className='mr-4'>
              <IconButton onClick={handleBack}>
                <img
                  src={Close}
                  alt='Close'
                  className='nav-company-icon '
                />
              </IconButton>
            </div>
          </div>
        </Grid>
        {isLoading ? (
          <SkeletonCompanies />
        ) : (
          <Grid
            item
            lg={8}
            xl={7}
            style={{
              width: '100%',
            }} //TODO: Refactorizar para que se vea bien en mobile
          >
            <form onSubmitCapture={submitForm}>
              <p className='a-text-regular-first-neutral-100 mb-3'>* Campos obligatorios</p>
              <div className='d-flex flex-row align-items-start a-text-medium-second-neutral-70'>
                <Switch
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                  classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                  }}
                  className='mb-3'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />

                {!active && <p className='pt-3 mx-2 flex-shrink'>Empresa inactiva desde el</p>}
                {!active && (
                  <SelectScroll
                    data={years}
                    className='mb-3'
                    id='location'
                    placeholder='Seleccione el año'
                    dateChanged={(e) => setDateInactive(e.target.value)}
                    value={dateInactive}
                    selectClass='position-absolute-list'
                    warning={warnings.dateInactive}
                  />
                )}
              </div>
              <div className='mb-3 w-100'>
                <Label text='Nombre *' />
                <TextInput
                  placeholder='Ingresa el nombre de la empresa'
                  setText={setName}
                  text={name}
                  warning={warnings.name}
                  maxLength={255}
                />
              </div>
              <div className='mb-3 w-50 '>
                <Label text='Logo *' />
                <ImageInput
                  className='input-file logo'
                  Icon={FileIcon}
                  id='logo'
                  originalSource={logoSource}
                  style={{ width: `calc(${100}% - 10px)` }}
                  warning={warnings.logo}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Descripción *' />
                <AutoexpansibleInput
                  placeholder='Ingresa la descripción de la empresa'
                  setText={setDescription}
                  text={description}
                  warning={warnings.description}
                />
              </div>
              <div className='mb-3 w-100'>
                <HashtagsInput
                  hashtags={hashtags}
                  setHashtags={setHashtags}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Año de fundación de la empresa *' />
                <SelectScroll
                  data={foundationYears}
                  className=''
                  id='location'
                  placeholder='Seleccione el año'
                  dateChanged={(e) => setFoundation(e.target.value)}
                  value={foundation}
                  selectClass='position-absolute-list'
                  warning={warnings.foundation}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Ubicación de la sede *' />
                <Select2Scroll
                  id='company-sede'
                  placeholder='Selecciona la sede de la empresa'
                  type='select2Scroll'
                  data={sedes}
                  classLabel='a-text-medium-second-neutral-90'
                  onChange={(e) => setLocation(e.target.value)}
                  onMouseDown={(item) => {
                    setLocation(item.name);
                  }}
                  setData={setLocation}
                  value={location}
                  height='300px'
                  Children={paisMenu}
                  onClickEvent={(item) => setLocation(item.name)}
                  warning={warnings.location}
                  disabled={registerNewSede}
                />
                {registerNewSede ? (
                  <RegisterNewSedeComponent
                    warning={warnings.new_sede}
                    setValidSede={setValidSede}
                    setSede={setLocation}
                  />
                ) : (
                  ''
                )}
                <Button
                  onClick={() => setRegisterNewSede(!registerNewSede)}
                  label={registerNewSede ? 'Cancelar' : 'Registrar nueva sede'}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Tamaño de la empresa *' />
                <SelectScroll
                  data={sizesData}
                  nameKey={(elem) => elem.name}
                  className=''
                  id='size'
                  placeholder='Selecciona el tamaño de la empresa'
                  dateChanged={(e) => setSize(e.target.value)}
                  value={size}
                  selectClass='position-absolute-list'
                  warning={warnings.size}
                />
              </div>
              <div className='mb-3 w-100'>
                <BigLabel text='Fundadores' />
                <AddFounder2
                  foundersData={founders}
                  setFounders={setFounders}
                  warning={warnings.founders}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Sector *' />
                <SelectScroll
                  data={sectorsData}
                  nameKey={(elem) => elem.name}
                  IdKey={(elem) => elem.sector_id}
                  id='sectors'
                  placeholder='Selecciona el sector'
                  dateChanged={(e) => {
                    let id = e.target.value;
                    setSectorId(id);
                    setSectors(sectorsData.filter((item) => item.sector_id == id)[0].name);
                    // console.log(sectorsData.filter((item) => item.sector_id == id));
                    // setSectors(sectorsData.filter((item) => item.sector_id == id)[0].name);
                  }}
                  value={sectorId}
                  selectClass='position-absolute-list'
                  warning={warnings.sectors}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Industrias *' />
                <Checkbox
                  data={industriesData}
                  value={industries}
                  setValue={setIndustries}
                  id='industries'
                  nameKey={(elem) => elem.name}
                  warning={warnings.industries}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Tecnología (primaria)*' />
                <SelectScroll
                  data={technologyData}
                  nameKey={(elem) => elem.name}
                  IdKey={(elem) => elem.market_id}
                  className=''
                  id='technology'
                  placeholder='Selecciona la tecnología'
                  dateChanged={(e) => {
                    let id = e.target.value;
                    setTechnologyId(id);
                    setTechnology(technologyData.filter((item) => item.market_id == id)[0].name);
                  }}
                  value={technologyId}
                  selectClass='position-absolute-list'
                  warning={warnings.technology}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Tecnología (secundaria)' />
                <Checkbox
                  data={subtechnologyData}
                  value={secundaryTechnology}
                  setValue={setSecundaryTechnology}
                  className=''
                  id='subtechnology'
                  nameKey={(elem) => elem.Subtechnology}
                  warning={warnings.subtechnology}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Mercado *' />
                <Checkbox
                  data={mercadosData}
                  value={market}
                  setValue={setMarket}
                  className=''
                  id='mercados'
                  nameKey={(elem) => elem.name}
                  warning={warnings.mercados}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Ingresos' />
                <TextInput
                  placeholder='Ingresa el nombre de la empresa'
                  setText={setIncome}
                  text={income}
                  maxLength={255}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Financiamiento' />
                <TextInput
                  text={funding}
                  setText={setFunding}
                  placeholder={'Ingresa el monto de fondeo'}
                  maxLength={255}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Valoración' />
                <TextInput
                  text={valuation}
                  setText={setValuation}
                  placeholder={'Ingresa la última valoración de la empresa'}
                  maxLength={255}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Oportunidades *' />
                <MarkdownEditor
                  editorState={opportunities}
                  setEditorState={setOpportunities}
                />
                {warnings.opportunities && <p className='mt-2 a-text-regular-alert-60'>{warnings.opportunities}</p>}
                {/*<TextInput*/}
                {/*  text={opportunities}*/}
                {/*  setText={setOpportunities}*/}
                {/*  placeholder={"Ingresa las oportunidades de la empresa"}*/}
                {/*  warning={warnings.opportunities}*/}
                {/*/>*/}
              </div>
              <div className='mb-3 w-100'>
                <Label text='Inversionistas' />
                <AddMultipleText
                  data={investors}
                  setData={setInvestors}
                  addLabel='Agregar inversionista'
                  calcelLabel='Cancelar'
                  placeholder='Ingresa el nombre del inversionista'
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Sitio Web *' />
                <TextInput
                  text={website}
                  setText={setWebsite}
                  placeholder={'Ingresa el sitio web de la empresa'}
                  warning={warnings.website}
                  maxLength={255}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Video' />
                <TextInput
                  text={video_url}
                  setText={setVideo_url}
                  placeholder={'Ingresa el video de la empresa'}
                  warning={warnings.video_url}
                  maxLength={255}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Redes sociales' />
                <IconTextInput
                  icon={Linkedin2}
                  link={linkedin}
                  setLink={setLinkedin}
                  warning={warnings.linkedin}
                  iconInactivo={LinkedinInactivo}
                  maxLength={255}
                />
                <IconTextInput
                  icon={Twitter2}
                  link={twitter}
                  setLink={setTwitter}
                  warning={warnings.twitter}
                  iconInactivo={TwitterInactivo}
                  maxLength={255}
                />
                <IconTextInput
                  icon={Youtube2}
                  link={youtube}
                  setLink={setYoutube}
                  warning={warnings.youtube}
                  iconInactivo={YtInactivo}
                  maxLength={255}
                />
                <IconTextInput
                  icon={Instagram2}
                  link={instagram}
                  setLink={setInstagram}
                  warning={warnings.instagram}
                  iconInactivo={IgInactivo}
                  maxLength={255}
                />
                <IconTextInput
                  icon={Facebook2}
                  link={facebook}
                  setLink={setFacebook}
                  warning={warnings.facebook}
                  iconInactivo={FbInactivo}
                  maxLength={255}
                />
                {/* <IconTextInput
                  icon={Whatsapp2}
                  link={whatsapp}
                  setLink={setWhatsapp}
                  warning={warnings.whatsapp}
                  iconInactivo={WaInactivo}
                /> */}
              </div>
              <div className='mb-3 w-100'>
                <Label text='Alianzas y colaboraciones' />
                <AddMultipleText
                  data={alliances}
                  setData={setAlliances}
                  addLabel='Agregar alianza adicional'
                  calcelLabel='Cancelar'
                  placeholder='Ingresa alianza y/o colaboraciones de la empresa'
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Contacto CID *' />
                <Select2Scroll
                  id='company-contacto'
                  placeholder='Selecciona el contacto CID'
                  type='select2Scroll'
                  data={cidUsers}
                  classLabel='a-text-medium-second-neutral-90'
                  onChange={(e) => setContactocid(e.target.value)}
                  onMouseDown={(item) => {
                    setContactocid(item.name + ' ' + item.lastname);
                    setIDContactoCID(item.user_id);
                  }}
                  setData={setContactocid}
                  nameKey={(elem) => elem.name + ' ' + elem.lastname}
                  value={contactocid}
                  Children={contactoCID}
                  warning={warnings.contactocid}
                />
              </div>
              <div className='mb-3 w-100'>
                <Label text='Capturista' />
                <TextInput
                  text={capturista}
                  setText={setCapturista}
                  placeholder={'Ingresa el capturista'}
                  disabled={true}
                />
              </div>
              <div className='mb-3 w-100'>
                <BigLabel text='Contacto' />
                <Contacto
                  data={contacto}
                  setData={setContacto}
                />
              </div>
              <div className='mb-3 w-100'>
                {warnings.validForm ? (
                  <p
                    className='mt-2 a-text-regular-alert-60'
                    style={{
                      width: `calc(${100}% - 10px)`,
                      padding: 10,
                    }}
                  >
                    {warnings.validForm}
                  </p>
                ) : (
                  ''
                )}
                <div
                  onClick={submitForm}
                  className='btn btn-primary'
                  style={{
                    cursor: 'pointer',
                    width: 300,
                    height: 40,
                    borderRadius: 4,
                    backgroundColor: '#355471',
                    color: '#FFFFFF',
                    fontFamily: 'Roboto-Medium',
                    fontSize: 14,
                    fontWeight: 600,
                    letterSpacing: 0.25,
                    lineHeight: 20,
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {companyId ? 'Actualizar empresa' : 'Añadir empresa'}
                </div>
              </div>
            </form>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default function FormCompany({ userId, companyId, text }) {
  const [error, setError] = useState(false);

  return (
    <>
      {error ? (
        <Error />
      ) : (
        <Form
          userId={userId}
          companyId={companyId}
          text={text}
          setError={setError}
        />
      )}
    </>
  );
}
