import React from "react"
import emptyState404 from "../../../../assets/img/questions/sin-resultados.svg";

export function EmptyStateQuestions({
  titulo,
  link,
  hyper,
  redirect,
  page,
  setPage,
  sub,
}) {
  return (
    <div className="text-center mt-3">
      {link ? (
        <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
          {titulo} <br />{" "}
          <span className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
            {" "}
            Ve a{" "}
            <span
              onClick={() => setPage(!page)}
              className="a-text-regular-second-primary-70 cursor-pointer text-decoration-underline"
            >
              {hyper}
            </span>{" "}
            de usuarios a los que puedes ayudar
          </span>
        </p>
      ) : (
        <p className="a-text-regular-second-neutral-90 text-md-medium  h1-xl text-normal mb-md-3 px-3">
          {titulo} {sub ? <br /> : <div />} {sub ? `${sub}` : <div />}
        </p>
      )}

      <img src={emptyState404} className="mt-5 d-block m-auto w-500 h1-500" />
    </div>
  );
}
