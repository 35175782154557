import React, { useContext } from 'react'
// import Play from "../../../assets/img/icon/play cuadradito.svg"
// import Pause from "../../../assets/img/icon/pause cuadradito.svg"
import Stop from "../../../assets/img/closeFilter.svg"
// import PlusTen from "../../../assets/img/icon/mas10.svg"
import PlusTen from "../../../assets/img/icon/mas10blanco.svg"
// import MinusTen from "../../../assets/img/icon/menos10.svg"
import MinusTen from "../../../assets/img/icon/menos10blanco.svg"
import Play from "../../../assets/img/icon/play.svg"
// import Pause from "../../../assets/img/icon/pausa2.svg"
import Pause from "../../../assets/img/icon/pause blanco.svg"

import { PodcastContext } from '../../../providers/PodcastContext';

import { useAudioPosition } from "react-use-audio-player";

import {
    IconButton,
    useMediaQuery,
  } from "@material-ui/core";

export const AudioControls = ({ togglePlayPause, playing }) => {
    const { setActive } = useContext(PodcastContext)

    const ipadMiniMatch = useMediaQuery("(max-width: 767px)");

    const { percentComplete, duration, seek } = useAudioPosition({
        highRefreshRate: true,
    });

    const goTo = (type) => {
        switch (type) {
            case 0:
                seek(((percentComplete * duration) / 100) - 10)
                break;
            case 1:
                seek(((percentComplete * duration) / 100) + 10)
                break;
            default:
                break;
        }
    }

    return (
        <div className='d-flex align-items-end'>
            <div className={ipadMiniMatch ? 'd-none' : ''}>
                <IconButton
                    onClick={() => goTo(0)}
                    className={"cursor-pointer"}
                >
                    <img
                    src={MinusTen}
                    alt="Play"
                    width={28}
                    height={28}
                    />
                </IconButton> 
            </div>
            <div>
                <IconButton
                    onClick={togglePlayPause}
                    className={"cursor-pointer"}
                    size={ipadMiniMatch ? 'small' : 'medium'}
                >
                    <img
                    src={playing ? Pause : Play}
                    alt="Play"
                    width={ipadMiniMatch ? 28 : 32}
                    height={ipadMiniMatch ? 28 : 32}
                    />
                </IconButton> 
            </div>
            <div className={ipadMiniMatch ? 'd-none' : ''}>
                <IconButton
                    onClick={() => goTo(1)}
                    className={"cursor-pointer"}
                >
                    <img
                    src={PlusTen}
                    alt="Play"
                    width={28}
                    height={28}
                    />
                </IconButton> 
            </div>
            {/* <div>
                <IconButton
                    onClick={() => {
                        //Aquí iría algo para cerrar la ventana flotante o lo que sea del podcast reproduciendose
                        stop();
                        setActive(false)
                    }}
                    className={"cursor-pointer"}
                >
                    <img
                    src={Stop}
                    alt="Stop"
                    width={26}
                    height={26}
                    />
                </IconButton> 
            </div> */}
        </div>
    )
}
