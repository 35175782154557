
import React from 'react';


//material
import { Grid } from '@material-ui/core'

//componentes
import { Redirect } from "react-router-dom";

//imagen
import CompanyIcon from '../../../assets/img/company.svg';
import AnalisisIcon from '../../../assets/img/analisis.svg'
import BuhoLanding from '../../../assets/img/buho_landing.svg';
import AndroidIcon from '../../../assets/img/android.svg';
import IosIcon from '../../../assets/img/ios.svg';

//apk
import WikiCidAndroid from '../../../assets/apk/Wikicid.apk'

export default function index() {

    const dispositivo = navigator.userAgent;

    let arrCir = [];

    for (let index = 0; index < 4; index++) {
        arrCir.push("")
    }

    if (/android|iphone/i.test(dispositivo)) {

        const text = /android/i.test(dispositivo) ? "Descargar para Android" : "Descargar para IOS"
        const DispoIcon = /android/i.test(dispositivo) ? AndroidIcon : IosIcon
        const typeApp = /android/i.test(dispositivo) ? WikiCidAndroid : null

        return (
            <div className="backgroundButtonLand">
                <Grid container
                    direction="column"
                >
                    <Grid container direction="column" className="containerLanding">
                        <Grid item xs >
                            <div className="pl-5 pr-5 pt-5 pb-4">
                                <p className="h3 a-text-medium-third-neutral text-center">¡Vive la experiencia WikiCID!</p>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a href={typeApp} rel="noreferrer" target="_blank" download className="buttonLanding text-decoration-none d-flex justify-content-center align-items-center a-button-primary2 roboto-medium mb-4 mt-2 pl-4 pr-4 pt-2 pb-2">
                                    <img alt="Icono Dispositivo" src={DispoIcon} width={45} height={45} />
                                    <p className="text-medium a-text-medium-third-neutral pl-4 flex-wrap textLanding">{text}</p>
                                </a>
                            </div>
                        </Grid>
                        <Grid item xs className="mb-1">
                            <Grid container direction="row" spacing={0}>
                                <Grid item xs>
                                    <div className="circle2 d-flex ml-4 justify-content-center align-items-center z-index-button">
                                        <img alt="Icono Circulo" src={AnalisisIcon} width={110} height={110} />
                                    </div>
                                    <div className="containerCirecle2 mt-4 pl-2">
                                        {
                                            arrCir.map(item => {
                                                return (
                                                    <div className="minicircle ml-3 mr-1 br-1" />
                                                )
                                            })
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className="containerCirecle mt-3 pl-2">
                                        {
                                            arrCir.map(item => {
                                                return (
                                                    <div className="minicircle ml-3 mr-1 br-1" />
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="circle1 d-flex justify-content-center align-items-center ml-1 mb-1 mb-5">
                                        <img alt="Icono Circulo" src={CompanyIcon} width={125} height={125} />
                                    </div>


                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <svg className="wavesLanding" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" width="100%" height="100%" preserveAspectRatio="none" x="0" y="0">
                        <path fill="#fff" fill-opacity="1" d="M0,160L48,181.3C96,203,192,245,288,234.7C384,224,480,160,576,133.3C672,107,768,117,864,138.7C960,160,1056,192,1152,224C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    </svg>
                    <Grid container className="backgroundButtonLand">
                        <Grid item xs className="backgroundButtonLand sizeBuho">
                            <img alt="Buho" className="buhoLanding pl-4" src={BuhoLanding} width={200} height={200} />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }

}

