
import { BASE_PATH } from "./config";
import axios from "axios";

export function createUserApi(newUser) {
	return axios.post(`${BASE_PATH}/admin/create-user`, newUser);
}

export function getUserApi(userId) {
    return axios.get(`${BASE_PATH}/admin/get-user/${userId}`);
}

export function deactivateUserApi(userId) {
	return axios.post(`${BASE_PATH}/admin/deactivate-user`, { userId: userId });
}

export function activateUserApi(userId) {
	return axios.post(`${BASE_PATH}/admin/activate-user`, { userId: userId });
}

export function getUsersApi(userId) {
    return axios.get(`${BASE_PATH}/admin/get-users`, { params: { userId } });
}

export function deleteUserApi(userId, adminId) {
    return axios.delete(`${BASE_PATH}/admin/delete-user`, {
        params: {
            userId,
            adminId: Number(adminId)
        }
    })
}

export function updateUserApi(updateUser) {
    return axios.put(`${BASE_PATH}/admin/update-user`, updateUser)
}

export function verifyUser(token) {
    return axios.post(`${BASE_PATH}/admin/verify-user?token=${token}`,).then(response => {
        if (response.status === 200) {
        } else {
        }
        return response;
    }).catch(err => {
        return err;
    })
}
