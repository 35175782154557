/**
 * Cosumo de endpoints para adminitración de usuarios y logeos
 */

import { BASE_PATH } from './config';
import axios from 'axios';

export function getCidUsers() {
	return axios
		.get(`${BASE_PATH}/get-cidUsers`)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
}

export function signInAdminApi(adminSignInData) {
	let { email, password } = adminSignInData;

	return axios
		.post(`${BASE_PATH}/admin/sign-in`, {
			email,
			password,
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
}

export function signInApi(userData) {
	let { email, password } = userData;
	return axios
		.post(`${BASE_PATH}/sign-in`, {
			email,
			password,
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err;
		});
}

export function sendEmailResetPasswordApi(email) {
	return axios.post(`${BASE_PATH}/reset-send`, {
		email,
	});
}

export function verifyTokenPasswordApi(token) {
    return axios.get(`${BASE_PATH}/reset-verify?token=${token}`);
}

export function createNewPasswordApi(password, userId, token) {
    return axios.put(`${BASE_PATH}/reset-password`, {
        password,
        userId,
        token
    });
}
