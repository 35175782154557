export function contactoCID({ text, value, key, onMouseDown }) {
  return (
    <>
      <div className="d-flex" id={text}
        onMouseDown={() => { onMouseDown(value); }}
        style={{
          cursor: "pointer",
          padding: 5,
        }}>
        <div className="d-flex flex-column" id={text}>
          <p id={text}>{value.name + " " + value.lastname}</p>
          {/* <p id={text} className="text-xs a-text-medium-first-neutral-90">Correo - {value.email}</p> */}
        </div>
      </div>

    </>
  );
}
