import React from "react";
import { Link } from "react-router-dom";

//Material UI
import Grid from "@material-ui/core/Grid";

//Images
import Logo from "../../../assets/img/logo.svg";
import SuccessIcon from "../../../assets/img/sucess-icon.svg";

//Components
import { Button } from "../../../components/atoms/Button"

//Hooks
import useAuth from "../../../hooks/useAuth";

export default function SuccessfulResetPassword({ history }){

    const user = useAuth();
    if(user){
        window.location.href = history.goBack()
    }else{
        return (
            <div className="recover-password-background">
                <Grid container spacing={0} className="d-flex justify-content-center align-items-md-center h-100">
                    <Grid xs={10} md={6} lg={4}>
                        <div className="card--large">
                            <img className="pt-5 mb-5 logo d-block mx-auto" src={Logo} alt="Wikicid Logo" />
                            <div className="mb-4 d-flex justify-content-center">
                                <img src={SuccessIcon} alt="Sucess" className="sucess-reset-icon"/>
                            </div>
                            <h3 className="h3 text-center mb-4 a-text-bold-second-neutral-100">Se ha restablecido tu contraseña con éxito</h3>
                            <p className="text-center text-normal a-text-regular-second-neutral-60 mb-4">Ahora puedes usar tu nueva contraseña para ingresar a tu cuenta.</p>
                            <div className="w-100 mx-auto">
                                <Link to="/" className="w-100">
                                    <Button height="36px" width="100%" classes="a-button-primary1" text="Ingresar" />
                                </Link>
                            </div>
    
                        </div>
    
                    </Grid>
                </Grid>
            </div>
        )
    }


}