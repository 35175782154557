import React from 'react';

function Button({ id, classes, height, width, text, type, disabled, onclick }) {
	const styleBtnText = {
		fontWeight: '900',
		padding: '3.5px',
	};

	return (
		<button
			disabled={disabled}
			id={id}
			className={classes}
			style={{ height: height, width: width }}
			type={type}
			onClick={onclick}
		>
			<span style={styleBtnText}>{text}</span>
		</button>
	);
}

export { Button };
