//React
import { Link } from "react-router-dom";

// Images
import emptyStateMinuta from "../../../../assets/img/empty-state-minuta.svg";

export function EmptyStateBuo({ titulo, link, onClick, subTitulo }) {
  return (
    <div className="text-center">
      <p className="a-text-bold-second-neutral-90 h3-md h1-xl mb-md-3">
        Aún no tenemos {titulo}
      </p>
      <span className="a-text-regular-second-neutral-40 h3-md h1-xl">
        Haz clic{" "}
        {link ? (
          <Link to={link}>
            <span className="a-text-regular-second-primary-70 cursor-pointer text-decoration-underline">
              aquí
            </span>
          </Link>
        ) : (
          <span
            className="a-text-regular-second-primary-70 cursor-pointer text-decoration-underline"
            onClick={onClick}
          >
            aquí
          </span>
        )}{" "}
        para agregar {subTitulo}
      </span>
      <img
        src={emptyStateMinuta}
        className="mt-5 d-block m-auto minuta-empty-state"
      />
    </div>
  );
}
