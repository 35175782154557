// React
import React, { useState, useEffect } from "react";
// Material
import {
  useMediaQuery,
  Modal,
  Grid,
  createMuiTheme,
  CircularProgress,
} from "@material-ui/core";

// Componentes
import { InputWithLabel } from "../../molecules/InputWith/InputWithLabel";
import { Button } from "../../atoms/Button/";
import SkeletonTopicQuestion from "../../molecules/Skeleton/SkeletonQuestions/SkeletonTopicQuestion";
// Api
import {
  getTopics,
  addTopicbyUser,
  updateTopicbyUser,
} from "../../../api/questions";
//Hook
import Close from "../../../assets/img/closeFilter.svg";

import ModalSuccess from "../../molecules/Modal/SuccessModal";

function FormAddQuestionTopic({
  userId,
  state,
  type,
  config,
  page,
  text,
  storeTopics,
  setChangeTopics
}) {
  // State

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [change, setChange] = useState(false);
  const [load, setLoad] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState(storeTopics);
  let check = false;
  const [open, setOpen] = useState(false);
  // Status err

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  //const theme = useTheme();
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  const handleClickTopics = (e, topic) => {
    e.preventDefault()
    const { topics: { name, topics_id } } = topic;
    const deletedSelectedTopic = selectedTopics.find(topicState => topicState.topics_id === topics_id)
    if (deletedSelectedTopic) {
      const filterDeletedTopics = selectedTopics.filter(topicState => topicState.topics_id !== deletedSelectedTopic.topics_id)
      setSelectedTopics(filterDeletedTopics)
    } else {
      setSelectedTopics([...selectedTopics, { topics_id, name }])
    }
  }

  // Peticiones
  useEffect(() => {
    const obtenerTopics = async () => {
      try {
        const { data: { topics } } = await getTopics();
        setData(topics)
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    obtenerTopics()
  }, [change]);
  // Funciones

  function onChangeCheck(e) {
    setChecked(!checked);
  }

  // Submit
  async function submitCreateForm(e) {
    e.preventDefault();
    const TopicInputs = document.querySelectorAll(
      ".input-checkbox-icon:checked"
    );
    const topics = [];
    const logos = [];

    TopicInputs.forEach((topic) => {
      let topicData = JSON.parse(topic.value);
      topics.push(topicData.topic_id);
    });

    if (config) {
      setLoad(true);
      try {
        await updateTopicbyUser(userId, topics);
        setTimeout(() => {
          setOpen(true);
          setLoad(false);
        }, 1200);
        if (topics.length === 0) {
          localStorage.setItem('topics_user', JSON.stringify([10]));
        } else {
          localStorage.setItem('topics_user', JSON.stringify(topics));
        }
      } catch (error) {
        console.log(error)
      } finally {
        setTimeout(() => {
          setOpen(false);
        }, 3500);
      }

    } else {
      let viewState = 0;

      if (checked) {
        viewState = 1;
      }
      setLoad(true);

      const response = addTopicbyUser(userId, topics, viewState);

      response
        .then((result) => {
          if (state) {
            window.location.href = "/cid/carso/questions/list";
          } else {
            window.location.href = "/cid/carso/questions/first";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  return (
    <>
      <Grid item md={12} className="mb-5">
        <form onSubmitCapture={submitCreateForm}>
          <div className="br-3  w-100  d-flex flex-column  mb-5 ">
            {isLoading ? (
              <SkeletonTopicQuestion num={9} />
            ) : (
              <InputWithLabel
                text={text}
                config={type}
                id="question-topic"
                placeholder="Agregar topic"
                type="checkboxIcon"
                Location={true}
                data={data}
                value={storeTopics}
                className="btn-checkbox-icon-add"
                handleClick={handleClickTopics}
              />
            )}

            {type ? (
              <div className="d-flex flex-column-reverse flex-md-row justify-content-between mt-3 mt-lg-2 ">
                <div className=" mt-md-3 mt-lg-3  mt-xl-3 pt-1 mt-3  ">
                  <input
                    type="checkbox"
                    id={"view-always"}
                    className="cursor-pointer radioNew "
                    name="sector"
                    onChange={onChangeCheck}
                    checked={checked}
                  />
                  <label
                    className="cursor-pointer a-text-regular-second-neutral-70 text-md-normal text-small ml-2 "
                    htmlFor="view-always"
                  >
                    {" "}
                    No volver a mostrar
                  </label>
                </div>
                <div className="d-flex justify-content-md-end  justify-content-around     mb-3 mb-lg-1 pr-2 mt-md-3 mt-lg-2  mt-xl-2">
                  <Button
                    type="submit"
                    classes={`text-normal a-button-topic-gray-1  a-button-topic-gray-1 roboto-bold h3-md  text-lg-normal ${load ? " disabled " : " "
                      }`}
                    text={"Omitir"}
                  />

                  <Button
                    type="submit"
                    classes={`text-normal ml-2  a-button-topic-blue-1  roboto-bold h3-md ml-md-4 text-lg-normal ${load ? " disabled " : " "
                      }`}
                    text={load ? <CircularProgress /> : "Guardar"}
                  />
                </div>{" "}
              </div>
            ) : (
              <div className="d-flex   mb-3 mb-lg-1 pr-2 mt-md-3 mt-lg-2  mt-xl-2">
                {config === "btn-none" ? (
                  <div />
                ) : (
                  <Button
                    type="submit"
                    classes={`text-normal text-lg-normal  mr-md-3 a-button-topic-blue-1  roboto-bold h3-md  ${load ? "disabled" : ""
                      }`}
                    text={load ? <CircularProgress /> : "Guardar"}
                  />
                )}
              </div>
            )}
          </div>
        </form>

        <Modal
          open={open}
          onClose={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal">
            <ModalSuccess
              open={open}
              setOpen={setOpen}
              message={"Se cambiaron las configuraciones"}
              action={"Timmer"}
            />
          </div>
        </Modal>
      </Grid>
    </>
  );
}

export { FormAddQuestionTopic };
