export const timeSince = (dateState) => {
  const startTime = new Date(dateState)
  const fechaInicio = startTime.setHours( startTime.getHours() );
  const seconds = Math.floor(( new Date().getTime() - fechaInicio ) / 1000);

  let interval = seconds / 31536000;
  if (interval >= 1) {
    return Math.floor(interval) + (Math.floor(interval) === 1 ? " año" : " años");
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return Math.floor(interval) + (Math.floor(interval) === 1 ? " mes" : " meses");
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return Math.floor(interval) + (Math.floor(interval) === 1 ? " día" : " días");
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return Math.floor(interval) + (Math.floor(interval) === 1 ? " hora" : " horas");
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return Math.floor(interval) + (Math.floor(interval) === 1 ? " minuto" : " minutos");
  }
  return "unos segundos";
}

export const temas = [
  {
    "topics_id": 10,
    "name": "Todas",
  },
  {
    "topics_id": 1,
    "name": "Ciencia",
  },
  {
    "topics_id": 2,
    "name": "Tecnología",
  },
  {
    "topics_id": 3,
    "name": "Economía",
  },
  {
    "topics_id": 4,
    "name": "Negocios",
  },
  {
    "topics_id": 5,
    "name": "Finanzas",
  },
  {
    "topics_id": 6,
    "name": "Política",
  },
  {
    "topics_id": 7,
    "name": "Desarrollo",
  },
  {
    "topics_id": 8,
    "name": "Web",
  },
  {
    "topics_id": 9,
    "name": "Gaming",
  }
]