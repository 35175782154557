//React
import React, { useEffect, useState } from "react";

//Material
import { Skeleton } from "@material-ui/lab";
import { useMediaQuery, Grid } from "@material-ui/core";

export default function SkeletonNews({ num }) {
  // State
  const [arraySkeleton, setArraySkeleton] = useState([]);

  useEffect(() => {
    let numArray = [];
    for (let i = 0; i < num; i++) {
      numArray.push("");
    }
    setArraySkeleton(numArray);
  }, []);
  return arraySkeleton.map((r, index) => (
    <div key={r+index} className=" d-flex justify-content-between box-shadow-all mt-3 br-1">
      <div className=" align-items-center w-100 ml-3 mt-3">
        <Skeleton
          variant="text"
          height={20}
          width="30%"
          animation="wave"
          className="pb-3 mr-3"
        />
        <Skeleton
          variant="rect"
          height={30}
          width="80%"
          animation="wave"
          className="mb-2"
        />
        <Skeleton
          variant="rect"
          height={100}
          width="80%"
          animation="wave"
          className="mb-3"
        />
        <div className="d-flex justify-content-between  mb-3">
          <Skeleton variant="rect" width="5%" animation="wave" />
          <Skeleton variant="rect" width="5%" animation="wave" />
        </div>
      </div>
      <div className="m-3">
        <Skeleton
          variant="rect"
          width={298}
          height={185}
          animation="wave"
          className="image-news justify-content-between  align-items-center br-1"
        />
      </div>
    </div>
  ));
}
