import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useIntersection } from '../../../../../hooks/useIntersectionObserver';


const ImageRenderer = ({ multipleImages,img,idx }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div
    ref={imgRef}

    >
      {

      }


      {isInView === false ? <div className={classnames('image-container', {
      ['isLoadedTran']: !!isLoaded
    })} ></div> 
    :
    (
        <>

            <img
            className={classnames('image img-question-list', {
                ['isLoaded']: !!isLoaded
              })}
            src={img}
            onLoad={handleOnLoad}

          />

        </>
      )}
    </div>
  );
};

export default ImageRenderer;
