// React
import React from "react";

// Auth
import AuthProvider from "../providers/AuthProvider";

// Componentes
import LayoutMaster from "../components/organisms/LayoutMaster";

export default function LayoutCid(props) {
    // States
    const { routes, location } = props;

    return (
        <AuthProvider>
            <LayoutMaster routes={routes} location={location.pathname} />
        </AuthProvider>
    )
}
