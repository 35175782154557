import React, { useState } from "react";
import {
  useMediaQuery,
  Grid,
} from "@material-ui/core";

import { BlogByUserList as CardMyBlogList } from "../../organisms/BlogList/BlogByUserList";

// Components
export default function BlogMenu({ page, setPage, userId, viewType }) {
  
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const [myQuestions, setMyquestions] = useState(true);
  const [myAnsw, setMyAnsw] = useState(false);
  const [myRequest, setMyRequest] = useState(false);
  const [myQuery, setMyQuery] = useState(false);

  const categories = [
    'publicadas',
    'programadas',
    'guardadas',
    'borradores',
  ]

  const [category, setCategory] = useState(categories[0])

  return (
    <Grid xs={12} className="w-100  ">
      <div className="d-flex mt-3  align-items-center scroll-overflow      w-100">
        <div
          onClick={() => {
            setMyquestions(true);
            setMyAnsw(false);
            setMyRequest(false);
            setMyQuery(false);
            setCategory(categories[0])
          }}
          className={` ${
            ipadMiniMatch ? " " : ""
          } cursor-pointer text-small text-lg-normal  text-md-medium mr-2 mr-md-3 text-center br-4 px-2 py-1 py-md-2  ${
            myQuestions ? "switch-question-active" : "switch-question"
          } px-md-4 py-lg-1 h-a`}
        >
          Publicadas{" "}
        </div>

        <div
          onClick={() => {
            setMyRequest(false);
            setMyquestions(false);
            setMyQuery(false);
            setCategory(categories[3]);
            setMyAnsw(true);
          }}
          className={` ${
            ipadMiniMatch ? " " : ""
          } text-small text-md-medium cursor-pointer mr-md-3 mr-2  text-center br-4 text-lg-normal px-2 py-1 py-md-2  ${
            myAnsw ? "switch-question-active" : "switch-question"
          } px-md-4 h-a py-lg-1`}
        >
          Borradores
        </div>

        <div
          onClick={() => {
            setMyRequest(true);
            setMyquestions(false);
            setMyQuery(false);
            setCategory(categories[1]);
            setMyAnsw(false);
          }}
          className={` ${
            ipadMiniMatch ? " " : ""
          } text-small text-md-medium cursor-pointer mr-md-3 mr-2  text-center br-4 text-lg-normal px-2 py-1 py-md-2  ${
            myRequest ? "switch-question-active" : "switch-question"
          } px-md-4 h-a py-lg-1`}
        >
          Programadas
        </div>

        <div
          onClick={() => {
            setMyRequest(false);
            setMyquestions(false);
            setMyQuery(true);
            setCategory(categories[2]);
            setMyAnsw(false);
          }}
          className={` ${
            ipadMiniMatch ? " " : ""
          } text-small text-md-medium cursor-pointer mr-md-3 mr-2  text-center br-4 text-lg-normal px-2 py-1 py-md-2  ${
            myQuery ? "switch-question-active" : "switch-question"
          } px-md-4 h-a py-lg-1`}
        >
          Guardado
        </div>
      </div>
          {
            myQuestions ? (<CardMyBlogList category={category} viewType={viewType}/>) :(<div/>)
          }
          {
            myRequest ? (<CardMyBlogList category={category} viewType={viewType}/>) :(<div/>)
          }
          {
            myQuery ? (<CardMyBlogList category={category} viewType={viewType} />) :(<div/>)
          }
          {
            myAnsw ? (<CardMyBlogList category={category} viewType={viewType} />) :(<div/>)
          }


    </Grid>
  );
}
