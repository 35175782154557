import React, { useState} from "react";
import {
  useMediaQuery,
  IconButton,
  createMuiTheme,
  CircularProgress,
} from "@material-ui/core";

import QuestionInputWithRows from "../../../components/molecules/QuestionInput/QuestionInputWithRows/index";
import CardResponseModal from "../../molecules/Card/CardQuestion/CardUniqueQuestion/CardResponseModal";
import { updateComment } from "../../../api/blogs";
import { Button } from "../../atoms/Button";
import Close from "../../../assets/img/closeFilter.svg";
import enviar from "../../../assets/img/questions/enviar.svg";
import { useAuth } from "../../../hooks/useAuth";

export function UpdateComment({
  setOpen,
  commentary,
  type,
  setChange,
  change,
  blog,
}) {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });

  const user = useAuth();
  const [loading, setLoading] = useState(false);
  const { userId } = user;

  const [textState, setTextState] = useState(commentary.description);

  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const ipadProUse = useMediaQuery("(min-width:992px)");

  function handleWrite(evn) {
    if (evn.target.value !== "") {
      setTextState(evn.target.value);
    } else {
      setTextState("");
    }
  }

  function submitCreateForm(e) {
    e.preventDefault();
    setLoading(true);
    let comment;

    if (iPadProMatch) {
      comment = document.getElementById("reponse-text-ipad").value;
    } else {
      comment = document.getElementById("question-text-ipad-mini").value;
    }

    updateComment(userId, commentary.comment_blog_id, comment)
      .then((response) => {
        setOpen(false);
        setChange(!change);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <form onSubmitCapture={submitCreateForm}>
      {ipadProUse ? (
        <div className={` px-5 `}>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div>
              <p className="h3-md a-text-medium-first-primary-60">
                Deja tu comentario
              </p>
            </div>

            <IconButton>
              <img
                src={Close}
                onClick={() => setOpen(false)}
                alt="PDF Icon"
                className="cursor-pointer "
                width="24px"
              />
            </IconButton>
          </div>

          <CardResponseModal info={blog} type={type} />

          <QuestionInputWithRows
            id={"reponse-text-ipad"}
            placeholder={"Escribe tu opinión"}
            focus={true}
            onChange={(e) => handleWrite(e)}
            value={textState}
          />

          <div className=" mt-2 d-flex pl-5  align-items-center  ">
            <div className="d-flex align-items-center mr-4  "></div>
            <div className="d-flex flex-row-ms-reverse w-100 ml-2">
              <Button
                type="submit"
                classes={`text-normal   a-button-topic-blue-1 roboto-bold ${
                  loading ? "disabled" : ""
                } `}
                height={36}
                width="136px"
                text={loading ? <CircularProgress /> : "Publicar"}
                disabled={loading ? true : false}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={`py-2 px-md-5  px-2 `}>
          <div className="w-100 d-flex justify-content-between align-items-center  mb-3  border-bottom-second-Neutral-100 pb-3 ">
            <p className="h3-md a-text-medium-first-primary-60">
              Deja tu comentario
            </p>
            <IconButton>
              <img
                src={Close}
                onClick={() => setOpen(false)}
                alt="PDF Icon"
                className="cursor-pointer "
                width="24px"
              />
            </IconButton>
          </div>

          <CardResponseModal info={blog} type={type} />

          <QuestionInputWithRows
            id={"question-text-ipad-mini"}
            placeholder={"Responde a la pregunta"}
            focus={true}
          />

          <div className="d-flex flex-column pl-5 mt-md-3 align-flex-end">
            <div className="d-flex ml-md-4 mt-md-3 mt-2 ">
              <button className="background-transparent b-none " type="submit">
                <img
                  src={enviar}
                  alt="icon"
                  className="sidebar-community__icon ml-3 ml-md-4 cursor-pointer  align-self-start "
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
