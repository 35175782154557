//React
import React from "react";
import Moment from "react-moment";
// Material
import userIcon from "../../../../../assets/img/user-icon.svg";

//Images, Icons

export default function CardResponseModal({ info, noIcons, type }) {
  switch (type) {
    case "question":
      return (
        <div className=" h-a d-flex flex-row br-2 mb-4 mb-md-5 mb-lg-3  py-md-2 py-2">
          <div className="pr-3  d-flex ">
            <div
              className={`d-flex justify-content-start  align-items-center    mt-md-1 mt-lg-1 `}
            >
              <img
                src={`https://images.weserv.nl/?url=${info.photo_link}&maxage=31d`}
                alt="icon"
                className="icon-modal-user br-5   align-self-start "
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                }}
              />
            </div>

            <div className=" pt-1 d-flex flex-column pl-4">
              <span className="a-text-regular-first-neutral-100 text-big h3-md ">
                {info.creator} - {info.name}{" "}
                <Moment fromNow>{info.created_at}</Moment>{" "}
              </span>
              <span className="a-text-medium-second-neutral-70 text-big h3-md ">
                {info.question}
                {info.description}
              </span>
            </div>
          </div>
        </div>
      );
      break;

    case "blog":
      return (
        <div className=" h-a d-flex flex-row br-2 mb-4 mb-md-5 mb-lg-3  py-md-2 py-2">
          <div className="pr-3  d-flex ">
            <div
              className={`d-flex justify-content-start  align-items-center    mt-md-1 mt-lg-1 `}
            >
              <img
                src={`https://images.weserv.nl/?url=${info.photo_link}&maxage=31d`}
                alt="icon"
                className="icon-modal-user br-5   align-self-start "
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = userIcon;
                }}
              />
            </div>

            <div className=" pt-1 d-flex flex-column pl-4">
              <span className="a-text-medium-second-neutral-70 text-big h3-md ">
                {info.title}
              </span>
              <span className="a-text-regular-first-neutral-100 text-small mt-2">
                {info.name}
              </span>
            </div>
          </div>
        </div>
      );
      break;
    case "response":
      return (
        <div className=" h-a d-flex flex-row br-2 mb-4 mb-md-5 mb-lg-3  py-md-2 py-2">
          <div className="pr-3  d-flex ">
            <div
              className={`d-flex justify-content-start  align-items-center    mt-md-1 mt-lg-1 `}
            >
              <img
                src={`https://images.weserv.nl/?url=${info.photo_link}&maxage=31d`}
                alt="icon"
                className="icon-modal-user sidebar-community__icon    align-self-start"
              />
            </div>

            <div className=" pt-1 d-flex flex-column pl-4">
              <span className="a-text-regular-first-neutral-100 text-big h3-md ">
                {info.ResponseName} - <Moment fromNow>{info.created_at}</Moment>{" "}
              </span>
              <span className="a-text-medium-second-neutral-70 text-big h3-md ">
                {info.description}
              </span>
            </div>
          </div>
        </div>
      );
      break;
    case "replay":
      return (
        <div className=" h-a d-flex flex-row br-2 mb-4 mb-md-5 mb-lg-3  py-md-2 py-2">
          <div className="pr-3  d-flex ">
            <div
              className={`d-flex justify-content-start  align-items-center    mt-md-1 mt-lg-1 `}
            >
              <img
                src={`https://images.weserv.nl/?url=${info.photo_link}&maxage=31d`}
                alt="icon"
                className="icon-modal-user sidebar-community__icon    align-self-start"
              />
            </div>

            <div className=" pt-1 d-flex flex-column pl-4">
              <span className="a-text-regular-first-neutral-100 text-big h3-md ">
                {info.ResponseName} - <Moment fromNow>{info.created_at}</Moment>{" "}
              </span>
              <span className="a-text-medium-second-neutral-70 text-big h3-md ">
                {info.description}
              </span>
            </div>
          </div>
        </div>
      );
      break;
  }
}
