import React from 'react';
import { useMediaQuery, Grid } from '@material-ui/core/';
import FormProfile from '../../../../components/organisms/Form/FormProfile';
import Header from '../../../../components/molecules/Header';

export default function UpdateProfile(e) {
	const matchIpadPro = useMediaQuery('(min-width: 992px)');
	const matchIpadMini = useMediaQuery('(min-width: 768px)');

	return (
		<Grid
			container
			justify='center'
			spacing={0}
			className=''
		>
			{matchIpadMini ? (
				<Header
					className={` ${matchIpadPro ? 'mt-0' : 'mt-2'} `}
					isCollapsed={e.isCollapsed}
					link='/cid/company-search'
					text='Buscar empresa'
				/>
			) : (
				<> </>
			)}
			<Grid
				item
				xs={12}
				lg={12}
				className='background-lg-first-neutral-10 h-100vh-header scroll-overflow br-2'
			>
				<div className={`br-2 ${matchIpadPro ? 'm-lg-4' : 'm-lg-4 p-lg-4'}`}>
					<FormProfile userId={e.userId} />
				</div>
			</Grid>
		</Grid>
	);
}
