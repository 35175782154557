/**Consumo de endpoint de CRUD de Empresas y Minutas, así como consulta de sedes, tecnologías,
 * mercados y creación de OnePage de las empresas.
 */

import { BASE_PATH, PDF_PATH } from "./config";
import axios from "axios";

export function getSedes(userId) {
  return axios
    .get(`${BASE_PATH}/get-sedes?userId=${userId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getTechnology(userId) {
  return axios.get(`${BASE_PATH}/get-technology?userId=${userId}`);
}

export function getSubtechnology(techId) {
  let subtechString = "";

  if (techId > 0) {
    subtechString = `&technologyId=${techId}`;
  }
  return axios.get(`${BASE_PATH}/get-subtechnology?${subtechString}`);
}

export function getIndustrias(sectorId) {
  let sectorString = "";

  if (sectorId > 0) {
    sectorString = `&sectorId=${sectorId}`;
  }

  return axios.get(`${BASE_PATH}/get-industrias?${sectorString}`);
}

export function getMercados(userId) {
  return axios.get(`${BASE_PATH}/get-mercados?userId=${userId}`);
}

export function getSectors(userId) {
  return axios.get(`${BASE_PATH}/get-sectors?userId=${userId}`);
}

export function getSizes(userId) {
  return axios
    .get(`${BASE_PATH}/get-sizes?userId=${userId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

//Founders
export function getFounders(company_id, founder_id) {
  return axios
    .get(
      `${BASE_PATH}/get-founders?company_id=${company_id}&founder_id=${founder_id}`,
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("HUBO ERROR");
      return err;
    });
}

export function getInvestors(userId, companyId) {
  return axios.get(
    `${BASE_PATH}/get-investors?userId=${userId}&companyId=${companyId}`,
  );
}

//CRUD empresas
export function creteCompanyApi(data) {
  return axios.post(`${BASE_PATH}/create-company`, data);
}

// integando data de companies
export function getCompanies(userId) {
  return axios
    .get(`${BASE_PATH}/get-companies?userId=${userId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getCompaniesV2(currentPage, filter, sort) {
  return axios
    .get(
      `${BASE_PATH}/get-companiesv2` +
        `?pagination=` +
        currentPage +
        `&filter=` +
        JSON.stringify(filter) +
        `&sort=` +
        sort,
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getCompany(userId, companyId) {
  return axios
    .get(
      `${BASE_PATH}/get-company?userId=${userId}&companyId=${companyId}&view=1`,
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function deleteCompanyApi(userId, companyId) {
  return axios
    .delete(`${BASE_PATH}/delete-company`, {
      data: {
        userId,
        companyId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function updateCompanyApi(data) {
  return axios
    .put(`${BASE_PATH}/update-company`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getCompaniesReport(ids) {
  return axios.post(`${BASE_PATH}/get-report-filter`, { company_id: ids });
}

export function getPagination(page) {
  return axios.get(`${BASE_PATH}/get-companies?pagination=${page}`);
}

export function getPagination2() {
  return axios.get(`${BASE_PATH}/get-companies2?pagination`);
}

export function getValidationUrl(urls) {
  return axios.post(`${BASE_PATH}/get-ValidationUrl`, urls);
}

export function getOnePage(companyId, companyName) {
  return axios
    .get(
      `${PDF_PATH}/get-one-page/?companyId=${companyId}&empresa=${companyName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getOnePagePDF(companyId, companyName) {
  return axios
    .get(
      `${PDF_PATH}/get-one-page-pdf/?companyId=${companyId}&empresa=${companyName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getOnePagePresentacion(companyId, companyName) {
  return axios
    .get(
      `${PDF_PATH}/get-one-page-presentacion?companyId=${companyId}&empresa=${companyName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
}

export function getOnePagesReport(company_id) {
  return axios.get(
    `${PDF_PATH}/get-one-pages-report?company_id=(${company_id})`,
  );
}

export function getTrends(userId) {
  return axios.get(`${BASE_PATH}/get-popular?userId=${userId}`);
}

// CRUD Minutas

export function createMinuta(data) {
  return axios.post(`${BASE_PATH}/crear-minuta`, data);
}

export function getMinuta(userId, companyId) {
  return axios.get(
    `${BASE_PATH}/get-minuta?userId=${userId}&companyId=${companyId}`,
  );
}

export function getHashtags(companyId) {
  return axios.get(`${BASE_PATH}/get-hashtags?companyId=${companyId}`);
}

export function getMinutaById(userId, minutaId) {
  return axios.get(
    `${BASE_PATH}/get-minuta-id?userId=${userId}&minutaId=${minutaId}`,
  );
}

export function updateMinuta(data) {
  return axios.post(`${BASE_PATH}/update-minuta`, data);
}

export function deleteMinutaApi(userId, minutaId) {
  return axios.post(`${BASE_PATH}/delete-minuta`, {
    userId,
    minutaId,
  });
}

export function getAllCompaniesForCSV() {
  return axios.get(`${BASE_PATH}/get-reports-all`);
}
