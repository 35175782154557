import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export function AutoexpansibleInput({ text, placeholder, setText, warning, maxLength }) {
  const useStyles = makeStyles({
    input: {
      fontFamily: "Roboto-Medium",
      "&::placeholder": {
        color: "#8b8f8b",
        fontFamily: "Roboto-Medium",
        opacity: 0.7,
      },
    },
  });



  const classes = useStyles();
  return (
    <>
      <TextField
        className={`input text-normal a-text-medium-first-neutral-80 
            ${warning ? "input-error" : ""}
            `}
        style={{
          minWidth: `calc(${100}% - 10px)`,
          maxWidth: `100%`,
          minHeight: `25px`,
          paddingInlineStart: "8px",
          paddingBlockStart: "4px",
          fontFamily: "Roboto-Medium",
        }}
        inputProps={{
          maxLength: maxLength,
        }}
        multiline
        maxrows={4}
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={placeholder}
        InputProps={{
          classes: { input: classes.input },
          disableUnderline: true,
        }} />
      {warning ? <p className="mt-2 a-text-regular-alert-60">{warning}</p> : ""}
    </>
  );
}
