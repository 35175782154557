import React from "react";

import { useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { useAuth } from "../../../hooks/useAuth";

import { Button } from "../../atoms/Button/index";
import AlertIcon from "../../../assets/img/questions/alert2.svg";
import SkeletonTopicQuestion from "../Skeleton/SkeletonQuestions/SkeletonTopicQuestion";
import { deleteBlog } from "../../../api/blogs";

// object es el id del objeto a borrar
// deleteObject es la función para borrar en la db
// type es el tipo de elemento a borrar
// message es el mensaje a mostar en el modal
// los open y setOpen son para el modalSuccess

export function WarningModal({
  open,
  setOpen,
  message,
  type,
  deleteObject,
  object,
}) {
  const matchIpadMini = useMediaQuery("(min-width: 768px)");

  const user = useAuth();
  const { userId } = user;

  const closeModal = () => {
    setOpen(false);
  };

  const acceptAction = () => {
    if (type == "question") {
      setOpen(false);
      deleteObject(object);
    } else {
      setOpen(false);
      deleteObject(userId, object);
    }
  };

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center p-0">
        <img
          src={AlertIcon}
          alt="icon"
          className="align-self-center mb-2 mt-3"
        />
        <p
          className={`align-self-center text-center a-text-medium-second-neutral-70 text-normal mx-3 pt-3 w-100 ${
            matchIpadMini ? "text-big pb-6" : "text-normal mb-3"
          }`}
        >
          {message}
        </p>

        <div className="d-flex mx-auto mb-3">
          <Button
            onclick={acceptAction}
            type="button"
            classes={`a-text-medium-first-primary-60 a-button-topic-gray-2 mr-2 ${
              matchIpadMini ? "text-normal" : "text-small"
            }`}
            height={33}
            width={matchIpadMini ? 147 : 98}
            text="Aceptar"
          />

          <Button
            type="button"
            classes={` a-button-primary1 ml-2 ${
              matchIpadMini ? "text-normal" : "text-small"
            }`}
            height={36}
            width={matchIpadMini ? 147 : 98}
            text="Cancelar"
            onclick={closeModal}
          />
        </div>
      </div>
    </>
  );
}
