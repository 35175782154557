import React, { useCallback } from "react";
import { useAudioPosition } from "react-use-audio-player";

import {
  useMediaQuery
} from '@material-ui/core'

import LinearProgress from "@material-ui/core/LinearProgress";

export const ProgressBar = () => {
  const { percentComplete, duration, seek } = useAudioPosition({
    highRefreshRate: true,
  });

  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${percentComplete}%, #23a2e7), color-stop(${percentComplete}%, #FFFF))
  `;

  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");

  const toTime = (value) => {
    var hours   = Math.floor(value/ 3600).toString();
    var minutes = Math.floor((value - (hours * 3600)) / 60).toString();
    var seconds = Math.ceil((value - (hours * 3600) - (minutes * 60))).toString();
  
    if (hours   < 10) {hours   = "0"+ hours;}
    if (minutes < 10) {minutes = "0"+ minutes;}
    if (seconds < 10) {seconds = "0"+ seconds;}
  
    let time = `${hours != '00' ? hours + ":": ""}${ minutes + ":"}${seconds != '00' ? seconds : ""}`
    
  
    return time
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className={ipadMiniMatch ? 'd-flex align-items-center a-text-regular-first-neutral-10 mr-2 ml-2' :  'd-none'}>
          {percentComplete == 0 ? "00:00" : toTime((percentComplete*duration/100))}
        </div>
        <div className={ipadMiniMatch ? "w-80 d-flex align-items-end": "w-100 mr-1 ml-1 d-flex mt-1"}>
          <input
            type="range"
            value={(percentComplete * duration) / 100}
            step="0.1"
            min="0"
            max={duration}
            className="progress-bar"
            onChange={(e) => {
              console.log(seek(e.target.value));
            }}
            style={{ background: trackStyling }}
          />
        </div>
        <div className={ipadMiniMatch ? 'd-flex align-items-center a-text-regular-first-neutral-10 mr-2 ml-2' :  'd-none'}>
          {toTime(duration)}
        </div>
      </div>
      {/* <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <div className="w-80 d-flex align-items-end">
          <input
            type="range"
            value={(percentComplete * duration) / 100}
            step="0.1"
            min="0"
            max={duration}
            className="progress-bar"
            onChange={(e) => {
              console.log(seek(e.target.value));
            }}
            style={{ background: trackStyling }}
          />
        </div>
        <div className="d-flex justify-content-between w-80">
          <div className="d-flex align-items-center a-text-regular-first-neutral-100 mr-2 ml-2">
            {percentComplete == 0 ? "00:00" : toTime((percentComplete*duration/100))}
          </div>
          <div className="d-flex align-items-center a-text-regular-first-neutral-100 mr-2 ml-2">
            {toTime(duration)}
          </div>
        </div>
      </div> */}
    </>
  );
};
