// React
import React from "react";
import { Redirect } from "react-router-dom";

// Material
import { Grid } from '@material-ui/core/';

// Hooks
import { useAuth } from "../../../../hooks/useAuth";

// Componentes
import { FormUpdateReport } from "../../../../components/organisms/FormUpdateReport";
import Header from "../../../../components/molecules/Header";

export default function UpdateReport(e) {
    // State
    const user = useAuth();
    const { userId } = user;
    const reportId = e.match.params.id;
    if (!user) {
        return (
            <Redirect to="/" />
        )
    }
    if (user.rolId == 3) {
        return <Redirect to='/cid/carso/home' />
    }

    return (
        <Grid container justify="center" spacing={0} className=''>
            <Header isCollapsed={e.isCollapsed} link='/cid/analisis-search' text='Buscar análisis' />
            <Grid item xs={10} lg={12} className="background-lg-first-neutral-10 h-100vh-header scroll-overflow br-2">
                <div className="background-lg-white mt-4 m-lg-4 p-lg-4 br-2">
                    <FormUpdateReport userId={userId} reportId={reportId} />
                </div>
            </Grid>
        </Grid>
    )
}