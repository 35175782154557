import { Grid } from "@material-ui/core";
import { Error500connection } from "../../Error/500/index.js";

export function Error() {
  return (
    <div className=" background-white br-2 ">
      <Grid
        container
        spacing={0}
        className="background-lg-white br-2 py-4 pr-4 my-4 pl-form"
      >
        <Error500connection />
      </Grid>
    </div>
  );
}
