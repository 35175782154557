import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import Tag from "../../../../assets/img/blogs/etiqueta.svg";
import Play from "../../../../assets/img/icon/PlayIcon.svg";
import Compartir from "../../../../assets/img/icon/compartir.svg";
import TrashIconOcean from "../../../../assets/img/trash-icon-ocean.svg";
import Megusta from "../../../../assets/img/questions/megusta.svg";
import Save from "../../../../assets/img/news/guardar-1.svg";

import { Grid, useMediaQuery, IconButton } from "@material-ui/core";
import { PodcastContext } from "../../../../providers/PodcastContext";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";

export const CardEpisode = ({ data, posterId, setOpenWarning, setObjectToDelete}) => {
  const { setPodcast, setActive } = useContext(PodcastContext);
  let history = useHistory();
  const ipadProUse = useMediaQuery("(min-width:992px)");
  const user = useAuth();
  const { userId } = user;

  const [duration, setDuration] = useState();

  getSound(data.audio_podcast)

  const handleOnClick = () => {
    history.push({
      pathname: "/cid/carso/podcast/list/episode/" + data.podcast_id,
      state: { episode: data},
    });
  }

  function handleClickDelete() {
    setOpenWarning((s) => !s);
    setObjectToDelete(data.podcast_id);
  }

    const toTime = (value) => {
    var hours = Math.floor(value / 3600).toString();
    var minutes = Math.floor((value - hours * 3600) / 60).toString();
    var seconds = Math.ceil(value - hours * 3600 - minutes * 60).toString();

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    let time = `${hours != "00" ? hours + ":" : ""}${minutes + ":"}${
      seconds != "00" ? seconds : ""
    }`;

    return time;
  };

  function getSound(file) {
    var audio = new Audio(file);
    audio.addEventListener("loadeddata", () => {
      let duration = audio.duration;
      setDuration(toTime(duration));
    });
  }

  return (
    <>
    {
      ipadProUse ? (
        <div
          className={` mb-2 mr-1 ml-1 h-a br-2 px-3  box-shadow-lg-all card  }`}
        >
          <div className=" w-100">
            <div className={`d-flex justify-content-between`}>
              <div 
                className="d-flex flex-column justify-content-evenly w-75" 
              >
                <div
                  className={`pr-3 card-blog-height"
                        }  w-100`}
                >
                  <div className="a-text-medium-second-neutral-70 text-normal w-100 cursor-pointer" onClick={handleOnClick} > 
                    <p> {data.titleEp} </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll mt-1 align-items-center">
                  <img
                    src={Tag}
                    alt="icon"
                    className={`tag-icon mr-1 ${
                      data.labelsEp == undefined ? "d-none" : ""
                    }`}
                  />
                  {data.labelsEp?.length != 0
                    ? data.labelsEp?.map((label, i) => (
                        <p
                          className="a-text-regular-first-primary-60 text-xs"
                          key={i}
                        >
                          {label.name}
                          {i < data.labelsEp.length - 1 ? ", " : ""}{" "}
                        </p>
                      ))
                    : ""}
                </div>
                <div className="w-100 d-flex">
                        <p
                          className="a-text-regular-first-primary-60 text-small"
                        >
                          {data.descriptionEp?.substr(0, 150)}{"..."}
                        </p>
                </div>
              </div>
              <div className="mt-1 mb-1 d-flex align-items-center justify-content-end w-25">
                <img
                  src={`https://images.weserv.nl/?url=${data.cover_podcast}&maxage=31d`}
                  alt="icon"
                  className="image-podcast-list-vertical-episode cursor-pointer"
                  onClick={handleOnClick}
                />
              </div>
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    setPodcast(data);
                    setActive(true);
                  }}
                  className={"cursor-pointer"}
                >
                  <img src={Play} alt="Play" width="28px" />
                </IconButton>
                <p className="ml-1 text-small a-text-bold-first-neutral-100 ">{duration}</p>
              </div>
                <div>
                  <IconButton className={"cursor-pointer"}>
                    <img src={Compartir} alt="Share" width="20px" />
                  </IconButton>
                </div>

              {
                userId == posterId ? (
                  <div onClick={handleClickDelete}>
                    <IconButton className={"cursor-pointer"}>
                      <img src={TrashIconOcean} alt="Opt" width="20px" height="20px" />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <IconButton className={"cursor-pointer"}>
                      <img src={Save} alt="Opt" width="18px" height="18px"/>
                    </IconButton>
                  </div>
                )
              }


            </div>
          </div>
        </div>
      ) : (
        
        <div
          className={` mb-2 mr-1 ml-1 h-a br-2 px-2  box-shadow-lg-all card  py-md-3 py-2}`}
        >
          <div className=" w-100">
            <div className={`d-flex justify-content-between`}>
              <div 
                className="d-flex flex-column justify-content-evenly w-75" 
              >
                <div
                  className={`pt-2 pr-3 card-blog-height"
                        }  w-100`}
                >
                  <div className="a-text-medium-second-neutral-70 text-small w-100 cursor-pointer" onClick={handleOnClick}>
                    <p> {data.titleEp} </p>
                  </div>
                </div>
                <div className="d-flex overflow-scroll mt-1 align-items-center">
                  <img
                    src={Tag}
                    alt="icon"
                    className={`tag-icon mr-1 ${
                      data.labelsEp == undefined ? "d-none" : ""
                    }`}
                  />
                  {data.labelsEp?.length != 0
                    ? data.labelsEp?.map((label, i) => (
                        <p
                          className="a-text-regular-first-primary-60 text-xxs"
                          key={i}
                        >
                          {label.name}
                          {i < data.labelsEp.length - 1 ? ", " : ""}{" "}
                        </p>
                      ))
                    : ""}
                </div>
                <div className="w-100 d-flex">
                        <p
                          className="a-text-regular-first-primary-60 text-xs"
                        >
                          {data.descriptionEp?.substr(0, 70)}{"..."}
                        </p>
                </div>
                {/* <div className="d-flex flex-wrap scroll-overflow align-items-center mb-2">
                  {data.labelsEp.map((d, i) => (
                    <div className={`mr-2 align-items-center d-flex flex-wrap`} key={i}>
                      <label
                        className={`switch-question-check text-lg-normal  a-text-regular-first-primary-60 mr-2  py-1 px-2   py-md-2  px-md-3 py-lg-1 br-4  box-shadow-none mb-0-imp filterChecked`}
                      >
                        <p className={`${"text-small text-lg-normal"}`}>{d}</p>
                      </label>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className="mt-1 mb-1 d-flex align-items-center justify-content-end w-25" >
                <img
                  src={`https://images.weserv.nl/?url=${data.cover_podcast}&maxage=31d`}
                  alt="icon"
                  className="image-podcast-list-vertical-episode cursor-pointer"
                  onClick={handleOnClick}
                />
              </div>
            </div>
          </div>
          <div className="w-100 mt-1">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <IconButton
                  onClick={() => {
                    setPodcast(data);
                    setActive(true);
                  }}
                  className={"cursor-pointer"}
                  size="small"
                >
                  <img src={Play} alt="Play" width="22px" />
                </IconButton>
                <p className="ml-1 text-xs a-text-bold-first-neutral-100 ">{duration}</p>
              </div>
      
              <IconButton className={"cursor-pointer"} size="small">
                <img src={Compartir} alt="Share" width="16px" />
              </IconButton>
              {
                userId == posterId ? (
                  <IconButton className={"cursor-pointer"} size="small" onclick={handleClickDelete}>
                    <img src={TrashIconOcean} alt="Opt" width="18px" height="18px" />
                  </IconButton>
                ) : (
                  <IconButton className={"cursor-pointer"} size="small">
                    <img src={Save} alt="Opt" width="16px" height="16px"/>
                  </IconButton>
                )
              }

            </div>
          </div>
        </div>
      )
    }
    </>
  );
};
