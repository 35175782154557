import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Modal, useMediaQuery, IconButton } from '@material-ui/core';
import Header from '../../../components/molecules/Header';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ModalSuccess from '../../../components/molecules/Modal/SuccessModal';
import { WarningModal } from '../../../components/molecules/Modal/WarningModal';
import { CommentModal } from '../../../components/molecules/Modal/CommetModal';
import Moment from 'react-moment';
import ProfileComponent from '../../../components/molecules/Card/ProfileComponent/ProfileDetailBlog';
import Close from '../../../assets/img/closeFilter.svg';
import Clock from '../../../assets/img/blogs/reloj.svg';
import Tag from '../../../assets/img/blogs/etiqueta.svg';
import PencilIconOcean from '../../../assets/img/pencil-icon-ocean.svg';
import userIcon from '../../../assets/img/user-icon.svg';
import Compartir from '../../../assets/img/icon/compartir.svg';
import Copy from '../../../assets/img/news/copiar-enlace.svg';
import Correo from '../../../assets/img/social/correo.svg';
import Whats from '../../../assets/img/social/whats.svg';
import Comentario from '../../../assets/img/questions/comentario.svg';
import LikeComponent from '../../../components/molecules/Card/CardQuestion/LikeComponent';
import { getBlogComments, getBlogById, deleteComment } from '../../../api/blogs';
import { useAuth } from '../../../hooks/useAuth';
import { CardComment } from '../../../components/molecules/Card/CardBlog/CardComment';
import { UpdateComment } from '../../../components/molecules/Modal/UpdateComment';

export default function BlogsDetail(e) {
	/*
    En este componente se utiliza la información que mandamos mediante un push al dar click sobre cada card del blog
    recuperamos los labels y el blog para poder llenar los elementos dentro de este componente
    data es nuestro blog y labels son los labels del usuario
  */
	let history = useHistory();
	const location = useLocation();
	const user = useAuth();
	const [data, setData] = useState([]);
	const [labels, setLabels] = useState([]);
	const [loadlabels, setLoadLabels] = useState(true);
	const [isShare, setIsShare] = useState(false);
	const [comments, setComments] = useState();
	const [responseModal, setResponseModal] = useState(false);
	const [change, setChange] = useState(false);
	const [openSuccess, setOpenSuccess] = useState(false);
	const [loadDelete, setLoadDelete] = useState(true);
	const [openWarning, setOpenWarning] = useState(false);
	const [comment, setComment] = useState();
	const [commentEdit, setCommentEdit] = useState(false);
	const ipadProUse = useMediaQuery('(min-width:992px)');

	// Se quiere acceder al blog directo desde el URL, pero como estamos usando useHistory
	// tenemos problemas, por eso cuando el history no tenga state, vamos a hacer una petición de dicho blog
	// para mostrarlo

	console.log('This is location', location.state);
	const url = window.location.href;
	const blogIDRegex = /\d+$/;
	const blogID = url.match(blogIDRegex);
	const { userId: userID } = user;

	useEffect(() => {
		setLoadLabels(true);

		if (!!location.state) {
			console.log('Sí trae blog');
			setData(location.state.blog);
			setLabels(location.state.blog.labels);
		} else {
			console.log('No trae blog');
			getBlogForThisEntry(blogID);
		}
		getAllComments(blogID);
		setLoadLabels(false);
	}, []);

	useEffect(() => {
		getAllComments(blogID);
	}, [change]);

	async function getBlogForThisEntry(blogID) {
		try {
			const response = await getBlogById(blogID);
			setData(response.data.blog[0]);
			setLabels(response.data.blog[0].labels);
		} catch (error) {
			console.log(error);
		}
	}

	async function getAllComments(blogID) {
		try {
			const response = await getBlogComments(blogID);
			console.log('así llegan los comentarios', response);
			setComments(response.data.CommentsBlogs);
		} catch (error) {
			console.log(error);
		}
	}

	function copyToClipboard(blog_id) {
		const url = window.location.href;
		navigator.clipboard.writeText(url);
		console.log('this is on the clipboard', url);
	}

	function handleDelete() {
		setOpenWarning(true);
	}

	async function deleteObject(userId, commentId) {
		try {
			setOpenSuccess(true);
			const deleted = await deleteComment(userId, commentId);
			setChange(!change);

			if (deleted.status == 200) {
				setLoadDelete(false);
				setTimeout(() => {
					setOpenSuccess(false);
				}, 500);
			}
			setComment();
		} catch (err) {
			console.log(err);
			setComment();
		}
	}

	return (
		<Grid
			container
			justify='center'
			spacing={0}
			className=''
		>
			{ipadProUse ? (
				<Header
					isCollapsed={e.isCollapsed}
					link=''
					text='Buscar empresa'
				/>
			) : (
				<div />
			)}

			<div
				className={`${
					ipadProUse
						? 'd-none'
						: 'w-100 d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-1 mx-md-5 mx-4 py-3 mt-2 '
				}`}
			>
				{' '}
				<p className='h3-md text-big a-text-medium-first-primary-60'>Entrada del blog</p>{' '}
				<Link to={'/cid/carso/blogs/list'}>
					{' '}
					<IconButton>
						<img
							src={Close}
							alt='PDF Icon'
							className='cursor-pointer '
							width='24px'
						/>
					</IconButton>
				</Link>
			</div>

			<Grid
				item
				xs={10}
				lg={12}
				className='background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2'
			>
				<div className='background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all '>
					{ipadProUse ? (
						<div className='d-flex justify-content-between align-items-center mb-4 '>
							{' '}
							<p className='h3-md text-big a-text-medium-first-primary-60'>
								{data.work_in_progress == 1 ? 'Borrador' : 'Entrada de blog'}
							</p>{' '}
							<Link to={'/cid/carso/blogs/list'}>
								{' '}
								<IconButton>
									<img
										src={Close}
										alt='PDF Icon'
										className='cursor-pointer '
										width='24px'
									/>
								</IconButton>
							</Link>
						</div>
					) : (
						<div />
					)}

					<div className='w-100 d-flex mt-4 justify-content-center'>
						<span className='a-text-medium-second-neutral-70 text-normal h3-md '>{data.title}</span>
					</div>
					{ipadProUse ? (
						<div className='w-100 d-flex mt-2  justify-content-md-center '>
							<div className='d-flex justify-content-start  align-items-center '>
								<img
									// src={
									//   data.photo_link !== null
									//     ? `https://images.weserv.nl/?url=${data.photo_link}&maxage=31d`
									//     : userIcon
									// }
									src={
										data.photo_link == null || data.photo_link == 'undefined'
											? userIcon
											: `https://images.weserv.nl/?url=${data.photo_link}&maxage=31d`
									}
									alt='icon'
									// className="icon-card-poper mr-2 "
									className='icon-card-question icon-md-card-question  mr-2 align-self-start br-3'
								/>
							</div>
							{loadlabels ? (
								<div />
							) : (
								<ProfileComponent
									userId={userID}
									type={'onlyProfile'}
									info={data}
								/>
							)}
							<div className='d-flex ml-3 align-items-center'>
								<img
									src={Clock}
									alt='icon'
									className='icon-blog-clock-detail mr-1'
								/>
								<span className='a-text-regular-first-neutral-100 text-small text-md-normal '>
									<Moment format='DD/MM/YYYY'>{data.created_at}</Moment>
								</span>
							</div>
							<div className='d-flex ml-3 align-items-center'>
								<img
									height={13}
									width={13}
									src={Tag}
									alt='icon'
									// className={`${loadlabels ?  'd-none' : `${labels[0]?.name == "" ? 'd-none ': 'mr-2 '}` }`}
									className={`tag-icon mr-1 ml-1  ${
										labels?.length !== 0 && labels[0].name !== 'undefined' && labels[0].name !== ''
											? ''
											: 'd-none'
									}`}
								/>
								<>
									{labels.length === 0 ? (
										<div />
									) : (
										<>
											{labels.map((res, idx) =>
												res.name !== 'undefined' ? (
													<span
														// className="text-xs a-text-regular-first-neutral-100 mr-1 "
														className='a-text-regular-first-neutral-100 text-small text-md-normal mr-1'
														key={idx}
													>
														{` ${res.name} `}{' '}
														{labels.length > 1 && idx != labels.length - 1 ? ', ' : ''}
													</span>
												) : (
													<span></span>
												)
											)}
										</>
									)}
								</>
							</div>
						</div>
					) : (
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<div className='d-flex justify-content-start  align-items-center mt-1'>
								<img
									// src={
									//   data.photo_link !== null
									//     ? `https://images.weserv.nl/?url=${data.photo_link}&maxage=31d`
									//     : userIcon
									// }
									src={
										data.photo_link == null || data.photo_link == 'undefined'
											? userIcon
											: `https://images.weserv.nl/?url=${data.photo_link}&maxage=31d`
									}
									alt='icon'
									// className="icon-card-poper mr-2 "
									className='icon-detail-user  mr-2 align-self-start br-3'
								/>
								<ProfileComponent
									userId={userID}
									type={'onlyProfile'}
									info={data}
								/>
							</div>
							<div className='d-flex text-xs text-small-md align-items-center mt-1'>
								<img
									height={13}
									width={13}
									src={Tag}
									alt='icon'
									// className={`${loadlabels ?  'd-none' : `${labels[0]?.name == "" ? 'd-none ': 'mr-2 '}` }`}
									className={`tag-icon mr-1 ml-1  ${
										labels?.length !== 0 && labels[0].name !== 'undefined' && labels[0].name !== ''
											? ''
											: 'd-none'
									}`}
								/>
								{labels.map((res, idx) =>
									res.name !== 'undefined' ? (
										<span
											// className="text-xs a-text-regular-first-neutral-100 mr-1 "
											className='a-text-regular-first-neutral-100 text-small text-md-normal mr-1'
											key={idx}
										>
											{` ${res.name} `}{' '}
											{labels.length > 1 && idx != labels.length - 1 ? ', ' : ''}
										</span>
									) : (
										<span></span>
									)
								)}
							</div>
						</div>
					)}
					<div
						className={`w-100  mb-2 mt-2 ${
							loadlabels
								? 'd-none'
								: `${
										userID !== data.user_id
											? 'd-none asas'
											: 'd-flex justify-content-center align-items-center'
								  }`
						} `}
						onClick={() => {
							history.push({
								pathname: '/cid/carso/blog/editar/' + data.blog_id,
								// state:{blog:data,tags:labels}
								state: { blog: data },
							});
						}}
					>
						<IconButton>
							<img
								src={PencilIconOcean}
								alt='icon'
								className='icon-blog-clock-detail mr-1 cursor-pointer'
							/>
						</IconButton>

						<span className='a-text-regular-first-primary-70 text-small text-md-normal cursor-pointer '>
							Editar
						</span>
					</div>

					{/* En esta línea se muestra el contenido del blog */}
					<div
						className='blog-detail-container mt-3'
						dangerouslySetInnerHTML={{ __html: data.description }}
					></div>
					{data.work_in_progress != 1 ? (
						<>
							{/* Sección final del blog */}
							<div className='mt-7'>
								<div className='a-text-regular-first-neutral-100 text-small text-md-normal'>
									¿Te gustó el blog?
								</div>
								<hr className='hr-gray mt-3 w-50' />
							</div>
							<div className='d-flex'>
								<div className='d-flex justify-content-between'>
									<IconButton
										onClick={() => setIsShare(true)}
										className={isShare ? 'd-none' : 'd-flex'}
									>
										<img
											src={Compartir}
											alt='Compartir'
											className='cursor-pointer'
											width='24px'
										/>
									</IconButton>

									<div
										className={!isShare ? 'd-none' : 'd-flex align-items-center'}
										onClick={() => setIsShare(false)}
									>
										<IconButton>
											<img
												src={Compartir}
												alt='Compartir'
												className='cursor-pointer'
												width='24px'
											/>
										</IconButton>

										<a
											href={`https://api.whatsapp.com/send?text=*${data.title}*%0A🌐%20${window.location.href}`}
											target='_blank'
											rel='noreferrer noopener'
										>
											<button className='d-flex btn-in'>
												<img
													src={Whats}
													width={`20px`}
													alt='Compartir'
													className='cursor-pointer mr-2'
												/>
											</button>
										</a>
										<a
											href={`mailto:?subject=Blog: &body=Blog:%20${window.location.href}%0D`}
											target='_blank'
											rel='noreferrer noopener'
										>
											<button className='d-flex btn-in'>
												<img
													src={Correo}
													alt='Compartir'
													className='cursor-pointer mr-2'
												/>
											</button>
										</a>

										<button
											className='d-flex btn-in'
											onClick={() => {
												copyToClipboard(data.blog_id);
											}}
										>
											<img
												src={Copy}
												id='f'
												alt='Copiar'
												width={`20px`}
												className='cursor-pointer mr-2 pdf-img'
											/>
										</button>
									</div>
								</div>

								{/* Botón de like */}
								<div className={'d-flex justify-content-between ml-3'}>
									<LikeComponent
										blog={blogID}
										userId={userID}
										type={'blog'}
									/>
								</div>

								{/* Botón de comentar */}
								<div className={'d-flex justify-content-between ml-3'}>
									<IconButton
										onClick={() => {
											setResponseModal(true);
										}}
									>
										<img
											src={Comentario}
											alt='Comentario'
											className='cursor-pointer'
											width='24px'
										/>
									</IconButton>
									<span className='d-flex align-items-center a-text-regular-first-primary-70 text-normal'>
										{`${
											comments?.length === 0
												? ''
												: `${comments?.length !== undefined ? comments?.length : ''}`
										}`}
									</span>
								</div>
							</div>
							<div className='mt-3'>
								{comments?.length !== 0 ? (
									<>
										<div className='a-text-regular-first-neutral-100 text-small mb-3'>
											Comentarios
										</div>
										{comments?.map((res, idx) => (
											<CardComment
												res={res}
												setChange={setChange}
												change={change}
												setOpenWarning={setOpenWarning}
												openWarning={openWarning}
												deleteObject={deleteObject}
												setComment={setComment}
												setCommentEdit={setCommentEdit}
												commentEdit={commentEdit}
											/>
										))}
									</>
								) : (
									''
								)}
							</div>
						</>
					) : (
						''
					)}

					{/* Botón de compartir */}

					{/* Aquí se empiezan a mostrar los comentarios */}
				</div>
				<Modal
					open={responseModal}
					onClose={false}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
				>
					<div
						className={`${
							ipadProUse
								? ' modal-md-width modal-desk  height-modal d-flex flex-column '
								: 'modal-response d-flex flex-column py-3   '
						}`}
					>
						<CommentModal
							setOpen={setResponseModal}
							blog={data}
							setChange={setChange}
							change={change}
							type={'blog'}
						/>
					</div>
				</Modal>
				<Modal
					open={commentEdit}
					onClose={false}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
				>
					<div
						className={`${
							ipadProUse
								? ' modal-md-width modal-desk  height-modal d-flex flex-column '
								: 'modal-response d-flex flex-column py-3   '
						}`}
					>
						<UpdateComment
							setOpen={setCommentEdit}
							blog={data}
							commentary={comment}
							setChange={setChange}
							change={change}
							type={'blog'}
						/>
					</div>
				</Modal>
				<Modal
					open={openSuccess}
					onClose={false}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
				>
					<div className='modal'>
						<ModalSuccess
							open={openSuccess}
							setOpen={setOpenSuccess}
							message={`${loadDelete ? 'Espera un momento...' : 'Tu comentario se ha eliminado'}`}
							action={'Timmer'}
						/>
					</div>
				</Modal>
				<Modal
					open={openWarning}
					onClose={false}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
				>
					<div className='modal'>
						<WarningModal
							message={'Tu comentario será eliminado'}
							open={openWarning}
							setOpen={setOpenWarning}
							setOpenSuccess={setOpenSuccess}
							openSuccess={openSuccess}
							deleteObject={deleteObject}
							object={comment}
						/>
					</div>
				</Modal>
			</Grid>
		</Grid>
	);
}
