import React from 'react';
import { Skeleton } from '@material-ui/lab';

const index = () => {
    return (
        <div>
      <Skeleton
        variant="rect"
        width={120}
        height={15}
        animation="wave"
        className=""
      />
      <Skeleton
        variant="rect"
        width={100}
        height={30}
        animation="wave"
        className="br-3 mt-3 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={285}
        height={120}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />
      {/* Boton */}
      <Skeleton
        variant="rect"
        width={215}
        height={30}
        animation="wave"
        className="my-3 br-1"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      {/* Mercados */}
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <div className="d-flex flex-wrap w-50">
        <Skeleton
          variant="rect"
          width={100}
          height={20}
          animation="wave"
          className="br-3 my-1 mr-2"
        />
        <Skeleton
          variant="rect"
          width={100}
          height={20}
          animation="wave"
          className="br-3 my-1 mr-2"
        />
        <Skeleton
          variant="rect"
          width={100}
          height={20}
          animation="wave"
          className="br-3 my-1 mr-2"
        />
        <Skeleton
          variant="rect"
          width={100}
          height={20}
          animation="wave"
          className="br-3 my-1 mr-2"
        />
        <Skeleton
          variant="rect"
          width={100}
          height={20}
          animation="wave"
          className="br-3 my-1 mr-2"
        />
        <Skeleton
          variant="rect"
          width={100}
          height={20}
          animation="wave"
          className="br-3 my-1 mr-2"
        />
      </div>
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-3"
      />
      <Skeleton
        variant="rect"
        width={215}
        height={30}
        animation="wave"
        className="my-3 br-1"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mt-5 mb-3"
      />
      {/* Redes Sociales */}
      <div className="d-flex my-2">
        <Skeleton
          variant="rect"
          width={35}
          height={35}
          animation="wave"
          className="br-3 mr-2"
        />
        <Skeleton
          variant="rect"
          width={535}
          height={35}
          animation="wave"
          className="br-1 "
        />
      </div>
      <div className="d-flex my-2">
        <Skeleton
          variant="rect"
          width={35}
          height={35}
          animation="wave"
          className="br-3 mr-2"
        />
        <Skeleton
          variant="rect"
          width={535}
          height={35}
          animation="wave"
          className="br-1 "
        />
      </div>
      <div className="d-flex my-2">
        <Skeleton
          variant="rect"
          width={35}
          height={35}
          animation="wave"
          className="br-3 mr-2"
        />
        <Skeleton
          variant="rect"
          width={535}
          height={35}
          animation="wave"
          className="br-1 "
        />
      </div>
      <div className="d-flex my-2">
        <Skeleton
          variant="rect"
          width={35}
          height={35}
          animation="wave"
          className="br-3 mr-2"
        />
        <Skeleton
          variant="rect"
          width={535}
          height={35}
          animation="wave"
          className="br-1 "
        />
      </div>
      <div className="d-flex my-2">
        <Skeleton
          variant="rect"
          width={35}
          height={35}
          animation="wave"
          className="br-3 mr-2"
        />
        <Skeleton
          variant="rect"
          width={535}
          height={35}
          animation="wave"
          className="br-1 "
        />
      </div>
      {/* Alianzas y Colaboradores */}
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mt-5 mb-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />
      <Skeleton
        variant="rect"
        width={215}
        height={30}
        animation="wave"
        className="my-3 br-1"
      />
      {/* Contacto Cid */}
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mt-5 mb-2"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />
      {/* Capturista */}
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="my-3"
      />
      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />

      {/* Contacto Principal */}

      <Skeleton
        variant="rect"
        width={280}
        height={35}
        animation="wave"
        className="br-1 mt-5"
      />

      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mb-2 mt-4"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mb-2 mt-4"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />

      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mb-2 mt-4"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />

      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mb-2 mt-4"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />
      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mb-2 mt-4"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />

      <Skeleton
        variant="rect"
        width={100}
        height={20}
        animation="wave"
        className="mb-2 mt-4"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="br-1 mb-2"
      />

      <Skeleton
        variant="rect"
        width={215}
        height={30}
        animation="wave"
        className="my-3 br-1"
      />

      <Skeleton
        variant="rect"
        width={580}
        height={35}
        animation="wave"
        className="mt-5 mb-3 br-1"
      />
    </div>
    )
}

export default index
 
