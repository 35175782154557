//Libraries
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  Grid,
  useMediaQuery,
  IconButton,
  Badge,
  Tooltip,
  Modal,
} from "@material-ui/core";


//Context used
import { PodcastContext } from "../../../providers/PodcastContext";

//Components
import Header from "../../../components/molecules/Header";
import ProfileComponent from "../../../components/molecules/Card/ProfileComponent/ProfileDetailBlog";
import { CardProgram } from "../../../components/molecules/Card/CardPodcast/CardProgram";
import { CardEpisode } from "../../../components/molecules/Card/CardPodcast/CardEpisode";
import { WarningModal } from "../../../components/molecules/Modal/WarningModal";
import ModalSuccess from "../../../components/molecules/Modal/SuccessModal";

//Assets
import Close from "../../../assets/img/closeFilter.svg";
import userIcon from "../../../assets/img/user-icon.svg";
import Play from "../../../assets/img/icon/PlayIcon.svg";
import Tag from "../../../assets/img/blogs/etiqueta.svg";
import FilterIconOcean from "../../../assets/img/filtrar.svg";
import OrderIconOcean from "../../../assets/img/order-icon-ocean.svg";
import Compartir from "../../../assets/img/icon/compartir.svg";
import TrashIconOcean from "../../../assets/img/trash-icon-ocean.svg";
import Save from "../../../assets/img/news/guardar-1.svg";
import Add from "../../../assets/img/icon/agregar.svg";

//API
import { deleteEpisode, getProgram } from "../../../api/podcasts";

//Hooks
import { useAuth } from "../../../hooks/useAuth";

export const PodcastProgram = (e) => {
  //Location, history y auth
  const location = useLocation();
  let history = useHistory();
  const user = useAuth();
  const { userId } = user;

  //Context
  const { setPodcast, setActive } = useContext(PodcastContext);

  //Obtener la información del location
  const podcast = location.state.podcast;
  const duration = location.state.duration;

  //Estados filtrado y ordenado
  const [filter, setFilter] = useState(false);
  const [order, setOrder] = useState(false);

  //Estados para obtener la información del programa de la DB
  const [fetchedProgram, setFetchedProgram] = useState();
  const [programEpisodes, setProgramEpisodes] = useState([]);

  //Estados para modales de borrado y exito al borrar
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState();
  const [loadDelete, setLoadDelete] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(true);

  //MediaQuery
  const ipadProUse = useMediaQuery("(min-width:992px)");

  //UseEffect
  useEffect(() => {
    fetchSpecificProgram(podcast.program_podcast_id);
  }, [deleteSuccess]);

  //Async-Awaits para obtener información de la DB
  async function fetchSpecificProgram(program_id) {
    try {
      const response = await getProgram(program_id);
      console.log("Esto es lo que llegó del programa", response.data.program);
      console.log(
        "Estos son los episodios",
        response.data.program[0].episodios
      );
      setFetchedProgram(response.data.program);
      setProgramEpisodes(response.data.program[0].episodios);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteObjectEpisode(userId, podcastId) {
    try {
      setOpenSuccess(true);
      const deleted = await deleteEpisode(userId, podcastId);

      if (deleted.status == 200) {
        setLoadDelete(false);

        setTimeout(() => {
          setOpenSuccess(false);
          setDeleteSuccess(!deleteSuccess);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //handlers
  const handleClickAdd = () => {
    history.push({
      pathname: "/cid/carso/podcast/upload/",
      state: { program: podcast },
    });
  };

  return (
    <Grid container justify="center" spacing={0} className="">
      {ipadProUse ? (
        <Header isCollapsed={e.isCollapsed} link="" text="Buscar podcast" />
      ) : (
        <div />
      )}
      <div
        className={`${
          ipadProUse
            ? "d-none"
            : "w-100 d-flex justify-content-between align-items-center border-bottom-second-Neutral-100  mb-1 mx-md-5 mx-4 py-3 mt-2 "
        }`}
      >
        {" "}
        <p className="h3-md text-big a-text-medium-first-primary-60">
          Podcast
        </p>{" "}
        <Link to={"/cid/carso/podcast/list"}>
          {" "}
          <IconButton>
            <img
              src={Close}
              alt="PDF Icon"
              className="cursor-pointer "
              width="24px"
            />
          </IconButton>
        </Link>
      </div>
      <div
        className={`${
          ipadProUse
            ? "d-none"
            : "w-100 mb-1 mx-md-5 p-3 br-3 mx-4 mt-2 background-prueba"
        }`}
      >
        <div className="w-100 d-flex align-items-center">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <p className="mt-1 a-text-bold-second-neutral-70 text-big h3-md">
                {podcast.title}
              </p>
              <div className="d-flex align-items-center mt-1">
                <div className="d-flex flex-column">
                  <div className="d-flex overflow-scroll mt-1 align-items-center">
                    <img
                      src={Tag}
                      alt="icon"
                      className={`tag-icon mr-1 ml-1  ${
                        podcast.labelsPodcast.length != 0 ? "" : "d-none"
                      }`}
                    />
                    {podcast.labelsPodcast?.map((res, idx) => (
                      <span
                        className="a-text-regular-first-primary-60 text-xs mr-1"
                        key={idx}
                      >
                        {` ${res.name} `}{" "}
                        {podcast.labelsPodcast.length > 1 &&
                        idx != podcast.labelsPodcast.length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </div>
                  <div className="mr-1 d-flex a-text-regular-first-neutral-100 text-xs align-items-center mt-1">
                    <img
                      src={userIcon}
                      alt="icon"
                      className={`person-icon mr-1 ml-1`}
                    />
                    {podcast.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={`https://images.weserv.nl/?url=${podcast.cover}&maxage=31d`}
            alt="icon"
            className="program-cover"
          />
        </div>
        <div className="mt-3 d-flex container-podcast-description text-small align-items-center">
          {podcast.description}
        </div>

        <div className="d-flex mt-2">
          <div className="mr-8">
            <IconButton className={"cursor-pointer"} size="small">
              <img src={Compartir} alt="Share" width="18px" />
            </IconButton>
          </div>

          {userId == podcast.user_id ? (
            <>
              <IconButton className={"cursor-pointer"} size="small">
                <img src={TrashIconOcean} alt="Opt" width="18px" />
              </IconButton>
            </>
          ) : (
            <IconButton className={"cursor-pointer"} size="small">
              <img src={Save} alt="Opt" width="16px" />
            </IconButton>
          )}
        </div>
      </div>
      <Grid
        item
        xs={10}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh-header   scroll-overflow  br-2"
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ">
          {ipadProUse ? (
            <div className="d-flex justify-content-between align-items-center mb-4 ">
              {" "}
              <p className="h3-md text-big a-text-medium-first-primary-60">
                Podcast
              </p>{" "}
              <Link to={"/cid/carso/podcast/list"}>
                {" "}
                <IconButton>
                  <img
                    src={Close}
                    alt="PDF Icon"
                    className="cursor-pointer "
                    width="24px"
                  />
                </IconButton>
              </Link>
            </div>
          ) : (
            <div />
          )}

          <div
            className={`${
              ipadProUse ? "p-3 br-3 background-prueba" : "d-none"
            }`}
          >
            <div className="w-100 d-flex align-items-center">
              <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="d-flex flex-column w-80">
                  <p className="mt-1 a-text-bold-second-neutral-70 text-big-32">
                    {podcast.title}
                  </p>
                  <div className="d-flex w-100 align-items-center mt-1">
                    <div className="d-flex flex-column">
                      <div className="d-flex overflow-scroll mt-1 align-items-center">
                        <img
                          src={Tag}
                          alt="icon"
                          className={`tag-icon mr-1 ml-1  ${
                            podcast.labelsPodcast?.length != 0 ? "" : "d-none"
                          }`}
                        />
                        {podcast.labelsPodcast?.map((res, idx) => (
                          <span
                            className="a-text-regular-first-primary-60 text-small mr-1"
                            key={idx}
                          >
                            {` ${res.name} `}{" "}
                            {podcast.labelsPodcast.length > 1 &&
                            idx != podcast.labelsPodcast.length - 1
                              ? ", "
                              : ""}
                          </span>
                        ))}
                      </div>
                      <div className="d-flex a-text-regular-first-neutral-100 text-small align-items-center mt-1">
                        <img
                          src={userIcon}
                          alt="icon"
                          className={`person-icon mr-1 ml-1`}
                        />
                        {podcast.name}
                      </div>
                      <div className="mt-3 w-100 d-flex container-podcast-description text-normal align-items-center">
                        {podcast.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                src={`https://images.weserv.nl/?url=${podcast.cover}&maxage=31d`}
                alt="icon"
                className="program-cover"
              />
            </div>

            <div className="d-flex mt-2">
              <div className="mr-8">
                <IconButton className={"cursor-pointer"}>
                  <img src={Compartir} alt="Share" width="24px" />
                </IconButton>
              </div>

              {userId == podcast.user_id ? (
                <>
                  <div className="mr-8">
                    <IconButton className={"cursor-pointer mr-8"}>
                      <img src={TrashIconOcean} alt="Opt" width="28px" />
                    </IconButton>
                  </div>

                  <div
                    onClick={() => {
                      handleClickAdd();
                    }}
                  >
                    <Tooltip title="Agregar episodio" placement="right">
                      <IconButton className={"cursor-pointer"}>
                        <img src={Add} alt="Opt" width="28px" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <div className="mr-8">
                  <IconButton className={"cursor-pointer"}>
                    <img src={Save} alt="Share" width="22px" />
                  </IconButton>
                </div>
              )}
            </div>
          </div>

          <div className="mt-3">
            <p className="a-text-medium-first-primary-60 text-normal text-lg-medium">
              Todos los episodios
            </p>
          </div>

          <div
            className={`w-100 justify-content-end d-none ${
              filter || order ? "d-none" : "d-flex"
            } `}
          >
            <div
              onClick={() => setOrder(!order)}
              className="mr-2 d-flex align-items-center filter-button cursor-pointer"
            >
              <IconButton size="small">
                <img src={OrderIconOcean} alt="Order" width="16px" />
              </IconButton>
              <p className="text-small text-lg-normal a-text-regular-first-primary-60 filter-button__text">
                Ordenar
              </p>
            </div>
            <div className="a-text-regular-first-neutral-100 d-flex align-items-center">
              {" "}
              |{" "}
            </div>
            <div
              onClick={() => setFilter(!filter)}
              className="ml-1  d-flex align-items-center filter-button cursor-pointer  "
            >
              <IconButton size="small">
                <img src={FilterIconOcean} alt="Filter" width="16px" />
              </IconButton>
              <p className="text-small text-lg-normal a-text-regular-first-primary-60 filter-button__text">
                Filtrar
              </p>
            </div>
          </div>
          {/* Aquí van los episodios */}
          <div className="mt-2">
            {programEpisodes != 0
              ? programEpisodes.map((episode) => (
                  <CardEpisode
                    key={episode.podcast_id}
                    data={episode}
                    posterId={podcast.user_id}
                    setOpenWarning={setOpenWarning}
                    setObjectToDelete={setObjectToDelete}
                  />
                ))
              : ""}
          </div>
        </div>
      </Grid>
      <Modal
        open={openSuccess}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <ModalSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            message={`${
              loadDelete ? "Espera un momento..." : "Tu episodio se ha eliminado"
            }`}
            action={"Timmer"}
          />
        </div>
      </Modal>
      <Modal
        open={openWarning}
        onClose={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal">
          <WarningModal
            message={"Tu episodio será eliminado"}
            open={openWarning}
            setOpen={setOpenWarning}
            setOpenSuccess={setOpenSuccess}
            openSuccess={openSuccess}
            deleteObject={deleteObjectEpisode}
            object={objectToDelete}
          />
        </div>
      </Modal>
    </Grid>
  );
};
