import React, { useState } from "react";

// Material
import { Grid, IconButton, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
export default function TabMenu({
  optone,
  opttwo,
  optthird,
  optfourth,
  setOptOne,
  setOptTwo,
  setOptThird,
  setOptFourth,
}) {
  function handleMenu(id) {
    setOptOne(false);
    setOptTwo(false);
    setOptThird(false);
    setOptFourth(false);
    switch (id) {
      case 1:
        setOptOne(true);
        break;
      case 2:
        setOptTwo(true);
        break;
      case 3:
        setOptThird(true);
        break;
      case 4:
        setOptFourth(true);
        break;
      default:
        break;
    }
  }
  return (
    <Grid item xs={12} md={12} lg={12} className="mt-3">
      <Grid
        item
        xs={12}
        className="mt-3 w-100 d-flex justify-content-center  flex-column "
      >
        <div className="hr-nav"></div>
        <Grid container justify="center" spacing={0}>
          <Grid
            xs={12}
            md={12}
            className="d-flex justify-content-around list-style-none a-text-regular-second-neutral-60 "
          >
            <li
              className={`cursor-pointer text-small text-md-medium  py-2 ${
                opttwo
                  ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50"
                  : ""
              }`}
              onClick={() => handleMenu(2)}
            >
              {/* Cambio temporal sólo es para el AB Testing */}
              <Link
                className="a-text-regular-second-neutral-60 linkTemporal text-decoration-none"
                to="/cid/carso/questions/list"
              >
                Preguntas
              </Link>
            </li>
            <li
              className={`cursor-pointer text-small text-md-medium   py-2 ${
                optthird
                  ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50"
                  : ""
              }`}
              onClick={() => handleMenu(3)}
            >
              {/* Cambio temporal sólo es para el AB Testing */}
              <Link
                className="a-text-regular-second-neutral-60 text-decoration-none"
                to="/cid/carso/questions/list/tu_actividad"
              >
                Tu actividad
              </Link>
            </li>
            <li
              className={`cursor-pointer text-small text-md-medium   py-2 ${
                optfourth
                  ? "a-text-medium-second-neutral-90 border-bottom-second-primary-50"
                  : ""
              }`}
              onClick={() => handleMenu(4)}
            >
              Config
            </li>
          </Grid>
        </Grid>
        <div className="hr-nav"></div>
      </Grid>
    </Grid>
  );
}
