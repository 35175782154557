import { BASE_PATH } from "./config";
import axios from "axios";

export function uploadPodcast(data) {
   return axios.post(`${BASE_PATH}/podcast/create-podcast`, data);
}

export function getPodcasts() {
   return axios.get(`${BASE_PATH}/podcast/get-podcast`)
}

export function deleteEpisode(userId, podcastId) {
   return axios.post(`${BASE_PATH}/podcast/delete-podcast`, { userId, podcastId })
}

export function deleteProgram(userId, programId) {
   return axios.post(`${BASE_PATH}/podcast/delete-program`, { userId, programId })
}

export function getProgram(programId) {
   return axios.get(`${BASE_PATH}/podcast/get-programa?programId=${programId}`)
}

export function createProgram(data) {
   return axios.post(`${BASE_PATH}/podcast/create-program`, data)
}

export function getProgramsFiltered(categories) {
   return axios.get(`${BASE_PATH}/podcast/get-filterProgram?categories=(${categories})`)
}

export function savePodcast(data) {
   return axios.post(`${BASE_PATH}/podcast/saved-podcast`, data)
}

export function podcastSaved(userId) {
   return axios.get(`${BASE_PATH}/podcast/get-podcastSaved/${userId}`)
}