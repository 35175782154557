import userIcon from "../../../../assets/img/user-icon.svg";
import Moment from "react-moment";
import Popover from "@material-ui/core/Popover";
import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "../../../atoms/Button";
import { follow, searchFollower } from "../../../../api/profile";

import Empresa from "../../../../assets/img/profile/empresa.svg";
import Area from "../../../../assets/img/profile/area.svg";
import { useHistory } from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    minHeight: "60px",
    width: "350px",
    borderColor: "secondary.main !important",
    borderWidth: "10px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: 'auto',
  },
}));
export default function ProfileComponent({ info, userId, type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [followStatus, setFollowStatus] = useState(false)

  let history = useHistory();
  const matchDesktop = useMediaQuery("(min-width: 1100px)")
  console.log(info.user_id,'detail')
useEffect(() => {
  searchingFollow(userId, info.user_id)
}, [])

async function searchingFollow(userId,userFollows) {
  try {
    const response = await searchFollower(userId,info.user_id)
    setFollowStatus(response.data.follow.isFollow)
  } catch (error) {
    
  }
  
}



async function onClick() {
  setFollowStatus(!followStatus)
  const response = await follow(userId,info.user_id,followStatus)
}

  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null);

  const popoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true)
  };

  const popoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false)
  };

  const open = Boolean(anchorEl);
  return (
    <div
      className={`d-flex justify-content-start  align-items-center`}
    >
      
      <div className="d-flex flex-column">
        {
          matchDesktop 
            ? 
              (                 
                <span 
                  className="a-text-regular-first-neutral-100 text-small text-md-medium cursor-pointer "
                  onClick={()=>{
                    history.push({
                      pathname:`/cid/carso/profile/${info.ResponseName || info.name}`,
                      state: { profile: info}
                    });
                  }}>

                  <Typography
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    // onMouseOver={handlePopoverOpen}
                    ref={popoverAnchor}
                    onMouseEnter={popoverEnter}
                    onMouseLeave={popoverLeave}
        
                  >
                  
                    {info.user_id === userId ? `${info.name}` : `${info.name}`}
                     
                    {/* {info.user_id === userId ? `${info.name}` : `${info.name}`} */}
                  </Typography>
                </span>
              ) 
            :    
              <span 
                className="a-text-regular-first-neutral-100 text-small text-md-medium" 
                onClick={()=>{
                  history.push({
                    pathname:`/cid/carso/profile/${info.ResponseName || info.name}`,
                    state: { profile: info}
                  });
                }}
              >
     
              {info.user_id === userId ? "Respondiste" : `${info.name}`} - {" "}
              <Moment fromNow>{info.created_at}</Moment>{" "}
             </span>
        }
   
        <span
          className={`${
            type != "onlyProfile"
              ? "a-text-regular-first-neutral-100 text-xs text-md-medium"
              : "d-none"
          }`}
        >
          En respuesta a{" "}
          <span className="a-text-regular-first-primary-60 text-xs text-md-normal">
            {info.creator}
          </span>
        </span>
      </div>
      <div         >
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}

        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}

        // disableRestoreFocus
        PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave}}
        
      >
        <div
          className={`d-flex justify-content-start flex-column align-items-center  mx-4 my-4 `}
          // onMouseLeave={handlePopoverClose}
        >
          <div className={`d-flex align-items-center  ${info.user_id === userId ? ' w-100' :'justify-content-center' } `}>
            <img
              src={
                info.photo_link !== null
                  ? `https://images.weserv.nl/?url=${info.photo_link}&maxage=31d`
                  : userIcon
              }
              alt="icon"
              className="icon-card-poper mr-1 "
            />
            <div className="d-flex flex-column mr-3 ">
              <span className="a-text-medium-first-primary-70 text-normal ml-3 profile-ancor cursor-pointer" onClick={()=>{
                  history.push({
                    pathname:`/cid/carso/profile/${info.ResponseName || info.name}`,
                    state: { profile: info}
                });
              }}>
                {info.ResponseName || info.name}
              </span>
            </div>
            <Button
              type="submit"
              classes={`text-normal  a-button-topic-blue-1 roboto-regular  ml-4 ${info.user_id === userId ? 'd-none' :'' }`}
              height={30}
              width="100%"
              text={`${followStatus ? 'Siguiendo' : 'Seguir'}`}
              onclick={onClick}
            />
          </div>

          <div className={`w-100 ${info.presentation ? " mt-4" : "d-none"}`}>
            <span className="a-text-regular-second-neutral-100  text-normal ">
              {info.presentation || ""}
            </span>
          </div>
          <div className={`w-100  ${info.company !== null ? "w-100 mt-4 d-flex justify-content-around " : "d-none"}`}>
            <div className=" w-50 d-flex align-items-center">
              <img src={Empresa} alt="Empresa imgane" />
              <span className=" a-text-regular-first-primary-60  text-normal ml-1 ">
                <span className="a-text-regular-second-neutral-100  text-normal">Empresa: </span>
                {info.company || ""}
              </span>
            </div>
            <div className="d-flex w-50  align-items-center ml-4">
              <img src={Area} alt="area imgane" />{" "}
              <span className="a-text-regular-first-primary-60   text-normal ml-1 ">
              <span className="a-text-regular-second-neutral-100   text-normal ">Área: </span>
               {info.nameArea || ""}
              </span>
            </div>
          </div>
        </div>
      </Popover>
      </div>

    </div>
  );
}
