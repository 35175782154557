// React
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
// Material
import { Grid, useMediaQuery, createMuiTheme } from "@material-ui/core/";
import { getQuestions, getTopicsByUserId } from "../../../api/questions";

// Hooks
import { useAuth } from "../../../hooks/useAuth";

// Componentes
import { FormAddQuestionTopic } from "../../../components/organisms/FormAddQuestionTopic/index";
import Header from "../../../components/molecules/Header";
import CardQuestion from "../../../components/organisms/CardQuestions/index";
export default function Topics(e) {
  // State
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        md: 768,
        lg: 992,
      },
    },
  });
  const ipadMiniMatch = useMediaQuery("(min-width: 768px)");
  const iPadMatch = useMediaQuery(theme.breakpoints.between(768, 992));
  const iPadProMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const [currentData, setCurrentData] = useState([]);
  const [countUser, setCountUser] = useState(true);
  const user = useAuth();
  const [isActive, setIsActive] = useState(true);
  const [userQuestios, setUserQuestions] = useState(false);
  useEffect(() => {
    const response = getQuestions();
    response
      .then((result) => {
        setCurrentData(result.data);
        setUserQuestions(true);
      })
      .catch((err) => {
        console.log(err);
        setCurrentData([]);
      });
  }, []);

  const getUserQuestions = (state) => {
    if (currentData.length !== 0) {
      window.location.href = "/cid/carso/questions/list";
    } else {
      setUserQuestions(state);
    }
  };
  const { userId } = user;

  if (!user) {
    return <Redirect to="/" />;
  }


  return (
    <Grid container justify="center" spacing={0} className="">
      <Header
        isCollapsed={e.isCollapsed}
        link="/cid/analysis"
        text="Buscar pregunta"
      />
      <Grid
        item
        xs={10}
        lg={12}
        className="background-lg-first-neutral-10 h-100vh   scroll-overflow  br-2"
      >
        <div className="background-lg-white m-lg-4 p-lg-4 px-lg-4 br-2 box-shadow-lg-all ">
          <p
            className={`mt-3 mt-lg-1 mb-lg-3 mb-lg-4  ${ipadMiniMatch
                ? `h3   mb-md-7  ${iPadProMatch ? "mt-0 mb-md-5  " : "mt-6 mb-md-7  "
                }`
                : ""
              } ${userQuestios ? "mb-4" : "mb-3"
              } a-text-medium-second-neutral-70  `}
          >
            Sección de preguntas
          </p>
          <div className="  d-flex  justify-content-center  align-items-center">
            <FormAddQuestionTopic
              userId={userId}
              config={false}
              state={userQuestios}
              storeTopics={[]}
              text="Selecciona los temas de tu interés"
              type={true}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
