import { useReducer } from 'react';
const ACTIONS = {
	LOADING: 'loading',
	CHECK_BOX_SECTOR: 'check_box_sector',
	CHECK_BOX_CLIENT: 'check_box_client',
	CHECK_BOX_MARKET: 'check_box_market',
	BUTTON_SECTOR: 'button_sector',
	BUTTON_MARKET: 'button_market',
	BUTTON_CLIENT: 'button_client',
	OPEN: 'open',
	MODAL_BODY: 'modal_body',
	STORE_FILE: 'store_file',
	STORE_LOGO: 'store_logo',
	STORE_IMAGES: 'store_images',
	STORE_IMAGES_NEW: 'store_images_new',
	STORE_IMAGES_NEW_END: 'store_images_new_end',
	STORE_TEMPORAL_IMG: 'store_temporal_img',
	STORE_NAME_FILE: 'store_name_file',
	STORE_LOGO_NAME: 'store_logo_name',
	STORE_PROGRESS: 'store_progress',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.LOADING:
			return { ...state, isLoading: action.payload };
		case ACTIONS.CHECK_BOX_SECTOR:
			return { ...state, checkboxSector: action.payload };
		case ACTIONS.CHECK_BOX_CLIENT:
			return { ...state, checkboxClient: action.payload };
		case ACTIONS.CHECK_BOX_MARKET:
			return { ...state, checkboxMarket: action.payload };
		case ACTIONS.BUTTON_SECTOR:
			return { ...state, buttonSector: action.payload };
		case ACTIONS.BUTTON_MARKET:
			return { ...state, buttonMarket: action.payload };
		case ACTIONS.BUTTON_CLIENT:
			return { ...state, buttonClient: action.payload };
		case ACTIONS.OPEN:
			return { ...state, open: action.payload };
		case ACTIONS.MODAL_BODY:
			return { ...state, modalBody: action.payload };
		case ACTIONS.STORE_FILE:
			return { ...state, file: action.payload };
		case ACTIONS.STORE_LOGO:
			return { ...state, logo: action.payload };
		case ACTIONS.STORE_IMAGES_NEW:
			return { ...state, imagesNew: action.payload };
		case ACTIONS.STORE_IMAGES:
			return { ...state, images: action.payload };
		case ACTIONS.STORE_IMAGES_NEW_END:
			return { ...state, imagesNewEnd: action.payload };
		case ACTIONS.STORE_TEMPORAL_IMG:
			return { ...state, temporalImage: action.payload };
		case ACTIONS.STORE_NAME_FILE:
			return { ...state, nameFile: action.payload };
		case ACTIONS.STORE_LOGO_NAME:
			return { ...state, logoName: action.payload };
		case ACTIONS.STORE_PROGRESS:
			return { ...state, progress: action.payload };
		default:
			return state;
	}
};
export default function useFormReport({
	initialLoading,
	initialCheckboxSector,
	initialCheckboxClient,
	initialCheckboxMarket,
	initialButtonSector,
	initialButtonMarket,
	initialButtonClient,
	initialOpenState,
	initialModalBody,
	initialFile,
	initialLogo,
	initialImages,
	initialImagesNew,
	initialImagesend,
	initialtemporalImage,
	initialNameFile,
	initialLogoName,
	initialProgress,
}) {
	const [state, dispach] = useReducer(reducer, {
		isLoading: initialLoading,
		checkboxSector: initialCheckboxSector,
		checkboxClient: initialCheckboxClient,
		checkboxMarket: initialCheckboxMarket,
		buttonSector: initialButtonSector,
		buttonMarket: initialButtonMarket,
		buttonClient: initialButtonClient,
		open: initialOpenState,
		modalBody: initialModalBody,
		file: initialFile,
		logo: initialLogo,
		images: initialImages,
		imagesNew: initialImagesNew,
		imagesNewEnd: initialImagesend,
		temporalImage: initialtemporalImage,
		nameFile: initialNameFile,
		logoName: initialLogoName,
		progress: initialProgress,
	});
	const {
		isLoading,
		checkboxSector,
		checkboxClient,
		checkboxMarket,
		buttonSector,
		buttonMarket,
		buttonClient,
		open,
		modalBody,
		file,
		logo,
		images,
		imagesNew,
		imagesNewEnd,
		temporalImage,
		nameFile,
		logoName,
		progress,
	} = state;

	return {
		isLoading,
		checkboxSector,
		checkboxClient,
		checkboxMarket,
		buttonSector,
		buttonMarket,
		buttonClient,
		open,
		modalBody,
		file,
		logo,
		images,
		imagesNew,
		imagesNewEnd,
		temporalImage,
		nameFile,
		logoName,
		progress,
		setIsLoading: (isLoading) => dispach({ type: ACTIONS.LOADING, payload: isLoading }),
		setCheckboxSector: (checkboxSector) => dispach({ type: ACTIONS.CHECK_BOX_SECTOR, payload: checkboxSector }),
		setCheckboxClient: (checkboxClient) => dispach({ type: ACTIONS.CHECK_BOX_CLIENT, payload: checkboxClient }),
		setCheckboxMarket: (checkboxMarket) => dispach({ type: ACTIONS.CHECK_BOX_MARKET, payload: checkboxMarket }),
		setButtonSector: (buttonSector) => dispach({ type: ACTIONS.BUTTON_SECTOR, payload: buttonSector }),
		setButtonMarket: (buttonMarket) => dispach({ type: ACTIONS.BUTTON_MARKET, payload: buttonMarket }),
		setButtonClient: (buttonClient) => dispach({ type: ACTIONS.BUTTON_CLIENT, payload: buttonClient }),
		setOpen: (open) => dispach({ type: ACTIONS.OPEN, payload: open }),
		setModalBody: (modalBody) =>
			dispach({
				type: ACTIONS.MODAL_BODY,
				payload: modalBody,
			}),
		setFile: (file) => dispach({ type: ACTIONS.STORE_FILE, payload: file }),
		setLogo: (logo) => dispach({ type: ACTIONS.STORE_LOGO, payload: logo }),
		setImages: (images) => dispach({ type: ACTIONS.STORE_IMAGES, payload: images }),
		setImagesNew: (imagesNew) => dispach({ type: ACTIONS.STORE_IMAGES_NEW, payload: imagesNew }),
		setImagesNewEnd: (imagesNewEnd) => dispach({ type: ACTIONS.STORE_IMAGES_NEW_END, payload: imagesNewEnd }),
		setTemporalImages: (temporalImage) => dispach({ type: ACTIONS.STORE_TEMPORAL_IMG, payload: temporalImage }),
		setNameFile: (nameFile) => dispach({ type: ACTIONS.STORE_NAME_FILE, payload: nameFile }),
		setLogoName: (logoName) => dispach({ type: ACTIONS.STORE_LOGO_NAME, payload: logoName }),
		setProgress: (progress) => dispach({ type: ACTIONS.STORE_PROGRESS, payload: progress }),
	};
}
