//TODO: hacer que se puedan borrar fundadores en la base de datos
// React
import { useEffect, useState } from 'react';
// Componens
import { InputWithLabel } from '../../../molecules/InputWith/InputWithLabel';
import { IconButton } from '@material-ui/core';
import Trash from '../../../../assets/img/trash-icon-ocean.svg';
import { Input } from '../../../atoms/Input';
import { Button } from '../../../atoms/Button';
import Linkedin2 from '../../../../assets/img/linkedin2.svg';
import Mail from '../../../../assets/img/mail.svg';
import LinkedinInactivo from '../../../../assets/img/social/linkedingris.svg';
import Fundador from '../../../../assets/img/fundador.svg';
import { Label } from '../../../organisms/Form/FormCompany/Label';
import { ImageInput } from '../../../atoms/Input/ImageInput';

const founderTemplateObject = {
	id_founder: 0,
	name_founder: '',
	position: '',
	descripcion_founders: '',
	url_linkedin: '',
	email: '',
	url_photo: '',
	index: 0,
};

export function AddFounder2({foundersData, setFounders}) {
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [buttonText, setButtonText] = useState('Agregar fundador adicional');

	useEffect(() => {
		if (foundersData.length === 0) {
			setFounders([{...founderTemplateObject}]);
		}
		console.log(12)
	}, []);

	useEffect(() => {
		// check if any fields are filled in all founders
		const allFieldsFilled = foundersData.every((item) => item.name_founder || item.position || item.descripcion_founders || item.url_linkedin || item.email || item.url_photo);

		if (!allFieldsFilled && foundersData.length === 1) {
			setButtonDisabled(true);
			setButtonText('Agregar fundador adicional');
		} else if (!allFieldsFilled && foundersData.length > 1) {
			setButtonDisabled(false);
			setButtonText('Cancelar');
		} else {
			setButtonDisabled(false);
			setButtonText('Agregar fundador adicional');
		}
	}, [foundersData]);

	function handleButton() {
		if (buttonDisabled) return;
		
		if (buttonText === 'Cancelar') {
			// filter empty founders
			const newFounders = foundersData.filter((item) => item.name_founder || item.position || item.descripcion_founders || item.url_linkedin || item.email || item.url_photo);
			setFounders(newFounders);
		} else {
			setFounders([...foundersData, {...founderTemplateObject, index: foundersData.length}]);
		}
	}

	function handleChange(evt, name, index) {
		const { value } = evt.target;
		const newFounders = [...foundersData];
		newFounders[index][name] = value;
		setFounders(newFounders);
	}

	function handleFileChange(evt, index) {
		const { files } = evt.target;
		const newFounders = [...foundersData];
		newFounders[index].url_photo = URL.createObjectURL(files[0]);
		setFounders(newFounders);
	}


    return (
        <div>
			{foundersData.map((item, index) => (
				<>
            <div className='mb-3'>
					<InputWithLabel
						text='Nombre del fundador *'
						className='company-founder mt-2'
						placeholder='Ingresa el nombre del fundador'
						type='text'
						classLabel='a-text-medium-second-neutral-90'
						// focus={isFocus}
						value={item.name_founder}
						onChange={(evt) => handleChange(evt, "name_founder", index)}
						// checked={checked}
						// disabled={!checked}
					/>
			</div>
			<div className='mb-3 mt-2'>
			<InputWithLabel
				text='Puesto del Fundador *'
				className='position-founder'
				placeholder='Ingresa el puesto del fundador'
				type='text'
				classLabel='a-text-medium-second-neutral-90'
				value={item.position}
				onChange={(evt) => handleChange(evt, 'position', index)}
				// checked={checked}
				// disabled={!checked}
			/>
			</div>
			<div className='mb-3 mt-2'>
					<InputWithLabel
						text='Currículum del fundador *'
						className='curriculum-founder'
						placeholder='Ingresa un breve currículum del fundador'
						type='text'
						maxlength='255'
						classLabel='a-text-medium-second-neutral-90'
						value={item.descripcion_founders}
						onChange={(evt) => handleChange(evt, 'descripcion_founders', index)}
						// checked={checked}
						// disabled={!checked}
					/>
				</div>
				{/* <p className={checked ? 'a-text-medium-second-neutral-90 mb-2' : 'a-text-medium-first-neutral-80 mb-2'}>
					Contacto del fundador
				</p> */}
				<div className='mb-3'>
					<Input
						width={100}
						type='social'
						id='founder-linkedin'
						className='mb-3'
						placeholder='Ingresa el linkedin del fundador'
						imagen={Linkedin2}
						value={item.url_linkedin}
						onChange={(evt) => handleChange(evt, 'url_linkedin', index)}
						// checked={checked}
						// disabled={!checked}
					/>

					<Input
						width={100}
						type='social'
						id='founder-mail'
						className='mb-3'
						placeholder='Ingresa el correo del fundador'
						imagen={Mail}
						value={item.email}
						onChange={(evt) => handleChange(evt, 'email', index)}
						// checked={checked}
						// disabled={!checked}
					/>
				</div>
				<div className='mb-5 founder-photo'>
					{/* <InputWithLabel
						text='Ingrese una foto del fundado'
						type='founder-pic'
						id={`founder-pic-${index}`}
						name='url_photo'
						value={item.url_photo}
						className="founder-pic"
						// value={previewFounderImage[`image${index}`]}
						onChange={(evt) => handleFileChange(evt, index)}
						// checked={checked}
						// disabled={!checked}
						/> */}
					<Label text="Ingrese una foto del fundador"></Label>
					<ImageInput
						id={`founder-pic-${index}`}
						value={item.url_photo}
						className="input_file founders_photo"
						Icon={Fundador}
						originalSource={item.url_photo}
						onChange={(evt) => handleFileChange(evt, index)}
						style = {{width: 200, height: 200}}
					/>
				</div>
			</>
			))}

			<div className='mb-3' onClick={handleButton}>
				<Button
					width="40%"
					id='btn-add'
					classes={`${
						buttonDisabled
							? 'button-form-inactive text-small minuta_btn-add'
							: 'button-form text-small minuta_btn-add'
					} `}
					text={buttonText}
					type='button'
					// disabled={buttonDisabled}
				/>
			</div>
        </div>)
}
